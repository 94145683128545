import { isServer } from "common/helpers/envChecker";

const LOCAL_STORAGE_KEY = "NOTIFICATION_REQUEST_BANNER_VISIBLE";

export function getVisibleFromLocalStorage() {
  if (isServer()) return true;
  return JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) || "true");
}

export function setVisibleFromLocalStorage(visible: boolean) {
  if (isServer()) return;
  localStorage.setItem(LOCAL_STORAGE_KEY, `${visible}`);
}
