import { Svg18RightarrowG } from "@moim/icons";
import React from "react";
import styled from "styled-components";

import { H10Bold } from "common/components/designSystem/typos";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
const Button = styled.div`
  display: inline-flex;
  align-items: center;
`;

const Text = styled(H10Bold)`
  color: ${props => props.theme.colorV2.colorSet.grey300};
`;

const MoreButtonIcon = styled(Svg18RightarrowG).attrs(props => ({
  size: "xs",
  iconColor: props.theme.colorV2.colorSet.grey300,
}))``;

const MoreButton: React.FC<{ text: string; onClick(): void }> = ({
  text,
  onClick,
}) => {
  return (
    <Wrapper>
      <Button onClick={onClick} role="button">
        <Text>{text}</Text>
        <MoreButtonIcon />
      </Button>
    </Wrapper>
  );
};

export default MoreButton;
