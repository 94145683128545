import * as React from "react";
import uuid from "uuid";
// hooks
import { useActions } from "app/store";
import { useCancelTokenWithCancelHandler } from "common/hooks/useCancelToken";
import { useParseListElementConfig } from "common/components/blockitListLayout/hooks/useParseListElementConfig";
import useRedirect from "common/hooks/useRedirect";
import { useIntlShort } from "common/hooks/useIntlShort";

// actions
import {
  getContentsGroupData as getContentsGroupDataAction,
  getContentsGroupThreads as getContentsGroupThreadsAction,
} from "app/actions/contentsGroup";
// helpers
import { MoimURL } from "common/helpers/url";
// component
import InViewTrigger from "../../../components/inViewTrigger";
import { ArrowContainer } from "common/components/blockitEditorBase/styled";
import MoreButton from "../../../components/moreButton";
import ContentsGroupItemList from "./list";
import { ContentsGroupPreviewFilter } from "./filter";
import { Spacer } from "common/components/designSystem/spacer";
import { useBlockitRendererContext } from "../../../context";

const DEFAULT_LIMIT = 20;

const ContentGroupPreviewWithResourceId: React.FC<Moim.Blockit.V2.IContentGroupPreviewBlock> = ({
  listElement,
  filter,
  listShowConfig,
  resourceId,
  moreButton,
}) => {
  const intl = useIntlShort();
  const redirect = useRedirect();
  const { prefetchedData } = useBlockitRendererContext();

  const [
    contentsGroupData,
    setContentsGroup,
  ] = React.useState<Moim.ContentsGroup.IContentsGroupData | null>(
    resourceId ? prefetchedData?.contentsGroup?.[resourceId] ?? null : null,
  );
  const { cancelTokenSource, handleCancel } = useCancelTokenWithCancelHandler();

  const [threads, setThreads] = React.useState<Moim.Forum.IThread[]>();
  const [isLoading, setLoadStatus] = React.useState<boolean>();
  const portalSectionId = React.useMemo(
    () => `contents-group-preview-portal-${uuid.v4()}`,
    [],
  );
  const { getContentsGroupThreads, getContentsGroupData } = useActions({
    getContentsGroupThreads: getContentsGroupThreadsAction,
    getContentsGroupData: getContentsGroupDataAction,
  });

  const { listElementType, maxVisibleCount } = useParseListElementConfig(
    listElement,
  );

  const handleClickViewMore = React.useCallback(() => {
    if (!resourceId) {
      return;
    }
    const defaultRedirectUrl = new MoimURL.ContentsGroupThreads({
      id: resourceId,
    }).toString();
    const redirectUrl = contentsGroupData?.detailUrl;

    if (!redirectUrl) {
      redirect(defaultRedirectUrl);
    } else {
      const nl = new URL(redirectUrl);

      if (nl.hostname === location.hostname) {
        redirect(defaultRedirectUrl);
      } else {
        window.open(redirectUrl, "_blank");
      }
    }
  }, [contentsGroupData, redirect, resourceId]);

  const handleOnView = React.useCallback(async () => {
    try {
      if (!isLoading && resourceId) {
        setLoadStatus(true);
        const [contentsGroupResponse, _threads] = await Promise.all([
          getContentsGroupData(resourceId),
          getContentsGroupThreads({
            id: resourceId,
            limit: maxVisibleCount ?? DEFAULT_LIMIT,
            cancelToken: cancelTokenSource.current.token,
          }),
        ]);
        if (contentsGroupResponse) {
          setContentsGroup(contentsGroupResponse);
        }

        setThreads(_threads?.data);
      }
    } finally {
      setLoadStatus(false);
    }
  }, [
    isLoading,
    getContentsGroupData,
    resourceId,
    getContentsGroupThreads,
    maxVisibleCount,
    cancelTokenSource,
  ]);

  const filterContents = React.useCallback(
    (filters?: { tagSetIds: string[] }) => {
      if (resourceId) {
        getContentsGroupThreads({
          id: resourceId,
          limit: maxVisibleCount ?? DEFAULT_LIMIT,
          filters,
          cancelToken: cancelTokenSource.current.token,
        })
          .then(result => {
            setThreads(result?.data);
          })
          .finally(() => {
            setLoadStatus(false);
          });
      }
    },
    [maxVisibleCount],
  );

  const handleChangeSelectedTags = React.useCallback(
    (selectedTags: string[][]) => {
      const tagSetIds = selectedTags
        ? Object.values(selectedTags)?.filter(tags => tags.length)
        : undefined;
      filterContents({
        tagSetIds: tagSetIds?.flatMap(it => it) ?? [],
      });
    },
    [filterContents],
  );

  React.useLayoutEffect(
    () => () => {
      handleCancel();
    },
    [handleCancel],
  );

  return (
    <>
      <InViewTrigger onVisible={handleOnView} />
      {filter?.tagGroups?.length ? (
        <ContentsGroupPreviewFilter
          tagGroupIds={filter?.tagGroups}
          onChangeSelectedTags={handleChangeSelectedTags}
        />
      ) : null}
      {listElementType === "horizontal" && (
        <ArrowContainer id={portalSectionId} onClick={handleClickViewMore} />
      )}
      <ContentsGroupItemList
        threads={threads}
        portalSectionId={portalSectionId}
        isLoading={Boolean(
          (!threads?.length && isLoading === undefined) || isLoading,
        )}
        listElement={listElement}
        listShowConfig={listShowConfig}
      />
      {moreButton?.show ? (
        <>
          <Spacer value={24} />
          <MoreButton
            text={intl("button_see_more_content")}
            onClick={handleClickViewMore}
          />
        </>
      ) : null}
    </>
  );
};

export default React.memo(ContentGroupPreviewWithResourceId);
