// javascript
import React from "react";
import { JoinedSubMoimStatusType } from "./container/joinedSubMoims";
import useIsMobile from "common/hooks/useIsMobile";
import { UnreadSnackBarType } from "./components/unreadSnackBar";

type IUnreadSnackBarState =
  | { open: false }
  | {
      open: true;
      type: UnreadSnackBarType;
      openBy: Moim.Id;
    };

export const CLOSED_UNREAD_SNACK_BAR_STATE: IUnreadSnackBarState = {
  open: false,
};

interface NavigationPanelContextValue {
  joinedSubMoimsStatus: JoinedSubMoimStatusType;
  topUnreadSnackBar: IUnreadSnackBarState;
  bottomUnreadSnackBar: IUnreadSnackBarState;
  setJoinedSubMoimsStatus(status: JoinedSubMoimStatusType): void;
  setTopUnreadSnackBar(state: IUnreadSnackBarState): void;
  setBottomUnreadSnackBar(state: IUnreadSnackBarState): void;
}

const navigationPanelInitialValues = {
  joinedSubMoimsStatus: "Open" as JoinedSubMoimStatusType,
  setJoinedSubMoimsStatus: () => {},
  topUnreadSnackBar: CLOSED_UNREAD_SNACK_BAR_STATE,
  setTopUnreadSnackBar: () => {},
  bottomUnreadSnackBar: CLOSED_UNREAD_SNACK_BAR_STATE,
  setBottomUnreadSnackBar: () => {},
};

export const NavigationPanelContext = React.createContext<
  NavigationPanelContextValue
>(navigationPanelInitialValues);

export function useNavigationPanelContext(): NavigationPanelContextValue {
  const isMobile = useIsMobile();

  const [status, setStatus] = React.useState<JoinedSubMoimStatusType>(
    isMobile ? "Open" : "Disabled",
  );
  const [topUnreadSnackBar, setTopUnreadSnackBar] = React.useState<
    IUnreadSnackBarState
  >(CLOSED_UNREAD_SNACK_BAR_STATE);
  const [bottomUnreadSnackBar, setBottomUnreadSnackBar] = React.useState<
    IUnreadSnackBarState
  >(CLOSED_UNREAD_SNACK_BAR_STATE);

  return {
    joinedSubMoimsStatus: status,
    setJoinedSubMoimsStatus: setStatus,
    topUnreadSnackBar,
    setTopUnreadSnackBar,
    bottomUnreadSnackBar,
    setBottomUnreadSnackBar,
  };
}
export function useNavigationPanelContextInitialValues(): NavigationPanelContextValue {
  const isMobile = useIsMobile();

  const [status, setStatus] = React.useState<JoinedSubMoimStatusType>(
    isMobile ? "Open" : "Disabled",
  );
  const [topUnreadSnackBar, setTopUnreadSnackBar] = React.useState<
    IUnreadSnackBarState
  >(CLOSED_UNREAD_SNACK_BAR_STATE);
  const [bottomUnreadSnackBar, setBottomUnreadSnackBar] = React.useState<
    IUnreadSnackBarState
  >(CLOSED_UNREAD_SNACK_BAR_STATE);

  return {
    joinedSubMoimsStatus: status,
    setJoinedSubMoimsStatus: setStatus,
    topUnreadSnackBar,
    setTopUnreadSnackBar: (params: any) => {
      setTopUnreadSnackBar(params);
    },
    bottomUnreadSnackBar,
    setBottomUnreadSnackBar,
  };
}
