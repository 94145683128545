/* eslint-disable @typescript-eslint/no-empty-function */
import { default as localforage } from "localforage";
import moment from "moment";
import { isBrowser } from "../envChecker";

class EntityCacheController {
  private static instance: EntityCacheController;
  private entityStorage: any;

  private constructor() {
    try {
      this.entityStorage = isBrowser()
        ? localforage.createInstance({
            name: "moim_entity_storage",
            driver: localforage.INDEXEDDB,
            storeName: "entityStorage",
            version: 1,
          })
        : {
            setItem: async () => {},
            getItem: async (): Promise<any> => {},
            clear: async () => {},
          };
    } catch {
      this.entityStorage = {
        setItem: async () => {},
        getItem: async (): Promise<any> => {},
        clear: async () => {},
      };
    }
  }

  public static getInstance(): EntityCacheController {
    if (!EntityCacheController.instance) {
      EntityCacheController.instance = new EntityCacheController();
    }
    return EntityCacheController.instance;
  }

  public async writeEntities(key: Moim.Entity.NormalizedKey, entities: any) {
    await this.entityStorage.setItem(key, entities);
  }

  public async removeEntity(
    key: Moim.Entity.NormalizedKey,
    resourceId: Moim.Id,
  ) {
    try {
      const entities = await this.fetchEntities(key);
      if (!entities) {
        return;
      }

      delete entities[resourceId];

      await this.entityStorage.setItem(key, entities);
    } catch {
      await this.entityStorage.clear();
    }
  }

  public async fetchEntities(
    key: Moim.Entity.NormalizedKey,
  ): Promise<Record<string, any> | undefined> {
    try {
      return await this.entityStorage.getItem(key);
    } catch {
      return;
    }
  }

  public async checkAndClearCache() {
    const age = await this.entityStorage.getItem("age");
    if (!age) {
      this.initializeAge();
      return;
    }

    const isOld = moment(age).isBefore(moment().subtract(1, "days"));

    if (isOld) {
      this.initializeAge();
      await this.entityStorage.clear();
    }
  }

  private initializeAge() {
    this.entityStorage.setItem("age", moment.now());
  }
}

export const entityCacheController = EntityCacheController.getInstance();
