import { createContext } from "react";

export interface IStepData {
  key: string;
  title?: string;
  previewButtonText: string;
  nextButtonText: string;
  default?: boolean;
}
interface IStepContext {
  steps: IStepData[];
  currentStep: number;
  isLastStep: boolean;
  error: string | undefined;
  onNext(): void;
  onPrev(): void;
  setCurrentStep(step: number): void;
  setError(error: string | undefined): void;
}

const stepContextInitialValue: IStepContext = {
  steps: [],
  currentStep: 0,
  isLastStep: false,
  error: undefined,
  onNext: () => {},
  onPrev: () => {},
  setCurrentStep: () => {},
  setError: () => {},
};

const StepContext = createContext<IStepContext>(stepContextInitialValue);

export { StepContext };
