import React from "react";
import { useStoreState } from "app/store";
import { channelWithCategorySelector } from "app/selectors/channel";
import ChannelItem from "./item";
import { WithCategory } from "common/components/channelList";

const ChannelListElement = () => {
  const { channelList } = useStoreState(state => ({
    channelList: channelWithCategorySelector(state),
  }));

  return (
    <>
      {channelList.data.map(channel => {
        if (channel.type !== "category") {
          return <ChannelItem key={channel.id} channelId={channel.id} />;
        }

        return (
          <WithCategory
            key={`category_${channel.id}`}
            useCollapse={true}
            showChannelAddButton={false}
            categoryName={channel.name}
            elementPaletteKey="background"
            textColorPaletteKey="categoryTitleText"
          >
            {channel.items?.map(item => (
              <ChannelItem key={item.id} channelId={item.id} />
            ))}
          </WithCategory>
        );
      })}
    </>
  );
};

export default ChannelListElement;
