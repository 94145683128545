import useEntitiesFetchSelector from "./useEntitiesFetchSelector";

export default function useChannelFetchSelector(requestIds: Moim.Id[]) {
  const { isLoading, entities } = useEntitiesFetchSelector({
    channels: requestIds,
  });

  return {
    isLoading,
    channels: entities.channels ?? [],
  };
}
