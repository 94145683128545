import * as React from "react";
import { useOpenStateWithHashRoute } from "common/hooks/useOpenState";

import AddressCreateDialogWrapper from "./components/dialogWrapper";
import CreateDialogComponent, { IProps } from "./component";

const HASH_KEY = "addressFormDialog";

export interface IRef {
  openStatus: boolean;
  open(): void;
  close(): void;
}

const AddressCreateDialog = React.forwardRef<
  IRef,
  Partial<Omit<IProps, "open">>
>(({ shippingAddressId, onClose }, ref) => {
  const { isOpen, open, close } = useOpenStateWithHashRoute(HASH_KEY);

  const handleOpen = React.useCallback(() => {
    open();
  }, []);

  const handleClose = React.useCallback(() => {
    close();
    onClose?.();
  }, [onClose]);

  React.useImperativeHandle(ref, () => ({
    openStatus: isOpen,
    open: handleOpen,
    close: handleClose,
  }));

  return (
    <AddressCreateDialogWrapper isOpenDialog={isOpen} onClose={handleClose}>
      <CreateDialogComponent
        open={isOpen}
        shippingAddressId={shippingAddressId}
        onClose={handleClose}
      />
    </AddressCreateDialogWrapper>
  );
});

export default React.memo(AddressCreateDialog);
