import { createContext, useContextWithSelector } from "./core";

interface ThreadItemContextValues {
  item: Moim.Forum.IThread<any>;
  config: Partial<Moim.Thread.IThreadItemConfig>;
  stat?: Moim.Thread.IThreadItemStatProps;
}

const ThreadItemContext = createContext<ThreadItemContextValues>({
  item: {} as any,
  config: {} as any,
});

export const ThreadItemContextProvider: React.FC<
  ThreadItemContextValues & { children?: React.ReactNode }
> = ({ children, ...props }) => {
  return (
    <ThreadItemContext.Provider value={props}>
      {children}
    </ThreadItemContext.Provider>
  );
};

export function useThreadItemContext<T>(
  selector: (state: ThreadItemContextValues) => T,
) {
  return useContextWithSelector<ThreadItemContextValues, T>(
    ThreadItemContext,
    selector,
  );
}
