export const getShowAuthorTop = (
  showConfig: Omit<Moim.Forum.IForumShowConfig, "show_type">,
) => {
  if (showConfig.show_author_top !== undefined) {
    return showConfig.show_author_top;
  } else if (
    showConfig.show_author &&
    showConfig.author_config?.position === "top"
  ) {
    return true;
  } else {
    return true;
  }
};

export const getShowAuthorBottom = (
  showConfig: Omit<Moim.Forum.IForumShowConfig, "show_type">,
): {
  type: "simple" | "detailed";
  active: boolean;
} => {
  if (showConfig.show_author_bottom_config !== undefined) {
    return showConfig.show_author_bottom_config;
  } else if (
    showConfig.show_author &&
    showConfig.author_config?.position === "bottom"
  ) {
    return {
      type: "simple",
      active: true,
    };
  } else {
    return {
      type: "detailed",
      active: false,
    };
  }
};
