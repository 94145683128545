import { useStoreState } from "app/store";
import { get } from "lodash";
import { useCurrentHubSellerId } from "./commerce/useHubSeller";

type DisplayConfigKey = keyof Moim.Commerce.DisplayConfigType;

export const useSellerDisplayConfig = (key: DisplayConfigKey) => {
  const hubSellerId = useCurrentHubSellerId();
  const config = useStoreState(
    state =>
      state.entities.commerce_seller[hubSellerId ?? ""]?.displayConfig?.[key],
  );

  return config ?? false;
};

export const useSellerConfig = (
  key?: keyof Moim.Commerce.ISellerConfig,
  path?: string,
) => {
  const hubSellerId = useCurrentHubSellerId();
  const config = useStoreState(state => {
    const sellerConfig =
      state.entities.commerce_seller[hubSellerId ?? ""]?.config;
    if (path) {
      return get(sellerConfig, path);
    }
    if (key) {
      return sellerConfig?.[key];
    }
    return undefined;
  });
  return config ?? false;
};
