import * as React from "react";
import { useIntl } from "react-intl";
import { ThreadInput } from "common/components/threadV2";
import AlertDialog from "common/components/alertDialog";
import { UnsignedCommentInputFeedback } from "common/components/feedBack/components/unsigned/input";
// hook
import PermissionChecker from "common/components/permissionChecker";
import { useStoreState } from "app/store";
import useOpenState from "app/common/hooks/useOpenState";
import { useForumThreadInput } from "common/components/threadV2/components/threadInput/hooks";
import { usePostShowPermission } from "app/modules/postShow/hooks";
import { useHandleSignIn } from "common/hooks/useHandleSign";
import useCurrentUser from "common/hooks/useCurrentUser";
// helper
import { AnalyticsClass } from "common/helpers/analytics/analytics";
import isEmpty from "common/components/blockitEditorBase/helpers/isEmpty";
import { fileListSelector } from "app/selectors/file";
// type
import { IForwardRef } from "common/components/groupInput";
import { PermissionDeniedFallbackType } from "app/enums";

// components
import UserProfileImage from "common/components/userProfileImage";
import {
  CurrentUserAvatarPlacement,
  ThreadInputWrapper,
  NoRightTextWrapper,
  BackgroundLayer,
  inputWrapperStyle,
} from "./styled";

interface IProps {
  channelId: Moim.Id;
  originalThreadId: Moim.Id;
  commentId: Moim.Id;
  autoFocus?: boolean;
}

const NestedReplyInput: React.FC<IProps> = ({
  channelId,
  originalThreadId,
  commentId,
  autoFocus,
}) => {
  const intl = useIntl();
  const refThis = React.useRef<HTMLDivElement>(null);
  const inputRef = React.useRef<IForwardRef>(null);
  const currentUser = useCurrentUser();

  const uploadLoadingAlertOpenState = useOpenState();
  const { getFileEntities, currentGroupId, isReplyAnonymous } = useStoreState(
    state => ({
      getFileEntities: (ids: string[]) => fileListSelector(state, ids),
      currentGroupId: state.app.currentGroupId ?? undefined,
      isReplyAnonymous: (state.entities.channels[
        channelId
      ] as Moim.Channel.IForumSimpleChannel)?.anonymous_config?.reaction,
    }),
  );
  const handleSignIn = useHandleSignIn();
  const { hasPermission: writeComment, isLoading } = usePostShowPermission(
    "WRITE_COMMENT",
    undefined,
    originalThreadId,
  );
  const threadInputHookProps = useForumThreadInput.useProps();
  const { handlePostThread } = useForumThreadInput.useHandlers(
    threadInputHookProps,
  );

  const checkUploadDone = React.useCallback(() => {
    const ids = inputRef.current?.getUploadQueue();

    if (!ids) return true;
    const files = getFileEntities(ids);
    return !files
      .map(f => f?.status.name ?? "WAITING_FOR_UPLOAD")
      .some(statusName => statusName !== "AVAILABLE");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getFileEntities, inputRef]);

  const handleFocus = React.useCallback(() => {
    AnalyticsClass.getInstance().forumPostCommentReplyWriteSelect({
      forumId: channelId,
      commentId,
    });
  }, [channelId, commentId]);

  const handleEnter = React.useCallback(
    (
      contents: Moim.Blockit.Blocks[],
      preLinkMeetingData: Moim.Meeting.IPreLinkedMeetingInfo | null,
    ) => {
      if (!checkUploadDone()) {
        uploadLoadingAlertOpenState.open();
        return;
      }
      const { isEmptyFile, isEmptyText, isEmptyImageFile } = isEmpty(contents);

      if (
        isEmptyFile &&
        isEmptyText &&
        isEmptyImageFile &&
        !preLinkMeetingData
      ) {
        return;
      }

      handlePostThread({
        type: "nestedReply",
        channelId,
        threadId: commentId,
        contents,
        groupId: currentGroupId,
      });

      inputRef.current?.groupInputClear();
      AnalyticsClass.getInstance().forumPostCommentReplyWritePublish({
        forumId: channelId,
        commentId,
      });
    },
    [
      checkUploadDone,
      handlePostThread,
      channelId,
      commentId,
      currentGroupId,
      uploadLoadingAlertOpenState,
    ],
  );

  const uploadLoadingAlertButtons = React.useMemo(
    () => [
      {
        text: intl.formatMessage({ id: "ok_button" }),
        onClick: uploadLoadingAlertOpenState.close,
      },
    ],
    [uploadLoadingAlertOpenState.close, intl],
  );

  return (
    <>
      <ThreadInputWrapper ref={refThis}>
        <PermissionChecker
          fallbackType={PermissionDeniedFallbackType.CUSTOM}
          hasPermission={writeComment}
          isLoading={isLoading}
          permissionDeniedCustomElement={
            <NoRightTextWrapper>
              <BackgroundLayer>
                {intl.formatMessage({ id: "no_right_block_input_placeholder" })}
              </BackgroundLayer>
            </NoRightTextWrapper>
          }
          unsignedCustomElement={
            <UnsignedCommentInputFeedback onClick={handleSignIn} />
          }
        >
          <CurrentUserAvatarPlacement>
            <UserProfileImage
              size="s"
              canOpenProfileDialog={false}
              src={currentUser?.avatar_url ?? ""}
              title={currentUser?.name}
            />
          </CurrentUserAvatarPlacement>
          <ThreadInput
            id={commentId}
            autoFocus={autoFocus}
            inputRef={inputRef}
            wrapperOverrideStyle={inputWrapperStyle}
            maxAttachmentCount={50}
            mentionPortalContainer={refThis.current}
            onFocus={handleFocus}
            onEnter={handleEnter}
            placeholder={intl.formatMessage({
              id: "post_show_reply_placeholder",
            })}
            useImageFileAttachButton={true}
            resizeDetectHeight={true}
            disableMention={Boolean(isReplyAnonymous)}
            disableCreateMeeting={true}
            disableMentionPortal={false}
          />
        </PermissionChecker>
      </ThreadInputWrapper>
      <AlertDialog
        key="uploadLoadingAlert"
        open={uploadLoadingAlertOpenState.isOpen}
        content={intl.formatMessage({
          id: "thread_v2_input_upload_working_alert_body",
        })}
        rightButtons={uploadLoadingAlertButtons}
        onClose={uploadLoadingAlertOpenState.close}
      />
    </>
  );
};

export default NestedReplyInput;
