import React from "react";
import ProductGroupItem from "./productGroupItem";
import { Spacer } from "common/components/designSystem/spacer";

interface ProductGroupListProps {
  purchase: Moim.Commerce.IPurchase;
}

const ProductGroupList = ({ purchase }: ProductGroupListProps) => {
  const productGroupListElement = React.useMemo(
    () =>
      purchase.items?.map((item, index) => (
        <React.Fragment key={index}>
          {index !== 0 && <Spacer key={`spacer_${index}`} value={12} />}
          <ProductGroupItem item={item} purchase={purchase} />
        </React.Fragment>
      )),
    [purchase],
  );

  return <>{productGroupListElement}</>;
};

export default ProductGroupList;
