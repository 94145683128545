import * as React from "react";
import BlockitListLayout from "common/components/blockitListLayout";
import {
  DimmedLeftArrow,
  DimmedRightArrow,
} from "common/components/horizontalScroll/arrows";
import NavItem from "./components/item";
import { Wrapper } from "./styled";
import { withPlacement } from "../../hoc/withPlacement";
import {
  BlockitRendererContextProvider,
  useBlockitRendererContext,
} from "../../context";
import { getSrcToUse } from "../../helper/srcGetter";

const QuickLinkNavigation: React.FC<Moim.Blockit.V2.IQuickLinkNavigationBlock> = ({
  listElement,
  itemStyle,
  navigationItems,
}) => {
  const itemGutterSize = listElement.itemGutterSize ?? 32;
  const itemGutterSize_web = listElement.itemGutterSize_web ?? 32;
  const columnCount = listElement.columnCount;

  const context = useBlockitRendererContext();
  const wrapperWidth =
    (context.estimatedWidth - itemGutterSize * (columnCount - 1)) / columnCount;

  return (
    <Wrapper>
      <BlockitListLayout
        element={{ ...listElement, itemGutterSize, itemGutterSize_web }}
        leftArrow={DimmedLeftArrow}
        rightArrow={DimmedRightArrow}
      >
        {navigationItems.map((datum, index) => (
          <BlockitRendererContextProvider estimatedWidth={wrapperWidth}>
            <NavItem
              key={index}
              {...datum}
              src={
                datum.image ? getSrcToUse(datum.image, wrapperWidth) : undefined
              }
              itemStyle={itemStyle}
            />
          </BlockitRendererContextProvider>
        ))}
      </BlockitListLayout>
    </Wrapper>
  );
};

export default withPlacement(React.memo(QuickLinkNavigation));
