import React from "react";
import styled, { css } from "styled-components";
import cx from "classnames";

import TransitionController from "../components/transitionController";
import { MEDIA_QUERY } from "common/constants/responsive";
import { px2rem } from "common/helpers/rem";
import { getPaddingStyle } from "../helper/blockitStyleHelpers";
import { getColorByAlias } from "app/theme";

const PlacementContainer = styled.div<{
  background?: Moim.Blockit.V2.BlockBackgroundType;
}>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  ${props => {
    switch (props.background?.type) {
      case "image": {
        return css`
          background-image: url(${props.background.value});
          background-size: ${props.background.crop === false
            ? "contain"
            : "cover"};
          background-repeat: no-repeat;
          background-position: center;
        `;
      }
      case "color": {
        return css`
          background-color: ${getColorByAlias(
            props.theme.colorV2,
            props.background.value,
          )};
        `;
      }
      default:
        return;
    }
  }}
`;

const Section = styled.div<{
  sectionWidth?: "fit-container" | number;
  sectionMaxWidth?: number | string;
  sectionMinWidth?: number | string;
  padding?: Moim.Blockit.V2.IBlockitPadding;
}>`
  position: relative;
  max-width: ${props =>
    props.sectionMaxWidth
      ? typeof props.sectionMaxWidth === "number"
        ? px2rem(props.sectionMaxWidth)
        : props.sectionMaxWidth
      : "initial"};
  min-width: ${props =>
    props.sectionMinWidth
      ? typeof props.sectionMinWidth === "number"
        ? px2rem(props.sectionMinWidth)
        : props.sectionMinWidth
      : "initial"};

  width: ${props => {
    if (!props.sectionWidth || props.sectionWidth === "fit-container") {
      return "100%";
    }
    return `${props.sectionWidth}%`;
  }};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media ${MEDIA_QUERY.ONLY_MOBILE} {
    width: 100%;
  }
  ${props => getPaddingStyle(props.padding)};
`;

export function withPlacement<T extends Moim.Blockit.V2.BaseBlock>(
  WrappedComponent: React.ComponentType<T>,
) {
  return class extends React.PureComponent<T & { className?: string }> {
    public render() {
      const {
        background,
        sectionWidth,
        sectionMinWidth,
        sectionMaxWidth,
        transition,
        padding,
      } = this.props;
      return (
        <PlacementContainer background={background}>
          <TransitionController transition={transition}>
            {({ styles, className: animationClassName }) => (
              <Section
                className={cx(this.props.className, animationClassName)}
                sectionWidth={sectionWidth}
                sectionMinWidth={sectionMinWidth}
                sectionMaxWidth={sectionMaxWidth}
                padding={padding}
                style={styles}
              >
                <WrappedComponent {...this.props} />
              </Section>
            )}
          </TransitionController>
        </PlacementContainer>
      );
    }
  };
}
