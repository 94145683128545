import { Svg48Play } from "@moim/icons";
import { useBlockitRendererContext } from "common/components/blockitEditorBase/components/blockitRenderer.v2/context";
import { getSrcUrlToUse } from "common/components/blockitEditorBase/components/blockitRenderer.v2/helper/srcGetter";
import {
  parseRatio,
  replaceRatio,
} from "common/components/thread/components/wrapper/thumbnail";
import { px2rem } from "common/helpers/rem";
import * as React from "react";
import styled, { css } from "styled-components";

const PlayIcon = styled(Svg48Play).attrs({
  size: "l",
  touch: 48,
})``;

const PlayIconWrapper = styled.div`
  width: ${px2rem(48)};
  height: ${px2rem(48)};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const ImageContent = styled.div<{ ratio?: string; radius: number }>`
  position: relative;
  width: 100%;
  border-radius: ${(props) => px2rem(props.radius)};

  overflow: hidden;
  ${(props) => {
    const { width, height } = parseRatio(props.ratio);
    return css`
      height: 0;
      padding-top: ${Math.round(100 * (height / width))}%;
    `;
  }}
`;

export function ThreadThumbnailWrapper({
  children,
  ratio: stringRatio,
  thumbnail,
  className,
  isVideo,
  radius = 0,
}: {
  children: (src?: string, srcSet?: string) => React.ReactElement<HTMLElement>;
  thumbnail?: Moim.Forum.IThumbnail;
  ratio?: string;
  className?: string;
  isVideo?: boolean;
  radius?: number;
}) {
  const { estimatedWidth } = useBlockitRendererContext();
  const replacedSrc = React.useMemo(() => {
    const WHITE_LIST_DOMAINS = [
      "files.moim.co",
      "files.vingle.network",
      location.hostname,
    ];

    if (!thumbnail) {
      return undefined;
    }

    if (!WHITE_LIST_DOMAINS.includes(new URL(thumbnail.url).hostname)) {
      return thumbnail.url;
    }

    const url = getSrcUrlToUse(thumbnail, estimatedWidth);
    const replaced = replaceRatio(url, stringRatio);

    return replaced ?? thumbnail?.url;
  }, [thumbnail, estimatedWidth, stringRatio]);

  return (
    <ImageContent ratio={stringRatio} className={className} radius={radius}>
      {children(replacedSrc)}
      {isVideo && (
        <PlayIconWrapper>
          <PlayIcon />
        </PlayIconWrapper>
      )}
    </ImageContent>
  );
}
