import * as React from "react";
import { FormattedMessage } from "react-intl";
import { BoxContainer, BoxInputWrapperStyle, Left, Right } from "../styled";
import { MultilineBoxInput } from "common/components/designSystem/boxInput";

import RequiredMark from "common/components/requiredMark";
import { useIntlShort } from "common/hooks/useIntlShort";

interface IProps {
  error?: string;
  required?: boolean;
  memo: string | undefined;
  onChange(updated: Partial<Moim.Commerce.ICommerceShippingAddress>): void;
}

const MemoInput: React.FC<IProps> = ({ required, error, memo, onChange }) => {
  const intl = useIntlShort();
  const handleChange = React.useCallback(
    (value: string) => {
      onChange({ memo: value });
    },
    [onChange],
  );
  return (
    <>
      <Left>
        <FormattedMessage id={"delivery_memo_title"} />
        {required && <RequiredMark />}
      </Left>
      <Right>
        <BoxContainer>
          <MultilineBoxInput
            size="Small"
            value={memo}
            placeholder={intl("delivery_memo_placeholder")}
            wrapperStyle={BoxInputWrapperStyle}
            onChange={handleChange}
            status={error ? "Error" : undefined}
            helperText={error}
          />
        </BoxContainer>
      </Right>
    </>
  );
};

export default MemoInput;
