import { px2rem } from "common/helpers/rem";
import styled, { css } from "styled-components";

export const GridContainer = styled.div<{ imageCount: number }>`
  width: 100%;
  max-height: 100%; 
  display: grid;
  gap: ${px2rem(4)};
  ${({ imageCount }) => {
    switch (imageCount) {
      case 1:
        return css`
          grid-template-rows: 1fr;
          grid-template-columns: 1fr;
        `;
      case 2:
        return css`
          grid-template-rows: 1fr 1fr;
          grid-template-columns: 1fr;

          & > div:nth-child(1) {
            grid-row: span 1;
          }
        `;
      case 3:
        return css`
          grid-template-rows: 1fr 1fr;
          grid-template-columns: 1fr 1fr;

          & > div:nth-child(1) {
            grid-column: span 2;
          }
        `;
      case 4:
        return css`
          grid-template-rows: 1fr 1fr;
          grid-template-columns: 1fr 1fr;
        `;
      case 5:
      default:
        return css`
          grid-template-rows: repeat(5, 1fr);
          grid-template-columns: repeat(6, 1fr);

          & > div:nth-child(1),
          & > div:nth-child(2) {
            grid-row: span 3;
            grid-column: span 3;
          }

          & > div:nth-child(3),
          & > div:nth-child(4),
          & > div:nth-child(5) {
            grid-row: span 2;
            grid-column: span 2;
          }
        `;
    }
  }}}
`;

export const GridItemWrapper = styled.div`
  overflow: hidden;
`;
