import * as React from "react";
import styled from "styled-components";

import FallbackImage from "common/components/fallbackImage";
import { ThreadThumbnailWrapper } from "common/components/thread/components/wrapper/thumbnail";

import { px2rem } from "common/helpers/rem";
import {
  BlockitRendererContextProvider,
  useBlockitRendererContext,
} from "common/components/blockitEditorBase/components/blockitRenderer.v2/context";

interface IProps {
  thumbnail?: Moim.Forum.IThumbnail;
  ratio?: string;
  radius?: number;
  isVideo?: boolean;
  position?: "left" | "right" | "top" | "bottom";
}

const StyledImage = styled.img.attrs<IProps>({ loading: "lazy", alt: "" })`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const FallbackImageWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  img.logo {
    height: initial !important;
  }
`;

const Wrapper = styled.div<{
  position?: "left" | "right" | "top" | "bottom";
}>`
  width: ${props =>
    props.position === "left" || props.position === "right"
      ? px2rem(100)
      : "100%"};
`;

function Image({ thumbnail, ratio, radius, position, isVideo }: IProps) {
  const { estimatedWidth } = useBlockitRendererContext();

  return (
    <BlockitRendererContextProvider
      estimatedWidth={
        position === "left" || position === "right" ? 100 : estimatedWidth
      }
    >
      <Wrapper position={position}>
        <ThreadThumbnailWrapper
          ratio={ratio}
          thumbnail={thumbnail}
          isVideo={isVideo}
          radius={radius}
        >
          {src =>
            src ? (
              <StyledImage src={src} />
            ) : (
              <FallbackImageWrapper>
                <FallbackImage type="postItem" />
              </FallbackImageWrapper>
            )
          }
        </ThreadThumbnailWrapper>
      </Wrapper>
    </BlockitRendererContextProvider>
  );
}

export default React.memo(Image);
