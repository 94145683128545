import styled from "styled-components";
import { px2rem } from "common/helpers/rem";
import { B1Bold } from "common/components/designSystem/typos";

export const Section = styled.div`
  position: relative;
  width: 100%;
  padding: 0 ${px2rem(16)};
  display: flex;
  flex-direction: column;

  & + & {
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: ${px2rem(1)};
      background-color: ${props => props.theme.colorV2.colorSet.grey50};
    }
  }
`;

export const ProfileImageWrapper = styled.div`
  padding: ${px2rem(16)} 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${px2rem(8)};
`;

export const UserName = styled(B1Bold)`
  color: ${props => props.theme.colorV2.colorSet.grey800};
`;
