import * as React from "react";
import styled from "styled-components";
import { px2rem } from "common/helpers/rem";

import { ProductItemTeamBuyingButton } from "app/modules/commerce/components/productShow/layout/productSummary/components/right/purchaseGloveBox/components/buttons/teamBuying";

import { useOpenPurchasePreparationDialogWithBuyNow } from "common/components/dialogs/purchasePreparationDialog/hooks/useOpen";
import useGroupTexts from "common/hooks/useGroupTexts";

const Wrapper = styled.div`
  width: 100%;
  padding: ${px2rem(4)} 0;
`;

interface IProps {
  block: Moim.Component.ProductItem.ITeamBuyingButton;
  product: Moim.Commerce.IProduct;
  teamBuyingId: Moim.Id;
  className?: string;
  onSelect?(): void;
}

const TeamBuyingButton = ({
  className,
  teamBuyingId,
  product,
  onSelect,
}: IProps) => {
  const buttonTexts = useGroupTexts("button_buy_team");
  const openPurchasePreparationDialog = useOpenPurchasePreparationDialogWithBuyNow(
    product.id,
  );
  const productStatus = product.status;
  const stockCount = product.stockCount;

  const handleClickBuyNow = React.useCallback(
    e => {
      e.stopPropagation();
      e.preventDefault();

      openPurchasePreparationDialog({
        teamBuying: {
          enterFrom: "team",
          teamBuyingId,
        },
      });

      onSelect?.();
    },
    [openPurchasePreparationDialog, teamBuyingId, onSelect],
  );

  return (
    <Wrapper key="button_buy_now_teamBuying" className={className}>
      <ProductItemTeamBuyingButton
        buttonSize="s"
        productStatus={productStatus}
        productStockCount={stockCount}
        alternateButtonText={buttonTexts?.singular}
        onClick={handleClickBuyNow}
      />
    </Wrapper>
  );
};

export default React.memo(TeamBuyingButton);
