import React from "react";
import { useCurrentUserLocale } from "common/hooks/localization/useCurrentUserLocale";
import { getLocaleTextShort } from "common/hooks/localization/useLocaleText";
import { IOption } from "common/components/designSystem/selection/type";

export const useFormItemOptions = (
  options: PickValue<Moim.Form.IFormItem, "options">,
): IOption[] => {
  const locale = useCurrentUserLocale();

  return React.useMemo(
    () =>
      options?.map(option => ({
        label: getLocaleTextShort(option, "label", locale),
        value: option.label,
      })) ?? [],
    [options, locale],
  );
};
