import { useStoreState } from "../../../../store";
import { ThreadItem } from "../commonThreadItem";
import useChannelFetchSelector from "common/hooks/useEntitiesFetchSelectors/useChannelFetchSelector";
import useCoinFetchSelector from "common/hooks/useEntitiesFetchSelectors/useCoinFetchSelector";
import useGroupFetchSelector from "common/hooks/useEntitiesFetchSelectors/useGroupFetchSelector";
import usePositionFetchSelector from "common/hooks/useEntitiesFetchSelectors/usePositionFetchSelector";
import useGetTagsFromThread from "common/hooks/useGetTagsFromThread";
import React from "react";

export const Magazine: React.FC<{
  thread: Moim.Forum.IThread;
  config: Partial<Moim.Thread.IThreadItemConfig>;

  className?: string;
  stat?: Moim.Thread.IThreadItemStatProps;
  titlePrefix?: React.ReactNode;
  disableAnonymousSuffix?: boolean;
}> = ({
  thread,
  config,
  className,
  disableAnonymousSuffix,
  stat,
  titlePrefix,
}) => {
  const { tags } = useGetTagsFromThread(thread);
  const author = useStoreState((state) => state.entities.users[thread.author]);
  const { positions } = usePositionFetchSelector(author?.positions ?? []);
  const { coins } = useCoinFetchSelector(
    thread.unlockMethods?.map((method) => method.resourceId) ?? [],
  );
  const { channels } = useChannelFetchSelector(
    thread.root_id ? [thread.root_id] : [],
  );
  const { groups } = useGroupFetchSelector(
    thread.groupId ? [thread.groupId] : [],
  );

  return (
    <ThreadItem.MagazineItem
      thread={thread}
      config={config}
      className={className}
      stat={stat}
    >
      <ThreadItem.MagazineItem.Title prefix={titlePrefix} />
      <ThreadItem.MagazineItem.Description />
      <ThreadItem.MagazineItem.UnlockMethods coins={coins} />
      <ThreadItem.MagazineItem.DividingSpace />
      <ThreadItem.MagazineItem.Tags tags={tags} />
      <ThreadItem.MagazineItem.Engage
        disableAnonymousSuffix={disableAnonymousSuffix}
        positions={positions}
        author={author}
        channel={channels[0]}
        moim={groups[0]}
      />
    </ThreadItem.MagazineItem>
  );
};
