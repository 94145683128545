// components
import { InvisibleText } from "./styledComponents";
import { useSnackbar } from "common/components/alertTemplates/alerts/globalSnackbar/useGlobalSnackbar";
// helper
import { MEDIA_QUERY } from "common/constants/responsive";
import { useIntlShort } from "common/hooks/useIntlShort";
import useMedia from "common/hooks/useMedia";
import * as React from "react";
import Clipboard from "react-clipboard.js";
import { FormattedMessage } from "react-intl";

interface IProps {
  displayText:
    | React.ReactNode
    | ((
        handler?: (e?: React.MouseEvent<HTMLElement>) => void,
      ) => React.ReactNode);
  shareData: ShareData;
  displayTextWrapper?: React.ElementType;
  clipboardComponent?: string;
  successMessageKey?: string;
  failMessageKey?: string;
  afterShare?(): void;
}

/**
 * @description opens native share module via navigator.share(Web Share API) on mobile. If impossible, copies the share data to clipboard.
 */
const ShareBase: React.FC<IProps> = ({
  displayText,
  shareData,
  displayTextWrapper,
  clipboardComponent = "span",
  afterShare,
  successMessageKey = "copy_link_success_toast_message",
  failMessageKey = "copy_link_failure_toast_message",
}) => {
  const intl = useIntlShort();
  const { open: openSuccess } = useSnackbar({
    textElement: <FormattedMessage id={successMessageKey} />,
    type: "success",
  });
  const { open: openFail } = useSnackbar({
    textElement: <FormattedMessage id={failMessageKey} />,
    type: "error",
  });
  const isMobile = Boolean(
    useMedia([MEDIA_QUERY.EXCEPT_DESKTOP], [true], false),
  );
  const canShareAPI = React.useMemo(
    () => Boolean((window.navigator as any).share),
    [],
  );

  const handleShareClick: React.MouseEventHandler<HTMLSpanElement> =
    React.useCallback(
      async (e) => {
        e.stopPropagation();
        const shareText = shareData.text
          ? `${shareData.text}\n${shareData.url}`
          : shareData.url;
        try {
          await (window.navigator as any).share({
            text: shareText,
          });
        } finally {
          afterShare?.();
        }
      },
      [shareData, afterShare],
    );

  const handleCopySuccess = React.useCallback(() => {
    openSuccess();
    afterShare?.();
  }, [openSuccess, intl, afterShare]);

  const handleCopyFail = React.useCallback(() => {
    openFail();
    afterShare?.();
  }, [openFail, intl, afterShare]);

  const getTarget = React.useCallback(
    (e: Element) => e.nextElementSibling as Element,
    [],
  );

  const displayElement: React.ReactNode = React.useMemo(() => {
    if (typeof displayText === "function") return displayText(handleShareClick);
    if (displayTextWrapper) {
      return React.createElement(
        displayTextWrapper,
        { onClick: handleShareClick, role: "button" },
        [displayText],
      );
    } else {
      return (
        <span onClick={handleShareClick} role="button">
          {displayText}
        </span>
      );
    }
  }, [displayText, displayTextWrapper, handleShareClick]);

  return (
    <>
      {isMobile && canShareAPI ? (
        displayElement
      ) : (
        <>
          <Clipboard
            component={clipboardComponent}
            options={{
              target: getTarget,
            }}
            onSuccess={handleCopySuccess}
            onError={handleCopyFail}
          >
            <>{displayElement}</>
          </Clipboard>
          <InvisibleText>
            {shareData.text}
            {"\n"}
            {shareData.url}
          </InvisibleText>
        </>
      )}
    </>
  );
};

export default ShareBase;
