import React, { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useIntlShort } from "common/hooks/useIntlShort";
import convertCamel2Snake from "../convertCamel2Snake";

export const usePaymentStatusDisplay = (
  status: Moim.Commerce.DisplayingPurchaseStatusType,
  options?: {
    type?: "pickUp" | "delivery" | "noDelivery";
    shippingRequired?: boolean;
    isBlockchainCurrency?: boolean;
    isTeamBuying?: boolean;
  },
) => {
  const textVariants = useMemo(() => {
    let prefix = "";
    let suffix = "";

    switch (status) {
      case "submitted":
        prefix = "order_submitted";
        break;
      case "readyForPayment":
        prefix = "order_ready_for_payment";
        break;
      case "readyForConfirmation":
        prefix = "order_ready_for_confirmation";
        break;
      case "paid":
        prefix = options?.isTeamBuying ? "paid_team_buying" : "paid";
        break;
      case "preparingForDelivery":
        prefix = "preparingForDelivery";
        if (options?.shippingRequired === false) {
          suffix = "_without_shipping";
        }
        break;
      case "paymentError":
        prefix = "paymentError";
        break;

      case "waitingForDevlieryReception":
        prefix = "waiting_for_delivery_reception";
        break;
      default:
        prefix = convertCamel2Snake(status);
        break;
    }

    return `purchase_item_status_${prefix}${suffix}`;
  }, [options, status]);

  const intl = useIntlShort();
  const text = intl(textVariants as Moim.Group.GroupTextSetKey);

  if (status === "vbankPending" && options?.isBlockchainCurrency) {
    return <FormattedMessage id="status_crypto_transaction_unconfirmed" />;
  }

  return text;
};
