import styled, { css } from "styled-components";
import { px2rem } from "common/helpers/rem";

export const Wrapper = styled.div<{
  selected: boolean;
}>`
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  height: ${px2rem(42)};
  margin-left: ${px2rem(12)};

  transition: background-color 200ms ease-in;
  border-radius: ${px2rem(2)};
  position: relative;
  ${props =>
    props.selected &&
    css`
      background-color: ${props.theme.getTopAreaElementPalette("background")
        .fog50};
    `};
  &:hover {
    background-color: ${props =>
      props.theme.getTopAreaElementPalette("background").fog50};
  }

  > * {
    margin: 0 ${px2rem(8)};
  }
`;
