import React from "react";
import { useIntlShort } from "common/hooks/useIntlShort";
import { MoimURL } from "common/helpers/url";
import CommerceCategoryItem from "./CommerceCategoryItem";
import {
  AccordionHeader,
  ArrowDownIcon,
  CategoryThirdDepsWrapper,
  CollapseIconButton,
  SecondCategoryTitle,
} from "./styled";

interface Props {
  category: Moim.Commerce.ICategory;
  hasChildCategory?: boolean;
  onClose?: () => void;
}

const CommerceCategoryAccordion = ({ category, onClose }: Props) => {
  const intl = useIntlShort();

  const [open, toggle] = React.useReducer(state => !state, false);
  if (!(category?.items && category?.items.length > 0)) {
    return (
      <SecondCategoryTitle
        to={new MoimURL.CommerceCategories({
          id: category.id,
          section: "products",
        }).toString()}
      >
        {category.name}
      </SecondCategoryTitle>
    );
  }
  const categoryAll = {
    ...category,
    name: intl("menu_all"),
  };

  return (
    <div>
      <AccordionHeader expanded={open} onClick={toggle}>
        {category.name}
        <CollapseIconButton open={open}>
          <ArrowDownIcon />
        </CollapseIconButton>
      </AccordionHeader>
      {open && (
        <CategoryThirdDepsWrapper>
          <CommerceCategoryItem
            clickable={true}
            onClose={onClose}
            key={categoryAll.id}
            category={categoryAll}
          />
          {category.items.map(childCategory => {
            const clickable = Boolean(
              childCategory.parentId.split("|").length === 3 ||
                childCategory.items.length === 0,
            );
            return (
              <CommerceCategoryItem
                clickable={clickable}
                onClose={onClose}
                key={childCategory.id}
                category={childCategory}
              />
            );
          })}
        </CategoryThirdDepsWrapper>
      )}
    </div>
  );
};

export default CommerceCategoryAccordion;
