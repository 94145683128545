import { PurchasePreparationDialogTypes } from "app/actions/types";
import { ActionUnion } from "app/actions/helpers";
import { IPurchasePreparationDialogPayload } from "./reducer";

function createAction<T extends { type: PurchasePreparationDialogTypes }>(
  d: T,
): T {
  return d;
}

export const ActionCreators = {
  open: (payload: IPurchasePreparationDialogPayload) =>
    createAction({
      type: PurchasePreparationDialogTypes.OPEN,
      payload,
    }),

  close: () =>
    createAction({
      type: PurchasePreparationDialogTypes.CLOSE,
    }),

  updatePurchaseReadyItems: (
    purchaseReadyItems: Moim.Commerce.IPurchaseReadyItem[],
  ) =>
    createAction({
      type: PurchasePreparationDialogTypes.UPDATE_PURCHASE_READY_ITEMS,
      payload: {
        purchaseReadyItems,
      },
    }),
};

export type Actions = ActionUnion<typeof ActionCreators>;
