import * as React from "react";
import useOpenState from "common/hooks/useOpenState";
import UserProfileImage from "common/components/userProfileImage";
import GuestProfileMenu from "../../../../components/guestProfileMenu";
import { Wrapper } from "./styled";

const GuestUserProfile: React.FC = ({}) => {
  const targetRef = React.useRef<HTMLDivElement>(null);
  const { isOpen, open, close } = useOpenState();

  return (
    <>
      <Wrapper
        ref={targetRef}
        role="button"
        aria-label="guest profile menu entry"
        selected={isOpen}
        onClick={open}
      >
        <UserProfileImage
          size="s"
          elementPaletteProps={{ type: "topArea", key: "others" }}
        />
      </Wrapper>
      <GuestProfileMenu
        open={isOpen}
        anchorElement={targetRef.current}
        onCloseRequest={close}
        onClickMenuButton={close}
      />
    </>
  );
};

export default GuestUserProfile;
