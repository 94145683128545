import { MoimURL } from "common/helpers/url";

export default function channelToUrl(
  channel: Moim.Channel.SimpleChannelType,
): string | undefined {
  switch (channel.type) {
    case "forum": {
      return new MoimURL.Forum({
        forumId: channel.id || "",
      }).toString();
    }

    case "conversation": {
      return new MoimURL.ConversationShow({
        conversationId: channel.id || "",
      }).toString();
    }

    case "subgroups":
    case "tag": {
      return new MoimURL.SubMoimList({
        tag: channel.id,
      }).toString();
    }

    case "view": {
      return new MoimURL.ViewShow({
        viewId: channel.id,
      }).toString();
    }

    case "link": {
      if (channel.url.startsWith("/")) {
        return channel.url;
      }

      const targetUrl = new URL(channel.url);
      if (targetUrl.hostname === location.hostname) {
        return channel.url;
      }

      return;
    }

    default: {
      return;
    }
  }
}
