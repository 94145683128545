//* Reference docs: https://hgoebl.github.io/mobile-detect.js/doc/MobileDetect.html */
import MobileDetect from "mobile-detect";
import memoize from "lodash/memoize";
import * as EnvChecker from "app/common/helpers/envChecker";
import { getInitialData } from "./initialData";

export const enum OS_TYPE {
  AndroidOS = "AndroidOS",
  BlackBerry = "BlackBerryOS",
  PalmOS = "PalmOS",
  SymbianOS = "SymbianOS",
  WindowsMobileOS = "WindowsMobileOS",
  WindowsPhoneOS = "WindowsPhoneOS",
  iOS = "iOS",
  iPadOS = "iPadOS",
  MeeGoOS = "MeeGoOS",
  MaemoOS = "MaemoOS",
  JavaOS = "JavaOS",
  webOS = "webOS",
  badaOS = "badaOS",
  BREWOS = "BREWOS",
}

const isNewIpad = EnvChecker.isServer()
  ? false
  : Boolean(
      window.navigator.userAgent.match(/Mac/) &&
        window.navigator.maxTouchPoints &&
        window.navigator.maxTouchPoints > 2,
    );

const instance = memoize(() => {
  return new MobileDetect(
    EnvChecker.isServer()
      ? getInitialData("userAgent", "")
      : window.navigator.userAgent,
  );
});

export const isApple = memoize(() => {
  const detect = instance();
  switch (detect.os()) {
    case OS_TYPE.iPadOS:
    case OS_TYPE.iOS: {
      return true;
    }
    default: {
      return false;
    }
  }
});

export const isiOS = memoize(() => {
  const detect = instance();
  switch (detect.os()) {
    case OS_TYPE.iOS: {
      return true;
    }
    default: {
      return false;
    }
  }
});

export const isAndroid = memoize(() => {
  const detect = instance();
  switch (detect.os()) {
    case OS_TYPE.AndroidOS: {
      return true;
    }
    default: {
      return false;
    }
  }
});

export const isMobileAgent = memoize(() => {
  const detect = instance();
  return Boolean(detect.phone() || detect.mobile());
});

export const isTablet = memoize(() => {
  const detect = instance();
  return Boolean(detect.tablet()) || isNewIpad;
});

export const getSafariVersion = memoize(() => {
  const detect = instance();
  return detect.version("Safari");
});

export const isMoimFlutterApp = memoize(() => {
  const detect = instance();
  return detect.match("moimApp");
});
