import * as React from "react";
import { FormattedMessage } from "react-intl";
import MissionHead from "./components/missionHead";
import MissionGroup from "./components/missionGroup";
import {
  BlockVeil,
  Body,
  Bottom,
  Container,
  LockIcon,
  Mid,
  RetryIcon,
  VerifyAllButton,
} from "./styled";

export type ThemeType = "black" | "white";

interface IProps {
  isLoading: boolean;
  isLoadingHistory: boolean;
  isJoinLoading: boolean;
  isAchieveLoading: boolean;
  quest: Moim.DQuest.INormalizedQuest;
  history?: Moim.DQuest.IHistory;
  selectedTheme?: ThemeType;
  hasJoinablePermission?: boolean;
  onClickJoin(): void;
  onClickVerifyAll(): void;
  onClickMission(missionId: Moim.Id): void;
  onClickAchieve(): void;
}

// history 갱신에 대한 로딩 모습 추가 필요.
const DQuestMissionaryComponent: React.FC<IProps> = ({
  isLoadingHistory,
  isJoinLoading,
  isAchieveLoading,
  quest,
  history,
  selectedTheme = "black",
  hasJoinablePermission,
  onClickJoin,
  onClickVerifyAll,
  onClickMission,
  onClickAchieve,
}) => {
  const canPlayQuest = React.useMemo(() => {
    if (quest.displayStatus === "ACTIVE") {
      if (quest.joinType === "EXPLICIT") {
        return Boolean(history);
      }
      return true;
    }
    return false;
  }, [history, quest.displayStatus, quest.joinType]);

  return (
    <Container>
      <MissionHead
        isJoinLoading={isJoinLoading}
        isAchieveLoading={isAchieveLoading}
        quest={quest}
        onClickJoin={onClickJoin}
        onClickAchieve={onClickAchieve}
        selectedTheme={selectedTheme}
        hasJoinablePermission={hasJoinablePermission}
        history={history}
      />
      <Body canPlayQuest={canPlayQuest} selectedTheme={selectedTheme}>
        <div className="container">
          <Mid>
            <MissionGroup
              isLoading={isLoadingHistory}
              missions={quest.mission}
              history={history}
              selectedTheme={selectedTheme}
              onClickMission={onClickMission}
            />
          </Mid>
          <Bottom selectedTheme={selectedTheme}>
            <VerifyAllButton
              isLoading={isLoadingHistory}
              selectedTheme={selectedTheme}
              disabled={isLoadingHistory}
              onClick={onClickVerifyAll}
            >
              <FormattedMessage id="button_quest_verify_all" />
              <RetryIcon selectedTheme={selectedTheme} />
            </VerifyAllButton>
          </Bottom>
          <BlockVeil>
            <LockIcon />
          </BlockVeil>
        </div>
      </Body>
    </Container>
  );
};

export default DQuestMissionaryComponent;
