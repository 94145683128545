// zeplin https://app.zeplin.io/project/5db7ff7b7ef2d22c5191d3cb/screen/5f5c3692fac9074a33411477
import * as React from "react";
import { rgba } from "polished";
import styled, { FlattenInterpolation } from "styled-components";
import { px2rem } from "common/helpers/rem";
import { withPlacement } from "common/components/blockitEditorBase/components/blockitRenderer.v2/hoc/withPlacement";

type IProps = Omit<Moim.Blockit.V2.ILineBlock, "type"> & {
  wrapperStyle?: FlattenInterpolation<any>;
};

const Wrapper = styled.div<{
  overrideStyle?: FlattenInterpolation<any>;
}>`
  width: 100%;
  overflow: hidden;
  background-color: ${props => props.theme.colorV2.colorSet.white1000};
  ${props => props.overrideStyle};
`;

const LineElement = styled.div<IProps>`
  position: relative;
  width: 100%;
  height: ${props => (props.height ? px2rem(props.height) : px2rem(1))};
  background-color: ${props =>
    rgba(
      props.theme.getColorByAlias(
        props.backgroundColor ?? props.theme.colorV2.primary.main,
      ) ?? "",
      1,
    )};

  &::after {
    content: "";
    position: absolute;
    width: ${props => `${props.fillWidth}%`};
    height: ${props => (props.height ? px2rem(props.height) : px2rem(1))};
    top: 0;
    left: 0;
    right: 0;
    background-color: ${props => props.theme.getColorByAlias(props.fillColor)};
  }
`;

const Line: React.FC<IProps> = ({ wrapperStyle, ...props }) => (
  <Wrapper overrideStyle={wrapperStyle}>
    <LineElement {...props} />
  </Wrapper>
);

export default withPlacement(Line);
