import * as React from "react";

import { DropdownIcon, MoreIconWrapper, CategoryName } from "./styled";
import { ItemWrapper, WithUnreadStatusBadgeWrapper } from "../styled";
import Popover, { PopoverInner } from "../popover";
import PopoverChannelItem from "../popoverChannelItem";
import ChannelUnreadCount from "../channelUnreadCount";

import useCurrentGroup from "common/hooks/useCurrentGroup";
import useMatchRoute from "common/hooks/useMatchRoute";
import { useStoreState } from "app/store";
import isSelectedChannel from "common/helpers/isSelectedChannel";
import {
  gethasUnreadFromChannelList,
  getTotalUnreadCountFromChannelList,
} from "../../helper";

interface IProps {
  category: Moim.Channel.ICategory;
  disableFoldIcon?: boolean;
  onClickChannel?(channel: Moim.Channel.SimpleChannelWithoutCategoryType): void;
}

export default function CategoryItem({
  category,
  disableFoldIcon,
  onClickChannel,
}: IProps) {
  const refThis = React.useRef<HTMLDivElement>(null);
  const [open, setOpen] = React.useState<boolean>(false);
  const currentGroup = useCurrentGroup();

  const { totalUnreadCount, hasUnread } = useStoreState(state => {
    const channels = category.items;
    const totalUnreadCount = getTotalUnreadCountFromChannelList(
      state,
      channels,
    );
    return {
      totalUnreadCount,
      hasUnread:
        totalUnreadCount === 0
          ? gethasUnreadFromChannelList(state, channels)
          : false,
    };
  });

  const selectedChannel = useMatchRoute();
  const isSelected = React.useMemo(
    () =>
      Boolean(
        category.items?.find(item =>
          isSelectedChannel(
            selectedChannel,
            item.type,
            item.id,
            currentGroup?.home.web.type === "channel"
              ? currentGroup?.home.web.ref
              : undefined,
          ),
        ),
      ),
    [category, selectedChannel],
  );

  const handleClickCategory = React.useCallback(() => {
    setOpen(true);
  }, [category]);

  const handleClose = React.useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleClickChannel = React.useCallback(
    (channel: Moim.Channel.SimpleChannelWithoutCategoryType) => {
      handleClose();
      onClickChannel?.(channel);
    },
    [handleClose, onClickChannel],
  );

  const channelListElement = React.useMemo(
    () =>
      category.items?.map(channel => {
        return (
          <PopoverChannelItem
            key={channel.id}
            channel={channel}
            onClick={handleClickChannel}
          />
        );
      }),
    [category.items, handleClickChannel],
  );

  const preventDefault: React.MouseEventHandler<HTMLAnchorElement> = React.useCallback(
    e => {
      e.preventDefault();
    },
    [],
  );

  if (!category.items?.length) {
    return null;
  }

  return (
    <>
      <ItemWrapper
        ref={refThis}
        isCategory={true}
        selected={isSelected}
        onClick={handleClickCategory}
      >
        <CategoryName>
          <WithUnreadStatusBadgeWrapper
            href="#"
            rel="nofollow"
            isUnread={hasUnread}
            onClick={preventDefault}
          >
            {category.name}
          </WithUnreadStatusBadgeWrapper>
        </CategoryName>
        <ChannelUnreadCount count={totalUnreadCount} />

        {disableFoldIcon ? null : (
          <MoreIconWrapper popoverOpen={open}>
            <DropdownIcon aria-label="category more button" />
          </MoreIconWrapper>
        )}
      </ItemWrapper>
      <Popover
        open={open}
        anchorEl={refThis.current}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handleClose}
      >
        <PopoverInner>{channelListElement}</PopoverInner>
      </Popover>
    </>
  );
}
