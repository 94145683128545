import * as React from "react";
import { buffedBatchUsersWithDirect as buffedBatchUsersWithDirectAction } from "app/actions/user";
import { useStoreState, useActions } from "app/store";
import { userSelector, userListSelectorByIds } from "app/selectors/user";

/**
 * @Deprecated
 */
export default function useUserDataWithAutoBatch(
  userId?: Moim.Id,
  canId?: Moim.Id,
  canUsername?: string,
  userIds: Moim.Id[] = [],
) {
  const [batchCalled, setBatchCalled] = React.useState<boolean>(false);
  const [reqUserId, setReqUserId] = React.useState<Moim.Id>(
    userId ?? canId ?? canUsername ?? "",
  );
  const [, setReqUserIds] = React.useState<Moim.Id[]>(userIds);
  const { user, users } = useStoreState(state => ({
    user: userSelector(state, reqUserId),
    users: userListSelectorByIds(state, [...userIds]),
  }));

  const { buffedBatchUsersWithDirect } = useActions({
    buffedBatchUsersWithDirect: buffedBatchUsersWithDirectAction,
  });

  const batch = React.useCallback(() => {
    buffedBatchUsersWithDirect(userId, canId, canUsername, userIds).then(
      result => {
        if (result) {
          if (canId) {
            setReqUserId(result[canId]);
            return;
          }
          if (userId) {
            setReqUserId(result[userId]);
            return;
          }
          if (canUsername) {
            setReqUserId(result[canUsername]);
            return;
          }

          if (userIds.length) {
            setReqUserIds(_state => {
              const newArray = Array.from(
                new Set(_state.concat(Object.values(result))),
              );
              return newArray;
            });
          }
        }
      },
    );
  }, [buffedBatchUsersWithDirect, canId, canUsername, userId, userIds]);

  React.useEffect(() => {
    if (
      !batchCalled &&
      (user?.group_id === "empty" || users.find(_u => _u.group_id === "empty"))
    ) {
      setBatchCalled(true);
      batch();
    }
  }, [batchCalled, batch, user, users]);

  return { user, users };
}
