import { Svg24CloseB } from "@moim/icons";
import styled, { css } from "styled-components";


import { H8Bold } from "common/components/designSystem/typos";
import { px2rem } from "common/helpers/rem";
import { useScrollStyle } from "common/components/designSystem/styles";
import { MEDIA_QUERY } from "common/constants/responsive";

export const CloseButton = styled(Svg24CloseB).attrs({
  size: "m",
  touch: 24,
  role: "button",
})``;

export const DialogTitle = styled(H8Bold)`
  color: ${props => props.theme.colorV2.colorSet.grey800};
`;
export const BottomSheetTitle = styled(H8Bold)`
  color: ${props => props.theme.colorV2.colorSet.grey800};
  text-align: center;
  padding: ${px2rem(12)} 0;
`;

export const dialogContentStyle = css`
  display: flex;
  flex-direction: column;

  padding: 0 ${px2rem(40)} ${px2rem(40)};
`;

export const BottomSheetContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 ${px2rem(16)} ${px2rem(16)};
`;

export const DialogScrollSection = styled.div`
  flex: 1;
  min-width: 0;

  @media ${MEDIA_QUERY.EXCEPT_DESKTOP} {
    ${useScrollStyle}
  }
`;
