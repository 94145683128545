import { Svg18DoublearrowG3, Svg18DoublearrowG2, Svg18DoublearrowT, Svg18DoublearrowB } from "@moim/icons";
// vendor
import styled, { css } from "styled-components";

import { B3Regular } from "common/components/designSystem/typos";






// helper
import { px2rem } from "common/helpers/rem";

const SNACKBAR_MARGIN = 8;

export const UnreadSnackBarContent = styled(B3Regular)`
  padding: ${px2rem(7)} 0 ${px2rem(6)};
  color: ${props => props.theme.colorV2.colorSet.white1000};
  margin-left: ${px2rem(9)};
  white-space: nowrap;
`;

export const SnackBarWrapper = styled.div<{
  transitionDirection: "top" | "bottom";
  position?: number;
}>`
  position: absolute;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;

  z-index: ${props => props.theme.zIndexes.gnbSticky};
  transform: translateZ(0);
  will-change: transform;

  ${props => {
    switch (props.transitionDirection) {
      case "top":
        return css`
          top: ${px2rem((props.position ?? 0) + SNACKBAR_MARGIN)};
        `;

      case "bottom":
        return css`
          bottom: ${px2rem((props.position ?? 0) + SNACKBAR_MARGIN)};
        `;
    }
  }};
`;

export const TopUnreadIcon = styled(Svg18DoublearrowT).attrs({
  size: "xs",
  touch: 18,
})``;

export const BottomUnreadIcon = styled(Svg18DoublearrowB).attrs({
  size: "xs",
  touch: 18,
})``;

export const TopMentionIcon = styled(Svg18DoublearrowG3).attrs({
  size: "xs",
  touch: 18,
})``;

export const BottomMentionIcon = styled(Svg18DoublearrowG2).attrs({
  size: "xs",
  touch: 18,
})``;

export const snackbarStyle = css`
  position: relative !important;
`;
