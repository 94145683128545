import styled from "styled-components";
import LoadingIconBase from "common/components/loading/icon";
import { px2rem } from "common/helpers/rem";

export const LoadWrapper = styled.div`
  width: 100%;
  height: ${px2rem(42)};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Loading = styled(LoadingIconBase)``;

export const InfiniteScrollerWrapper = styled.div`
  width: 100%;
`;
