import React, { createContext } from "react";
import useMedia from "common/hooks/useMedia";
import { isMobileAgent } from "common/helpers/browserDetect";
import { MEDIA_QUERY } from "common/constants/responsive";
import { isServer } from "common/helpers/envChecker";
import MobileDetect from "mobile-detect";

interface IsMobileContextValue {
  isMobile: boolean;
}

const queries = [MEDIA_QUERY.ONLY_MOBILE];
const values = [true];

const initialValue: IsMobileContextValue = { isMobile: false };

const IsMobileContext = createContext<IsMobileContextValue>(initialValue);

export { IsMobileContext };

export function IsMobileProvider({
  children,
  ssrUserAgent,
}: React.PropsWithChildren<{ ssrUserAgent?: string }>) {
  const isMobile = useMedia(queries, values, false);
  const value = React.useMemo(() => {
    if (isServer() && ssrUserAgent) {
      const detect = new MobileDetect(ssrUserAgent);

      return {
        isMobile: Boolean(detect.phone() || detect.mobile()),
      };
    }

    return {
      isMobile: isMobile ?? isMobileAgent(),
    };
  }, [isMobile, ssrUserAgent]);

  return (
    <IsMobileContext.Provider value={value}>
      {children}
    </IsMobileContext.Provider>
  );
}
