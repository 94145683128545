import * as React from "react";

export default function useOpenState(defaultState: boolean = false) {
  const [isOpen, setIsOpen] = React.useState<boolean>(defaultState);

  const close = React.useCallback(() => {
    setIsOpen(false);
  }, []);

  const open = React.useCallback(() => {
    setIsOpen(true);
  }, []);

  return React.useMemo(
    () => ({
      isOpen,
      open,
      close,
      setIsOpen,
    }),
    [isOpen, open, close],
  );
}

export function useOpenStateWithHashRoute(
  hashKey: string,
  options?: {
    onHashDismiss?: () => void;
  },
) {
  const refKey = React.useRef(hashKey);
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  const close = React.useCallback(() => {
    history.back();
  }, []);

  const open = React.useCallback(() => {
    location.hash = refKey.current;
  }, []);

  const handleHashChange = React.useCallback((e: HashChangeEvent) => {
    if (new URL(e.newURL).hash === `#${refKey.current}`) {
      setIsOpen(true);
    }

    if (new URL(e.oldURL).hash === `#${refKey.current}`) {
      setIsOpen(false);
      options?.onHashDismiss?.();
    }
  }, []);

  React.useEffect(() => {
    if (location.hash === `#${refKey.current}`) {
      const replaceUrl = new URL(location.toString());
      replaceUrl.hash = "";

      location.replace(replaceUrl.toString());
    }

    // CDM
    window.addEventListener("hashchange", handleHashChange, { passive: true });
    return () => {
      setIsOpen(false);
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, []);

  return React.useMemo(
    () => ({
      isOpen,
      open,
      close,
      setIsOpen,
    }),
    [isOpen, open, close],
  );
}
