import React from "react";
import styled from "styled-components";
import { px2rem } from "common/helpers/rem";

import { Item } from "./item";
import { AddFileButton } from "./addFileButton";
import { useXScrollStyle } from "common/components/designSystem/styles";
import { MEDIA_QUERY } from "common/constants/responsive";

const Wrapper = styled.div`
  width: 100%;
  padding: ${px2rem(12)} 0;
  display: flex;
  gap: ${px2rem(8)};
  @media ${MEDIA_QUERY.ONLY_MOBILE} {
    ${useXScrollStyle}
  }

  @media ${MEDIA_QUERY.EXCEPT_MOBILE} {
    overflow-x: auto;
    &::-webkit-scrollbar {
      width: ${px2rem(8)};
    }
    &::-webkit-scrollbar:horizontal {
      height: ${px2rem(8)};
    }
    &::-webkit-scrollbar-track {
      background-color: ${props => props.theme.colorV2.colorSet.grey10};
    }
    &::-webkit-scrollbar-thumb {
      border-radius: ${px2rem(4)};
      background-color: ${props => props.theme.colorV2.colorSet.grey50};
    }
  }
`;

export const FileInput: React.FC<{
  fileIds: { id: Moim.Id; priority: number }[];
  files: Record<string, { fileId: Moim.Id; file: File }>;
  addFile(): void;
  setFiles: React.Dispatch<
    React.SetStateAction<
      Record<
        string,
        {
          fileId: Moim.Id;
          file: File;
        }
      >
    >
  >;
  setFileIds: React.Dispatch<
    React.SetStateAction<
      {
        id: Moim.Id;
        priority: number;
      }[]
    >
  >;
}> = ({ fileIds, files, addFile, setFiles, setFileIds }) => {
  const handleFileDeleteClick = React.useCallback(
    (fileId: Moim.Id) => {
      setFileIds(state => state.filter(file => file.id !== fileId));
      setFiles(state => {
        const newState = { ...state };
        delete newState[fileId];

        return newState;
      });
    },
    [setFileIds, setFiles],
  );

  return (
    <Wrapper data-body-scroll-lock-ignore="true">
      <AddFileButton addFile={addFile} />
      {fileIds.map((fileId, index) => {
        return (
          <Item
            key={index}
            file={files[fileId.id]?.file}
            fileId={fileId.id}
            onClickDelete={handleFileDeleteClick}
          />
        );
      })}
    </Wrapper>
  );
};
