/* eslint-disable no-underscore-dangle */
import * as React from "react";
import moment from "moment";
import { IntlProvider } from "react-intl";
import { connect } from "react-redux";
import loadable from "@loadable/component";
// Import locale setting packages
import { IAppState } from "app/rootReducer";
import { browserLocale as findLocale, SupportedLanguageType } from "./";
import { getInitialData } from "common/helpers/initialData";

interface IProps extends ReturnType<typeof mapStateToProps> {
  defaultLocale: SupportedLanguageType;
  browserLocale?: string;
  children: React.ReactNode;
  requestId?: string;
}

function mapStateToProps(state: IAppState) {
  return {
    currentLocale: state.app.locale,
    textKeyVisible: state.app.textKeyVisible,
    defaultLocale: state.entities.groups[state.app.currentGroupId ?? ""]?.config
      .defaultLocale as SupportedLanguageType,
    supportedLanguages:
      state.entities.groups[state.app.currentGroupId ?? ""]?.config.locales,
  };
}

const LoadableMessage = loadable.lib(async (props: { locale: string }) =>
  import(`./assets/${props.locale}.json`),
);

class IntlWrapper extends React.Component<IProps> {
  public componentDidMount() {
    this.setMomentLocale();
  }

  public componentDidUpdate(prevProps: IProps) {
    if (prevProps.currentLocale !== this.props.currentLocale) {
      this.setMomentLocale();
    }
  }
  public render() {
    const {
      children,
      textKeyVisible,
      currentLocale,
      defaultLocale,
      supportedLanguages,
    } = this.props;

    const locale = findLocale(currentLocale, defaultLocale, supportedLanguages);

    const groupTexts =
      getInitialData("groupTexts", this.props.requestId ?? "") ?? {};

    return (
      <LoadableMessage locale={locale}>
        {payload => (
          <IntlProvider
            locale={locale}
            messages={
              !textKeyVisible
                ? {
                    ...payload,
                    ...groupTexts,
                  }
                : {}
            }
          >
            {children}
          </IntlProvider>
        )}
      </LoadableMessage>
    );
  }

  private readonly setMomentLocale = () => {
    const locale = findLocale(
      this.props.currentLocale || this.props.browserLocale,
    );

    moment.locale(locale);
  };
}

export default connect(mapStateToProps)(IntlWrapper);
