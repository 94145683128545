import * as React from "react";
import { isEqual } from "lodash";
import { CSSTransition } from "react-transition-group";
import videojs from "video.js";
import {
  Wrapper,
  VideoContainer,
  VideoJsGlobalStyle,
  ToggleVeil,
  VolumeControl,
  CenterPlayButton,
} from "./styledComponents";
import MediaLazyTransitionWrapper from "../mediaWrapper";
import ErrorBoundary from "common/components/errorBoundary";

const VIDEO_OPTION = {
  preload: "auto",
  html5: {
    nativeControlsForTouch: false,
    nativeAudioTracks: false,
    nativeTextTracks: false,
    nativeVideoTracks: false,
    hls: {
      overrideNative: true,
    },
  },
};

export interface IProps
  extends React.DetailedHTMLProps<
    React.VideoHTMLAttributes<HTMLVideoElement>,
    HTMLVideoElement
  > {
  sources: any; // @TODO: Change to alright source
  staticRatio?: "unset" | string;
  height?: number;
  width?: number;
  poster?: string;
  fluid?: boolean;
  previewColor?: string;
  blurHash?: string;
  isAudio?: boolean;
  disableSkeleton?: boolean;
  disableMediaOpacity?: boolean;
  disableMuteButton?: boolean;
}

interface IState {
  isPlayed: boolean;
  pipButtonVisible: boolean;
  playStatus: boolean;
  muteStatus: boolean;
}

class RawHlsVideo extends React.PureComponent<IProps, IState> {
  public state: IState = {
    isPlayed: false,
    pipButtonVisible: this.props.isAudio ? false : true,
    playStatus: this.props.autoPlay ?? false,
    muteStatus: this.props.muted ?? true,
  };

  private player: ReturnType<typeof videojs> | null = null;

  public componentDidUpdate(prevProps: Readonly<IProps>): void {
    if (!isEqual(this.props.sources, prevProps.sources)) {
      this.player?.pause();
      this.player?.src(this.props.sources);
      this.player?.load();
    }

    if (this.props.muted !== prevProps.muted) {
      this.setState({
        muteStatus: this.props.muted ?? true,
      });
      this.player?.load();
    }

    if (this.props.poster !== prevProps.poster && this.props.poster) {
      this.player?.poster(this.props.poster);
    }

    if (this.props.controls !== prevProps.controls) {
      this.player?.controls(Boolean(this.props.controls));
    }

    if (this.props.autoPlay !== prevProps.autoPlay) {
      this.setState({
        playStatus: this.props.autoPlay ?? false,
      });

      if (this.props.autoPlay) {
        this.player?.play();
      } else {
        this.player?.pause();
      }
    }
  }

  public componentWillUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  }

  public render() {
    const {
      previewColor,
      blurHash,
      width,
      height,
      style,
      disableSkeleton,
      disableMediaOpacity,
    } = this.props;
    const sources = this.getSources();

    return (
      <ErrorBoundary>
        <VideoJsGlobalStyle />
        <Wrapper style={style}>
          {sources.length && width && height ? (
            <MediaLazyTransitionWrapper
              src={sources[0].src}
              type="video"
              width={width}
              height={height}
              previewColor={previewColor}
              blurHash={blurHash}
              disableSkeleton={disableSkeleton}
              disableMediaOpacity={disableMediaOpacity}
            >
              {this.renderVideo()}
            </MediaLazyTransitionWrapper>
          ) : (
            <div>{this.renderVideo()}</div>
          )}
          {!this.props.autoPlay && !this.state.isPlayed && (
            <CenterPlayButton
              playStatus={false}
              style={{ visibility: "visible" }}
              onClick={this.handleVeilClick}
            />
          )}
          {!this.props.controls && (
            <>
              <ToggleVeil onClick={this.handleVeilClick} />
              <CSSTransition
                in={this.state.playStatus}
                timeout={600}
                classNames="playButtonAnim"
              >
                <CenterPlayButton playStatus={!this.state.playStatus} />
              </CSSTransition>
              {!this.props.disableMuteButton && (
                <VolumeControl
                  className="vjs-icon-placeholder"
                  muted={this.state.muteStatus}
                  onClick={this.handleVolumeControlClick}
                />
              )}
            </>
          )}
        </Wrapper>
      </ErrorBoundary>
    );
  }

  private readonly getSources = () => {
    const sources = this.props.sources;
    return sources.filter((v: any): v is NonNullable<typeof v> => Boolean(v));
  };

  private readonly renderSourceNodes = () =>
    this.getSources().map((source: any) => (
      <source src={source.src} type={source.type} key={source.src} />
    ));

  private readonly renderVideo = () => {
    const { poster, fluid: _fluid, ref: _ref, className, muted } = this.props;
    return (
      <VideoContainer
        playsInline={true}
        className={`video-js vjs-default-skin ${className ? className : ""}`}
        controlsList="nodownload"
        ref={this.initialize}
        poster={poster}
        muted={muted}
      >
        {this.renderSourceNodes()}
      </VideoContainer>
    );
  };

  private readonly initialize = (node: HTMLVideoElement | null) => {
    if (node) {
      this.player = videojs(node, this.getPlayerOptions());

      this.player.one("play", () => {
        this.setState({ isPlayed: true });
      });

      this.player.on("play", () => {
        this.setState({
          playStatus: true,
        });
      });
      this.player.on("pause", () => {
        this.setState({
          playStatus: false,
        });
      });
      this.player.on("volumechange", e => {
        this.setState({
          muteStatus: e.target.player.muted(),
        });
      });
    }
  };

  private readonly getPlayerOptions = (): any => {
    const {
      autoPlay,
      muted,
      height,
      width,
      poster,
      fluid,
      controls,
      staticRatio,
      loop,
    } = this.props;
    return {
      ...VIDEO_OPTION,
      bigPlayButton: false,
      height,
      width,
      poster,
      playbackRates: [0.25, 0.5, 1, 1.5, 2],
      fluid: !!fluid,
      aspectRatio:
        staticRatio === "unset"
          ? undefined
          : staticRatio ?? (width && height)
          ? `${width}:${height}`
          : "16:9",
      controlBar: {
        seekToLive: false,
        liveDisplay: false,
        pictureInPictureToggle: false,
      },
      controls,
      autoplay: autoPlay,
      muted,
      playsinline: true,
      loop,
    } as any;
  };

  private readonly handleVeilClick = () => {
    if (this.player?.paused()) {
      this.player?.play();
    } else {
      this.player?.pause();
    }
  };

  private readonly handleVolumeControlClick = () => {
    this.player?.muted(!this.player?.muted());
  };
}
export default RawHlsVideo;
