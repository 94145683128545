import styled from "styled-components";

import { Wrapper } from "../styled";
import { DefaultBoldDivider } from "common/components/divider";

export const StyledWrapper = styled(Wrapper)`
  padding: 0;
`;

export const FormList = styled.div`
  width: 100%;
  height: fit-content;
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const BoldDivider = styled(DefaultBoldDivider)`
  &::before {
    background-color: ${props => props.theme.colorV2.colorSet.grey10};
    border-top: 1px solid ${props => props.theme.colorV2.colorSet.grey10};
  }
`;
