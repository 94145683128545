export const formatCurrency = (
  currencyRule: Moim.Commerce.CurrencyFormatType = {
    format: "#!",
    decimal: ".",
    separator: ",",
    precision: undefined,
    symbol: "",
    currency: "",
  },
  value: number,
  currency?: string,
) => {
  const symbol = (currencyRule.symbol || currency) ?? "";

  const absValue = Math.abs(value);

  const [abs, decimal] = String(absValue)?.split(".");

  const decimalNum =
    currencyRule.precision !== undefined
      ? new Array(currencyRule.precision)
          .fill(0)
          .map(
            (defaultValue, index) =>
              Array.from(decimal ?? "")?.[index] ?? defaultValue,
          )
          .join("")
      : decimal;

  const allCommasRegex = new RegExp(",", "g");
  const absoluteNum = new Intl.NumberFormat(["us", "ko", "ja"], {
    useGrouping: true,
  })
    .format(Number(abs))
    .replace(allCommasRegex, currencyRule?.separator ?? ",");

  const groupedValue = `${absoluteNum}${
    decimalNum ? `${currencyRule?.decimal ?? "."}${decimalNum}` : ""
  }`;

  const formattedValue = currencyRule.format
    .replace("!", symbol)
    .replace("#", groupedValue);

  return {
    formattedValue,
    groupedValue,
    symbol,
  };
};

export const formatClassifiedCurrency = (
  currencyRule: Moim.Commerce.CurrencyFormatType = {
    format: "#!",
    decimal: ".",
    separator: ",",
    precision: undefined,
    symbol: "",
    currency: "",
  },
  value: number,
  currency?: string,
) => {
  if (value === 0) {
    return {
      groupedValue: "0",
      formattedValue: currencyRule.format
        .replace("!", (currencyRule.symbol || currency) ?? "")
        .replace("#", "0"),
      symbol: currency ?? currencyRule.symbol,
    };
  }

  const { formattedValue, groupedValue, symbol } = formatCurrency(
    currencyRule,
    value,
    currency,
  );

  if (value < 0) {
    return {
      formattedValue: `-${formattedValue}`,
      groupedValue: `-${groupedValue}`,
      symbol,
    };
  }

  return {
    formattedValue,
    groupedValue,
    symbol,
  };
};
