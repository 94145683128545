import { B2RegularStyle } from "common/components/designSystem/typos";
import { ThreadDescriptionWrapper } from "common/components/thread/commonThreadItem/components/common/wrapper/description";
import { useThreadItemContext } from "common/components/thread/commonThreadItem/context";
import { textAlignStyle } from "common/components/thread/styles";
import { px2rem } from "common/helpers/rem";
import { useIntlShort } from "common/hooks/useIntlShort";
import * as React from "react";
import TruncateMarkup from "react-truncate-markup";
import styled from "styled-components";

const ContentsWrapper = styled(ThreadDescriptionWrapper)<{
  textAlign?: Moim.Forum.ForumListConfigTextAlignment;
}>`
  ${textAlignStyle};
  ${B2RegularStyle};
  width: 100%;
  padding: ${px2rem(4)} ${px2rem(16)};
  color: ${(props) => props.theme.colorV2.colorSet.grey800};
`;

const EllipsisText = styled.span`
  color: ${(props) => props.theme.colorV2.colorSet.grey300};
`;

function Contents() {
  const intl = useIntlShort();
  const {
    line = 5,
    showText,
    isUnread,
    textAlign,
    descriptionPlain,
  } = useThreadItemContext((state) => ({
    showText: state.config.showText,
    line: state.config.textMaxLine,
    descriptionPlain: state.item.preview?.descriptionPlain,
    isUnread: state.stat?.isUnread,
    textAlign: state.config.textAlignment,
  }));

  const renderEllipsis = React.useCallback(() => {
    return (
      <span>
        ... <EllipsisText>{intl("shaved_more")}</EllipsisText>
      </span>
    );
  }, []);

  if (!showText || !descriptionPlain) {
    return null;
  }
  return (
    <ContentsWrapper
      isUnread={isUnread}
      descriptionPlain={descriptionPlain}
      textAlign={textAlign}
    >
      {(value) => (
        <TruncateMarkup lines={5} ellipsis={renderEllipsis}>
          <div style={{ width: "100%" }}>{value}</div>
        </TruncateMarkup>
      )}
    </ContentsWrapper>
  );
}

export default React.memo(Contents);
