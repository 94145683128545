import * as React from "react";
import loadable, { Options } from "@loadable/component";
import LoadingIcon from "common/components/loading/icon";

export function VingleLoadable<T = any>(
  component: () => Promise<any>,
  options?: Options<T>,
) {
  return loadable<T>(component, {
    fallback: <LoadingIcon />,
    ...options,
  });
}

export class VingleError extends Error {}
