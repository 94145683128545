import { Svg18CloseG } from "@moim/icons";
import { ThemeMode } from "app/enums";
import { rgba } from "polished";
import styled, { css } from "styled-components";
import { H6Bold, PB3Regular } from "common/components/designSystem/typos";
import { px2rem } from "common/helpers/rem";
import * as BaseStyled from "../../styled";

export const Title = styled(H6Bold)`
  width: 100%;
  flex: 1;
  min-width: 0;
  color: ${props => props.theme.colorV2.colorSet.grey800};
`;
export const Description = styled(PB3Regular)<{ textColor?: string }>`
  color: rgba(1, 5, 5, 0.66);
  white-space: pre-line;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const TopArea = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  * + ${Title} {
    margin-left: ${px2rem(6)};
  }
  * + ${ButtonWrapper} {
    margin-left: ${px2rem(2)};
  }
`;
export const BodyArea = styled.div`
  width: 100%;
`;
export const FooterArea = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const CloseIcon = styled(Svg18CloseG).attrs({
  iconColor: rgba(1, 5, 5, 0.86),
  size: "xs",
  touch: 30,
})``;

export const Wrapper = styled(BaseStyled.Wrapper)`
  flex-direction: column;
  ${TopArea} + ${BodyArea} {
    margin-top: ${px2rem(4)};
  }

  border-top: ${px2rem(2)} solid
    ${props => props.bgColor ?? (props.theme.themeMode.mode === ThemeMode.DARK ? props.theme.colorV2.colorSet.grey1000 : props.theme.colorV2.colorSet.grey800)};
`;

export const SnackbarIconClickWrapper = styled(BaseStyled.SnackbarIconClickWrapper)`
  margin-left: initial;
`;

export const Layer = styled(BaseStyled.Layer)<Pick<Moim.Snackbar.IFlagProps, "subType">>`
  ${props => {
    switch (props.subType) {
      case "normal":
        return css`
          ${Wrapper} {
            background-color: rgba(1, 5, 5, 0.06);
            border-top-color: rgba(1, 5, 5, 0.86);
          }
        `;
      case "success":
        return css`
          ${Wrapper} {
            background-color: ${props.theme.color.darkBlue100};
            border-top-color: ${props.theme.color.darkBlue};
          }
        `;
      case "warning":
        return css`
          ${Wrapper} {
            background-color: ${rgba(props.theme.color.yellow, 0.06)};
            border-top-color: ${props.theme.color.yellow};
          }
        `;
      case "error":
        return css`
          ${Wrapper} {
            background-color: ${props.theme.color.red100};
            border-top-color: ${props.theme.color.red};
          }
        `;
    }
  }}
`;
