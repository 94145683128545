import * as React from "react";
import { FormattedMessage } from "react-intl";
import { useLocation } from "react-router";
import { PermissionDeniedFallbackType } from "app/enums";
import { MoimURL } from "common/helpers/url";
import useRedirect from "common/hooks/useRedirect";
import useGroupTexts from "common/hooks/useGroupTexts";
import { DefaultLayout } from "app/modules/secondaryView/native/layout";
import RoutedMoimTab, { IRoutedTab } from "common/components/tab/routed";

import UnsignedChecker from "common/components/unsiginedChecker";
import MyQuestsComponent from "./component";
import {
  AppBarStickyWrapperStyle,
  DefaultLayoutBodyStyle,
  ParallaxTitle,
  ParallaxWrapper,
  RootLayoutBodyStyle,
  RoutedTapContainerStyle,
  ViewAllButtonContainer,
  ViewAllTextButton,
} from "./styled";

export default function MyQuestContainer() {
  const mainRedirect = useRedirect();
  const location = useLocation();
  const myQuestTexts = useGroupTexts("my_quest");
  const inActiveTexts = useGroupTexts("my_quest_ongoing");
  const achievedTexts = useGroupTexts("my_quest_finished");
  const viewAllTexts = useGroupTexts("my_quest_view_all");

  const enableViewAllButton = React.useMemo(
    () => MoimURL.MyQuestList.isSame(location.pathname),
    [],
  );

  const handleClickViewAll = React.useCallback(() => {
    mainRedirect(new MoimURL.QuestList().toString());
    close();
  }, [mainRedirect, close]);

  const tabs: IRoutedTab[] = React.useMemo(
    () => [
      {
        key: "myquests-inactive",
        url: [MoimURL.MyQuestList, MoimURL.MyQuestListInActive],
        title: inActiveTexts?.singular ?? (
          <FormattedMessage id="my_quest_ongoing" />
        ),
        page: () => <MyQuestsComponent type="IN_PROGRESS" />,
        default: true,
      },
      {
        key: "myquests-achieved",
        url: MoimURL.MyQuestListAchieved,
        title: achievedTexts?.singular ?? (
          <FormattedMessage id="my_quest_finished" />
        ),
        page: () => <MyQuestsComponent type="ACHIEVED" />,
      },
    ],
    [achievedTexts, inActiveTexts],
  );

  React.useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <DefaultLayout
      appBar={{
        wrapperStickyStyle: AppBarStickyWrapperStyle,
        enableScrollParallax: true,
        parallaxWrapperComponent: ParallaxWrapper,
        titleContainerDisappearPosition: 20,
        parallaxDisappearPosition: 110,
        titleElement: myQuestTexts ? (
          <span>{myQuestTexts.singular}</span>
        ) : (
          <FormattedMessage id="my_quest" />
        ),
        expendScrollParallaxElement: (
          <ParallaxTitle>
            {myQuestTexts ? (
              <span>{myQuestTexts.singular}</span>
            ) : (
              <FormattedMessage id="my_shopping" />
            )}
          </ParallaxTitle>
        ),
      }}
      rootOverrideStyle={RootLayoutBodyStyle}
      bodyOverrideStyle={DefaultLayoutBodyStyle}
    >
      <UnsignedChecker fallbackType={PermissionDeniedFallbackType.SCREEN}>
        <RoutedMoimTab
          tabs={tabs}
          stickyData={{ topPosition: 45 }}
          routedTabContainerStyle={RoutedTapContainerStyle}
        />
      </UnsignedChecker>
      {enableViewAllButton ? (
        <ViewAllButtonContainer onClick={handleClickViewAll}>
          <ViewAllTextButton>
            {viewAllTexts ? (
              viewAllTexts.singular
            ) : (
              <FormattedMessage id="my_quest_view_all" />
            )}
          </ViewAllTextButton>
        </ViewAllButtonContainer>
      ) : null}
    </DefaultLayout>
  );
}
