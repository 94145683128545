import React from "react";
import { SingleDropdownFormItem } from "./displayType/singleDropdown";
import { SingleToggleButtonGroupFormItem } from "./displayType/toggleButtonGroup/single";
import { RadioFormItem } from "./displayType/radioButton";

export interface IRef {
  validate(): Record<Moim.Id, any>;
}
export const SingleSelectionFormItem = React.forwardRef<
  IRef,
  { item: Moim.Form.IFormItem }
>(({ item }, ref) => {
  switch (item.displayType) {
    case "radioButton":
      return <RadioFormItem ref={ref} item={item} />;

    case "toggleButtonGroup":
      return <SingleToggleButtonGroupFormItem ref={ref} item={item} />;
    case "dropdown":
      return <SingleDropdownFormItem ref={ref} item={item} />;

    default:
      return null;
  }
});
