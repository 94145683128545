import * as React from "react";
import { ThunkResult, useActions, useStoreState } from "app/store";
import { RecordOfLoadEntities, loadEntitiesDirect } from "app/actions/entity";
import isEqual from "lodash/isEqual";
import { usePrevious } from "react-use";

function checkIsFulfilledAction(
  requestEntities: RecordOfLoadEntities,
): ThunkResult<boolean> {
  return (_, getState) => {
    let returnFlag = true;
    const state = getState();
    const storedEntities = Object.entries(requestEntities).reduce(
      (acc, [key, ids]) => {
        const targetEntities = state.entities[key];
        const mappedData = ids.map(id => targetEntities[id]);

        acc[key] = mappedData;
        return acc;
      },
      {} as Record<Moim.Entity.NormalizedKey, any>,
    );

    const filteredRequest = Object.entries(requestEntities).reduce(
      (acc, [key, ids]) => {
        if (ids.length) {
          acc[key] = ids;
        }
        return acc;
      },
      {} as RecordOfLoadEntities,
    );
    if (!Object.keys(filteredRequest).length) return true;

    Object.entries(filteredRequest).forEach(([key, value]) => {
      if (!storedEntities[key]) {
        returnFlag = false;
        return;
      }

      value.forEach(id => {
        if (!storedEntities[key][id]) {
          returnFlag = false;
          return;
        }
      });
    });

    return returnFlag;
  };
}

/**
 *
 * @param requestEntities {commerce_product: ["ID1", "ID2"]}
 * @returns {commerce_product: [{...}, {...}]}
 */
export default function useEntitiesFetchSelector(
  requestEntities: RecordOfLoadEntities,
) {
  const prevProps = usePrevious(requestEntities);
  const isLoading = React.useRef<boolean>(false);

  const { checkIsFulfilled } = useActions({
    checkIsFulfilled: checkIsFulfilledAction,
  });
  const storedEntities = useStoreState(state =>
    Object.entries(requestEntities).reduce((acc, [key, ids]) => {
      const targetEntities = state.entities[key];
      const mappedData = ids.map(id => targetEntities[id]);

      acc[key] = mappedData;
      return acc;
    }, {} as Record<Moim.Entity.NormalizedKey, any>),
  );

  const { dispatchLoadEntitiesDirect } = useActions({
    dispatchLoadEntitiesDirect: loadEntitiesDirect,
  });

  const fetch = React.useCallback(
    async (re?: RecordOfLoadEntities) => {
      if (isLoading.current) return;

      isLoading.current = true;
      try {
        // API
        await dispatchLoadEntitiesDirect(re ?? requestEntities);
      } finally {
        isLoading.current = false;
      }
    },
    [dispatchLoadEntitiesDirect, requestEntities],
  );

  React.useEffect(() => {
    if (
      !checkIsFulfilled(requestEntities) &&
      !isEqual(prevProps, requestEntities)
    ) {
      fetch();
    }
  }, [prevProps, requestEntities]);

  return {
    isLoading: isLoading.current,
    entities: storedEntities,
  };
}
