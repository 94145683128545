import * as React from "react";
import styled from "styled-components";
import useGroupTexts from "common/hooks/useGroupTexts";
import { StyledAloneBuyingButton } from "./styled";
import { useSingleLineStyle } from "common/components/designSystem/styles";
import CurrencyFormatter from "common/components/currencyFormatter";
import {
  H10BoldStyle,
  H8BoldStyle,
} from "common/components/designSystem/typos";

interface IProps {
  productStatus: Moim.Commerce.PRODUCT_STATUS;
  className?: string;
  buttonSize?: Moim.DesignSystem.Size;
  productStockCount?: number;
  alonePrice?: Moim.Commerce.IProductPrice;
  alternateButtonText?: React.ReactNode;
  disabled?: boolean;
  onClick: React.MouseEventHandler<HTMLElement>;
}

const AloneBuyingButton: React.FC<IProps> = ({
  productStatus,
  className,
  buttonSize = "l",
  productStockCount,
  alonePrice,
  alternateButtonText,
  disabled,
  onClick,
}) => {
  const aloneButtonText = useGroupTexts("button_buy_alone");

  const buttonDisableStatus =
    productStatus !== "onSale" ||
    (productStockCount !== undefined && productStockCount === 0) ||
    disabled;

  return (
    <StyledAloneBuyingButton
      className={className}
      size={buttonSize}
      disabled={buttonDisableStatus}
      onClick={onClick}
    >
      {alternateButtonText ? (
        alternateButtonText
      ) : (
        <div className="text">
          <div>{aloneButtonText?.singular}</div>
          <CurrencyFormatter
            currency={alonePrice?.currency}
            value={alonePrice?.value}
          />
        </div>
      )}
    </StyledAloneBuyingButton>
  );
};

export const ProductShowAloneBuyingButton = styled(AloneBuyingButton)`
  flex: 1;
  padding: 0;
  ${H8BoldStyle}
`;
export const ProductItemAloneBuyingButton = styled(AloneBuyingButton)`
  width: 100%;
  ${useSingleLineStyle}
  ${H10BoldStyle}
`;
