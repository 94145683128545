import styled from "styled-components";
import { px2rem } from "common/helpers/rem";

import { B4Regular } from "common/components/designSystem/typos";
import { textAlignStyle } from "common/components/thread/styles";

export const Engage = styled(B4Regular)`
  display: flex;
  align-items: center;
`;

export const EngageWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${px2rem(8)};
`;

export const Wrapper = styled.div<{
  textAlign?: Moim.Forum.ForumListConfigTextAlignment;
}>`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: ${props => props.theme.colorV2.colorSet.grey300};
  margin-inline: ${px2rem(-4)};

  gap: ${px2rem(2)} ${px2rem(8)};

  ${EngageWrapper} {
    ${textAlignStyle}
  }
`;
