import styled from "styled-components";
import {
  FlatButton,
  GhostGeneralButton,
} from "common/components/designSystem/buttons";
import { H10BoldStyle } from "common/components/designSystem/typos";

export const StyledAloneBuyingButton = styled(GhostGeneralButton)`
  width: 100%;
  flex: 1;

  .text {
    ${H10BoldStyle}
  }
`;

export const StyledTeamBuyingButton = styled(FlatButton)`
  width: 100%;
  flex: 1;

  .text {
    ${H10BoldStyle}
  }
`;
