import * as React from "react";
import uuid from "uuid";

import { MoimURL } from "common/helpers/url";
import { getBatchProductSets } from "app/actions/commerce";
import { isEmpty } from "lodash";

// hooks
import { arrayEqual, useActions, useStoreState } from "app/store";
import useRedirect from "common/hooks/useRedirect";
import { useParseListElementConfigWithFallback } from "../hooks";
// components
import InViewTrigger from "../../../inViewTrigger";
import BlockitHeader from "../../../header";
import BlockitFooter from "../../../footer";
import BlockitListLayout from "common/components/blockitListLayout";
import ProductItemCellSkeleton from "common/components/productItemCell/skeleton";
import {
  PortalRightArrow,
  PortalLeftArrow,
} from "common/components/horizontalScroll/arrows";
import ProductItemCell from "common/components/productItemCell";
import { Inner } from "../styled";
import { ArrowContainer } from "common/components/blockitEditorBase/styled";

import { productItemLayoutSelector } from "app/selectors/componentLayout";
import { useCommerceAnalyticsReport } from "common/hooks/commerce/useCommerceAnalytics";
import { getEstimatedWidth } from "common/components/blockitEditorBase/components/blockitRenderer.v2/helper/srcGetter";
import {
  BlockitRendererContextProvider,
  useBlockitRendererContext,
} from "common/components/blockitEditorBase/components/blockitRenderer.v2/context";
import useIsMobile from "common/hooks/useIsMobile";
import { BlockitFeedback } from "../../../feedback";

const ProductSetPreview: React.FC<Pick<
  Moim.Blockit.Commerce.IProductListPreviewBlock,
  | "title"
  | "description"
  | "resourceId"
  | "header"
  | "footer"
  | "itemLayout_web"
  | "listElement"
  | "maxDisplayedItemsCount"
  | "maxDisplayedItemsCount_web"
>> = ({
  title,
  description,
  header,
  footer,
  resourceId,
  itemLayout_web,
  listElement,
  maxDisplayedItemsCount,
  maxDisplayedItemsCount_web,
}) => {
  const redirect = useRedirect();

  const productItemLayout = useStoreState(
    state => itemLayout_web ?? productItemLayoutSelector(state, "listPreview"),
  );
  const {
    listElementType,
    maxVisibleCount,
    convertedListElement,
  } = useParseListElementConfigWithFallback(
    listElement,
    maxDisplayedItemsCount,
    maxDisplayedItemsCount_web,
  );

  const portalSectionId = React.useMemo(
    () => `product-set-preview-portal-${uuid.v4()}`,
    [],
  );

  const { dispatchBatchGetProductSet } = useActions({
    dispatchBatchGetProductSet: getBatchProductSets,
  });

  const handleClickViewMore = React.useCallback(() => {
    if (!resourceId) return;

    redirect(
      new MoimURL.CommerceProductSets({
        id: resourceId,
        section: "products",
      }).toString(),
    );
  }, [redirect, resourceId]);

  const productSet = useStoreState(state => {
    if (resourceId) {
      return state.entities.commerce_productSet[resourceId];
    }

    return null;
  });

  const { estimatedWidth, prefetchedData } = useBlockitRendererContext();

  const handleOnView = React.useCallback(async () => {
    if (!productSet && resourceId) {
      dispatchBatchGetProductSet(resourceId, maxVisibleCount);
    }
  }, [productSet, resourceId, dispatchBatchGetProductSet, maxVisibleCount]);

  const productIds = React.useMemo(() => {
    const cached = resourceId
      ? prefetchedData?.productSetProductIds?.[resourceId] ?? []
      : [];

    if (!productSet) {
      if (cached.length > 0) {
        return cached;
      }
    }

    return productSet?.productIds;
  }, [prefetchedData?.productSetProductIds, productSet, resourceId]);

  // [a,b,c,undefined,d,e,f]
  const products = useStoreState(
    state =>
      productIds
        ? productIds.map(
            id =>
              state.entities.commerce_product[id] as
                | Moim.Commerce.IProduct
                | undefined,
          )
        : undefined,
    arrayEqual,
  );

  const slicedProducts = React.useMemo(() => {
    const needSkipUndefined =
      productSet &&
      products &&
      products.length > 0 &&
      !products.every(b => b === undefined);

    return products
      ?.filter(pd => {
        if (!needSkipUndefined) {
          // allow undefined product
          return pd === undefined || Boolean(pd?.isDisplayed);
        }

        return Boolean(pd?.isDisplayed);
      })
      .slice(0, maxVisibleCount);
  }, [productSet, products, maxVisibleCount]);

  const reports = useCommerceAnalyticsReport();

  const isMobile = useIsMobile();
  const itemWidth = getEstimatedWidth(
    convertedListElement,
    undefined,
    estimatedWidth,
    !isMobile,
  );
  const itemElements = React.useMemo(() => {
    if (slicedProducts === undefined) {
      return new Array(maxVisibleCount)
        .fill(0)
        .map((_, idx) => (
          <ProductItemCellSkeleton
            key={`plp_skeleton_${idx}`}
            direction={productItemLayout?.direction}
          />
        ));
    }

    return slicedProducts.map((product, idx) => (
      <BlockitRendererContextProvider estimatedWidth={itemWidth}>
        {!product ? (
          <ProductItemCellSkeleton
            key={`plp_skeleton_${idx}`}
            direction={productItemLayout?.direction}
          />
        ) : (
          <ProductItemCell
            key={`${product.id}`}
            productId={product.id}
            block={productItemLayout}
            onProductSelect={reports.reportBlockProductListPreviewProductSelect}
            onSellerSelect={reports.reportBlockProductListPreviewSellerSelect}
            onBuyNowSelect={reports.reportBlockProductListPreviewProductBuyNow}
            onAddToCartSelect={
              reports.reportBlockProductListPreviewProductAddToCart
            }
          />
        )}
      </BlockitRendererContextProvider>
    ));
  }, [slicedProducts, maxVisibleCount, productItemLayout, itemWidth]);

  return (
    <Inner>
      <InViewTrigger onVisible={handleOnView} />
      <BlockitHeader
        title={title}
        description={description}
        showTitle={header?.showTitle ?? true}
        showDescription={header?.showDescription ?? true}
        showMoreButton={header?.showMoreButton ?? true}
        onClickViewMore={handleClickViewMore}
      />
      {listElementType === "horizontal" && (
        <ArrowContainer id={portalSectionId} />
      )}

      {productSet && isEmpty(productSet.productIds) ? (
        <BlockitFeedback.Empty textKey="product_list_preview_empty" />
      ) : (
        <BlockitListLayout
          element={convertedListElement}
          rightArrow={
            listElementType === "horizontal" ? PortalRightArrow : undefined
          }
          leftArrow={
            listElementType === "horizontal" ? PortalLeftArrow : undefined
          }
          arrowPortalTargetId={portalSectionId}
        >
          {itemElements}
        </BlockitListLayout>
      )}

      <BlockitFooter
        showMoreButton={footer?.showMoreButton ?? false}
        textKey={"button_see_more_product"}
        onClickViewMore={handleClickViewMore}
      />
    </Inner>
  );
};

export default React.memo(ProductSetPreview);
