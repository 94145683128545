import * as React from "react";
import { FlattenInterpolation } from "styled-components";
import SelectableItem from "./components/selectableItem";
import { Section, SectionContents, SelectableItemWrapper } from "./styled";
import { IOption } from "common/components/designSystem/selection/type";
import InfiniteScroller from "common/components/infiniteScroller";

export interface IProps {
  selectableItemStyle?: FlattenInterpolation<any>;
  emptyStateElement?: React.ReactNode;
  options: IOption[];
  hover?: boolean;
  isMultipleSelect: boolean;
  isSelected(selectedId: Moim.Id): boolean;
  onSelected(selectedId: Moim.Id): void;
}

export default function SelectableItemList({
  options,
  hover,
  isMultipleSelect,
  isSelected,
  onSelected,
  selectableItemStyle,
  emptyStateElement,
}: IProps) {
  const selectableItemListElement = React.useMemo(
    () =>
      options.length === 0
        ? emptyStateElement
        : options.map(option => (
            <SelectableItemWrapper
              key={`${option.value}_${option.label}`}
              selectableItemStyle={selectableItemStyle}
            >
              <SelectableItem
                hover={hover}
                option={option}
                isSelected={isSelected(option.value)}
                isMultipleSelect={isMultipleSelect}
                onClick={onSelected}
              />
            </SelectableItemWrapper>
          )),

    [
      options,
      emptyStateElement,
      selectableItemStyle,
      hover,
      isSelected,
      isMultipleSelect,
      onSelected,
    ],
  );

  return (
    <Section>
      <SectionContents>
        <InfiniteScroller
          loadMore={() => {}}
          itemLength={options.length}
          threshold={400}
        >
          {selectableItemListElement}
        </InfiniteScroller>
      </SectionContents>
    </Section>
  );
}
