import { useStoreState } from "app/store";
import { ENTER_FROM_TYPES } from "../reducer";

export const getVisibleOptionInventoryStep = (
  purchaseReadyItems?: Moim.Commerce.IPurchaseReadyItem[],
) => !purchaseReadyItems?.length;

export function useVisibleOptionInventoryStep() {
  const { purchaseReadyItems } = useStoreState(state => ({
    purchaseReadyItems:
      state.purchasePreparationDialog.payload?.purchaseReadyItems,
  }));

  return getVisibleOptionInventoryStep(purchaseReadyItems);
}

export const getVisibleTeamBuyingStep = ({
  teamBuyingId,
  teamId,
  enterFrom,
}: {
  teamBuyingId?: string;
  teamId?: string;
  enterFrom?: ENTER_FROM_TYPES;
}) => Boolean(enterFrom === "team" && teamBuyingId && !teamId);

export function useVisibleTeamBuyingStep() {
  const { teamBuyingId, teamId, enterFrom } = useStoreState(state => ({
    teamBuyingId:
      state.purchasePreparationDialog.payload?.teamBuying?.teamBuyingId,
    teamId: state.purchasePreparationDialog.payload?.teamBuying?.teamId,
    enterFrom: state.purchasePreparationDialog.payload?.teamBuying?.enterFrom,
  }));

  return getVisibleTeamBuyingStep({ teamBuyingId, teamId, enterFrom });
}
