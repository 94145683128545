import React from "react";
import { CheckboxFormItem } from "./displayType/checkbox";
import { MultiToggleButtonGroupFormItem } from "./displayType/toggleButtonGroup/multi";
import { MultiDropdownFormItem } from "./displayType/multiDropdown";

export interface IRef {
  validate(): Record<Moim.Id, any>;
}
export const MultiSelectionFormItem = React.forwardRef<
  IRef,
  { item: Moim.Form.IFormItem }
>(({ item }, ref) => {
  switch (item.displayType) {
    case "checkbox":
      return <CheckboxFormItem ref={ref} item={item} />;

    case "toggleButtonGroup":
      return <MultiToggleButtonGroupFormItem ref={ref} item={item} />;
    case "dropdown":
      return <MultiDropdownFormItem ref={ref} item={item} />;

    default:
      return null;
  }
});
