import React from "react";
import styled from "styled-components";
import { createPortal } from "react-dom";
import { ThemeMode } from "app/enums";

const Backdrop = styled.div`
  background-color: ${props =>
    props.theme.themeMode.mode === ThemeMode.DARK
      ? props.theme.colorV2.colorSet.white800
      : props.theme.colorV2.colorSet.grey500};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${props => props.theme.zIndexes.bottomSheet};
`;

export const BottomSheetBackdrop: React.FC<{ onCloseRequest(): void }> = ({
  onCloseRequest,
}) => {
  const handleClickBackdrop: React.MouseEventHandler<HTMLDivElement> = React.useCallback(
    e => {
      e.preventDefault();
      e.stopPropagation();
      onCloseRequest();
    },
    [onCloseRequest],
  );
  return createPortal(
    <Backdrop onClick={handleClickBackdrop} />,
    document.getElementById("vingle-portal") ?? document.body,
  );
};
