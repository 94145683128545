import * as React from "react";
import styled from "styled-components";

import ShavedText from "common/components/shavedText/v2";
import { NativeEmojiSafeText } from "common/components/designSystem/texts";
import { ThreadTitleWrapper } from "common/components/thread/components/wrapper/title";
import { textAlignStyle } from "common/components/thread/styles";
import { Thread } from "app/typings";
import { useIntl } from "react-intl";

interface IProps {
  isDraftThread?: boolean;
  title?: string;
  stat?: Thread.IThreadItemStatProps;
  prefix?: React.ReactNode;
  textAlign?: Moim.Forum.ForumListConfigTextAlignment;
  line?: number;
}

const TitleWrapper = styled(ThreadTitleWrapper)<{
  textAlign?: Moim.Forum.ForumListConfigTextAlignment;
}>`
  ${textAlignStyle}
`;

function Title({
  isDraftThread,
  title,
  stat,
  prefix,
  textAlign,
  line = 1,
}: IProps) {
  const intl = useIntl();
  return (
    <TitleWrapper stat={stat} prefix={prefix} textAlign={textAlign}>
      <ShavedText line={line}>
        <NativeEmojiSafeText
          value={
            title
              ? title
              : isDraftThread
              ? intl.formatMessage({ id: "post_editor_auto_saved_draft_title" })
              : ""
          }
        />
      </ShavedText>
    </TitleWrapper>
  );
}

export default React.memo(Title);
