import MoimList from "./components/moimList";
import MyShopping from "./components/myShopping";
import PostTemplate from "./components/postTemplate";
import Profile from "./components/profile";
import { ActionCreators } from "app/actions/secondaryView";
import { PermissionDeniedFallbackType } from "app/enums";
import LogoutButton from "app/modules/navigationPanel/components/moimConfigMenu/component/logoutButton";
import MemberButton from "app/modules/navigationPanel/components/moimConfigMenu/component/memberButton";
import { MoimAdminV2Button } from "app/modules/navigationPanel/components/moimConfigMenu/component/moimAdminButton";
import PersonalSettingButton from "app/modules/navigationPanel/components/moimConfigMenu/component/personalSettingButton";
import PluginsButton from "app/modules/navigationPanel/components/moimConfigMenu/component/pluginsButton";
import ShareMoimButton from "app/modules/navigationPanel/components/moimConfigMenu/component/shareMoimButton";
import { useActions } from "app/store";
import { DefaultDivider } from "common/components/divider";
import { DefaultLoader } from "common/components/loading";
import PermissionChecker from "common/components/permissionChecker";
import ResponsiveMenu from "common/components/responsiveMenu";
import { MenuWrapper } from "common/components/responsiveMenu/components/menu";
import useCurrentGroup from "common/hooks/useCurrentGroup";
import useCurrentUser from "common/hooks/useCurrentUser";
import useSuperPermission from "common/hooks/useSuperPermission";
import * as React from "react";

interface IProps extends React.ComponentProps<typeof ResponsiveMenu> {
  onClickMenuButton: () => void;
}

export default function ProfileMenu({ onClickMenuButton, ...props }: IProps) {
  const ref = React.useRef<HTMLUListElement>(null);
  const currentGroup = useCurrentGroup();
  const currentUser = useCurrentUser();
  const {
    hasPermission: hasSettingPermission,
    isLoading: isPermissionLoading,
  } = useSuperPermission();
  const [minHeight, setHeight] = React.useState(0);

  const hasCommunityAccount = React.useMemo(
    () =>
      Boolean(
        currentUser?.profiles?.find(
          (profile) => profile.type === "COMMUNITY_ACCOUNT",
        ),
      ),
    [currentUser?.profiles],
  );

  const { openFromProfile } = useActions({
    openFromProfile: ActionCreators.openNativeSecondaryViewFromProfile,
  });

  const onClickMenuButtonFromProfileMenu = React.useCallback(() => {
    openFromProfile(true);
    onClickMenuButton();
  }, [onClickMenuButton, openFromProfile]);

  const onClickMenuButtonFromMyProfile = React.useCallback(() => {
    openFromProfile(false);
    onClickMenuButton();
  }, [onClickMenuButton, openFromProfile]);

  React.useLayoutEffect(() => {
    if (props.open) {
      requestAnimationFrame(() => {
        if (ref.current) {
          setHeight(ref.current.getBoundingClientRect().height + 16);
        }
      });
    }
  }, [props.open]);

  return (
    <ResponsiveMenu {...props} minHeight={minHeight}>
      <MenuWrapper ref={ref}>
        <Profile onClickButton={onClickMenuButtonFromMyProfile} />
        {currentGroup?.seller_id && (
          <MyShopping onClickButton={onClickMenuButtonFromProfileMenu} />
        )}
        <MoimList onClickButton={onClickMenuButton} />

        <DefaultDivider />

        {currentGroup?.config.showMemberCount && (
          <MemberButton onClickButton={onClickMenuButtonFromProfileMenu} />
        )}
        <ShareMoimButton onClickButton={onClickMenuButton} />

        <DefaultDivider />

        <PersonalSettingButton onClickButton={onClickMenuButton} />
        {(hasSettingPermission || hasCommunityAccount) && (
          <MoimAdminV2Button onClickButton={onClickMenuButton} />
        )}
        {!isPermissionLoading ? (
          <PermissionChecker
            fallbackType={PermissionDeniedFallbackType.NONE}
            hasPermission={hasSettingPermission}
            isLoading={isPermissionLoading}
          >
            <>
              <PluginsButton onClickButton={onClickMenuButton} />
              <PostTemplate onClickButton={onClickMenuButton} />
            </>
          </PermissionChecker>
        ) : (
          <DefaultLoader />
        )}

        <DefaultDivider />
        <LogoutButton onClickButton={onClickMenuButton} />
      </MenuWrapper>
    </ResponsiveMenu>
  );
}
