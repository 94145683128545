import { css } from "styled-components";
import { px2rem } from "common/helpers/rem";

export const getPaddingStyle = (padding?: Moim.Blockit.V2.IBlockitPadding) => {
  let style = css``;
  if (!padding) return style;

  if (padding.top !== undefined) {
    style = style.concat(
      css`
        padding-top: ${px2rem(padding.top)};
      `,
    );
  }

  if (padding.bottom !== undefined) {
    style = style.concat(
      css`
        padding-bottom: ${px2rem(padding.bottom)};
      `,
    );
  }

  if (padding.left !== undefined) {
    style = style.concat(
      css`
        padding-left: ${px2rem(padding.left)};
      `,
    );
  }

  if (padding.right !== undefined) {
    style = style.concat(
      css`
        padding-right: ${px2rem(padding.right)};
      `,
    );
  }

  return style;
};

export const getMarginStyle = (margin?: Moim.Blockit.V2.IBlockitPadding) => {
  let style = css``;
  if (!margin) return style;

  if (margin.top !== undefined) {
    style = style.concat(
      css`
        margin-top: ${px2rem(margin.top)};
      `,
    );
  }

  if (margin.bottom !== undefined) {
    style = style.concat(
      css`
        margin-bottom: ${px2rem(margin.bottom)};
      `,
    );
  }

  if (margin.left !== undefined) {
    style = style.concat(
      css`
        margin-left: ${px2rem(margin.left)};
      `,
    );
  }

  if (margin.right !== undefined) {
    style = style.concat(
      css`
        margin-right: ${px2rem(margin.right)};
      `,
    );
  }

  return style;
};

export const blockAlign2FlexAlign = (
  align?: Moim.Blockit.V2.IBlockAlignment,
) => {
  if (align === "left") {
    return "flex-start";
  }
  if (align === "center") {
    return "center";
  }
  if (align === "right") {
    return "flex-end";
  }
};
export const blockVerticalAlign2FlexAlign = (
  align?: Moim.Blockit.V2.IBlockVerticalAlignment,
) => {
  if (align === "top") {
    return "flex-start";
  }
  if (align === "center") {
    return "center";
  }
  if (align === "bottom") {
    return "flex-end";
  }
};
