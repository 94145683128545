import { Svg24AddB } from "@moim/icons";
import React from "react";
import styled from "styled-components";
import { px2rem } from "common/helpers/rem";


import { Box } from "./item";

const AddIcon = styled(Svg24AddB).attrs(props => ({
  size: "s",
  iconColor: props.theme.colorV2.colorSet.grey100,
}))``;

const Button = styled(Box).attrs({ role: "button" })`
  width: ${px2rem(110)};
  min-width: ${px2rem(110)};
  height: ${px2rem(110)};
  border-radius: ${px2rem(4)};
  background-color: ${props => props.theme.colorV2.colorSet.grey10};
  border: solid ${px2rem(1)} ${props => props.theme.colorV2.colorSet.grey50};
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const AddFileButton: React.FC<{
  addFile(): void;
}> = ({ addFile }) => {
  return (
    <Button onClick={addFile}>
      <AddIcon />
    </Button>
  );
};
