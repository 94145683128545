/* eslint-disable no-underscore-dangle */
import makeOgMetaData from "common/helpers/makeOgMetaData";
import useCurrentGroup from "./useCurrentGroup";
import replaceLineBreakString from "common/helpers/string/replaceLineBreakString";
function useOGData(params?: {
  ogData?: Moim.IOGData;
  fallbackTitle?: string;
  fallbackDescription?: string;
  fallbackImageUrl?: string;
}) {
  const currentMoim = useCurrentGroup();
  if (!currentMoim) {
    return {
      seoTitle: "",
      seoDescription: "",
      metaObjects: [],
    };
  }

  const currentMoimTitle = currentMoim.ogData?.title || currentMoim.name;
  const { ogData, fallbackTitle, fallbackDescription, fallbackImageUrl } =
    params ?? {};

  const resultTitle =
    ogData?.title ||
    fallbackTitle ||
    currentMoim.ogData?.title ||
    currentMoim?.name ||
    "";
  const resultDescription =
    ogData?.description ||
    fallbackDescription ||
    currentMoim.ogData?.description ||
    currentMoim?.description ||
    "";
  const resultImageUrl =
    ogData?.imageUrl ||
    fallbackImageUrl ||
    currentMoim.ogData?.imageUrl ||
    (currentMoim.banner?.type === "image"
      ? currentMoim.banner.data.url
      : undefined);

  const _resultSeoTitle = ogData?.seoTitle || fallbackTitle || ogData?.title;
  const _resultMoimSeoTitle =
    currentMoim.ogData?.seoTitle ||
    currentMoim.ogData?.title ||
    currentMoim.name;

  const resultSeoTitle =
    _resultSeoTitle && _resultSeoTitle !== _resultMoimSeoTitle
      ? `${_resultSeoTitle} | ${_resultMoimSeoTitle}`
      : _resultMoimSeoTitle;

  const resultSeoDescription =
    ogData?.seoDescription ||
    fallbackDescription ||
    ogData?.description ||
    currentMoim.ogData?.seoDescription ||
    currentMoim.ogData?.description ||
    currentMoim.description ||
    "";

  const metaObjects = makeOgMetaData({
    type: "website",
    title: resultTitle,
    description: replaceLineBreakString(resultDescription, " "),
    imageUrl: resultImageUrl,
    url: location.href,
    siteName: currentMoimTitle,
  });

  return {
    seoTitle: resultSeoTitle,
    seoDescription: resultSeoDescription,
    metaObjects,
  };
}

export default useOGData;
