import LazyBlurHashImage from "common/components/lazyBlurHashImage";
import { GridItemWrapper } from "common/components/thread/commonThreadItem/components/common/thumbnailGrid/styled";
import * as React from "react";

interface IProps {
  media: Moim.Blockit.ICarouselImage;
  width: number;
  height: number;
  halfHeight?: boolean;
}

const GridItemElement: React.FC<IProps> = ({
  media,
  width,
  height,
  halfHeight,
}) => {
  return (
    <GridItemWrapper>
      <LazyBlurHashImage
        src={media.src ?? media.imageSrc}
        alt={(media.src ?? media.imageSrc).split("/").pop() ?? ""}
        blurHash={media.blurHash ?? media.blur_hash}
        fallBackSrc={media.fallbackSrc ?? media.src ?? media.imageSrc}
        width={width}
        height={halfHeight ? height / 2 : height}
      />
    </GridItemWrapper>
  );
};

export default React.memo(GridItemElement);
