import React from "react";
import { FormItemHeader } from "../../../../header";
import { useIntlShort } from "common/hooks/useIntlShort";
import { FormValueContext } from "../../../../context";
import { IRef } from "../../../..";
import { useFormItemOptions } from "../../../../hooks";
import { Error, ToggleButton, ToggleButtonGroup } from "../styled";

export const MultiToggleButtonGroupFormItem = React.forwardRef<
  IRef,
  { item: Moim.Form.IFormItem }
>(({ item }, ref) => {
  const intl = useIntlShort();
  const { onChange, values, errors } = React.useContext(FormValueContext);
  const options = useFormItemOptions(item.options);
  const currentValue = values[item.id] ?? [];
  const error = errors[item.id];

  React.useImperativeHandle(ref, () => ({
    validate: () => {
      if (item.isRequired && !currentValue) {
        return { [item.id]: intl("error_form_question_required") };
      }
      if (item.maxCount !== undefined && currentValue?.length > item.maxCount) {
        return {
          [item.id]: intl("error_options_max_count", {
            max_count: item.maxCount,
          }),
        };
      }
      return { [item.id]: undefined };
    },
  }));

  return (
    <>
      <FormItemHeader item={item} />
      {Boolean(error) && <Error>{error}</Error>}
      <ToggleButtonGroup>
        {options.map(option => {
          const selected = currentValue?.includes(option.value);
          return (
            <ToggleButton
              key={option.value}
              selected={selected}
              onClick={() => {
                if (selected) {
                  onChange(
                    item.id,
                    currentValue?.filter(value => value !== option.value),
                  );
                } else {
                  onChange(item.id, [...currentValue, option.value]);
                }
              }}
            >
              {option.label}
            </ToggleButton>
          );
        })}
      </ToggleButtonGroup>
    </>
  );
});
