import React from "react";
import { FormattedMessage } from "react-intl";
import Checkbox from "common/components/blockitEditorBase/components/blockitRenderer/components/inputs/checkbox/index";
import ResponsiveMenu from "common/components/responsiveMenu";
import {
  TagItemsWrapper,
  TagItems,
  WrapperStyle,
  PopoverStyle,
  TagItemsBarWrapper,
  SelectButton,
} from "./styled";

interface IProps {
  tagGroup?: Moim.TagSet.ITagGroup;
  selectedTags?: string[];
  anchorElement?: null | Element | ((element: Element) => Element);
  isOpen: boolean;
  onClose(): void;
  onChange(id: string, selectedTags: string[]): void;
}

export const TagGroupPopover: React.FC<IProps> = ({
  tagGroup,
  selectedTags = [],
  anchorElement,
  isOpen,
  onClose,
  onChange,
}) => {
  if (!tagGroup) {
    return null;
  }
  return (
    <ResponsiveMenu
      open={isOpen}
      anchorElement={anchorElement}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      minHeight={320}
      paperOverrideStyle={PopoverStyle}
      onCloseRequest={onClose}
    >
      <TagItemsWrapper>
        <TagItems>
          {tagGroup.items.map(item => {
            const selected = selectedTags.includes(item.id);
            return (
              <Checkbox
                wrapperStyle={WrapperStyle}
                label={item.value}
                name={item.id}
                element={{
                  type: "check-box-input",
                  initialChecked: false,
                }}
                checked={selected}
                onChange={() => {
                  if (selected) {
                    onChange(
                      tagGroup.id,
                      selectedTags.filter(tag => tag !== item.id),
                    );
                  } else {
                    onChange(tagGroup.id, [...selectedTags, item.id]);
                  }
                }}
              />
            );
          })}
        </TagItems>
        <TagItemsBarWrapper>
          <SelectButton onClick={() => onChange(tagGroup.id, [])}>
            <FormattedMessage id="select_tag_dialog_deselect_all" />
          </SelectButton>
          <SelectButton
            onClick={() =>
              onChange(
                tagGroup.id,
                tagGroup.items.map(item => item.id),
              )
            }
          >
            <FormattedMessage id="select_tag_dialog_select_all" />
          </SelectButton>
        </TagItemsBarWrapper>
      </TagItemsWrapper>
    </ResponsiveMenu>
  );
};
