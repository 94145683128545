import styled, { css } from "styled-components";
import { px2rem } from "common/helpers/rem";
import { B2Regular } from "common/components/designSystem/typos";
import { MEDIA_QUERY } from "common/constants/responsive";

export const CurrentUserAvatarPlacement = styled.div`
  width: ${px2rem(24)};
  height: ${px2rem(24)};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${px2rem(6)};
`;

export const inputWrapperStyle = css`
  width: 100%;
  height: fit-content;
  min-width: 0;
  flex: 1;
`;

export const ThreadInputWrapper = styled.div`
  width: 100%;
  display: flex;
  column-gap: ${px2rem(12)};
  align-items: flex-start;

  > div[class^="UnsignedInputWrapper"] {
    width: 100%;
  }

  @media ${MEDIA_QUERY.ONLY_MOBILE} {
    padding: 0 ${px2rem(16)} ${px2rem(5)};
  }
  @media ${MEDIA_QUERY.EXCEPT_MOBILE} {
    padding: ${px2rem(8)} 0;
  }
`;

export const NoRightTextWrapper = styled(B2Regular)`
  color: ${props => props.theme.colorV2.colorSet.grey300};
  height: ${px2rem(44)};
  line-height: ${px2rem(44)};
  border-radius: ${px2rem(4)};
  background-color: ${props => props.theme.colorV2.colorSet.white1000};
  z-index: ${props => props.theme.zIndexes.gnbSticky};
`;

export const BackgroundLayer = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 ${px2rem(16)};
  background-color: ${props => props.theme.colorV2.colorSet.grey10};
`;
