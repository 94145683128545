import styled from "styled-components";
import { px2rem } from "common/helpers/rem";

import { B4Regular } from "common/components/designSystem/typos";

export const Engage = styled(B4Regular)`
  display: flex;
  align-items: center;
`;

export const EngageWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${px2rem(8)};
  padding: ${px2rem(4)} 0;
  color: ${props => props.theme.colorV2.colorSet.grey300};
`;
