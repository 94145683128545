import styled, { css } from "styled-components";
import { px2rem } from "common/helpers/rem";

const Spacer = styled.div<{
  width?: {
    value: number;
    unit: string; // px / %
  };
  height?: {
    value: number;
    unit: string; // px / %
  };
}>`
  ${props =>
    props.width &&
    css`
      width: ${props.width.unit === "px"
        ? px2rem(props.width.value)
        : `${props.width.value}${props.width.unit}`};
    `}

  ${props =>
    props.height &&
    css`
      padding-top: ${props.height.unit === "px"
        ? px2rem(props.height.value)
        : `${props.height.value}${props.height.unit}`};
    `}
`;

export default Spacer;
