import {
  CoinImage,
  ContentUnlockChip,
  ContentUnlockedChip,
  OwnedIcon,
  OwnedIconWrapper,
} from "common/components/productItemCell/components/image/components/chips/styled";
import { useThreadItemContext } from "common/components/thread/commonThreadItem/context";
import { textAlignStyle } from "common/components/thread/styles";
import { px2rem } from "common/helpers/rem";
import { useIntlShort } from "common/hooks/useIntlShort";
import * as React from "react";
import styled from "styled-components";

interface IProps {
  unlockMethods?: Moim.Forum.IThreadUnlockMethod[];
  textAlign?: Moim.Forum.ForumListConfigTextAlignment;
  isUnlocked?: boolean;
}

export const UnlockedChipComponent: React.FC = () => {
  const intl = useIntlShort();
  const unlockedTexts = intl("unlocked_post");

  return (
    <ContentUnlockedChip>
      <OwnedIconWrapper>
        <OwnedIcon />
      </OwnedIconWrapper>
      {unlockedTexts}
    </ContentUnlockedChip>
  );
};

export const CoinChipComponent: React.FC<{
  coin: Moim.Community.Coin.ICoin;
  quantity: number;
}> = ({ coin, quantity }) => {
  return (
    <ContentUnlockChip
      badgeColor={coin.preview?.hexCode}
      scale={coin.preview?.fogType}
    >
      {coin.preview?.XS ? (
        <CoinImage>
          <img src={coin.preview?.XS} alt={coin.name} />
        </CoinImage>
      ) : null}
      {quantity} {coin.symbol}
    </ContentUnlockChip>
  );
};

const UnlockMethodWrapper = styled.div<{
  textAlign?: Moim.Forum.ForumListConfigTextAlignment;
}>`
  display: flex;
  align-items: center;
  gap: ${px2rem(4)};
  margin-top: ${px2rem(8)};
  ${textAlignStyle};
`;

function UnlockMethods({ coins }: { coins?: Moim.Community.Coin.ICoin[] }) {
  const { unlockMethods, textAlign, isUnlocked, showUnlockMethods } =
    useThreadItemContext((state) => ({
      unlockMethods: state.item.unlockMethods,
      textAlign: state.config.textAlignment,
      isUnlocked: Boolean(state.item.unlocked_at),
      showUnlockMethods: Boolean(
        state.item.locked &&
          state.item.unlockMethods?.find(
            (method) =>
              method?.resourceType === "transferCoin" && method.quantity,
          ),
      ),
    }));

  if (!showUnlockMethods) {
    return null;
  }
  if (isUnlocked) {
    return (
      <UnlockMethodWrapper textAlign={textAlign}>
        <UnlockedChipComponent />
      </UnlockMethodWrapper>
    );
  }

  return (
    <UnlockMethodWrapper textAlign={textAlign}>
      {unlockMethods?.map((method) => {
        switch (method.resourceType) {
          case "transferCoin":
            const coin = coins?.find((coin) => coin.id === method.resourceId);
            if (coin) {
              return (
                <CoinChipComponent
                  key={method.resourceId}
                  coin={coin}
                  quantity={method.quantity}
                />
              );
            }
            break;

          default:
            return null;
        }
      })}
    </UnlockMethodWrapper>
  );
}

export default React.memo(UnlockMethods);
