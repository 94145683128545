import * as React from "react";
import { FormattedMessage } from "react-intl";
import { GridContextProvider, GridDropZone, GridItem } from "react-grid-dnd";
import DragHere from "common/components/blockitEditorBase/components/dragHere";
import { ImagePreview, AddPreview } from "./components/imagePreview";
import { px2rem } from "common/helpers/rem";
import {
  Wrapper,
  Description,
  ImagePreviewContainer,
  InnerWrapper,
} from "./styled";
export interface IItemMeta {
  id: Moim.Id; // shortId()
  payload: {
    rawFile: File;
  };
}

interface IProps {
  items: IItemMeta[];
  onDnDChange(
    sourceKey: string,
    sourceIndex: number,
    targetIndex: number,
    targetKey: string,
  ): void;
  onClickAddPreview(): void;
  onClickDeleteItem(id: Moim.Id): void;
  onDrop(e: React.DragEvent<HTMLDivElement>): void;
}

const FileUploadManageComponent: React.FC<IProps> = ({
  items,
  onDnDChange,
  onClickAddPreview,
  onClickDeleteItem,
  onDrop,
}) => {
  const refInnerScroll = React.useRef<HTMLDivElement>(null);
  const [veilStatus, setVeilStatus] = React.useState({
    top: false,
    bottom: false,
  });
  const [dropZoneVisibility, setDropZoneVisibility] = React.useState(false);

  const handleDragEnter = React.useCallback(e => {
    e.stopPropagation();
    e.preventDefault();
    setDropZoneVisibility(true);
  }, []);

  const handleDragLeave = React.useCallback(() => {
    setDropZoneVisibility(false);
  }, []);

  const handleDragOver = React.useCallback(e => {
    e.stopPropagation();
    e.preventDefault();
    e.dataTransfer.dropEffect = "copy";

    setDropZoneVisibility(true);
  }, []);

  const handleDrop = React.useCallback(
    e => {
      e.stopPropagation();
      e.preventDefault();
      setDropZoneVisibility(false);

      onDrop?.(e);
    },
    [onDrop],
  );

  const preventDragging = React.useCallback(e => {
    e.stopPropagation();
    e.preventDefault();
  }, []);

  const handleScroll: React.UIEventHandler<HTMLDivElement> = React.useCallback(
    e => {
      e.stopPropagation();
      e.preventDefault();
      const currentScrollPos = e.currentTarget.scrollTop;
      const scrollMaxHeight =
        e.currentTarget.scrollHeight - e.currentTarget.clientHeight - 80;

      setVeilStatus({
        top: currentScrollPos <= 40 ? false : true,
        bottom: currentScrollPos >= scrollMaxHeight ? false : true,
      });
    },
    [],
  );

  React.useLayoutEffect(() => {
    refInnerScroll.current?.scrollTo(0, 1);
  }, []);

  return (
    <Wrapper>
      <Description>
        <FormattedMessage id="dialog_rearrange_image_order_guide" />
      </Description>
      <GridContextProvider onChange={onDnDChange}>
        <ImagePreviewContainer
          onDragEnter={handleDragEnter}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          <div data-active={veilStatus.top} className="topVeil"></div>
          <InnerWrapper ref={refInnerScroll} onScroll={handleScroll}>
            <GridDropZone
              id="items"
              boxesPerRow={5}
              rowHeight={80}
              style={{
                willChange: "transform, position",
                width: "100%",
                minHeight: px2rem(240),
                height: 80 * Math.ceil(items.length / 5),
              }}
            >
              {items.map((item, idx) => (
                <GridItem key={item.id} style={{ willChange: "transform" }}>
                  <ImagePreview
                    file={item.payload.rawFile}
                    positionLabel={`${idx + 1}`}
                    onClickDelete={() => {
                      onClickDeleteItem(item.id);
                    }}
                  />
                </GridItem>
              ))}
              <GridItem
                key="Add-btn"
                onMouseDown={preventDragging}
                onTouchStart={preventDragging}
              >
                <AddPreview onClick={onClickAddPreview} />
              </GridItem>
            </GridDropZone>
          </InnerWrapper>
          <DragHere
            visible={dropZoneVisibility}
            offset={0}
            onDragLeave={handleDragLeave}
          />

          <div data-active={veilStatus.bottom} className="bottomVeil"></div>
        </ImagePreviewContainer>
      </GridContextProvider>
    </Wrapper>
  );
};

export default FileUploadManageComponent;
