import { Engage, EngageWrapper, Wrapper } from "./styled";
import { VoteStatus } from "app/enums";
import { SmallComment } from "common/components/engage/comment";
import { SmallLike } from "common/components/engage/like";
import { SmallUpDown } from "common/components/engage/upDown";
import ViewCount from "common/components/engage/viewCount";
import * as React from "react";

export interface IProps {
  threadId: Moim.Id;
  upVoteCount: number;
  downVoteCount: number;
  commentCount: number;
  viewCount?: number;
  voteType?: Moim.Forum.PostReactionType;
  voteStatus?: Moim.Enums.VoteStatus;

  textAlign?: Moim.Forum.ForumListConfigTextAlignment;
  showReaction: boolean;
  showCommentCount: boolean;
  showViewCount: boolean;
}

function Engagement({
  threadId,
  upVoteCount,
  downVoteCount,
  commentCount,
  voteType,
  voteStatus,
  textAlign,
  showReaction,
  showCommentCount,
  showViewCount,
  viewCount,
}: IProps) {
  return (
    <Wrapper textAlign={textAlign}>
      {(showReaction || showCommentCount) && (
        <EngageWrapper>
          {showReaction && (
            <Engage>
              {voteType === "up" ? (
                <SmallLike
                  liked={voteStatus === VoteStatus.POSITIVE}
                  likeCount={upVoteCount}
                  threadId={threadId ?? ""}
                />
              ) : (
                <SmallUpDown
                  threadId={threadId ?? ""}
                  status={voteStatus ?? VoteStatus.NONE}
                  upCount={upVoteCount}
                  downCount={downVoteCount}
                  visibleNoneCountFallback={false}
                />
              )}
            </Engage>
          )}

          {showCommentCount && (
            <Engage>
              <SmallComment count={commentCount} />
            </Engage>
          )}

          {showViewCount && viewCount !== undefined ? (
            <Engage>
              <ViewCount count={viewCount} />
            </Engage>
          ) : null}
        </EngageWrapper>
      )}
    </Wrapper>
  );
}

export default React.memo(Engagement);
