import * as React from "react";
import useCurrentUser from "common/hooks/useCurrentUser";

export default function useCurrentUserPositionCheck() {
  const currentUser = useCurrentUser();

  const checker = React.useCallback(
    (
      positionIds: Moim.Id[],
      opt: { conditionType: "or" | "and" } = { conditionType: "or" },
    ) => {
      const userPositionIds = currentUser?.positions ?? [];
      if (userPositionIds.length === 0) {
        return false;
      }
      if (opt.conditionType === "or") {
        return Boolean(
          userPositionIds.filter(id => positionIds.includes(id)).length,
        );
      } else {
        return (
          userPositionIds.filter(id => positionIds.includes(id)).length ===
          positionIds.length
        );
      }
    },
    [currentUser?.positions],
  );

  return checker;
}
