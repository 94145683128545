/* eslint-disable no-underscore-dangle */
import { InitialLoadData } from "app/routes/ssrLoadData";
import { ISSRLoadDataPayload } from "app/typings/ssr";
import { MoimAPI } from "common/api";
import { getInitialData, setInitialData } from "common/helpers/initialData";
import _ from "lodash";

async function loadPermission(
  requestId: string,
  api: MoimAPI,
  resourceId: string,
) {
  const permissions = await api.permission.getPermission({
    resource: resourceId,
  });

  setInitialData(`__resourcePermissions.${resourceId}`, requestId, permissions);
}

async function loadChannel(requestId: string, api: MoimAPI, channelId: string) {
  const channel = await api.channel.getChannel({ channelId });
  setInitialData("__entities.channel", requestId, channel);
  setInitialData("__entities.forum", requestId, channel);
}

async function loadThreadList(
  requestId: string,
  api: MoimAPI,
  channelId: string,
  threadId?: string,
) {
  const channel = getInitialData("__entities.channel", requestId);

  if (channel) {
    if (threadId) {
      const beforeThreadList = await api.forum.getThreadList({
        channelId,
        before: threadId,
        sort: channel?.sorting_options?.[0]?.sort ?? "createdAt",
        order: channel?.sorting_options?.[0]?.order ?? "desc",
        inclusive: true,
      });

      const afterThreadList = await api.forum.getThreadList({
        channelId,
        after: threadId,
        sort: channel?.sorting_options?.[0]?.sort ?? "createdAt",
        order: channel?.sorting_options?.[0]?.order ?? "desc",
      });

      setInitialData("__entities.threadList", requestId, {
        data: [...beforeThreadList.data, ...afterThreadList.data],
        paging: {},
      });
    } else {
      const threadList = await api.forum.getThreadList({
        channelId,
        sort: "createdAt",
        order: "DESC",
        limit: 20,
      });

      setInitialData("__entities.threadList", requestId, threadList);
    }
  }
}

async function loadThread(
  requestId: string,
  api: MoimAPI,
  channelId: string,
  threadId: string,
) {
  const thread = await api.forum.getThread({ parentId: channelId, threadId });
  setInitialData("__entities.thread", requestId, thread);
}

async function loadUser(requestId: string, api: MoimAPI) {
  const thread = getInitialData("__entities.thread", requestId);
  if (thread && thread.data.author) {
    const users = await api.user.batchUsers({ users: [thread.data.author] });
    setInitialData("__entities.users", requestId, users);
  }
}

export async function ForumLoadData({
  match,
  api,
  requestId,
}: ISSRLoadDataPayload) {
  await InitialLoadData(requestId);

  const promises: Promise<void>[] = [];

  if (match.params.forumId) {
    await loadChannel(requestId, api, match.params.forumId);

    promises.push(loadPermission(requestId, api, match.params.forumId));
    promises.push(
      loadThreadList(
        requestId,
        api,
        match.params.forumId,
        match.params.threadId,
      ),
    );

    if (match.params.threadId) {
      promises.push(
        loadThread(requestId, api, match.params.forumId, match.params.threadId),
      );
      promises.push(loadPermission(requestId, api, match.params.threadId));
    }
  }

  await Promise.all(promises);
  await loadUser(requestId, api);
}
