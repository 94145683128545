import * as React from "react";
import { FormattedMessage } from "react-intl";
import Clipboard from "react-clipboard.js";
import { PermissionDeniedFallbackType } from "app/enums";
import useCurrentUser from "common/hooks/useCurrentUser";
import { useIntlShort } from "common/hooks/useIntlShort";
import { useSnackbar } from "common/components/alertTemplates/alerts/globalSnackbar/useGlobalSnackbar";
import useCurrentGroup from "common/hooks/useCurrentGroup";
import useCurrentHubGroup from "common/hooks/useCurrentHubGroup";

import { DefaultLayout } from "app/modules/secondaryView/native/layout";
import UnsignedChecker from "common/components/unsiginedChecker";
import {
  AppBarStickyWrapperStyle,
  BottomStickyContainer,
  CopyCodeButton,
  CopyIcon,
  DefaultLayoutBodyStyle,
  RootLayoutBodyStyle,
  ShareLinkButton,
  ShareLinkButtonWrapper,
} from "./styled";
import ReferralBlock from "common/components/referral/referralBlock";
import { Share } from "common/components/snsShareDialog/utils";
import { InvisibleText } from "common/components/shareBase/styledComponents";

export const ReferralContainer = () => {
  const intl = useIntlShort();
  const currentUser = useCurrentUser();
  const currentGroup = useCurrentGroup();
  const currentHubGroup = useCurrentHubGroup();

  const { open: openSuccess } = useSnackbar({
    textElement: <FormattedMessage id={"copy_code_success_toast_message"} />,
    type: "success",
  });
  const { open: openFail } = useSnackbar({
    textElement: <FormattedMessage id={"copy_code_fail_toast_message"} />,
    type: "error",
  });

  const shareUrl = React.useMemo(() => {
    const url = new URL(location.origin);
    if (currentUser?.id) {
      url.searchParams.append("inviterId", currentUser?.id);
    }
    if (currentGroup?.id) {
      url.searchParams.append("groupId", currentGroup?.id);
    }
    return url.toString();
  }, [currentUser, currentGroup]);

  const shareText = React.useMemo(
    () =>
      currentGroup?.is_hub
        ? intl(
            "custom_system_wording_referral_page_share_link_text_parent_moim",
            {
              service_name: currentGroup?.name,
              inviter_name: currentUser?.name,
              referral_code: currentUser?.id,
            },
          )
        : intl(
            "custom_system_wording_referral_page_share_link_text_child_moim",
            {
              service_name: currentHubGroup?.name,
              community_name: currentGroup?.name,
              inviter_name: currentUser?.name,
              referral_code: currentUser?.id,
            },
          ),
    [
      currentGroup?.is_hub,
      currentGroup?.name,
      currentUser?.name,
      currentUser?.id,
    ],
  );

  React.useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <DefaultLayout
      appBar={{
        wrapperStickyStyle: AppBarStickyWrapperStyle,
        enableScrollParallax: true,
      }}
      rootOverrideStyle={RootLayoutBodyStyle}
      bodyOverrideStyle={DefaultLayoutBodyStyle}
    >
      <UnsignedChecker fallbackType={PermissionDeniedFallbackType.SCREEN}>
        <ReferralBlock />
      </UnsignedChecker>
      <BottomStickyContainer>
        <Clipboard
          style={{ flex: 1 }}
          component={"button"}
          onSuccess={openSuccess}
          onError={openFail}
          options={{
            target: e => e.firstElementChild as Element,
          }}
        >
          <InvisibleText>{currentUser?.id}</InvisibleText>
          <CopyCodeButton>
            {currentUser?.id}
            <CopyIcon />
          </CopyCodeButton>
        </Clipboard>
        <ShareLinkButtonWrapper>
          <Share
            shareData={{
              url: shareUrl,
              text: shareText,
            }}
          >
            {handler => (
              <ShareLinkButton onClick={handler}>
                {intl("custom_system_wording_button_referral_page_copy_link")}
              </ShareLinkButton>
            )}
          </Share>
        </ShareLinkButtonWrapper>
      </BottomStickyContainer>
    </DefaultLayout>
  );
};
