import * as React from "react";
import styled, { css, FlattenInterpolation } from "styled-components";

import PositionChip from "common/components/chips/preset/positionChip";
import WithPositionChipV2 from "./new";
import { px2rem } from "common/helpers/rem";

const chipStyle = css`
  & + & {
    margin-left: ${px2rem(4)};
  }
`;

const PositionChipsWrapper = styled.div`
  margin-left: ${px2rem(4)};
  display: flex;
  align-items: center;
`;

const Wrapper = styled.div<{
  elementAlign?: "left" | "right";
  wrapperOverrideStyle?: FlattenInterpolation<any>;
}>`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  ${props =>
    props.elementAlign === "right"
      ? css`
          flex-direction: row-reverse;
          ${PositionChipsWrapper} {
            margin-left: 0;
            margin-right: ${px2rem(4)};
          }
        `
      : null};

  ${props => props.wrapperOverrideStyle}
`;

interface IProps
  extends Omit<React.ComponentProps<typeof WithPositionChipV2>, "positions"> {
  positions: Moim.Position.IPosition[];
}

function WithPositionChipComponent({
  positions,
  hasPositionChip = true,
  elementAlign,
  chipMaxWidth,
  children,
  displayChipLimit = 1,
  wrapperOverrideStyle,
}: React.PropsWithChildren<IProps>) {
  const positionChipElement = React.useMemo(() => {
    const sortedPositions = positions.sort(
      (prev, next) => next.priority - prev.priority,
    );
    if (hasPositionChip && sortedPositions.length > 0) {
      const chips = sortedPositions
        .filter(i => Boolean(i))
        .slice(0, displayChipLimit)
        .map(
          position =>
            position && (
              <PositionChip
                key={`position_${position.id}`}
                id={position.id}
                size="small"
                color={position.color}
                name={position.name}
                maxContentWidth={chipMaxWidth}
                overrideStyle={chipStyle}
              />
            ),
        );
      return <PositionChipsWrapper>{chips}</PositionChipsWrapper>;
    }

    return null;
  }, [positions, hasPositionChip, displayChipLimit, chipMaxWidth]);

  return (
    <Wrapper
      elementAlign={elementAlign}
      wrapperOverrideStyle={wrapperOverrideStyle}
    >
      {children}
      {positionChipElement}
    </Wrapper>
  );
}

const WithPositionChip: React.FC<Omit<
  React.ComponentProps<typeof WithPositionChipComponent>,
  "positions"
> & { positions: string[] | Moim.Position.IPosition[] }> = ({
  positions,
  ...props
}) => {
  if (typeof positions[0] === "string") {
    return <WithPositionChipV2 {...props} positions={positions as string[]} />;
  }

  return (
    <WithPositionChipComponent
      positions={positions as Moim.Position.IPosition[]}
      {...props}
    />
  );
};

export default WithPositionChip;
