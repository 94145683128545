import React from "react";
import styled from "styled-components";
import { px2rem } from "common/helpers/rem";
import { B3Regular } from "common/components/designSystem/typos";
import { Radio } from "common/components/designSystem/inputs";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  min-width: ${px2rem(100)};
  padding: 0 ${px2rem(4)};
  gap: ${px2rem(6)};
`;

const RadioTouchArea = styled.div`
  width: ${px2rem(42)};
  height: ${px2rem(42)};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const RadioInputTitle = styled(B3Regular)`
  color: ${props => props.theme.colorV2.colorSet.grey600};
`;

export const RadioItem: React.FC<{
  name: string;
  label: string;
  checked: boolean;
  value: string;
  onChange(value: string): void;
}> = ({ name, label, checked, value, onChange }) => {
  return (
    <Wrapper>
      <RadioTouchArea>
        <Radio
          name={name}
          checked={checked}
          onChange={() => {
            onChange(value);
          }}
        />
      </RadioTouchArea>
      <RadioInputTitle>{label}</RadioInputTitle>
    </Wrapper>
  );
};
