import * as React from "react";
import {
  Head,
  HeadCaption,
  HeadProgressContainer,
  RegularButton,
  UserProfilePreview,
} from "common/components/dquestMissionary/styled";
import DquestPermissionMessage from "common/components/dquestMissionary/components/dquestPermissionMessage";
import { FormattedMessage } from "react-intl";
import Progress from "common/components/dquestMissionary/components/progressBar";
import useIsMobile from "common/hooks/useIsMobile";
import { shaveWalletAddress } from "common/helpers/nft";
import useCurrentUser from "common/hooks/useCurrentUser";
import { ThemeType } from "common/components/dquestMissionary/component";
import MissionHintMessage from "common/components/dquestMissionary/components/missionHintMessage";

interface IProps {
  isJoinLoading: boolean;
  isAchieveLoading: boolean;
  quest: Moim.DQuest.INormalizedQuest;
  history?: Moim.DQuest.IHistory;
  selectedTheme: ThemeType;
  hasJoinablePermission?: boolean;
  onClickJoin(): void;
  onClickAchieve(): void;
}

const MissionHead = ({
  isJoinLoading,
  isAchieveLoading,
  quest,
  history,
  selectedTheme,
  hasJoinablePermission,
  onClickJoin,
  onClickAchieve,
}: IProps) => {
  const currentUser = useCurrentUser();
  const isMobile = useIsMobile();
  const isReJoinable = history?.reJoinable;

  const phase = React.useMemo(() => {
    if (!history) {
      return "before";
    } else {
      switch (history.status) {
        case "IN_PROGRESS":
          return "in_progress";
        case "ACHIEVED_NOT_REWARDED":
          return "not_rewarded";
        case "ACHIEVED":
          return "done";
        default:
          return "before";
      }
    }
  }, [history]);

  const web3UserName = React.useMemo(() => {
    if (currentUser) {
      return `${currentUser.name}${
        currentUser.metamask
          ? `(${shaveWalletAddress(currentUser.metamask)})`
          : ""
      }`;
    }
    return "Hey fren";
  }, [currentUser]);

  if (
    quest.joinType === "EXPLICIT" &&
    (quest.displayStatus === "READY" || quest.displayStatus === "ACTIVE") &&
    !history
  ) {
    if (isMobile) {
      return null;
    }

    return (
      <Head>
        <HeadCaption selectedTheme={selectedTheme}>
          <UserProfilePreview src={currentUser?.avatar_url} />
          <DquestPermissionMessage
            hasJoinablePermission={hasJoinablePermission}
            web3UserName={web3UserName}
            user={currentUser}
          />
        </HeadCaption>
        <RegularButton
          selectedTheme={selectedTheme}
          disabled={isJoinLoading || quest.displayStatus === "READY"}
          waiting={isJoinLoading}
          onClick={onClickJoin}
        >
          {quest.joinTitle ?? (
            <FormattedMessage id="button_quest_start_default" />
          )}
        </RegularButton>
      </Head>
    );
  }

  if (!history) return null;

  if (history.reJoinable) {
    return (
      <Head>
        <HeadCaption selectedTheme={selectedTheme}>
          <UserProfilePreview src={currentUser?.avatar_url} />
          <MissionHintMessage phase={phase} />
        </HeadCaption>
        <Progress
          isReJoinable={isReJoinable}
          value={0}
          max={history.progressTotal}
          selectedTheme={selectedTheme}
        />
      </Head>
    );
  } else {
    if (quest.achieveType === "EXPLICIT" && quest.displayStatus === "ACTIVE") {
      if (history.status === "ACHIEVED_NOT_REWARDED") {
        if (isMobile) {
          return (
            <Head>
              <HeadCaption selectedTheme={selectedTheme}>
                <UserProfilePreview src={currentUser?.avatar_url} />
                <MissionHintMessage phase={phase} />
              </HeadCaption>
              <Progress
                isReJoinable={isReJoinable}
                value={history.progress}
                max={history.progressTotal}
                selectedTheme={selectedTheme}
              />
            </Head>
          );
        } else {
          return (
            <>
              <Head>
                <HeadCaption selectedTheme={selectedTheme}>
                  <UserProfilePreview src={currentUser?.avatar_url} />
                  <MissionHintMessage phase={phase} />
                </HeadCaption>
                <RegularButton
                  selectedTheme={selectedTheme}
                  disabled={isAchieveLoading}
                  waiting={isAchieveLoading}
                  onClick={onClickAchieve}
                >
                  {quest.achieveTitle ?? (
                    <FormattedMessage id="button_quest_achieve_default" />
                  )}
                </RegularButton>
              </Head>
              <HeadProgressContainer>
                <Progress
                  isReJoinable={isReJoinable}
                  value={history.progress}
                  max={history.progressTotal}
                  selectedTheme={selectedTheme}
                />
              </HeadProgressContainer>
            </>
          );
        }
      } else if (history.status === "ACHIEVED") {
        if (isMobile) {
          return (
            <Head>
              <HeadCaption selectedTheme={selectedTheme}>
                <UserProfilePreview src={currentUser?.avatar_url} />
                <MissionHintMessage phase={phase} />
              </HeadCaption>
              <Progress
                isReJoinable={isReJoinable}
                value={history.progress}
                max={history.progressTotal}
                selectedTheme={selectedTheme}
              />
            </Head>
          );
        }
        return (
          <Head>
            <HeadCaption selectedTheme={selectedTheme}>
              <UserProfilePreview src={currentUser?.avatar_url} />
              <MissionHintMessage phase={phase} />
            </HeadCaption>
            <RegularButton selectedTheme={selectedTheme} disabled={true}>
              {quest.rewardedTitle ?? (
                <FormattedMessage id="button_quest_achieved_default" />
              )}
            </RegularButton>
          </Head>
        );
      }
    }

    return (
      <Head>
        <HeadCaption selectedTheme={selectedTheme}>
          <UserProfilePreview src={currentUser?.avatar_url} />
          <MissionHintMessage phase={phase} />
        </HeadCaption>
        <Progress
          isReJoinable={isReJoinable}
          value={history.progress}
          max={history.progressTotal}
          selectedTheme={selectedTheme}
        />
      </Head>
    );
  }
};

export default MissionHead;
