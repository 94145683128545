import produce from "immer";
import { AllActions } from "app/actions";
import { ImageBrochureTypes } from "app/actions/types";

export interface IImageBrochureState {
  isOpen: boolean;
  currentAssetOwnerId: Moim.Id;
  initialSrc: string;
  isPrivate?: boolean;
}

export const INITIAL_STATE: IImageBrochureState = {
  isOpen: false,
  currentAssetOwnerId: "",
  initialSrc: "",
  isPrivate: false,
};

export function reducer(state = INITIAL_STATE, action: AllActions) {
  return produce(state, draft => {
    switch (action.type) {
      case ImageBrochureTypes.OPEN_IMAGE_BROCHURE_DIALOG: {
        draft.isOpen = true;
        draft.currentAssetOwnerId = action.payload.ownerId;
        draft.initialSrc = "";
        break;
      }

      case ImageBrochureTypes.OPEN_SRC_IMAGE_BROCHURE_DIALOG: {
        draft.isOpen = true;
        draft.currentAssetOwnerId = "";
        draft.initialSrc = action.payload.src;
        draft.isPrivate = action.payload.isPrivate;
        break;
      }

      case ImageBrochureTypes.CLOSE_IMAGE_BROCHURE_DIALOG: {
        draft.isOpen = false;
        draft.currentAssetOwnerId = "";
        draft.initialSrc = "";
        break;
      }
    }
  });
}
