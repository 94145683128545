import styled, { css } from "styled-components";
import { px2rem } from "common/helpers/rem";

const sizeMap = new Map<Moim.DesignSystem.Size, number>([
  ["xs", 18],
  ["s", 24],
  ["m", 36],
  ["l", 48],
  ["xl", 64],
]);

export const TouchWrapper = styled.span.attrs(props => ({
  role: "button",
  "aria-label": props["aria-label"] ?? "button",
}))<{
  size: Moim.DesignSystem.Size;
  touch: number;
}>`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  ${props => {
    const presetSize = sizeMap.get(props.size);
    if (!presetSize) {
      return css`
        width: ${px2rem(props.touch)};
        height: ${px2rem(props.touch)};
      `;
    }
    const gap = Math.round((props.touch - presetSize) / 2);
    return css`
      margin: ${px2rem(gap)};
    `;
  }}
`;

export const SizeWrapper = styled.span<{
  size: Moim.DesignSystem.Size;
}>`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  ${props => {
    const presetSize = sizeMap.get(props.size);
    return css`
      width: ${presetSize ? px2rem(presetSize) : props.size};
      height: ${presetSize ? px2rem(presetSize) : props.size};
    `;
  }}

  svg {
    width: 100%;
    height: 100%;
  }
`;
