import styled from "styled-components";
import { px2rem } from "common/helpers/rem";
import { B3Regular } from "common/components/designSystem/typos";

export const SelectedListWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-right: ${px2rem(4)};
  padding-bottom: ${px2rem(8)};
  max-height: ${px2rem(100)};
`;

export const TextButtonWrapper = styled.div`
  min-width: fit-content;
  padding-bottom: ${px2rem(12)};
  margin-right: ${px2rem(-12)};
`;

export const EmptyTextWrapper = styled.div`
  display: flex;
  align-items: center;
  height: ${px2rem(32)};
  padding-right: ${px2rem(4)};
`;

export const EmptyText = styled(B3Regular)`
  color: ${props => props.theme.colorV2.colorSet.grey200};
`;
