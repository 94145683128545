import { Svg18View0 } from "@moim/icons";
import { B4Regular } from "common/components/designSystem/typos";
import * as React from "react";
import { FormattedNumber } from "react-intl";
import styled from "styled-components";

const Wrapper = styled(B4Regular)`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colorV2.colorSet.grey300};
`;

const ViewCountIcon = styled(Svg18View0).attrs((props) => ({
  size: "xs",
  iconColor: "currentColor",
}))``;

function ViewCount({ count }: { count?: number }) {
  return (
    <Wrapper>
      <ViewCountIcon />
      {count ? <FormattedNumber value={count} notation="compact" /> : null}
    </Wrapper>
  );
}

export default ViewCount;
