import { ThunkPromiseResult } from "app/store";
import { errorParseData } from "common/helpers/APIErrorParser";

export function getOTPTicket({
  domain,
}: {
  domain: string;
}): ThunkPromiseResult<Moim.IOTPTicketResponse> {
  return async (dispatch, getState, { apiSelector }) => {
    try {
      const response = await apiSelector(
        getState(),
        dispatch,
      ).auth.getOTPTicket({ domain });
      return response;
    } catch (err) {
      const error = errorParseData(err);
      return Promise.reject(error);
    }
  };
}
