import React from "react";
import { StaticSelection } from "common/components/designSystem/selection";
import { FormItemHeader } from "../../../header";
import { useIntlShort } from "common/hooks/useIntlShort";
import { FormValueContext } from "../../../context";
import { IRef } from "../../..";
import { useLocaleText } from "common/hooks/localization/useLocaleText";
import useIsMobile from "common/hooks/useIsMobile";
import { useFormItemOptions } from "../../../hooks";
import { SingleSelectionBottomSheetFormItem } from "../bottomSheet/single";

export const SingleDropdownFormItem = React.forwardRef<
  IRef,
  { item: Moim.Form.IFormItem }
>(({ item }, ref) => {
  const intl = useIntlShort();
  const isMobile = useIsMobile();

  const { onChange, values, errors } = React.useContext(FormValueContext);
  const error = errors[item.id];
  const currentValue = values[item.id];
  const title = useLocaleText(item, "title");
  const placeholder = useLocaleText(item, "placeholder");
  const options = useFormItemOptions(item.options);

  React.useImperativeHandle(ref, () => ({
    validate: () => {
      if (item.isRequired && !currentValue) {
        return { [item.id]: intl("error_form_question_required") };
      }
      return { [item.id]: undefined };
    },
  }));

  return (
    <>
      <FormItemHeader item={item} />
      {!isMobile ? (
        <StaticSelection
          isMultiple={false}
          state={error ? "error" : "normal"}
          options={options}
          useSearch={true}
          selected={currentValue}
          placeholder={placeholder}
          onChange={value =>
            onChange(item.id, typeof value === "string" ? value : currentValue)
          }
          size="l"
          reasonText={error}
        />
      ) : (
        <SingleSelectionBottomSheetFormItem
          item={item}
          title={title}
          placeholder={placeholder}
          currentValue={currentValue}
          error={error}
          options={options}
          onChange={onChange}
        />
      )}
    </>
  );
});
