import { rgba } from "polished";
import memoize from "lodash/memoize";
import getColorSetWithRgbaConvert from "../helpers/getColorSet";
import hexToRGBA from "../helpers/hexToRgba";
import { getColorByAlias } from "..";

export const DEFAULT_SYSTEM_COLOR: Moim.Group.ISystemColor = {
  blue: "#0096FF",
  darkBlue: "#1469BF",
  green: "#60ff00",
  darkGreen: "#01D7AB",
  red: "#FB2942",
  yellow: "#FCBA02",
  marine: "#3431CC",
};

function generateColorTheme(
  systemColor: Moim.Group.ISystemColor = DEFAULT_SYSTEM_COLOR,
): Moim.Theme.IColorTheme {
  return {
    blue: systemColor.blue,
    red: systemColor.red,
    red100: rgba(systemColor.red, 0.16),
    yellow: systemColor.yellow,
    green: systemColor.green,
    darkGreen: systemColor.darkGreen,
    darkBlue: systemColor.darkBlue,
    darkBlue100: rgba(systemColor.darkBlue, 0.16),
    marine300: "#3E00FF",
    marine400: "#3431CC",
    marine415: rgba("#3431CC", 0.15),
  };
}

export const generateColorV2Theme = memoize(function(
  colorV2: Moim.Group.IThemePalette,
): Moim.Group.IThemePalette {
  return {
    primary: {
      main: hexToRGBA(colorV2.primary.main ?? ""),
      dark: hexToRGBA(colorV2.primary.dark ?? ""),
      light: hexToRGBA(colorV2.primary.light ?? ""),
      main400: hexToRGBA(colorV2.primary.main400 ?? ""),
      light400: hexToRGBA(colorV2.primary.light400 ?? ""),
    },
    secondary: {
      main: hexToRGBA(colorV2.secondary.main ?? ""),
      dark: hexToRGBA(colorV2.secondary.dark ?? ""),
      light: hexToRGBA(colorV2.secondary.light ?? ""),
      main400: hexToRGBA(colorV2.secondary.main400 ?? ""),
      light400: hexToRGBA(colorV2.secondary.light400 ?? ""),
    },
    accent: getColorByAlias(colorV2, colorV2.accent, colorV2.primary.main)!,
    colorSetV2: getColorSetWithRgbaConvert(colorV2.colorSetV2),
    primaryFogType: colorV2.primaryFogType,
    secondaryFogType: colorV2.secondaryFogType,
    accentFogType: colorV2.accentFogType,
  };
});

export default memoize(generateColorTheme);
