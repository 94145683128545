import _, { debounce } from "lodash";

import { INITIAL_STATE as CAMPAIGN_INITIAL_STATE } from "app/modules/campaign/reducer";
import { IAppState } from "app/rootReducer";
import { IAppStore } from "app/store";
import { entityCacheController } from "common/helpers/entityCacheController";

export function subscribeStoreForCache(store: IAppStore) {
  const saveStateToDB = debounce(async () => {
    const state = store.getState();

    const limitThread = Object.entries(state.entities.threads)
      .slice(0, 100)
      .reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {});
    const limitProduct = Object.entries(state.entities.commerce_product)
      .slice(0, 100)
      .reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {});

    entityCacheController.writeEntities("threads", limitThread);
    entityCacheController.writeEntities("commerce_product", limitProduct);
  }, 500);

  store.subscribe(() => {
    saveStateToDB();
  });
}

export function migrationStoreState(state: IAppState | undefined) {
  if (!state) {
    return undefined;
  }

  const migratedState = state;
  // Note: migration 해야되는 데이터를 여기에서 수정
  migratedState.campaignPage = CAMPAIGN_INITIAL_STATE;

  return migratedState;
}
