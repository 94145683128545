import { Svg18Myshopping1 } from "@moim/icons";
import styled from "styled-components";
import { px2rem } from "common/helpers/rem";
import { GhostButton } from "common/components/designSystem/buttons";
import {
  B1RegularStyle,
  B2RegularStyle,
} from "common/components/designSystem/typos";
import { MEDIA_QUERY } from "common/constants/responsive";


export const SignInButton = styled(GhostButton)`
  width: 100%;
  min-width: ${px2rem(120)};
`;

export const ButtonMenuContainer = styled.div`
  width: 100%;
  padding: 0 ${px2rem(16)};
  padding-bottom: ${px2rem(8)};
`;

export const MenuText = styled.span`
  color: ${props => props.theme.colorV2.colorSet.grey800};

  @media ${MEDIA_QUERY.EXCEPT_MOBILE} {
    ${B2RegularStyle}
  }

  @media ${MEDIA_QUERY.ONLY_MOBILE} {
    ${B1RegularStyle}
  }
`;

export const MyShoppingIcon = styled(Svg18Myshopping1).attrs(props => ({
  size: "xs",
  color: props.theme.colorV2.colorSet.grey800,
}))``;
