import { getHubSellerSelector } from "app/selectors/commerce";
import { ThunkResult, useActions } from "app/store";
import _ from "lodash";

export type BuyNowValidationErrorType =
  | {
      type: "NOT_AVAILABLE_FOR_GUEST";
      metaData?: {
        productId?: string;
      };
    }
  | {
      type: "INVALID_RIGHT_HOLDER";
      metaData?: { type?: "ANYONE" | "MEMBER" | "LIMITED" };
    }
  | {
      type: "INVALID_LIMITATION";
      metaData?: { quantity?: number };
    }
  | {
      type: "EXCEED_AMOUNT_ADDITIONAL_FEE";
      metaData?: {
        additionalFees?: (Moim.Commerce.IProductAdditionalFee & {
          errorCode?: "EXCEED_AMOUNT";
          errorMessage?: string;
        })[];
      };
    }
  | undefined;

function validateGuest(
  calculate: Moim.Commerce.IPaymentCalcResponse,
): ThunkResult<BuyNowValidationErrorType> {
  return (_dispatch, getState) => {
    const state = getState();
    if (state.app.currentUserId) {
      return;
    }
    const hubSeller = getHubSellerSelector(state);
    const enableGuestCheckout = getHubSellerSelector(state)?.config
      .guestCheckoutEnabled;
    if (!hubSeller || !enableGuestCheckout) {
      return {
        type: "NOT_AVAILABLE_FOR_GUEST",
      };
    }

    const errorItem = _.flattenDeep(
      calculate.items.map(item =>
        item.items.map(_item =>
          _item.items.filter(_item2 => {
            if (
              _item2.error?.code === "INVALID_HOLDER" &&
              _item2.error.metadata?.type === "MEMBER"
            ) {
              return true;
            }
            const productId = _item2.productId;
            const product = state.entities.commerce_product[productId];
            if (!product) {
              true;
            }
            const hasAdditionalPrice = Boolean(
              product.additionalFeeInfos?.length,
            );
            const isNormalProduct =
              product.type === "normal" && !product.activeTeamBuyingId;

            return hasAdditionalPrice || !isNormalProduct;
          }),
        ),
      ),
    )?.[0] as any;

    if (errorItem) {
      return {
        type: "NOT_AVAILABLE_FOR_GUEST",
        metaData: { productId: errorItem.productId },
      };
    }

    const errorProductId = _.uniq(
      _.flattenDeep(
        calculate.items.map(item =>
          item.items.map(_item => _item.items.map(_item2 => _item2.productId)),
        ),
      ),
    ).find(productId => {
      const product = state.entities.commerce_product[productId];
      if (!product) {
        true;
      }
      const hasAdditionalPrice = Boolean(product.additionalFeeInfos?.length);
      const isNormalProduct =
        product.type === "normal" && !product.activeTeamBuyingId;

      return hasAdditionalPrice || !isNormalProduct;
    });

    if (errorProductId) {
      return {
        type: "NOT_AVAILABLE_FOR_GUEST",
        metaData: {
          productId: errorProductId,
        },
      };
    }
  };
}

function validateRightHolder(
  calculate: Moim.Commerce.IPaymentCalcResponse,
): ThunkResult<BuyNowValidationErrorType> {
  return _dispatch => {
    const error = (_.flattenDeep(
      calculate.items.map(item =>
        item.items.map(_item =>
          _item.items
            .map(_item2 => _item2.error)
            .filter(error => error?.code === "INVALID_HOLDER"),
        ),
      ),
    ) as Moim.Commerce.ProductGroupItemRightHolderErrorType[])?.[0];

    if (error) {
      return {
        type: "INVALID_RIGHT_HOLDER",
        metaData: error.metadata,
      };
    }
  };
}

function validateLimitation(
  calculate: Moim.Commerce.IPaymentCalcResponse,
): ThunkResult<BuyNowValidationErrorType> {
  return _dispatch => {
    const error = (_.flattenDeep(
      calculate.items.map(item =>
        item.items.map(_item =>
          _item.items
            .map(_item2 => _item2.error)
            .filter(
              error =>
                error &&
                (error.code === "INVALID_MAX_LIMITATION" ||
                  error.code === "INVALID_MIN_LIMITATION"),
            ),
        ),
      ),
    ) as Moim.Commerce.ProductGroupItemLimitationErrorType[])?.[0];

    if (error) {
      return {
        type: "INVALID_LIMITATION",
        metaData: error.metadata,
      };
    }
  };
}

function validateAdditionFee(
  calculate: Moim.Commerce.IPaymentCalcResponse,
): ThunkResult<BuyNowValidationErrorType> {
  return _dispatch => {
    if (!calculate.totalAdditionalFees) {
      return;
    }
    const exceedAmountAdditionalFees = calculate.totalAdditionalFees?.filter(
      fee => fee.errorCode === "EXCEED_AMOUNT",
    );
    if (exceedAmountAdditionalFees?.length) {
      return {
        type: "EXCEED_AMOUNT_ADDITIONAL_FEE",
        metaData: {
          additionalFees: exceedAmountAdditionalFees,
        },
      };
    }
    return;
  };
}
export function validateBuyNow(
  calculate: Moim.Commerce.IPaymentCalcResponse,
): ThunkResult<BuyNowValidationErrorType> {
  return dispatch => {
    const guestError = dispatch(validateGuest(calculate));
    if (guestError) {
      return guestError;
    }

    const rightHolderError = dispatch(validateRightHolder(calculate));
    if (rightHolderError) {
      return rightHolderError;
    }

    const limitationError = dispatch(validateLimitation(calculate));
    if (limitationError) {
      return limitationError;
    }

    const additionFeeError = dispatch(validateAdditionFee(calculate));
    if (additionFeeError) {
      return additionFeeError;
    }
  };
}

export function useValidateBuyNow() {
  const { dispatchValidateBuyNow } = useActions({
    dispatchValidateBuyNow: validateBuyNow,
  });
  return dispatchValidateBuyNow;
}
