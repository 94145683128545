import { isPathnameIncludeMoimHost } from "app/common/helpers/hostnameParser";
import { getSubdomain } from "../hostnameParser";

export const env = (() => {
  // tslint:disable:no-object-literal-type-assertion
  if (process.env.NODE_ENV && process.env.NODE_ENV === "test") {
    // Test env
    return { platform: "test", stage: "test" } as const;
  } else if (Boolean(process.env.SSR_ENABLED)) {
    // Server side rendering production environment
    if (process.env.PHASE === "PRODUCTION") {
      // Browser production env
      return {
        platform: "server",
        stage: "prod",
        renderer: "server",
      } as const;
    } else if (process.env.PHASE === "STAGING") {
      return {
        platform: "server",
        stage: "stage",
        renderer: "server",
      } as const;
    }
    return { platform: "server", stage: "dev", renderer: "server" } as const;
  } else {
    if (process.env.PHASE === "PRODUCTION") {
      // Browser production env
      return {
        platform: "browser",
        stage: "prod",
        renderer: "client",
      } as const;
    } else if (process.env.PHASE === "STAGING") {
      return {
        platform: "browser",
        stage: "stage",
        renderer: "client",
      } as const;
    } else {
      // Browser local(development) env
      return { platform: "browser", stage: "dev" } as const;
    }
  }
  // tslint:enable:no-object-literal-type-assertion
})();

export function isProd(): boolean {
  return (
    (env.platform === "server" || env.platform === "browser") &&
    env.stage === "prod"
  );
}

export function isStage(): boolean {
  return (
    (env.platform === "server" || env.platform === "browser") &&
    env.stage === "stage"
  );
}

export function isDev(): boolean {
  return env.stage === "dev";
}

export function isTest(): boolean {
  return env.stage === "test";
}

export function isServer(): boolean {
  return env.platform === "server";
}

export function isBrowser(): boolean {
  return !isServer() && !isTest();
}


export function isSecondaryWindow(): boolean {
  return isBrowser() && window.name !== "";
}

export function isHubDomain(pathname?: string) {
  return (
    (getSubdomain(pathname) === "www" || getSubdomain(pathname) === "") &&
    isPathnameIncludeMoimHost(pathname)
  );
}

export function isGroupDomain() {
  return !isHubDomain();
}
