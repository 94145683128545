import styled, { css } from "styled-components";
import {
  H1RegularStyle,
  H1BoldStyle,
  H2RegularStyle,
  H2BoldStyle,
  H3RegularStyle,
  H3BoldStyle,
  H4RegularStyle,
  H4BoldStyle,
  H5RegularStyle,
  H5BoldStyle,
  H6RegularStyle,
  H6BoldStyle,
  H7RegularStyle,
  H7BoldStyle,
  H8RegularStyle,
  H8BoldStyle,
  H9RegularStyle,
  H9BoldStyle,
  H10RegularStyle,
  H10BoldStyle,
  B1RegularStyle,
  B1BoldStyle,
  pB1RegularStyle,
  pB1BoldStyle,
  B2RegularStyle,
  B2BoldStyle,
  pB2RegularStyle,
  pB2BoldStyle,
  B3RegularStyle,
  B3BoldStyle,
  pB3RegularStyle,
  pB3BoldStyle,
  B4RegularStyle,
  B4BoldStyle,
  pB4RegularStyle,
  pB4BoldStyle,
  CaptionRegularStyle,
} from "common/components/designSystem/typos";
import { px2rem } from "common/helpers/rem";

interface IFontStyle {
  color?: string;
  align?: Moim.Blockit.V2.IBlockAlignment;
  border?: Moim.Blockit.V2.IBlockitBorder;
  weight?: number;
  fontFamily?: string;
  letterSpacing?: number;
}

const baseWrapperStyle = css<IFontStyle>`
  display: inline-block;
  white-space: pre-wrap;
  width: 100%;

  ${props =>
    props.align &&
    css`
      text-align: ${props.align};
    `};
  ${props =>
    props.color
      ? css`
          color: ${props.theme.getColorByAlias(props.color)};
        `
      : css`
          color: ${props.theme.colorV2.colorSet.grey800};
        `};

  ${props =>
    props.border?.radius
      ? css`
          border-radius: ${px2rem(props.border.radius)};
        `
      : undefined};

  ${props =>
    props.border?.thickness
      ? css`
          border-width: ${px2rem(props.border.thickness)};
        `
      : undefined};

  ${props =>
    props.border?.color
      ? css`
          border-color: ${props.theme.getColorByAlias(props.border.color)};
        `
      : undefined};

  ${props =>
    props.weight &&
    css`
      font-weight: ${props.weight};
    `}

  ${props =>
    props.fontFamily &&
    css`
      font-family: ${props.fontFamily};
    `}

  ${props =>
    props.letterSpacing !== undefined
      ? css`
          letter-spacing: ${px2rem(props.letterSpacing)};
        `
      : undefined}

  &[role="button"]:hover {
    text-decoration: underline;
  }
`;

/**
 * HEADING 1
 */

export const H1Regular = styled.h1`
  ${baseWrapperStyle}
  display: block;
  ${H1RegularStyle}
`;
export const H1Bold = styled.h1`
  ${baseWrapperStyle}
  display: block;
  ${H1BoldStyle}
`;

/**
 * HEADING 2
 */

export const H2Regular = styled.h2`
  ${baseWrapperStyle}
  display: block;
  ${H2RegularStyle}
`;

export const H2Bold = styled.h2`
  ${baseWrapperStyle}
  display: block;
  ${H2BoldStyle}
`;

/**
 * HEADING 3
 */

export const H3Regular = styled.h3`
  ${baseWrapperStyle}
  display: block;
  ${H3RegularStyle}
`;

export const H3Bold = styled.h3`
  ${baseWrapperStyle}
  display: block;
  ${H3BoldStyle}
`;

/**
 * HEADING 4
 */

export const H4Regular = styled.h4`
  ${baseWrapperStyle}
  display: block;
  ${H4RegularStyle};
`;
export const H4Bold = styled.h4`
  ${baseWrapperStyle}
  display: block;
  ${H4BoldStyle};
`;

/**
 * HEADING 5
 */

export const H5Regular = styled.h5`
  ${baseWrapperStyle}
  display: block;
  ${H5RegularStyle};
`;
export const H5Bold = styled.h5`
  ${baseWrapperStyle}
  display: block;
  ${H5BoldStyle};
`;

/**
 * HEADING 6
 */

export const H6Regular = styled.h6`
  ${baseWrapperStyle}
  display: block;
  ${H6RegularStyle};
`;
export const H6Bold = styled.h6`
  ${baseWrapperStyle}
  display: block;
  ${H6BoldStyle};
`;

/**
 * HEADING 7
 */

export const H7Regular = styled.div`
  ${baseWrapperStyle}
  display: block;
  ${H7RegularStyle};
`;
export const H7Bold = styled.div`
  ${baseWrapperStyle}
  display: block;
  ${H7BoldStyle};
`;

/**
 * HEADING 8
 */

export const H8Regular = styled.div`
  ${baseWrapperStyle}
  display: block;
  ${H8RegularStyle};
`;
export const H8Bold = styled.div`
  ${baseWrapperStyle}
  display: block;
  ${H8BoldStyle};
`;

/**
 * HEADING 9
 */

export const H9Regular = styled.div`
  ${baseWrapperStyle}
  display: block;
  ${H9RegularStyle};
`;
export const H9Bold = styled.div`
  ${baseWrapperStyle}
  display: block;
  ${H9BoldStyle};
`;

/**
 * HEADING 10
 */

export const H10Regular = styled.div`
  ${baseWrapperStyle}
  display: block;
  ${H10RegularStyle};
`;
export const H10Bold = styled.div`
  ${baseWrapperStyle}
  display: block;
  ${H10BoldStyle};
`;

/**
 * Body 1
 */

export const B1Regular = styled.div`
  ${baseWrapperStyle}
  ${B1RegularStyle};
`;
export const B1Bold = styled.div`
  ${baseWrapperStyle}
  ${B1BoldStyle};
`;

/**
 * paragraph Body 1
 */

export const PB1Regular = styled.div`
  ${baseWrapperStyle}
  ${pB1RegularStyle};
`;
export const PB1Bold = styled.div`
  ${baseWrapperStyle}
  ${pB1BoldStyle};
`;

/**
 * Body 2
 */

export const B2Regular = styled.div`
  ${baseWrapperStyle}
  ${B2RegularStyle};
`;
export const B2Bold = styled.div`
  ${baseWrapperStyle}
  ${B2BoldStyle};
`;

/**
 * paragraph Body 2
 */

export const PB2Regular = styled.div`
  ${baseWrapperStyle}
  ${pB2RegularStyle};
`;
export const PB2Bold = styled.div`
  ${baseWrapperStyle}
  ${pB2BoldStyle};
`;

/**
 * Body 3
 */

export const B3Regular = styled.div`
  ${baseWrapperStyle}
  ${B3RegularStyle};
`;
export const B3Bold = styled.div`
  ${baseWrapperStyle}
  ${B3BoldStyle};
`;

/**
 * paragraph Body 3
 */

export const PB3Regular = styled.div`
  ${baseWrapperStyle}
  ${pB3RegularStyle};
`;
export const PB3Bold = styled.div`
  ${baseWrapperStyle}
  ${pB3BoldStyle};
`;

/**
 * Body 4
 */

export const B4Regular = styled.div`
  ${baseWrapperStyle}
  ${B4RegularStyle};
`;
export const B4Bold = styled.div`
  ${baseWrapperStyle}
  ${B4BoldStyle};
`;

/**
 * paragraph Body 4
 */

export const PB4Regular = styled.div`
  ${baseWrapperStyle}
  ${pB4RegularStyle};
`;
export const PB4Bold = styled.div`
  ${baseWrapperStyle}
  ${pB4BoldStyle};
`;

/**
 * LABEL
 */

export const CaptionRegular = styled.label`
  ${baseWrapperStyle}
  ${CaptionRegularStyle};
`;

export const PlainText = styled.span<IFontStyle>`
  ${baseWrapperStyle};
`;
