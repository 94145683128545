import styled, { css } from "styled-components";

import { px2rem } from "common/helpers/rem";
import { B4Regular, H9Bold } from "app/common/components/designSystem/typos";

export const Inner = styled.div`
  padding: 0 ${px2rem(16)};
`;

export const Title = styled(H9Bold)`
  padding: ${px2rem(6)} ${px2rem(16)};
  font-weight: ${props => props.theme.font.bold};
  color: ${props => props.theme.colorV2.colorSet.grey800};
`;

export const InputWrapper = styled.div`
  padding: 0 ${px2rem(16)};
  border-radius: ${px2rem(16)};
  border: solid ${px2rem(1)} ${props => props.theme.colorV2.colorSet.grey100};
  min-height: ${px2rem(110)};

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const MinimumCharactersCount = styled(B4Regular)`
  margin-top: ${px2rem(8)};
  display: flex;
  justify-content: flex-end;
  gap: ${px2rem(4)};
  color: ${props => props.theme.colorV2.colorSet.grey200};
  .count {
    color: ${props => props.theme.colorV2.colorSet.grey500};
  }
`;

export const EditorInputInner = styled(Inner)<{ error?: string }>`
  ${props =>
    Boolean(props.error) &&
    css`
      ${InputWrapper} {
        border-color: ${props => props.theme.color.red};
      }

      ${MinimumCharactersCount} {
        * {
          color: ${props => props.theme.color.red};
        }
      }
    `}
`;
