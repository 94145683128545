import styled from "styled-components";
import { px2rem } from "common/helpers/rem";

export const ListWrapper = styled.div`
  width: 100%;
  height: fit-content;
`;

export const QuestPreviewContainer = styled.div`
  width: 100%;
  margin-bottom: ${px2rem(8)};
`;
