/* eslint-disable no-underscore-dangle */
// vendor
import { Reducer, combineReducers } from "redux";
import { connectRouter, RouterState } from "connected-react-router";
import { History } from "history";
// helper
import { entityCacheController } from "common/helpers/entityCacheController";
// common components
import * as SecondaryViewReducer from "app/modules/secondaryView/reducer";
import * as ImageBrochureReducer from "common/components/imageBrochure/reducer";
import * as BlockitModalReducer from "common/components/blockitModal/reducer";
import * as PageLoadingIndicatorReducer from "common/components/pageLoadingIndicator/reducer";
import * as MoimLeaveReducer from "app/modules/personalSetting/containers/moimLeave/reducer";
import * as CreateMeetingDialogReducer from "common/components/createMeetingDialog/reducer";
import * as MyReferralPerformanceDialogReducer from "common/components/myReferralPerformanceDialog/reducer";
import * as MyReferralDialogReducer from "common/components/myReferralDialog/reducer";

// Reducers
import * as AppReducer from "./reducers/app";
import * as ConversationReducer from "./reducers/conversation";
import * as ConversationPageReducer from "./modules/conversation/reducer";
import * as GroupReducer from "app/reducers/group";
import * as EntityReducer from "./reducers/entity";
import * as ForumDataReducer from "./reducers/forum";
import * as SideNavigationReducer from "./reducers/sideNavigation";
import * as ForumEditorPageReducer from "./modules/forum/containers/forumEditor/reducer";
import * as CommentListPageReducer from "./modules/postShow/components/bottom/components/commentList/reducer";
import * as ProfilePageReducer from "./modules/profile/reducer";
import * as ProfileReducer from "./reducers/profile";
import * as MeetingReducer from "./reducers/meeting";
import * as CommerceReducer from "./reducers/commerce";
import * as CampaignDataReducer from "./reducers/campaign";
import * as SnackbarReducer from "app/reducers/snackbar";
import * as PositionReducer from "app/reducers/position";
import * as ThreadReducer from "app/reducers/thread";
import * as NftReducer from "app/reducers/nft";
import * as ChildMoimGroupReducer from "app/reducers/childMoimGroup";

import * as ProfileEditorPageReducer from "app/modules/profileEditor/reducer";
import * as ChannelReducer from "app/reducers/channel";
import * as SubGroupReducer from "app/reducers/subgroup";
import * as PermissionReducer from "app/reducers/permission";
import * as SearchReducer from "app/reducers/search";
import * as DirectMessageReducer from "app/reducers/directMessage";
import * as NotificationReducer from "app/reducers/notification";
import * as DraftReducer from "app/reducers/draft";
import * as SearchPageReducer from "app/reducers/searchPage";
import * as PostTemplateReducer from "app/reducers/postTemplate";
import * as CommercePageReducer from "app/modules/commerce/reducer";
import * as CampaignPageReducer from "app/modules/campaign/reducer";
import * as PluginReducer from "app/reducers/plugins";
import * as ContentsGroupReducer from "./reducers/contentsGroup";
import * as PromoteReducer from "./reducers/promote";
import * as DQuestReducer from "./reducers/dquest";
import * as CommunityCoinReducer from "./reducers/community/coin";
import * as CryptobadgeReducer from "./reducers/cryptobadge";
import * as UserReducer from "./reducers/userPageData";
import * as ComponentLayoutReducer from "./reducers/componentLayout";

// DialogReducer
import * as ProfileDialogReducer from "./modules/profileDialog/reducer";
import * as NewDirectMessageDialogPageReducer from "app/modules/newDirectMessageDialog/reducer";
import * as PositionApplyDialogReducer from "common/components/positionApplyDialog/reducer";
import * as SNSShareDialogReducer from "common/components/snsShareDialog/reducer";
import * as ChannelNotificationSettingDialogReducer from "common/components/channelNotificationSettingDialog/reducer";
import * as JoinGroupDialogReducer from "app/common/components/dialogs/joinGroupDialog/reducer";
import * as PositionFormDialogReducer from "app/reducers/positionFormDialog";
import * as GlobalReportDialogReducer from "common/components/reportDialog/base/reducer";
import * as PopupBannerReducer from "common/components/popupBanner/reducer";
import * as DQuestCompleteDialogReducer from "app/modules/dquest/containers/completeDialog/reducer";
import * as PurchasePreparationDialogReducer from "common/components/dialogs/purchasePreparationDialog/reducer";
import * as GlobalAlertDialogReducer from "common/components/dialogs/globalAlertDialog/reducer";
import * as GlobalUserStatusFeedbackDialogReducer from "common/components/dialogs/globalUserStatusFeedbackDialog/reducer";
import * as GlobalDuplicatePostDialogReducer from "common/components/dialogs/globalDuplicatePostDialog/reducer";
import * as GlobalMovePostDialogReducer from "common/components/dialogs/globalMovePostDialog/reducer";
// Hub
import * as HubPageReducer from "./hub/reducer";
import * as HubAppStore from "./reducers/hub";
import * as ForumListPageReducer from "./modules/forum/containers/forumThreadList/reducer";
import { isBrowser, isTest } from "common/helpers/envChecker";
import { initialStateHelper } from "./reducers/helpers/loadInitialState";

export interface IGroupAppState {
  router: RouterState;
  conversation: ConversationReducer.IConversationState;
  conversationPage: ConversationPageReducer.IConversationPageData;
  app: AppReducer.IAppGlobalState;
  hub: HubAppStore.IHubAppStore;
  hubPage: HubPageReducer.IHubPageState;
  entities: Moim.Entity.INormalizedData;
  group: Moim.Group.IGroupData;
  forumData: Moim.Forum.IForumData;
  forumListPage: ForumListPageReducer.IForumListPageData;
  forumEditorPage: ForumEditorPageReducer.IForumEditorPage;
  forumCommentListPage: CommentListPageReducer.ICommentPageData;
  profileData: ProfileReducer.IProfileState;
  profilePage: ProfilePageReducer.IProfilePageState;
  imageBrochure: ImageBrochureReducer.IImageBrochureState;
  sideNavigation: SideNavigationReducer.ISideNavigationState;
  snackbar: SnackbarReducer.ISnackbarState;
  subgroupsData: Moim.SubGroup.ISubGroupData;
  position: PositionReducer.IPositionState;
  profileEditorPage: ProfileEditorPageReducer.IProfileEditorState;
  channelData: ChannelReducer.IChannelState;
  permission: Moim.Permission.IPermissionData;
  secondaryViewPage: SecondaryViewReducer.IState;
  search: SearchReducer.ISearchState;
  directMessage: DirectMessageReducer.IDirectMessageState;
  notification: NotificationReducer.INotificationState;
  blockitModal: BlockitModalReducer.IReduxState;
  pageLoadingIndicator: PageLoadingIndicatorReducer.IState;
  draftState: DraftReducer.IDraftState;
  searchPageData: SearchPageReducer.ISearchState;
  moimLeavePage: MoimLeaveReducer.IState;
  meeting: Moim.Meeting.IReduxState;
  postTemplate: PostTemplateReducer.IPostTemplateState;
  commerce: Moim.Commerce.IReduxState;
  commercePage: CommercePageReducer.IReduxState;
  campaignData: Moim.Campaign.IReduxState;
  campaignPage: CampaignPageReducer.IReduxState;
  plugins: PluginReducer.IReduxState;
  contentsGroup: Moim.ContentsGroup.IReduxState;
  promote: PromoteReducer.IReduxState;
  thread: ThreadReducer.IReduxState;
  nft: NftReducer.INftState;
  childMoimGroup: Moim.ChildMoimGroup.IReduxState;
  dquest: DQuestReducer.IDQuestState;
  communityCoin: CommunityCoinReducer.ICoinState;
  cryptobadge: CryptobadgeReducer.ICryptobadgeState;
  userPageData: UserReducer.IUserPageDataState;

  componentLayout: ComponentLayoutReducer.IState;
  // dialog
  profileDialog: ProfileDialogReducer.IProfileDialogState;
  joinGroupDialog: Moim.Group.IJoinGroupDialogState;
  positionFormDialog: PositionFormDialogReducer.IPositionFormState;
  newDirectMessageDialog: Moim.DirectMessage.IDirectMessageDialogState;
  positionApplyDialog: PositionApplyDialogReducer.IPositionApplyState;
  SNSShareDialog: SNSShareDialogReducer.ISNSShareDialogDialogState;
  createMeetingDialog: CreateMeetingDialogReducer.IReduxState;
  channelNotificationSettingDialog: ChannelNotificationSettingDialogReducer.IChannelNotificationSettingDialogState;
  myReferralPerformanceDialog: MyReferralPerformanceDialogReducer.IMyInviteeListDialogState;
  myReferralDialog: MyReferralDialogReducer.IMyReferralDialogState;
  globalReportDialog: GlobalReportDialogReducer.IGlobalReportDialogState;
  popupBanner: PopupBannerReducer.IPopupBannerState;
  dquestCompleteDialog: DQuestCompleteDialogReducer.IDQuestCompleteDialogState;
  purchasePreparationDialog: PurchasePreparationDialogReducer.IState;
  globalAlertDialog: GlobalAlertDialogReducer.IState;
  globalUserStatusFeedbackDialog: GlobalUserStatusFeedbackDialogReducer.IState;
  globalDuplicatePostDialog: GlobalDuplicatePostDialogReducer.IState;
  globalMovePostDialog: GlobalMovePostDialogReducer.IState;
}

export type IAppState = IGroupAppState;

const originInitialState: (requestId?: string) => IAppState = (
  requestId?: string,
) => ({
  router: undefined as any,
  conversation: ConversationReducer.INITIAL_STATE,
  conversationPage: ConversationPageReducer.INITIAL_STATE,
  app: initialStateHelper(AppReducer.APP_INITIAL_STATE, requestId),
  hub: HubAppStore.initialState,
  hubPage: HubPageReducer.INITIAL_STATE,
  entities: initialStateHelper(EntityReducer.INITIAL_DATA, requestId),
  group: initialStateHelper(GroupReducer.INITIAL_STATE, requestId),
  forumData: initialStateHelper(ForumDataReducer.INITIAL_STATE, requestId),
  forumListPage: initialStateHelper(
    ForumListPageReducer.INITIAL_STATE,
    requestId,
  ),
  forumEditorPage: ForumEditorPageReducer.INITIAL_STATE,
  forumCommentListPage: CommentListPageReducer.INITIAL_STATE,
  profileData: ProfileReducer.INITIAL_STATE,
  profilePage: ProfilePageReducer.INITIAL_STATE,
  imageBrochure: ImageBrochureReducer.INITIAL_STATE,
  sideNavigation: initialStateHelper(
    SideNavigationReducer.INITIAL_STATE,
    requestId,
  ),
  snackbar: SnackbarReducer.INITIAL_STATE,
  subgroupsData: SubGroupReducer.INITIAL_STATE,
  position: initialStateHelper(PositionReducer.INITIAL_STATE, requestId),
  profileEditorPage: ProfileEditorPageReducer.INITIAL_STATE,
  channelData: initialStateHelper(ChannelReducer.INITIAL_STATE, requestId),
  permission: initialStateHelper(PermissionReducer.INITIAL_STATE, requestId),
  secondaryViewPage: SecondaryViewReducer.INITIAL_STATE,
  search: SearchReducer.INITIAL_STATE,
  directMessage: DirectMessageReducer.INITIAL_STATE,
  notification: NotificationReducer.INITIAL_STATE,
  blockitModal: BlockitModalReducer.INITIAL_STATE,
  pageLoadingIndicator: PageLoadingIndicatorReducer.INITIAL_STATE,
  draftState: DraftReducer.INITIAL_STATE,
  searchPageData: SearchPageReducer.INITIAL_STATE,
  moimLeavePage: MoimLeaveReducer.INITIAL_STATE,
  meeting: MeetingReducer.INITIAL_STATE,
  postTemplate: PostTemplateReducer.INITIAL_STATE,
  commerce: initialStateHelper(CommerceReducer.INITIAL_STATE, requestId),
  commercePage: CommercePageReducer.INITIAL_STATE,
  campaignData: CampaignDataReducer.INITIAL_STATE,
  campaignPage: CampaignPageReducer.INITIAL_STATE,
  plugins: PluginReducer.INITIAL_STATE,
  contentsGroup: ContentsGroupReducer.INITIAL_STATE,
  promote: initialStateHelper(PromoteReducer.INITIAL_STATE, requestId),
  thread: initialStateHelper(ThreadReducer.INITIAL_STATE, requestId),
  nft: NftReducer.INITIAL_STATE,
  childMoimGroup: ChildMoimGroupReducer.INITIAL_STATE,
  dquest: DQuestReducer.INITIAL_STATE,
  communityCoin: CommunityCoinReducer.INITIAL_STATE,
  cryptobadge: CryptobadgeReducer.INITIAL_STATE,
  userPageData: UserReducer.INITIAL_STATE,
  componentLayout: initialStateHelper(
    ComponentLayoutReducer.INITIAL_STATE,
    requestId,
  ),

  // dialog
  profileDialog: ProfileDialogReducer.INITIAL_STATE,
  joinGroupDialog: JoinGroupDialogReducer.INITIAL_STATE,
  positionFormDialog: PositionFormDialogReducer.INITIAL_STATE,
  newDirectMessageDialog: NewDirectMessageDialogPageReducer.INITIAL_STATE,
  positionApplyDialog: PositionApplyDialogReducer.INITIAL_STATE,
  SNSShareDialog: SNSShareDialogReducer.INITIAL_STATE,
  channelNotificationSettingDialog:
    ChannelNotificationSettingDialogReducer.INITIAL_STATE,
  createMeetingDialog: CreateMeetingDialogReducer.INITIAL_STATE,
  myReferralPerformanceDialog: MyReferralPerformanceDialogReducer.INITIAL_STATE,
  myReferralDialog: MyReferralDialogReducer.INITIAL_STATE,
  globalReportDialog: GlobalReportDialogReducer.INITIAL_STATE,
  popupBanner: PopupBannerReducer.INITIAL_STATE,
  dquestCompleteDialog: DQuestCompleteDialogReducer.INITIAL_STATE,
  purchasePreparationDialog: PurchasePreparationDialogReducer.INITIAL_STATE,
  globalAlertDialog: GlobalAlertDialogReducer.INITIAL_STATE,
  globalUserStatusFeedbackDialog:
    GlobalUserStatusFeedbackDialogReducer.INITIAL_STATE,
  globalDuplicatePostDialog: GlobalDuplicatePostDialogReducer.INITIAL_STATE,
  globalMovePostDialog: GlobalMovePostDialogReducer.INITIAL_STATE,
});

/**
 * preloadCachedData should working only in browser properly
 *
 * BEFORE USE **preloadCachedData** should properly be fetched by **fetchPreloadCachedData**
 */
let preloadCachedData: any;
export const fetchPreloadCachedData = async () => {
  if (isBrowser()) {
    const [threadPromise, productPromise] = await Promise.allSettled<any[]>([
      entityCacheController.fetchEntities("threads"),
      entityCacheController.fetchEntities("commerce_product"),
    ]);

    preloadCachedData = {
      threads: threadPromise.status === "fulfilled" ? threadPromise.value : {},
      commerce_product:
        productPromise.status === "fulfilled" ? productPromise.value : {},
    };
  }
};

const applyCacheToEntities = (
  entities?: Record<Moim.Entity.NormalizedKey, any>,
) => {
  if (entities) {
    return {
      ...entities,
      threads: {
        ...entities.threads,
        ...(preloadCachedData?.threads ?? {}),
      },
      commerce_product: {
        ...entities.commerce_product,
        ...(preloadCachedData?.commerce_product ?? {}),
      },
    };
  }
  return {
    threads: {
      ...(preloadCachedData?.threads ?? {}),
    },
    commerce_product: {
      ...(preloadCachedData?.commerce_product ?? {}),
    },
  };
};

export const initialStateGenerator: (requestId?: string) => IAppState = (
  requestId?: string,
) => {
  try {
    if (isBrowser()) {
      if (
        (window as any).__MOIM_SSR_CONTENT__ &&
        (window as any).__INITIAL_STATE__
      ) {
        return {
          ...(window as any).__INITIAL_STATE__,
          entities: applyCacheToEntities(
            (window as any).__INITIAL_STATE__.entities,
          ),
        };
      }

      return {
        ...originInitialState(requestId),
        entities: applyCacheToEntities(originInitialState(requestId).entities),
      };
    }
    return originInitialState(requestId);
  } catch {
    return originInitialState(requestId);
  }
};

export const initialState = isTest()
  ? originInitialState()
  : initialStateGenerator();

export function createAppReducers(history: History): Reducer<IGroupAppState> {
  return combineReducers({
    router: connectRouter(history),
    app: AppReducer.reducer,
    hub: HubAppStore.hubReducers,
    hubPage: HubPageReducer.reducer,
    conversation: ConversationReducer.reducer,
    conversationPage: ConversationPageReducer.reducer,
    entities: EntityReducer.reducer,
    group: GroupReducer.reducer,
    forumData: ForumDataReducer.reducers,
    forumListPage: ForumListPageReducer.reducer,
    forumEditorPage: ForumEditorPageReducer.reducer,
    forumCommentListPage: CommentListPageReducer.reducer,
    profileData: ProfileReducer.reducer,
    profilePage: ProfilePageReducer.reducer,
    imageBrochure: ImageBrochureReducer.reducer,
    sideNavigation: SideNavigationReducer.reducer,
    snackbar: SnackbarReducer.reducer,
    subgroupsData: SubGroupReducer.reducer,
    position: PositionReducer.reducer,
    profileEditorPage: ProfileEditorPageReducer.reducer,
    channelData: ChannelReducer.reducer,
    permission: PermissionReducer.reducer,
    secondaryViewPage: SecondaryViewReducer.reducer,
    search: SearchReducer.reducer,
    directMessage: DirectMessageReducer.reducer,
    notification: NotificationReducer.reducer,
    blockitModal: BlockitModalReducer.reducer,
    pageLoadingIndicator: PageLoadingIndicatorReducer.reducer,
    draftState: DraftReducer.reducer,
    searchPageData: SearchPageReducer.reducer,
    moimLeavePage: MoimLeaveReducer.reducer,
    meeting: MeetingReducer.reducer,
    postTemplate: PostTemplateReducer.reducer,
    commerce: CommerceReducer.reducer,
    commercePage: CommercePageReducer.reducer,
    campaignData: CampaignDataReducer.reducer,
    campaignPage: CampaignPageReducer.reducer,
    plugins: PluginReducer.reducer,
    contentsGroup: ContentsGroupReducer.reducer,
    promote: PromoteReducer.reducer,
    thread: ThreadReducer.reducers,
    nft: NftReducer.reducer,
    childMoimGroup: ChildMoimGroupReducer.reducer,
    dquest: DQuestReducer.reducer,
    communityCoin: CommunityCoinReducer.reducer,
    cryptobadge: CryptobadgeReducer.reducer,
    userPageData: UserReducer.reducer,
    componentLayout: ComponentLayoutReducer.reducer,

    // dialog
    profileDialog: ProfileDialogReducer.reducer,
    joinGroupDialog: JoinGroupDialogReducer.reducer,
    positionFormDialog: PositionFormDialogReducer.reducer,
    newDirectMessageDialog: NewDirectMessageDialogPageReducer.reducer,
    positionApplyDialog: PositionApplyDialogReducer.reducer,
    SNSShareDialog: SNSShareDialogReducer.reducer,
    channelNotificationSettingDialog:
      ChannelNotificationSettingDialogReducer.reducer,
    createMeetingDialog: CreateMeetingDialogReducer.reducer,
    myReferralPerformanceDialog: MyReferralPerformanceDialogReducer.reducer,
    myReferralDialog: MyReferralDialogReducer.reducer,
    globalReportDialog: GlobalReportDialogReducer.reducer,
    popupBanner: PopupBannerReducer.reducer,
    dquestCompleteDialog: DQuestCompleteDialogReducer.reducer,
    purchasePreparationDialog: PurchasePreparationDialogReducer.reducer,
    globalAlertDialog: GlobalAlertDialogReducer.reducer,
    globalUserStatusFeedbackDialog:
      GlobalUserStatusFeedbackDialogReducer.reducer,
    globalDuplicatePostDialog: GlobalDuplicatePostDialogReducer.reducer,
    globalMovePostDialog: GlobalMovePostDialogReducer.reducer,
  });
}
