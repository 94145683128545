import * as React from "react";

import HorizontalChannelList from "common/components/horizontalChannelList";
import { Container } from "./styled";

import { arrayEqual, useStoreState } from "app/store";

import { channelWithCategorySelector } from "app/selectors/channel";
import { TopNavigationContext } from "../../../../context";

function MenuElement({
  blockProperties,
}: {
  blockProperties?: Partial<Moim.Layout.Navigation.MenuElementType>;
}) {
  const { onlyMoreMenu } = React.useContext(TopNavigationContext);
  const channelList = useStoreState(
    storeState => channelWithCategorySelector(storeState).data,
    arrayEqual,
  );

  return (
    <Container element={blockProperties}>
      <HorizontalChannelList
        channelList={channelList}
        maxChannelVisibleFixedCount={onlyMoreMenu ? 0 : undefined}
        blockProperties={blockProperties}
      />
    </Container>
  );
}

export default React.memo(MenuElement);
