import React from "react";

import { TagSet, TagSetWrapper } from "./styled";

export const TagGroupList: React.FC<{
  tagGroups: Moim.TagSet.ITagGroup[];
  selectedTags: Record<Moim.Id, string[]>;
  activeTagGroupId: string | undefined;
  onClickTagGroup(
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    tagGroup: Moim.TagSet.ITagGroup,
  ): void;
}> = ({ tagGroups, activeTagGroupId, selectedTags, onClickTagGroup }) => {

  return (
    <>
      <TagSetWrapper>
        {tagGroups.map(tagGroup => {
          const key = tagGroup.id;
          const labelText = tagGroup.set;
          return (
            <div>
              <TagSet
                id={key}
                isActivated={activeTagGroupId === tagGroup.id}
                isSelected={Boolean(selectedTags[key]?.length)}
                onClick={e => {
                  onClickTagGroup(e, tagGroup);
                }}
              >
                <span>
                  {labelText}
                  {selectedTags[key]?.length
                    ? `(${selectedTags[key].length})`
                    : ""}
                </span>
              </TagSet>
            </div>
          );
        })}
      </TagSetWrapper>
    </>
  );
};

export const TagList: React.FC<{
  tagGroup: Moim.TagSet.ITagGroup;
  selectedTags?: string[];

  onChange(id: string, selectedTags: string[]): void;
}> = ({ tagGroup, selectedTags = [], onChange }) => {
  return (
    <TagSetWrapper>
      {tagGroup.items.map(tag => {
        const key = tag.id;
        const labelText = tag.value;
        const selected = selectedTags?.includes(key);
        return (
          <div>
            <TagSet
              id={key}
              isSelected={selectedTags?.includes(key)}
              onClick={() => {
                if (selected) {
                  onChange(
                    tagGroup.id,
                    selectedTags.filter(selectedTag => selectedTag !== tag.id),
                  );
                } else {
                  onChange(tagGroup.id, [...selectedTags, tag.id]);
                }
              }}
            >
              <span>{labelText}</span>
            </TagSet>
          </div>
        );
      })}
    </TagSetWrapper>
  );
};
