import { ItemWrapper, Left, Right, Wrapper } from "./styled";
import { VoteStatus } from "app/enums";
import { LargeBookmark } from "common/components/engage/bookmark";
import { LargeComment } from "common/components/engage/comment";
import { LargeLike } from "common/components/engage/like";
import { LargeShare } from "common/components/engage/share";
import { useThreadItemContext } from "common/components/thread/commonThreadItem/context";
import * as React from "react";

function Engagement({
  reactionHandler,
  // onDownCountClick,
  // onUpCountClick,
  onCommentClick,
  onShareClick,
  onBookmarkClick,
}: {
  reactionHandler?: (type: Moim.Enums.VoteStatus) => void;
  onDownCountClick?: React.MouseEventHandler;
  onUpCountClick?: React.MouseEventHandler;
  onCommentClick?: React.MouseEventHandler;
  onShareClick?: React.MouseEventHandler;
  onBookmarkClick?: React.MouseEventHandler;
}) {
  const {
    threadId,
    upVoteCount,
    // downVoteCount,
    commentCount,
    voteStatus,
    // voteType,
    isBookmarked,
    textAlign,
    showReaction,
    showCommentCount,
  } = useThreadItemContext((state) => ({
    threadId: state.item.id,
    upVoteCount: state.item.up_vote_score,
    downVoteCount: state.item.down_vote_score,
    commentCount: state.item.replies_count,
    voteStatus: state.item.vote?.type,
    voteType: state.config.reactionType,
    isBookmarked: state.item.is_bookmarked,
    textAlign: state.config.textAlignment,
    showReaction: state.config.showReaction,
    showCommentCount: state.config.showCommentCount,
  }));

  const handleClick = React.useCallback(
    (callback?: React.MouseEventHandler) => (event: React.MouseEvent) => {
      event.stopPropagation();
      if (callback) {
        callback(event);
      }
    },
    [],
  );

  const handleReactionClick = React.useCallback(
    (newType: Moim.Enums.VoteStatus) => {
      reactionHandler?.(voteStatus !== newType ? newType : VoteStatus.NONE);
    },
    [voteStatus, reactionHandler],
  );

  if (!showReaction && !showCommentCount && !onShareClick && !onBookmarkClick) {
    return null;
  }
  return (
    <Wrapper textAlign={textAlign}>
      {(showReaction || showCommentCount) && (
        <Left>
          {showReaction && (
            <>
              <ItemWrapper
                onClick={handleClick(() =>
                  handleReactionClick(VoteStatus.POSITIVE),
                )}
              >
                <LargeLike
                  threadId={threadId}
                  liked={voteStatus === VoteStatus.POSITIVE}
                  likeCount={upVoteCount}
                  handleLike={reactionHandler}
                  showLabel={!Boolean(upVoteCount)}
                  disableOpenVotedUserList
                />
              </ItemWrapper>
            </>
          )}

          <ItemWrapper onClick={handleClick(onCommentClick)}>
            <LargeComment
              count={commentCount}
              showCommentCount={showCommentCount}
              showLabel={!Boolean(commentCount)}
            />
          </ItemWrapper>
        </Left>
      )}

      <Right>
        <ItemWrapper onClick={handleClick(onShareClick)}>
          <LargeShare />
        </ItemWrapper>

        <ItemWrapper onClick={handleClick(onBookmarkClick)}>
          <LargeBookmark isBookmarked={isBookmarked} />
        </ItemWrapper>
      </Right>
    </Wrapper>
  );
}

export default React.memo(Engagement);
