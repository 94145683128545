import React from "react";
import styled from "styled-components";
import { LinearProgress } from "@material-ui/core";
import { px2rem } from "common/helpers/rem";
import { rgba } from "polished";
import { StepContext } from "common/components/dialogs/dialogStepController";

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: ${px2rem(2)};

  .lp-root {
    background-color: ${props => rgba(props.theme.colorV2.accent, 0.26)};
  }

  .lp-barPrimary {
    background-color: ${props => props.theme.colorV2.accent};
  }
`;

export const JoinGroupDialogProgressBar = () => {
  const { currentStep, steps } = React.useContext(StepContext);
  const hasMainStep = steps.find(step => step.key === "main");
  const denominator = hasMainStep ? steps.length - 1 : steps.length;
  const numerator = hasMainStep ? currentStep - 1 : currentStep;

  if (steps[currentStep]?.key === "main" || denominator <= 1) {
    return null;
  }

  return (
    <Wrapper>
      <LinearProgress
        variant="determinate"
        classes={{ root: "lp-root", barColorPrimary: "lp-barPrimary" }}
        value={Math.round((numerator / denominator) * 100)}
      />
    </Wrapper>
  );
};
