/* eslint-disable no-empty */
import { ThunkPromiseResult } from "app/store";
import ActionLogAPI from "common/api/activityLog";

export function activityLogAction(
  ...params: Parameters<typeof ActionLogAPI.prototype.action>
): ThunkPromiseResult {
  return async (dispatch, getState, { apiSelector }) => {
    try {
      await apiSelector(getState(), dispatch).activityLog.action(...params);
    } catch {}
  };
}
