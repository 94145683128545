import { ThunkResult } from "app/store";
import { batchThreadData } from "common/helpers/batchService";
import { AddEntities } from "./entity";

export function batchThreads(ids: Moim.Id[]): ThunkResult {
  return dispatch => {
    batchThreadData(ids).then(entities => {
      dispatch(AddEntities(entities));
    });
  };
}
