import * as React from "react";
import { useActions, useStoreState } from "app/store";
import useIsMobile from "common/hooks/useIsMobile";
import { ActionCreators as CommerceActionCreators } from "app/actions/commerce";

import AppBar from "common/components/appBar";
import ResponsiveDialog, {
  Dialog,
} from "common/components/dialogs/responsiveDialog";
import TrackingDialogComponent from "./trackingDialogComponent";
import CustomAppBarModalLayout from "common/components/modalLayout/customAppbar";
import { CloseButton } from "../../components/addressManage/components/createDialog/styled";

const DeliveryTrackingDialog = () => {
  const { deliveryTrackingDialog, deliveryInfo } = useStoreState(state => ({
    deliveryTrackingDialog: state.commercePage.deliveryTrackingDialog,
    deliveryInfo: state.commercePage.deliveryTrackingDialog?.deliveryInfo,
  }));
  const { closeDeliveryTrackingDialog } = useActions({
    closeDeliveryTrackingDialog:
      CommerceActionCreators.closeDeliveryTrackingDialog,
  });

  const { trackingNumber, deliveryCompanyName } = deliveryInfo;

  const isMobile = useIsMobile();

  return (
    <>
      <ResponsiveDialog
        open={deliveryTrackingDialog?.open}
        dialogBase={Dialog}
        onCloseRequest={closeDeliveryTrackingDialog}
        maxHeight={450}
      >
        {isMobile ? (
          <TrackingDialogComponent
            onClose={closeDeliveryTrackingDialog}
            trackingNumber={deliveryInfo?.trackingNumber}
            deliveryCompanyName={deliveryInfo?.deliveryCompanyName}
          />
        ) : (
          <CustomAppBarModalLayout
            appBar={
              <AppBar
                rightButton={
                  <CloseButton onClick={closeDeliveryTrackingDialog} />
                }
              />
            }
            hasAppBarBorder={false}
          >
            <TrackingDialogComponent
              onClose={closeDeliveryTrackingDialog}
              trackingNumber={trackingNumber}
              deliveryCompanyName={deliveryCompanyName}
            />
          </CustomAppBarModalLayout>
        )}
      </ResponsiveDialog>
    </>
  );
};

export default DeliveryTrackingDialog;
