import useHubSeller from "common/hooks/commerce/useHubSeller";

export default function useVisibleMyCart() {
  const hubSeller = useHubSeller();

  const visibleMyCart = hubSeller?.config.cartEnabled;

  return visibleMyCart;
}

export function useVisibleGuestCart() {
  const hubSeller = useHubSeller();

  const visibleMyCart =
    hubSeller?.config.cartEnabled && hubSeller?.config.guestCartEnabled;

  return visibleMyCart;
}
