import React from "react";

import BaseTemplate from "app/hub/createGroup/components/step/template";

import { useIntlShort } from "common/hooks/useIntlShort";
import { useActions } from "app/store";
import { updateMyProfile } from "app/actions/me";
import { Contents, Wrapper } from "../styled";
import { ReasonBoxInput } from "common/components/reasonInput";
import * as qs from "query-string";
import { Spacer } from "common/components/designSystem/spacer";

export const ReferralCode: React.FC<{
  group?: Moim.Group.IGroup | null;
  buttonText?: string;
  skipButtonText?: string;
  onNext(): void;
  onSkip?(): void;
}> = ({ group, buttonText, skipButtonText, onNext, onSkip }) => {
  const intl = useIntlShort();
  const queryParams = qs.parse(location.search);
  const isOptional = skipButtonText !== undefined;

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [referralCodeError, setReferralCodeError] = React.useState<string>();
  const [referralCode, setReferralCode] = React.useState<string>(
    typeof queryParams.inviterId === "string" ? queryParams.inviterId : "",
  );

  const { dispatchUpdateMyProfile } = useActions({
    dispatchUpdateMyProfile: updateMyProfile,
  });

  const handleClickDoneButton = React.useCallback(async () => {
    try {
      if (!group?.id) {
        return;
      }
      const updateParams = {
        referral:
          referralCode.length > 0
            ? {
                code: referralCode,
              }
            : undefined,
        signUpInfo: {
          finishedStep: "referralCode" as const,
        },
      };

      setIsLoading(true);
      const { success, error } = await dispatchUpdateMyProfile(
        updateParams,
        undefined,
        group.id,
      );

      if (success) {
        onNext();
      } else {
        setReferralCodeError(error?.message);
        return { error };
      }
    } finally {
      setIsLoading(false);
    }
  }, [group, dispatchUpdateMyProfile, referralCode, onNext]);

  const handleChange = React.useCallback(value => {
    setReferralCode(value);
    setReferralCodeError(undefined);
  }, []);

  return (
    <Wrapper>
      <Contents>
        <BaseTemplate
          stepData={{
            title: intl("custom_system_wording_sign_up_invite_code_page_title"),
            subTitle: intl(
              "custom_system_wording_sign_up_invite_code_page_description",
            ),
            buttonText:
              isOptional && referralCode.length === 0
                ? intl("button_sign_up_with_no_code")
                : buttonText,
          }}
          onClick={handleClickDoneButton}
          onClickSkipButton={onSkip}
          waitingButton={isLoading}
          disabledButton={!isOptional && referralCode.length === 0}
        >
          <ReasonBoxInput
            size="Large"
            reasonType={referralCodeError ? "error" : "normal"}
            reasonMessage={referralCodeError}
            status={referralCodeError ? "Error" : "Focused"}
            value={referralCode}
            onChange={handleChange}
          />
          <Spacer value={70} />
        </BaseTemplate>
      </Contents>
    </Wrapper>
  );
};
