import styled, { css } from "styled-components";
import { px2rem } from "common/helpers/rem";
import { B3Regular } from "common/components/designSystem/typos";

export const Text = styled(B3Regular)`
  color: ${props => props.theme.colorV2.colorSet.grey800};
`;

export const Wrapper = styled.div<{ color?: string }>`
  width: 100%;
  height: ${px2rem(28)};
  padding: ${px2rem(4)} 0;
  display: flex;
  align-items: center;
  column-gap: ${px2rem(4)};

  ${props =>
    props.color
      ? css`
          ${Text} {
            color: ${props.theme.getColorByAlias(props.color)};
          }
        `
      : undefined}
`;
