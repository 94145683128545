import AlertDialog from "../alertDialog";
import axios from "axios";
import useOpenState from "common/hooks/useOpenState";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";

const MOIM_FILE_DOMAINS = ["files.moim.co", "files.vingle.network"];

function isOwnResource(urlString: string) {
  try {
    const url = new URL(urlString);

    if (MOIM_FILE_DOMAINS.includes(url.hostname)) {
      return true;
    }

    if (
      url.hostname === location.hostname &&
      url.pathname.startsWith("/files")
    ) {
      return true;
    }

    return false;
  } catch {
    return false;
  }
}

const HiddenDownload = styled.a`
  display: none;
`;

interface IProps
  extends React.DetailedHTMLProps<
    React.LinkHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  > {
  resourceUrl: string;
  fileName: string;
  disable?: boolean;
  isPrivate?: boolean;
}

const DownloadButton: React.FC<IProps> = ({
  resourceUrl,
  fileName,
  disable = false,
  isPrivate = false,
  onClick,
  children,
  ...rest
}) => {
  const intl = useIntl();
  const refDownload = React.useRef<HTMLAnchorElement>(null);
  const [objectUrl, setObjectUrl] = React.useState("");
  const {
    isOpen: openStatusOfNotOwnResourceAlert,
    open: openNotOwnResourceAlert,
    close: closeNotOwnResourceAlert,
  } = useOpenState();

  const handleClick: React.MouseEventHandler<HTMLAnchorElement> =
    React.useCallback(
      async (e) => {
        onClick?.(e);
        if (resourceUrl) {
          if (!isOwnResource(resourceUrl)) {
            openNotOwnResourceAlert();
            return;
          }
          try {
            const newUrl = new URL(resourceUrl);

            if (isPrivate) {
              const link = document.createElement("a");
              link.href = resourceUrl;
              link.download = fileName;
              link.click();
              link.remove();
              return;
            }
            newUrl.searchParams.append("t", "download");
            const result = await axios({
              url: newUrl.toString(),
              method: "GET",
              responseType: "blob",
            });
            const objUrl = window.URL.createObjectURL(new Blob([result.data]));
            setObjectUrl(objUrl);
            e.preventDefault();

            if (refDownload.current) {
              refDownload.current.href = objUrl;
              refDownload.current.click();
            }
          } catch {
            alert(intl.formatMessage({ id: "file_download_failure_message" }));
          }
        }
      },
      [onClick, resourceUrl, isPrivate, fileName, intl],
    );

  const handleDownloadClick: React.MouseEventHandler<HTMLAnchorElement> =
    React.useCallback((e) => {
      e.stopPropagation();
    }, []);

  React.useEffect(
    () => () => {
      window.URL.revokeObjectURL(objectUrl);
      closeNotOwnResourceAlert();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <>
      {React.createElement(
        "button",
        {
          ...rest,
          target: "_blank",
          disabled: disable,
          "aria-label": `${fileName}'s download button`,
          onClick: handleClick,
        },
        [
          children,
          <HiddenDownload
            key="hidden-download"
            ref={refDownload}
            href={objectUrl}
            download={fileName}
            onClick={handleDownloadClick}
          />,
        ],
      )}
      <AlertDialog
        open={openStatusOfNotOwnResourceAlert}
        title={
          <FormattedMessage id="dialog_external_image_download_unavailable_title" />
        }
        content={
          <FormattedMessage id="dialog_external_image_download_unavailable_body" />
        }
        rightButtons={[
          {
            textColor: "PRIMARY_MAIN",
            text: <FormattedMessage id="button_ok" />,
            onClick: closeNotOwnResourceAlert,
          },
        ]}
        onClose={closeNotOwnResourceAlert}
      />
    </>
  );
};

export default DownloadButton;
