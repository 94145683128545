import React from "react";
import styled from "styled-components";

import { px2rem } from "common/helpers/rem";
import { H8BoldStyle } from "common/components/designSystem/typos";

function number2String(n: number) {
  return String("0" + n).slice(-2);
}
function getLastDay(year: string | undefined, month: string | undefined) {
  const date =
    year && month
      ? new Date(parseInt(year, 10), parseInt(month, 10))
      : new Date();
  const currentYear = date.getFullYear();
  const currentMonth = date.getMonth();

  return new Date(currentYear, currentMonth, 0).getDate();
}
const InputWrapper = styled.div`
  width: 100%;
  padding: ${px2rem(8)} ${px2rem(16)};
`;

const Input = styled.div<{
  year: string | undefined;
  month: string | undefined;
  day: string | undefined;
}>`
  width: 100%;
  display: flex;
  align-items: center;
  gap: ${px2rem(8)};
  padding: ${px2rem(8)} ${px2rem(16)};
  border-radius: ${px2rem(4)};
  border: ${px2rem(1)} solid ${props => props.theme.colorV2.colorSet.grey50};

  select {
    flex: 1;
    min-width: 0;
    color: ${props => props.theme.colorV2.colorSet.grey300};
    ${H8BoldStyle}
    appearance: none;
    text-align: center;
    border: none;

    &[name="year"] {
      color: ${props =>
        props.year
          ? props.theme.colorV2.colorSet.grey800
          : props.theme.colorV2.colorSet.grey300};
    }
    &[name="month"] {
      color: ${props =>
        props.month
          ? props.theme.colorV2.colorSet.grey800
          : props.theme.colorV2.colorSet.grey300};
    }
    &[name="day"] {
      color: ${props =>
        props.day
          ? props.theme.colorV2.colorSet.grey800
          : props.theme.colorV2.colorSet.grey300};
    }
  }

  span {
    color: ${props => props.theme.colorV2.colorSet.grey300};
    ${H8BoldStyle}
  }
`;

export const MobileDatePickerInput: React.FC<{
  value: string | undefined;
  onChange(value: string | undefined);
}> = ({ onChange, value }) => {
  const [year, month, day] = React.useMemo(() => value?.split("-") ?? [], [
    value,
  ]);

  const yearsOptions = React.useMemo(
    () =>
      Array.from({ length: new Date().getFullYear() - 1899 }, (_, index) => {
        const yearValue = new Date().getFullYear() - index;
        return (
          <option key={yearValue} value={String(yearValue)}>
            {yearValue}
          </option>
        );
      }),
    [],
  );

  const monthsOptions = React.useMemo(
    () =>
      Array.from({ length: 12 }, (_, index) => {
        const monthValue = number2String(index + 1);
        return (
          <option key={monthValue} value={monthValue}>
            {monthValue}
          </option>
        );
      }),
    [],
  );

  const daysOptions = React.useMemo(() => {
    const lastDayOfMonth = getLastDay(year, month);
    return Array.from({ length: lastDayOfMonth }, (_, index) => {
      const dayValue = number2String(index + 1);
      return (
        <option key={dayValue} value={dayValue}>
          {dayValue}
        </option>
      );
    });
  }, [year, month]);

  const handleChange = React.useCallback(
    (
      year: string | undefined = "",
      month: string | undefined = "",
      day: string | undefined = "",
    ) => {
      if (!year && !month && !day) {
        onChange(undefined);
        return;
      }

      if (day && getLastDay(year, month) < parseInt(day, 10)) {
        onChange(`${year}-${month}-${getLastDay(year, month)}`);
        return;
      }

      onChange(`${year}-${month}-${day}`);
    },
    [onChange],
  );

  return (
    <InputWrapper>
      <Input year={year} month={month} day={day}>
        <select
          name="year"
          value={year}
          onChange={e => {
            handleChange(e.target.value || undefined, month, day);
          }}
        >
          <option value="">YYYY</option>
          {yearsOptions}
        </select>
        <span>/</span>
        <select
          name="month"
          value={month}
          onChange={e => {
            const value = e.target.value;

            handleChange(year, value || undefined, day);
          }}
        >
          <option value="">MM</option>
          {monthsOptions}
        </select>
        <span>/</span>
        <select
          name="day"
          value={day}
          onChange={e => {
            const value = e.target.value;

            handleChange(year, month, value || undefined);
          }}
        >
          <option value="">DD</option>
          {daysOptions}
        </select>
      </Input>
    </InputWrapper>
  );
};
