/* eslint-disable no-underscore-dangle */
import { getCertificate_node_Certificate } from "@vingle/cryptobadge-sdk";
import { ISSRLoadDataPayload } from "app/typings/ssr";
import CryptoBadgeAPI from "common/api/cryptobadge/cryptobadgeClient";
import { setInitialData } from "common/helpers/initialData";
import { first } from "lodash";

export async function CryptoBadgeShowLoadData({
  match,
  api,
  requestId,
}: ISSRLoadDataPayload) {
  const badgeId = match.params.badgeId;

  if (badgeId) {
    const badge = first(await api.cryptobadge.getCryptobadges([badgeId]));

    if (badge) {
      setInitialData("__entities.cryptobadge.badge", requestId, badge);
    }
  }
}

export async function CryptoBadgeCertificateShowLoadData({
  match,
  requestId,
}: ISSRLoadDataPayload) {
  const certificateId = match.params.certificateId;

  if (certificateId) {
    const result = (
      await CryptoBadgeAPI.getCertificateItem({
        id: certificateId,
      })
    )?.node as getCertificate_node_Certificate | null;

    if (result) {
      setInitialData("__entities.cryptobadge.certificate", requestId, result);
    }
  }
}

export async function CryptoBadgeGroupShowLoadData({
  match,
  api,
  requestId,
}: ISSRLoadDataPayload) {
  const groupId = match.params.groupId;

  if (groupId) {
    const badgeGroupData = await api.cryptobadge.getCryptobadgeGroup(groupId);

    if (badgeGroupData) {
      setInitialData(
        "__entities.cryptobadge.badgeGroup",
        requestId,
        badgeGroupData,
      );
    }
  }
}
