import * as React from "react";
import { ThemeContext } from "styled-components";

import useIsMobile from "common/hooks/useIsMobile";
import { useActions, useStoreState } from "app/store";

import MoimAPI from "common/api";
import { getRight } from "app/actions/community";

import BlockitEditor from "common/components/blockitEditorBase";
import { Spacer } from "common/components/designSystem/spacer";
import DQuestMissionary from "common/components/dquestMissionary";
import { DefaultLoader } from "common/components/loading";
import {
  BannerImage,
  Title,
  Description,
  Divider,
  StatusAndPeriod,
  Outcomes,
} from "./components";
import AvailablePositions from "./components/availablePositions";
import {
  Wrapper,
  PaddedWrapper,
  ContentWrapper,
  LoadingWrapper,
} from "./styled";
import { getPositionsBatch } from "app/actions/position";

const EMPTY_ARRAY: any[] = [];

interface IProps {
  isLoading: boolean;
  quest?: Moim.DQuest.INormalizedQuest;
  content?: Moim.Blockit.Blocks[];
  history?: Moim.DQuest.IHistory;
}

const DQuestShowComponent: React.FC<IProps> = ({
  isLoading,
  quest,
  content = EMPTY_ARRAY,
  history,
}) => {
  const isMobile = useIsMobile();
  const theme = React.useContext(ThemeContext);

  const isAccomplished = React.useMemo(() => {
    if (!history) {
      return false;
    }
    if (history.reJoinable) {
      return false;
    }

    return (
      (history.status === "ACHIEVED_NOT_REWARDED" ||
        history.status === "ACHIEVED") &&
      history.progress === history.progressTotal
    );
  }, [history]);

  const bannerImages = React.useMemo(
    () =>
      (isMobile || !quest?.show.imageUrls_web?.length
        ? quest?.show.imageUrls
        : quest?.show.imageUrls_web) ?? EMPTY_ARRAY,
    [isMobile, quest?.show.imageUrls, quest?.show.imageUrls_web],
  );

  const [rights, setRights] = React.useState<Moim.Community.IRight>();
  const isMembersOnlyQuest =
    rights?.rules[0].holder.type === "MEMBER" || !Boolean(rights);

  const extractAllowedPositionIds = React.useCallback(
    (_rights: Moim.Community.IRight) => {
      if (!_rights || !_rights.rules.length) {
        return [];
      }

      return _rights.rules.reduce((result: string[], rule) => {
        const { holder } = rule;
        if (holder?.type === "POSITION" && holder.filter?.value) {
          const values = Array.isArray(holder.filter.value)
            ? holder.filter.value
            : [holder.filter.value];
          return result.concat(
            values.filter((value: unknown) => typeof value === "string"),
          );
        }
        return result;
      }, []);
    },
    [],
  );

  const allowedPositions = React.useMemo(() => {
    if (!rights || !rights.rules.length) {
      return [];
    }

    return extractAllowedPositionIds(rights);
  }, [rights]);

  const hasPositionChip = useStoreState(state =>
    allowedPositions?.some(position =>
      Boolean(state.entities.positions[position]?.name),
    ),
  );

  const [hasJoinablePermission, setHasJoinablePermission] = React.useState(
    false,
  );

  React.useEffect(() => {
    if (!quest || hasJoinablePermission) return;
    try {
      MoimAPI.community
        .validateRight(quest.id, "JOIN_QUEST", {
          validateHolderOnly: true,
        })
        .then(() => {
          setHasJoinablePermission(true);
        });
    } catch (e) {
      setHasJoinablePermission(false);
    }
  }, [hasJoinablePermission, quest]);

  const { dispatchGetRight, dispatchGetPositions } = useActions({
    dispatchGetRight: getRight,
    dispatchGetPositions: getPositionsBatch,
  });

  React.useEffect(() => {
    if (!quest?.id) return;
    dispatchGetRight({
      resourceId: quest.id,
      action: "JOIN_QUEST",
    }).then(result => {
      const batchingPositions = result ? extractAllowedPositionIds(result) : [];
      if (batchingPositions.length) {
        dispatchGetPositions({
          positions: batchingPositions,
        });
      }
      setRights(result);
    });
  }, [dispatchGetRight, quest?.id]);

  return (
    <Wrapper>
      {isLoading ? (
        <LoadingWrapper>
          <DefaultLoader />
        </LoadingWrapper>
      ) : (
        <>
          <StatusAndPeriod
            status={quest?.displayStatus}
            startAt={quest?.startAt}
            endAt={quest?.endAt}
            viewerCount={quest?.viewerCount}
            isAccomplished={isAccomplished}
          />
          <PaddedWrapper>
            <Title text={quest?.title ?? ""} />
          </PaddedWrapper>
          <Spacer value={8} />
          <Outcomes outcomes={quest?.outcomes ?? EMPTY_ARRAY} />
          {!isMembersOnlyQuest && (
            <>
              <AvailablePositions
                positions={allowedPositions}
                hasPositionChip={hasPositionChip}
              />
              <Spacer value={16} />
            </>
          )}

          {Boolean(bannerImages.length) && (
            <>
              <BannerImage imageSrc={bannerImages[0]} />
              <Spacer value={12} />
            </>
          )}
          {quest?.description ? (
            <>
              <PaddedWrapper>
                <Description text={quest?.description ?? ""} />
              </PaddedWrapper>
              <Spacer value={24} />
              <Divider />
            </>
          ) : null}

          {Boolean(content?.length) && (
            <>
              <PaddedWrapper>
                <ContentWrapper>
                  <BlockitEditor
                    id={`quest_content_${quest?.id ?? "empty"}`}
                    readonly={true}
                    contents={content}
                  />
                </ContentWrapper>
              </PaddedWrapper>
              <Divider />
            </>
          )}
          <Spacer value={16} />
          {quest ? (
            <PaddedWrapper>
              <DQuestMissionary
                hasJoinablePermission={hasJoinablePermission}
                allowedPositions={allowedPositions}
                questId={quest.id}
                mainColor={theme.themeMode.mode === "DARK" ? "white" : "black"}
                bottomStickyBGColor={
                  theme.themeMode.mode === "DARK"
                    ? theme.colorV2.colorSet.white1000
                    : theme.colorV2.colorSet.grey1000
                }
              />
            </PaddedWrapper>
          ) : null}
          <Spacer value={isMobile ? 102 : 68} />
        </>
      )}
    </Wrapper>
  );
};

export default React.memo(DQuestShowComponent);
