import * as React from "react";

import { SectionTitle } from "../profileComponent/styledComponent";
import { H8Bold } from "common/components/designSystem/typos";
import { Wrapper } from "./styled";
import { SkeletonBox } from "common/components/skeleton";
import CoinItemList from "./components/list";

import { useActions } from "app/store";
import useCancelToken from "common/hooks/useCancelToken";
import {
  getCoinGroup,
  getCoinGroupBalance,
  getCoinGroupCoinList,
} from "app/actions/community/coin";
import { px2rem } from "common/helpers/rem";

const SESSION_KEY_CG = "profile-coin-group-coin-group";
const SESSION_KEY_CB = "profile-coin-group-coin-balance";
const SESSION_KEY_COINS = "profile-coin-group-coins";

interface IProps {
  id: string;
}
const CoinGroup: React.FC<IProps> = ({ id }) => {
  const cancelToken = useCancelToken();
  const sessionCG = sessionStorage.getItem(SESSION_KEY_CG);
  const sessionCB = sessionStorage.getItem(SESSION_KEY_CB);
  const sessionCoins = sessionStorage.getItem(SESSION_KEY_COINS);

  const [coinGroup, setCoinGroup] = React.useState<
    Moim.Community.Coin.ICoinGroup | undefined
  >(sessionCG ? JSON.parse(sessionCG) : undefined);
  const [coinGroupBalance, setCoinGroupBalance] = React.useState<
    Moim.Community.Coin.ICoinGroupBalance | undefined
  >(sessionCB ? JSON.parse(sessionCB) : undefined);
  const [coins, setCoins] = React.useState<
    Moim.Community.Coin.ICoin[] | undefined | null
  >(sessionCoins ? JSON.parse(sessionCoins) : undefined);

  const {
    dispatchGetCoinGroup,
    dispatchGetCoinGroupCoinList,
    dispatchGetCoinGroupBalance,
  } = useActions({
    dispatchGetCoinGroup: getCoinGroup,
    dispatchGetCoinGroupCoinList: getCoinGroupCoinList,
    dispatchGetCoinGroupBalance: getCoinGroupBalance,
  });

  React.useEffect(() => {
    if (!id) return;

    dispatchGetCoinGroup(id, cancelToken.current.token).then(result => {
      if (result) {
        sessionStorage.setItem(SESSION_KEY_CG, JSON.stringify(result));
      }
      setCoinGroup(result);
    });
    dispatchGetCoinGroupCoinList(id, cancelToken.current.token).then(result => {
      if (result?.data) {
        sessionStorage.setItem(SESSION_KEY_COINS, JSON.stringify(result.data));
        setCoins(result.data);
      } else {
        setCoins(null);
      }
    });
    dispatchGetCoinGroupBalance(id, cancelToken.current.token).then(result => {
      if (result) {
        sessionStorage.setItem(SESSION_KEY_CB, JSON.stringify(result));
      }
      setCoinGroupBalance(result);
    });
  }, [id]);

  return (
    <>
      <SectionTitle>
        {coinGroup?.name ? (
          <H8Bold>{coinGroup?.name}</H8Bold>
        ) : (
          <SkeletonBox width="30%" height={px2rem(21)} />
        )}
      </SectionTitle>
      <Wrapper>
        <CoinItemList coins={coins} coinGroupBalance={coinGroupBalance} />
      </Wrapper>
    </>
  );
};

export default CoinGroup;
