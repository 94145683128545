import styled from "styled-components";
import { px2rem } from "common/helpers/rem";

export const LoadingWrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: ${px2rem(220)};
  display: flex;
  align-items: center;
  justify-content: center;
`;
