import { AppDispatch } from "../store";

import { updateChannelStat } from "app/actions/channel";
import { getIsCurrentChannel } from "./utils/getIsCurrentChannel";
import { IAppState } from "app/rootReducer";
import {
  batchDirectMessagesHandler,
  batchUserData,
} from "common/helpers/batchService";
import { AddEntities } from "app/actions/entity";
import { ActionCreators } from "app/actions/directMessage";

export async function dataHandler(
  dispatch: AppDispatch,
  getState: () => IAppState,
  response: Moim.WebsocketEvent.Stat,
) {
  dispatch(
    updateChannelStat(
      response.payload.map(data => {
        const isCurrentChannelStat = getIsCurrentChannel(data.id);

        return {
          id: data.id,
          count: isCurrentChannelStat ? 0 : data.count,
          has_new: isCurrentChannelStat ? false : data.hasNew,
          last_read: data.lastRead,
          updated_at: data.updatedAt,
          has_new_notification: data.hasNewNotification,
          list_count: data.listCount,
          root_list_count: data.rootListCount,
        };
      }),
    ),
  );

  const dmStats = response.payload.filter(p =>
    Boolean(p.resourceId.split("|")[1]?.startsWith("D")),
  );

  for (const dmStat of dmStats) {
    const dmId = dmStat.resourceId.split("|")[1];
    const isCurrentChannel = getIsCurrentChannel(dmId);

    if (!isCurrentChannel) {
      const state = getState();

      if (!getState().entities.directMessages[dmId]) {
        const currentUserId = state.app.currentUserId;
        const directMessage = (await batchDirectMessagesHandler([dmId]))?.find(
          data => data.id === dmId,
        );
        const targetUserIds = directMessage?.members.filter(
          member => member !== currentUserId || !state.entities.users[member],
        );
        const targetUserEntities = targetUserIds
          ? await batchUserData(targetUserIds)
          : undefined;

        if (directMessage) {
          dispatch(
            AddEntities({
              directMessages: { [directMessage.id]: directMessage },
              ...targetUserEntities,
            }),
          );

          dispatch(
            ActionCreators.succeededGetDirectMessage({ data: dmId }, "before"),
          );
        }
      }
    }

    /**
     * ToDo: buffering? throttling?
     */
  }
}
