import * as React from "react";
import { FormattedMessage } from "react-intl";
import CurrencyFormatter from "common/components/currencyFormatter";
import { Title, Wrapper, Section } from "../common";

interface IProps {
  previousBalance: number;
  rechargeAmount: number;
  coinSymbol: string;
  coinName: string;
}

export default function CoinRechargeInfo({
  previousBalance,
  rechargeAmount,
  coinSymbol,
  coinName,
}: IProps) {
  return (
    <Wrapper>
      <Title>
        <FormattedMessage id="my_shopping_purchase_details_reload_candy_info_title" />
      </Title>
      <Section
        title={
          <FormattedMessage
            id="my_shopping_purchase_details_reload_candy_current_amount"
            values={{ candy_name: coinName }}
          />
        }
        contents={
          <CurrencyFormatter currency={coinSymbol} value={previousBalance} />
        }
        titleOption={{ maxWidth: 170 }}
        contentsOption={{ textAlign: "right", isBold: false }}
      />
      <Section
        title={
          <FormattedMessage
            id="my_shopping_purchase_details_reload_candy_reload_amount"
            values={{ candy_name: coinName }}
          />
        }
        contents={
          <CurrencyFormatter currency={coinSymbol} value={rechargeAmount} />
        }
        titleOption={{ maxWidth: 170 }}
        contentsOption={{ textAlign: "right", isBold: false }}
      />
      <Section
        title={
          <FormattedMessage
            id="my_shopping_purchase_details_reload_candy_total_amount_after_reloading"
            values={{ candy_name: coinName }}
          />
        }
        contents={
          <CurrencyFormatter
            currency={coinSymbol}
            value={previousBalance + rechargeAmount}
          />
        }
        titleOption={{ maxWidth: 170 }}
        contentsOption={{ textAlign: "right", isBold: false }}
      />
    </Wrapper>
  );
}
