import { Svg24BackB } from "@moim/icons";
import styled, { css } from "styled-components";

import { px2rem } from "common/helpers/rem";
import {
  B1Regular,
  B4Bold,
  H4Bold,
} from "common/components/designSystem/typos";
import { useScrollStyle } from "common/components/designSystem/styles";
import { MEDIA_QUERY } from "common/constants/responsive";
import { UnsignedChatInputFeedback } from "common/components/feedBack/components/unsigned/input";
import { FormattedMessage } from "react-intl";
import * as React from "react";
import {
  MAX_HEIGHT,
  TOOLBAR_HEIGHT,
} from "common/components/groupInput/styledComponent";

export const StyledUnsignedChatInputFeedback = styled(
  UnsignedChatInputFeedback,
)`
  @media ${MEDIA_QUERY.ONLY_MOBILE} {
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: ${props => props.theme.zIndexes.gnbSticky + 1};
    background-color: ${props => props.theme.colorV2.colorSet.white1000};
  }
`;

export const Header = styled(H4Bold)`
  padding-top: ${px2rem(8)};
  border-bottom: ${props =>
    ` ${px2rem(1)} solid ${props.theme.colorV2.colorSet.grey50}`};
  color: ${props => props.theme.colorV2.colorSet.grey800};
`;

export const LoadMoreText = styled(B4Bold)`
  color: ${props => props.theme.colorV2.colorSet.grey300};
`;

export const LoadingWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LoadMoreWrapper = styled.div`
  width: 100%;
  min-height: ${px2rem(30)};
  display: flex;
  align-items: center;
  padding: 0 ${px2rem(16)};
`;

export const ListContainer = styled.div`
  flex: 1;
  min-height: 0;
  width: 100%;
  height: 100%;

  > div {
    padding: ${px2rem(8)} 0;

    > div:last-child {
      margin-bottom: ${px2rem(90)};
    }
  }
`;

export const ScrollSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  ${useScrollStyle}
`;

export const getStickedWrapperStyle = (adjustTop?: number) => css`
  @media ${MEDIA_QUERY.ONLY_MOBILE} {
    position: fixed !important;
    top: ${adjustTop ? px2rem(adjustTop) : 0} !important;
  }
`;

export const AppBarWrapperStyle = css`
  color: ${props => props.theme.colorV2.colorSet.grey800};
`;

export const AppBarLeftButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 ${px2rem(12)} 0 ${px2rem(13)};
`;

export const StickyBottom = styled.div`
  position: fixed;
  width: 100%;
  height: fit-content;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: ${props => props.theme.zIndexes.default};
  background-color: ${props => props.theme.colorV2.colorSet.white1000};

  ::before {
    position: absolute;
    display: block;
    content: "";
    inset: 0;
    background-color: rgba(
      255,
      255,
      255,
      0.1
    ); // NOTE: same to Mui dialog's ::before BG-color"
  }
`;

export const StickyBottomInnerContainer = styled.div`
  width: 100%;
  height: fit-content;
`;

export const BackIconButton = styled(Svg24BackB).attrs(props => ({
  size: "s",
  touch: 24,
  role: "button",
  iconColor: props.theme.colorV2.colorSet.grey800,
}))``;

const MIN_HEIGHT = MAX_HEIGHT + TOOLBAR_HEIGHT + 30;

const EmptyWrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: ${px2rem(MIN_HEIGHT)};
  padding-bottom: ${px2rem(98)};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${px2rem(80)} 0;
`;

const GuideText = styled(B1Regular)`
  text-align: center;
  white-space: pre-line;
  color: ${props => props.theme.colorV2.colorSet.grey800};
`;

export function EmptyReplies() {
  return (
    <EmptyWrapper>
      <GuideText>
        <FormattedMessage id="comment_show_reply_empty" />
      </GuideText>
    </EmptyWrapper>
  );
}
