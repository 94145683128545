import * as React from "react";
import { FlattenInterpolation } from "styled-components";
import { useResizeDetector } from "react-resize-detector";
import { IProps, useProps } from "./useHooks";
import {
  AppBarWrapper,
  CenterWrapper,
  IScrollParallaxContainer,
  LeftWrapper,
  MainTitle,
  RightWrapper,
  ScrollParallaxWrapper,
  StickyWrapper,
  stickyWrapperStyle,
  SubTitle,
  TitleContainer,
  Wrapper,
} from "./styledComponent";
import DisplayController from "../displayControllerAboutScrollDirection";

const AppBar: React.FC<IProps> = props => {
  const {
    refThis,
    refParallax,
    sideElementWidth,
    wrapperStyle,
    wrapperStickyStyle,
    wrapperStickedStyle,
    isParallaxVisible,
    alwaysShowAppBarTitle,
    titleOpacity,
    parallaxOpacity,
    titleElement,
    titleAlignmentState,
    subTitleElement,
    leftButton,
    rightButton,
    enableScrollParallax,
    parallaxWrapperComponent: ParallaxWrapper = ScrollParallaxWrapper,
    useScrollDownHide,
    expendScrollParallaxElement,
    parentWidth,
    handleResize,
    handleResizeSideWidth,
  } = useProps(props);

  const { ref: refLeftWrapper } = useResizeDetector({
    handleWidth: true,
    onResize: handleResizeSideWidth,
  });
  const { ref: refRightWrapper } = useResizeDetector({
    handleWidth: true,
    onResize: handleResizeSideWidth,
  });
  useResizeDetector({
    handleWidth: true,
    refreshMode: "debounce",
    targetRef: refThis,
    onResize: handleResize,
  });

  const subTitle = React.useMemo(
    () => (subTitleElement ? <SubTitle>{subTitleElement}</SubTitle> : null),
    [subTitleElement],
  );

  const leftElement = React.useMemo(
    () => (
      <LeftWrapper ref={refLeftWrapper} sideElementWidth={sideElementWidth}>
        {leftButton}
      </LeftWrapper>
    ),
    [leftButton, sideElementWidth],
  );

  const rightElement = React.useMemo(
    () => (
      <RightWrapper ref={refRightWrapper} sideElementWidth={sideElementWidth}>
        {rightButton}
      </RightWrapper>
    ),
    [rightButton, sideElementWidth],
  );

  const parallaxElement = React.useMemo(
    () =>
      enableScrollParallax && expendScrollParallaxElement ? (
        <ParallaxWrapper ref={refParallax} opacity={parallaxOpacity}>
          {expendScrollParallaxElement}
        </ParallaxWrapper>
      ) : null,
    [
      enableScrollParallax,
      expendScrollParallaxElement,
      parallaxOpacity,
      refParallax,
    ],
  );

  const appBarElement = React.useMemo(() => {
    const contents = (
      <AppBarWrapper wrapperStyle={wrapperStyle} fixedWidth={parentWidth}>
        {leftElement}
        <CenterWrapper>
          <TitleContainer
            alignment={titleAlignmentState}
            opacity={alwaysShowAppBarTitle ? 1 : titleOpacity}
          >
            <MainTitle>{titleElement}</MainTitle>
            {subTitle}
          </TitleContainer>
        </CenterWrapper>
        {rightElement}
      </AppBarWrapper>
    );

    if (useScrollDownHide) {
      return (
        <DisplayController<{
          overrideStickyStyle?: FlattenInterpolation<any>;
        }>
          overrideStyle={stickyWrapperStyle}
          overrideStickyStyle={wrapperStickyStyle}
        >
          {contents}
        </DisplayController>
      );
    }

    return (
      <StickyWrapper overrideStickyStyle={wrapperStickyStyle}>
        {contents}
      </StickyWrapper>
    );
  }, [
    alwaysShowAppBarTitle,
    leftElement,
    parentWidth,
    rightElement,
    subTitle,
    titleAlignmentState,
    titleElement,
    titleOpacity,
    useScrollDownHide,
    wrapperStickyStyle,
    wrapperStyle,
  ]);

  return (
    <Wrapper
      ref={refThis}
      isSticky={enableScrollParallax}
      isParallaxVisible={isParallaxVisible}
      wrapperStickedStyle={wrapperStickedStyle}
    >
      {appBarElement}
      {parallaxElement}
    </Wrapper>
  );
};

export default AppBar;
export { IScrollParallaxContainer };
