import * as React from "react";
import { Wrapper } from "./styled";
import { useBlockitListLayoutConfig } from "../hooks/useLayout";
import { useBlockitRendererContext } from "app/common/components/blockitEditorBase/components/blockitRenderer.v2/context/index";

export interface IExternalProps {
  disappearBottomDivider?: boolean;
}

interface IProps
  extends Omit<Moim.Blockit.IListStyleElement, "type">,
    IExternalProps {
  children: React.ReactNode[];
}

const GridLayout: React.FC<IProps> = ({ disappearBottomDivider, ...props }) => {
  const { children } = props;
  const {
    gapSize,
    columnSize,
    rowSize,
    maxViewCount,
    itemStackDirection,
  } = useBlockitListLayoutConfig("vertical", props);
  const { backgroundColor } = useBlockitRendererContext();

  return (
    <Wrapper
      gapSize={gapSize}
      columnCount={columnSize}
      rowCount={rowSize}
      stackDirection={itemStackDirection}
      backgroundColor={backgroundColor}
      disappearBottomDivider={disappearBottomDivider}
    >
      {maxViewCount ? children.slice(0, maxViewCount) : children}
    </Wrapper>
  );
};

export default GridLayout;
