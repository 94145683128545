import React from "react";
import moment from "moment-timezone";
// components
import { DefaultLoader as Loader } from "common/components/loading";
import Username from "../steps/username";
import Main from "../steps/main";
import Phone from "../steps/phone";
import { ProfileImage } from "../steps/profileImage";
import { SignUpForm } from "../steps/signUpForm";
import { ShippingAddress } from "../steps/shippingAddress";
import { ReferralCode } from "../steps/referralCode";

import { LoadingWrapper } from "../styled";

import { useActions, useStoreState } from "app/store";
import useCancelToken from "common/hooks/useCancelToken";
import useCurrentUser from "common/hooks/useCurrentUser";
import { useIntlShort } from "common/hooks/useIntlShort";
import { useSnackbar } from "common/components/alertTemplates/alerts/globalSnackbar/useGlobalSnackbar";

import { postUser } from "app/actions/user";
import getUserLocale from "common/helpers/getUserLocale";
import { AnalyticsClass } from "common/helpers/analytics/analytics";
import * as CookieHandler from "common/helpers/cookieHandler";

import { GROUP_INVITE_CODE } from "common/constants/keys";
import { getInMemoryLocalCurrency } from "common/api";
import { StepContext } from "common/components/dialogs/dialogStepController";
import { updateMyProfile } from "app/actions/me";
import { JoinGroupDialogProgressBar } from "../component/progressBar";
import LeaveAlertDialog from "../component/leaveAlertDiaog";
import useOpenState from "common/hooks/useOpenState";
import { ActionCreators as GroupActionCreators } from "app/actions/group";
import { IRef } from "./current";

interface IProps {
  group?: Moim.Group.IGroup | null;
  authentication: Moim.IAuthentication | null;
  onClickDoneButton(): void;
}

const JoinDialogStepRenderer: React.FC<IProps> = ({
  group,
  authentication,
  onClickDoneButton,
}: IProps) => {
  const {
    isLastStep,
    onNext,
    currentStep,
    steps,
    setCurrentStep,
  } = React.useContext(StepContext);
  const step = steps[currentStep];
  const { isLoading, currentHubGroupId } = useStoreState(state => ({
    isLoading:
      state.joinGroupDialog.isLoading ||
      state.joinGroupDialog.isGetParentMoimUserLoading,
    currentHubGroupId: state.app.currentHubGroupId,
  }));

  /**
   * 이탈했다 다시 시작하는 경우의 사용자 정보 필요
   */
  const currentUser = useCurrentUser();
  const { dispatchJoinGroup, dispatchUpdateMyProfile } = useActions({
    dispatchJoinGroup: postUser,
    dispatchUpdateMyProfile: updateMyProfile,
  });
  const formId = group?.sign_up_config_v2.signUpForm?.formId;

  const intl = useIntlShort();
  const cancelToken = useCancelToken();
  const { open: openSnackbar } = useSnackbar({
    text: intl("join_moim_welcome_toast_message", {
      moim_name: group?.name,
    }),
  });
  const [joinedUser, setJoinedUser] = React.useState<
    Moim.User.IOriginalUserDatum | undefined
  >();

  const currentStepState = React.useMemo(() => {
    switch (step?.key as Moim.Group.JoinGroupDialogStepType) {
      case "profileImage":
        return group?.sign_up_config_v2.profileImage?.state;

      case "phone":
        return group?.sign_up_config_v2.phone?.state;

      case "signUpForm":
        return group?.sign_up_config_v2.signUpForm?.state;

      case "shippingAddress":
        return group?.sign_up_config_v2.shippingAddress?.state;

      case "referralCode":
        return group?.sign_up_config_v2.referralCode?.state;
    }
  }, [step?.key, group]);

  /**
   * joined user 정보를 사용해야 하는데, 회원 이름을 입력하는 첫번째 케이스에서는 State 가 갱신되지 않은 상태의 Context 로 함수가 실행됨
   */
  const handleNext = React.useCallback(
    (_joinedUser?: Moim.User.IOriginalUserDatum | undefined) => {
      if (isLastStep) {
        onClickDoneButton();
        openSnackbar();

        const user = _joinedUser ?? joinedUser ?? currentUser;

        if (user && user.group_id === currentHubGroupId) {
          AnalyticsClass.getInstance().signUpUser(user.id ?? "");
        }
      } else {
        onNext();
      }
    },
    [
      isLastStep,
      onClickDoneButton,
      openSnackbar,
      joinedUser,
      currentUser,
      currentHubGroupId,
      onNext,
    ],
  );

  const handleJoinGroup = React.useCallback(
    async (
      user: Partial<PickValue<Moim.User.IPostUserRequestBody, "user">>,
    ) => {
      const username = user.name;
      if (!group || !authentication || !username) {
        return;
      }

      try {
        const inviteCode = CookieHandler.get(
          GROUP_INVITE_CODE(group.is_hub ? group.id : group.parent!),
        );
        const result = await dispatchJoinGroup(
          {
            groupId: group.id,
            useParentProfile: group.config.useOnlyParentProfile,
            user: {
              name: username,
              tz: moment.tz.guess(),
              locale: getUserLocale(),
              authentication: { ...authentication, provider: "cryptobadge" },
              currency: getInMemoryLocalCurrency(),
              provider: authentication.provider,
              ...user,
            },
            referral:
              inviteCode && group.is_hub
                ? { code: inviteCode, url: location.href }
                : undefined,
          },
          cancelToken.current.token,
        );

        setJoinedUser(result);
        return result;
      } catch (error) {
        throw error;
      }
    },
    [group, authentication, cancelToken, dispatchJoinGroup],
  );

  const handleMainNext = React.useCallback(
    async (
      user: Partial<PickValue<Moim.User.IPostUserRequestBody, "user">>,
    ) => {
      try {
        await handleJoinGroup(user);
        if (steps.length > 2) {
          setCurrentStep(2);
        } else {
          onClickDoneButton();
        }
      } catch (error) {
        throw error;
      }
    },
    [handleJoinGroup, steps, setCurrentStep, onClickDoneButton],
  );

  const handleUsernameNext = React.useCallback(
    async (
      user: Partial<PickValue<Moim.User.IPostUserRequestBody, "user">>,
    ) => {
      try {
        const joined = await handleJoinGroup(user);
        handleNext(joined);
      } catch (error) {
        throw error;
      }
    },
    [handleJoinGroup, handleNext],
  );

  const handleClickSkipButton = React.useCallback(async () => {
    if (!group?.id || !step?.key) {
      return;
    }
    await dispatchUpdateMyProfile(
      {
        signUpInfo: {
          finishedStep: step.key as Moim.Group.JoinGroupDialogStepType,
        },
      },
      undefined,
      group.id,
    );
    handleNext();
  }, [group?.id, step?.key, handleNext]);

  const buttonText = isLastStep
    ? group?.config.needSignUpApproval
      ? intl("button_apply_signup")
      : intl("button_signup")
    : intl("button_signup_next");

  const skipButtonText =
    currentStepState === "optional" ? intl("button_signup_skip") : undefined;

  if (isLoading) {
    return (
      <LoadingWrapper>
        <Loader />
      </LoadingWrapper>
    );
  }

  switch (step?.key) {
    case "main":
      return (
        <Main
          onClickSetUsername={handleNext}
          onClickUseParentProfile={handleMainNext}
        />
      );

    case "username":
      return (
        <Username
          group={group}
          buttonText={buttonText}
          onNext={handleUsernameNext}
        />
      );

    case "phone":
      return (
        <Phone
          group={group}
          buttonText={buttonText}
          skipButtonText={skipButtonText}
          onNext={handleNext}
          onSkip={
            currentStepState === "optional" ? handleClickSkipButton : undefined
          }
          defaultPhone={joinedUser?.phoneNumber}
        />
      );

    case "profileImage":
      return (
        <ProfileImage
          group={group}
          buttonText={buttonText}
          skipButtonText={skipButtonText}
          onNext={handleNext}
          onSkip={
            currentStepState === "optional" ? handleClickSkipButton : undefined
          }
        />
      );

    case "signUpForm":
      if (formId) {
        return (
          <SignUpForm
            groupId={group?.id}
            buttonText={buttonText}
            skipButtonText={skipButtonText}
            formId={formId}
            formResponseId={joinedUser?.signUpFormResponseId}
            onNext={handleNext}
            onSkip={
              currentStepState === "optional"
                ? handleClickSkipButton
                : undefined
            }
          />
        );
      }
      return null;

    case "shippingAddress":
      return (
        <ShippingAddress
          group={group}
          buttonText={buttonText}
          skipButtonText={skipButtonText}
          onNext={handleNext}
          onSkip={
            currentStepState === "optional" ? handleClickSkipButton : undefined
          }
        />
      );

    case "referralCode":
      return (
        <ReferralCode
          group={group}
          buttonText={buttonText}
          skipButtonText={skipButtonText}
          onNext={handleNext}
          onSkip={
            currentStepState === "optional" ? handleClickSkipButton : undefined
          }
        />
      );
    default:
      return null;
  }
};

const JoinDialogComponent = React.forwardRef<IRef, IProps>((props, ref) => {
  const { currentStep, steps } = React.useContext(StepContext);
  const {
    isOpen: isLeaveAlertDialog,
    open: openLeaveAlertDialog,
    close: closeLeaveAlertDialog,
  } = useOpenState();

  const { dispatchCloseDialog } = useActions({
    dispatchCloseDialog: GroupActionCreators.closeJoinGroupDialog,
  });

  const handleCloseDialog = React.useCallback(() => {
    if (
      steps.slice(currentStep)?.some(step => {
        switch (step.key) {
          case "profileImage":
            return (
              props.group?.sign_up_config_v2.profileImage?.state !==
              "deactivated"
            );
          case "phone":
            return (
              props.group?.sign_up_config_v2.phone?.state !== "deactivated"
            );
          case "signUpForm":
            return (
              props.group?.sign_up_config_v2.signUpForm?.state !== "deactivated"
            );
          case "shippingAddress":
            return (
              props.group?.sign_up_config_v2.shippingAddress?.state !==
              "deactivated"
            );
          case "referralCode":
            return (
              props.group?.sign_up_config_v2.referralCode?.state !==
              "deactivated"
            );
        }
      })
    ) {
      openLeaveAlertDialog();
    } else {
      dispatchCloseDialog();
    }
  }, [
    props.group,
    currentStep,
    steps,
    openLeaveAlertDialog,
    dispatchCloseDialog,
  ]);

  React.useImperativeHandle(ref, () => ({
    onClose: handleCloseDialog,
  }));

  return (
    <>
      <JoinGroupDialogProgressBar />

      <JoinDialogStepRenderer {...props} />
      <LeaveAlertDialog
        isOpen={isLeaveAlertDialog}
        onClose={closeLeaveAlertDialog}
      />
    </>
  );
});
export default React.memo(JoinDialogComponent);
