import { IPostShowModalQueryParams } from "./component";
import { POST_SHOW_SCROLL_TO_BOTTOM } from "common/constants/keys";
import { MoimURL } from "common/helpers/url";
import useIsMobile from "common/hooks/useIsMobile";
import useRedirect from "common/hooks/useRedirect";
import * as qs from "query-string";
import * as React from "react";

export function useRedirectPostShowModal(
  params: IPostShowModalQueryParams & {
    postId: string;
    scrollToBottom?: boolean;
  },
) {
  const isMobile = useIsMobile();
  const redirect = useRedirect();

  return React.useCallback(() => {
    if (params.scrollToBottom) {
      sessionStorage.setItem(POST_SHOW_SCROLL_TO_BOTTOM, "true");
    } else {
      sessionStorage.removeItem(POST_SHOW_SCROLL_TO_BOTTOM);
    }

    const query = qs.parse(location.search, { arrayFormat: "bracket" });
    redirect({
      pathname: isMobile
        ? new MoimURL.ShowThread({ threadId: params.postId }).toLocation({})
            .pathname
        : location.pathname,
      search: qs.stringify({
        ...query,
        postId: isMobile ? undefined : params.postId,
        groupId: params.groupId,
      }),
    });
  }, [isMobile, location.search, params.groupId, params.postId]);
}

export function useRedirectPostShowModalWithThread(
  params: IPostShowModalQueryParams & { thread: Moim.Forum.IThread },
) {
  const { thread } = params;
  const redirect = useRedirect();

  return React.useCallback(() => {
    switch (thread.type) {
      case "reply":
        if (thread.root_id && thread.original_id) {
          redirect(
            new MoimURL.FocusedShowForumThread({
              forumId: thread.root_id,
              threadId: thread.original_id,
              focusedId: thread.id,
            }).toString(),
          );
        }
        break;
      case "nestedReply":
        if (thread.root_id && thread.original_id) {
          redirect(
            new MoimURL.FocusedShowSubReply({
              forumId: thread.root_id,
              threadId: thread.original_id,
              commentId: thread.parent_id,
              focusedId: thread.id,
            }).toString(),
          );
        }
        break;
      default: {
        const query = qs.parse(location.search, { arrayFormat: "bracket" });
        redirect({
          pathname: location.pathname,
          search: qs.stringify({
            ...query,
            postId: params.postId,
            groupId: params.groupId,
          }),
        });
      }
    }
  }, [
    params.groupId,
    params.postId,
    redirect,
    thread.id,
    thread.original_id,
    thread.parent_id,
    thread.root_id,
    thread.type,
  ]);
}
