import { getCommerceAPIDomain } from "common/helpers/domainMaker";
import { MoimAPI } from "..";
import { MoimBaseAPI } from "./";

export class MoimCommerceAPI extends MoimBaseAPI {
  public constructor(protected instance: MoimAPI) {
    super(instance);
    const headers: any = {};

    const localCurrency = instance.getLocalCurrency();

    if (localCurrency) {
      headers["x-user-currency"] = localCurrency;
    }
    this.extendConfig({
      headers,
      baseURL: getCommerceAPIDomain(),
    });
  }
}
