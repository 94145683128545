import * as React from "react";
import { MoimURL } from "common/helpers/url";

import GuestUserProfile from "../guestUserProfile";
import { ActionCreators as SecondaryViewActions } from "app/actions/secondaryView";

import UserProfileImage from "common/components/userProfileImage";
import ProfileMenu from "../../../../components/profileMenu";
import ParentProfileMenu from "../../../../components/parentProfileMenu";
import PeriodPopover from "../../../../components/periodPopover";
import NotiAlertBadge from "common/components/alertBadge/preset/notification";
import DMAlertBadge from "common/components/alertBadge/preset/directMessage";
import CartAlertBadge from "common/components/alertBadge/preset/cartBadge";
import { SpacerVertical } from "common/components/designSystem/spacer";

import {
  Wrapper,
  ItemWrapper,
  IconWrapper,
  NotiIcon,
  DMIcon,
  PeriodIcon,
  MyCartIcon,
  CurrentUserWrapper,
  NotiAlertBadgeStyle,
  buttonFactory,
} from "./styled";

import useOpenState from "common/hooks/useOpenState";
import useCurrentUser from "common/hooks/useCurrentUser";
import useNotificationsDialog from "common/hooks/useNotificationsDialog";
import useParentMoimUser from "common/hooks/useParentMoimUser";
import { useHandleSignIn, useHandleSignUp } from "common/hooks/useHandleSign";
import { useEnableGuestCheckout } from "common/hooks/commerce/useCommerceConfig";
import DirectMessageList from "../../../../components/directMessageList";
import { useActions, useStoreState } from "app/store";
import useCurrentGroup from "common/hooks/useCurrentGroup";
import useVisibleMyCart, {
  useVisibleGuestCart,
} from "../../../../hooks/useVisibleMyCart";
import { useIntlShort } from "common/hooks/useIntlShort";
import useRedirect from "common/hooks/useRedirect";

const SignUpButton = ({
  type,
  ...props
}: React.PropsWithChildren<
  {
    type: Required<Moim.Layout.Navigation.IButtonStyle["type"]>;
  } & Record<string, any>
>) =>
  React.createElement(buttonFactory(type), {
    ...props,
    className: `signup ${props.className}`,
  });

const SignInButton = ({
  type,
  ...props
}: React.PropsWithChildren<
  {
    type: Required<Moim.Layout.Navigation.IButtonStyle["type"]>;
  } & Record<string, any>
>) =>
  React.createElement(buttonFactory(type), {
    ...props,
    className: `signin ${props.className}`,
  });

interface IProps {
  blockProperties?: Moim.Layout.Navigation.CurrentUserElementType;
}

const CurrentUser: React.FC<IProps> = ({ blockProperties }) => {
  const currentGroup = useCurrentGroup();
  const currentUser = useCurrentUser();
  const parentUser = useParentMoimUser();
  const redirect = useRedirect();
  const { signUpButtonScale, currentUserId } = useStoreState(state => ({
    signUpButtonScale: state.group.theme.element.topArea.others.scale,
    currentUserId: state.app.currentUserId,
  }));
  const handleSignIn = useHandleSignIn();
  const handleSignUp = useHandleSignUp();
  const intl = useIntlShort();
  const visiblePeriodIcon = React.useMemo(
    () => currentGroup?.is_hub && currentGroup?.status_config?.type !== "none",
    [currentGroup],
  );

  const { openFromProfile } = useActions({
    openFromProfile: SecondaryViewActions.openNativeSecondaryViewFromProfile,
  });

  const visibleMyCartIcon = useVisibleMyCart();

  const visibleGuestCartIcon = useVisibleGuestCart();
  const enableGuestCheckout = useEnableGuestCheckout();

  const visibleUser = React.useMemo(() => currentUser ?? parentUser, [
    currentUser,
    parentUser,
  ]);
  const visibleNotiIcon = React.useMemo(() => Boolean(currentUser), [
    currentUser,
  ]);

  const visibleDMIcon = React.useMemo(
    () =>
      Boolean(currentUser) && !Boolean(blockProperties?.iconDisplay?.hideDM),
    [currentUser, blockProperties?.iconDisplay?.hideDM],
  );

  const profileRef = React.useRef<HTMLDivElement>(null);
  const notiRef = React.useRef<HTMLDivElement>(null);
  const dmRef = React.useRef<HTMLDivElement>(null);
  const periodRef = React.useRef<HTMLDivElement>(null);
  const {
    isOpen: isProfileMenuOpen,
    open: openProfileMenu,
    close: closeProfileMenu,
  } = useOpenState();
  const {
    isOpen: isParentProfileMenuOpen,
    open: openParentProfileMenu,
    close: closeParentProfileMenu,
  } = useOpenState();
  const {
    isOpen: isDirectMessageListDialogOpen,
    open: openDirectMessageListDialog,
    close: closeDirectMessageListDialog,
  } = useOpenState();
  const {
    isOpen: isPeriodDialogOpen,
    open: openPeriodDialog,
    close: closePeriodDialog,
  } = useOpenState();
  const {
    open: isNotificationsDialogOpen,
    openNotificationsDialog,
  } = useNotificationsDialog();

  const handleProfileClick = React.useCallback(() => {
    if (currentUser) {
      openProfileMenu();
    } else if (parentUser) {
      openParentProfileMenu();
    }
  }, [currentUser, openParentProfileMenu, openProfileMenu, parentUser]);

  const handleMyCartClick = React.useCallback(() => {
    openFromProfile(true);
    redirect(new MoimURL.CommerceMyCarts().toString());
  }, [redirect]);

  const handleNotificationClick = React.useCallback(() => {
    openNotificationsDialog({
      anchorElement: notiRef,
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "right",
      },
      transformOrigin: {
        vertical: "top",
        horizontal: "right",
      },
    });
  }, [openNotificationsDialog]);

  const inner = React.useMemo(() => {
    if (currentUserId === undefined && blockProperties?.uiMode === undefined) {
      return null;
    }

    if (
      (blockProperties?.uiMode === "signed" ||
        blockProperties?.uiMode === undefined) &&
      (parentUser || currentUserId)
    ) {
      return (
        <>
          <IconWrapper>
            {visiblePeriodIcon && (
              <ItemWrapper
                ref={periodRef}
                selected={isPeriodDialogOpen}
                onClick={openPeriodDialog}
              >
                <PeriodIcon />
              </ItemWrapper>
            )}

            {visibleDMIcon && (
              <ItemWrapper
                ref={dmRef}
                selected={isDirectMessageListDialogOpen}
                onClick={openDirectMessageListDialog}
              >
                <DMIcon />
                <DMAlertBadge overrideStyle={NotiAlertBadgeStyle} />
              </ItemWrapper>
            )}

            {visibleNotiIcon && (
              <ItemWrapper
                ref={notiRef}
                selected={isNotificationsDialogOpen}
                onClick={handleNotificationClick}
              >
                <NotiIcon />
                <NotiAlertBadge overrideStyle={NotiAlertBadgeStyle} />
              </ItemWrapper>
            )}
            {visibleMyCartIcon && (
              <ItemWrapper selected={false} onClick={handleMyCartClick}>
                <MyCartIcon />
                <CartAlertBadge overrideStyle={NotiAlertBadgeStyle} />
              </ItemWrapper>
            )}
          </IconWrapper>

          <CurrentUserWrapper
            ref={profileRef}
            selected={isProfileMenuOpen || isParentProfileMenuOpen}
            onClick={handleProfileClick}
          >
            <UserProfileImage
              src={visibleUser?.avatar_url || ""}
              size="s"
              isOnline={true}
              elementPaletteProps={{ type: "topArea", key: "others" }}
            />
          </CurrentUserWrapper>
        </>
      );
    }

    return (
      <>
        {visiblePeriodIcon && (
          <>
            <ItemWrapper
              ref={periodRef}
              selected={isPeriodDialogOpen}
              onClick={openPeriodDialog}
            >
              <PeriodIcon />
            </ItemWrapper>
          </>
        )}
        <SpacerVertical value={8} />

        <SignInButton
          type={blockProperties?.signInButtonStyle?.type ?? "GHOST"}
          size="m"
          onClick={handleSignIn}
        >
          {intl("button_login")}
        </SignInButton>
        <SignUpButton
          type={blockProperties?.signUpButtonStyle?.type ?? "FLAT"}
          size="m"
          signUpbuttonScale={signUpButtonScale}
          onClick={handleSignUp}
        >
          {intl("button_signup")}
        </SignUpButton>

        {visibleGuestCartIcon && (
          <>
            {enableGuestCheckout && <SpacerVertical value={4} />}
            <ItemWrapper selected={false} onClick={handleMyCartClick}>
              <MyCartIcon />
              <CartAlertBadge overrideStyle={NotiAlertBadgeStyle} />
            </ItemWrapper>
          </>
        )}
        {enableGuestCheckout && <GuestUserProfile />}
      </>
    );
  }, [
    currentUserId,
    blockProperties,
    parentUser,
    visiblePeriodIcon,
    isPeriodDialogOpen,
    openPeriodDialog,
    handleSignIn,
    intl,
    signUpButtonScale,
    handleSignUp,
    visibleGuestCartIcon,
    enableGuestCheckout,
    handleMyCartClick,
    visibleDMIcon,
    isDirectMessageListDialogOpen,
    openDirectMessageListDialog,
    visibleNotiIcon,
    isNotificationsDialogOpen,
    handleNotificationClick,
    visibleMyCartIcon,
    isProfileMenuOpen,
    isParentProfileMenuOpen,
    handleProfileClick,
    visibleUser,
  ]);

  return (
    <Wrapper element={blockProperties}>
      {inner}
      <ProfileMenu
        open={isProfileMenuOpen}
        anchorElement={profileRef.current}
        onCloseRequest={closeProfileMenu}
        onClickMenuButton={closeProfileMenu}
      />
      <ParentProfileMenu
        open={isParentProfileMenuOpen}
        anchorElement={profileRef.current}
        onCloseRequest={closeParentProfileMenu}
        onClickMenuButton={closeParentProfileMenu}
      />
      <DirectMessageList
        open={isDirectMessageListDialogOpen}
        anchorElement={dmRef.current}
        onCloseRequest={closeDirectMessageListDialog}
        onClickMenuButton={closeDirectMessageListDialog}
      />
      <PeriodPopover
        open={isPeriodDialogOpen}
        anchorElement={periodRef.current}
        onCloseRequest={closePeriodDialog}
        onClickMenuButton={closePeriodDialog}
      />
    </Wrapper>
  );
};

export default React.memo(CurrentUser);
