import { GlobalAlertDialogTypes } from "app/actions/types";
import { ActionUnion } from "app/actions/helpers";

function createAction<T extends { type: GlobalAlertDialogTypes }>(d: T): T {
  return d;
}

export const ActionCreators = {
  open: (payload: {
    content: string;
    buttons?: {
      text: string;
      textColor?: string;
    }[];
  }) =>
    createAction({
      type: GlobalAlertDialogTypes.OPEN,
      payload,
    }),

  close: () =>
    createAction({
      type: GlobalAlertDialogTypes.CLOSE,
    }),
};

export type Actions = ActionUnion<typeof ActionCreators>;
