import * as React from "react";
import moment from "moment";
import { FormattedMessage } from "react-intl";

import PhoneNumberInput, {
  IRefHandler as IPhoneRefHandler,
} from "./components/phoneNumberInput";
import VerifyCodeInput from "./components/verifyCodeInput";
import { StepWrapper, Title, ErrorMessage } from "./styled";

import { useActions, useStoreState } from "app/store";
import useCancelToken from "common/hooks/useCancelToken";
import { updateMyProfile } from "app/actions/me";
import BaseTemplate from "app/hub/createGroup/components/step/template";
import { Contents, Wrapper } from "../styled";

export const PhoneEdit: React.FC<{
  stepData: Moim.Group.ICreateMoimStepData;
  groupId: string | undefined;
  defaultPhone?: Moim.User.IUserPhone;
  updateProfile(
    phoneNumber: Moim.User.IUserPhone | undefined,
  ): Promise<{ error?: Moim.IErrorResponse } | undefined>;
  onClickSkipButton?(): void;
}> = ({
  stepData,
  groupId,
  updateProfile,
  onClickSkipButton,
  defaultPhone,
}) => {
  const { isUpdateProfileLoading } = useStoreState(state => ({
    isUpdateProfileLoading: state.profileEditorPage.isUpdateLoading,
  }));

  const refPhoneInput = React.useRef<IPhoneRefHandler>(null);
  const [phoneValidationSucceed, setPhoneValidationSucceed] = React.useState(
    false,
  );
  const [verifyError, setVerifyError] = React.useState<
    Moim.IErrorResponse | undefined
  >(undefined);
  const [updateProfileError, setUpdateProfileError] = React.useState<
    Moim.IErrorResponse | undefined
  >(undefined);
  const [verifyInputShowStatus, setVerifyInputShowStatus] = React.useState(
    false,
  );
  const [verifyEndTime, setVerifyEndTime] = React.useState<
    number | undefined
  >();

  const handleClickDoneButton = React.useCallback(async () => {
    const phoneNumber = refPhoneInput.current?.getPhoneNumber();
    const result = await updateProfile(phoneNumber);

    setUpdateProfileError(result?.error);
  }, [updateProfile]);

  const resetVerifyTimer = React.useCallback(() => {
    setVerifyEndTime(
      moment()
        .add(3, "minutes")
        .valueOf(),
    );
    setVerifyError(undefined);
  }, []);
  const handleVerifyTimeout = React.useCallback(() => {
    setVerifyEndTime(undefined);
  }, []);

  const showVerifyInput = React.useCallback(() => {
    setVerifyInputShowStatus(true);
  }, []);

  return (
    <Wrapper>
      <Contents>
        <BaseTemplate
          stepData={stepData}
          onClick={handleClickDoneButton}
          onClickSkipButton={onClickSkipButton}
          waitingButton={isUpdateProfileLoading}
          disabledButton={!phoneValidationSucceed}
        >
          <StepWrapper>
            <Title>
              <FormattedMessage id="mobile_phone_number_verification/title" />
            </Title>
            <PhoneNumberInput
              ref={refPhoneInput}
              defaultPhone={defaultPhone}
              phoneValidationSucceed={phoneValidationSucceed}
              groupId={groupId}
              showVerifyInput={showVerifyInput}
              resetVerifyTimer={resetVerifyTimer}
            />
            {verifyInputShowStatus && (
              <VerifyCodeInput
                verifyEndTime={verifyEndTime}
                phoneValidationSucceed={phoneValidationSucceed}
                setPhoneValidationSucceed={setPhoneValidationSucceed}
                error={verifyError}
                setError={setVerifyError}
                onVerifyTimeout={handleVerifyTimeout}
              />
            )}
            {updateProfileError && (
              <ErrorMessage>{updateProfileError.message}</ErrorMessage>
            )}
          </StepWrapper>
        </BaseTemplate>
      </Contents>
    </Wrapper>
  );
};

export default function Phone({
  group,
  defaultPhone,
  buttonText,
  skipButtonText,
  onNext,
  onSkip,
}: {
  group?: Moim.Group.IGroup | null;
  defaultPhone?: Moim.User.IUserPhone;
  buttonText: string;
  skipButtonText?: string;
  onNext(): void;
  onSkip?(): void;
}) {
  const { dispatchUpdateProfile } = useActions({
    dispatchUpdateProfile: updateMyProfile,
  });
  const cancelToken = useCancelToken();

  const updateProfile = React.useCallback(
    async (phoneNumber: Moim.User.IUserPhone | undefined) => {
      if (!group?.id) {
        return;
      }
      const { success, error } = await dispatchUpdateProfile(
        { signUpInfo: { phoneNumber, finishedStep: "phone" } },
        cancelToken.current.token,
        group.id,
      );
      if (success) {
        onNext();
      } else {
        return { error };
      }
    },
    [group?.id, cancelToken, dispatchUpdateProfile, onNext],
  );

  const stepData = React.useMemo(
    () => ({
      title: <FormattedMessage id="sign_up_form_verify_phone_number_title" />,
      subTitle: (
        <FormattedMessage
          id={
            group?.event_noti_config?.phoneAuthentication?.kakaoTalk?.active
              ? "sign_up_form_verify_phone_number_description_kakao"
              : "sign_up_form_verify_phone_number_description"
          }
        />
      ),
      buttonText,
      skipButtonText,
    }),
    [group, buttonText, skipButtonText],
  );
  return (
    <PhoneEdit
      stepData={stepData}
      defaultPhone={defaultPhone}
      groupId={group?.id}
      updateProfile={updateProfile}
      onClickSkipButton={onSkip}
    />
  );
}
