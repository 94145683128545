import produce from "immer";
import { AllActions } from "app/actions";
import { GlobalUserStatusFeedbackDialog } from "app/actions/types";

export interface IState {
  open: boolean;
}

export const INITIAL_STATE: IState = {
  open: false,
};

export function reducer(state = INITIAL_STATE, action: AllActions) {
  return produce(state, draft => {
    switch (action.type) {
      case GlobalUserStatusFeedbackDialog.OPEN: {
        draft.open = true;

        break;
      }

      case GlobalUserStatusFeedbackDialog.CLOSE: {
        draft.open = false;
        break;
      }
    }
  });
}
