import { Svg18InfoG, Svg24CloseB } from "@moim/icons";
import styled from "styled-components";
import { px2rem } from "common/helpers/rem";




import { MEDIA_QUERY } from "common/constants/responsive";
import { GhostGeneralButton } from "common/components/designSystem/buttons";
import { B4Regular, H10BoldStyle } from "common/components/designSystem/typos";

export const CloseButton = styled(Svg24CloseB).attrs({
  size: "m",
  touch: 24,
  role: "button",
})``;

export const GuideWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${px2rem(8)};
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${px2rem(18)};
  height: ${px2rem(18)};
`;

export const GuideText = styled(B4Regular)`
  color: ${props => props.theme.colorV2.colorSet.grey300};
`;

export const TrackingDialogWrapper = styled.div`
  width: 100%;
  position: relative;
  width: ${px2rem(455)};
  min-height: ${px2rem(180)};

  @media ${MEDIA_QUERY.ONLY_MOBILE} {
    width: 100%;
    min-height: 100%;
    padding: ${px2rem(16)};
  }
`;

export const TrackingDialogContainer = styled.div`
  flex: 1;
  min-width: 0;
  padding: ${px2rem(9)} ${px2rem(48)} ${px2rem(22)} ${px2rem(48)};

  @media ${MEDIA_QUERY.ONLY_MOBILE} {
    padding: 0;
  }
`;

export const DetailWrapper = styled.div`
  margin-top: ${px2rem(12)};
  margin-bottom: ${px2rem(8)};
`;

export const ButtonContainer = styled.div`
  padding: ${px2rem(8)} 0;
`;

export const ConfirmButton = styled(GhostGeneralButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${px2rem(32)};
  padding: ${px2rem(8)} ${px2rem(16)};
  border-color: ${props => props.theme.colorV2.accent};
  color: ${props => props.theme.colorV2.accent};
  ${H10BoldStyle};
`;

export const InfoIcon = styled(Svg18InfoG).attrs(props => ({
  size: "xs",
  touch: 18,
  iconColor: props.theme.colorV2.colorSet.grey300,
}))``;
