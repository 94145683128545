import React from "react";
import styled from "styled-components";
import { rgba } from "polished";
import { Spacer } from "common/components/designSystem/spacer";
import { PostShowContext } from "app/modules/postShow/context";
import { px2rem } from "common/helpers/rem";
import { useStoreState } from "app/store";
import { PermissionDeniedFallbackType } from "app/enums";
import PermissionChecker from "common/components/permissionChecker";

import UnlockMethodInformation from "./unlockMethod";
import AuthorInformation from "./authorInfo";
import { MEDIA_QUERY } from "common/constants/responsive";
import { getShowAuthorBottom } from "app/modules/postShow/utils/migrateVariable";

const FOOTER_HEIGHT = 68;

const Wrapper = styled.div<{ disableBGBlur: boolean; hasFooter: boolean }>`
  width: 100%;
  position: absolute;
  height: 402px;
  bottom: ${props => (props.hasFooter ? px2rem(FOOTER_HEIGHT) : 0)};
  right: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  z-index: ${props => props.theme.zIndexes.default};
  background-image: ${props =>
    props.disableBGBlur
      ? "inherit"
      : `linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, ${rgba(
          props.theme.colorV2.colorSet.white1000,
          1,
        )} 60%, ${rgba(props.theme.colorV2.colorSet.white1000, 1)} 100%)`};

  @media ${MEDIA_QUERY.ONLY_MOBILE} {
    height: ${px2rem(308)};
  }
`;

const FooterGradient = styled.div`
  width: 100%;
  height: ${px2rem(FOOTER_HEIGHT)};
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;

  background-image: ${props =>
    `linear-gradient(to bottom, ${props.theme.colorV2.colorSet.white1000} 0%, transparent 100%)`}};
`;

const ReadMore: React.FC = ({}) => {
  const { post, showConfig } = React.useContext(PostShowContext);

  const unlockMethod = post?.unlockMethods?.find(
    method => method.resourceType === "transferCoin",
  );

  const { author } = useStoreState(state => ({
    author: post?.author ? state.entities.users[post.author] : undefined,
  }));

  const bottomAuthorConfig = React.useMemo(
    () => getShowAuthorBottom(showConfig),
    [showConfig],
  );

  const enableFooter =
    bottomAuthorConfig.active && !!post.preview?.blocks?.length;

  return (
    <>
      <Wrapper
        disableBGBlur={!post.preview?.blocks?.length}
        hasFooter={enableFooter}
      >
        <PermissionChecker
          fallbackType={PermissionDeniedFallbackType.SCREEN}
          hasPermission={!!post.locked}
          isLoading={false}
        >
          <AuthorInformation author={author} />
          {Boolean(unlockMethod) && (
            <>
              <Spacer value={12} />
              <UnlockMethodInformation unlockMethod={unlockMethod} />
            </>
          )}
          <Spacer value={34} />
        </PermissionChecker>
      </Wrapper>
      {enableFooter ? (
        <>
          <Spacer value={68} />
          <FooterGradient />
        </>
      ) : null}
    </>
  );
};

export default ReadMore;
