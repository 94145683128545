import styled from "styled-components";
import { px2rem } from "common/helpers/rem";
import {
  B3Regular,
  H4RegularStyle,
} from "common/components/designSystem/typos";
import { MEDIA_QUERY } from "common/constants/responsive";

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  margin-top: ${px2rem(40)};
  padding: ${px2rem(54)} ${px2rem(64)} ${px2rem(46)};
  background-color: ${props => props.theme.colorV2.colorSet.grey10};

  @media ${MEDIA_QUERY.ONLY_MOBILE} {
    padding: ${px2rem(54)} ${px2rem(16)} ${px2rem(46)};
  }
`;

export const AvatarPlacement = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  transform: translate3d(${px2rem(-84)}, -50%, 0);
  width: ${px2rem(80)};
  height: ${px2rem(80)};

  [class^="SizeWrapper"] {
    width: ${px2rem(80)};
    height: ${px2rem(80)};
  }

  @media ${MEDIA_QUERY.ONLY_MOBILE} {
    transform: translate3d(${px2rem(-36)}, -50%, 0);
  }
`;

export const DetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${px2rem(14)};
  width: 100%;
`;

export const Username = styled.div`
  color: ${props => props.theme.colorV2.colorSet.grey800};
  ${H4RegularStyle}
`;

export const Description = styled(B3Regular)`
  white-space: pre-line;
  color: ${props => props.theme.colorV2.colorSet.grey300};
  > div {
    width: 100%;
  }
`;
