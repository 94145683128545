import React from "react";
import { PostShowContext } from "../../context";
import { useActions, useStoreState } from "app/store";
import useCancelToken from "common/hooks/useCancelToken";
import { getPermission } from "app/actions/permission";
import PermissionChecker from "common/components/permissionChecker";
import { PermissionDeniedFallbackType } from "app/enums";
import { usePostShowPermission } from "../../hooks";
import PostShowPreview from "./components/preview";
import { ThreadContentSkeleton } from "app/modules/forum/containers/forumShow/skeleton";

const PostShowPermissionChecker = ({ children }: React.PropsWithChildren) => {
  const cancelToken = useCancelToken();
  const { post } = React.useContext(PostShowContext);
  const { currentUserId } = useStoreState(state => ({
    currentUserId: state.app.currentUserId,
  }));
  const {
    hasPermission: hasReadPermission,
    isLoading: permissionLoading,
  } = usePostShowPermission("READ_POST");
  const { dispatchGetPermission } = useActions({
    dispatchGetPermission: getPermission,
  });

  React.useEffect(() => {
    if (post) {
      if (permissionLoading === undefined) {
        dispatchGetPermission(
          { resource: post.id },
          cancelToken.current.token,
          post.groupId,
        );
      }
    }
  }, [post?.id, currentUserId]);

  return (
    <PermissionChecker
      fallbackType={PermissionDeniedFallbackType.CUSTOM}
      permissionDeniedCustomElement={<PostShowPreview />}
      unsignedCustomElement={<PostShowPreview />}
      hasPermission={hasReadPermission}
      isLoading={permissionLoading}
      customLoadingElement={<ThreadContentSkeleton />}
    >
      {children}
    </PermissionChecker>
  );
};

export default PostShowPermissionChecker;
