import { Svg24CloseB } from "@moim/icons";
import styled from "styled-components";

import { px2rem } from "common/helpers/rem";
import { MEDIA_QUERY } from "common/constants/responsive";
import { TextGeneralButton } from "common/components/designSystem/buttons";

export const CloseButton = styled(Svg24CloseB).attrs({
  size: "m",
  touch: 24,
  role: "button",
})``;

export const GroupFilterDialogWrapper = styled.div`
  width: 100%;
  position: relative;
  @media ${MEDIA_QUERY.EXCEPT_MOBILE} {
    min-height: 100%;
    display: flex;
    flex-direction: column;
  }
`;

export const GroupFilterContainer = styled.div`
  flex: 1;
  min-width: 0;
  padding: 0 ${px2rem(24)};

  @media ${MEDIA_QUERY.ONLY_MOBILE} {
    padding: 0;
  }
`;

export const GroupFilterButtonContainer = styled.div`
  position: sticky;
  bottom: 0;
  width: 100%;
  padding: ${px2rem(16)} ${px2rem(20)};
  background-color: ${props => props.theme.colorV2.colorSet.white1000};
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: ${px2rem(1)} solid ${props => props.theme.colorV2.colorSet.grey50};
  @media ${MEDIA_QUERY.ONLY_MOBILE} {
    height: ${px2rem(51)};
    padding: ${px2rem(8)} ${px2rem(16)};
  }
`;

export const Title = styled.div`
  margin-left: ${px2rem(9)};
`;

export const GroupFilterButton = styled(TextGeneralButton).attrs({ size: "s" })`
  display: flex;
  width: fit-content;
  padding: 0 ${px2rem(20)};
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.colorV2.colorSet.grey800};
  @media ${MEDIA_QUERY.ONLY_MOBILE} {
    padding: 0;
    &:first-child {
      justify-content: flex-start;
    }
    &:last-child {
      justify-content: flex-end;
    }
  }
`;
