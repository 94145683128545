import * as React from "react";
import shortid from "shortid";

interface IContext {
  key: string;
  doRefresh(): void;
}

const MainPanelRefreshContext = React.createContext<IContext>({
  key: shortid(),
  doRefresh: () => {},
});

export default MainPanelRefreshContext;
