import React from "react";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";

import { PostShowContext } from "app/modules/postShow/context";
import { Spacer } from "common/components/designSystem/spacer";
import PostItem from "./components/postItem";
import Notice from "./components/notice";
import CoinTransferInfo from "./components/coinTransferInfo";
import TokenTransferButton from "./components/transferButton";
import { Dialog } from "common/components/basicResponsiveDialog/styled";
import AppBar from "common/components/appBar";

import { CustomAppBarModalLayout } from "common/components/modalLayout";
import { useStoreState } from "app/store";
import useIsMobile from "common/hooks/useIsMobile";
import useGroupTexts from "common/hooks/useGroupTexts";

import {
  CloseButton,
  DialogTitle,
  dialogContentStyle,
  DialogScrollSection,
  BottomSheetTitle,
  BottomSheetContent,
} from "./styled";

const TokenTransferComponent: React.FC<{
  coinBalance: number;
  onCloseTransferDialog(): void;
}> = ({ coinBalance, onCloseTransferDialog }) => {
  const { post } = React.useContext(PostShowContext);
  const unlockMethod = post?.unlockMethods?.find(
    method => method.resourceType === "transferCoin",
  );
  const coinId = unlockMethod?.resourceId;

  const { coin } = useStoreState(state => ({
    coin: coinId ? state.entities.community_coins[coinId] : undefined,
  }));

  return (
    <>
      <DialogScrollSection>
        <Spacer value={2} />
        <PostItem post={post} />
        <Spacer value={14} />
        <Notice />
        <Spacer value={16} />
        <CoinTransferInfo
          coinBalance={coinBalance}
          coinSymbol={coin?.symbol}
          recipientUserId={post.author}
        />
        <Spacer value={2} />
      </DialogScrollSection>
      {unlockMethod !== undefined && (
        <TokenTransferButton
          unlockMethod={unlockMethod}
          authorId={post.author}
          coinSymbol={coin?.symbol}
          coinPreview={coin?.preview?.XS ?? coin?.imageUrl}
          threadId={post.id}
          channelId={post.parent_id}
          groupId={post.groupId}
          onCloseTransferDialog={onCloseTransferDialog}
        />
      )}
    </>
  );
};

const TokenTransferDialog: React.FC<{
  isOpen: boolean;
  coinBalance: number;
  onClose(): void;
}> = ({ isOpen, coinBalance, onClose }) => {
  const isMobile = useIsMobile();
  const dialogTitle = useGroupTexts("dialog_send_candy_to_unlock_post_title");
  return (
    <>
      {isMobile ? (
        <BottomSheet open={isOpen} onDismiss={onClose}>
          <BottomSheetTitle>{dialogTitle?.singular}</BottomSheetTitle>
          <Spacer value={4} />
          <BottomSheetContent>
            <TokenTransferComponent
              coinBalance={coinBalance}
              onCloseTransferDialog={onClose}
            />
          </BottomSheetContent>
        </BottomSheet>
      ) : (
        <Dialog open={isOpen} onClose={onClose}>
          <CustomAppBarModalLayout
            appBar={
              <AppBar
                leftButton={<CloseButton onClick={onClose} />}
                titleElement={
                  <DialogTitle>{dialogTitle?.singular}</DialogTitle>
                }
                titleAlignment="Center"
              />
            }
            hasAppBarBorder={false}
            contentStyle={dialogContentStyle}
          >
            <TokenTransferComponent
              coinBalance={coinBalance}
              onCloseTransferDialog={onClose}
            />
          </CustomAppBarModalLayout>
        </Dialog>
      )}
    </>
  );
};

export default TokenTransferDialog;
