import { Svg18Like1, Svg18Message1, Svg18Wish1, Svg18View1 } from "@moim/icons";
import styled from "styled-components";






import { px2rem } from "common/helpers/rem";
import { B4RegularStyle } from "common/components/designSystem/typos";
import { getFlexAlignStyle } from "../wrapper/styled";

export const EngageIcon = styled.div`
  width: ${px2rem(18)};
  height: ${px2rem(18)};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const EngageLabel = styled.span`
  color: ${props => props.theme.colorV2.colorSet.grey300};
  ${B4RegularStyle};
`;

export const EngageLikeIcon = styled(Svg18Like1).attrs(props => ({
  size: "xs",
  iconColor: props.theme.colorV2.colorSet.grey200,
}))``;
export const EngageWishIcon = styled(Svg18Wish1).attrs(props => ({
  size: "xs",
  iconColor: props.theme.colorV2.colorSet.grey200,
}))``;
export const EngageCommentIcon = styled(Svg18Message1).attrs(props => ({
  size: "xs",
  iconColor: props.theme.colorV2.colorSet.grey200,
}))``;
export const EngageViewIcon = styled(Svg18View1).attrs(props => ({
  size: "xs",
  iconColor: props.theme.colorV2.colorSet.grey200,
}))``;

export const EngageItem = styled.div`
  display: flex;
  align-items: center;

  & + & {
    margin-left: ${px2rem(8)};
  }
`;

export const Engagement = styled.div<{
  horizontalAlign?: "start" | "center" | "end" | "space-around";
}>`
  width: 100%;
  display: flex;
  align-items: center;
  padding: ${px2rem(2)} 0;
  overflow: hidden;

  ${props =>
    props.horizontalAlign &&
    getFlexAlignStyle({
      direction: "row",
      horizontalAlign: props.horizontalAlign,
    })}
`;
