import useEntitiesFetchSelector from "./useEntitiesFetchSelector";

export default function useCoinRechargePackageFetchSelector(
  requestIds: Moim.Id[],
) {
  const { isLoading, entities } = useEntitiesFetchSelector({
    community_coin_recharge_package: requestIds,
  });

  return {
    isLoading,
    rechargePackages: (entities.community_coin_recharge_package ??
      []) as Moim.Community.Coin.IRechargePackage[],
  };
}
