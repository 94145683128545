import * as React from "react";
import styled from "styled-components";
import { px2rem } from "common/helpers/rem";

import HorizontalScroll from "common/components/horizontalScroll";
import useIsMobile from "common/hooks/useIsMobile";
import ScrollLayout from "common/components/blockitListLayout/layouts/horizontalScroll";
import { MEDIA_QUERY } from "common/constants/responsive";

export const DESKTOP_IMAGE_THUMBNAIL_SIZE = 200;

const ImageCase = styled.div`
  position: relative;
  cursor: pointer;

  width: ${px2rem(DESKTOP_IMAGE_THUMBNAIL_SIZE)};
  height: ${px2rem(DESKTOP_IMAGE_THUMBNAIL_SIZE)};

  @media ${MEDIA_QUERY.ONLY_MOBILE} {
    width: 100%;
    height: 100%;
  }
`;

const DesktopListWrapper = styled(HorizontalScroll)`
  .react-horizontal-scrolling-menu--item
    + .react-horizontal-scrolling-menu--separator {
    margin-left: ${px2rem(4)};
  }
`;
interface IProps {
  children: React.ReactNode[];
}

const ListWrapper: React.FC<IProps> = ({ children }) => {
  const isMobile = useIsMobile();

  if (isMobile) {
    return (
      <ScrollLayout
        scrollable={true}
        itemStackDirection={"horizontal"}
        scrollDirection={"horizontal"}
        rowCount={1}
        rowCount_web={1}
        columnCount={2}
        columnCount_web={2}
        itemGutterSize={4}
      >
        {children.map((child, idx) => {
          return <ImageCase key={`media_${idx}`}>{child}</ImageCase>;
        })}
      </ScrollLayout>
    );
  }
  return (
    <DesktopListWrapper arrowPlacement="inner">
      {children.map((child, idx) => {
        return <ImageCase key={`media_${idx}`}>{child}</ImageCase>;
      })}
    </DesktopListWrapper>
  );
};

export default React.memo(ListWrapper);
