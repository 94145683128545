import { ItemIdTypes } from "app/enums";
import { NativeEmojiSafeText } from "common/components/designSystem/texts";
import ShavedTextV2 from "common/components/shavedText/v2";
import { useThreadItemContext } from "common/components/thread/commonThreadItem/context";
import { ThreadTitleWrapper } from "common/components/thread/components/wrapper/title";
import { textAlignStyle } from "common/components/thread/styles";
import * as React from "react";
import { useIntl } from "react-intl";
import styled, { css } from "styled-components";

const TitleWrapper = styled(ThreadTitleWrapper)<{
  textAlign?: Moim.Forum.ForumListConfigTextAlignment;
  thumbnailUrl?: string;
}>`
  ${(props) =>
    props.thumbnailUrl &&
    css`
      color: ${(props) =>
        props.theme.themeMode.lightPalette.colorSet.white1000};
    `}
  ${textAlignStyle};
`;

function Title({ prefix }: { prefix?: React.ReactNode }) {
  const intl = useIntl();

  const {
    line = 1,
    showTitle,
    stat,
    textAlign,
    title,
    isDraftThread,
    thumbnailUrl,
  } = useThreadItemContext((state) => ({
    isDraftThread: state.item.id.startsWith(ItemIdTypes.DRAFT),
    showTitle: state.config.showTitle,
    line: state.config.titleMaxLine,
    title: state.item.title,
    stat: state.stat,
    textAlign: state.config.textAlignment,
    thumbnailUrl: state.item.preview?.thumbnail?.url,
  }));
  if (!showTitle) {
    return null;
  }
  return (
    <TitleWrapper
      stat={stat}
      prefix={prefix}
      textAlign={textAlign}
      thumbnailUrl={thumbnailUrl}
    >
      <ShavedTextV2 line={line}>
        <NativeEmojiSafeText
          value={
            title
              ? title
              : isDraftThread
              ? intl.formatMessage({ id: "post_editor_auto_saved_draft_title" })
              : ""
          }
        />
      </ShavedTextV2>
    </TitleWrapper>
  );
}

export default React.memo(Title);
