import React from "react";
import { css } from "styled-components";
import { SingleLineBoxInput } from "common/components/designSystem/boxInput";
import { FormItemHeader } from "../header";
import { FormValueContext } from "../context";
import { useIntlShort } from "common/hooks/useIntlShort";
import { useLocaleText } from "common/hooks/localization/useLocaleText";
import { px2rem } from "common/helpers/rem";
import { IRef } from "..";

const EMAIL_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

export const EmailFormItem = React.forwardRef<
  IRef,
  { item: Moim.Form.IFormItem }
>(({ item }, ref) => {
  const intl = useIntlShort();
  const placeholder = useLocaleText(item, "placeholder");
  const { values, errors, onChange, setError } = React.useContext(
    FormValueContext,
  );
  const currentValue = values[item.id];
  const error = errors[item.id];

  React.useImperativeHandle(ref, () => ({
    validate: () => {
      if (item.isRequired && !currentValue) {
        return { [item.id]: intl("error_form_question_required") };
      } else if (currentValue && !EMAIL_REGEX.test(currentValue)) {
        return { [item.id]: intl("error_email_invalid_format") };
      } else {
        return { [item.id]: undefined };
      }
    },
  }));

  return (
    <>
      <FormItemHeader item={item} />
      <SingleLineBoxInput
        value={currentValue}
        placeholder={placeholder}
        onChange={value => {
          onChange(item.id, value);
        }}
        onBlur={() => {
          if (!currentValue || EMAIL_REGEX.test(currentValue)) {
            setError(item.id, undefined);
          } else {
            setError(item.id, intl("error_email_invalid_format"));
          }
        }}
        helperText={error}
        status={error ? "Error" : "Inactive"}
        size="Large"
        wrapperStyle={css`
          margin-left: ${px2rem(16)};
          margin-right: ${px2rem(16)};
        `}
      />
    </>
  );
});
