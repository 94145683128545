import useEntitiesFetchSelector from "./useEntitiesFetchSelector";

export default function usePositionFetchSelector(requestIds: Moim.Id[]) {
  const { isLoading, entities } = useEntitiesFetchSelector({
    positions: requestIds,
  });

  return {
    isLoading,
    positions: entities.positions ?? [],
  };
}
