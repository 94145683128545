import React from "react";

interface IProps {
  addressDetail: {
    zipcode?: string;
    address?: string;
    address2?: string;
    city?: string;
    state?: string;
  };
}

const AddressHumanize = ({ addressDetail }: IProps) => {
  const { zipcode, address, address2, city, state } = addressDetail;
  return (
    <>{`(${zipcode ?? ""}) ${address ?? ""}${address2 ? ` ${address2}` : ""}${
      city ? `, ${city}` : ""
    }${state ? `, ${state}` : ""}`}</>
  );
};

export default AddressHumanize;
