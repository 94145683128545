import * as React from "react";
import { FlattenInterpolation } from "styled-components";
// hooks
import useUserDataWithAutoBatch from "common/hooks/useUserDataWithAutoBatch";
// components
import SimpleType from "./components/simple";
import DetailedType from "./components/detailed";
import { withPlacement } from "../../hoc/withPlacement";
import { Wrapper } from "./styled";

export interface IProps extends Omit<Moim.Blockit.IUserProfilePreview, "type"> {
  wrapperStyle?: FlattenInterpolation<any>;
}

const UserProfilePreviewBlock: React.FC<IProps> = ({
  userId,
  canUserId,
  showConfig,
}) => {
  const { user } = useUserDataWithAutoBatch(userId, canUserId);

  if (!user) {
    return null;
  }

  return (
    <Wrapper>
      {showConfig.type === "simple" ? (
        <SimpleType user={(user as unknown) as Moim.User.IOriginalUserDatum} />
      ) : (
        <DetailedType
          user={(user as unknown) as Moim.User.IOriginalUserDatum}
        />
      )}
    </Wrapper>
  );
};

export default withPlacement(React.memo(UserProfilePreviewBlock));
