import * as React from "react";
import { Link } from "react-router-dom";

import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import { MoimURL } from "common/helpers/url";
// hooks
import { useHandleSignIn } from "common/hooks/useHandleSign";
import useIsMobile from "common/hooks/useIsMobile";
// components
import MenuPopover from "common/components/responsiveMenu/components/menuPopover";
import { MenuWrapper } from "common/components/responsiveMenu/components/menu";
import MenuItem from "app/modules/navigationPanel/components/moimConfigMenu/component/menuItem";
import {
  SignInButton,
  ButtonMenuContainer,
  MenuText,
  MyShoppingIcon,
} from "./styled";
import { useIntlShort } from "common/hooks/useIntlShort";

interface IProps extends React.ComponentProps<typeof MenuPopover> {
  onClickMenuButton: () => void;
}

function GuestProfileMenuPure({ onClickMenuButton, ...props }: IProps) {
  const isMobile = useIsMobile();
  const intl = useIntlShort();
  const handleSignIn = useHandleSignIn();

  const handleSignInClick = React.useCallback(() => {
    onClickMenuButton();
    handleSignIn();
  }, [handleSignIn, onClickMenuButton]);

  const handleClose = React.useCallback(() => {
    props.onCloseRequest();
  }, [props]);

  const inner = React.useMemo(
    () => (
      <>
        <MenuWrapper>
          <MenuItem
            icon={<MyShoppingIcon />}
            smallIcon={<MyShoppingIcon />}
            onClickButton={onClickMenuButton}
          >
            <Link
              to={MoimURL.CommerceGuestPaymentSearch.toString()}
              onClick={onClickMenuButton}
            >
              <MenuText>{intl("button_view_guest_checkout_order")}</MenuText>
            </Link>
          </MenuItem>
          <ButtonMenuContainer>
            <SignInButton size="s" onClick={handleSignInClick}>
              {intl("button_login")}
            </SignInButton>
          </ButtonMenuContainer>
        </MenuWrapper>
      </>
    ),
    [handleSignInClick, intl, onClickMenuButton],
  );

  if (isMobile) {
    return (
      <BottomSheet open={props.open} onDismiss={handleClose}>
        {inner}
      </BottomSheet>
    );
  }

  return <MenuPopover {...props}>{inner}</MenuPopover>;
}

const GuestProfileMenu: React.FC<IProps> = props => {
  if (!props.open) return null;

  return React.createElement(GuestProfileMenuPure, props);
};

export default GuestProfileMenu;
