import * as React from "react";
import ProfileStacker from "common/components/userProfileStacker";
import { Wrapper, Text } from "./styled";
import useGroupTexts from "common/hooks/useGroupTexts";

interface IProps {
  buyerCount: number;
  teamBuyingId?: Moim.Id;
  block: Moim.Component.ProductItem.ITotalBuyerCount;
  className?: string;
}

const isTBD = true;

const TotalBuyerCount: React.FC<IProps> = ({
  buyerCount,
  block,
  className,
}) => {
  const texts = useGroupTexts("total_buyer_count_title", {
    buyer_count: buyerCount,
  });

  if (buyerCount <= 10) {
    return null;
  }

  return (
    <Wrapper color={block.color} className={className}>
      {isTBD ? null : (
        <ProfileStacker
          size="xs"
          sources={[
            "https://ca.edge.vg/i/G0CUWIRCRG-UO3N6I5JA-1234--512",
            undefined,
            "https://community-files.moim.mobi/FILE-32IDU1MT/img_1749__1_.jpeg",
            undefined,
          ]}
        />
      )}
      <Text>{texts?.singular}</Text>
    </Wrapper>
  );
};

export default React.memo(TotalBuyerCount);
