import * as History from "history";
import produce from "immer";
import { AppTypes, AuthTypes, GroupTypes } from "../actions/types";
import { AllActions } from "../actions";
import {
  CURRENT_USER_KEY,
  LOCAL_CURRENCY,
  USER_LOCALE,
} from "common/constants/keys";
import { isBrowser } from "common/helpers/envChecker";
import { getInitialData } from "common/helpers/initialData";
import { MoimURL } from "app/common/helpers/url/index";

export type IAppGlobalState = Readonly<{
  currentGroupId: Moim.Id | null;
  currentHubGroupId: Moim.Id | null;
  currentUserId: Moim.Id | null | undefined;
  blockedUsers: Moim.Id[];
  parentMoimUser: Moim.User.IOriginalUserDatum | null;

  authenticationLoading: Partial<Record<Moim.AuthenticationProvider, boolean>>;
  authenticationError: Partial<
    Record<Moim.AuthenticationProvider, Moim.IErrorResponse>
  >;
  appConfig: Moim.Group.IAppConfig | undefined;
  loggingIn: boolean;
  textKeyVisible: boolean;
  locale: string | null;
  localCurrency: string | undefined;
  history: {
    currentLocationKey?: string;
    locations: (History.Location<Moim.IHistoryState> | undefined)[];
  };
  providers: Record<string, { web: boolean; android: boolean; ios: boolean }>;
  requestId: string;
}>;

const isAccessExternalEditor =
  MoimURL.ExternalMoimBlockitEditor.isSameExact(location.pathname) ||
  MoimURL.ExternalMoimBlockitEditorV2.isSameExact(location.pathname);

export const APP_INITIAL_STATE: (requestId?: string) => IAppGlobalState = (
  requestId?: string,
) => ({
  currentUserId: undefined,
  currentGroupId: isAccessExternalEditor
    ? new URLSearchParams(location.search).get("groupId")
    : getInitialData("__bootData.data.group.id", requestId ?? "") ??
      getInitialData("currentGroupId", requestId ?? "") ??
      null,
  currentHubGroupId: isAccessExternalEditor
    ? new URLSearchParams(location.search).get("hubGroupId")
    : getInitialData("__bootData.data.group", requestId ?? "")
    ? getInitialData("__bootData.data.group.is_hub", requestId ?? "")
      ? getInitialData("__bootData.data.group.id", requestId ?? "")
      : getInitialData("__bootData.data.group.parent", requestId ?? "")
    : getInitialData("currentHubGroupId", requestId ?? "") ?? null,
  blockedUsers: [],
  parentMoimUser: null,
  authenticationLoading: {},
  authenticationError: {},
  appConfig: undefined,
  loggingIn: false,
  textKeyVisible: false,
  locale: getInitialData("browserLocale", requestId ?? "") ?? null,
  localCurrency: undefined,
  providers: {
    kakao: { web: false, android: false, ios: false },
  },
  history: {
    currentLocationKey: undefined,
    locations: [],
  },
  requestId: requestId ?? "",
});

export const reducer = (state = APP_INITIAL_STATE(), action: AllActions) =>
  produce(state, draft => {
    switch (action.type) {
      case AppTypes.INITIALIZE_HISTORY: {
        draft.history.locations = Array.from({ length: action.payload.size });
        break;
      }

      case AppTypes.PUSH_HISTORY: {
        const locationIndex = Number(
          (action.payload.location.state as any).index,
        );
        (draft.history.locations[
          locationIndex
        ] as any) = action.payload.location;
        draft.history.currentLocationKey = action.payload.location.key;
        break;
      }

      case AppTypes.CHANGE_TEXT_KEY_VISIBLE: {
        draft.textKeyVisible = action.payload.textKeyVisible;
        break;
      }

      case AppTypes.CHANGE_LOCALE: {
        draft.locale = action.payload.locale;
        localStorage.setItem(USER_LOCALE, action.payload.locale);
        break;
      }

      case AppTypes.CHANGE_LOCAL_CURRENCY: {
        const localCurrency = action.payload.currency;
        draft.localCurrency = localCurrency;
        if (localCurrency) {
          localStorage.setItem(LOCAL_CURRENCY, localCurrency);
        } else {
          localStorage.removeItem(LOCAL_CURRENCY);
        }
        break;
      }

      case AppTypes.CURRENT_GROUP_CHANGED: {
        draft.currentGroupId = action.payload.group;
        draft.currentHubGroupId = action.payload.parent
          ? action.payload.parent
          : null;
        break;
      }

      case AuthTypes.CURRENT_USER_CHANGED: {
        const userId = action.payload.user;
        draft.loggingIn = false;
        draft.currentUserId = userId;
        draft.blockedUsers = [];

        if (isBrowser()) {
          if (userId) {
            localStorage.setItem(CURRENT_USER_KEY, userId);
          } else {
            localStorage.removeItem(CURRENT_USER_KEY);
          }
        }

        break;
      }

      case AuthTypes.PARENT_MOIM_USER_CHANGED: {
        draft.loggingIn = false;
        draft.parentMoimUser = action.payload.user;
        break;
      }

      case AppTypes.PARENT_GROUP_CHANGED: {
        draft.currentHubGroupId = action.payload.group ?? null;
        break;
      }

      case AuthTypes.START_GET_AUTHENTICATION: {
        draft.authenticationLoading[action.payload.provider] = true;
        draft.authenticationError[action.payload.provider] = undefined;
        break;
      }

      case AuthTypes.FAILED_GET_AUTHENTICATION: {
        draft.authenticationLoading[action.payload.provider] = false;
        draft.authenticationError[action.payload.provider] =
          action.payload.error;
        break;
      }

      case AuthTypes.SUCCEED_GET_AUTHENTICATION: {
        draft.authenticationLoading.cryptobadge = false;
        break;
      }

      case AuthTypes.START_LOGGING_IN: {
        draft.loggingIn = true;
        break;
      }

      case AuthTypes.END_LOGGING_IN: {
        draft.loggingIn = false;
        break;
      }

      case AuthTypes.SIGN_OUT: {
        draft.currentUserId = null;
        draft.loggingIn = false;
        draft.authenticationLoading = {};
        draft.authenticationError = {};
        draft.parentMoimUser = null;
        draft.blockedUsers = [];
        break;
      }

      case AppTypes.SET_PROVIDERS: {
        Object.entries(action.payload.providers).forEach(([key, value]) => {
          draft.providers[key] = value;
        });
        break;
      }

      case GroupTypes.SUCCESS_BOOT_STRAP: {
        const { group, bannedUsers } = action.payload;

        if (bannedUsers) {
          draft.blockedUsers = bannedUsers;
        }

        if (group) {
          draft.currentGroupId = group.id;
          draft.currentHubGroupId =
            group.parent ?? (group.is_hub ? group.id : null);
        }
        break;
      }

      case AppTypes.SUCCEED_USER_BLOCK: {
        const { userId } = action.payload;

        draft.blockedUsers.push(userId);
        break;
      }

      case AppTypes.SUCCEED_USER_UNBLOCK: {
        const { userId } = action.payload;

        draft.blockedUsers = draft.blockedUsers.filter(id => id !== userId);
        break;
      }

      case AppTypes.SET_APP_CONFIG: {
        draft.appConfig = action.payload.appConfig;
        break;
      }
    }
  });
