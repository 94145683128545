import ThumbnailCarousel from "app/common/components/thread/commonThreadItem/components/common/thumbnailCarousel";
import styled from "styled-components";
import React from "react";
import { FallbackImage } from "common/components/fallbackImage";
import { convertMediaBlocksToCarouselBlock } from "common/components/thread/commonThreadItem/components/common/thumbnailCarousel/helper";
import ThumbnailGrid, {
  THUMBNAIL_GRID_IMAGE_LIMIT,
} from "common/components/thread/commonThreadItem/components/common/thumbnailGrid";
import ThumbnailLock from "common/components/thread/commonThreadItem/components/common/thumbnailLock";
import { ThreadThumbnailWrapper } from "common/components/thread/commonThreadItem/components/common/wrapper/thumbnail";
import { px2rem } from "common/helpers/rem";
import useIsMobile from "common/hooks/useIsMobile";

const MediaWrapper = styled.div<{ isMobile?: boolean }>`
  width: 100%;
  padding-inline: ${props => (!props.isMobile ? px2rem(16) : 0)};
`;

const StyledImage = styled.img.attrs({ loading: "lazy", alt: "" })`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const CarouselWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const FallbackImageWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  img.logo {
    height: initial !important;
  }
`;

const DEFAULT_RATIO = "1:1";
const EXCEPTIONAL_RATIO = "6:5";
const DEFAULT_RADIUS = 0;

interface IProps {
  preview?: Moim.Forum.IThreadPreview;
  mediaBlocks?: Moim.Blockit.V2.IImageBlock[];
  mediaBlocksCount?: number;
  thumbnailConfig?: Moim.Blockit.V2.IPostListShowConfig["thumbnailConfig"];
  mediaDisplayType?: Moim.Blockit.V2.IPostListShowConfig["mediaDisplayType"];
  showContent?: boolean;
}

const Media: React.FC<IProps> = ({
  mediaBlocks,
  mediaBlocksCount,
  preview,
  mediaDisplayType = "carousel",
  showContent,
}) => {
  const isMobile = useIsMobile();

  const carouselBlock = convertMediaBlocksToCarouselBlock(mediaBlocks ?? []);

  const thumbnailElement = React.useMemo(() => {
    if (!showContent) {
      return (
        <ThumbnailLock
          mediaBlocksCount={mediaBlocksCount ?? 0}
          {...carouselBlock}
        />
      );
    }

    switch (mediaDisplayType) {
      case "grid":
        return (
          <ThumbnailGrid
            mediaBlocksCount={mediaBlocksCount ?? 0}
            {...carouselBlock}
          />
        );
      case "carousel":
      default:
        return (
          <ThumbnailCarousel
            mediaBlocksCount={mediaBlocksCount ?? 0}
            {...carouselBlock}
          />
        );
    }
  }, [carouselBlock, mediaBlocksCount, mediaDisplayType, showContent]);

  return (
    <MediaWrapper isMobile={isMobile}>
      <ThreadThumbnailWrapper
        ratio={
          mediaDisplayType === "grid" &&
          carouselBlock.images.length > THUMBNAIL_GRID_IMAGE_LIMIT - 1
            ? EXCEPTIONAL_RATIO
            : DEFAULT_RATIO
        }
        thumbnail={preview?.thumbnail}
        isVideo={preview?.isVideo}
        radius={DEFAULT_RADIUS}
      >
        {(src, srcSet) =>
          src || srcSet ? (
            carouselBlock.images.length > 0 ? (
              <CarouselWrapper>{thumbnailElement}</CarouselWrapper>
            ) : (
              // below case is needed when the thread has video resources only.
              <StyledImage src={src} srcSet={srcSet} />
            )
          ) : (
            <FallbackImageWrapper>
              <FallbackImage type="postItem" />
            </FallbackImageWrapper>
          )
        }
      </ThreadThumbnailWrapper>
    </MediaWrapper>
  );
};

export default React.memo(Media);
