import * as React from "react";
import * as qs from "query-string";
import { useLocation } from "react-router";
import { useStoreState, useActions } from "app/store";
import { getThreadShow } from "app/actions/forum";
import { useCancelTokenWithCancelHandler } from "common/hooks/useCancelToken";
import useRedirect from "common/hooks/useRedirect";
import useIsMobile from "common/hooks/useIsMobile";
import { MoimURL } from "common/helpers/url";

import EmptyThread from "../components/emptyThread";
import { DefaultLoader } from "common/components/loading";
import PostShow from "..";

export interface IPostShowModalQueryParams {
  groupId?: Moim.Id;
  postId?: Moim.Id;
}

export interface IProps {
  isModalShow: boolean;
  postId: string;
  onClose?(): void;
}

const ThreadShowComponent = ({ isModalShow, postId, onClose }: IProps) => {
  const isMobile = useIsMobile();
  const location = useLocation();
  const redirect = useRedirect();
  const queryParams = qs.parse(location.search, {
    arrayFormat: "bracket",
  }) as IPostShowModalQueryParams;
  const groupId = queryParams.groupId;

  const [isLoading, setLoadStatus] = React.useState(false);
  const [isLoaded, setLoadedStatus] = React.useState(false);
  const { cancelTokenSource, handleCancel } = useCancelTokenWithCancelHandler();

  const latestAppHistoryLocation = useStoreState(
    state =>
      state.app.history.locations[state.app.history.locations.length - 1],
  );

  const thread = useStoreState(state =>
    postId ? state.entities.threads[postId] : null,
  );

  const { fetchThread } = useActions({
    fetchThread: getThreadShow,
  });

  const handleClose = React.useCallback(() => {
    if (isMobile) {
      if (latestAppHistoryLocation) {
        history.back();
      } else {
        redirect(new MoimURL.MoimAppHome().toString());
      }
    } else {
      onClose?.();
    }
  }, [isMobile, latestAppHistoryLocation, onClose, redirect]);

  React.useEffect(() => {
    if (postId && !isLoading) {
      setLoadStatus(true);
      setLoadedStatus(false);
      fetchThread(
        postId,
        cancelTokenSource.current.token,
        undefined,
        groupId || undefined,
      ).finally(() => {
        setLoadStatus(false);
        setLoadedStatus(true);
      });
    }
    return () => {
      handleCancel();
      setLoadedStatus(false);
    };
  }, [postId]);

  if (!thread || !postId) {
    if (isLoaded) {
      return <EmptyThread />;
    }
    return <DefaultLoader />;
  }

  return (
    <PostShow post={thread} isModalShow={isModalShow} onBack={handleClose} />
  );
};

export default React.memo(ThreadShowComponent);
