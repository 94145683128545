/* eslint-disable no-underscore-dangle */
import { browserLocale, SupportedLanguageType } from "app/intl";
import { MoimAPI } from "common/api";
import { getInitialData, setInitialData } from "common/helpers/initialData";

async function loadBootData(requestId: string, api: MoimAPI) {
  const boot = await api.group.boot();

  setInitialData("__bootData", requestId, boot);
}

async function loadPermissions(requestId: string, api: MoimAPI) {
  if (api.groupId) {
    const permissions = await api.permission.getPermission({
      resource: api.groupId,
    });

    setInitialData(
      `__resourcePermissions.${api.groupId}`,
      requestId,
      permissions,
    );
  }
}

async function loadChannels(requestId: string, api: MoimAPI) {
  const channels = await api.channel.getChannels({ limit: 100 });

  setInitialData("__channelData", requestId, channels);
}

async function loadCategories(requestId: string, api: MoimAPI) {
  const boot = getInitialData("__bootData", requestId);

  if (boot?.group?.sellerId) {
    const categories = await api.commerce.getSellerCategories(
      boot?.group?.sellerId,
    );

    setInitialData("__categoryData", requestId, categories);
  }
}

async function loadGroupTexts(requestId: string, api: MoimAPI) {
  const response = await api.group.getGroupTexts();
  setInitialData("groupTexts", requestId, response.data.translations);
}

export async function InitialLoadData(requestId: string) {
  const currentGroupId = getInitialData("currentGroupId", requestId);
  const userLocale = getInitialData("browserLocale", requestId) as string;

  const group = getInitialData("__bootData", requestId);

  const moimLocales = group?.group?.config?.locales;
  const defaultLocale = group?.group?.config?.defaultLocale as
    | SupportedLanguageType
    | undefined;

  const targetLocale = browserLocale(userLocale, defaultLocale, moimLocales);

  const api = new MoimAPI(undefined, currentGroupId, undefined, targetLocale);

  await loadBootData(requestId, api);
  await Promise.all([
    loadGroupTexts(requestId, api),
    loadPermissions(requestId, api),
    loadChannels(requestId, api),
    loadCategories(requestId, api),
  ]);
}
