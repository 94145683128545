import * as React from "react";

import { GhostButton, FlatButton, TextButton, Wrapper } from "./styled";
import { WithCarouselLink } from "../carousel/item";
import { withPlacement } from "../../hoc/withPlacement";

const ButtonElement: React.FC<Moim.Blockit.V2.IButtonBlock> = ({
  subType,
  content,
  size,
  color,
  scale,
  style,
}) => {
  const shortSize = React.useMemo(() => {
    switch (size) {
      case "large":
        return "l";
      case "medium":
        return "m";
      case "custom":
        return "custom";
      case "small":
        return "s";
    }
  }, [size]);
  switch (subType) {
    case "flat":
      return (
        <FlatButton
          size={shortSize as any}
          color={color}
          scale={scale}
          customStyle={style}
        >
          {content}
        </FlatButton>
      );
    case "ghost":
      return (
        <GhostButton
          size={shortSize as any}
          color={color}
          scale={scale}
          customStyle={style}
        >
          {content}
        </GhostButton>
      );
    case "text":
    default:
      return (
        <TextButton
          size={shortSize as any}
          color={color}
          scale={scale}
          customStyle={style}
        >
          {content}
        </TextButton>
      );
  }
};

const Buttons: React.FC<Moim.Blockit.V2.IButtonBlock> = ({ ...block }) => {
  return (
    <Wrapper align={block.align ?? "center"}>
      <WithCarouselLink to={block.href}>
        <ButtonElement {...block} />
      </WithCarouselLink>
    </Wrapper>
  );
};

export default withPlacement(Buttons);
