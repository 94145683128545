import styled from "styled-components";

import { B4Regular } from "common/components/designSystem/typos";
import { LinkIcon } from "common/components/channelList/components/channelItem/styled";

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
`;

export const ExternalButton = styled.button`
  display: flex;
  align-items: center;
`;

export const ExternalLinkText = styled(B4Regular)`
  color: ${props => props.theme.colorV2.colorSet.grey200};
`;

export const ExternalLinkIcon = styled(LinkIcon).attrs(props => ({
  size: "xs",
  touch: 24,
  iconColor: props.theme.colorV2.colorSet.grey200,
}))``;
