import * as React from "react";
import styled from "styled-components";

import ShavedText from "common/components/shavedText/v2";
import { px2rem } from "common/helpers/rem";
import { B4Regular } from "common/components/designSystem/typos";
import FallbackImage from "common/components/fallbackImage";

interface IProps {
  productImage?: string;
  productName: string;
}

const Wrapper = styled.div`
  width: 100%;
  padding: ${px2rem(8)} ${px2rem(16)};
`;

const Box = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: ${px2rem(8)};
  background-color: ${props => props.theme.colorV2.colorSet.grey10};
  border-radius: ${px2rem(4)};
  padding: ${px2rem(10)} ${px2rem(16)};
`;

const Image = styled.img`
  width: ${px2rem(24)} !important;
  height: ${px2rem(24)} !important;
  object-fit: cover;
  border-radius: ${px2rem(2)};
`;

const FallbackImageWrapper = styled.div`
  width: ${px2rem(24)};
  height: ${px2rem(24)};
  border-radius: ${px2rem(2)};
  img.logo {
    height: initial !important;
  }
`;
const Name = styled(B4Regular)`
  color: ${props => props.theme.colorV2.colorSet.grey600};
`;

function Product({ productImage, productName }: IProps) {
  const [isError, setErrorStatus] = React.useState(false);
  const handleError = React.useCallback(() => {
    setErrorStatus(true);
  }, []);

  return (
    <Wrapper>
      <Box>
        {productImage && !isError ? (
          <Image src={productImage} alt="" onError={handleError} />
        ) : (
          <FallbackImageWrapper>
            <FallbackImage />
          </FallbackImageWrapper>
        )}
        <ShavedText line={1}>
          <Name>{productName}</Name>
        </ShavedText>
      </Box>
    </Wrapper>
  );
}

export default React.memo(Product);
