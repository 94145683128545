import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
// hooks
import useSearchUsers from "common/hooks/useSearchUsers";
// components
import { MemberSelection } from "common/components/designSystem/selection";

// styled
import {
  Section,
  SectionTitle,
  transferDialogMemberSelectionWrapperStyle,
} from "../styled";

interface IProps {
  isBlockchainCoin: boolean;
  userId?: Moim.Id;
  onChange(userId: Moim.Id): void;
}

const MemberSelectInput: React.FC<IProps> = ({
  userId,
  onChange,
  isBlockchainCoin,
}) => {
  const intl = useIntl();

  const {
    users,
    isLoading,
    handleSearchChange: onSearchChange,
  } = useSearchUsers();

  return (
    <Section>
      <SectionTitle>
        <FormattedMessage id="send_candy_dialog_select_member_title" />
      </SectionTitle>
      <MemberSelection
        overrideStyle={transferDialogMemberSelectionWrapperStyle}
        size="l"
        state="normal"
        isMultiple={false}
        selected={userId ?? null}
        options={users.data
          .filter(user => !isBlockchainCoin || Boolean(user.metamask))
          .map(item => ({
            value: item.id,
            label: item.name,
          }))}
        onChange={onChange}
        placeholder={intl.formatMessage({
          id: "send_candy_dialog_select_member_placeholder",
        })}
        useSearch={true}
        isSearchLoading={isLoading}
        onSearchChange={onSearchChange}
      />
    </Section>
  );
};

export default MemberSelectInput;
