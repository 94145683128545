import styled, { css, FlattenInterpolation } from "styled-components";
import { px2rem } from "app/common/helpers/rem";
import ArrowButton from "../arrowButton";
import { noScrollBarStyle, useXScrollStyle } from "common/components/designSystem/styles";

export const LABEL_ITEM_DESKTOP_HEIGHT = 62;
export const LABEL_ITEM_MOBILE_HEIGHT = 48;

const visibilityAnimStyle = css`
  transition-property: opacity;
  transition-duration: 200ms;
  transition-delay: 10ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
`;

export const LabelList = styled.div`
  flex: 1;
  min-width: 0;
  display: inline-flex;
  gap: ${px2rem(8)};
  padding-right: ${px2rem(38)};

  ${noScrollBarStyle};
  ${useXScrollStyle};
`;

export const LabelListGradient = styled.div<{
  disable: boolean;
  position: "left" | "right";
}>`
  pointer-events: none;
  position: absolute;
  top: 0;
  bottom: 0;
  width: ${px2rem(91)};
  ${visibilityAnimStyle};
  ${props => props.disable && `visibility: hidden; opacity: 0;`};
  ${props =>
    props.position === "right"
      ? css`
          right: 0;
          background-image: linear-gradient(
            to right,
            rgba(255, 255, 255, 0) 0%,
            ${props.theme.colorV2.colorSet.white1000} 65%
          );
        `
      : css`
          left: 0;
          background-image: linear-gradient(
            to left,
            rgba(255, 255, 255, 0) 0%,
            ${props.theme.colorV2.colorSet.white1000} 65%
          );
          z-index: ${props.theme.zIndexes.default};
        `};
`;

const PositionButtonStyle = css`
  position: absolute;
  top: ${px2rem(4)};
  bottom: 0;
  width: ${px2rem(24)};
  height: ${px2rem(24)};
  display: none;
  z-index: ${props => props.theme.zIndexes.default};

  ${visibilityAnimStyle};
`;

export const LeftArrowButton = styled(ArrowButton).attrs({
  arrowType: "left",
})`
  ${PositionButtonStyle};
  left: 0;
  ${props =>
    props.disabled &&
    css`
      visibility: hidden;
      opacity: 0;
    `};
`;

export const RightArrowButton = styled(ArrowButton).attrs({
  arrowType: "right",
})<{ visibleExpandButton: boolean }>`
  ${PositionButtonStyle};
  right: ${props => px2rem(props.visibleExpandButton ? 38 : 18)};
  ${props =>
    props.disabled &&
    css`
      visibility: hidden;
      opacity: 0;
    `};
`;

export const ExpandButtonWrapper = styled.button`
  position: absolute;
  right: 0;
  width: ${px2rem(30)};
  height: ${px2rem(30)};
  border-radius: 50%;
  z-index: ${props => props.theme.zIndexes.default};
  &::before,
  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: ${px2rem(30)};
    height: ${px2rem(30)};
    border-radius: 50%;
    z-index: ${props => props.theme.zIndexes.below};
  }

  &::before {
    background-color: ${props => props.theme.colorV2.colorSet.white1000};
  }
`;

export const Wrapper = styled.div<{
  overrideStyle?: FlattenInterpolation<any>;
}>`
  max-width: 100%;
  display: inline-flex;
  position: relative;
  align-items: center;

  &:hover {
    ${LeftArrowButton},
    ${RightArrowButton} {
      display: block;
    }
  }

  ${props => props.overrideStyle};
`;
