import { Svg18Share1, Svg24Share1 } from "@moim/icons";
import { useSingleLineStyle } from "common/components/designSystem/styles";
import { B3Regular, B4Regular } from "common/components/designSystem/typos";
import { px2rem } from "common/helpers/rem";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";

const SmallWrapper = styled(B4Regular)`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colorV2.colorSet.grey300};
`;

const LargeWrapper = styled(B3Regular)`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colorV2.colorSet.grey800};
`;

const SmallShareIcon = styled(Svg18Share1).attrs((props) => ({
  size: "xs",
  iconColor: "currentColor",
}))``;

const LargeShareIcon = styled(Svg24Share1).attrs((props) => ({
  size: "s",
  touch: 24,
  iconColor: "currentColor",
}))``;

const Label = styled.span`
  margin-left: ${px2rem(4)};
  ${useSingleLineStyle};
`;

export function SmallShare({ showLabel }: { showLabel?: boolean }) {
  return (
    <SmallWrapper>
      <SmallShareIcon />
      {showLabel && (
        <Label>
          <FormattedMessage id="menu_share_moim" />
        </Label>
      )}
    </SmallWrapper>
  );
}

export function LargeShare({ showLabel }: { showLabel?: boolean }) {
  return (
    <LargeWrapper>
      <LargeShareIcon />
      {showLabel && (
        <Label>
          <FormattedMessage id="menu_share_moim" />
        </Label>
      )}
    </LargeWrapper>
  );
}
