import * as React from "react";
import { Location } from "history";
import { Route, Switch } from "react-router";

// hooks
import { useProps, useHandlers, useEffects } from "./hooks";
// components
import StickyTopBanner from "common/components/promotion/stickyTopBanner";
import { MainWrapper, Container } from "./components/styled";
import FreezeView from "common/components/freezeView";
import TopBanner from "common/components/topBanner";
import SecondaryViewContainer from "../secondaryView";
import {
  NavigationPanelContext,
  useNavigationPanelContextInitialValues,
} from "../navigationPanel/context";
import LayoutController from "./components/controller";
import ThreadShowModalContainer from "app/modules/postShow/modal";
import DQuestShowModalContainer from "app/modules/dquest/containers/show/modal";
import {
  Wrapper as InnerWrapper,
  MainWrapper as InnerMainWrapper,
} from "./components/controller/styled";
import FooterBlockV2 from "common/components/footer/footerBlockV2";
import MainPanelRefreshContext from "common/hooks/mainPanelRefresh/provider";
import { isHubDomain } from "common/helpers/envChecker";
import { IRouteConfig } from "app/routes/client";
import useIsMobile from "common/hooks/useIsMobile";

export const NO_GNB_TOP_NAV_PORTAL_ID = "no_nav_gnb_portal";

export type IProps = React.PropsWithChildren<{}>;

const AppDownloadPromoteTopBanner: React.FC = () => {
  const [isScrollVisible, setScrollVisible] = React.useState(true);
  const handleScroll = React.useCallback(e => {
    const scrollElement = e.target.scrollingElement;
    if (scrollElement) {
      setScrollVisible(scrollElement.scrollTop === 0);
    }
  }, []);

  React.useLayoutEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  if (!isScrollVisible) {
    return null;
  }
  return <StickyTopBanner />;
};

function Layout(props: IProps) {
  const hookProps = useProps(props);
  const hookHandlers = useHandlers(hookProps);
  useEffects(hookProps, hookHandlers);

  const {
    isExpandSideNavigation,
    nativeSecondaryViewOpenStatus,
    pluginSecondaryViewOpenStatus,
    isMobile,
    children,
    mainPanelKey,
  } = hookProps;
  const { doRefresh } = hookHandlers;
  const navigationPanelContextValue = useNavigationPanelContextInitialValues();

  return (
    <MainPanelRefreshContext.Provider
      value={{
        key: mainPanelKey,
        doRefresh,
      }}
    >
      <NavigationPanelContext.Provider value={navigationPanelContextValue}>
        <FreezeView
          isFreeze={
            isMobile &&
            (isExpandSideNavigation ||
              nativeSecondaryViewOpenStatus ||
              pluginSecondaryViewOpenStatus)
          }
        >
          <Container>
            {isMobile && <AppDownloadPromoteTopBanner />}
            <TopBanner />
            <LayoutController>
              <MainWrapper key={mainPanelKey}>
                {children}
                <FooterBlockV2 />
              </MainWrapper>
              <SecondaryViewContainer />
            </LayoutController>
            <ThreadShowModalContainer />
            <DQuestShowModalContainer />
          </Container>
        </FreezeView>
      </NavigationPanelContext.Provider>
    </MainPanelRefreshContext.Provider>
  );
}

function NoGNBLayout(props: IProps) {
  const hookProps = useProps(props);
  const hookHandlers = useHandlers(hookProps);
  useEffects(hookProps, hookHandlers);

  const {
    isExpandSideNavigation,
    nativeSecondaryViewOpenStatus,
    pluginSecondaryViewOpenStatus,
    isMobile,
    children,
  } = hookProps;
  const navigationPanelContextValue = useNavigationPanelContextInitialValues();

  return (
    <NavigationPanelContext.Provider value={navigationPanelContextValue}>
      <FreezeView
        isFreeze={
          isMobile &&
          (isExpandSideNavigation ||
            nativeSecondaryViewOpenStatus ||
            pluginSecondaryViewOpenStatus)
        }
      >
        <Container>
          {isMobile && <AppDownloadPromoteTopBanner />}
          <InnerWrapper>
            <div id={NO_GNB_TOP_NAV_PORTAL_ID} />
            <InnerMainWrapper>
              <MainWrapper>{children}</MainWrapper>
              <SecondaryViewContainer />
            </InnerMainWrapper>
          </InnerWrapper>
        </Container>
      </FreezeView>
    </NavigationPanelContext.Provider>
  );
}

export default Layout;

export { NoGNBLayout };

export const LayoutRoute = ({
  routes,
  location,
  children,
}: React.PropsWithChildren<{
  routes: IRouteConfig[];
  location?: Location;
}>) => {
  const isMobile = useIsMobile();
  const orderedRoutesPath = React.useMemo(() => {
    const defaultPaths: string[] = [];
    const noGnbPaths: string[] = [];
    const fullScreenPaths: string[] = [];

    routes.forEach(route => {
      const path =
        route.def &&
        (Array.isArray(route.def)
          ? route.def.map(def => def.pattern)
          : route.def.pattern);

      if (path) {
        const layoutType =
          (isMobile ? route.mobileLayoutType : route.layoutType) ??
          route.layoutType ??
          "base";

        switch (layoutType) {
          case "no-gnb": {
            Array.isArray(path)
              ? noGnbPaths.push(...path)
              : noGnbPaths.push(path);
            break;
          }
          case "full-screen": {
            Array.isArray(path)
              ? fullScreenPaths.push(...path)
              : fullScreenPaths.push(path);
            break;
          }
          default:
          case "base": {
            Array.isArray(path)
              ? defaultPaths.push(...path)
              : defaultPaths.push(path);
            break;
          }
        }
      }
    });

    return {
      defaultPaths,
      noGnbPaths,
      fullScreenPaths,
    };
  }, [isMobile, routes]);

  if (!isHubDomain()) {
    return (
      <Switch location={location}>
        <Route
          path={orderedRoutesPath.noGnbPaths}
          exact={true}
          render={() => <NoGNBLayout>{children}</NoGNBLayout>}
        />
        <Route
          path={orderedRoutesPath.fullScreenPaths}
          exact={true}
          render={() => <>{children}</>}
        />
        <Route
          path={orderedRoutesPath.defaultPaths}
          exact={true}
          render={() => <Layout>{children}</Layout>}
        />
      </Switch>
    );
  }

  return <>{children}</>;
};
