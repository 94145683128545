import styled from "styled-components";
import { px2rem } from "common/helpers/rem";
import { MEDIA_QUERY } from "common/constants/responsive";
import { H8Bold } from "common/components/designSystem/typos";
import { TopNaviBlockPropertyStyle } from "../section/styled";

export const Container = styled.div<{
  element?: Partial<Moim.Layout.Navigation.CurrentGroupProfileElementType>;
}>`
  display: flex;
  align-items: center;
  min-width: 0;
  min-height: 0;

  ${TopNaviBlockPropertyStyle}
`;

export const LogoImageWrapper = styled.div`
  width: fit-content;
  padding: 0 ${px2rem(16)};
  display: flex;
  align-items: center;

  @media ${MEDIA_QUERY.ONLY_MOBILE} {
    padding: 0;
  }
`;
export const LogoImage = styled.img.attrs({ alt: "" })`
  max-width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const MoimNameWrapper = styled.div<{ visibleMoimName: boolean }>`
  display: flex;
  align-items: center;
  padding: 0 ${px2rem(16)};
  width: fit-content;

  @media ${MEDIA_QUERY.ONLY_MOBILE} {
    padding: 0;
  }
`;
export const MoimName = styled(H8Bold)<{
  textColor?: Moim.Blockit.V2.ColorValue;
}>`
  flex: 1;
  min-width: 0;
  color: ${props => {
    if (props.textColor) {
      return props.theme.getBlockitColorValue(props.textColor, "grey800");
    }

    return props.theme.colorV2.colorSet.fog800;
  }};
  margin-left: ${px2rem(12)};
`;
