import React, { memo, useCallback, useState } from "react";
import CollectionItemBanner from "./components/banner";
import Description from "./components/description";
import Schedules from "./components/schedules";
import Statistics from "./components/statistics";
import Skeleton from "./skeleton";
import InViewTrigger from "../../../components/inViewTrigger";
import { Spacer } from "common/components/designSystem/spacer";
import MoreButton from "../../../components/moreButton";
import {
  Inner,
  NftCollectionItemWrapper,
  NftCollectionItemContainer,
  CollectionName,
} from "./styled";

import { withPlacement } from "../../../hoc/withPlacement";
import { useIntlShort } from "common/hooks/useIntlShort";
import { useActions, useStoreState } from "app/store";
import { useRedirectToMoimURL } from "common/hooks/useSecondaryView";
import {
  getSchedulesByContract as getSchedulesByContractAction,
  getContractStatistics as getContractStatisticsAction,
  getContractDetail,
} from "app/actions/nft";

import { MoimURL } from "common/helpers/url";

const NFTCollectionPreviewBlock: React.FC<Moim.Blockit.V2.WEB3.INFTCollectionPreviewBlock> = ({
  resourceId,
  showConfig,
  moreButton,
}) => {
  const intl = useIntlShort();
  const [isLoading, setLoadingStatus] = useState<boolean | null | undefined>(
    undefined,
  );
  const [
    availableScheduleIdsByContract,
    setAvailableScheduleIdsByContract,
  ] = useState<string[] | undefined>(undefined);
  const [statistics, setStatistics] = useState<
    Moim.NFT.IContractStatistics | undefined
  >(undefined);

  const redirect = useRedirectToMoimURL();

  const {
    getSchedulesByContract,
    getContractStatistics,
    getContract,
  } = useActions({
    getContract: getContractDetail,
    getSchedulesByContract: getSchedulesByContractAction,
    getContractStatistics: getContractStatisticsAction,
  });

  const contract = useStoreState(
    state => state.entities.nftContracts[resourceId],
  );
  const schedules = useStoreState(state =>
    availableScheduleIdsByContract?.map(
      iid => state.entities.nftSchedules[iid],
    ),
  );

  const handleNftCollectionShowPage = useCallback(() => {
    redirect(
      new MoimURL.NftCollectionShow({
        contractId: resourceId,
      }).toString(),
    );
  }, [redirect, resourceId]);

  const handleGetData = useCallback(async () => {
    if (!resourceId) {
      return;
    }
    try {
      getContract(resourceId);

      if (!availableScheduleIdsByContract) {
        getSchedulesByContract(resourceId, undefined, "DESC").then(result =>
          setAvailableScheduleIdsByContract(result.availableScheduleIds),
        );
      }
      if (!statistics) {
        getContractStatistics(resourceId).then(result => setStatistics(result));
      }
      setLoadingStatus(false);
    } catch {
      setLoadingStatus(null);
    }
  }, [
    getContract,
    resourceId,
    availableScheduleIdsByContract,
    statistics,
    getSchedulesByContract,
    getContractStatistics,
  ]);

  const handleOnView = useCallback(() => {
    if (isLoading === undefined) {
      handleGetData();
    }
  }, [handleGetData, isLoading]);

  return (
    <>
      <InViewTrigger onVisible={handleOnView} />
      {isLoading !== false || !contract ? (
        <Skeleton />
      ) : (
        <Inner>
          <NftCollectionItemWrapper
            role="button"
            onClick={handleNftCollectionShowPage}
          >
            <CollectionItemBanner
              bannerUrl={contract.banner?.url}
              representImgUrl={contract.representResources?.[0]?.previewUrl}
            />
            <NftCollectionItemContainer>
              <CollectionName>{contract.name ?? ""}</CollectionName>
              <Statistics
                ownerCount={statistics?.ownerCount ?? 0}
                itemCount={
                  contract.imported
                    ? contract.totalItemCount
                    : (contract.itemType === "GENERATIVE"
                        ? statistics?.mintedItemCount
                        : statistics?.itemCount) ?? 0
                }
              />
              {contract.description?.length && (
                <Description value={contract.description} />
              )}
              {!contract.imported && schedules && showConfig.showSchedules && (
                <Schedules schedules={schedules} />
              )}
            </NftCollectionItemContainer>
          </NftCollectionItemWrapper>
        </Inner>
      )}
      {moreButton?.show ? (
        <>
          <Spacer value={24} />
          <MoreButton
            text={intl("button_see_more_nft_group")}
            onClick={handleNftCollectionShowPage}
          />
        </>
      ) : null}
    </>
  );
};

export default withPlacement(memo(NFTCollectionPreviewBlock));
