import * as React from "react";
import styled from "styled-components";
import { ThreadRenderer } from "common/components/thread";

import { px2rem } from "common/helpers/rem";
import { Spacer } from "common/components/designSystem/spacer";
import useRedirect from "common/hooks/useRedirect";
import useCurrentGroup from "common/hooks/useCurrentGroup";
import { MoimURL } from "common/helpers/url";
import { forumConfig2ItemConfig } from "common/components/thread/helper";
import { Thread } from "app/typings";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledThreadRenderer = styled(ThreadRenderer)`
  margin: ${px2rem(8)} 0;
  padding: ${px2rem(8)} ${px2rem(16)};
`;

interface IProps {
  thread: Moim.Forum.IThread;
}

export default function PostItem({ thread }: IProps) {
  const redirect = useRedirect();
  const currentGroup = useCurrentGroup();

  const isSameMoim = React.useMemo(() => currentGroup?.id === thread.groupId, [
    thread.groupId,
    currentGroup,
  ]);

  const handleClickWrapper = React.useCallback(() => {
    if (isSameMoim) {
      redirect(
        new MoimURL.ShowForumThread({
          forumId: thread.parent_id,
          threadId: thread.id,
        }).toString(),
      );
    }
  }, [isSameMoim, redirect, thread?.parent_id, thread?.id]);

  const config: Partial<Thread.IThreadItemConfig> = React.useMemo(() => {
    return {
      ...forumConfig2ItemConfig(),
      showAuthor: true,
      showDate: true,
      showThumbnail: true,
      showTitle: true,
      showText: true,
      textAlignment: "LEFT",
      thumbnailConfig: {
        type: "ratio",
        value: "5:3",
        position: "left",
      },
      viewType: "post",
      viewType_web: "post",
    };
  }, []);

  const inner = React.useMemo(
    () => (
      <>
        <Spacer value={8} />
        <StyledThreadRenderer threadId={thread.id} config={config} />
      </>
    ),
    [thread, config],
  );
  return <Wrapper onClick={handleClickWrapper}>{inner}</Wrapper>;
}
