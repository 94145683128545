import { AllActions } from "app/actions";
import { CommerceTypes, EntityTypes } from "app/actions/types";
import { VoteStatus } from "app/enums";
import { browserLocale } from "app/intl";
import {
  commerceCategoryListNormalizer,
  deliveryGroupListNormalizer,
  productNormalizer,
  productSetListNormalizer,
  sellerListNormalizer,
} from "app/models/commerce";
import { getInitialData } from "common/helpers/initialData";
import { getLocaleTextShort } from "common/hooks/localization/useLocaleText";
import produce from "immer";
import { isEqual } from "lodash";

export const INITIAL_STATE_SELLER: (
  requestId?: string,
) => Record<Moim.Id, Moim.Commerce.ISeller> = (requestId?: string) => {
  const draft = {};

  const sellerData = getInitialData("__sellerData", requestId ?? "");

  if (sellerData) {
    draft[sellerData.id] = {
      ...sellerData,
      cachedAt: -1,
    };
  }

  const sellerEntities = getInitialData("__entities.sellers", requestId ?? "");

  if (sellerEntities) {
    const normalized = sellerListNormalizer(sellerEntities);
    Object.assign(draft, normalized.entities.commerce_seller);
  }

  return draft;
};

export const INITIAL_STATE_BRAND: Record<Moim.Id, Moim.Commerce.IBrand> = {};

export const INITIAL_STATE_PRODUCT: (
  requestId?: string,
) => Record<Moim.Id, Moim.Commerce.IProduct> = (requestId?: string) => {
  const draft = {};
  const initialData = getInitialData("__entities.product", requestId ?? "");

  if (initialData) {
    const normalized = productNormalizer(initialData);
    const locale = browserLocale();

    if (normalized.entities.commerce_product) {
      Object.entries(normalized.entities.commerce_product).forEach(
        ([key, value]) => {
          draft[key] = {
            ...value,
            productVariants: value.productVariants
              ? value.productVariants
              : draft[key]?.productVariants,

            name: getLocaleTextShort(value, "name", locale)!,
            description: getLocaleTextShort(value, "description", locale),

            details: value.details?.map(detail => ({
              ...detail,
              key: getLocaleTextShort(detail, "key", locale)!,
              value: getLocaleTextShort(detail, "value", locale)!,
            })),
            primaryDetails: value.primaryDetails?.map(detail => ({
              ...detail,
              key: getLocaleTextShort(detail, "key", locale)!,
              value: getLocaleTextShort(detail, "value", locale)!,
            })),
            returnReplacementPolicy: getLocaleTextShort(
              value,
              "returnReplacementPolicy",
              locale,
            ),
          };
        },
      );
    }
  }

  return draft;
};

export const INITIAL_STATE_PRODUCT_SET: (
  requestId?: string,
) => Record<Moim.Id, Moim.Commerce.IProductSet> = (requestId?: string) => {
  const draft = {};
  const sets = getInitialData("__entities.productSets", requestId ?? "");
  if (sets) {
    const result = productSetListNormalizer(sets);
    Object.assign(draft, result.entities.commerce_productSet);
  }

  return draft;
};
export const INITIAL_STATE_CATEGORY: (
  requestId?: string,
) => Record<Moim.Id, Moim.Commerce.ICategory> = (requestId?: string) => {
  const draft = {};
  const categories = getInitialData("__categoryData", requestId ?? "");

  if (categories) {
    const result = commerceCategoryListNormalizer(categories).entities;
    Object.assign(draft, result.commerce_category);
  }

  const categoryEntities = getInitialData(
    "__entities.categories",
    requestId ?? "",
  );
  if (categoryEntities) {
    const result = commerceCategoryListNormalizer(categoryEntities).entities;
    Object.assign(draft, result.commerce_category);
  }

  return draft;
};
export const INITIAL_STATE_CART: Record<
  Moim.Id,
  Moim.Commerce.ICartResponse
> = {};

export const INITIAL_STATE_PURCHASE_ITEM: Record<
  Moim.Id,
  Moim.Commerce.IDenormalizedPurchaseItem
> = {};

export const INITIAL_STATE_PAYMENT: Record<
  Moim.Id,
  Moim.Commerce.IPayment
> = {};

export const INITIAL_STATE_PURCHASE: Record<
  Moim.Id,
  Moim.Commerce.IPurchase
> = {};

export const INITIAL_STATE_VARIANT: (
  requestId?: string,
) => Record<Moim.Id, Moim.Commerce.IProductVariant> = (requestId?: string) => {
  const draft = {};
  const initialData = getInitialData("__entities.product", requestId ?? "");

  if (initialData) {
    const normalized = productNormalizer(initialData);

    if (normalized.entities.commerce_variants) {
      Object.assign(draft, normalized.entities.commerce_variants);
    }
  }

  return draft;
};

export const INITIAL_STATE_SHIPPING_ADDRESS: Record<
  Moim.Id,
  Moim.Commerce.ICommerceShippingAddress
> = {};

export const INITIAL_STATE_DELIVERY_GROUP: (
  requestId?: string,
) => Record<Moim.Id, Moim.Commerce.IDeliveryGroup> = (requestId?: string) => {
  const draft = {};
  const groups = getInitialData("__entities.deliveryGroups", requestId ?? "");
  if (groups) {
    const result = deliveryGroupListNormalizer(groups);

    Object.assign(draft, result.entities.commerce_delivery_group);
  }

  return draft;
};

export const INITIAL_STATE_COUPON: Record<Moim.Id, Moim.Commerce.ICoupons> = {};

export const INITIAL_STATE_TEAM_BUYING: Record<
  Moim.Id,
  Moim.Commerce.ITeamBuying
> = {};

export function sellerReducer(
  state = INITIAL_STATE_SELLER(),
  action: AllActions,
) {
  return produce(state, draft => {
    switch (action.type) {
      case EntityTypes.ADD_ENTITY: {
        if (action.payload.commerce_seller) {
          const locale = browserLocale(action.payload.locale);
          Object.entries(action.payload.commerce_seller).forEach(
            ([key, value]) => {
              if (!value?.deliveryPolicy) {
                draft[key] = { ...value };
                return;
              }
              draft[key] = {
                ...value,
                deliveryPolicy: {
                  ...value.deliveryPolicy,
                  buttonText: getLocaleTextShort(
                    value.deliveryPolicy,
                    "buttonText",
                    locale,
                  )!,
                  title: getLocaleTextShort(
                    value.deliveryPolicy,
                    "title",
                    locale,
                  )!,
                  description: getLocaleTextShort(
                    value.deliveryPolicy,
                    "description",
                    locale,
                  )!,
                },
              };
            },
          );
          return draft;
        }
        break;
      }
    }
  });
}

export function brandReducer(state = INITIAL_STATE_BRAND, action: AllActions) {
  return produce(state, draft => {
    switch (action.type) {
      case EntityTypes.ADD_ENTITY: {
        if (action.payload.commerce_brand) {
          Object.entries(action.payload.commerce_brand).forEach(
            ([key, value]) => {
              draft[key] = { ...value, view: value.view ?? draft[key]?.view };
            },
          );
          return draft;
        }
        break;
      }
    }
  });
}

export function productReducer(
  state = INITIAL_STATE_PRODUCT(),
  action: AllActions,
) {
  return produce(state, draft => {
    switch (action.type) {
      case EntityTypes.ADD_ENTITY: {
        if (action.payload.commerce_product) {
          const locale = browserLocale(action.payload.locale);
          Object.entries(action.payload.commerce_product).forEach(
            ([key, value]) => {
              draft[key] = {
                ...value,
                productVariants: value.productVariants
                  ? value.productVariants
                  : draft[key]?.productVariants,

                name: getLocaleTextShort(value, "name", locale)!,
                description: getLocaleTextShort(value, "description", locale),

                details: value.details?.map(detail => ({
                  ...detail,
                  key: getLocaleTextShort(detail, "key", locale)!,
                  value: getLocaleTextShort(detail, "value", locale)!,
                })),
                primaryDetails: value.primaryDetails?.map(detail => ({
                  ...detail,
                  key: getLocaleTextShort(detail, "key", locale)!,
                  value: getLocaleTextShort(detail, "value", locale)!,
                })),
                backOrderDescription: getLocaleTextShort(
                  value,
                  "backOrderDescription",
                  locale,
                ),
                returnReplacementPolicy: getLocaleTextShort(
                  value,
                  "returnReplacementPolicy",
                  locale,
                ),
                writtenOpenReview:
                  value.writtenOpenReview ?? draft[key]?.writtenOpenReview,
              };
            },
          );
          return draft;
        }
        break;
      }

      case CommerceTypes.SUCCEED_CREATE_PRODUCT_REVIEW: {
        if (draft[action.payload.productId]) {
          draft[action.payload.productId].reviewsCount++;
        }
        break;
      }

      case CommerceTypes.SUCCEED_DELETE_PRODUCT_REVIEW: {
        if (draft[action.payload.productId]) {
          draft[action.payload.productId].reviewsCount--;
        }
        break;
      }

      case CommerceTypes.SUCCEED_CREATE_PRODUCT_QUESTION: {
        if (draft[action.payload.productId]) {
          draft[action.payload.productId].repliesCount++;
        }

        break;
      }

      case CommerceTypes.SUCCEED_DELETE_PRODUCT_QUESTION: {
        if (draft[action.payload.productId]) {
          draft[action.payload.productId].repliesCount--;
        }
        break;
      }

      case CommerceTypes.SUCCEED_CREATE_FUND_COMMENT: {
        if (draft[action.payload.productId]) {
          draft[action.payload.productId].commentsCount =
            (draft[action.payload.productId].commentsCount ?? 0) + 1;
        }

        break;
      }

      case CommerceTypes.SUCCEED_DELETE_FUND_COMMENT: {
        if (draft[action.payload.productId]) {
          draft[action.payload.productId].commentsCount =
            (draft[action.payload.productId].commentsCount ?? 0) - 1;
        }
        break;
      }

      // Product support Like type
      case CommerceTypes.FAILED_PUT_PRODUCT_VOTE:
      case CommerceTypes.START_PUT_PRODUCT_VOTE: {
        if (action.payload.status === VoteStatus.POSITIVE) {
          draft[action.payload.productId].vote = {
            type: action.payload.status,
            updated_at: Date.now(),
          };
          draft[action.payload.productId].up_vote_score++;
          draft[action.payload.productId].vote_score++;
        } else {
          draft[action.payload.productId].vote = undefined;
          draft[action.payload.productId].up_vote_score--;
          draft[action.payload.productId].vote_score--;
        }

        break;
      }
    }
  });
}

export function productSetReducer(
  state = INITIAL_STATE_PRODUCT_SET(),
  action: AllActions,
) {
  return produce(state, draft => {
    switch (action.type) {
      case EntityTypes.ADD_ENTITY: {
        if (action.payload.commerce_productSet) {
          Object.entries(action.payload.commerce_productSet).forEach(
            ([key, value]) => {
              draft[key] = value;
            },
          );
          return draft;
        }
        break;
      }
    }
  });
}

export function commerceCategoryReducer(
  state = INITIAL_STATE_CATEGORY(),
  action: AllActions,
) {
  return produce(state, draft => {
    switch (action.type) {
      case EntityTypes.ADD_ENTITY: {
        if (action.payload.commerce_category) {
          Object.entries(action.payload.commerce_category).forEach(
            ([key, value]) => {
              draft[key] = value;
            },
          );
          return draft;
        }
        break;
      }
    }
  });
}

export function cartResponseReducer(
  state = INITIAL_STATE_CART,
  action: AllActions,
) {
  return produce(state, draft => {
    switch (action.type) {
      case EntityTypes.ADD_ENTITY: {
        if (action.payload.commerce_carts) {
          Object.entries(action.payload.commerce_carts).forEach(
            ([key, value]) => {
              if (!draft[key] || (draft[key] && !isEqual(draft[key], value))) {
                draft[key] = value;
              }
            },
          );
          return draft;
        }
        break;
      }
    }
  });
}
export function commercePurchaseItemReducer(
  state = INITIAL_STATE_PURCHASE_ITEM,
  action: AllActions,
) {
  return produce(state, draft => {
    switch (action.type) {
      case EntityTypes.ADD_ENTITY: {
        const purchaseItems = action.payload.commerce_purchaseItems;
        if (purchaseItems) {
          const locale = browserLocale(action.payload.locale);
          const keys = Object.keys(purchaseItems);
          keys.forEach(key => {
            if (purchaseItems[key]) {
              draft[key] = {
                ...draft[key],
                ...purchaseItems[key],
                reviewable:
                  draft[key]?.reviewable === "alreadyWritten"
                    ? "alreadyWritten"
                    : purchaseItems[key].reviewable,
                productName: getLocaleTextShort(
                  purchaseItems[key],
                  "productName",
                  locale,
                )!,
              };
            }
          });
        }

        break;
      }

      case CommerceTypes.SUCCEED_CREATE_PRODUCT_REVIEW: {
        if (action.payload.purchaseId && action.payload.purchaseItemId) {
          draft[
            `${action.payload.purchaseId}_${action.payload.purchaseItemId}`
          ].reviewable = "alreadyWritten";
        }
        break;
      }
    }
  });
}

export function paymentReducer(
  state = INITIAL_STATE_PAYMENT,
  action: AllActions,
) {
  return produce(state, draft => {
    switch (action.type) {
      case EntityTypes.ADD_ENTITY: {
        if (action.payload.commerce_payments) {
          Object.entries(action.payload.commerce_payments).forEach(
            ([key, value]) => {
              draft[key] = value;
            },
          );
          return draft;
        }
        break;
      }

      case CommerceTypes.SUCCEED_CANCEL_PAYMENT: {
        if (draft[action.payload.paymentId]) {
          draft[action.payload.paymentId].status = "refunded";
          draft[action.payload.paymentId].cancellable = false;
        }

        break;
      }
    }
  });
}

export function purchaseReducer(
  state = INITIAL_STATE_PURCHASE,
  action: AllActions,
) {
  return produce(state, draft => {
    switch (action.type) {
      case EntityTypes.ADD_ENTITY: {
        if (action.payload.commerce_purchases) {
          Object.entries(action.payload.commerce_purchases).forEach(
            ([key, value]) => {
              draft[key] = value;
            },
          );
          return draft;
        }
        break;
      }
    }
  });
}

export function variantReducer(
  state = INITIAL_STATE_VARIANT(),
  action: AllActions,
) {
  return produce(state, draft => {
    switch (action.type) {
      case EntityTypes.ADD_ENTITY: {
        if (action.payload.commerce_variants) {
          Object.entries(action.payload.commerce_variants).forEach(
            ([key, value]) => {
              draft[key] = value;
            },
          );
          return draft;
        }
        break;
      }
    }
  });
}

export function shippingAddressReducer(
  state = INITIAL_STATE_SHIPPING_ADDRESS,
  action: AllActions,
) {
  return produce(state, draft => {
    switch (action.type) {
      case EntityTypes.ADD_ENTITY: {
        if (action.payload.commerce_shipping_address) {
          Object.entries(action.payload.commerce_shipping_address).forEach(
            ([key, value]) => {
              draft[key] = value;
            },
          );
          return draft;
        }
        break;
      }
    }
  });
}

export function deliveryGroupReducer(
  state = INITIAL_STATE_DELIVERY_GROUP(),
  action: AllActions,
) {
  return produce(state, draft => {
    switch (action.type) {
      case EntityTypes.ADD_ENTITY: {
        if (action.payload.commerce_delivery_group) {
          Object.entries(action.payload.commerce_delivery_group).forEach(
            ([key, value]) => {
              draft[key] = value;
            },
          );
          return draft;
        }
        break;
      }
    }
  });
}

export function couponReducer(
  state = INITIAL_STATE_COUPON,
  action: AllActions,
) {
  return produce(state, draft => {
    switch (action.type) {
      case EntityTypes.ADD_ENTITY: {
        if (action.payload.commerce_coupons) {
          Object.entries(action.payload.commerce_coupons).forEach(
            ([key, value]) => {
              draft[key] = value;
            },
          );
          return draft;
        }
        break;
      }

      case CommerceTypes.SUCCEED_DOWNLOAD_COUPON: {
        const { id } = action.payload;
        if (draft[id]) {
          draft[id].isDownloaded = true;
          draft[id].donwloadedCount = (draft[id].donwloadedCount ?? 0) + 1;
        }
        break;
      }
    }
  });
}

export function teamBuyingReducer(
  state = INITIAL_STATE_TEAM_BUYING,
  action: AllActions,
) {
  return produce(state, draft => {
    switch (action.type) {
      case EntityTypes.ADD_ENTITY: {
        if (action.payload.commerce_teamBuyings) {
          Object.entries(action.payload.commerce_teamBuyings).forEach(
            ([key, value]) => {
              draft[key] = value;
            },
          );
          return draft;
        }

        break;
      }
    }
  });
}
