import React from "react";
import { useResizeDetector } from "react-resize-detector";

import { MoimURL } from "common/helpers/url";
import useIsMobile from "common/hooks/useIsMobile";
import useGroupTexts from "common/hooks/useGroupTexts";
import useHubSeller from "common/hooks/commerce/useHubSeller";

import ResponsiveDialog, {
  FixedWidthDialog,
} from "common/components/responsiveDialog";

import {
  MobileTitle,
  DeliveryPolicyDialogContainer,
  DeliveryPolicyContentsWrapper,
  DeliveryPolicyLinkButton,
  ExternalIcon,
  DeliveryPolicyDialogContentWrapper,
} from "../styled";
import DeliveryPolicyItem from "./deliveryPolicyItem";
import { Spacer } from "common/components/designSystem/spacer";

import { DefaultDivider } from "common/components/divider";

import { IRefHandler as IBottomSheetRefHandler } from "common/components/bottomSheet";
import { Link } from "react-router-dom";

interface IProps {
  isOpen: boolean;
  deliveryPolicies: Moim.Commerce.DeliveryPolicy[];
  deliveryType?: Moim.Commerce.DeliveryProductGroupType;
  close(): void;
}

const DeliveryPolicyDialog: React.FC<IProps> = ({
  isOpen,
  close,
  deliveryPolicies,
  deliveryType,
}) => {
  const isMobile = useIsMobile();
  const bottomSheetRef = React.useRef<IBottomSheetRefHandler>(null);
  const [mobileInventoryHeight, setInventoryHeight] = React.useState<
    number | undefined
  >(200);

  const hubSeller = useHubSeller();

  const buttonText = hubSeller?.deliveryPolicy?.buttonText;
  const termId = hubSeller?.deliveryPolicy?.termId;

  const handleResizeInventory = React.useCallback((_, height) => {
    setInventoryHeight(height > 200 ? height : 200);
  }, []);

  const pickUpTitle = useGroupTexts("dialog_pickup_info_title").singular;
  const deliveryTitle = useGroupTexts("dialog_shipping_info_title").singular;

  const isPickUp = deliveryType === "pickUp";
  const title = isPickUp ? pickUpTitle : deliveryTitle;

  const mobileInventoryHeader = React.useMemo(() => {
    if (!isMobile) return null;

    return (
      <>
        <MobileTitle>{title}</MobileTitle>
        <DefaultDivider />
      </>
    );
  }, [isMobile]);

  const { ref } = useResizeDetector({
    handleHeight: true,
    onResize: handleResizeInventory,
  });

  return (
    <ResponsiveDialog
      bottomSheetRef={bottomSheetRef}
      open={isOpen}
      dialogBase={FixedWidthDialog}
      titleElement={title}
      titleAlignment={isMobile ? "Left" : "Center"}
      minHeight={mobileInventoryHeight}
      onCloseRequest={close}
    >
      <DeliveryPolicyDialogContainer>
        <DeliveryPolicyDialogContentWrapper ref={ref}>
          <Spacer value={8} />
          {mobileInventoryHeader}
          {deliveryPolicies.map((deliveryPolicy, index) => (
            <>
              <DeliveryPolicyContentsWrapper key={deliveryPolicy.id}>
                {index !== 0 && (
                  <>
                    <DefaultDivider />
                    <Spacer value={16} />
                  </>
                )}
                <DeliveryPolicyItem deliveryPolicy={deliveryPolicy} />
              </DeliveryPolicyContentsWrapper>
            </>
          ))}
          <Spacer value={10} />
          {buttonText && termId && (
            <DeliveryPolicyContentsWrapper>
              <Link to={new MoimURL.About({ section: termId }).toString()}>
                <DeliveryPolicyLinkButton size="xl">
                  {buttonText}
                  <ExternalIcon />
                </DeliveryPolicyLinkButton>
              </Link>
            </DeliveryPolicyContentsWrapper>
          )}
        </DeliveryPolicyDialogContentWrapper>
      </DeliveryPolicyDialogContainer>
    </ResponsiveDialog>
  );
};

export default React.memo(DeliveryPolicyDialog);
