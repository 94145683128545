import React from "react";
import { useActions, useStoreState } from "app/store";
import useBuyNowAction from "common/hooks/commerce/useBuyNowAction";
import { useSnackbar } from "common/components/alertTemplates/alerts/globalSnackbar/useGlobalSnackbar";
import { useIntlShort } from "common/hooks/useIntlShort";
import { PurchasePreparationDialogContext } from "../context";
import { ActionCreators } from "../actions";

export const purchaseReadyItemToCartItemPayload = (
  items: Moim.Commerce.IPurchaseReadyItem[],
) =>
  items.reduce((acc, value) => {
    if (acc[value.sellerId]) {
      acc[value.sellerId].push({
        productId: value.productId,
        quantity: value.qty,
        productVariantId: value.variantId,
        checked: true,
        disabled: false,
      });
    } else {
      acc[value.sellerId] = [
        {
          productId: value.productId,
          quantity: value.qty,
          productVariantId: value.variantId,
          checked: true,
          disabled: false,
        },
      ];
    }

    return acc;
  }, {} as Record<string, Moim.Commerce.ICartItemDatum[]>);

export function usePurchasePreparationDialogBuyNowAction() {
  const intl = useIntlShort();
  const buyNowAction = useBuyNowAction();

  const { open: openSnackbar } = useSnackbar({
    type: "error",
    useCloseButton: true,
  });
  const { purchaseReadyItems, teamBuying } = React.useContext(
    PurchasePreparationDialogContext,
  );
  const { product } = useStoreState(state => ({
    product:
      state.entities.commerce_product[
        state.purchasePreparationDialog.payload?.productId!
      ],
  }));
  const { dispatchClose } = useActions({ dispatchClose: ActionCreators.close });
  const handleTeamBuyAction = React.useCallback(
    async (params?: {
      purchaseReadyItems?: Moim.Commerce.IPurchaseReadyItem[];
      teamBuying?: {
        teamBuyingId: string;
        teamId: string;
      };
    }) => {
      if (!product) {
        return;
      }
      const _purchaseReadyItems =
        params?.purchaseReadyItems ?? purchaseReadyItems ?? [];
      const _teamBuying =
        params?.teamBuying ??
        (teamBuying
          ? {
              teamBuyingId: teamBuying.teamBuyingId,
              teamId: teamBuying.teamId!,
            }
          : undefined);

      try {
        const itemsObj = purchaseReadyItemToCartItemPayload(
          _purchaseReadyItems,
        );

        await buyNowAction(
          Object.entries(itemsObj).map(([key, value]) => ({
            sellerId: key,
            items: [
              {
                type: product.shippingRequired
                  ? product.deliveryGroupId
                    ? "deliveryGroup"
                    : "deliveryAlone"
                  : "noDelivery",
                id: product.deliveryGroupId,
                items: value,
              },
            ],
          })),
          false,
          undefined,
          undefined,
          _teamBuying,
        );
        dispatchClose();
      } catch {
        openSnackbar({
          text: `(NK)구매 진행에 실패했습니다.\n잠시후 다시 시도해주세요.`,
        });
      }
    },
    [
      intl,
      product,
      purchaseReadyItems,
      teamBuying,
      buyNowAction,
      dispatchClose,
    ],
  );
  return handleTeamBuyAction;
}
