import styled from "styled-components";
import { px2rem } from "common/helpers/rem";
import { WithCarouselLink } from "common/components/blockitEditorBase/components/blockitRenderer.v2/blocks/carousel/item";
import { B4Regular } from "common/components/designSystem/typos";

export const Wrapper = styled(WithCarouselLink)`
  width: 100%;
  display: flex;
  align-items: center;
  background-color: ${props => props.theme.colorV2.colorSet.white1000};
`;

export const Title = styled.div`
  width: 100%;
  flex: 1;
  min-width: 0;
`;

export const Extra = styled(B4Regular)`
  color: ${props => props.theme.colorV2.colorSet.grey300};
  margin-left: ${px2rem(8)};
`;

export const IconWrapper = styled.div`
  width: ${px2rem(18)};
  height: ${px2rem(18)};
`;
