import { useStoreState } from "app/store";
import { Thread } from "app/typings";
import React from "react";
import { ThreadThumbnailWrapper } from "../../components/wrapper/thumbnail";
import styled from "styled-components";
import FallbackImage from "common/components/fallbackImage";

const GalleryItemWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const StyledImage = styled.img.attrs({ loading: "lazy", alt: "" })`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const FallbackImageWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  img.logo {
    height: initial !important;
  }
`;

export const GalleryItem: React.FC<Thread.IThreadItemBaseProps> = ({
  threadId,
  config,
  className,
}) => {
  const thread = useStoreState(state => state.entities.threads[threadId]);
  const ratio =
    config.thumbnailConfig?.type === "ratio"
      ? config.thumbnailConfig?.value
      : "1:1";
  const radius = config.thumbnailConfig?.radius ?? 0;

  return (
    <GalleryItemWrapper className={className}>
      <ThreadThumbnailWrapper
        ratio={ratio}
        thumbnail={thread.preview?.thumbnail}
        isVideo={thread.preview?.isVideo}
        radius={radius}
      >
        {(src, srcSet) =>
          src || srcSet ? (
            <StyledImage src={src} srcSet={srcSet} />
          ) : (
            <FallbackImageWrapper>
              <FallbackImage type="postItem" />
            </FallbackImageWrapper>
          )
        }
      </ThreadThumbnailWrapper>
    </GalleryItemWrapper>
  );
};
