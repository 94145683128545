import { ThreadItemContextProvider } from "../../context";
import Description from "./components/description";
import Engage from "./components/engage";
import Tags from "./components/tags";
import Title from "./components/title";
import UnlockMethods from "./components/unlockContentMethods";
import { DividingSpace as _DividingSpace, Wrapper } from "./styled";
import * as React from "react";

function _MagazineItem({
  thread,
  stat,
  config,
  className,
  children,
}: Moim.Thread.IThreadItemBaseProps) {
  return (
    <ThreadItemContextProvider config={config} stat={stat} item={thread}>
      <Wrapper
        thumbnailUrl={thread.preview?.thumbnail?.url}
        className={className}
      >
        {children}
      </Wrapper>
    </ThreadItemContextProvider>
  );
}

const DividingSpace = () => {
  return <_DividingSpace />;
};

type CompoundedComponent = typeof _MagazineItem & {
  DividingSpace: typeof DividingSpace;
  Tags: typeof Tags;
  Title: typeof Title;
  Description: typeof Description;
  Engage: typeof Engage;
  UnlockMethods: typeof UnlockMethods;
};

const MagazineItem = _MagazineItem as CompoundedComponent;

MagazineItem.DividingSpace = DividingSpace;
MagazineItem.Tags = Tags;
MagazineItem.Title = Title;
MagazineItem.Description = Description;
MagazineItem.Engage = Engage;
MagazineItem.UnlockMethods = UnlockMethods;

export { MagazineItem };
