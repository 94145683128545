import React from "react";
import styled from "styled-components";
import { SkeletonBox } from "common/components/skeleton";
import { px2rem } from "common/helpers/rem";

const Skeleton = styled(SkeletonBox)`
  width: 100%;
  height: ${px2rem(240)};
`;

export function MoimCellSkeleton() {
  return <Skeleton />;
}
