import * as React from "react";
// components
import { FormattedMessage, useIntl } from "react-intl";
import {
  ButtonWrapper,
  StartWithNewProfileButton,
  StartWithUsernameButton,
} from "./styled";
import MoimInfo from "../../component/moimInfo";
import UserProfileImage from "common/components/userProfileImage";
// hooks
import useCurrentGroup from "common/hooks/useCurrentGroup";
import { useActions, useStoreState } from "app/store";
import { useSnackbar } from "common/components/alertTemplates/alerts/globalSnackbar/useGlobalSnackbar";
// helpers
import { errorParseData } from "common/helpers/APIErrorParser";
import { Contents, Wrapper } from "../styled";
import { getCurrentUserWithParentFallback } from "common/helpers/authentication/actions";

export interface IProps {
  onClickSetUsername(): void;
  onClickUseParentProfile(
    user: Partial<PickValue<Moim.User.IPostUserRequestBody, "user">>,
  ): Promise<void>;
}

export default function Main({
  onClickSetUsername,
  onClickUseParentProfile,
}: IProps) {
  const currentGroup = useCurrentGroup();
  const intl = useIntl();
  const { open: openSnackbar } = useSnackbar({
    text: intl.formatMessage({
      id: currentGroup?.config.useOnlyParentProfile
        ? "child_moim_join_by_only_parent_profile_duplicated_username_error"
        : "child_moim_join_by_parent_profile_duplicated_username_error",
    }),
  });
  const { parentUser } = useStoreState(state => ({
    parentUser: state.app.parentMoimUser,
  }));

  const { dispatchGetMeProfile } = useActions({
    dispatchGetMeProfile: getCurrentUserWithParentFallback,
  });

  const handleNextButtonClick = React.useCallback(async () => {
    if (!parentUser) {
      return;
    }
    try {
      await onClickUseParentProfile(parentUser);
    } catch (error) {
      if (error instanceof Error) {
        const parsedError = errorParseData(error);
        switch (parsedError?.code) {
          case "DUPLICATED_USERNAME_IN_PARENT_GROUP":
          case "DUPLICATED_USERNAME":
            openSnackbar();
            break;
        }
      }
    }
  }, [onClickUseParentProfile, openSnackbar, parentUser]);

  React.useEffect(() => {
    dispatchGetMeProfile(currentGroup?.parent, true);
  }, []);

  return (
    <>
      <Wrapper>
        <Contents>
          <MoimInfo name={currentGroup?.name} icon={currentGroup?.icon} />
          <ButtonWrapper>
            <StartWithUsernameButton onClick={handleNextButtonClick} size="l">
              <UserProfileImage
                userId={parentUser?.id}
                src={parentUser?.avatar_url}
                size="s"
              />
              <FormattedMessage
                id="join_dialog_start_with_username"
                values={{ name: parentUser?.name }}
              />
            </StartWithUsernameButton>
            {!currentGroup?.config.useOnlyParentProfile && (
              <StartWithNewProfileButton size="s" onClick={onClickSetUsername}>
                <FormattedMessage id="join_dialog_start_with_new_profile" />
              </StartWithNewProfileButton>
            )}
          </ButtonWrapper>
        </Contents>
      </Wrapper>
    </>
  );
}
