// vendor
import * as React from "react";
// hook
// component
import {
  ChannelName,
  LinkChannelWrapper,
  LinkIcon,
  PrivateIcon,
  RightElementWrapper,
  TitleWrapper,
  Wrapper,
} from "./styled";
import ShavedText from "common/components/shavedText";
import { CommonBadge } from "common/components/alertBadge";
import { NativeEmojiSafeText } from "common/components/designSystem/texts";

import channelToUrl from "app/modules/layout/components/topNavigation/channelToUrl";
import { useStoreState } from "app/store";
import isSelectedChannel from "common/helpers/isSelectedChannel";
import { channelUnreadStatusSelector, unreadCountSelector } from "app/selectors/channel";
import { currentGroupSelector } from "app/selectors/app";
import maximumCount from "common/helpers/numberFormatter/maximumCount";
import useMatchRoute from "common/hooks/useMatchRoute";
import useMainPanelRefresh from "common/hooks/mainPanelRefresh";
import { AnalyticsClass } from "common/helpers/analytics/analytics";
import useRedirect from "common/hooks/useRedirect";
import { getChannelUrl } from "common/helpers/getChannelUrl";

export const MAX_UNREAD_COUNT = 9;

export interface IProps {
  channelId: Moim.Id;
  isMuted: boolean;
  elementPaletteKey?: Moim.Theme.SideAreaElementThemePaletteKey;
  textColorPaletteKey?: Moim.Theme.SideAreaElementThemePaletteKey;
  onClickChannel(channel: Moim.Channel.SimpleChannelType): void;
}

export function getIsInnerUrl(url?: string) {
  try {
    if (!url) {
      return false;
    }

    const nl = new URL(url);
    return nl.hostname === location.hostname;
  } catch (err) {
    return true;
  }
}

function LinkChannelController({
  channel,
  children,
}: React.PropsWithChildren<{
  channel: Moim.Channel.SimpleChannelWithoutCategoryType;
}>) {
  const preventDefault: React.MouseEventHandler<HTMLAnchorElement> = React.useCallback(
    e => {
      e.preventDefault();
    },
    [],
  );
  if (channel.type !== "link") {
    return <>{children}</>;
  }

  return (
    <LinkChannelWrapper href={channel.url} onClick={preventDefault}>
      {children}
    </LinkChannelWrapper>
  );
}

const ChannelItem = React.forwardRef<HTMLDivElement, IProps>(
  (
    {
      channelId,
      onClickChannel,
      isMuted,
      elementPaletteKey,
      textColorPaletteKey,
    },
    ref,
  ) => {
    const redirect = useRedirect();
    const selectedChannel = useMatchRoute();
    const mainPanelRefresh = useMainPanelRefresh();

    const {
      channel,
      unreadCountMessage,
      isLoggedIn,
      isSelected,
      isUnread,
    } = useStoreState(storeState => {
      const ch = storeState.entities.channels[
        channelId
      ] as Moim.Channel.SimpleChannelWithoutCategoryType;
      const currentGroup = currentGroupSelector(storeState);
      return {
        channel: ch,
        unreadCountMessage: maximumCount(
          unreadCountSelector(storeState, channelId),
          MAX_UNREAD_COUNT,
        ),
        isLoggedIn: Boolean(storeState.app.currentUserId),
        isUnread: channelUnreadStatusSelector(storeState, ch),
        isSelected: isSelectedChannel(
          selectedChannel,
          ch.type,
          ch.id,
          currentGroup?.home.web.type === "channel"
            ? currentGroup?.home.web.ref
            : undefined,
        ),
      };
    });

    const isLimited = Boolean(channel.is_limited);

    const href = React.useMemo(() => channelToUrl(channel), [channel]);

    const handleClickChannel = React.useCallback(() => {
      const url = getChannelUrl(channel);
      if (url) {
        redirect(url, undefined, isLoggedIn);
        onClickChannel(channel);
        mainPanelRefresh.doRefresh();

        AnalyticsClass.getInstance().channelListChannelSelect({
          targetChannelId: channel.id,
        });
      }
    }, [channel, mainPanelRefresh, redirect, onClickChannel, isLoggedIn]);

    const channelTitleSuffix = React.useMemo(() => {
      const elements: React.ReactNode[] = [];

      if (channel.type === "link") {
        const isInnerRoute = getIsInnerUrl(channel.url);
        if (!isInnerRoute) {
          elements.push(
            <LinkIcon
              key="link-icon"
              elementPaletteKey={textColorPaletteKey}
            />,
          );
        }
      }

      if (isLimited) {
        elements.push(
          <PrivateIcon
            key="private-icon"
            elementPaletteKey={textColorPaletteKey}
          />,
        );
      }

      return elements;
    }, [channel, isLimited, textColorPaletteKey]);

    const rightElement: React.ReactNode = React.useMemo(() => {
      const elements: React.ReactNode[] = [];

      if (unreadCountMessage) {
        elements.push(
          <CommonBadge key="alert-badge">{unreadCountMessage}</CommonBadge>,
        );
      }

      return <RightElementWrapper>{elements}</RightElementWrapper>;
    }, [textColorPaletteKey, elementPaletteKey, unreadCountMessage]);

    const preventDefault: React.MouseEventHandler<HTMLAnchorElement> = React.useCallback(
      e => {
        e.preventDefault();
      },
      [],
    );

    return (
      <Wrapper
        ref={ref}
        isMuted={isMuted}
        isSelected={isSelected}
        isUnread={isUnread}
        hasChannelItemSuffix={Boolean(channelTitleSuffix.length)}
        elementPaletteKey={elementPaletteKey}
        onClick={handleClickChannel}
      >
        <LinkChannelController channel={channel}>
          <TitleWrapper title={channel.name}>
            <ChannelName
              href={href ?? "#"}
              rel={!href ? "nofollow" : undefined}
              isMuted={isMuted}
              isSelected={isSelected}
              isUnread={isUnread}
              isLoggedIn={isLoggedIn}
              elementPaletteKey={textColorPaletteKey}
              onClick={preventDefault}
            >
              <ShavedText
                value={<NativeEmojiSafeText value={channel.name} />}
                line={1}
              />
            </ChannelName>
            {channelTitleSuffix}
          </TitleWrapper>
        </LinkChannelController>
        {rightElement}
      </Wrapper>
    );
  },
);

export default React.memo(ChannelItem);
