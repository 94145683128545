import { CancelToken } from "axios";
import { ThreadTypes } from "app/actions/types";
import { ActionUnion } from "app/actions/helpers";
import { ThunkPromiseResult } from "app/store";
import { loadEntities } from "../entity";
import { threadListNormalizer } from "app/models";

function createAction<T extends { type: ThreadTypes }>(d: T): T {
  return d;
}

export const ActionCreators = {
  startFetchUnlockedContents: () =>
    createAction({ type: ThreadTypes.START_FETCH_UNLOCKED_CONTENTS }),
  succeedFetchUnlockedContents: () =>
    createAction({ type: ThreadTypes.SUCCEED_FETCH_UNLOCKED_CONTENTS }),
  failedFetchUnlockedContents: () =>
    createAction({ type: ThreadTypes.FAILED_FETCH_UNLOCKED_CONTENTS }),
};

export type Actions = ActionUnion<typeof ActionCreators>;

export function fetchUnlockedContents(
  payload: Pick<Moim.IPaging, "after" | "limit">,
  cancelToken?: CancelToken,
): ThunkPromiseResult<
  Moim.IPaginatedListResponse<Moim.Forum.IThread> | undefined
> {
  return async (dispatch, getState, { apiSelector }) => {
    dispatch(ActionCreators.startFetchUnlockedContents());
    try {
      const result = await apiSelector(
        getState(),
        dispatch,
      ).thread.getUnlockedThreads(payload, cancelToken);
      const normalized = threadListNormalizer(result);
      dispatch(loadEntities(normalized.entities));

      dispatch(ActionCreators.succeedFetchUnlockedContents());

      return result;
    } catch (err) {
      dispatch(ActionCreators.failedFetchUnlockedContents());
    }
  };
}
