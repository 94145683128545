import { MenuText } from "../../styled";
import { Svg18AdminsettingG } from "@moim/icons";
import { getAdminV2Url } from "app/modules/navigationPanel/components/moimConfigMenu/component/moimAdminButton";
import { useStoreState } from "app/store";
import { MenuItem } from "common/components/responsiveMenu/components/menu";
import useCurrentHubGroup from "common/hooks/useCurrentHubGroup";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";

const LinkIcon = styled(Svg18AdminsettingG).attrs((props) => ({
  size: "xs",
  iconColor: props.theme.colorV2.colorSet.grey300,
}))``;

interface IProps {
  userId: string;
  requestClose: () => void;
}

function OpenInAdminMenuItem({ userId, requestClose }: IProps) {
  const { groupId } = useStoreState((state) => ({
    groupId: state.app.currentGroupId,
  }));
  const hubGroup = useCurrentHubGroup();

  const adminLink =
    hubGroup?.url && groupId
      ? `${getAdminV2Url(
          hubGroup?.url,
          groupId,
          `/members/management/${userId}`,
        )}`
      : "";

  return (
    <a href={adminLink} target="_blank">
      <MenuItem onClick={requestClose}>
        <LinkIcon />
        <MenuText>
          <FormattedMessage id="profile_show_more_menu_open_admin" />
        </MenuText>
      </MenuItem>
    </a>
  );
}

export default OpenInAdminMenuItem;
