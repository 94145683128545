import React from "react";
import styled from "styled-components";
import { CheckboxItem } from "./Item";
import { FormItemHeader } from "../../../header";
import { useIntlShort } from "common/hooks/useIntlShort";
import { useFormItemOptions } from "../../../hooks";
import { FormValueContext } from "../../../context";
import { IRef } from "../../..";

const List = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const CheckboxFormItem = React.forwardRef<
  IRef,
  { item: Moim.Form.IFormItem }
>(({ item }, ref) => {
  const intl = useIntlShort();
  const { onChange, values } = React.useContext(FormValueContext);
  const options = useFormItemOptions(item.options);
  const currentValue = values[item.id] ?? [];
  const onChangeItem = (value: any) => {
    onChange(item.id, value);
  };

  React.useImperativeHandle(ref, () => ({
    validate: () => {
      if (item.isRequired && !currentValue?.length) {
        return { [item.id]: intl("error_form_question_required") };
      }
      return { [item.id]: undefined };
    },
  }));
  return (
    <>
      <FormItemHeader item={item} />
      <List>
        {options.map((option, index) => {
          const isSelected = currentValue?.includes(option.label);
          return (
            <CheckboxItem
              key={index}
              value={option.value}
              onChange={value => {
                if (isSelected) {
                  onChangeItem(
                    currentValue?.length > 1
                      ? currentValue?.filter(v => v !== value)
                      : undefined,
                  );
                } else {
                  onChangeItem([...currentValue, value]);
                }
              }}
              label={option.label}
              checked={isSelected}
            />
          );
        })}
      </List>
    </>
  );
});
