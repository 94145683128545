import { MoimURL } from "common/helpers/url";
import { matchPath } from "react-router";

export function getIsCurrentChannel(channelId: string) {
  const currentMatchParams =
    matchPath<Moim.IMatchParams>(location.pathname, {
      path: [
        MoimURL.MeetingHome.pattern,
        MoimURL.DirectMessageShow.pattern,
        MoimURL.ConversationShow.pattern,
        MoimURL.FocusedShowForumThread.pattern,
        MoimURL.ShowForumThread.pattern,
        MoimURL.Forum.pattern,
      ],
    })?.params || {};
  return Object.values(currentMatchParams).includes(channelId);
}
