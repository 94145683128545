import useEntitiesFetchSelector from "../useEntitiesFetchSelector";

export default function useBrandFetchSelector(requestIds: Moim.Id[]) {
  const { isLoading, entities } = useEntitiesFetchSelector({
    commerce_brand: requestIds,
  });

  return {
    isLoading,
    brands: (entities.commerce_brand ?? []) as Moim.Commerce.IBrand[],
  };
}
