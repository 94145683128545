import { useOpacityHoverStyle } from "common/components/designSystem/styles";
import { px2rem } from "common/helpers/rem";
import styled from "styled-components";

export const Wrapper = styled.div<{
  textAlign?: Moim.Forum.ForumListConfigTextAlignment;
}>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: ${(props) => props.theme.colorV2.colorSet.grey300};
  padding-inline: ${px2rem(16)};
  & * {
    color: ${(props) => props.theme.colorV2.colorSet.grey800};
  }
`;

export const Left = styled.div`
  display: flex;
  align-items: center;
  gap: ${px2rem(16)};
`;

export const Right = styled.div`
  display: flex;
  align-items: center;
  gap: ${px2rem(16)};
`;

export const ItemWrapper = styled.div`
  width: fit-content;
  transition: opacity 200ms ease-in;
  padding-block: ${px2rem(13)};

  &:hover {
    ${useOpacityHoverStyle}
  }
`;
