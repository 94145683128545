import { B4Regular } from "common/components/designSystem/typos";
import { textAlignStyle } from "common/components/thread/styles";
import WithPositionChip from "common/components/withPositionChip";
import { px2rem } from "common/helpers/rem";
import styled, { css } from "styled-components";

export const ROW_GAP = 20;
export const COLUMN_GAP = 8;

export const Status = styled(B4Regular)`
  display: flex;
  max-width: 100%;
`;

export const AuthorWrapper = styled(Status)`
  align-items: center;
  gap: ${px2rem(6)};
`;

export const Engage = styled(B4Regular)`
  display: flex;
  align-items: center;
`;

export const EngageWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${px2rem(8)};
`;

export const StatusWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  ${Status} + ${Status}:before {
    content: "･";
    margin: 0 ${px2rem(2)};
  }
`;

export const ReadTimeWrapper = styled(B4Regular)``;

export const Wrapper = styled.div<{
  textAlign?: Moim.Forum.ForumListConfigTextAlignment;
  thumbnailUrl?: string;
}>`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: ${(props) => props.theme.colorV2.colorSet.grey300};

  gap: ${px2rem(2)} ${px2rem(8)};
  margin-top: ${px2rem(6)};
  ${(props) =>
    props.thumbnailUrl &&
    css`
      & * {
        color: ${(props) =>
          props.theme.themeMode.lightPalette.colorSet.white500};
      }
    `}

  ${ReadTimeWrapper}, ${EngageWrapper},
  ${StatusWrapper} {
    ${textAlignStyle}
  }
`;

export const EngageProfileWithPositionChip = styled(WithPositionChip)`
  width: 100%;
  > * {
    flex: 1;
  }
`;
