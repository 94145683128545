import { Svg18DownarrowG, Svg18UparrowG } from "@moim/icons";
import styled from "styled-components";
import { px2rem } from "common/helpers/rem";


import { B4Bold } from "common/components/designSystem/typos";

export const Wrapper = styled.div`
  [type="nestedReply"] {
    padding-left: 0;
    padding-right: 0;
  }
`;

export const OriginalAuthorContainer = styled.div`
  width: ${px2rem(18)};
  height: ${px2rem(18)};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Sep = styled(B4Bold)`
  color: ${props => props.theme.colorV2.colorSet.grey300};
  margin: 0 ${px2rem(4)};
`;

export const LoadMoreText = styled(B4Bold)`
  color: ${props => props.theme.colorV2.colorSet.grey300};
`;

export const LoadMoreWrapper = styled.div`
  width: 100%;
  height: ${px2rem(30)};
  display: flex;
  align-items: center;
`;

export const DownArrowIcon = styled(Svg18DownarrowG).attrs(props => ({
  size: "xs",
  iconColor: props.theme.colorV2.colorSet.grey300,
  touch: 30,
}))``;

export const UpArrowIcon = styled(Svg18UparrowG).attrs(props => ({
  size: "xs",
  iconColor: props.theme.colorV2.colorSet.grey300,
  touch: 30,
}))``;
