import * as React from "react";
import styled from "styled-components";

import CreateDayOMeter from "common/components/createDayOMeter";
import ShavedText from "common/components/shavedText/v2";
import { B4Regular } from "common/components/designSystem/typos";

import { useIntlShort } from "common/hooks/useIntlShort";

import { px2rem } from "common/helpers/rem";

const Wrapper = styled(B4Regular)`
  padding: ${px2rem(4)} 0;
  color: ${props => props.theme.colorV2.colorSet.grey400};
`;

interface IProps {
  date: number;
}

function TimeStamp({ date }: IProps) {
  const intl = useIntlShort();
  return (
    <Wrapper>
      <ShavedText line={1}>
        <CreateDayOMeter
          givenDate={date}
          className="time"
          useChange={false}
          normalFormat={intl("datetime_format_short_tiny_date")}
        />
      </ShavedText>
    </Wrapper>
  );
}

export default React.memo(TimeStamp);
