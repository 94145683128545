import * as React from "react";
interface IType {
  [key: string]: string | number | Record<string, string | number> | undefined;

  extraValues?: Record<string, string | number>;
  utmSource?: string;
  utmMedium?: string;
  utmCampaign?: string;
  utmContent?: string;
  sourceUrl?: string;
  referralUrl?: string;
}

export const ActivityLogInjectorContext = React.createContext<IType | null>(
  null,
);

export function useActivityLogInjectorContext(): IType | null {
  return React.useContext(ActivityLogInjectorContext);
}
