import * as React from "react";
import moment from "moment";
import { FormattedMessage, useIntl } from "react-intl";
import useGroupTexts from "common/hooks/useGroupTexts";
import { useCurrentUserLocale } from "common/hooks/localization/useCurrentUserLocale";
import { useConvertPaymentCustomFields } from "common/hooks/commerce/useConvertPaymentCustomFields";
import useHubSeller from "common/hooks/commerce/useHubSeller";

import { Spacer } from "common/components/designSystem/spacer";
import BuyerInformation from "./components/buyerInformation";
import PaymentInformation from "./components/paymentInformation";
import ShippingInformation from "./components/shippingInformation";
import RefundInformation from "./components/refundInformation";
import PaymentDetailSkeleton from "./skeleton";
import CoinRechargeInfo from "./components/coinRechargeInfo";
import PurchaseList from "./components/purchaseList";
import {
  HeaderWrapper,
  TitleContainer,
  PaymentCancelButton,
  Title,
  PaymentNumber,
  InformationContainer,
  PurchaseListContainer,
  PaymentConfirmButton,
  ButtonContainer,
  BoldDivider,
} from "./styled";

import AdditionalInfo from "./components/additionalInfo";
import GuideHint from "./components/guideHint";
import AddressHumanize from "../../components/addressHumanize";

interface IProps {
  payment?: Moim.Commerce.IPayment;
  isLoading: boolean;
  onClickPaymentCancel(): void;
  onClickConfirmPayment(): void;
}

// TODO  확장성을 위해 purchases(seller 별 주문사항에 배송 정보가 들어가있음)
function getPurchase(payment?: Moim.Commerce.IPayment) {
  return payment?.purchases[0];
}

export default function PaymentDetailComponent({
  payment,
  isLoading,
  onClickConfirmPayment,
  onClickPaymentCancel,
}: IProps) {
  const isCandy = Boolean(payment?.type === "candy");
  const locale = useCurrentUserLocale();

  const hubSeller = useHubSeller();

  const {
    convertedFields,
    isLoading: isConvertingCustomFields,
    handleImageClick,
  } = useConvertPaymentCustomFields(locale, payment?.customFields);

  const intl = useIntl();
  const numberTexts = useGroupTexts("my_shopping_orders_id_title");
  const candyReloadGuideTexts = useGroupTexts("my_shopping_reload_candy_guide");

  const purchase = getPurchase(payment);
  const address = {
    zipcode: purchase?.zipcode,
    address: purchase?.address,
    address2: purchase?.address2,
    city: purchase?.city,
    state: purchase?.state,
  };

  const coinExtraData = React.useMemo(() => {
    if (!payment || payment.type !== "candy") return;

    return payment.purchases[0]?.purchaseItems[0]?.extraData;
  }, [payment]);

  if (isLoading || isConvertingCustomFields) {
    return <PaymentDetailSkeleton />;
  }

  if (!payment) {
    return null;
  }

  return (
    <>
      <Spacer value={4} />
      <HeaderWrapper>
        <TitleContainer>
          <Title>
            {moment(payment.createdAt).format(
              intl.formatMessage({ id: "datetime_format_full_time_date" }),
            )}
          </Title>
          <PaymentNumber>
            {isCandy ? (
              <FormattedMessage
                id="my_shopping_purchase_details_payment_number_title"
                values={{ purchase_id: payment.id }}
              />
            ) : numberTexts ? (
              `${numberTexts.singular} #${payment.id}`
            ) : (
              <FormattedMessage
                id="commerce/purchase_id"
                values={{ purchase_id: payment.id }}
              />
            )}
          </PaymentNumber>
        </TitleContainer>
        {payment?.payable ? (
          <ButtonContainer>
            <PaymentConfirmButton onClick={onClickConfirmPayment}>
              <FormattedMessage id={"button_go_pay"} />
            </PaymentConfirmButton>
          </ButtonContainer>
        ) : (
          payment?.cancellable && (
            <ButtonContainer>
              <PaymentCancelButton onClick={onClickPaymentCancel}>
                <FormattedMessage
                  id={
                    payment.status === "submitted"
                      ? "button_cancel_order_submission"
                      : payment.payMethod === "PAY_IN_STORE"
                      ? "button_cancel_order"
                      : "button_cancel_purchase"
                  }
                />
              </PaymentCancelButton>
            </ButtonContainer>
          )
        )}
      </HeaderWrapper>

      <Spacer value={4} />
      <InformationContainer>
        <PaymentInformation payment={payment} />
        {payment.type !== "candy" ? (
          <BuyerInformation
            name={payment.buyerName}
            phone={payment.buyerPhone}
            email={payment.buyerEmail}
          />
        ) : null}

        {payment.type === "candy" && coinExtraData ? (
          <CoinRechargeInfo
            previousBalance={coinExtraData.coinInfoSnap?.previousBalance ?? 0}
            rechargeAmount={coinExtraData.coinAmount ?? 0}
            coinSymbol={coinExtraData.coinInfoSnap?.symbol ?? ""}
            coinName={coinExtraData.coinInfoSnap?.name ?? ""}
          />
        ) : null}

        {payment.purchases.some(purchase =>
          purchase.purchaseItems.some(purchaseItem =>
            Boolean(purchaseItem.shippingRequired),
          ),
        ) && (
          <ShippingInformation
            buttonText={hubSeller?.deliveryPolicy?.buttonText}
            termId={hubSeller?.deliveryPolicy?.termId}
            name={getPurchase(payment)?.recipientName ?? payment.buyerName}
            phone={getPurchase(payment)?.recipientPhone ?? payment.buyerPhone}
            address={<AddressHumanize addressDetail={address} />}
            memo={getPurchase(payment)?.memo}
          />
        )}
        {payment?.customFields && (
          <AdditionalInfo
            customFields={convertedFields}
            handleImageClick={handleImageClick}
          />
        )}

        {payment.refunds?.map(refund => (
          <RefundInformation
            key={`refund_${refund.id}`}
            refund={refund}
            currency={payment.currency}
            refundedCardName={payment.detail?.cardName}
          />
        ))}
      </InformationContainer>
      <PurchaseListContainer>
        {payment.purchases.map(purchase => (
          <>
            <BoldDivider key={`divider_${purchase.id}`} />
            <Spacer value={16} />
            <PurchaseList key={purchase.id} purchase={purchase} />
          </>
        ))}
      </PurchaseListContainer>

      {isCandy && candyReloadGuideTexts.singular ? (
        <>
          <InformationContainer>
            <GuideHint message={candyReloadGuideTexts.singular} />
          </InformationContainer>
        </>
      ) : null}
    </>
  );
}
