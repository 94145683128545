import React from "react";

import { IRouteComponentProps } from "app/routes/client";
import { useStoreState } from "app/store";
import PageUpdater from "common/components/pageUpdater";
import useOGData from "common/hooks/useOGData";

export function NFTCollectionScheduleShowHelmet(props: IRouteComponentProps) {
  const { match } = props;
  const collection = useStoreState(
    state => state.entities.nftContracts[match.params.contractId ?? ""],
  );
  const schedule = useStoreState(
    state => state.entities.nftSchedules[match.params.scheduleId ?? ""],
  );

  const { seoTitle, seoDescription, metaObjects } = useOGData({
    fallbackTitle: schedule?.name ?? collection?.name,
    fallbackImageUrl:
      schedule?.banner?.previewUrl ?? collection?.banner?.previewUrl,
    fallbackDescription: schedule?.description ?? collection?.description,
  });

  return collection && schedule ? (
    <PageUpdater
      title={seoTitle}
      description={seoDescription}
      metaObjects={metaObjects}
      path={`/collection/${collection?.id}/schedules/${schedule?.id}`}
    />
  ) : null;
}
