import * as React from "react";
import { useIntl } from "react-intl";

interface IProps {
  value?: number;
  defaultValue?: string;
}

export default React.memo(function ActionCount({
  value,
  defaultValue,
}: IProps) {
  const intl = useIntl();

  const positiveNumber = value
    ? intl.formatNumber(value, { notation: "compact" })
    : defaultValue ?? null;
  return <>{positiveNumber}</>;
});
