import React from "react";
import styled from "styled-components";
import { PostShowWrapper } from "app/modules/postShow/styled";
import ReadMore from "./components/readMore";

import { ContentWrapper } from "../../../threadShow/styledComponent";
import BlockitEditor from "common/components/blockitEditorBase";

import { PostShowContext } from "app/modules/postShow/context";
import { tagSetBlockExtractor } from "app/modules/forum/containers/forumEditor/helper";
import { px2rem } from "common/helpers/rem";

const ReadMorePlacement = styled.div`
  position: relative;
  min-height: ${px2rem(570)};
`;

const PostShowPreview: React.FC = () => {
  const { post } = React.useContext(PostShowContext);
  const { contentsWithoutTagSet } = React.useMemo(
    () => tagSetBlockExtractor(post?.preview?.blocks ?? []),
    [post?.preview?.blocks],
  );

  return (
    <ReadMorePlacement>
      <PostShowWrapper>
        <ContentWrapper>
          <BlockitEditor
            id={post?.id}
            readonly={true}
            contents={contentsWithoutTagSet}
          />
        </ContentWrapper>
      </PostShowWrapper>

      <ReadMore />
    </ReadMorePlacement>
  );
};

export default PostShowPreview;
