import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { px2rem } from "common/helpers/rem";

import { useStoreState } from "app/store";

import { B3Regular } from "common/components/designSystem/typos";

import PositionItem from "./PositionItem";

interface AllowedPositionsChipsProps {
  allowedPositions?: string[];
}

const ChipWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: ${px2rem(5)};

  &&& {
    div + div {
      margin: 0;
    }
  }
`;

const AllowedPositionsChips = ({
  allowedPositions,
}: AllowedPositionsChipsProps) => {
  const hasPositionName = useStoreState(state =>
    Boolean(
      allowedPositions?.some(
        position => state.entities.positions[position]?.name,
      ),
    ),
  );

  if (!allowedPositions) return null;

  if (!hasPositionName) {
    return <FormattedMessage id="quest_right_set_to_join_empty" />;
  }

  return (
    <ChipWrapper>
      <B3Regular>
        <FormattedMessage id="dialog_quest_no_right_position_title" />:
      </B3Regular>
      {allowedPositions.map(position => {
        if (!position) return null;
        return <PositionItem positionId={position} />;
      })}
    </ChipWrapper>
  );
};

export default React.memo(AllowedPositionsChips);
