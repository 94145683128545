/* eslint-disable no-underscore-dangle */
// import { qs } from "query-string";
import { InitialLoadData } from "app/routes/ssrLoadData";
import { ISSRLoadDataPayload } from "app/typings/ssr";
import { MoimAPI } from "common/api";
import { getInitialData, setInitialData } from "common/helpers/initialData";
import _ from "lodash";

async function loadProduct(requestId: string, api: MoimAPI, productId: string) {
  const product = await api.commerce.getProductData(productId);
  setInitialData("__entities.product", requestId, product);
}

async function loadThread(requestId: string, api: MoimAPI, productId: string) {
  const sellerId = getInitialData("__bootData.data.group.seller_id", requestId);

  if (sellerId) {
    try {
      const thread = await api.forum.getThread({
        parentId: sellerId,
        threadId: productId,
      });

      setInitialData("__entities.thread", requestId, thread);
      // eslint-disable-next-line no-empty
    } catch {}
  }
}

async function loadReply(
  requestId: string,
  api: MoimAPI,
  parentId: string,
  replyId: string,
) {
  const sellerId = getInitialData("__bootData.data.group.seller_id", requestId);

  if (sellerId) {
    try {
      const thread = await api.forum.getComment({
        channelId: sellerId,
        threadId: parentId,
        replyId,
      });

      setInitialData("__entities.reply", requestId, thread);
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }
}

async function loadLayout(requestId: string, api: MoimAPI, productId: string) {
  try {
    const layout = await api.componentLayout.getComponentLayout(
      productId,
      "show",
    );
    setInitialData("__entities.productLayout", requestId, layout);
    // eslint-disable-next-line no-empty
  } catch {}
}

export async function ProductShowLoadData({
  match,
  api,
  requestId,
}: ISSRLoadDataPayload): Promise<any> {
  await InitialLoadData(requestId);

  const promises: Promise<void>[] = [];

  if (match.params.id) {
    promises.push(loadThread(requestId, api, match.params.id));
    promises.push(loadProduct(requestId, api, match.params.id));
    promises.push(loadLayout(requestId, api, match.params.id));
  }

  await Promise.all(promises);
}

export async function ProductShowThreadLoadData({
  match,
  api,
  requestUrl,
  requestId,
}: ISSRLoadDataPayload) {
  await ProductShowLoadData({ requestId, match, api, requestUrl });

  if (match.params.id && match.params.threadId) {
    await loadReply(requestId, api, match.params.id, match.params.threadId);
  }
}

export async function ProductSetLoadData({
  requestId,
  match,
  api,
}: ISSRLoadDataPayload) {
  if (match.params.id) {
    const response = await api.commerce.batchProductSet([match.params.id]);
    setInitialData("__entities.productSets", requestId, response);
  }
}

export async function SellerLoadData({
  requestId,
  match,
  api,
}: ISSRLoadDataPayload) {
  if (match.params.id) {
    const response = await api.commerce.batchSeller([match.params.id]);
    setInitialData("__entities.sellers", requestId, response);
  }
}

export async function CategoryLoadData({ api, requestId }) {
  const sellerId = getInitialData("__currentGroup.data.seller_id", requestId);

  if (sellerId) {
    const response = await api.commerce.getSellerCategories(sellerId);
    setInitialData("__entities.categories", requestId, response);
  }
}

export async function DeliveryGroupLoadData({
  match,
  api,
  requestId,
}: ISSRLoadDataPayload) {
  if (match.params.id) {
    const response = await api.commerce.batchDeliveryGroups([match.params.id]);
    setInitialData("__entities.deliveryGroups", requestId, response);
  }
}
