import produce from "immer";
import { AllActions } from "app/actions";
import { ComponentLayoutTypes } from "app/actions/types";
import { getInitialData } from "common/helpers/initialData";

export interface IState {
  layout: Record<string, Moim.Component.IComponentLayout | null>;
}

export const INITIAL_STATE: (requestId?: string) => IState = (
  requestId?: string,
) => {
  const draft = {
    layout: {},
  };

  const initialData = getInitialData(
    "__entities.productLayout",
    requestId ?? "",
  );

  const initialProduct = getInitialData("__entities.product", requestId ?? "");

  if (initialData) {
    draft.layout[initialData.id] = initialData;
  } else if (initialProduct) {
    draft.layout[`${initialProduct.id}|show`] = null;
  }

  return draft;
};

export function reducer(state = INITIAL_STATE(), action: AllActions) {
  return produce(state, draft => {
    switch (action.type) {
      case ComponentLayoutTypes.UPDATE_COMPONENT_LAYOUT: {
        const { id, layout } = action.payload;
        draft.layout[id] = layout;
        break;
      }
    }
  });
}
