import * as React from "react";
import {
  StatusChip,
  RemainDateNoStyleChip,
  PeriodChip,
  OutcomeChip,
  AttendedCountChipChip,
} from "../chips";
import { Footer, OutcomeFooter, OutcomeContainer } from "./styled";

const MAX_SIZE = 3;

interface IProps {
  questId: Moim.Id;
  status: Moim.DQuest.QUEST_DISPLAY_STATUS;
  startAt?: number;
  endAt?: number;
  showOutcome?: boolean;
  attendedCount?: number;
  outcomes?: Moim.DQuest.IOutcome[];
  showPeriod?: boolean;
  showAttendedCount?: boolean;
}

const FooterComponent: React.FC<IProps> = ({
  questId,
  status,
  startAt,
  endAt,
  outcomes = [],
  showOutcome = false,
  showPeriod = true,
  attendedCount,
  showAttendedCount = false,
}) => {
  const outcomeContents: Moim.DQuest.IOutcomeContent[] = React.useMemo(
    () =>
      outcomes
        .filter(out => Boolean(out.contentVisible))
        .map(out => ({
          iconUrl: out.imageUrl,
          text: out.title,
        })),
    [outcomes],
  );

  const outcomeElements = React.useMemo(
    () =>
      outcomeContents.slice(0, MAX_SIZE).map((outcome, index) => (
        <OutcomeChip key={`${questId}outcome_${index}`} title={outcome.text}>
          <OutcomeContainer>
            {outcome.iconUrl && <img className="icon" src={outcome.iconUrl} />}
            <span className="text">{outcome.text}</span>
          </OutcomeContainer>
        </OutcomeChip>
      )),
    [questId, outcomeContents],
  );

  const remainChip = React.useMemo(() => {
    const count = outcomeContents.length - MAX_SIZE;
    if (count > 0) {
      return (
        <OutcomeChip key={`${questId}outcome_remain_count`}>
          <OutcomeContainer>
            <span className="text">+{count}</span>
          </OutcomeContainer>
        </OutcomeChip>
      );
    }

    return null;
  }, [outcomeContents.length, questId]);

  return (
    <>
      {showPeriod ? (
        <Footer>
          <div className="left">
            <StatusChip status={status} />
            {Boolean(status === "READY" && startAt) && (
              <RemainDateNoStyleChip targetDate={startAt!} />
            )}
            <PeriodChip startAt={startAt} endAt={endAt} />
          </div>

          {showAttendedCount && (
            <div className="right">
              {attendedCount ? (
                <AttendedCountChipChip attendedCount={attendedCount} />
              ) : null}
            </div>
          )}
        </Footer>
      ) : null}

      {Boolean(showOutcome && outcomeContents.length) && (
        <OutcomeFooter>
          {outcomeElements}
          {remainChip}
        </OutcomeFooter>
      )}
    </>
  );
};

export default React.memo(FooterComponent);
