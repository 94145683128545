import * as React from "react";
import PageUpdater from "common/components/pageUpdater";
import useOGData from "common/hooks/useOGData";
import useGroupTexts from "common/hooks/useGroupTexts";

export function QuestListHelmet() {
  const listName = useGroupTexts("quest_list_title");
  const { seoTitle, seoDescription, metaObjects } = useOGData({
    fallbackTitle: listName.singular,
  });

  return listName ? (
    <PageUpdater
      title={seoTitle}
      description={seoDescription}
      metaObjects={metaObjects}
      path={`/quests`}
    />
  ) : null;
}

export function QuestShowHelmet(props: {
  quest?: Moim.DQuest.INormalizedQuest;
}) {
  const { quest } = props;

  const { seoTitle, seoDescription, metaObjects } = useOGData({
    fallbackTitle: quest?.title,
    fallbackImageUrl: quest?.preview?.coverImageUrls?.[0],
    fallbackDescription: quest?.description,
  });

  return quest ? (
    <PageUpdater
      title={seoTitle}
      description={seoDescription}
      metaObjects={metaObjects}
      path={`/quests/${quest?.id}`}
    />
  ) : null;
}

export function QuestGroupShowHelmet(props: {
  questGroup?: Moim.DQuest.IQuestGroup;
}) {
  const { questGroup } = props;
  const { seoTitle, seoDescription, metaObjects } = useOGData({
    fallbackTitle: questGroup?.title,
    fallbackDescription: questGroup?.description,
  });

  return questGroup ? (
    <PageUpdater
      title={seoTitle}
      description={seoDescription}
      metaObjects={metaObjects}
      path={`/quest-group/${questGroup.id}`}
    />
  ) : null;
}
