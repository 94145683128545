import * as React from "react";
import { AnalyticsClass } from "common/helpers/analytics/analytics";
import { qs } from "url-parse";
import _ from "lodash";

const CommerceCheckoutComplete: React.FC = ({}) => {
  const [url, setUrl] = React.useState<string | undefined>();
  const frameRef = React.useRef<HTMLIFrameElement | null>();

  const handleGetMessage = async (event: MessageEvent) => {
    try {
      const data = JSON.parse(event.data) as
        | {
            type: "order_completed_share_select";
            data: {
              url: string;
              userId: string;
              teamBuyingId: string;
              teamId: string;
              productId: string;
            };
          }
        | {
            type: "order_completed_action_select";
            data: {
              url: string;
            };
          };

      if (data.type === "order_completed_share_select") {
        if ((window.navigator as any).share) {
          await (window.navigator as any).share({
            url: data.data.url,
          });
        }

        AnalyticsClass.getInstance().orderCompleteShareUrl({
          userId: data.data.userId,
          teamBuyingId: data.data.teamBuyingId,
          productId: data.data.productId,
          teamId: data.data.teamId,
        });
      } else if (data.type === "order_completed_action_select") {
        const { url: redirectUrl } = data.data;
        location.href = redirectUrl;
      }

      // eslint-disable-next-line no-empty
    } catch {}
  };

  React.useEffect(() => {
    const analytics = AnalyticsClass.getInstance();
    const params = qs.parse(location.search) as { [key: string]: string };

    const eventParams = params.eventData;
    const successUrl = params.successUrl;

    if (eventParams) {
      const parsed = JSON.parse(decodeURIComponent(eventParams as string));

      analytics.purchase({
        transactionId: parsed.transactionId,
        value: parsed.totalPrice,
        currency: parsed.currency,
        items: parsed.items,
      });
    }

    const successUrlDecoded = decodeURIComponent(successUrl);
    const successUrlObject = new URL(successUrlDecoded);
    const successUrlParams = qs.parse(successUrlObject.search) as {
      [key: string]: string;
    };

    const checkoutQuery = {
      ...params,
      ...successUrlParams,
    };

    _.assign(
      checkoutQuery,
      _.omitBy(
        {
          moimToken: params.moimToken,
          callbackUrl: params.callbackUrl,
          callbackContinueUrl: params.callbackContinueUrl,
          embedded: "true",
        },
        // eslint-disable-next-line @typescript-eslint/unbound-method
        _.isUndefined,
      ),
    );

    setUrl(
      `${successUrlObject.origin}${successUrlObject.pathname}?${qs.stringify(
        checkoutQuery,
      )}`,
    );
  }, []);

  React.useEffect(() => {
    window.addEventListener("message", handleGetMessage, false);
    return () => window.removeEventListener("message", handleGetMessage);
  }, []);

  if (!url) {
    return <div />;
  }

  const actualVh = window.innerHeight;

  return (
    <iframe
      ref={_ref => (frameRef.current = _ref)}
      src={url}
      style={{
        width: "100vw",
        height: `${actualVh}px`,
        border: "none",
        display: "block",
      }}
    ></iframe>
  );
};

export default CommerceCheckoutComplete;
