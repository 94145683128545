/* eslint-disable no-underscore-dangle */
import { ISSRLoadDataPayload } from "app/typings/ssr";
import { setInitialData } from "common/helpers/initialData";
import _ from "lodash";

export async function NFTSetShowLoadData({
  match,
  api,
  requestId,
}: ISSRLoadDataPayload) {
  const setId = match.params.id;

  if (setId) {
    const channel = await api.nft.getNftSetList(setId);
    setInitialData("__entities.nftSet", requestId, channel);
  }
}
