import { Svg18MessageC, Svg24Message1 } from "@moim/icons";
import { useSingleLineStyle } from "common/components/designSystem/styles";
import { B3Regular, B4Regular } from "common/components/designSystem/typos";
import { px2rem } from "common/helpers/rem";
import * as React from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";
import styled from "styled-components";

const SmallWrapper = styled(B4Regular)`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colorV2.colorSet.grey300};
`;

const LargeWrapper = styled(B3Regular)`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colorV2.colorSet.grey800};
`;

const SmallCommentIcon = styled(Svg18MessageC).attrs((props) => ({
  size: "xs",
  iconColor: "currentColor",
}))``;

const LargeCommentIcon = styled(Svg24Message1).attrs((props) => ({
  size: "s",
  touch: 24,
  iconColor: "currentColor",
}))``;

const SmallLabel = styled.span`
  margin-left: ${px2rem(2)};
  ${useSingleLineStyle};
`;

const SmallCount = styled.span`
  margin-left: ${px2rem(2)};
  ${useSingleLineStyle};
`;

const LargeLabel = styled.span`
  margin-left: ${px2rem(2)};
  ${useSingleLineStyle};
`;

const LargeCount = styled.span`
  margin-left: ${px2rem(2)};
  ${useSingleLineStyle};
`;

export function SmallComment({
  count,
  showCommentCount,
  showLabel,
}: {
  count?: number;
  showCommentCount?: boolean;
  showLabel?: boolean;
}) {
  return (
    <SmallWrapper>
      <SmallCommentIcon />
      {showLabel && (
        <SmallLabel>
          <FormattedMessage id="button_feed_comment" />
        </SmallLabel>
      )}
      {showCommentCount && !!count && (
        <SmallCount>
          <FormattedNumber
            value={count ?? 0}
            notation="compact"
            useGrouping={true}
          />
        </SmallCount>
      )}
    </SmallWrapper>
  );
}

export function LargeComment({
  count,
  showCommentCount,
  showLabel,
}: {
  count?: number;
  showCommentCount?: boolean;
  showLabel?: boolean;
}) {
  return (
    <LargeWrapper>
      <LargeCommentIcon />
      {showLabel && (
        <LargeLabel>
          <FormattedMessage id="button_feed_comment" />
        </LargeLabel>
      )}
      {showCommentCount && !!count && (
        <LargeCount>
          <FormattedNumber
            value={count}
            notation="compact"
            useGrouping={true}
          />
        </LargeCount>
      )}
    </LargeWrapper>
  );
}
