import { Svg18Template, Svg24TemplateG } from "@moim/icons";
// vendor
import styled from "styled-components";

// component

export const DraftSmallIcon = styled(Svg18Template).attrs((props) => ({
  size: "xs",
  iconColor: props.theme.colorV2.colorSet.grey300,
}))``;

export const DraftIcon = styled(Svg24TemplateG).attrs((props) => ({
  size: "s",
  iconColor: props.theme.colorV2.colorSet.grey300,
}))``;
