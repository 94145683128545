import { createContext } from "react";

interface IFormValueContext {
  values: Record<Moim.Id, any>;
  errors: Record<Moim.Id, any>;
  onChange(id: Moim.Id, value: any): void;
  setError(id: Moim.Id, error: any);
}

// Note: post 필드는 무조건 있음
const initialValue: IFormValueContext = {
  values: {},
  errors: {},
  onChange: () => {},
  setError: () => {},
};

const FormValueContext = createContext<IFormValueContext>(initialValue);

export { FormValueContext };
