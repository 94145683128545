import { Svg18Pinsolid } from "@moim/icons";
import styled from "styled-components";

export const ExtraElementWrapper = styled.div``;

export const PinnedIcon = styled(Svg18Pinsolid).attrs((props) => ({
  size: "xs",
  iconColor: props.theme.colorV2.colorSet.grey800,
}))``;

export const PostCellWrapper = styled.div.attrs({ role: "button" })`
  display: flex;
  align-items: center;
  justify-content: center;
`;
