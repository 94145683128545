import { Svg18RemoveG, Svg18SearchPlaceholderG, Svg18Search2 } from "@moim/icons";
import styled, { css } from "styled-components";
import { px2rem } from "common/helpers/rem";
import {
  B2Regular,
  B2RegularStyle,
} from "common/components/designSystem/typos";
// icons



import { useScrollStyle } from "common/components/designSystem/styles";
import { getBGLevel3DialogStyle } from "common/components/designSystem/BGLevel";
import { ThemeColorScaleTypes } from "app/enums";
import { TopNaviBlockPropertyStyle } from "app/modules/layout/components/topNavigation/desktop/components/elements/section/styled";

export const cssVariables = css<{ tintColor?: Moim.Blockit.V2.ColorValue }>`
  ${props =>
    props.tintColor
      ? css`
          --tint-color-input-text: ${props.tintColor ===
            ThemeColorScaleTypes.WHITE ||
          props.tintColor === ThemeColorScaleTypes.BLACK
            ? props.theme.getBlockitColorValue(props.tintColor, "grey800")
            : ""};

          --tint-color-10: ${props.theme.getBlockitColorValue(
            props.tintColor,
            "grey10",
          )};
          --tint-color-200: ${props.theme.getBlockitColorValue(
            props.tintColor,
            "grey200",
          )};
          --tint-color-400: ${props.theme.getBlockitColorValue(
            props.tintColor,
            "grey400",
          )};
          --tint-color-800: ${props.theme.getBlockitColorValue(
            props.tintColor,
            "grey800",
          )};
        `
      : null}
`;

export const PositionWrapper = styled.div<{
  element?: Partial<Moim.Layout.Navigation.SearchElementType>;
}>`
  display: flex;
  height: 100%;
  align-items: center;
  min-width: 0;
  min-height: 0;

  ${TopNaviBlockPropertyStyle}
`;

export const Container = styled.div<{
  tintColor?: Moim.Blockit.V2.ColorValue;
}>`
  width: 100%;
  min-height: ${px2rem(30)};
  padding: 0 ${px2rem(8)};
  position: relative;

  ${cssVariables}
`;

export const IconRenderContainer = styled.div<{
  tintColor?: Moim.Blockit.V2.ColorValue;
}>`
  width: ${px2rem(30)};
  height: ${px2rem(30)};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  ${cssVariables}
`;

export const FocusControlWrapper = styled.div<{
  focused: boolean;
}>`
  width: 100%;
  z-index: 0;

  ${props =>
    props.focused
      ? css`
          padding: ${px2rem(8)} ${px2rem(8)} 0;
          border-radius: ${px2rem(4)};
          ${getBGLevel3DialogStyle({ borderRadius: 4 })};
          z-index: ${props.theme.zIndexes.default};
          position: absolute;
          top: -${px2rem(8)};
          left: 0;
          right: 0;
          padding-top: ${px2rem(32)};
        `
      : css`
          display: none;
        `};
`;

export const SearchIcon = styled(Svg18SearchPlaceholderG).attrs({
  size: "xs",
  touch: "30",
})`
  cursor: default;
  path {
    fill: var(
      --tint-color-800,
      ${props => props.theme.colorV2.colorSet.grey800}
    );
  }
`;
export const PrimaryColorSearchIcon = styled(Svg18SearchPlaceholderG).attrs(props => ({
  size: "xs",
  touch: "42",
  iconColor: props.theme.colorV2.primary.color,
}))``;

export const RemoveIcon = styled(Svg18RemoveG).attrs({
  size: "xs",
  touch: "30",
})`
  g {
    fill: var(
      --tint-color-400,
      ${props => props.theme.colorV2.colorSet.grey400}
    );
    stroke: ${props => props.theme.colorV2.colorSet.white1000};
  }
`;

export const InputSearchIcon = styled.div``;

export const Input = styled.input`
  outline: none;
  border: none;
  width: 100%;
  min-width: 0;
  flex: 1;
  padding: ${px2rem(4)} ${px2rem(6)};
  background: none;
  ${B2RegularStyle};
  color: var(
    --tint-color-input-text,
    ${props => props.theme.colorV2.colorSet.grey800}
  );
`;

export const MobileSearchIcon = styled(Svg18Search2).attrs(props => ({
  size: "xs",
  touch: 30,
  iconColor:
    props.theme.getThemeElementColor({
      targetColor: "color",
      elementPalette: props.elementPaletteProps,
    }) ??
    props.theme.getThemeElementColor({
      targetColor: "fog800",
      elementPalette: props.elementPaletteProps,
      fallback: props.theme.colorV2.colorSet.grey800,
    }),
}))<{
  elementPaletteProps?: Moim.Theme.CommonElementThemePaletteProps;
}>``;

export const SuggestionContainer = styled.div`
  width: 100%;
  height: 100%;
  padding-top: ${px2rem(16)};
  padding-bottom: ${px2rem(4)};
  ${useScrollStyle};
`;

export const SearchGo = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: ${px2rem(42)};
`;

export const SearchKeyword = styled(B2Regular)`
  display: block;
  flex: 1;
  width: 100%;
  min-width: 0;
  padding: ${px2rem(10)} ${px2rem(6)} ${px2rem(10)} ${px2rem(16)};
  color: ${props => props.theme.colorV2.colorSet.grey600};
`;

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  border-radius: ${px2rem(15)};
  transition: border 0.2s;
  border: 1px solid
    var(--tint-color-200, ${props => props.theme.colorV2.colorSet.grey200});
  background-color: var(
    --tint-color-10,
    ${props => props.theme.colorV2.colorSet.grey10}
  );
  padding: 0 ${px2rem(2)};
  display: flex;
  align-items: center;
  cursor: text;
  z-index: ${props =>
    props.theme.zIndexes.default + props.theme.zIndexes.default};
  &[data-focused="true"] {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: ${px2rem(15)};
      background-color: ${props => props.theme.colorV2.colorSet.white1000};
      z-index: ${props => props.theme.zIndexes.below};
    }
  }

  &:hover {
    border: 1px solid
      var(--tint-color-400, ${props => props.theme.colorV2.colorSet.grey400});
  }

  &[data-focused="true"] {
    ${Input} {
      color: ${props => props.theme.colorV2.colorSet.grey800} !important;
    }
    ${SearchIcon} {
      svg > path {
        fill: ${props => props.theme.colorV2.colorSet.grey800} !important;
      }
    }
    ${RemoveIcon} {
      svg > g {
        fill: ${props => props.theme.colorV2.colorSet.grey400} !important;
      }
    }
  }
`;

export const PopoverContainer = styled.div`
  position: relative;

  padding: ${px2rem(8)};
  border-radius: ${px2rem(4)};
  ${getBGLevel3DialogStyle({ borderRadius: 4 })};

  .suggestionContainer {
    width: 100%;
    z-index: 0;
    > div {
      padding: 0;
    }
  }
`;

export const PopoverPaperStyle = css`
  width: ${px2rem(200)};
  min-height: 0;
  height: fit-content;
  background-color: transparent;
  &:before {
    background-color: transparent;
  }
`;
