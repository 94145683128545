import { Svg18Bookmark1, Svg18BookmarkFill } from "@moim/icons";
import * as React from "react";
import styled from "styled-components";



import { ThreadRenderer } from "common/components/thread";
import { useActions } from "app/store";
import { useIntlShort } from "common/hooks/useIntlShort";
import useCancelToken from "common/hooks/useCancelToken";
import { px2rem } from "common/helpers/rem";
import { deleteBookmark, postBookmark } from "app/actions/bookmark";

const UnBookmarkIcon = styled(Svg18Bookmark1).attrs(props => ({
  size: "xs",
  touch: 30,
  iconColor: props.theme.colorV2.colorSet.grey100,
}))``;

const BookmarkIcon = styled(Svg18BookmarkFill).attrs(props => ({
  size: "xs",
  touch: 30,
  iconColor: props.theme.colorV2.colorSet.grey800,
}))``;

const Wrapper = styled.div<{
  thumbnailRatio?: string;
  thumbnailPosition?: string;
}>`
  border-radius: ${px2rem(4)};
  border: ${px2rem(1)} solid ${props => props.theme.colorV2.colorSet.grey50};
  padding: ${px2rem(20)} ${px2rem(16)};
  display: flex;
  align-items: center;

  .post-item {
    pointer-events: none;
    padding-right: ${px2rem(8)};
    position: relative;
    &:after {
      content: "";
      position: absolute;
      width: ${px2rem(1)};
      right: 0;
      top: 0;
      bottom: 0;
      background-color: ${props => props.theme.colorV2.colorSet.grey10};
    }

    [class^="ContentWrapper"] {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    [class^="UnlockMethodWrapper"] {
      margin-bottom: ${px2rem(4)};
    }

    [class^="SpacerBase"]:last-of-type {
      display: none;
    }
  }

  gap: ${px2rem(8)};
`;

const IconWrapper = styled.div`
  width: ${px2rem(30)};
  height: ${px2rem(30)};
  transition: opacity 200ms ease-in;

  &:hover {
    opacity: 0.6;
  }
`;
interface IProps {
  post: Moim.Forum.IThread;
}

const PostItem: React.FC<IProps> = ({ post }) => {
  const intl = useIntlShort();
  const cancelToken = useCancelToken();

  const { dispatchPostBookmark, dispatchDeleteBookmark } = useActions({
    dispatchPostBookmark: postBookmark,
    dispatchDeleteBookmark: deleteBookmark,
  });

  const handleBookmark = React.useCallback(() => {
    dispatchPostBookmark(
      { forumId: post.parent_id, threadId: post.id },
      {
        succeed: intl("toast_message_bookmark_success"),
        failed: intl("toast_message_bookmark_failure"),
      },
      cancelToken.current.token,
      post.groupId,
    );
  }, [
    cancelToken,
    dispatchPostBookmark,
    intl,
    post.parent_id,
    post.groupId,
    post.id,
  ]);

  const handleUnBookmark = React.useCallback(() => {
    dispatchDeleteBookmark(
      { forumId: post.parent_id, threadId: post.id },
      {
        succeed: intl("toast_message_bookmark_cancel_success"),
        failed: intl("toast_message_bookmark_cancel_failure"),
      },
      cancelToken.current.token,
      post.groupId,
    );
  }, [
    cancelToken,
    dispatchDeleteBookmark,
    intl,
    post.parent_id,
    post.id,
    post.groupId,
  ]);

  const config: Partial<Moim.Blockit.IPostListShowConfig> = React.useMemo(
    () => ({
      showAuthor: true,
      showAuthorAvatar: false,
      showAuthorPosition: false,
      showReadTime: false,
      showCommentCount: false,
      showDate: true,
      showReaction: false,
      reactionType: "up",
      showThumbnail: true,
      showTitle: true,
      showText: false,
      showViewCount: false,
      textAlignment: "LEFT",
      tagSets: [],
      tagSetFilterType: "NAVIGATION",

      thumbnailConfig: {
        type: "ratio",
        value: "1:1",
        position: "left",
      },
      viewType: "post",
      viewType_web: "post",
    }),
    [],
  );

  return (
    <Wrapper>
      <ThreadRenderer
        threadId={post.id}
        config={config}
        className="post-item"
      />

      {!post?.is_bookmarked ? (
        <IconWrapper onClick={handleBookmark}>
          <UnBookmarkIcon />
        </IconWrapper>
      ) : (
        <IconWrapper onClick={handleUnBookmark}>
          <BookmarkIcon />
        </IconWrapper>
      )}
    </Wrapper>
  );
};

export default PostItem;
