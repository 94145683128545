import * as React from "react";

import RequiredMark from "common/components/requiredMark";

import { Spacer } from "common/components/designSystem/spacer";
import {
  Inner,
  EditorInputInner,
  Title,
  InputWrapper,
  MinimumCharactersCount,
} from "./styled";
import {
  MediaReviewReward,
  TextReviewReward,
} from "./components/reviewReward.new";

import RichEditorFallback from "../../component";

import { useStoreState } from "app/store";

import { useIntlShort } from "common/hooks/useIntlShort";
import { FileInput } from "./components/fileInput";
import ReviewDialogInputToolbar from "./components/toolbar";
import { currentGroupSelector } from "app/selectors/app";
import { IRefHandler } from "common/components/blockitEditorBase";

export interface IRef extends IRefHandler {
  validate?(): void;
}
interface IProps {
  contents: Moim.Blockit.Blocks[];
  fileIds: { id: Moim.Id; priority: number }[];
  visibleReward?: boolean;
  maxAttachmentCount?: number;
  onChangeContents(contents: Moim.Blockit.Blocks[]): void;
  onChangeFileIds: React.Dispatch<
    React.SetStateAction<{ id: Moim.Id; priority: number }[]>
  >;
}

const ReviewDialogGroupInput = React.forwardRef<IRef, IProps>(
  (
    {
      fileIds,
      contents,
      visibleReward = true,
      maxAttachmentCount = 50,
      onChangeFileIds,
      onChangeContents,
    },
    ref,
  ) => {
    const refEditor = React.useRef<IRefHandler>(null);
    const [error, setError] = React.useState<string>();
    const intl = useIntlShort();
    const {
      currentGroupId,
      productReviewMinimumCharactersCount,
    } = useStoreState(state => {
      const currentGroup = currentGroupSelector(state);

      return {
        currentGroupId: currentGroup?.id,
        productReviewMinimumCharactersCount:
          currentGroup?.config.productReviewMinimumCharactersCount,
      };
    });
    const [currentCursorFormat, setCurrentCursorFormat] = React.useState<{
      [key: string]: boolean;
    }>({});
    const [files, setFiles] = React.useState<
      Record<string, { fileId: Moim.Id; file: File }>
    >({});

    const handleChange = React.useCallback(
      (contents: Moim.Blockit.Blocks[]) => {
        const block = contents.filter(
          i => i.type !== "image" && i.type !== "file",
        );
        onChangeContents?.(block);
      },
      [onChangeContents],
    );

    const handleFileChange = React.useCallback(
      (fileId: Moim.Id, file: Moim.Upload.IUploadFileMeta) => {
        setFiles(state => {
          state[fileId] = { fileId, file: file.file };

          return state;
        });

        onChangeFileIds(state => {
          const arr = [
            ...state,
            {
              id: fileId,
              priority: fileIds.length + file.priority,
            },
          ];
          return arr.sort((x, y) => x.priority - y.priority);
        });
      },
      [files.length, onChangeFileIds],
    );

    const handleClickFileAttachment = React.useCallback(() => {
      if (fileIds.length + 1 <= maxAttachmentCount) {
        refEditor.current?.addMediaFiles();
      } else {
        alert(
          intl("input_field_file_maximum_number_toast_message", {
            plain_count: maxAttachmentCount,
          }),
        );
      }
    }, [fileIds, refEditor, intl, maxAttachmentCount]);

    const reviewCharacterCount =
      refEditor.current?.containerElement?.innerText
        ?.replace(/\n/g, "")
        .replace(/\s*/g, "").length ?? 0;

    React.useImperativeHandle<IRef | null, IRef | null>(ref, () =>
      refEditor.current
        ? {
            ...refEditor.current,
            validate: () => {
              let error: string | undefined;
              if (
                productReviewMinimumCharactersCount !== undefined &&
                reviewCharacterCount < productReviewMinimumCharactersCount
              ) {
                error = "error";
              }
              setError(error);
              return error;
            },
          }
        : null,
    );

    return (
      <>
        <Title>{intl("review_editor_media_upload_title")}</Title>
        {visibleReward && <MediaReviewReward />}
        <Spacer value={4} />
        <Inner>
          <FileInput
            addFile={handleClickFileAttachment}
            files={files}
            fileIds={fileIds}
            setFiles={setFiles}
            setFileIds={onChangeFileIds}
          />
        </Inner>

        <Spacer value={16} />

        <Title>
          {intl("review_editor_title")}
          <RequiredMark />
        </Title>
        {visibleReward && <TextReviewReward />}
        <Spacer value={12} />

        <EditorInputInner error={error}>
          <InputWrapper>
            <RichEditorFallback
              id="createReviewDialog"
              groupId={currentGroupId || undefined}
              editorRef={refEditor}
              contents={contents}
              placeholder={intl("review_editor_placeholder")}
              isMultiline={false}
              autoFocus={false}
              maxAttachmentCount={maxAttachmentCount}
              disableImageBlockUpload={true}
              disableVideoBlockUpload={true}
              onFileChange={handleFileChange}
              onChange={handleChange}
              onChangeCursorFormat={setCurrentCursorFormat}
              onEnter={() => {}}
            />
            <ReviewDialogInputToolbar
              refEditor={refEditor}
              currentCursorFormat={currentCursorFormat}
            />
          </InputWrapper>
          {productReviewMinimumCharactersCount !== undefined && (
            <MinimumCharactersCount>
              {intl("write_review_editor_text_minimum_limit", {
                minimum_count: productReviewMinimumCharactersCount,
                current_count: (
                  <span className="count">{reviewCharacterCount}</span>
                ),
              })}
            </MinimumCharactersCount>
          )}
        </EditorInputInner>
      </>
    );
  },
);

export default ReviewDialogGroupInput;
