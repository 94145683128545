import { isServer } from "common/helpers/envChecker";
import safeParseJSON from "common/helpers/safeParseJSON";

const LOCAL_STORAGE_KEY = "COMMERCE_GUEST_CART";

export function getGuestCartFromLocalStorage(
  sellerId: string,
): Moim.Commerce.ICartSellerItem[] | undefined {
  if (isServer()) return undefined;
  const result = safeParseJSON(
    localStorage.getItem(LOCAL_STORAGE_KEY) || "",
    undefined,
  );

  if (result?.sellerId === sellerId) {
    return result.cartItems;
  }
}

export function setGuestCartFromLocalStorage(
  sellerId: string,
  cartItems: Moim.Commerce.ICartSellerItem[],
) {
  if (isServer()) return;
  localStorage.setItem(
    LOCAL_STORAGE_KEY,
    JSON.stringify({ sellerId, cartItems }),
  );
}

export function deleteGuestCartFromLocalStorage(sellerId: string) {
  if (isServer()) return;

  const result = safeParseJSON(
    localStorage.getItem(LOCAL_STORAGE_KEY) || "",
    undefined,
  );

  if (result?.sellerId === sellerId) {
    localStorage.removeItem(LOCAL_STORAGE_KEY);
  }
}
