import * as React from "react";
import { FormattedMessage } from "react-intl";
import { DefaultLoader as Loader } from "common/components/loading";
import InfiniteScroller from "common/components/infiniteScroller";
import {
  Wrapper,
  TabContentWrapper,
  EmptyWrapper,
  EmptyEmoji,
  EmptyTitle,
  HeaderTitle,
  HeaderSubTitle,
  BookmarkCount,
  ParallaxHeaderTitle,
  Header,
  TitleIconWrapper,
  UnlockContentsIcon,
} from "./styled";
import { DefaultLayout } from "../../layout";
import PostItem from "./components/item";
import { Spacer } from "common/components/designSystem/spacer";
import { DefaultDivider } from "common/components/divider";

import useCancelToken from "common/hooks/useCancelToken";
import { useActions } from "app/store";
import { fetchUnlockedContents } from "app/actions/unlockedContent";
import useUserFetchSelector from "common/hooks/useEntitiesFetchSelectors/useUserFetchSelector";
import useGroupTexts from "common/hooks/useGroupTexts";

interface IProps {
  userId: Moim.Id;
  useTab?: boolean;
}

const UserUnlockedContents: React.FC<IProps> = ({ userId }) => {
  const cancelToken = useCancelToken();
  const unlockedMenuTexts = useGroupTexts(
    "profile_show_contents_menu_unlocked_posts",
  );
  const [isLoading, setLoadStatus] = React.useState(false);
  const [contents, setContents] = React.useState<
    Moim.IPaginatedListResponse<Moim.Forum.IThread>
  >({
    data: [],
    paging: {},
  });
  const { dispatchFetchUnlockedContents } = useActions({
    dispatchFetchUnlockedContents: fetchUnlockedContents,
  });
  const { users } = useUserFetchSelector([userId]);

  const fetch = React.useCallback(
    async (after?: string) => {
      if (isLoading) return;

      setLoadStatus(true);
      try {
        const result = await dispatchFetchUnlockedContents(
          {
            after,
          },
          cancelToken.current.token,
        );
        if (result) {
          setContents(state => ({
            data: after ? state.data.concat(result.data) : result.data,
            paging: result.paging,
          }));
        }
      } finally {
        setLoadStatus(false);
      }
    },
    [cancelToken, dispatchFetchUnlockedContents, isLoading],
  );

  const handleLoadMoreBookmark = React.useCallback(() => {
    if (contents.paging.after) {
      fetch(contents.paging.after);
    }
  }, [contents.paging.after, fetch]);

  React.useEffect(() => {
    fetch();
  }, []);

  const postItemListElement = React.useMemo(
    () =>
      contents.data.map(content => {
        if (!content) {
          return null;
        }
        return <PostItem key={content.id} thread={content} />;
      }),
    [contents.data],
  );

  return (
    <DefaultLayout
      appBar={{
        titleElement: (
          <>
            <HeaderTitle>
              <TitleIconWrapper margin={2}>
                <UnlockContentsIcon />
              </TitleIconWrapper>
              {unlockedMenuTexts.singular}
            </HeaderTitle>
            <HeaderSubTitle>{users[0]?.name}</HeaderSubTitle>
          </>
        ),
        titleAlignment: "Left",
        enableScrollParallax: true,
        parallaxWrapperComponent: Header,
        expendScrollParallaxElement: (
          <>
            <HeaderSubTitle>{users[0]?.name}</HeaderSubTitle>
            <ParallaxHeaderTitle>
              <TitleIconWrapper margin={6}>
                <UnlockContentsIcon />
              </TitleIconWrapper>
              {unlockedMenuTexts.singular}
            </ParallaxHeaderTitle>
          </>
        ),
      }}
    >
      <Wrapper>
        <TabContentWrapper>
          {Boolean(contents.data.length) ? (
            <>
              <BookmarkCount>
                <FormattedMessage
                  id="number_of_posts"
                  values={{ count: contents.data.length }}
                />
              </BookmarkCount>
              <Spacer value={8} />
              <DefaultDivider />

              <InfiniteScroller
                loadMore={handleLoadMoreBookmark}
                isLoading={isLoading}
                loader={<Loader />}
                paging={contents.paging}
                itemLength={contents.data.length}
              >
                {postItemListElement}
              </InfiniteScroller>
            </>
          ) : isLoading ? (
            <Loader />
          ) : (
            <EmptyWrapper>
              <EmptyEmoji>📃</EmptyEmoji>
              <EmptyTitle>
                <FormattedMessage id="profile_show_contents_unlocked_posts_empty" />
              </EmptyTitle>
            </EmptyWrapper>
          )}
        </TabContentWrapper>
      </Wrapper>
    </DefaultLayout>
  );
};

export default UserUnlockedContents;
