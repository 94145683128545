import * as React from "react";
import { FormattedMessage } from "react-intl";
import useThreadFetchSelector from "common/hooks/useEntitiesFetchSelectors/useThreadFetchSelector";
import useUserFetchSelector from "common/hooks/useEntitiesFetchSelectors/useUserFetchSelector";
import UserProfileImage from "common/components/userProfileImage";
import NestedReplyInput from "app/modules/subReplies/components/input";
import List from "./list";
import {
  Wrapper,
  LoadMoreWrapper,
  LoadMoreText,
  OriginalAuthorContainer,
  DownArrowIcon,
  Sep,
  UpArrowIcon,
} from "./styled";
import { useStoreState } from "app/store";
import ReplyItem from "app/modules/subReplies/components/replyItem";

interface IProps {
  commentId: Moim.Id;
  previewReplies: Moim.Id[];
  isExpand: boolean;
  focusedReplyId?: Moim.Id;
  hasAuthorReply?: boolean;
  originalAuthorId?: Moim.Id;
  onClickExpand?(): void;
  onClickFold?(): void;
}

const RepresentativeReplies: React.FC<IProps> = ({
  commentId,
  previewReplies,
  isExpand,
  originalAuthorId,
  focusedReplyId,
  hasAuthorReply = false,
  onClickExpand,
  onClickFold,
}) => {
  const { threads: comments } = useThreadFetchSelector([commentId]);
  const { users } = useUserFetchSelector(
    originalAuthorId ? [originalAuthorId] : [],
  );

  const comment = comments?.[0];
  const originalAuthor = users?.[0] as Moim.User.IUser | undefined;
  const remainCount = comment.replies_count - previewReplies.length;

  const originalThreadId = useStoreState(state =>
    comment ? state.entities.threads[comment.parent_id]?.parent_id : undefined,
  );

  if (!comment || (!isExpand && !previewReplies.length)) return null;

  return (
    <Wrapper>
      {isExpand && comment.root_id ? (
        <>
          {originalThreadId && (
            <NestedReplyInput
              originalThreadId={originalThreadId}
              channelId={comment.root_id}
              commentId={commentId}
              autoFocus={true}
            />
          )}

          {comment.replies_count ? (
            <LoadMoreWrapper onClick={onClickFold}>
              <LoadMoreText>
                <FormattedMessage
                  id="button_post_show_replies_see_more_close"
                  values={{ n: comment.replies_count }}
                />
              </LoadMoreText>
              <UpArrowIcon />
            </LoadMoreWrapper>
          ) : null}

          <List
            channelId={comment.root_id}
            commentId={commentId}
            replyId={focusedReplyId}
          />
        </>
      ) : (
        <>
          {previewReplies.map(replyId => (
            <ReplyItem key={`preview_${replyId}`} replyId={replyId} />
          ))}
          {remainCount > 0 ? (
            <LoadMoreWrapper onClick={onClickExpand}>
              <LoadMoreText>
                <FormattedMessage
                  id="button_post_show_replies_see_more"
                  values={{ n: remainCount }}
                />
              </LoadMoreText>
              {hasAuthorReply && originalAuthor ? (
                <>
                  <Sep>・</Sep>
                  <OriginalAuthorContainer>
                    <UserProfileImage
                      size="xs"
                      canOpenProfileDialog={false}
                      userId={originalAuthor.id ?? ""}
                      src={originalAuthor.avatar_url ?? ""}
                      title={originalAuthor.name ?? ""}
                    />
                  </OriginalAuthorContainer>
                </>
              ) : null}
              <DownArrowIcon />
            </LoadMoreWrapper>
          ) : null}
        </>
      )}
    </Wrapper>
  );
};

export default React.memo(RepresentativeReplies);
