import styled, { css } from "styled-components";
import { px2rem } from "common/helpers/rem";
import DividerBase from "common/components/divider";
import { PositionType } from "app/typings/thread";
import { MEDIA_QUERY } from "common/constants/responsive";

export const Divider = styled(DividerBase).attrs(props => ({
  color: props.theme.colorV2.colorSet.grey50,
  height: px2rem(1),
}))``;

export const Wrapper = styled.div<{
  thumbnailPosition?: PositionType;
}>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  padding: ${px2rem(12)} 0;
  border-radius: ${px2rem(12)};
  border: solid ${px2rem(1)} ${props => props.theme.colorV2.colorSet.grey50};
  background-color: ${props => props.theme.colorV2.colorSet.white1000};
  @media ${MEDIA_QUERY.EXCEPT_MOBILE} {
    &:hover {
      .review-preview-image {
        transition: transform 300ms ease-in;
        transform: scale(1.2);
      }
    }
  }
`;

export const PropertiesWrapper = styled.div`
  flex: 1;
  min-width: 0;
  display: flex;
  flex-direction: column;
  padding: 0 ${px2rem(16)};
`;
export const PropertiesWithThumbnail = styled.div<{
  thumbnailPosition?: PositionType;
}>`
  display: flex;
  height: 100%;
  ${({ thumbnailPosition }) => {
    if (thumbnailPosition === "top" || thumbnailPosition === "bottom") {
      return css`
        gap: ${px2rem(8)};
      `;
    } else if (thumbnailPosition === "left" || thumbnailPosition === "right") {
      return css`
        gap: ${px2rem(12)};
      `;
    }
  }}

  ${props => {
    switch (props.thumbnailPosition) {
      case "top":
      case "bottom":
        return css`
          flex-direction: column;
        `;
      case "right":
      case "left":
        return css`
          flex-direction: row;
        `;
    }
  }}

  .properties {
    display: flex;
    flex-direction: column;
    flex: 23;
    min-width: 0;
  }

  .image {
    flex: 10;
    min-width: 0;
  }
`;
