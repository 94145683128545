import {
  Svg18DownarrowG,
  Ic1818MoimlistAll,
  AvatarUserSQuestion,
} from "@moim/icons";
import styled, { css } from "styled-components";

import { px2rem } from "common/helpers/rem";

import {
  useHoverStyle,
  useSingleLineStyle,
} from "common/components/designSystem/styles";

import {
  B3RegularStyle,
  H10Bold,
  H10BoldStyle,
} from "common/components/designSystem/typos";

export const CategoryWrapper = styled.div``;

export const CategoryEntryButton = styled.button<{
  elementPaletteKey: Moim.Theme.SideAreaElementThemePaletteKey;
  isSelected: boolean;
}>`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: ${px2rem(42)};
  cursor: pointer;
  transition: all 200ms ease-in;
  padding: 0 ${px2rem(16)};

  .right-arrow {
    opacity: 0;
  }

  &:hover {
    background-color: ${props =>
      props.theme.getSideAreaElementPalette(props.elementPaletteKey).fog50};

    .right-arrow {
      opacity: 1;
    }
  }

  ${({ isSelected, theme, elementPaletteKey }) =>
    isSelected &&
    css`
      background-color: ${theme.getSideAreaElementPalette(elementPaletteKey)
        .fog100};

      &:hover {
        background-color: ${theme.getSideAreaElementPalette(elementPaletteKey)
          .fog50};
      }
    `}
`;

export const TextWrapper = styled.span`
  display: flex;
  align-items: center;
  width: fit-content;
  max-width: 100%;
  min-width: 0;
  flex: 1;
`;

export const Title = styled.a<{
  elementPaletteKey: Moim.Theme.SideAreaElementThemePaletteKey;
}>`
  width: 100%;
  min-width: 0;
  ${B3RegularStyle}
  font-weight: ${props => props.theme.font.regular};
  color: ${props => {
    const palette = props.theme.getSideAreaElementPalette(
      props.elementPaletteKey,
    );

    return palette.color ?? palette.fog600;
  }};
  margin-left: ${px2rem(12)};
  ${useSingleLineStyle};
  word-break: break-all;
  text-align: left;
`;

export const AccordionHeader = styled.div<{ expanded?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${px2rem(12)} ${px2rem(16)};
  ${B3RegularStyle};
  font-weight: ${props =>
    props.expanded ? props.theme.font.bold : props.theme.font.regular};

  ${useHoverStyle};
  &:hover {
    background-color: ${props =>
      props.theme.colorV2.colorSet.grey50} !important;
  }
`;

export const RootCategory = styled.div`
  ${H10BoldStyle};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${px2rem(15)} ${px2rem(16)};
  border-top: 1px solid ${props => props.theme.colorV2.colorSet.grey50};
  ${useHoverStyle};
  & > span {
    opacity: 0;
    transition: opacity 200ms;
  }
  &:hover {
    & > span {
      opacity: 1;
    }
  }
`;

export const FallbackLogo = styled(AvatarUserSQuestion).attrs({
  size: "xs",
})``;

export const CategoryIcon = styled(Ic1818MoimlistAll).attrs(props => {
  const palette = props.theme.getSideAreaElementPalette("menuText");
  return {
    size: "xs",
    iconColor: palette.color ?? palette.fog400,
  };
})``;

export const Logo = styled.img`
  width: ${px2rem(18)};
  height: ${px2rem(18)};
  border: solid 1px ${props => props.theme.colorV2.colorSet.grey50};
  border-radius: 100%;
`;

export const DownArrowIcon = styled(Svg18DownarrowG).attrs(props => {
  const palette = props.theme.getSideAreaElementPalette("categoryTitleText");
  return {
    size: "xs",
    touch: 18,
    iconColor: palette.color ?? palette.fog400,
  };
})``;

export const CategoryTitle = styled(H10Bold)`
  display: block;
  font-weight: ${props => props.theme.font.bolder};
  color: ${props => {
    const palette = props.theme.getSideAreaElementPalette("categoryTitleText");

    return palette.color ?? palette.fog400;
  }};
`;
