import React from "react";

import PageUpdater from "common/components/pageUpdater";
import { getInitialData } from "common/helpers/initialData";
import useOGData from "common/hooks/useOGData";
import { useRequestId } from "common/hooks/useRequestId";

export function NFTSetShowHelmet(props: { set?: Moim.NFT.INftSetList }) {
  const { set } = props;
  const { seoTitle, seoDescription, metaObjects } = useOGData({
    fallbackTitle: set?.name,
    fallbackDescription: set?.description,
  });

  return set ? (
    <PageUpdater
      title={seoTitle}
      description={seoDescription}
      metaObjects={metaObjects}
      path={`/nft_sets/${set?.id}`}
    />
  ) : null;
}

export function NFTSetShowSSRHelmet() {
  const requestId = useRequestId();
  const set = getInitialData("__entities.nftSet", requestId);
  return NFTSetShowHelmet({ set });
}
