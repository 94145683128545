import React from "react";

import { IRouteComponentProps } from "app/routes/client";
import { useStoreState } from "app/store";
import PageUpdater from "common/components/pageUpdater";
import useOGData from "common/hooks/useOGData";

export function NFTShowHelmet(props: IRouteComponentProps) {
  const { match } = props;

  const nft = useStoreState(
    state => state.entities.nftItems[match.params.nftItemId ?? ""],
  );

  const { seoTitle, seoDescription, metaObjects } = useOGData({
    fallbackTitle: nft?.name,
    fallbackDescription: nft?.description,
    fallbackImageUrl: nft?.itemUrl,
  });

  return nft ? (
    <PageUpdater
      title={seoTitle}
      description={seoDescription}
      metaObjects={metaObjects}
      path={`/nft/${nft?.id}`}
    />
  ) : null;
}
