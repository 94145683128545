import ParsedText from "../parsedText";
import {
  AvatarHolder,
  CommentContainer,
  CommentCountWrapper,
  Content,
  EngageContainer,
  EngageStateWrapper,
  ParentPost,
  ParentTimeStamp,
  ParentUsername,
  StatContainer,
  TimeStamp,
  Title,
  UserDataHolder,
  Username,
  UsernameContainer,
  ViewCountWrapper,
  Wrapper,
} from "./styled";
import CreateDayOMeter from "common/components/createDayOMeter";
import { NativeEmojiSafeText } from "common/components/designSystem/texts";
import { SmallComment } from "common/components/engage/comment";
import { SmallLike } from "common/components/engage/like";
import ViewCount from "common/components/engage/viewCount";
import ShavedText from "common/components/shavedText";
import UserProfileImage from "common/components/userProfileImage";
import { useCurrentUserLocale } from "common/hooks/localization/useCurrentUserLocale";
import useGroupTexts from "common/hooks/useGroupTexts";
import * as React from "react";
import { useIntl } from "react-intl";

interface IProps {
  parentContent?: {
    id: string;
    createdAt: number;
    title?: string;
    creator?: {
      anonymousSuffix?: {
        en: string;
        ko: string;
      };
      username?: string;
    };
    engage?: {
      replyCount?: number;
      upVoteScore?: number;
      upVoted?: boolean;
      viewCount?: number;
    };
  };
  content: {
    id: string;
    createdAt: number;
    creator?: {
      id: string;
      username?: string;
      avatar?: string;
      anonymousSuffix?: {
        en: string;
        ko: string;
      };
    };

    body?: {
      texts?: string[];
    };
  };
}

const SearchCommentItem: React.FC<IProps> = ({ parentContent, content }) => {
  const intl = useIntl();
  const anonymousTextKey = useGroupTexts("anonymous_member");
  const locale = useCurrentUserLocale();

  const parentContentCreateName = React.useMemo(() => {
    if (parentContent?.creator?.anonymousSuffix) {
      return `${anonymousTextKey?.singular}${parentContent.creator.anonymousSuffix?.[locale]}`;
    }
    return parentContent?.creator?.username ?? "";
  }, [
    anonymousTextKey.singular,
    locale,
    parentContent?.creator?.anonymousSuffix,
    parentContent?.creator?.username,
  ]);

  const creatorName = React.useMemo(() => {
    if (content?.creator?.anonymousSuffix) {
      return `${anonymousTextKey?.singular}${content.creator.anonymousSuffix[locale]}`;
    }
    return content?.creator?.username ?? "";
  }, [
    anonymousTextKey.singular,
    content?.creator?.anonymousSuffix,
    content?.creator?.username,
    locale,
  ]);

  // @ts-ignore
  return (
    <Wrapper>
      <ParentPost>
        <Title>
          {parentContent && (
            <ShavedText
              value={
                <ParsedText
                  id={`${parentContent.id}_title`}
                  content={parentContent.title || ""}
                />
              }
              line={1}
            />
          )}
        </Title>
        <EngageStateWrapper>
          {/*
            검색쪽에서 활용될때는 Engage 가 없고, 작성자 정보만 있음
            User Contents 쪽에서는 둘다 있지만 Engage 만 있음
            그래서 이렇게 처리함, 이후 상황에 맞게 Props 나 아래 코드 변경할 것.. 화이팅!
          */}
          {parentContent?.engage ? (
            <EngageContainer>
              {parentContent.engage.upVoteScore !== undefined && (
                <SmallLike
                  liked={Boolean(parentContent.engage.upVoted)}
                  likeCount={parentContent.engage.upVoteScore || 0}
                  threadId={parentContent.id}
                  disabled={true}
                />
              )}
              {parentContent.engage.replyCount !== undefined && (
                <CommentCountWrapper>
                  <SmallComment count={parentContent.engage.replyCount || 0} />
                </CommentCountWrapper>
              )}
              {parentContent.engage.viewCount !== undefined && (
                <ViewCountWrapper>
                  <ViewCount count={parentContent.engage.viewCount || 0} />
                </ViewCountWrapper>
              )}
            </EngageContainer>
          ) : (
            <StatContainer>
              <ParentUsername>
                <NativeEmojiSafeText value={parentContentCreateName ?? ""} />
              </ParentUsername>

              <ParentTimeStamp>
                {parentContent && (
                  <CreateDayOMeter
                    key={`${parentContent.id}-create-at`}
                    givenDate={parentContent.createdAt || 0}
                    className="time"
                    useChange={false}
                    normalFormat={intl.formatMessage({
                      id: "datetime_format_short_tiny_date",
                    })}
                  />
                )}
              </ParentTimeStamp>
            </StatContainer>
          )}
        </EngageStateWrapper>
      </ParentPost>
      <CommentContainer>
        <AvatarHolder>
          <UserProfileImage
            size="m"
            src={content.creator?.avatar}
            userId={content.creator?.id}
            canOpenProfileDialog={false}
          />
        </AvatarHolder>
        <UserDataHolder>
          <UsernameContainer>
            <Username>
              <ShavedText
                value={<NativeEmojiSafeText value={creatorName} />}
                line={1}
              />
            </Username>
            <TimeStamp>
              <CreateDayOMeter givenDate={content.createdAt} />
            </TimeStamp>
          </UsernameContainer>
          <Content>
            <ShavedText
              value={
                <ParsedText
                  id={`${content.id}_title`}
                  content={content.body?.texts?.join("\n") || ""}
                />
              }
              line={3}
            />
          </Content>
        </UserDataHolder>
      </CommentContainer>
    </Wrapper>
  );
};

export default SearchCommentItem;
