import React from "react";
import { useIntlShort } from "common/hooks/useIntlShort";

import { useStoreState } from "app/store";
import useRedirect from "common/hooks/useRedirect";
import useMatchRoute from "common/hooks/useMatchRoute";
import useSideNavigationPanel from "common/hooks/useSideNavigationPanel";

import { MoimURL } from "common/helpers/url";
import { getStoreCommerceCategoriesSelector } from "app/selectors/commerce";

import {
  AccordionHeader,
  CategoryTitle,
  CategoryWrapper,
  DownArrowIcon,
} from "./styled";
import isSelectedChannel from "common/helpers/isSelectedChannel";
import CategoryItem from "./categoryItem";
import { Spacer } from "../../styled";
import { PaletteDivider } from "common/components/divider";
interface IProps {
  onClose?: () => void;
}

const CommerceCategoryList = ({ onClose }: IProps) => {
  const intl = useIntlShort();
  const redirect = useRedirect();
  const [isOpen, toggle] = React.useReducer((state: boolean) => !state, true);
  const selectedChannel = useMatchRoute();

  const { collapseSideNavigation } = useSideNavigationPanel();

  const categories = useStoreState(
    state => getStoreCommerceCategoriesSelector(state)?.data,
  );

  const categoryArr = Object.values(categories);

  const handleClick = React.useCallback(
    (category?: Moim.Commerce.ICategory, isRoot?: boolean) => {
      if (isRoot) {
        redirect(new MoimURL.CommerceCategoryAll().toString());
        if (onClose) {
          onClose();
        }
        collapseSideNavigation();
      }

      if (!category) return;
      if (category.id) {
        redirect(
          new MoimURL.CommerceCategories({
            id: category.id,
            section: "products",
          }).toString(),
        );
        if (onClose) {
          onClose();
        }
        collapseSideNavigation();
      }
    },
    [collapseSideNavigation, onClose, redirect],
  );

  const categoryList = React.useMemo(() => {
    if (!categoryArr) return null;

    return categoryArr.map(category => {
      const isSelected = isSelectedChannel(
        selectedChannel,
        "commerceCategory",
        category.id,
      );
      return (
        <CategoryItem
          key={category.id}
          category={category}
          isSelected={isSelected}
          onClickItem={handleClick}
        />
      );
    });
  }, [categoryArr, handleClick, selectedChannel]);

  return (
    <CategoryWrapper>
      <AccordionHeader expanded={isOpen} onClick={toggle}>
        <CategoryTitle>{intl("button_total_category")}</CategoryTitle>
        {!isOpen && <DownArrowIcon />}
      </AccordionHeader>

      {isOpen && (
        <>
          {categoryList}
          <CategoryItem
            isSelected={isSelectedChannel(
              selectedChannel,
              "commerceCategory",
              "all",
            )}
            onClickItem={handleClick}
            isRootCategory={true}
          />
          <Spacer value={16} />
        </>
      )}
      <PaletteDivider
        elementPaletteProps={{
          type: "sideArea",
          key: "menuText",
        }}
      />
    </CategoryWrapper>
  );
};

export default React.memo(CommerceCategoryList);
