import * as React from "react";
import keycode from "keycode";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Container,
  FocusControlWrapper,
  IconRenderContainer,
  Input,
  PopoverContainer,
  PopoverPaperStyle,
  PositionWrapper,
  PrimaryColorSearchIcon,
  RemoveIcon,
  SearchGo,
  SearchIcon,
  SearchKeyword,
  SuggestionContainer,
  Wrapper,
} from "./styled";
import Popover from "common/components/designSystem/popover";
import useOpenState from "common/hooks/useOpenState";
import useCurrentGroup from "common/hooks/useCurrentGroup";

interface IProps {
  renderAsIcon?: boolean;
  value?: string;
  blockProperties?: Partial<Moim.Layout.Navigation.SearchElementType>;
  onChange?(value: string): void;
  onEnter?(): void;
}

const SearchInputBar: React.FC<IProps> = ({
  renderAsIcon,
  value = "",
  blockProperties,
  onChange,
  onEnter,
}) => {
  const refAnchor = React.useRef<HTMLDivElement>(null);
  const refInput = React.useRef<HTMLInputElement>(null);
  const [focused, setFocus] = React.useState(false);
  const intl = useIntl();
  const currentMoim = useCurrentGroup();
  const tintColor = blockProperties?.tintColor;

  const { isOpen, open, close } = useOpenState();

  const forceRenderAsIcon =
    renderAsIcon || blockProperties?.displayType === "icon";

  const handleFocus = React.useCallback(() => {
    setFocus(true);
  }, []);
  const handleBlur = React.useCallback(() => {
    setFocus(false);
  }, []);

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = React.useCallback(
    e => {
      onChange?.(e.currentTarget.value);
    },
    [onChange],
  );

  const handleRemoveKeyword = React.useCallback(() => {
    onChange?.("");
  }, [onChange]);

  const handleClickWrapper = React.useCallback(() => {
    refInput.current?.focus();
  }, []);

  const handleEnter = React.useCallback(() => {
    onEnter?.();
  }, [onEnter]);

  const handleKeydown: React.KeyboardEventHandler<HTMLInputElement> = React.useCallback(
    e => {
      switch (e.keyCode) {
        case keycode("esc"):
          refInput.current?.blur();
          break;
        case keycode("enter"):
          handleEnter();
          break;
      }
    },
    [handleEnter],
  );

  const suggestionElement = React.useMemo(
    () =>
      (forceRenderAsIcon || focused) && (
        <SuggestionContainer>
          <SearchGo role="button" onClick={handleEnter}>
            <SearchKeyword>
              <FormattedMessage
                id="search/searching"
                values={{ keyword: value }}
              />
            </SearchKeyword>
            <PrimaryColorSearchIcon />
          </SearchGo>
        </SuggestionContainer>
      ),
    [focused, forceRenderAsIcon, handleEnter, value],
  );

  const handleGlobalSearchKeydown = React.useCallback(e => {
    if ((e.metaKey || e.ctrlKey) && e.keyCode === keycode("k")) {
      e.preventDefault();
      if (forceRenderAsIcon) {
        open();
      } else {
        refInput.current?.focus();
      }
    }
  }, []);

  React.useLayoutEffect(() => {
    window.addEventListener("keydown", handleGlobalSearchKeydown);

    return () => {
      window.removeEventListener("keydown", handleGlobalSearchKeydown);
    };
  }, [handleGlobalSearchKeydown]);

  if (forceRenderAsIcon) {
    return (
      <PositionWrapper element={blockProperties}>
        <IconRenderContainer ref={refAnchor} tintColor={tintColor}>
          <SearchIcon
            role="button"
            onClick={() => {
              open();
            }}
          />
        </IconRenderContainer>
        <Popover
          open={isOpen}
          anchorEl={refAnchor.current}
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
          transformOrigin={{ vertical: "top", horizontal: "left" }}
          paperOverrideStyle={PopoverPaperStyle}
          onClose={close}
        >
          <PopoverContainer>
            <Wrapper
              data-focused={true}
              onClick={handleClickWrapper}
              onFocus={handleFocus}
              onBlur={handleBlur}
            >
              <SearchIcon />
              <Input
                ref={refInput}
                autoFocus={true}
                value={value}
                placeholder={intl.formatMessage(
                  { id: "search_moim_placeholder" },
                  { moim_name: currentMoim?.name ?? "" },
                )}
                onChange={handleChange}
                onKeyDown={handleKeydown}
              />
              {value.length > 0 && <RemoveIcon onClick={handleRemoveKeyword} />}
            </Wrapper>
            <div className="suggestionContainer">{suggestionElement}</div>
          </PopoverContainer>
        </Popover>
      </PositionWrapper>
    );
  }

  return (
    <PositionWrapper element={blockProperties}>
      <Container tintColor={tintColor}>
        <Wrapper
          data-focused={focused}
          onClick={handleClickWrapper}
          onFocus={handleFocus}
          onBlur={handleBlur}
        >
          <SearchIcon />
          <Input
            ref={refInput}
            readOnly={!focused}
            autoFocus={focused}
            value={value}
            placeholder={intl.formatMessage(
              { id: "search_moim_placeholder" },
              { moim_name: currentMoim?.name ?? "" },
            )}
            onChange={handleChange}
            onKeyDown={handleKeydown}
          />
          {value.length > 0 && <RemoveIcon onClick={handleRemoveKeyword} />}
        </Wrapper>
        <FocusControlWrapper focused={focused}>
          {suggestionElement}
        </FocusControlWrapper>
      </Container>
    </PositionWrapper>
  );
};

export default SearchInputBar;
