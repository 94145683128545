import styled, { css } from "styled-components";
import { px2rem } from "common/helpers/rem";
import {
  B3RegularStyle,
  H2BoldStyle,
} from "common/components/designSystem/typos";
import { bgLevel1Style } from "common/components/designSystem/BGLevel";
import { MEDIA_QUERY } from "common/constants/responsive";

export const Wrapper = styled.div`
  user-select: none;
  max-width: ${px2rem(560)};

  width: 100%;
  background-color: ${bgLevel1Style};
  border: 1px solid ${props => props.theme.colorV2.colorSet.grey100};
  border-radius: ${px2rem(16)};
  padding: 0 ${px2rem(16)} ${px2rem(16)};

  display: flex;
  flex-direction: column;
  row-gap: ${px2rem(8)};

  & + & {
    margin-top: ${px2rem(24)};
  }

  [class^="UserWrapper"] {
    margin-bottom: 0;
  }

  @media ${MEDIA_QUERY.EXCEPT_MOBILE} {
    min-width: ${px2rem(540)};
  }
`;
export const TitleSection = styled.div`
  width: 100%;
  color: ${props => props.theme.colorV2.colorSet.grey800};
  ${H2BoldStyle}
`;
export const TextPreviewSection = styled.div`
  width: 100%;
  color: ${props => props.theme.colorV2.colorSet.grey700};
  white-space: pre-wrap;
  ${B3RegularStyle}
`;

export const ImageCell = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #fff;

  .blurhash {
    z-index: 0;
  }
  img {
    z-index: 1;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: left top;

    inset: 0;
    position: absolute;
  }
`;

export const ImageGrid = styled.div<{ imageLength: number }>`
  display: grid;
  border-radius: ${px2rem(12)};
  overflow: hidden;
  gap: 1px;

  ${props => {
    switch (props.imageLength) {
      case 1:
        return css`
          grid-template-columns: minmax(auto, 1fr);
          grid-template-areas: "i1";
          .i1 {
            height: ${px2rem(220)};
          }
        `;
      case 2:
        return css`
          grid-template-columns: repeat(2, minmax(auto, 1fr));
          grid-template-areas: "i1 i2";
          .i1 {
            height: ${px2rem(220)};
          }
          .i2 {
            height: ${px2rem(220)};
          }
        `;
      case 3:
        return css`
          grid-template-columns: repeat(4, minmax(auto, 1fr));
          grid-template-areas:
            "i1 i1 i1 i1"
            "i2 i2 i3 i3";
          .i1 {
            height: ${px2rem(220)};
          }
          .i2,
          .i3 {
            height: ${px2rem(180)};
          }
        `;
      case 4:
        return css`
          grid-template-columns: repeat(4, minmax(auto, 1fr));
          grid-template-areas:
            "i1 i1 i2 i2"
            "i3 i3 i4 i4";
          .i1,
          i2 {
            height: ${px2rem(220)};
          }
          .i3,
          i4 {
            height: ${px2rem(200)};
          }
        `;
      default:
      case 5:
        return css`
          grid-template-columns: repeat(6, minmax(auto, 1fr));
          grid-template-areas:
            "i1 i1 i1 i2 i2 i2"
            "i3 i3 i4 i4 i5 i5";
          .i1,
          i2 {
            height: ${px2rem(220)};
          }

          .i3,
          .i4,
          .i5 {
            height: ${px2rem(160)};
          }
        `;
    }
  }}
`;
export const EngageSection = styled.div`
  padding-top: ${px2rem(8)};
  margin-top: ${px2rem(16)};
  border-top: 1px solid ${props => props.theme.colorV2.colorSet.grey100};
  color: ${props => props.theme.colorV2.colorSet.grey300};
  display: flex;
  align-items: center;
  column-gap: ${px2rem(8)};
`;
