import * as React from "react";
import { ShavedTextV2Wrapper } from "./styled";

type IProps = React.PropsWithChildren<{
  line: number;
}>;

const ShavedTextV2 = React.memo(({ line, children }: IProps) => (
  <ShavedTextV2Wrapper line={line}>{children}</ShavedTextV2Wrapper>
));

export default ShavedTextV2;
