import {
  Count,
  LargeCount,
  LargeLabel,
  LargeUnSelectedUpIcon,
  LargeUpIcon,
  LargeWrapper,
  MiddleUnSelectedUpIcon,
  MiddleUpIcon,
  SmallLabel,
  SmallUpIcon,
  SmallUpSelectedUpIcon,
  SmallWrapper,
} from "./styled";
import { VoteStatus } from "app/enums";
import ActionCount from "common/components/actionCount";
import useHover from "common/hooks/useHover";
import * as React from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";

export interface IProps {
  upCount: number;
  disabled?: boolean;
  status: Moim.Enums.VoteStatus;
  showUpCount?: boolean;
  showLabel?: boolean;
  handler?: (type: Moim.Enums.VoteStatus) => void;
  onUpCountClick?: React.MouseEventHandler;
}

export function SmallUp({
  handler,
  upCount,
  disabled,
  status,
  showUpCount = true,
  showLabel,
  onUpCountClick,
}: IProps) {
  const isActiveUpButton = React.useMemo(
    () => status === VoteStatus.POSITIVE,
    [status],
  );

  const handleClick = React.useCallback(
    (newType: Moim.Enums.VoteStatus) => {
      handler?.(status !== newType ? newType : VoteStatus.NONE);
    },
    [status, handler],
  );

  const handleUpClick = React.useCallback(
    (event: React.MouseEvent) => {
      event?.stopPropagation();
      handleClick(VoteStatus.POSITIVE);
    },
    [handleClick],
  );

  const [upHoverRef, isUpButtonHovered] = useHover<HTMLDivElement>();
  return (
    <SmallWrapper disabled={disabled} ref={upHoverRef}>
      {isActiveUpButton ? (
        <SmallUpSelectedUpIcon disabled={disabled} onClick={handleUpClick} />
      ) : (
        <SmallUpIcon disabled={disabled} onClick={handleUpClick} />
      )}

      {showLabel && (
        <SmallLabel
          disabled={disabled}
          isActive={isActiveUpButton}
          isHovered={isUpButtonHovered}
          onClick={handleUpClick}
        >
          <FormattedMessage id="reaction_user_list/tab_title_up" />
        </SmallLabel>
      )}

      {upCount > 0 && showUpCount && (
        <Count isLiked={isActiveUpButton} onClick={onUpCountClick}>
          <ActionCount value={upCount} defaultValue="0" />
        </Count>
      )}
    </SmallWrapper>
  );
}

export function MiddleUp({
  handler,
  upCount,
  disabled,
  status,
  showUpCount = true,
  showLabel,
  onUpCountClick,
}: IProps) {
  const isActiveUpButton = React.useMemo(
    () => status === VoteStatus.POSITIVE,
    [status],
  );

  const handleClick = React.useCallback(
    (newType: Moim.Enums.VoteStatus) => {
      handler?.(status !== newType ? newType : VoteStatus.NONE);
    },
    [status, handler],
  );

  const handleUpClick = React.useCallback(
    (event: React.MouseEvent) => {
      event?.stopPropagation();
      handleClick(VoteStatus.POSITIVE);
    },
    [handleClick],
  );

  const [upHoverRef, isUpButtonHovered] = useHover<HTMLDivElement>();

  return (
    <LargeWrapper disabled={disabled} ref={upHoverRef}>
      {!isActiveUpButton ? (
        <MiddleUnSelectedUpIcon
          disabled={disabled}
          isActive={isActiveUpButton}
          isHovered={isUpButtonHovered}
          onClick={handleUpClick}
        />
      ) : (
        <MiddleUpIcon
          disabled={disabled}
          isActive={isActiveUpButton}
          isHovered={isUpButtonHovered}
          onClick={handleUpClick}
        />
      )}

      {showLabel && (
        <LargeLabel
          disabled={disabled}
          isActive={isActiveUpButton}
          isHovered={isUpButtonHovered}
          onClick={handleUpClick}
        >
          <FormattedMessage id="reaction_user_list/tab_title_up" />
        </LargeLabel>
      )}

      {upCount > 0 && showUpCount && (
        <Count isLiked={isActiveUpButton} onClick={onUpCountClick}>
          <ActionCount value={upCount} defaultValue="0" />
        </Count>
      )}
    </LargeWrapper>
  );
}

export function LargeUp({
  handler,
  upCount,
  disabled,
  status,
  showUpCount = true,
  showLabel,
  onUpCountClick,
}: IProps) {
  const isActiveUpButton = React.useMemo(
    () => status === VoteStatus.POSITIVE,
    [status],
  );

  const handleClick = React.useCallback(
    (newType: Moim.Enums.VoteStatus) => {
      handler?.(status !== newType ? newType : VoteStatus.NONE);
    },
    [status, handler],
  );

  const handleUpClick = React.useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      handleClick(VoteStatus.POSITIVE);
    },
    [handleClick],
  );

  const [upHoverRef, isUpButtonHovered] = useHover<HTMLDivElement>();
  return (
    <LargeWrapper disabled={disabled} ref={upHoverRef}>
      {!isActiveUpButton ? (
        <LargeUnSelectedUpIcon
          disabled={disabled}
          isActive={isActiveUpButton}
          isHovered={isUpButtonHovered}
          onClick={handleUpClick}
        />
      ) : (
        <LargeUpIcon
          disabled={disabled}
          isActive={isActiveUpButton}
          isHovered={isUpButtonHovered}
          onClick={handleUpClick}
        />
      )}

      {showLabel && (
        <LargeLabel
          disabled={disabled}
          isActive={isActiveUpButton}
          isHovered={isUpButtonHovered}
          onClick={handleUpClick}
        >
          <FormattedMessage id="reaction_user_list/tab_title_up" />
        </LargeLabel>
      )}

      {upCount > 0 && showUpCount && (
        <LargeCount isLiked={isActiveUpButton} onClick={onUpCountClick}>
          <FormattedNumber value={upCount} useGrouping={true} />
        </LargeCount>
      )}
    </LargeWrapper>
  );
}
