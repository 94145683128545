import { ThreadItemContextProvider } from "../../context";
import Contents from "./components/contents";
import Engage from "./components/engage";
import Media from "./components/media";
import UserInfo from "./components/userInfo";
import { px2rem } from "common/helpers/rem";
import useIsMobile from "common/hooks/useIsMobile";
import React from "react";
import styled, { css } from "styled-components";

const MediaFocusFeedWrapper = styled.div<{ isMobile?: boolean }>`
  width: 100%;
  height: 100%;

  overflow: hidden;

  ${(props) =>
    !props.isMobile &&
    css`
      &:after {
        content: "";
        display: block;
        margin-top: ${px2rem(10)};
        margin-inline: ${px2rem(16)};
        height: ${px2rem(1)};
        background-color: ${(props) => props.theme.colorV2.colorSet.grey50};
      }
    `}
`;

const _MediaFocusFeedItem = ({
  thread,
  stat,
  config,
  className,
  children,
}: Moim.Thread.IThreadItemBaseProps) => {
  const isMobile = useIsMobile();
  return (
    <ThreadItemContextProvider config={config} stat={stat} item={thread}>
      <MediaFocusFeedWrapper className={className} isMobile={isMobile}>
        {children}
      </MediaFocusFeedWrapper>
    </ThreadItemContextProvider>
  );
};

type CompoundedComponent = typeof _MediaFocusFeedItem & {
  UserInfo: typeof UserInfo;
  Media: typeof Media;
  Contents: typeof Contents;
  Engage: typeof Engage;
};

const MediaFocusFeedItem = _MediaFocusFeedItem as CompoundedComponent;

MediaFocusFeedItem.UserInfo = UserInfo;
MediaFocusFeedItem.Media = Media;
MediaFocusFeedItem.Contents = Contents;
MediaFocusFeedItem.Engage = Engage;

export { MediaFocusFeedItem };
