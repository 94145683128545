import * as React from "react";
import { PermissionDeniedFallbackType, VoteStatus } from "app/enums";
import CommentCount from "app/modules/postShow/components/threadShow/components/engage/components/commentCount";
import PermissionChecker, {
  useResourcePermissionWithoutSuperCheck,
} from "common/components/permissionChecker";

import { LargeLike } from "common/components/engage/like";
import { LargeUpDown } from "common/components/engage/upDown";
import { useActions, useStoreState } from "app/store";

import { EngageSection } from "./styled";
import useCurrentUser from "common/hooks/useCurrentUser";
import { voteThread } from "app/actions/forum";
import { AnalyticsClass } from "common/helpers/analytics/analytics";
import useCancelToken from "common/hooks/useCancelToken";

interface IProps {
  thread: Moim.Forum.IThread;
}

const Engage: React.FC<IProps> = ({ thread }) => {
  const threadId = thread.id;
  const channelId = thread.parent_id;
  const commentCount = thread.replies_count;

  const currentUser = useCurrentUser();
  const cancelToken = useCancelToken();

  const {
    hasPermission: votePermission,
    isLoading,
  } = useResourcePermissionWithoutSuperCheck(
    "POST_VOTE",
    threadId,
    thread.author,
  );
  const isAnonymousReaction = useStoreState(state =>
    Boolean(
      (state.entities.channels[channelId] as Moim.Channel.IForumSimpleChannel)
        ?.anonymous_config?.reaction,
    ),
  );
  const isLoadingToLike = useStoreState(
    state => state.forumData.isLoadingToLike,
  );
  const { dispatchVoteThread } = useActions({
    dispatchVoteThread: voteThread,
  });

  const handleReactionForum = React.useCallback(
    (status: Moim.Enums.VoteStatus) => {
      if (currentUser && !isLoadingToLike) {
        dispatchVoteThread({
          channelId: thread.parent_id,
          threadId: thread.id,
          groupId: thread.groupId,
          type: status,
          cancelToken: cancelToken.current.token,
        });

        if (status === null) {
          AnalyticsClass.getInstance().forumPostReactCancel({
            forumId: thread.parent_id,
            postId: thread.id,
            reactType: thread.vote?.type ?? "upvote",
          });
        } else {
          AnalyticsClass.getInstance().forumPostReact({
            forumId: thread.parent_id,
            postId: thread.id,
            reactType: status,
          });
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      currentUser,
      isLoadingToLike,
      dispatchVoteThread,
      thread.parent_id,
      thread.id,
      thread.groupId,
      thread.vote?.type,
      cancelToken,
    ],
  );

  const reaction = React.useMemo(
    () => ({
      type: "up",
      status: thread.vote?.type ?? VoteStatus.NONE,
      upVoteScore: thread.up_vote_score,
      downVoteScore: thread.down_vote_score,
      isLoading: isLoadingToLike,
      handler: handleReactionForum,
    }),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      handleReactionForum,
      isLoadingToLike,
      thread.down_vote_score,
      thread.up_vote_score,
      thread.vote?.type,
    ],
  );

  const handleCommentCountClick = React.useCallback(() => {}, []);
  const handleClickLike = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
    },
    [],
  );

  return (
    <EngageSection>
      <PermissionChecker
        fallbackType={PermissionDeniedFallbackType.ALERT}
        hasPermission={votePermission}
        isLoading={isLoading}
        groupId={thread.groupId}
      >
        <div onClick={handleClickLike}>
          {reaction.type === "up" ? (
            <LargeLike
              likeCount={reaction.upVoteScore}
              liked={reaction.status === VoteStatus.POSITIVE}
              threadId={threadId}
              channelId={channelId}
              handleLike={reaction.handler}
              disabled={reaction.isLoading}
              disableOpenVotedUserList={isAnonymousReaction}
            />
          ) : (
            <LargeUpDown
              threadId={threadId}
              channelId={channelId}
              status={reaction.status}
              upCount={reaction.upVoteScore}
              downCount={reaction.downVoteScore}
              disabled={reaction.isLoading}
              handler={reaction.handler}
              disableOpenVotedUserList={isAnonymousReaction}
            />
          )}
        </div>
      </PermissionChecker>
      <CommentCount count={commentCount} onClick={handleCommentCountClick} />
    </EngageSection>
  );
};

export default Engage;
