import { GlobalMovePostDialogTypes } from "app/actions/types";
import { ActionUnion } from "app/actions/helpers";

function createAction<T extends { type: GlobalMovePostDialogTypes }>(d: T): T {
  return d;
}

export const ActionCreators = {
  open: (payload: { threadId: string }) =>
    createAction({
      type: GlobalMovePostDialogTypes.OPEN,
      payload,
    }),

  close: () =>
    createAction({
      type: GlobalMovePostDialogTypes.CLOSE,
    }),
};

export type Actions = ActionUnion<typeof ActionCreators>;
