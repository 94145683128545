import ChipBase from "common/components/chips";
import { textAlignStyle } from "common/components/thread/styles";
import { isBrowser } from "common/helpers/envChecker";
import { px2rem } from "common/helpers/rem";
import * as React from "react";
import { useResizeDetector } from "react-resize-detector";
import styled from "styled-components";

const LABEL_GAP = 4;
const REMAINING_TAG_COUNT_WIDTH = 20;

const Tag = styled(ChipBase)`
  background-color: ${(props) => props.theme.colorV2.colorSet.grey50};
  color: ${(props) => props.theme.colorV2.colorSet.grey600};

  & + & {
    margin-left: ${px2rem(4)};
  }
`;

const Wrapper = styled.div<{
  textAlign?: Moim.Forum.ForumListConfigTextAlignment;
}>`
  display: flex;
  flex-wrap: wrap;

  ${textAlignStyle}

  ${Tag} {
    max-width: calc(100% - ${px2rem(LABEL_GAP + REMAINING_TAG_COUNT_WIDTH)});
  }
`;

interface IProps {
  tags: Moim.TagSet.ITagItem[];
  textAlign?: Moim.Forum.ForumListConfigTextAlignment;
}

function Tags({ tags, textAlign }: IProps) {
  const refThis = React.useRef<HTMLDivElement>(null);
  const [maxTagVisibleCount, setMaxTagVisibleCount] = React.useState(10);

  const calculateVisibleCount = React.useCallback(
    (width: number) => {
      const wrapper = refThis.current;
      if (isBrowser() && wrapper && width) {
        requestAnimationFrame(() => {
          let index = 1;
          let accumulateChildWidth = 0;
          for (const node of Array.from(wrapper.children)) {
            const childrenNodeWidth = node.getBoundingClientRect().width;
            if (
              accumulateChildWidth + childrenNodeWidth <
              width - REMAINING_TAG_COUNT_WIDTH
            ) {
              index++;
              accumulateChildWidth += childrenNodeWidth + LABEL_GAP;
            } else {
              setMaxTagVisibleCount(index);
              break;
            }
          }
        });
      }
    },
    [setMaxTagVisibleCount, refThis],
  );
  const visibleTags = React.useMemo(
    () => (tags ? tags.slice(0, maxTagVisibleCount) : []),
    [maxTagVisibleCount, tags],
  );
  const remainTagCount = tags ? tags.length - maxTagVisibleCount : 0;

  useResizeDetector({
    handleWidth: true,
    refreshMode: "debounce",
    onResize: calculateVisibleCount,
    targetRef: refThis,
  });

  return (
    <Wrapper ref={refThis} textAlign={textAlign}>
      {visibleTags.map((tag) => (
        <Tag key={tag.id} shape="round" size="small">
          {tag.value}
        </Tag>
      ))}
      {remainTagCount > 0 && (
        <Tag shape="round" size="small">
          ...
        </Tag>
      )}
    </Wrapper>
  );
}

export default Tags;
