import React, { useRef } from "react";
import { Popover } from "@material-ui/core";
import { useIntlShort } from "common/hooks/useIntlShort";
import useOpenState from "common/hooks/useOpenState";

import CommerceCategoryList from "./CommerceCategoryList";

import {
  CategoryButton,
  InnerContainer,
  ItemWrapper,
  MenuIcon,
} from "./styled";

const CommerceCategoryMenu = ({
  blockProperties,
}: {
  blockProperties: Partial<Moim.Layout.Navigation.CategoryElementType>;
}) => {
  const { isOpen, close, open } = useOpenState();
  const refThis = useRef<HTMLDivElement>(null);
  const intl = useIntlShort();

  return (
    <ItemWrapper
      ref={refThis}
      tintColor={blockProperties.tintColor}
      element={blockProperties}
    >
      <CategoryButton
        isOpen={isOpen}
        tintColor={blockProperties.tintColor}
        onClick={open}
      >
        <MenuIcon tintColor={blockProperties.tintColor} />
        {intl("button_total_category")}
      </CategoryButton>
      <Popover
        open={isOpen}
        anchorEl={refThis.current}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={close}
      >
        <InnerContainer>
          <CommerceCategoryList onClose={close} />
        </InnerContainer>
      </Popover>
    </ItemWrapper>
  );
};

export default CommerceCategoryMenu;
