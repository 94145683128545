import { Wrapper, ItemContainer, ImageWrapper, TextWrapper } from "./styled";
import ShavedText from "common/components/shavedText";
import { isServer } from "common/helpers/envChecker";
import useRedirect from "common/hooks/useRedirect";
import * as React from "react";
import { useResizeDetector } from "react-resize-detector";

const QuickLinkItem: React.FC<Moim.Blockit.IQuickLinkItem> = ({
  title,
  href,
  image,
}) => {
  const redirect = useRedirect();
  const [itemWidth, setItemWidth] = React.useState<number | undefined>(
    isServer() ? undefined : 0,
  );

  const textElement = React.useMemo(() => {
    if (image) {
      return (
        <TextWrapper isSingleLine={true}>
          <span>{title}</span>
        </TextWrapper>
      );
    }
    return (
      <TextWrapper isSingleLine={false}>
        <ShavedText line={3} value={title} />
      </TextWrapper>
    );
  }, [image, title]);

  const handleClick = React.useCallback(() => {
    redirect(href);
  }, [redirect, href]);

  const handleResize = React.useCallback((w) => {
    setItemWidth(w);
  }, []);

  const { ref } = useResizeDetector({
    handleWidth: true,
    onResize: handleResize,
  });

  return (
    <Wrapper ref={ref} onClick={handleClick}>
      <ItemContainer adjustHeight={itemWidth} withImage={Boolean(image)}>
        {image && (
          <ImageWrapper containerWidth={itemWidth}>
            <img
              alt={title}
              src={image.src}
              srcSet={image.srcSet}
              sizes={`${itemWidth}px`}
            />
          </ImageWrapper>
        )}
        {textElement}
      </ItemContainer>
    </Wrapper>
  );
};

export default QuickLinkItem;
