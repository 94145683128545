import { useCallback } from "react";

import { useActions, useStoreState } from "app/store";
import { ActionCreators } from "../actions";
import { IPurchasePreparationDialogPayload } from "../reducer";
import {
  getVisibleOptionInventoryStep,
  getVisibleTeamBuyingStep,
} from "./useVisibleStep";
import useBuyNowAction from "common/hooks/commerce/useBuyNowAction";
import { purchaseReadyItemToCartItemPayload } from "./useBuyNow";
import useCartActions from "common/hooks/commerce/useCartActions";

export function useOpenPurchasePreparationDialog(
  productId: string,
  callback: (
    payload: Omit<IPurchasePreparationDialogPayload, "productId">,
  ) => void,
  validate?: (
    payload: Omit<IPurchasePreparationDialogPayload, "productId">,
  ) => boolean,
) {
  const { open } = useActions({ open: ActionCreators.open });

  return useCallback(
    async (payload: Omit<IPurchasePreparationDialogPayload, "productId">) => {
      if (validate && !validate(payload)) {
        return;
      }
      const visibleOptionInventoryStep = getVisibleOptionInventoryStep(
        payload.purchaseReadyItems,
      );
      const visibleTeamBuyingStep = getVisibleTeamBuyingStep({
        teamBuyingId: payload.teamBuying?.teamBuyingId,
        teamId: payload.teamBuying?.teamId,
        enterFrom: payload.teamBuying?.enterFrom,
      });

      if (visibleOptionInventoryStep || visibleTeamBuyingStep) {
        open({ ...payload, productId });
      } else {
        callback?.(payload);
      }
    },
    [productId, callback, open, validate],
  );
}

export function useOpenPurchasePreparationDialogWithAddCart(
  productId: string,
  validate?: (
    payload: Omit<IPurchasePreparationDialogPayload, "productId">,
  ) => boolean,
) {
  const { addToCart } = useCartActions();

  const addCart = useCallback(
    (payload: Omit<IPurchasePreparationDialogPayload, "productId">) => {
      addToCart(productId, payload.purchaseReadyItems ?? []);
    },
    [productId, addToCart],
  );

  return useOpenPurchasePreparationDialog(productId, addCart, validate);
}

export function useOpenPurchasePreparationDialogWithBuyNow(
  productId: string,
  validate?: (
    payload: Omit<IPurchasePreparationDialogPayload, "productId">,
  ) => boolean,
) {
  const { product } = useStoreState(state => ({
    product: state.entities.commerce_product[productId],
  }));

  const buyNowAction = useBuyNowAction();

  const buyNow = useCallback(
    async (payload: Omit<IPurchasePreparationDialogPayload, "productId">) => {
      try {
        const itemsObj = purchaseReadyItemToCartItemPayload(
          payload.purchaseReadyItems ?? [],
        );

        await buyNowAction(
          Object.entries(itemsObj).map(([key, value]) => ({
            sellerId: key,
            items: [
              {
                type: product.shippingRequired
                  ? product.deliveryGroupId
                    ? "deliveryGroup"
                    : "deliveryAlone"
                  : "noDelivery",
                id: product.deliveryGroupId,
                items: value,
              },
            ],
          })),
          false,
          undefined,
          undefined,
          payload.teamBuying?.enterFrom === "team"
            ? {
                teamBuyingId: payload.teamBuying.teamBuyingId,
                teamId: payload.teamBuying.teamId,
              }
            : undefined,
        );
      } catch {}
    },
    [product, buyNowAction],
  );

  return useOpenPurchasePreparationDialog(productId, buyNow, validate);
}
export function useClosePurchasePreparationDialog() {
  const { close } = useActions({
    close: ActionCreators.close,
  });

  return { close };
}
