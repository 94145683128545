import LazyBlurHashImage from "common/components/lazyBlurHashImage";
import * as React from "react";

interface IProps {
  media: Moim.Blockit.ICarouselImage;
  width: number;
  height: number;
  index: number;
}

const CarouselItemElement: React.FC<IProps> = ({ media, width, height }) => {
  return (
    <LazyBlurHashImage
      src={media.src ?? media.imageSrc}
      alt={(media.src ?? media.imageSrc).split("/").pop() ?? ""}
      blurHash={media.blurHash ?? media.blur_hash}
      fallBackSrc={media.fallbackSrc ?? media.src ?? media.imageSrc}
      width={width}
      height={height}
    />
  );
};

export default React.memo(CarouselItemElement);
