// vendor
import * as React from "react";
// hook
import { useSideNaviElements } from "app/modules/layout/components/controller/hooks";

// component
import CurrentUser from "../../components/currentUser";
import Header from "../../components/header";
import NavigationPanelUnreadSnackBar from "../../components/unreadSnackBar";
import Period from "../../components/period";
import { PaletteDivider } from "common/components/divider";
import SideNavigationRenderer from "./renderer";

import {
  Body,
  HeaderWrapper,
  BottomWrapper,
  Wrapper,
  Container,
  Spacer,
} from "./styled";
import { useActions, useStoreState } from "app/store";
import { channelWithCategorySelector } from "app/selectors/channel";
import { getGroupStatus } from "app/actions/group";
import useCurrentUser from "common/hooks/useCurrentUser";
import useCurrentGroup from "common/hooks/useCurrentGroup";
import { useResizeDetector } from "common/hooks/useResizeDetector";

function NavigationPanel() {
  const [headerRef, headerSize] = useResizeDetector({
    refreshMode: "debounce",
  });
  const [footerRef, footerSize] = useResizeDetector({
    refreshMode: "debounce",
  });
  const currentUser = useCurrentUser();
  const currentGroup = useCurrentGroup();
  const visibleCurrentUser =
    Boolean(currentUser) &&
    !currentGroup?.navigation_structure.web.topNavi.showNavigation;
  const { hasNonCategoryChannel, logo } = useStoreState(storeState => ({
    hasNonCategoryChannel:
      channelWithCategorySelector(storeState).data[0]?.type !== "category",

    logo: storeState.group.theme.logo,
  }));
  const { dispatchGetGroupStatus } = useActions({
    dispatchGetGroupStatus: getGroupStatus,
  });

  const sideNaviElements = useSideNaviElements();

  React.useEffect(() => {
    dispatchGetGroupStatus();
  }, []);

  if (!currentGroup) {
    return null;
  }
  return (
    <Container>
      <Wrapper>
        <HeaderWrapper ref={headerRef}>
          <Header logo={logo} />
        </HeaderWrapper>
        <Period
          status={currentGroup.status}
          period={currentGroup.period}
          statusConfig={currentGroup.status_config}
        />
        <Spacer value={8} />
        {hasNonCategoryChannel && (
          <PaletteDivider
            elementPaletteProps={{
              type: "sideArea",
              key: "menuText",
            }}
          />
        )}
        <Body data-scroll-lock-scrollable>
          {sideNaviElements.map((element, index) => (
            <SideNavigationRenderer key={index} element={element} />
          ))}
        </Body>
        {visibleCurrentUser && (
          <BottomWrapper ref={footerRef}>
            {hasNonCategoryChannel && (
              <PaletteDivider
                elementPaletteProps={{
                  type: "sideArea",
                  key: "menuText",
                }}
              />
            )}
            <CurrentUser currentUser={currentUser!} />
          </BottomWrapper>
        )}
        <NavigationPanelUnreadSnackBar
          headerHeight={headerSize.height}
          footerHeight={footerSize.height}
        />
      </Wrapper>
    </Container>
  );
}

export default React.memo(NavigationPanel);
