import * as React from "react";
import { useResizeDetector } from "react-resize-detector";

// components
import { IRefHandler } from "common/components/blockitEditorBase";
import { InputActionContainer, InputWrapper } from "./styledComponent";

import RichEditorFallback from "./component";
export { default as SimpleDialogGroupInput } from "./preset/simpleDialogGroupInput";
export { default as ReviewDialogGroupInput } from "./preset/reviewDialogGroupInput";

import DesktopGroupInput from "./components/desktop";
import MobileGroupInput from "./components/mobile";
import AttachmentElement from "./components/attachmentElement";

import GroupInputContext from "./context";
import GroupInputTypes from "./type";
import { useProps, useEffects, useHandlers } from "./hooks";
import useContextValue from "./hooks/useContextValue";

export type IForwardRef = IRefHandler & { groupInputClear(): void };

const GroupInput = React.forwardRef<IForwardRef | null, GroupInputTypes.IProps>(
  (props, ref) => {
    const hookProps = useProps(props);
    const hookHandlers = useHandlers(hookProps);
    useEffects(hookProps, hookHandlers);
    const {
      id,
      isMobile,
      groupId,
      autoFocus,
      editorRef,
      defaultContents,
      disableMentionPortal,
      disableImageBlockUpload,
      disableVideoBlockUpload,
      mentionPortalContainer,
      isMultiline,
      placeholder,
      resizeDetectWidth,
      resizeDetectHeight,
      maxAttachmentCount,
      minInputLine,
      emojiDialog,
      hasContent,
      hasFileContent,
      hasMeetingFileContent,
      currentCursorFormat,
      useSaveButton,
      useFileAttachButton,
      disableCreateMeeting,
      isMaxUploaded,
      disableEditor,
      disableMention,
      files,
      imageBlocks,
      setCurrentCursorFormat,
      onResize,
      onFocus,
      onBlur,
      onCancel,
      setFiles,
      setImageBlocks,
      setImageFileIds,
    } = hookProps;
    const {
      clear,
      handleEnter,
      handleFileChange,
      handleChange,
      handleClickFileAttachment,
    } = hookHandlers;

    const values = useContextValue({
      editorRef,
      emojiDialog,
      hasContent,
      hasFile: hasFileContent,
      isMaxUploaded,
      useSaveButton,
      currentCursorFormat,
      useFileAttachButton,
      disableMention,
      disableCreateMeeting,
      onEnter: handleEnter,
      onClickFileAttachment: handleClickFileAttachment,
    });

    React.useImperativeHandle<IForwardRef | null, IForwardRef | null>(ref, () =>
      editorRef.current
        ? { ...editorRef.current, groupInputClear: clear }
        : null,
    );

    const WithToolbar = React.useMemo(
      () => (isMobile ? MobileGroupInput : DesktopGroupInput),
      [isMobile],
    );

    const handleDeleteFile = React.useCallback(
      (fileId: string) => {
        setFiles(state => state.filter(file => file.fileId !== fileId));
        editorRef.current?.onFileDelete(fileId);
      },
      [setFiles],
    );
    const handleDeleteImage = React.useCallback(
      (targetId: string) => {
        const tmpImageBlock = imageBlocks[parseInt(targetId, 10)];
        setImageBlocks(base => base.filter((_, idx) => `${idx}` !== targetId));

        if (tmpImageBlock?.fileId) {
          setImageFileIds(base =>
            base.filter(({ id: _id }) => _id !== tmpImageBlock.fileId),
          );
          editorRef.current?.onFileDelete(tmpImageBlock?.fileId);
        }
      },
      [imageBlocks, setImageBlocks, setImageFileIds],
    );

    const { ref: refResize } = useResizeDetector({
      handleWidth: resizeDetectWidth,
      handleHeight: resizeDetectHeight,
      onResize,
    });

    return (
      <>
        <GroupInputContext.Provider value={values}>
          <div ref={refResize}>
            <WithToolbar
              id={id}
              hasMeetingFileContent={hasMeetingFileContent}
              hasFileContent={hasFileContent}
              isExpand={(props.minInputLine ?? 0) > 1}
            >
              <AttachmentElement
                files={files}
                imageBlocks={imageBlocks}
                setFiles={setFiles}
                onDeleteFile={handleDeleteFile}
                onDeleteImage={handleDeleteImage}
              />
              <InputActionContainer>
                <InputWrapper minInputLine={minInputLine}>
                  <RichEditorFallback
                    id={id}
                    groupId={groupId}
                    disableEditor={disableEditor}
                    autoFocus={autoFocus}
                    editorRef={editorRef}
                    contents={defaultContents}
                    placeholder={placeholder}
                    isMultiline={isMultiline}
                    disableMention={disableMention}
                    disableMentionPortal={disableMentionPortal}
                    disableImageBlockUpload={disableImageBlockUpload}
                    disableVideoBlockUpload={disableVideoBlockUpload}
                    mentionPortalContainer={mentionPortalContainer}
                    maxAttachmentCount={maxAttachmentCount}
                    onFileChange={handleFileChange}
                    onEnter={handleEnter}
                    onChange={handleChange}
                    onChangeCursorFormat={setCurrentCursorFormat}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onCancel={onCancel}
                  />
                </InputWrapper>
              </InputActionContainer>
            </WithToolbar>
          </div>
        </GroupInputContext.Provider>
        {emojiDialog.emojiDialogElement}
      </>
    );
  },
);

export default GroupInput;
