import { Svg24SettingB } from "@moim/icons";
import { getAdminV2Url } from "app/modules/navigationPanel/components/moimConfigMenu/component/moimAdminButton";
import useCurrentGroup from "common/hooks/useCurrentGroup";
import * as React from "react";
import styled from "styled-components";

const StyledSettingIcon = styled(Svg24SettingB).attrs({
  role: "button",
  size: "s",
  touch: 24,
})``;

export default function SettingButton({ channelId }: { channelId: string }) {
  const currentGroup = useCurrentGroup();

  const adminUrl = getAdminV2Url(
    currentGroup?.url,
    currentGroup?.id,
    `/menu/channels/${channelId}`,
  );

  return (
    <a href={adminUrl} target="_blank">
      <StyledSettingIcon />
    </a>
  );
}
