import * as React from "react";
import { Extra, IconWrapper, Title, Wrapper } from "./styled";
import { withPlacement } from "common/components/blockitEditorBase/components/blockitRenderer.v2/hoc/withPlacement";
import { TextComponent } from "common/components/blockitEditorBase/components/blockitRenderer.v2/blocks/texts/component";
import ImageWithIcon from "app/modules/commerce/components/productShow/layout/productSummary/components/right/primaryDetailInformation/image";

type IProps = Omit<Moim.Blockit.V2.IRowTileBlock, "type">;

const RowTile: React.FC<IProps> = ({ title, right, left, extra, href }) => {
  const hrefWithTitle = title.content ? `${href}?title=${title.content}` : href;

  return (
    <Wrapper to={hrefWithTitle}>
      {left && (
        <IconWrapper>
          <ImageWithIcon imageUrl={left.src} />
        </IconWrapper>
      )}
      <Title>
        <TextComponent
          color={title.color}
          align={title.align}
          border={title.border}
          fontWeight={title.fontWeight}
          fontFamily={title.fontFamily}
          letterSpacing={title.letterSpacing}
          subType_v2={title.subType_v2}
        >
          {title.content}
        </TextComponent>
      </Title>
      {extra && <Extra>{extra.content}</Extra>}
      {right && (
        <IconWrapper>
          <ImageWithIcon imageUrl={right.src} />
        </IconWrapper>
      )}
    </Wrapper>
  );
};

export default withPlacement(RowTile);
