export const newThreadDirectionSelector = (
  sortingOption: Moim.Forum.IForumSortingOption,
) => {
  switch (sortingOption.sort) {
    case "createdAt":
    case "updatedAt": {
      return sortingOption.order === "ASC" ? "after" : "before";
    }
    case "voteScore":
    case "upVoteScore":
    case "downVoteScore":
    case "repliesCount": {
      return sortingOption.order === "ASC" ? "before" : "after";
    }
  }
};

export const reorderThreadListSelector = (
  threadList: Moim.Forum.IThread[],
  postedThreadList: Moim.Forum.IThread[],
  sortingOption: Moim.Forum.IForumSortingOption,
) => {
  const allReplies = [...threadList, ...postedThreadList];
  return allReplies.sort((x, y) => {
    let targetFieldName = "created_at";
    switch (sortingOption.sort) {
      case "createdAt":
        targetFieldName = "created_at";
        break;
      case "updatedAt":
        targetFieldName = "updated_at";
        break;
      case "voteScore":
        targetFieldName = "vote_score";
        break;
      case "upVoteScore":
        targetFieldName = "up_vote_score";
        break;
      case "downVoteScore":
        targetFieldName = "down_vote_score";
        break;
      case "repliesCount":
        targetFieldName = "replies_count";
        break;
    }

    if (
      !x.hasOwnProperty(targetFieldName) ||
      !y.hasOwnProperty(targetFieldName)
    ) {
      return 0;
    }

    if (sortingOption.order === "ASC") {
      return x[targetFieldName] - y[targetFieldName];
    } else {
      return y[targetFieldName] - x[targetFieldName];
    }
  });
};
