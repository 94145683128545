import { Svg24ImageG } from "@moim/icons";
import { IAppState } from "app/rootReducer";
import { useStoreState } from "app/store";
import * as React from "react";
import { createSelector } from "reselect";
import styled from "styled-components";

const ImageFallbackIcon = styled(Svg24ImageG).attrs((props) => ({
  size: "s",
  iconColor: props.theme.colorV2.colorSet.grey100,
}))``;

const GreyImage = styled.img`
  width: 30%;
  filter: saturate(0%);
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ImageFallback = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colorV2.colorSet.grey50};
`;

const BasicFallbackResource = () => {
  const { logo } = useStoreState((state) => ({
    logo: state.group.theme.parentLogo ?? state.group.theme.logo,
  }));
  return logo ? (
    <GreyImage className="logo" src={logo} alt="default image" />
  ) : (
    <ImageFallbackIcon />
  );
};

const postItemDefaultImageSelector = createSelector(
  (state: IAppState) => state.group.theme?.parentDefaultImages,
  (state: IAppState) => state.group.theme.defaultImages,
  (parentDefaultImages, currentDefaultImages) => {
    const defaultImages = parentDefaultImages ?? currentDefaultImages ?? [];
    const postItemImages = defaultImages.find((i) => i.type === "postItem");
    if (!postItemImages || !postItemImages.images.length) return;

    const activatedImage = postItemImages.images.find((i) => i.isActive);
    return activatedImage?.image;
  },
);

type IProps = React.PropsWithChildren<{
  type?: string; // NOTE: same as IDefaultImage.type
}>;

export const FallbackImage: React.FC<IProps> = ({
  children: _,
  type,
  ...rest
}) => {
  const postItemDefaultImage = useStoreState((state) =>
    postItemDefaultImageSelector(state),
  );

  const inner = React.useMemo(() => {
    switch (type) {
      case "postItem": {
        return postItemDefaultImage ? (
          <Image src={postItemDefaultImage.src} />
        ) : (
          <BasicFallbackResource />
        );
      }

      default: {
        return <BasicFallbackResource />;
      }
    }
  }, [postItemDefaultImage, type]);

  return React.createElement(ImageFallback, { ...rest }, inner);
};

export default FallbackImage;
