import { Svg18CopyG, Svg24BackB } from "@moim/icons";
import {
  FlatButton,
  GhostButton,
} from "common/components/designSystem/buttons";
import { H8BoldStyle } from "common/components/designSystem/typos";
import { MEDIA_QUERY } from "common/constants/responsive";
import { px2rem } from "common/helpers/rem";
import styled, { css } from "styled-components";

export const AppBarTitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const AppBarStickyWrapperStyle = css`
  background-color: ${(props) => props.theme.colorV2.colorSet.white1000};
`;

export const RootLayoutBodyStyle = css`
  @media ${MEDIA_QUERY.ONLY_MOBILE} {
    height: 100vh;

    div[class^="SecondaryViewWrapper"] {
      display: flex;
      flex-direction: column;
    }
  }
`;

export const DefaultLayoutBodyStyle = css`
  @media ${MEDIA_QUERY.ONLY_MOBILE} {
    height: 100%;

    > div:first-child {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }

  @media ${MEDIA_QUERY.EXCEPT_MOBILE} {
    width: 100%;
    height: 100%;
  }
`;

export const LeftButtonWrapper = styled.div``;

export const BackIcon = styled(Svg24BackB).attrs({
  role: "button",
  size: "s",
  touch: 24,
})``;

export const CopyIcon = styled(Svg18CopyG).attrs((props) => {
  const palette = props.theme.getButtonElementPalette("button");
  const paletteColor = palette.color ?? props.theme.colorV2.primary.main;
  return {
    size: "xs",
    iconColor: paletteColor,
  };
})``;

export const BottomStickyContainer = styled.div`
  cursor: pointer;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${px2rem(8)};
  padding: ${px2rem(8)} ${px2rem(16)};

  border-top: 1px solid ${(props) => props.theme.colorV2.colorSet.grey50};
  background-color: ${(props) => props.theme.colorV2.colorSet.white1000};
  z-index: ${(props) => props.theme.zIndexes.toast};

  @media ${MEDIA_QUERY.ONLY_MOBILE} {
    position: fixed;
  }
`;

export const CopyCodeButton = styled(GhostButton).attrs({
  size: "l",
})`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  gap: ${px2rem(8)};
  padding: ${px2rem(8)} ${px2rem(16)};
  ${H8BoldStyle};
`;

export const ShareLinkButtonWrapper = styled.div`
  flex: 1;
  height: 100%;
`;

export const ShareLinkButton = styled(FlatButton).attrs({
  size: "l",
})`
  width: 100%;
  height: 100%;
  padding: ${px2rem(8)} ${px2rem(16)};
  ${H8BoldStyle};
`;
