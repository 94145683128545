import { Svg24BoldG, Svg24ItalicG, Svg24LinkG, Svg24MentionG, Svg24FileG, Svg24ImageG, Svg24EmojiG, Svg24SendG, Svg18TextstyleG, Svg24VideoChat } from "@moim/icons";
import * as React from "react";
import styled, { ThemeContext } from "styled-components";
// icons











import GroupInputTypes from "common/components/groupInput/type";

const SendActiveIcon = styled(Svg24SendG).attrs(props => ({
  size: "s",
  iconColor: props.theme.colorV2.accent,
}))``;

const SendIcon = styled(Svg24SendG).attrs(props => ({
  size: "s",
  iconColor: props.theme.colorV2.colorSet.grey100,
}))``;

const MARK_RESOURCE: Record<
  GroupInputTypes.ToolButtonType,
  {
    normal: React.ComponentType<any>;
    active: React.ComponentType<any>;
  }
> = {
  bold: {
    normal: Svg24BoldG,
    active: Svg24BoldG,
  },
  italic: {
    normal: Svg24ItalicG,
    active: Svg24ItalicG,
  },
  link: {
    normal: Svg24LinkG,
    active: Svg24LinkG,
  },
  mention: {
    normal: Svg24MentionG,
    active: Svg24MentionG,
  },
  meeting: {
    normal: Svg24VideoChat,
    active: Svg24VideoChat,
  },
  file: {
    normal: Svg24FileG,
    active: Svg24FileG,
  },
  media: {
    normal: Svg24ImageG,
    active: Svg24ImageG,
  },
  emoji: {
    normal: Svg24EmojiG,
    active: Svg24EmojiG,
  },
  send: {
    normal: SendIcon,
    active: SendActiveIcon,
  },

  textStyle: {
    normal: Svg18TextstyleG,
    active: Svg18TextstyleG,
  },
};

interface IProps {
  type: GroupInputTypes.ToolButtonType;
  isActive?: boolean;
  touch?: number;
}

const ToolButton: React.FC<IProps> = ({ type, isActive, touch }) => {
  const theme = React.useContext(ThemeContext);

  const { normal: NormalIcon, active: ActiveIcon } = React.useMemo(
    () => MARK_RESOURCE[type],
    [type],
  );

  return isActive ? (
    <ActiveIcon
      size="s"
      touch={touch}
      role="button"
      iconColor={theme.colorV2.colorSet.grey800}
    />
  ) : (
    <NormalIcon
      size="s"
      touch={touch}
      role="button"
      iconColor={theme.colorV2.colorSet.grey300}
    />
  );
};

export default ToolButton;
