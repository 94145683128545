import { Svg8LikeXxs } from "@moim/icons";
import * as React from "react";
import styled from "styled-components";
import { px2rem } from "common/helpers/rem";
import useUserFetchSelector from "common/hooks/useEntitiesFetchSelectors/useUserFetchSelector";
import UserProfileImage from "common/components/userProfileImage";



export const SmallLikedIcon = styled(Svg8LikeXxs).attrs(props => ({
  size: px2rem(8),
  iconColor: props.theme.getReactionButtonElementPalette("like").color,
}))``;

const PlaceContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${px2rem(18)};
  height: ${px2rem(18)};
  margin-left: ${px2rem(8)};
`;

const VoteIconPlacement = styled.div`
  position: absolute;
  bottom: 0;
  right: ${px2rem(-2)};

  display: flex;
  align-items: center;
  justify-content: center;

  width: ${px2rem(8)};
  height: ${px2rem(8)};
`;

interface IProps {
  originalAuthorId?: Moim.Id;
  hasAuthorVote?: boolean;
}

const OriginalAuthorVoteComponent: React.FC<IProps> = ({
  originalAuthorId,
  hasAuthorVote,
}) => {
  const { users } = useUserFetchSelector(
    originalAuthorId ? [originalAuthorId] : [],
  );
  const originalAuthor = users?.[0] as Moim.User.IUser;

  if (!originalAuthor || !originalAuthorId || !hasAuthorVote) {
    return null;
  }

  return (
    <PlaceContainer>
      <UserProfileImage
        size="xs"
        canOpenProfileDialog={false}
        userId={originalAuthor.id ?? ""}
        src={originalAuthor.avatar_url ?? ""}
        title={originalAuthor.name}
      />
      <VoteIconPlacement>
        <SmallLikedIcon />
      </VoteIconPlacement>
    </PlaceContainer>
  );
};

export default OriginalAuthorVoteComponent;
