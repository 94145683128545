import * as React from "react";
import { useActions } from "app/store";
import { getSearchUserThreadsCount } from "app/actions/user";
import { THREAD_TYPE } from "app/typings/thread";

// let bufferedInst: ((arg: string) => Promise<void>) | undefined;

export default function useBufferedUserPostingCountFetch(): (
  userId: Moim.Id,
  types: THREAD_TYPE[],
) => void {
  const { dispatchGetSearchThreadsCount } = useActions({
    dispatchGetSearchThreadsCount: getSearchUserThreadsCount,
  });

  const handler = React.useCallback(
    async (userId: Moim.Id, types: THREAD_TYPE[]) => {
      if (userId) {
        dispatchGetSearchThreadsCount({
          types,
          userId,
        });
      }
    },
    [dispatchGetSearchThreadsCount],
  );

  const dispatch = React.useCallback(
    (userId: Moim.Id, types: THREAD_TYPE[]) => {
      /**
       * 간헐적으로 Count 가 정상 로딩 되지 않는 이슈가 있어서, 우선 해당 Buffer 로직을 사용하지 않도록 함
       */
      // if (bufferedInst) {
      //   bufferedInst(userId);
      // } else {
      //   bufferedInst = buffer({
      //     ms: 150,
      //     async subscribedFn(mergedIds: Moim.Id[]) {
      //       uniq(mergedIds).forEach(id => {
      //         handler(id, types);
      //       });
      //     },
      //   });
      //   bufferedInst(userId);
      // }
      handler(userId, types);
    },
    [handler],
  );

  return dispatch;
}
