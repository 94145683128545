import { useActions, useStoreState } from "app/store";

import useCancelToken from "common/hooks/useCancelToken";
import useCurrentGroup from "common/hooks/useCurrentGroup";
import useGroupTexts from "common/hooks/useGroupTexts";

import { getAuthentication } from "common/helpers/authentication/actions";
import { ActionCreators as GroupActionCreators } from "app/actions/group";
import { currentGroupSelector } from "app/selectors/app";
import { useMemo } from "react";
import selectHubMoimId from "common/helpers/selectHubMoimId";
import { useStoreSecondaryView } from "common/hooks/useSecondaryView";
import { UserStatusTypes } from "app/enums";
import { getSignUpConfig2Step } from "common/components/dialogs/joinGroupDialog/container/current";
import useCurrentHubGroup from "common/hooks/useCurrentHubGroup";

export type IHookProps = ReturnType<typeof useProps>;

export function useProps() {
  const storeSecondaryViewHandler = useStoreSecondaryView();
  const actions = useActions({
    dispatchGetAuthentication: getAuthentication,
    openJoinGroupDialog: GroupActionCreators.openJoinGroupDialog,
  });

  const { parentUser, ...state } = useStoreState(storeState => ({
    currentGroup: currentGroupSelector(storeState),
    parentUser: storeState.app.parentMoimUser,
    hubGroupId: selectHubMoimId(storeState),
  }));
  const hubGroup = useCurrentHubGroup();

  const cancelToken = useCancelToken();
  const currentGroup = useCurrentGroup();
  const joinButtonText = useGroupTexts("join_button");
  const parentSigned = Boolean(!state.currentGroup?.is_hub && parentUser);

  const joinDialogType: Moim.Group.JoinGroupDialogType = useMemo(() => {
    if ((state.currentGroup?.is_hub && !parentUser) || parentSigned) {
      if (parentUser?.status === UserStatusTypes.ACTIVE) {
        return "current";
      }
      return "parent";
    }
    return "current";
  }, [parentUser, parentSigned, state.currentGroup]);

  const initialStep = useMemo(() => {
    if (parentSigned) {
      switch (parentUser?.status) {
        case UserStatusTypes.PENDING: {
          const steps = getSignUpConfig2Step(hubGroup?.sign_up_config_v2);
          const initStep = steps[
            steps.findIndex(
              step => step?.key === parentUser.signUpInfo.finishedStep,
            ) + 1
          ]?.key as Moim.Group.JoinGroupDialogStepType;

          if (initStep) {
            return initStep;
          }
          break;
        }
        case UserStatusTypes.WAITING_FOR_APPROVAL:
        case UserStatusTypes.REJECTED:
          return undefined;
      }
    }
    return undefined;
  }, [hubGroup?.sign_up_config_v2, parentSigned, parentUser]);

  return {
    ...actions,
    ...state,
    storeSecondaryViewHandler,
    joinButtonText,
    cancelToken,
    currentGroup,
    joinDialogType,
    initialStep,
  };
}
