import { Svg18RightarrowG, Svg24ArrowG } from "@moim/icons";
import styled from "styled-components";


import { H4BoldStyle } from "common/components/designSystem/typos";
import LoadingIconBase from "common/components/loading/icon";
import { MEDIA_QUERY } from "common/constants/responsive";
import { px2rem } from "common/helpers/rem";

export const Divider = styled.div`
  width: 100%;
  height: ${px2rem(4)};
  margin: 0;
  border: none;
  background-color: ${props => props.theme.colorV2.colorSet.grey10};
  border-bottom: 1px solid ${props => props.theme.colorV2.colorSet.grey50};
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  @media ${MEDIA_QUERY.ONLY_MOBILE} {
    ${H4BoldStyle}
  }

  @media ${MEDIA_QUERY.EXCEPT_MOBILE} {
    ${H4BoldStyle}
  }
`;

export const DividerWrapper = styled.div`
  padding-bottom: ${px2rem(4)};
`;

export const LoadWrapper = styled.div`
  width: 100%;
  height: ${px2rem(42)};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ItemContainer = styled.div`
  padding-bottom: ${px2rem(12)};
  img {
    height: 100%;
  }

  > div {
    height: 100%;
  }
`;

export const MoreButtonIcon = styled(Svg18RightarrowG).attrs(props => ({
  size: "xs",
  iconColor: props.theme.colorV2.colorSet.grey800,
}))``;

export const ViewMoreIcon = styled(Svg24ArrowG).attrs(props => ({
  size: "s",
  iconColor: props.theme.colorV2.colorSet.grey800,
}))``;

export const Loading = styled(LoadingIconBase)``;
