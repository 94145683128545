import AcceptLanguageParser from "common/helpers/acceptLanguageParser";
import { browserLocale } from "app/intl";
import { useCurrentUserLocale } from "./useCurrentUserLocale";

export function getLocaleText<T extends { locale: string }>(
  textSet: T[] | undefined,
  options?: {
    values?: Record<string, string | number>;
    locale?: string;
  },
): T | undefined {
  const key = AcceptLanguageParser.pick(
    textSet
      ?.map(locale => locale.locale)
      ?.sort((a, b) => b.length - a.length) ?? [],
    browserLocale(options?.locale),
  );

  return textSet?.find(text => text.locale === key);
}

export function getLocaleTextShort(
  entity: Record<string, any>,
  key: string,
  locale,
) {
  return (
    getLocaleText<Moim.Localization.ILocaleText>(entity[`${key}Locale`], {
      locale,
    })?.value ?? entity[key]
  );
}

export function getLocaleContentShort(
  entity: Record<string, any>,
  key: string,
  locale,
) {
  return (
    getLocaleText<Moim.Localization.ILocaleContent>(entity[`${key}Locale`], {
      locale,
    })?.content ?? entity[key]
  );
}

export function useLocaleText(entity: Record<string, any>, key: string) {
  const locale = useCurrentUserLocale();

  return getLocaleTextShort(entity, key, locale);
}
