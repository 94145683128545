import { Svg24Uploadphoto } from "@moim/icons";
import { px2rem } from "common/helpers/rem";
import styled from "styled-components";

export const Wrapper = styled.div<{ width: number; height: number }>`
  position: relative;
  width: ${(props) => px2rem(props.width)};
  height: ${(props) => px2rem(props.height)};
`;

export const IconWrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
`;

export const UploadPhotoButton = styled(Svg24Uploadphoto).attrs({
  size: "s",
})``;

export const BigUploadPhotoButton = styled(Svg24Uploadphoto).attrs({
  size: "m",
})``;

export const LoadWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;
