import { Svg18RightarrowG } from "@moim/icons";
import styled from "styled-components";
import { px2rem } from "common/helpers/rem";
import { B4Regular, H8Bold } from "common/components/designSystem/typos";


export const TitleSection = styled.div`
  width: 100%;
  padding-inline: ${px2rem(16)};
  display: flex;
  align-items: center;
`;

export const Title = styled(H8Bold)`
  color: ${props => props.theme.colorV2.colorSet.grey800};
  padding: ${px2rem(11)} 0;
  margin-right: ${px2rem(3)};
  flex: 1;
  min-width: 0;
`;

export const Count = styled(B4Regular)`
  color: ${props => props.theme.colorV2.colorSet.grey300};
`;

export const RightArrow = styled(Svg18RightarrowG).attrs(props => ({
  size: "xs",
  iconColor: props.theme.colorV2.colorSet.grey300,
}))``;
