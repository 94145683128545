// vendor
import * as React from "react";
import { FormattedMessage } from "react-intl";
// component
import { MenuItem } from "common/components/responsiveMenu/components/menu";
import {
  MenuText,
  BookmarkIcon,
  DraftMenuIcon,
  UnlockContentsIcon,
} from "./styled";

import { useActions } from "app/store";
import { ActionCreators as DraftActionCreators } from "app/actions/draft";
import { MoimURL } from "common/helpers/url";
import useGroupTexts from "common/hooks/useGroupTexts";
import useCurrentGroup from "common/hooks/useCurrentGroup";
import useRedirect from "common/hooks/useRedirect";

interface IProps {
  userId: Moim.Id;
  requestClose(): void;
}

const Menus: React.FC<IProps> = ({ userId, requestClose }) => {
  const redirect = useRedirect();
  const currentGroup = useCurrentGroup();
  const unlockedMenuTexts = useGroupTexts(
    "profile_show_contents_menu_unlocked_posts",
  );
  const { openDraftListModal } = useActions({
    openDraftListModal: DraftActionCreators.openDraftListModal,
  });

  const handleBookmarkClick = React.useCallback(() => {
    requestClose();
    redirect(new MoimURL.UserBookmark({ userId }).toString());
  }, [userId, redirect, requestClose]);

  const handleDraftOpen = React.useCallback(() => {
    requestClose();
    openDraftListModal();
  }, [requestClose]);

  const handleLockContents = React.useCallback(() => {
    requestClose();
    redirect(new MoimURL.UserUnlockedContents({ userId }).toString());
  }, [requestClose, redirect, userId]);

  return (
    <>
      {[
        currentGroup?.config.enableLockContent ? (
          <MenuItem onClick={handleLockContents}>
            <UnlockContentsIcon />
            <MenuText>{unlockedMenuTexts.singular}</MenuText>
          </MenuItem>
        ) : (
          undefined
        ),
        <MenuItem onClick={handleBookmarkClick}>
          <BookmarkIcon />
          <MenuText>
            <FormattedMessage id="profile_show/contents_menu_bookmarks" />
          </MenuText>
        </MenuItem>,
        <MenuItem key="draft_list_open" onClick={handleDraftOpen}>
          <DraftMenuIcon />
          <MenuText>
            <FormattedMessage id="menu_draft_list" />
          </MenuText>
        </MenuItem>,
      ].filter(i => Boolean(i))}
    </>
  );
};

export default Menus;
