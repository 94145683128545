import useEntitiesFetchSelector from "./useEntitiesFetchSelector";

export default function useThreadFetchSelector(requestIds: Moim.Id[]) {
  const { isLoading, entities } = useEntitiesFetchSelector({
    threads: requestIds,
  });

  return {
    isLoading,
    threads: (entities.threads ?? []) as Moim.Forum.IThread[],
  };
}
