import * as React from "react";

// component
import {
  PortalLeftArrow,
  PortalRightArrow,
} from "common/components/horizontalScroll/arrows";
import BlockitListLayout from "common/components/blockitListLayout";
import { ItemContainer, ListWrapper } from "../../styled";
import PostItem from "../postCell";
import { PostCellSkeleton } from "../postCell/skeleton";

import { useParseListElementConfig } from "common/components/blockitListLayout/hooks/useParseListElementConfig";
import useIsMobile from "common/hooks/useIsMobile";
import { BlockitFeedback } from "../../../../components/feedback";
import { getEstimatedWidth } from "../../../../helper/srcGetter";
import {
  BlockitRendererContextProvider,
  useBlockitRendererContext,
} from "../../../../context";
import { DEFAULT_GAP } from "common/components/blockitListLayout/layouts/horizontalScroll/styled";

interface IProps {
  isLoading: boolean;
  portalSectionId?: string;
  threads: Moim.Forum.IThread[] | undefined;
  listElement: Moim.Blockit.V2.IListStyleElement;
  listShowConfig: Moim.Blockit.V2.IPostListShowConfig;
}

const ContentsGroupItemList: React.FC<IProps> = ({
  isLoading,
  portalSectionId,
  threads,
  listElement,
  listShowConfig,
}) => {
  const isMobile = useIsMobile();

  const {
    column,
    listElementType,
    maxVisibleCount,
  } = useParseListElementConfig(listElement);

  const slicedThreads = React.useMemo(
    () => threads?.slice(0, maxVisibleCount),
    [threads, maxVisibleCount],
  );

  const { estimatedWidth } = useBlockitRendererContext();
  const calculatedWidth = getEstimatedWidth(
    listElement,
    undefined,
    estimatedWidth,
  );

  const itemElements = React.useMemo(() => {
    const isUndefinedArray = slicedThreads?.some(
      thread => thread?.id === undefined,
    );
    if (slicedThreads === undefined || isLoading || isUndefinedArray) {
      const suggestArrayLength =
        column *
        (isMobile || !listElement.rowCount_web
          ? listElement.rowCount
          : listElement.rowCount_web) *
        (listElementType === "horizontal" ? 2 : 1);
      return new Array(
        isNaN(suggestArrayLength) ? (isMobile ? 1 : 2) : suggestArrayLength,
      )
        .fill(0)
        .map((_, idx) => (
          <ItemContainer key={`contents_group_skeleton_${idx}`}>
            <PostCellSkeleton
              viewType={
                isMobile || !listShowConfig.viewType_web
                  ? listShowConfig.viewType
                  : listShowConfig.viewType_web
              }
              showThumbnail={listShowConfig.showThumbnail}
              thumbnailConfig={listShowConfig.thumbnailConfig}
            />
          </ItemContainer>
        ));
    }

    const displayRowBorder = listShowConfig.viewType === "post";
    const gapSize =
      isMobile || !listElement.itemGutterSize_web
        ? listElement.itemGutterSize
        : listElement.itemGutterSize_web;

    return slicedThreads.map(thread => (
      <BlockitRendererContextProvider estimatedWidth={calculatedWidth}>
        <ItemContainer
          key={`contents_group_${thread.id}`}
          displayRowBorder={displayRowBorder}
          gapSize={gapSize ?? DEFAULT_GAP}
        >
          <PostItem thread={thread} config={listShowConfig as any} />
        </ItemContainer>
      </BlockitRendererContextProvider>
    ));
  }, [
    column,
    slicedThreads,
    listElementType,
    isLoading,
    isMobile,
    listElement.rowCount_web,
    listElement.rowCount,
    listShowConfig,
  ]);

  return (
    <ListWrapper>
      {!isLoading && threads !== undefined && !threads.length ? (
        <BlockitFeedback.Empty textKey="content_group_list_preview_empty" />
      ) : (
        <BlockitListLayout
          verticalScrollLayoutProps={{
            disappearBottomDivider: true,
          }}
          element={listElement}
          rightArrow={
            listElementType === "horizontal" ? PortalRightArrow : undefined
          }
          leftArrow={
            listElementType === "horizontal" ? PortalLeftArrow : undefined
          }
          arrowPortalTargetId={portalSectionId}
        >
          {itemElements}
        </BlockitListLayout>
      )}
    </ListWrapper>
  );
};

export default React.memo(ContentsGroupItemList);
