import React from "react";
import { VoteStatus } from "app/enums";
import {
  StyledBuyersCount,
  StyledDescription,
  StyledImage,
  StyledProductName,
  StyledProgressBar,
  StyledSoldAmount,
  StyledGoalAmount,
  StyledSeller,
  StyledStat,
  StyledStatus,
  StyledTimeSaleTimer,
  StyledBuyNowButton,
  StyledFundingItemWrapper,
} from "./common";
import { useIntlShort } from "common/hooks/useIntlShort";

const FundingItemElementRenderer: React.FC<{
  product: Moim.Commerce.IProduct;
  block: Moim.Component.ProductItem.ProductCellBlockType;
  wrapperBlock: Moim.Component.ProductItem.IWrapper;
  onClickLikeButtonClick?(nextStatus: boolean): void;
  onSellerSelect?(): void;
  onBuyNowSelect?(): void;
  onAddToCartSelect?(): void;
}> = ({
  product,
  block,
  wrapperBlock,
  onClickLikeButtonClick,
  onSellerSelect,
  onBuyNowSelect,
}) => {
  const intl = useIntlShort();
  switch (block.type) {
    case "wrapper":
      return <StyledFundingItemWrapper block={block} product={product} />;
    case "image":
      return (
        <StyledImage
          block={block}
          productId={product.id}
          isFavorite={product.vote?.type === VoteStatus.POSITIVE}
          images={product.images}
          productSets={product.productSets}
          onClickLikeButtonClick={onClickLikeButtonClick}
        />
      );
    case "timer":
      return (
        <StyledTimeSaleTimer
          product={product}
          block={block}
          horizontalAlign={wrapperBlock.horizontalAlign}
        />
      );
    case "product-name":
      return (
        <StyledProductName
          horizontalAlign={wrapperBlock.horizontalAlign}
          block={block}
          name={product.name}
        />
      );

    case "description":
      return (
        <StyledDescription
          horizontalAlign={wrapperBlock.horizontalAlign}
          block={block}
          description={product.description}
        />
      );
    case "progress-bar": {
      const isCountType = product.goalType === "count";
      return (
        <StyledProgressBar
          block={block}
          soldAmount={!isCountType ? product.soldAmount : product.soldCount}
          goalAmount={!isCountType ? product.goalAmount : product.goalCount}
        />
      );
    }
    case "sold-amount": {
      const isCountType = product.goalType === "count";
      return (
        <StyledSoldAmount
          horizontalAlign={wrapperBlock.horizontalAlign}
          block={block}
          currency={
            !isCountType
              ? product.soldAmount_price.currency
              : intl("funding_goal_count_type_unit")
          }
          soldAmount={
            !isCountType ? product.soldAmount_price.numValue : product.soldCount
          }
          goalAmount={
            !isCountType
              ? product.goalAmount_price?.numValue
              : product.goalCount
          }
        />
      );
    }
    case "goal-amount": {
      const isCountType = product.goalType === "count";
      return (
        <StyledGoalAmount
          horizontalAlign={wrapperBlock.horizontalAlign}
          block={block}
          goalType={product.goalType}
          currency={
            !isCountType
              ? product.goalAmount_price?.currency
              : intl("funding_goal_count_type_unit")
          }
          goalAmount={
            !isCountType ? product.goalAmount_price?.value : product.goalCount
          }
        />
      );
    }

    case "seller":
      return (
        <StyledSeller
          horizontalAlign={wrapperBlock.horizontalAlign}
          block={block}
          sellerId={product.sellerId}
          onSelect={onSellerSelect}
        />
      );
    case "buyers-count":
      return (
        <StyledBuyersCount
          horizontalAlign={wrapperBlock.horizontalAlign}
          block={block}
          buyersCount={product.buyersCount}
        />
      );

    case "status":
      return (
        <StyledStatus
          horizontalAlign={wrapperBlock.horizontalAlign}
          block={block}
          productType={product.type}
          status={product.status}
        />
      );

    case "stat":
      return (
        <StyledStat
          horizontalAlign={wrapperBlock.horizontalAlign}
          block={block}
          viewCount={product.view_count}
          voteScore={product.vote_score}
          commentsCount={product.commentsCount}
          repliesCount={product.repliesCount}
          reviewsCount={product.reviewsCount}
        />
      );

    case "buy-now-button":
      return (
        <StyledBuyNowButton
          block={block}
          product={product}
          onSelect={onBuyNowSelect}
        />
      );

    default:
      return null;
  }
};

export default FundingItemElementRenderer;
