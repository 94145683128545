import { Svg24DotmenuB, Svg18DotmenuB } from "@moim/icons";
// vendor
import styled from "styled-components";
// component




// TODO: icon 색이 이상하게 먹어서 fix된 색을 넣어둠
export const MoimListIcon = styled(Svg24DotmenuB).attrs(props => ({
  size: "s",
  iconColor: props.theme.colorV2.colorSet.grey300,
}))``;

export const SmallMoimListIcon = styled(Svg18DotmenuB).attrs(props => ({
  size: "xs",
  iconColor: props.theme.colorV2.colorSet.grey300,
}))``;
