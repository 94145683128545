import * as React from "react";

import { Wrapper, PropertiesWrapper } from "./styled";
import Product from "./components/product";

import { Properties } from "./components/properties";

import { useStoreState } from "app/store";
import { Thread } from "app/typings";

type IProps = Thread.IThreadItemBaseProps;

export function ReviewPreviewItem({
  threadId,
  config,
  className,
  disableAnonymousSuffix,
}: IProps) {
  const thread = useStoreState(
    state =>
      state.entities.threads[threadId] as Moim.Forum.IThread<any> | undefined,
  );

  const productData = useStoreState(state => {
    if (thread && thread.meta) {
      if (thread.meta.purchaseItemSnap) {
        return {
          productName: thread.meta?.purchaseItemSnap.productName,
          productImage: thread.meta?.purchaseItemSnap?.productImage?.mobile,
        };
      }

      if (thread.meta.productId) {
        const product = state.entities.commerce_product[thread.meta.productId];

        return {
          productName: product?.name,
          productImage:
            product?.images?.mobile?.[0]?.srcXs ??
            product?.images?.mobile?.[0]?.src,
        };
      }
    }

    return null;
  });

  if (!thread) {
    return null;
  }

  return (
    <Wrapper
      thumbnailPosition={config?.thumbnailConfig?.position}
      className={className}
    >
      <PropertiesWrapper>
        <Properties
          config={config}
          thread={thread}
          disableAnonymousSuffix={disableAnonymousSuffix}
        />
      </PropertiesWrapper>
      {productData && (
        <Product
          productImage={productData.productImage}
          productName={productData.productName}
        />
      )}
    </Wrapper>
  );
}
