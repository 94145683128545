import useRedirect from "./useRedirect";
import { useCallback, useMemo } from "react";
import useCurrentGroup from "./useCurrentGroup";
import getDefaultChannelUrl from "common/helpers/getChannelUrl";
import { MoimURL } from "common/helpers/url";
import { getInitialData } from "common/helpers/initialData";
import { useRequestId } from "./useRequestId";

function useRedirectDefaultChannel() {
  const redirect = useRedirect();
  const redirectDefaultChannel = useCallback(() => {
    redirect(new MoimURL.MoimAppHome().toString());
  }, [redirect]);
  return redirectDefaultChannel;
}

export default useRedirectDefaultChannel;

export function useHomeUrl() {
  const currentGroup = useCurrentGroup();
  const requestId = useRequestId();

  return useMemo(() => {
    let redirectUrl: string | undefined = new MoimURL.MoimAppHome().toString();

    // eslint-disable-next-line no-underscore-dangle
    const __homeChannel = getInitialData("__homeChannel", requestId);

    if (__homeChannel) {
      redirectUrl = getDefaultChannelUrl({
        id: __homeChannel.data.id,
        type: __homeChannel.data.type,
      });
    }

    if (currentGroup) {
      switch (currentGroup.home.web.type) {
        case "channel":
          redirectUrl = getDefaultChannelUrl(currentGroup.home.web.ref);
          break;
        case "cover":
          redirectUrl = new MoimURL.CoverPage().toString();
          break;
      }
    }
    return redirectUrl ?? new MoimURL.MoimAppHome().toString();
  }, [currentGroup]);
}
