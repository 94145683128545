import * as React from "react";
import { FormattedMessage } from "react-intl";
import { useLongPress } from "react-use";
import { css } from "styled-components";
import { MediaPreview } from "common/components/designSystem/media";
import LinkPreviewComponent from "common/components/linkPreview";
import { useOpenPostReportDialog } from "common/components/reportDialog/presets/post/hooks";
import ResponsiveMenu from "common/components/responsiveMenu";
import { MoreIcon, MoreMenuItem, ResponsiveMenuWrapper } from "./styled";
import { HoverMenuItem, NestedReply } from "common/components/threadV2";
import DeleteCommentAlertDialog from "app/modules/postShow/components/bottom/components/commentList/commentItem/components/deleteCommentAlertDialog";
import { useActions, useStoreState } from "app/store";
import { Share } from "common/components/snsShareDialog/utils";
import useCancelToken from "common/hooks/useCancelToken";
import useOpenState from "common/hooks/useOpenState";
import useGroupTexts from "common/hooks/useGroupTexts";
import { useCurrentUserLocale } from "common/hooks/localization/useCurrentUserLocale";
import { usePostShowPermission } from "app/modules/postShow/hooks";

import {
  ActionCreators as ForumActionCreators,
  voteReply,
} from "app/actions/forum";
import makeShareUrl from "common/helpers/makeShareUrl";
import { MoimURL } from "common/helpers/url";
import { VoteStatus } from "app/enums";
import { AnalyticsClass } from "common/helpers/analytics/analytics";
import { px2rem } from "common/helpers/rem";

interface IProps {
  focusedId?: Moim.Id;
  thread: Moim.Forum.IThread;
  isHover: boolean;
  commentReactionType: Moim.Forum.PostReactionType;
  showCommentReaction: boolean;
  showOriginalAuthorOnReply: boolean;
}

const ReplyItemComponent = React.forwardRef<HTMLDivElement | null, IProps>(
  (
    {
      thread,
      focusedId,
      isHover,
      commentReactionType,
      showCommentReaction,
      showOriginalAuthorOnReply,
    },
    forwardedRef,
  ) => {
    const replyRef = React.useRef<HTMLDivElement>(null);
    const highlighted = thread.id === focusedId;
    const {
      author,
      isAuthor,
      currentUserId,
      disableLike,
      commentEditState,
      isAnonymousReaction,
      originalThreadAuthorId,
      originalThreadId,
    } = useStoreState(state => ({
      author: thread ? state.entities.users[thread.author] : undefined,
      isAuthor:
        thread.author === state.app.currentUserId ||
        Boolean(thread?.anonymous_data?.isMine),
      originalThreadAuthorId:
        thread && showOriginalAuthorOnReply
          ? state.entities.threads[
              state.entities.threads[thread.parent_id]?.parent_id
            ]?.author
          : undefined,
      originalThreadId: thread
        ? state.entities.threads[thread.parent_id]?.parent_id
        : undefined,
      currentUserId: state.app.currentUserId,
      commentEditState: state.forumCommentListPage.commentEditState,
      disableLike: state.forumCommentListPage.isLoadingToCommentLike,
      isAnonymousReaction: thread?.root_id
        ? Boolean(
            (state.entities.channels[
              thread.root_id
            ] as Moim.Channel.IForumSimpleChannel)?.anonymous_config?.reaction,
          )
        : false,
    }));

    const anonymousTextKey = useGroupTexts("anonymous_member");
    const locale = useCurrentUserLocale();

    const { dispatchChangeCommentEditStatus, dispatchVoteReply } = useActions({
      dispatchChangeCommentEditStatus:
        ForumActionCreators.changeCommentEditState,
      dispatchVoteReply: voteReply,
    });

    const refMoreMenu = React.useRef<HTMLDivElement>(null);
    const cancelToken = useCancelToken();
    const {
      isOpen: isOpenResponsiveMenu,
      open: handleOpenMenu,
      close: handleCloseMenu,
    } = useOpenState();
    const {
      isOpen: isDeleteAlertOpen,
      open: openDeleteAlert,
      close: closeDeleteAlert,
    } = useOpenState();
    const longPressEvent = useLongPress(
      () => {
        handleOpenMenu();
      },
      {
        isPreventDefault: false,
        delay: 500,
      },
    );

    const { hasPermission: votePermission } = usePostShowPermission(
      "COMMENT_VOTE",
      undefined,
      originalThreadId,
    );
    const { hasPermission: deletePermission } = usePostShowPermission(
      "DELETE_COMMENT",
      thread.author,
      originalThreadId,
    );
    const { hasPermission: editPermission } = usePostShowPermission(
      "MANAGE_COMMENT",
      thread.author,
      originalThreadId,
    );

    const hasVotePermission = React.useMemo(
      () => votePermission || Boolean(thread.anonymous_data?.isMine),
      [votePermission, thread.anonymous_data?.isMine],
    );
    const hasDeletePermission = React.useMemo(
      () => deletePermission || Boolean(thread.anonymous_data?.isMine),
      [deletePermission, thread.anonymous_data?.isMine],
    );
    const hasEditPermission = React.useMemo(
      () => editPermission || Boolean(thread.anonymous_data?.isMine),
      [editPermission, thread.anonymous_data?.isMine],
    );

    const handleMenuClick = React.useCallback(() => {
      handleOpenMenu();
    }, [handleOpenMenu]);

    const fileContents = React.useMemo(
      () =>
        thread.content.filter(
          content => content.type === "file",
        ) as Moim.Blockit.IFileBlock[],
      [thread],
    );
    const mediaProps = React.useMemo(() => {
      if (fileContents.length && fileContents[0].files.length) {
        const file = fileContents[0].files[0];
        return {
          fileId: file.id,
          readonly: true,
          isSmallDeleteButton: true,
        } as React.ComponentProps<typeof MediaPreview>;
      }
      return undefined;
    }, [fileContents]);

    const linkPreviewContents = React.useMemo(
      () =>
        thread.content.filter(
          content => content.type === "link-preview",
        ) as Moim.Blockit.ILinkPreviewBlock[],
      [thread.content],
    );

    const linkPreviewProps:
      | React.ComponentProps<typeof LinkPreviewComponent>
      | undefined = React.useMemo(() => {
      if (linkPreviewContents.length && linkPreviewContents[0]) {
        const data = linkPreviewContents[0];
        return {
          readOnly: true,
          favicon: data.site?.icon,
          siteName: data.site?.name,
          url: data.url,
          title: data.title,
          description: data.description,
          image: data.thumb?.url,
          embed: data.embed,
        };
      }
      return undefined;
    }, [linkPreviewContents]);

    const menus = React.useMemo(
      () => [
        <HoverMenuItem ref={refMoreMenu} onClick={handleMenuClick}>
          <MoreIcon />
        </HoverMenuItem>,
      ],
      [handleMenuClick, refMoreMenu],
    );

    const shareUrl = React.useMemo(() => {
      if (thread.root_id && originalThreadId) {
        return makeShareUrl(
          new MoimURL.FocusedShowSubReply({
            forumId: thread.root_id,
            threadId: originalThreadId,
            commentId: thread.parent_id,
            focusedId: thread.id,
          }).toString(),
        );
      }
    }, [thread.root_id, thread.parent_id, thread.id, originalThreadId]);

    const handleOpenReportDialog = useOpenPostReportDialog({
      parentId: thread.author,
      threadId: thread.id,
    });

    const handleVote = React.useCallback(
      (status: Moim.Enums.VoteStatus) => {
        if (!disableLike && thread.root_id && currentUserId) {
          dispatchVoteReply(
            {
              channelId: thread.root_id,
              threadId: thread.parent_id,
              replyId: thread.id,
              type: status,
              cancelToken: cancelToken.current.token,
            },
            thread.groupId,
          );

          if (status === null) {
            AnalyticsClass.getInstance().forumPostCommentReplyReactCancel({
              replyId: thread.id,
              reactType: thread.vote?.type ?? "upvote",
            });
          } else {
            AnalyticsClass.getInstance().forumPostCommentReplyReact({
              replyId: thread.id,
              reactType: status,
            });
          }
        }
      },
      [
        disableLike,
        currentUserId,
        thread.root_id,
        thread.groupId,
        thread.parent_id,
        thread.id,
      ],
    );

    const handleClickCopy = React.useCallback(() => {
      const textRowNode = replyRef?.current?.querySelector<HTMLElement>(
        ".nested-reply-text-row",
      );
      navigator.clipboard.writeText(textRowNode?.innerText ?? "");
      handleCloseMenu();
    }, [handleCloseMenu]);

    const handleClickDelete = React.useCallback(() => {
      openDeleteAlert();
      handleCloseMenu();
    }, [openDeleteAlert, handleCloseMenu]);

    const handleClickEditComment = React.useCallback(() => {
      if (thread.root_id) {
        dispatchChangeCommentEditStatus({
          commentId: thread.id,
          threadId: thread.parent_id,
          channelId: thread.root_id,
          groupId: thread.groupId,
        });
        handleCloseMenu();
      }
    }, [
      thread.parent_id,
      thread.root_id,
      thread.id,
      thread.groupId,
      dispatchChangeCommentEditStatus,
      handleCloseMenu,
    ]);

    const handleSubmitEditComment = React.useCallback(() => {
      dispatchChangeCommentEditStatus(undefined);
    }, [dispatchChangeCommentEditStatus]);

    const handleCancelEditComment = React.useCallback(() => {
      dispatchChangeCommentEditStatus(undefined);
    }, [dispatchChangeCommentEditStatus]);

    const engageProps: PickValue<
      React.ComponentProps<typeof NestedReply>,
      "engage"
    > = React.useMemo(() => {
      if (!showCommentReaction || !originalThreadId) {
        return undefined;
      }

      if (commentReactionType === "up") {
        return {
          type: "like",
          disabled: !hasVotePermission || disableLike,
          liked: thread.vote?.type === VoteStatus.POSITIVE,
          likeCount: thread.vote_score,
          handleLike: handleVote,
          channelId: thread?.root_id,
          threadId: originalThreadId,
          replyId: thread.id,
          groupId: thread.groupId,
          disableOpenVotedUserList: isAnonymousReaction,
          originalAuthorId: originalThreadAuthorId,
          hasAuthorVote: thread.hasAuthorVote,
        };
      }

      return {
        type: "updown",
        upCount: thread?.up_vote_score,
        downCount: thread?.down_vote_score,
        channelId: thread?.root_id,
        threadId: originalThreadId,
        replyId: thread.id,
        groupId: thread.groupId,
        disabled: !hasVotePermission || disableLike,
        status: thread.vote?.type ?? VoteStatus.NONE,
        handler: handleVote,
        disableOpenVotedUserList: isAnonymousReaction,
        originalAuthorId: originalThreadAuthorId,
        hasAuthorVote: thread.hasAuthorVote,
      };
    }, [
      originalThreadId,
      originalThreadAuthorId,
      showCommentReaction,
      commentReactionType,
      thread,
      hasVotePermission,
      disableLike,
      handleVote,
      isAnonymousReaction,
    ]);

    const avatarProps = React.useMemo(
      () =>
        author
          ? {
              userId: author.id,
              src: author.avatar_url || "",
              title: author.name,
              role: "button",
              isOnline: author.presence === "ACTIVE",
              isOriginalAuthor:
                showOriginalAuthorOnReply && thread.isOriginalAuthor,
            }
          : isAnonymousReaction
          ? {}
          : undefined,
      [
        author,
        isAnonymousReaction,
        showOriginalAuthorOnReply,
        thread.isOriginalAuthor,
      ],
    );

    const headWrapperStyle = React.useMemo(
      () =>
        showOriginalAuthorOnReply && thread.isOriginalAuthor
          ? css`
              margin-bottom: ${px2rem(4)};
            `
          : undefined,
      [showOriginalAuthorOnReply, thread.isOriginalAuthor],
    );

    React.useImperativeHandle(forwardedRef, () => replyRef?.current!);

    return (
      <>
        <NestedReply
          ref={replyRef}
          key={thread.id}
          size="s"
          replyId={thread.id}
          engage={engageProps}
          longPressEvent={longPressEvent}
          userId={thread.author}
          avatar={avatarProps}
          title={
            isAnonymousReaction
              ? `${anonymousTextKey?.singular}${thread.anonymous_data?.authorSuffix?.[locale]}`
              : author?.name
          }
          createdAt={thread.created_at}
          contents={thread.content}
          media={mediaProps}
          hover={isHover}
          menus={menus}
          linkPreview={linkPreviewProps}
          selected={highlighted}
          isAnonymous={isAnonymousReaction}
          editState={{
            isEditMode: commentEditState?.commentId === thread.id,
            onEnter: handleSubmitEditComment,
            onCancel: handleCancelEditComment,
          }}
          headWrapperOverrideStyle={headWrapperStyle}
        />
        <ResponsiveMenu
          open={isOpenResponsiveMenu}
          anchorElement={refMoreMenu.current}
          onCloseRequest={handleCloseMenu}
        >
          <ResponsiveMenuWrapper onClick={handleCloseMenu}>
            <MoreMenuItem onClick={handleClickCopy}>
              <FormattedMessage id="copy_button" />
            </MoreMenuItem>
            <Share shareData={{ url: shareUrl }}>
              {handler => (
                <MoreMenuItem onClick={handler}>
                  <FormattedMessage id="menu_content_link_share" />
                </MoreMenuItem>
              )}
            </Share>
            {hasDeletePermission && (
              <MoreMenuItem onClick={handleClickDelete}>
                <FormattedMessage id="delete_button" />
              </MoreMenuItem>
            )}
            {hasEditPermission && (
              <MoreMenuItem onClick={handleClickEditComment}>
                <FormattedMessage id="edit_button" />
              </MoreMenuItem>
            )}
            {!isAuthor && (
              <MoreMenuItem onClick={handleOpenReportDialog}>
                <FormattedMessage id="more_menu_report" />
              </MoreMenuItem>
            )}
          </ResponsiveMenuWrapper>
        </ResponsiveMenu>

        <DeleteCommentAlertDialog
          comment={thread}
          isOpen={isDeleteAlertOpen}
          onClose={closeDeleteAlert}
        />
      </>
    );
  },
);

export default React.memo(ReplyItemComponent);
