import { Svg48Play } from "@moim/icons";
import * as React from "react";
import styled from "styled-components";

import { SkeletonBox } from "common/components/skeleton";
import { useActions, useStoreState } from "app/store";
import { ActionCreators as ImageBrochureActionCreators } from "common/components/imageBrochure/actions";
import { px2rem } from "common/helpers/rem";
import { DESKTOP_IMAGE_THUMBNAIL_SIZE } from "./listWrapper";

const VideoWrapper = styled.div`
  width: 100%;
  padding-top: 100%;
  position: relative;
`;

const PlayIconWrapper = styled.div`
  width: ${px2rem(48)};
  height: ${px2rem(48)};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
`;

const PlayIcon = styled(Svg48Play).attrs({
  size: "l",
  touch: 48,
})``;
const Video = styled.video`
  object-fit: cover;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;
export const VideoCell: React.FC<{ fileId: string; parentId: string }> = ({
  fileId,
  parentId,
}) => {
  const ref = React.useRef<HTMLVideoElement>(null);
  const fileEntity = useStoreState(
    state => state.entities.files[fileId] as Moim.Upload.IFileInfo,
  );
  const { openImageBrochure } = useActions({
    openImageBrochure: ImageBrochureActionCreators.openSrcImageBrochure,
  });

  const handleClick = React.useCallback(() => {
    if (ref.current) {
      ref.current.dataset.brochureSelected = "true";
      openImageBrochure(fileEntity?.image_preview?.url!);
    }
  }, [fileEntity, ref.current, openImageBrochure]);
  if (!fileEntity?.image_preview || !fileEntity?.image_urls) {
    return <SkeletonBox width="100%" height="100%" />;
  }

  return (
    <VideoWrapper onClick={handleClick}>
      <PlayIconWrapper>
        <PlayIcon />
      </PlayIconWrapper>
      <Video
        ref={ref}
        data-role={`brochure-thumbnail-${parentId}`}
        data-file-id={fileId}
        data-sources={JSON.stringify([
          {
            type: fileEntity?.mimetype,
            src: fileEntity?.url_private,
          },
        ])}
        width={DESKTOP_IMAGE_THUMBNAIL_SIZE}
        height={DESKTOP_IMAGE_THUMBNAIL_SIZE}
        poster={fileEntity?.image_preview?.url}
        style={{ objectFit: "cover" }}
      />
    </VideoWrapper>
  );
};
