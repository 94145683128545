import { B3Bold } from "common/components/designSystem/typos";
import { ThreadDescriptionWrapper } from "common/components/thread/commonThreadItem/components/common/wrapper/description";
import { useThreadItemContext } from "common/components/thread/commonThreadItem/context";
import { textAlignStyle } from "common/components/thread/styles";
import { px2rem } from "common/helpers/rem";
import { useCurrentUserLocale } from "common/hooks/localization/useCurrentUserLocale";
import { useIntlShort } from "common/hooks/useIntlShort";
import * as React from "react";
import TruncateMarkup from "react-truncate-markup";
import styled from "styled-components";

const ContentsWrapper = styled(ThreadDescriptionWrapper)<{
  textAlign?: Moim.Forum.ForumListConfigTextAlignment;
}>`
  ${textAlignStyle};
  width: 100%;
  padding: ${px2rem(2)} ${px2rem(16)};
`;

const UserName = styled(B3Bold)`
  display: inline-block;
  min-width: fit-content;
  color: ${(props) => props.theme.colorV2.colorSet.grey1000};
  margin-right: ${px2rem(4)};
`;

const EllipsisText = styled.span`
  color: ${(props) => props.theme.colorV2.colorSet.grey300};
`;

function Contents({
  disableAnonymousSuffix,
  author,
}: {
  disableAnonymousSuffix?: boolean;
  author?: Moim.User.IOriginalUserDatum;
}) {
  const locale = useCurrentUserLocale();
  const {
    line = 2,
    showText,
    isUnread,
    textAlign,
    descriptionPlain,
    showAuthor,
    anonymousData,
    authorId,
  } = useThreadItemContext((state) => ({
    showText: state.config.showText,
    line: state.config.textMaxLine,
    descriptionPlain: state.item.preview?.descriptionPlain,
    isUnread: state.stat?.isUnread,
    textAlign: state.config.textAlignment,
    showAuthor: state.config.showAuthor,
    anonymousData: state.item.anonymous_data,
    authorId: state.item.author,
  }));
  const intl = useIntlShort();
  const anonymousTextKey = intl("anonymous_member");

  const renderEllipsis = React.useCallback(() => {
    return (
      <span>
        ... <EllipsisText>{intl("shaved_more")}</EllipsisText>
      </span>
    );
  }, []);

  if (!showText || !descriptionPlain) {
    return null;
  }
  return (
    <ContentsWrapper
      isUnread={isUnread}
      descriptionPlain={descriptionPlain}
      textAlign={textAlign}
    >
      {(value) => (
        <TruncateMarkup lines={2} ellipsis={renderEllipsis}>
          <div style={{ width: "100%" }}>
            <UserName>
              {anonymousData
                ? `${anonymousTextKey}${
                    !disableAnonymousSuffix
                      ? anonymousData.authorSuffix?.[
                          locale as keyof typeof anonymousData.authorSuffix
                        ]
                      : ""
                  }`
                : author?.name ?? authorId}
            </UserName>
            {value}
          </div>
        </TruncateMarkup>
      )}
    </ContentsWrapper>
  );
}

export default React.memo(Contents);
