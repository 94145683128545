import * as React from "react";
import { FormattedNumber } from "react-intl";

// helpers
// components
import { WriteCommentButton, Text, MessageIcon } from "./styledComponent";

interface IProps {
  count: number;
  onClick(): void;
}

const CommentCount: React.FC<IProps> = ({ count, onClick }) => {
  return (
    <WriteCommentButton aria-label="write comment" onClick={onClick}>
      <MessageIcon />
      {count > 0 && (
        <Text>
          <FormattedNumber value={count} useGrouping={true} />
        </Text>
      )}
    </WriteCommentButton>
  );
};

export default CommentCount;
