import { Svg18FilterlineG } from "@moim/icons";
import {
  B3RegularStyle,
  B4RegularStyle,
  H10BoldStyle,
} from "common/components/designSystem/typos";
import { px2rem } from "common/helpers/rem";
import styled, { css, FlattenInterpolation } from "styled-components";

import { MEDIA_QUERY } from "common/constants/responsive";

export const TagSetFilterButtonStyle = css`
  margin-top: ${px2rem(2)};
`;

export const FilterIcon = styled(Svg18FilterlineG).attrs(props => ({
  size: "xs",
  touch: 18,
  iconColor: props.theme.colorV2.colorSet.grey800,
}))``;

export const TagsCount = styled.div`
  position: absolute;
  top: ${px2rem(-8)};
  right: ${px2rem(-8)};
  width: ${px2rem(20)};
  height: ${px2rem(18)};
  border-radius: ${px2rem(10)};
  background-color: ${props => props.theme.colorV2.colorSet.grey1000};
  color: ${props => props.theme.colorV2.colorSet.white1000};
  display: flex;
  justify-content: center;
  align-items: center;
  ${B4RegularStyle}
`;

export const FilterButtonWrapper = styled.div`
  position: relative;
`;

export const FilterButton = styled.button<{
  overrideStyle?: FlattenInterpolation<any>;
}>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${px2rem(30)};
  height: ${px2rem(30)};
  border-radius: 100%;
  border: solid ${px2rem(1)} ${props => props.theme.colorV2.colorSet.grey100};
  ${props => props.overrideStyle};
  transition: opacity 200ms ease-in-out;
  &:hover {
    opacity: 0.6;
  }
`;

export const TagSetWrapper = styled.div`
  display: flex;
  gap: 0 ${px2rem(8)};
  align-items: center;
  flex-shrink: 0;

  & > div {
    height: fit-content;
  }
`;

export const TagSet = styled.div<{
  isSelected: boolean;
  isActivated?: boolean;
}>`
  position: relative;
  padding: ${px2rem(6)} ${px2rem(14)};
  border-radius: ${px2rem(16)};
  cursor: pointer;

  @media ${MEDIA_QUERY.EXCEPT_MOBILE} {
    &:hover {
      opacity: 0.6;
    }
  }

  span {
    text-align: center;
  }

  ${props =>
    props.isActivated
      ? css`
          border: ${px2rem(2)} solid
            ${props => props.theme.colorV2.colorSet.grey800};
          span {
            ${B3RegularStyle};
            color: ${props => props.theme.colorV2.colorSet.grey800} !important;
          }
        `
      : props.isSelected
      ? css`
          background-color: ${props => props.theme.colorV2.colorSet.grey1000};
          span {
            ${H10BoldStyle};
            color: ${props =>
              props.theme.colorV2.colorSet.white1000} !important;
            letter-spacing: ${px2rem(-0.3)};
          }
        `
      : css`
          border: solid ${px2rem(1)}
            ${props => props.theme.colorV2.colorSet.grey100};
          span {
            ${B3RegularStyle};
            color: ${props => props.theme.colorV2.colorSet.grey400};
          }
        `};
`;
