import { useStoreState } from "app/store";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { StaticSelection } from "common/components/designSystem/selection";

import {
  Section,
  SectionContent,
  SectionTitle,
  SelectionStyle,
} from "../styled";

interface IProps {
  coinId?: Moim.Id;
  onChange(coinId: Moim.Id): void;
}

const CoinSelectInput: React.FC<IProps> = ({ coinId, onChange }) => {
  const intl = useIntl();

  const { coins } = useStoreState(state => ({
    coins: state.entities.community_coins,
  }));

  const options = React.useMemo(
    () =>
      Object.entries(coins)
        .filter(coin => coin[1].transferrable)
        .map(item => ({
          value: item[0],
          label: item[1].name,
        })),
    [coins],
  );

  return (
    <Section>
      <SectionTitle>
        <FormattedMessage id="send_candy_dialog_select_candy_title" />
      </SectionTitle>
      <SectionContent>
        <StaticSelection
          size="l"
          state="normal"
          selected={coinId ?? null}
          options={options}
          isMultiple={false}
          useSearch={true}
          placeholder={intl.formatMessage({
            id: "send_candy_dialog_select_candy_placeholder",
          })}
          overrideStyle={SelectionStyle}
          onChange={onChange}
        />
      </SectionContent>
    </Section>
  );
};

export default CoinSelectInput;
