import styled, { css } from "styled-components";
import { px2rem } from "common/helpers/rem";

export const TopNaviBlockPropertyStyle = css<{
  element?: Partial<Moim.Layout.Navigation.IBaseElement>;
}>`
  ${({ element }) => {
    const size = element?.size;
    switch (size?.type) {
      case "ratio":
        return css`
          flex: ${size.value};
          min-width: 0;
        `;

      case "fixed":
        return css`
          width: ${px2rem(size.value)};
        `;

      case "fit-contents":
      default:
        return css`
          width: fit-content;
        `;
    }
  }}

  ${({ element }) => {
    const minWidth = element?.minWidth;
    const maxWidth = element?.maxWidth;
    const padding = element?.padding;
    const align = element?.align;

    return css`
      ${() => {
        if (minWidth) {
          return `
              min-width: ${px2rem(minWidth)};
            `;
        }
      }}

      ${() => {
        if (maxWidth) {
          return `max-width: ${px2rem(maxWidth)};`;
        }
      }}

      ${() => {
        switch (align) {
          case "center":
            return `
              justify-content: center;
            `;
          case "left":
            return `
              justify-content: flex-start;
            `;
          case "right":
            return `
              justify-content: flex-end;
            `;
        }
      }}

      ${() => (padding?.top ? `padding-top: ${px2rem(padding?.top)};` : null)}
      ${() =>
        padding?.bottom ? `padding-bottom: ${px2rem(padding?.bottom)};` : null}
      ${() =>
        padding?.left ? `padding-left: ${px2rem(padding?.left)};` : null}
      ${() =>
        padding?.right ? `padding-right: ${px2rem(padding?.right)};` : null}
    `;
  }}
`;

export const TopNaviSectionBlockPropertyStyle = css<{
  element?: Partial<Moim.Layout.Navigation.SectionElementType>;
}>`
  ${({ element }) => {
    const size = element?.size;
    switch (size?.type) {
      case "ratio":
        return css`
          flex: ${size.value};
          min-width: 0;
        `;

      case "fixed":
        return css`
          width: ${px2rem(size.value)};
        `;

      case "fit-contents":
      default:
        return css`
          width: fit-content;
        `;
    }
  }}

  ${({ element }) => {
    const minWidth = element?.sectionMinWidth;
    const maxWidth = element?.sectionMaxWidth;
    const padding = element?.padding;
    const align = element?.align;

    return css`
      ${() => {
        if (minWidth) {
          return `
              min-width: ${px2rem(minWidth)};
            `;
        }
      }}

      ${() => {
        if (maxWidth) {
          return `max-width: ${px2rem(maxWidth)};`;
        }
      }}

      ${() => {
        switch (align) {
          case "center":
            return `
              justify-content: center;
            `;
          case "left":
            return `
              justify-content: flex-start;
            `;
          case "right":
            return `
              justify-content: flex-end;
            `;
        }
      }}

      ${() => (padding?.top ? `padding-top: ${px2rem(padding?.top)};` : null)}
      ${() =>
        padding?.bottom ? `padding-bottom: ${px2rem(padding?.bottom)};` : null}
      ${() =>
        padding?.left ? `padding-left: ${px2rem(padding?.left)};` : null}
      ${() =>
        padding?.right ? `padding-right: ${px2rem(padding?.right)};` : null}
    `;
  }}
`;

interface IProps {
  element?: Moim.Layout.Navigation.SectionElementType;
  zIndex?: number;
}

export const WrapperStyle = css<IProps>`
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  min-width: 0;
  min-height: 0;
  ${TopNaviSectionBlockPropertyStyle}

  ${props => {
    const background = props.element?.background;
    switch (background?.type) {
      case "image": {
        return css`
          background-image: url(${background.value});
          background-size: ${background.crop === false ? "contain" : "cover"};
          background-repeat: no-repeat;
          background-position: center;
        `;
      }
      case "color": {
        return css`
          background-color: ${props.theme.getColorByAlias(
            background.value,
            "transparent",
          )};
        `;
      }
      default:
        return;
    }
  }}

  // NOTE: box shadow
  ${props => {
    if (Boolean(props.element?.boxShadow)) {
      return css`
        box-shadow: ${props.theme.shadow.whiteElevated2};
      `;
    }
  }}

  z-index: ${props => 999 - (props.zIndex ?? 0)};
`;

export const Wrapper = styled.div<IProps>`
  ${WrapperStyle}
`;
