import * as React from "react";
import styled from "styled-components";
import useEmojiDialog from "common/components/groupInput/hooks/useEmojiDialog";
import { renderToolButton } from "common/components/groupInput/components/mobile/toolbar";
import { px2rem } from "common/helpers/rem";
import { TOOLBAR_HEIGHT } from "common/components/groupInput/styledComponent";
import { IRefHandler } from "common/components/blockitEditorBase";

const ToolbarContainer = styled.div`
  height: ${px2rem(TOOLBAR_HEIGHT)};
  display: flex;
  padding: 0 ${px2rem(5)} 0 ${px2rem(8)};
  justify-content: flex-end;
  align-items: center;
  position: sticky;
  bottom: 0;
  overflow: hidden;
`;
const ReviewDialogInputToolbar: React.FC<{
  refEditor: React.RefObject<IRefHandler>;
  currentCursorFormat: { [key: string]: boolean };
}> = ({ refEditor, currentCursorFormat }) => {
  const emojiDialog = useEmojiDialog(refEditor);
  const setEditorFocus = React.useCallback(() => {
    refEditor.current?.focus();
  }, [refEditor]);

  const handleAddUserMention = React.useCallback(() => {
    refEditor.current?.addMention();
  }, [refEditor]);

  const addLink = React.useCallback(() => {
    refEditor.current?.addLink();
  }, [refEditor]);

  return (
    <ToolbarContainer id={`createReviewDialog_toolbar`}>
      {renderToolButton("bold", {
        visible: true,
        className: "ql-bold",
        isActive: Boolean(currentCursorFormat.bold),
        onClick: setEditorFocus,
      })}
      {renderToolButton("italic", {
        visible: true,
        className: "ql-italic",
        isActive: Boolean(currentCursorFormat.italic),
        onClick: setEditorFocus,
      })}
      {renderToolButton("link", {
        visible: true,
        className: "ql-add-link",
        isActive: Boolean(currentCursorFormat.link),
        onClick: addLink,
      })}
      {renderToolButton("mention", {
        visible: true,
        onClick: handleAddUserMention,
      })}
      {renderToolButton("emoji", {
        ref: emojiDialog.emojiAnchor,
        visible: true,
        onClick: emojiDialog.open,
      })}
      {emojiDialog.emojiDialogElement}
    </ToolbarContainer>
  );
};

export default ReviewDialogInputToolbar;
