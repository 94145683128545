import { Svg24SectionfolderG, Svg24SectionfolderGHover } from "@moim/icons";
import styled, { css } from "styled-components";
// icons



export const ButtonWrapper = styled.button.attrs({ role: "button" })``;

const arrowIconStyle = css`
  & circle {
    fill: ${props => props.theme.colorV2.colorSet.white1000};
    :hover {
      fill: ${props => props.theme.colorV2.colorSet.grey50};
    }
  }
`;

export const RoundedLeftArrowIcon = styled(Svg24SectionfolderG).attrs({
  role: "button",
  size: "s",
  touch: 24,
})`
  ${arrowIconStyle}
`;
export const RoundedLeftArrowHoverIcon = styled(
  Svg24SectionfolderGHover,
).attrs({ role: "button", size: "s", touch: 24 })`
  ${arrowIconStyle}
`;

export const RoundedRightArrowIcon = styled(Svg24SectionfolderG).attrs(
  {
    role: "button",
    size: "s",
    touch: 24,
  },
)`
  transform: rotate(180deg);
  ${arrowIconStyle}
`;
export const RoundedRightArrowHoverIcon = styled(
  Svg24SectionfolderGHover,
).attrs({ role: "button", size: "s", touch: 24 })`
  transform: rotate(180deg);
  ${arrowIconStyle}
`;
