import React from "react";

import _ from "lodash";

import { FormItemRenderer, IRef } from "./form";
import { useIntlShort } from "common/hooks/useIntlShort";
import { FormValueContext } from "./form/context";
import MoimAPI from "common/api";
import { useActions } from "app/store";
import { updateMyProfile } from "app/actions/me";
import { Spacer } from "common/components/designSystem/spacer";
import { StyledWrapper, FormList, BoldDivider } from "./styled";
import SignUpFormTemplate from "./template";
import { AnalyticsClass } from "common/helpers/analytics/analytics";
import useCurrentHubUser from "common/hooks/useCurrentHubUser";

export const SignUpForm: React.FC<{
  groupId?: string;
  buttonText: string;
  skipButtonText?: string;
  formId: string;
  formResponseId?: string;
  onNext(): void;
  onSkip?(): void;
}> = ({
  groupId,
  buttonText,
  skipButtonText,
  formId,
  formResponseId,
  onNext,
  onSkip,
}) => {
  const intl = useIntlShort();
  const refs = React.useRef<IRef[]>([]);
  const [formItems, setFormItems] = React.useState<Moim.Form.IFormItem[]>([]);

  const [values, setValues] = React.useState<Record<Moim.Id, any>>({});
  const [errors, setErrors] = React.useState<Record<Moim.Id, any>>({});

  const { dispatchUpdateMyProfile } = useActions({
    dispatchUpdateMyProfile: updateMyProfile,
  });

  const currentHubUser = useCurrentHubUser();

  const validate = React.useCallback(() => {
    const errors = refs.current.reduce<Record<Moim.Id, any>>(
      (result, ref) => ({ ...result, ...ref.validate() }),
      {},
    );

    setErrors(errors);

    return errors;
  }, [refs.current]);

  const handleClickDoneButton = React.useCallback(async () => {
    if (Object.values(validate()).every(value => !value)) {
      const payload = _.flatten(
        formItems.map(item =>
          item.type === "section" ? item.items ?? [] : item,
        ),
      ).map(item => ({
        formItemId: item.id,
        type: item.type,
        title: item.title,
        description: item.description,
        value: values[item.id],
      }));

      const result = await MoimAPI.form.postFormResponseItems(formId, {
        formResponseItems: payload,
      });
      await dispatchUpdateMyProfile(
        {
          signUpInfo: {
            formResponseId: result.data.id,
            finishedStep: "signUpForm",
          },
        },
        undefined,
        groupId,
      );

      AnalyticsClass.getInstance().signUpAdditionalInfoSubmitted({
        result: "success",
        method: currentHubUser?.provider,
      });

      onNext();
    }
  }, [
    groupId,
    formItems,
    values,
    formId,
    formResponseId,
    currentHubUser,
    validate,
    onNext,
    dispatchUpdateMyProfile,
  ]);

  React.useEffect(() => {
    MoimAPI.form.getFormItems(formId).then(result => {
      const formItems = result.data;
      setFormItems(formItems);
    });
  }, []);

  return (
    <StyledWrapper>
      <SignUpFormTemplate
        stepData={{
          title: intl("signup_form_page_title"),
          subTitle: intl("signup_form_page_description"),
          buttonText,
          skipButtonText,
        }}
        onClick={handleClickDoneButton}
        onClickSkipButton={onSkip}
        waitingButton={formItems === undefined}
      >
        <FormValueContext.Provider
          value={{
            errors,
            values,
            onChange: (id, value) => {
              setValues(state => ({
                ...state,
                [id]: value,
              }));
            },
            setError: (id, error) => {
              setErrors(state => ({ ...state, [id]: error }));
            },
          }}
        >
          <FormList>
            {formItems?.map((item, index) => (
              <>
                <FormItemRenderer
                  ref={el => {
                    if (el) {
                      refs.current[index] = el;
                    }
                  }}
                  key={item.id}
                  item={item}
                />
                {index !== formItems.length - 1 ? (
                  item.type === "section" ? (
                    <>
                      <Spacer value={12} />
                      <BoldDivider />
                      <Spacer value={12} />
                    </>
                  ) : (
                    <Spacer value={12} />
                  )
                ) : null}
              </>
            ))}
          </FormList>
        </FormValueContext.Provider>
      </SignUpFormTemplate>
    </StyledWrapper>
  );
};
