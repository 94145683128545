import React from "react";
import styled from "styled-components";
import { B3Regular } from "common/components/designSystem/typos";
import { px2rem } from "common/helpers/rem";

const Title = styled(B3Regular)`
  padding: ${px2rem(8)} ${px2rem(16)};

  color: ${props => props.theme.color.blue};
`;

export const PlaintextFormItem: React.FC<{ item: Moim.Form.IFormItem }> = ({
  item,
}) => {
  return <Title>{item.title}</Title>;
};
