import React, { memo, useCallback, useMemo, useState } from "react";
import uuid from "uuid";

import { ArrowContainer } from "common/components/blockitEditorBase/styled";
import BlockitListLayout from "common/components/blockitListLayout";
import {
  PortalLeftArrow,
  PortalRightArrow,
} from "common/components/horizontalScroll/arrows";

import { BadgePreviewItemCell } from "common/components/cryptobadge/badgePreviewItemCell";
import Skeleton from "common/components/cryptobadge/badgePreviewItemCell/skeleton";
import { Inner, ItemContainer, ListWrapper } from "./styled";
import { BlockitFeedback } from "../../../components/feedback";
import InViewTrigger from "../../../components/inViewTrigger";
import MoreButton from "../../../components/moreButton";
import { Spacer } from "common/components/designSystem/spacer";
import { withPlacement } from "../../../hoc/withPlacement";
import { CryptobadgeGroupHooks } from "./hooks";
import { useMintRequests } from "common/components/cryptobadge/hooks/useMintRequests";
import { useIntlShort } from "common/hooks/useIntlShort";
import useIsMobile from "common/hooks/useIsMobile";
import useRedirect from "common/hooks/useRedirect";
import { useActions, useStoreState } from "app/store";
import { getCryptobadgeBadgeGroup as getCryptobadgeItemsByBadgeGroupIdAction } from "app/actions/cryptobadge";
import { MoimURL } from "common/helpers/url";
import { BADGE_PREVIEW_KEY_STORE_KEY } from "common/constants/keys";
import SessionHandler from "common/helpers/sessionHandler";

const CryptobadgeGroupPreview: React.FC<Moim.Blockit.V2.WEB3.ICryptobadgeGroupPreview> = ({
  listElement,

  resourceId,
  moreButton,
}) => {
  const intl = useIntlShort();
  const [isLoading, setLoadStatus] = useState(false);
  const [cryptobadges, setCryptobadges] = useState<
    Moim.Cryptobadge.ICryptobadge[] | undefined
  >(undefined);

  const redirect = useRedirect();
  const isMobile = useIsMobile();
  const {
    currentUserMintRequests: mintRequests,
    refreshMintRequestData,
  } = useMintRequests();

  const portalSectionId = useMemo(
    () => `cryptobadge-group-preview-portal-${uuid.v4()}`,
    [],
  );

  const { getCryptobadgeItemsByBadgeGroupId } = useActions({
    getCryptobadgeItemsByBadgeGroupId: getCryptobadgeItemsByBadgeGroupIdAction,
  });

  const { badgeETTStore } = useStoreState(state => ({
    badgeETTStore: state.entities.cryptobadges_list,
  }));

  const {
    column,
    listElementType,
    maxVisibleCount,
  } = CryptobadgeGroupHooks.useConfig(listElement);

  const handleGetBadgeGroup = useCallback(async () => {
    const badgeGroupListData = (
      await getCryptobadgeItemsByBadgeGroupId({
        badgeGroupId: resourceId,
      })
    )?.badgeGroupList;

    const badgeGroupList = [...(badgeGroupListData ?? [])];

    const splicedBadges =
      maxVisibleCount &&
      badgeGroupList &&
      badgeGroupList.length > 0 &&
      badgeGroupList.length > maxVisibleCount
        ? badgeGroupList.splice(0, maxVisibleCount)
        : badgeGroupList;

    splicedBadges && setCryptobadges(splicedBadges);

    getCryptobadgeItemsByBadgeGroupId({
      badgeGroupId: resourceId,
    });
  }, [getCryptobadgeItemsByBadgeGroupId, maxVisibleCount, resourceId]);

  const handleFindMintStatus = useCallback(
    (badge: Moim.Cryptobadge.ICryptobadge) =>
      mintRequests && mintRequests.length > 0
        ? mintRequests?.find(mint => mint.badge.name === badge.name)?.status
        : "NONE",
    [mintRequests],
  );

  const handleOnView = useCallback(async () => {
    try {
      setLoadStatus(true);
      if (!mintRequests) {
        refreshMintRequestData();
      }
      if (resourceId) {
        const rawCachedBadgeIds = SessionHandler.get(
          `${BADGE_PREVIEW_KEY_STORE_KEY}_${resourceId}`,
        );

        if (rawCachedBadgeIds) {
          const cachedBadgeIds: string[] | undefined = JSON.parse(
            rawCachedBadgeIds,
          );

          const slicedIds =
            maxVisibleCount &&
            cachedBadgeIds &&
            cachedBadgeIds.length > maxVisibleCount
              ? cachedBadgeIds.splice(0, maxVisibleCount)
              : cachedBadgeIds;

          const mappedBadge =
            slicedIds &&
            slicedIds.length > 0 &&
            slicedIds.map(id => badgeETTStore[id]).filter(i => Boolean(i));

          if (mappedBadge && mappedBadge.length > 0) {
            setCryptobadges(mappedBadge);
          } else {
            handleGetBadgeGroup();
          }
        } else {
          handleGetBadgeGroup();
        }
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log("!!!error", e);
    } finally {
      setLoadStatus(false);
    }
  }, [
    mintRequests,
    resourceId,
    refreshMintRequestData,
    maxVisibleCount,
    badgeETTStore,
    handleGetBadgeGroup,
  ]);

  const handleViewMore = useCallback(() => {
    redirect(
      new MoimURL.CryptobadgeGroupShow({ groupId: resourceId }).toString(),
    );
  }, [redirect, resourceId]);

  const itemElements = useMemo(() => {
    if (isLoading || !cryptobadges) {
      return new Array(
        column *
          (isMobile || !listElement.rowCount_web
            ? listElement.rowCount
            : listElement.rowCount_web),
      )
        .fill(0)
        .map((_value, idx) => <Skeleton key={idx} />);
    }

    return cryptobadges && cryptobadges.length > 0
      ? cryptobadges.map(badge => {
          const mintRequestStatus = handleFindMintStatus(badge);
          return (
            <ItemContainer key={`cryptobadge_group_${resourceId}_${badge.id}`}>
              <BadgePreviewItemCell
                item={badge}
                mintRequestStatus={mintRequestStatus}
              />
            </ItemContainer>
          );
        })
      : [];
  }, [
    column,
    cryptobadges,
    handleFindMintStatus,
    isLoading,
    isMobile,
    listElement.rowCount,
    listElement.rowCount_web,
    resourceId,
  ]);

  return (
    <>
      <InViewTrigger onVisible={handleOnView} />

      {listElementType === "horizontal" && (
        <ArrowContainer id={portalSectionId} />
      )}
      <Inner>
        {listElementType === "horizontal" && (
          <ArrowContainer id={portalSectionId} />
        )}
        <ListWrapper>
          {cryptobadges && cryptobadges.length === 0 ? (
            <BlockitFeedback.Empty textKey="cryptobadge_group_list_preview_empty" />
          ) : (
            <BlockitListLayout
              element={listElement}
              rightArrow={
                listElementType === "horizontal" ? PortalRightArrow : undefined
              }
              leftArrow={
                listElementType === "horizontal" ? PortalLeftArrow : undefined
              }
              arrowPortalTargetId={portalSectionId}
            >
              {itemElements}
            </BlockitListLayout>
          )}
        </ListWrapper>
      </Inner>
      {moreButton?.show ? (
        <>
          <Spacer value={24} />
          <MoreButton
            text={intl("button_see_more_nft")}
            onClick={handleViewMore}
          />
        </>
      ) : null}
    </>
  );
};

export default withPlacement(memo(CryptobadgeGroupPreview));
