import * as React from "react";

import MobileSearchButton from "app/modules/search/container/searchInput/mobile";
import MenuButton from "./components/menuButton";
import CurrentUser from "./components/currentUser";
import CurrentGroupProfile from "./components/currentGroupProfile";
import { Wrapper, CurrentUserWrapper } from "./styled";
import {
  useVisibleMobileTopTab,
  useVisibleTopSubNavigation,
} from "../../controller/hooks";
import useCurrentUser from "common/hooks/useCurrentUser";
import useCurrentGroup from "common/hooks/useCurrentGroup";

// NOTE: https://showbox.moim.co and https://showbox-global.moim.co
// [PLEASE READ THIS THREAD](https://canlab-co.slack.com/archives/C013L311UTY/p1719452968520819)
const VEIL_LOGIN = ["G0LAWDOGYL", "G05DF0UD69"];
const VEIL_DOMAIN = ["showbox.moim.co", "showbox-global.moim.co"];

export default function MobileTopNavigation() {
  const currentUser = useCurrentUser();
  const currentGroup = useCurrentGroup();
  const visibleTopSubNavigation = useVisibleTopSubNavigation();
  const visibleTopTabNavigation = useVisibleMobileTopTab();

  return (
    <Wrapper
      visibleTopSubNavigation={visibleTopSubNavigation}
      visibleTopTabNavigation={visibleTopTabNavigation}
    >
      <MenuButton />
      <CurrentGroupProfile />
      <CurrentUserWrapper hasCurrentUser={Boolean(currentUser)}>
        <MobileSearchButton
          prefixKey="topnav"
          elementPaletteProps={{ type: "topArea", key: "others" }}
        />
        {VEIL_DOMAIN.includes(location.hostname) ||
        (currentGroup && VEIL_LOGIN.includes(currentGroup.id)) ? null : (
          <CurrentUser />
        )}
      </CurrentUserWrapper>
    </Wrapper>
  );
}
