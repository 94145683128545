import * as React from "react";
import shortid from "shortid";
import styled, { css } from "styled-components";
import { useActions } from "app/store";
import { ActionCreators as ImageBrochureActionCreators } from "common/components/imageBrochure/actions";
import { withPlacement } from "../../hoc/withPlacement";
import { WithCarouselLink } from "../carousel/item";
import { px2rem } from "common/helpers/rem";

const ImageContainer = styled.div<{
  ratio?: string;
  border?: Moim.Blockit.V2.IBlockitBorder;
}>`
  & > img {
    object-fit: cover;
  }
  ${props => {
    if (props.ratio) {
      const rW = parseInt(props.ratio.split(":")[0], 10);
      const rH = parseInt(props.ratio.split(":")[1], 10);
      return css`
        position: relative;
        width: 100%;
        height: 0;
        padding-top: ${(100 * (rH / rW)).toFixed(2)}%;

        & > img {
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          ${props.border?.radius
            ? css`
                border-radius: ${px2rem(props.border.radius)};
              `
            : undefined};

          ${props.border?.thickness
            ? css`
                border-width: ${px2rem(props.border.thickness)};
              `
            : undefined};

          ${props.border?.color
            ? css`
                border-color: ${props.theme.getColorByAlias(
                  props.border.color,
                )};
              `
            : undefined};
        }
      `;
    } else {
      return css`
        width: 100%;
        height: 100%;

        & > img {
          width: 100%;
        }
      `;
    }
  }}
`;

const StyledWithCarouselLink = styled(WithCarouselLink)`
  width: 100%;
`;

const ImageBlock: React.FC<Moim.Blockit.V2.IImageBlock> = ({
  src,
  ratio,
  border,
  fallbackSrc,
  href,
  enableDetailShow,
}) => {
  const [isError, setError] = React.useState(false);
  const [uniqueId] = React.useState(shortid.generate());
  const { openSrcImageBrochure } = useActions({
    openSrcImageBrochure: ImageBrochureActionCreators.openSrcImageBrochure,
  });

  const handleClick = React.useCallback<
    React.MouseEventHandler<HTMLImageElement>
  >(
    e => {
      if (!href && enableDetailShow) {
        const target = e.currentTarget;
        if (target) {
          target.dataset.brochureSelected = "true";
          openSrcImageBrochure(src);
        }
      }
    },
    [href, openSrcImageBrochure, src, enableDetailShow],
  );

  const currentSrc = React.useMemo(() => {
    if (isError && fallbackSrc) {
      return fallbackSrc;
    }
    return src;
  }, [fallbackSrc, isError, src]);

  const handleError = React.useCallback(() => {
    setError(true);
  }, []);

  return (
    <StyledWithCarouselLink to={href}>
      <ImageContainer ratio={ratio} border={border}>
        <img
          role={enableDetailShow ? "button" : undefined}
          alt={currentSrc.split("/").pop() ?? ""}
          src={currentSrc}
          width="100%"
          height="100%"
          loading="lazy"
          data-role={
            enableDetailShow ? `brochure-thumbnail-${uniqueId}` : undefined
          }
          onError={handleError}
          onClick={handleClick}
        />
      </ImageContainer>
    </StyledWithCarouselLink>
  );
};

export default withPlacement(ImageBlock);
