export const addQueryParams = (
  url: string,
  params: Record<string, string>,
): string => {
  const urlObject = new URL(url);
  const queryParams = new URLSearchParams(urlObject.search);

  Object.entries(params).forEach(([key, value]) => {
    queryParams.set(key, value);
  });

  urlObject.search = queryParams.toString();

  return urlObject.toString();
};
