import * as React from "react";
import { useResizeDetector } from "react-resize-detector";

import * as Styled from "./styled";
import CategoryItem from "./components/categoryItem";
import ChannelItem from "./components/channelItem";
import MoreButton from "./components/moreButton";

import { isBrowser } from "common/helpers/envChecker";

interface IProps {
  channelList: Moim.Channel.SimpleChannelType[];
  maxChannelVisibleFixedCount?: number;
  blockProperties?: Partial<Moim.Layout.Navigation.MenuElementType>;
  onClickChannelItem?(channel: Moim.Channel.SimpleChannelType): void;
}

const MORE_BUTTON_WIDTH = 120;

function HorizontalChannelList({
  channelList,
  maxChannelVisibleFixedCount,
  blockProperties,
  onClickChannelItem,
}: IProps) {
  const refListContainer = React.useRef<HTMLDivElement | null>(null);
  const [maxChannelVisibleCount, setMaxChannelVisibleCount] = React.useState<
    number | null
  >(null);

  const [wrapperWidth, setWrapperWidth] = React.useState<number | null>(null);

  const visibleChannelList = React.useMemo(() => {
    const count = maxChannelVisibleFixedCount ?? maxChannelVisibleCount;

    if (count === null) {
      return channelList;
    }

    return [...channelList].splice(0, count);
  }, [channelList, maxChannelVisibleFixedCount, maxChannelVisibleCount]);

  const remainChannelList = React.useMemo(() => {
    const count = maxChannelVisibleFixedCount ?? maxChannelVisibleCount;

    if (count === null) {
      return [];
    }

    return [...channelList].splice(count, channelList.length);
  }, [channelList, maxChannelVisibleFixedCount, maxChannelVisibleCount]);

  const calculateVisibleChannelCount = React.useCallback(() => {
    requestAnimationFrame(() => {
      const wrapper = refListContainer.current;
      if (!wrapper) {
        return;
      }

      if (isBrowser() && wrapper && wrapperWidth !== null) {
        let index = 0;
        let accumulateChildWidth = 0;
        const maxWidth = wrapperWidth - MORE_BUTTON_WIDTH;

        for (const node of Array.from(wrapper.children)) {
          const childrenNodeWidth = node.getBoundingClientRect().width;
          if (accumulateChildWidth + childrenNodeWidth < maxWidth) {
            index++;
            accumulateChildWidth += childrenNodeWidth;
          } else {
            setMaxChannelVisibleCount(index);
            break;
          }
        }
      }
    });
  }, [wrapperWidth]);

  const handleResizeWrapper = React.useCallback(
    (width: number) => {
      if (width) {
        if (
          maxChannelVisibleCount !== null &&
          wrapperWidth !== null &&
          width > wrapperWidth
        ) {
          setMaxChannelVisibleCount(maxChannelVisibleCount + 1);
        }
        setWrapperWidth(Math.round(width));
      }
    },
    [
      setMaxChannelVisibleCount,
      setWrapperWidth,
      maxChannelVisibleCount,
      wrapperWidth,
    ],
  );

  const { ref } = useResizeDetector({
    refreshMode: "debounce",
    handleWidth: true,
    onResize: handleResizeWrapper,
  });

  React.useLayoutEffect(() => {
    if (visibleChannelList.length) {
      calculateVisibleChannelCount();
    }
  }, [visibleChannelList.length, calculateVisibleChannelCount]);

  return (
    <Styled.Wrapper>
      <Styled.Inner ref={ref}>
        <Styled.ChannelList ref={refListContainer}>
          {visibleChannelList.map(channel => {
            if (channel.type === "category") {
              return (
                <CategoryItem
                  key={channel.id}
                  category={channel}
                  disableFoldIcon={
                    blockProperties?.enableCategoryFoldIcon === false
                  }
                  onClickChannel={onClickChannelItem}
                />
              );
            }

            return (
              <ChannelItem
                key={channel.id}
                channel={channel}
                onClickChannel={onClickChannelItem}
              />
            );
          })}
          <MoreButton
            channelList={remainChannelList}
            onClickChannel={onClickChannelItem}
          />
        </Styled.ChannelList>
      </Styled.Inner>
    </Styled.Wrapper>
  );
}

export default HorizontalChannelList;
