import * as React from "react";
import useTeamBuyingFetchSelector from "common/hooks/commerce/useTeamBuyingFetchSelector";
import { Wrapper, IconContainer, TitleText, TeamBuyingIcon } from "./styled";
import useGroupTexts from "common/hooks/useGroupTexts";

interface IProps {
  teamBuyingId: Moim.Id;
  block: Moim.Component.ProductItem.ITeamBuyingBadgeTitle;
  className?: string;
}

const TeamBuyingBadgeTitle: React.FC<IProps> = ({
  teamBuyingId,
  block,
  className,
}) => {
  const texts = useGroupTexts("team_buying_badge_title");
  const { isLoading, teamBuyingData } = useTeamBuyingFetchSelector(
    teamBuyingId,
  );

  if (isLoading || !teamBuyingData) {
    return null;
  }

  return (
    <Wrapper
      key="team-buying-badge-title"
      color={block.color}
      className={className}
    >
      <IconContainer>
        <TeamBuyingIcon />
      </IconContainer>
      <TitleText>{texts?.singular}</TitleText>
    </Wrapper>
  );
};

export default React.memo(TeamBuyingBadgeTitle);
