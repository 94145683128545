import React from "react";
import styled from "styled-components";
import { B4Regular, H9Bold } from "common/components/designSystem/typos";
import RequiredMark from "common/components/requiredMark";
import { px2rem } from "common/helpers/rem";
import { useLocaleText } from "common/hooks/localization/useLocaleText";
import { Spacer } from "common/components/designSystem/spacer";

const Title = styled(H9Bold)`
  padding: ${px2rem(8)} ${px2rem(16)};

  color: ${props => props.theme.colorV2.colorSet.grey800};
`;
const Description = styled(B4Regular)`
  padding: 0 ${px2rem(16)};

  color: ${props => props.theme.colorV2.colorSet.grey400};
`;

export const FormItemHeader: React.FC<{ item: Moim.Form.IFormItem }> = ({
  item,
}) => {
  const title = useLocaleText(item, "title");
  const description = useLocaleText(item, "description");
  return (
    <>
      <Title>
        <span>{title}</span>
        {item.isRequired && <RequiredMark />}
      </Title>
      {Boolean(description) && (
        <>
          <Description>{description}</Description>
          <Spacer value={8} />
        </>
      )}
    </>
  );
};
