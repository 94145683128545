import styled from "styled-components";
import { B4Regular, B1Regular } from "common/components/designSystem/typos";
import { px2rem } from "common/helpers/rem";

export const Username = styled(B1Regular)`
  display: inline-flex;
  align-items: center;
  white-space: pre;
  width: 100%;
  color: ${props => props.theme.colorV2.colorSet.grey800};

  > div {
    white-space: pre-wrap;
  }
`;

export const StatSpan = styled(B4Regular)`
  display: block;
`;

export const StatContainer = styled.div`
  display: flex;
  align-items: center;
  color: ${props => props.theme.colorV2.colorSet.grey300};
  margin-top: ${px2rem(2)};
`;

export const StatSeparate = styled(B4Regular)`
  margin: 0 ${px2rem(4)};
  display: block;
`;

export const UserInfoWrapper = styled.div`
  flex: 1;
  width: 100%;
  min-width: 0;
  margin-left: ${px2rem(8)};
`;

export const UserWrapper = styled.div`
  width: 100%;
  min-width: 0;
  flex: 1;
  display: inline-flex;
  align-items: center;
`;

export const Wrapper = styled.div<{}>`
  display: flex;
  align-items: center;

  margin-top: ${px2rem(16)};
  & > ${UserWrapper} {
    margin-bottom: ${px2rem(16)};
    height: ${px2rem(60)};
  }
`;
