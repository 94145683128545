import * as React from "react";
import { createSelector } from "reselect";
import { IAppState } from "app/rootReducer";
import { useActions, useStoreState } from "app/store";
import { bufferedBatchTeamBuying } from "app/actions/commerce";

const teamBuyingReSelector = createSelector(
  (state: IAppState, _: Moim.Id) => state.entities.commerce_teamBuyings,
  (_: IAppState, id: Moim.Id) => id,
  (entities, id) => entities[id] as Moim.Commerce.ITeamBuying | undefined,
);

export default function useTeamBuyingFetchSelector(
  teamBuyingId: Moim.Id | undefined,
) {
  const [isLoading, setLoadStatus] = React.useState(false);
  const teamBuyingData = useStoreState(state =>
    teamBuyingId ? teamBuyingReSelector(state, teamBuyingId) : undefined,
  );
  const { dispatchBatchTeamBuying } = useActions({
    dispatchBatchTeamBuying: bufferedBatchTeamBuying,
  });

  const fetch = React.useCallback(async () => {
    if (isLoading || !teamBuyingId) {
      return;
    }

    try {
      setLoadStatus(true);
      dispatchBatchTeamBuying([teamBuyingId]);
    } catch (err) {
      console.error("[E] useTeamBuying >>\n", err);
    } finally {
      setLoadStatus(false);
    }
  }, [dispatchBatchTeamBuying, isLoading, teamBuyingId]);

  React.useEffect(() => {
    if (!teamBuyingData) {
      fetch();
    }
  }, [teamBuyingData, fetch]);

  return {
    isLoading,
    teamBuyingData,
  };
}
