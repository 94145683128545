import * as React from "react";

import ResponsiveMenu from "common/components/responsiveMenu";
import { MenuWrapper } from "common/components/responsiveMenu/components/menu";
import Profile from "./components/profile";
import LogoutButton from "app/modules/navigationPanel/components/moimConfigMenu/component/logoutButton";

interface IProps extends React.ComponentProps<typeof ResponsiveMenu> {
  onClickMenuButton: () => void;
}

export default function ParentProfileMenu({
  onClickMenuButton,
  ...props
}: IProps) {
  const ref = React.useRef<HTMLUListElement>(null);
  const [minHeight, setHeight] = React.useState(0);

  React.useLayoutEffect(() => {
    if (props.open) {
      requestAnimationFrame(() => {
        if (ref.current) {
          setHeight(ref.current.getBoundingClientRect().height + 16);
        }
      });
    }
  }, [props.open]);

  return (
    <ResponsiveMenu {...props} minHeight={minHeight}>
      <MenuWrapper ref={ref}>
        <Profile onClickButton={onClickMenuButton} />
        <LogoutButton onClickButton={onClickMenuButton} />
      </MenuWrapper>
    </ResponsiveMenu>
  );
}
