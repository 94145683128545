import * as React from "react";
import PageUpdater from "common/components/pageUpdater";
import { IRouteComponentProps } from "app/routes/client";
import { useStoreState } from "app/store";
import useOGData from "common/hooks/useOGData";

export function ProductShowThreadHelmet(props: IRouteComponentProps) {
  const productId = props.match.params.id;
  const product = useStoreState(
    state => state.entities.commerce_product[productId ?? ""],
  );

  const threadId = props.match.params.threadId;
  const thread = useStoreState(state => state.entities.threads[threadId ?? ""]);
  let fallbackImageUrl =
    thread?.preview?.thumbnail?.url_sm ?? thread?.preview?.thumbnail?.url;

  if (!fallbackImageUrl) {
    fallbackImageUrl = thread?.content?.find(
      (content): content is Moim.Blockit.IImageBlock =>
        content.type === "image",
    )?.imageHref;
  }

  if (!fallbackImageUrl) {
    fallbackImageUrl = thread?.content?.find(
      (content): content is Moim.Blockit.IImageBlock =>
        content.type === "image",
    )?.srcLg;
  }

  if (!fallbackImageUrl) {
    fallbackImageUrl = thread?.content?.find(
      (content): content is Moim.Blockit.IFileBlock =>
        content.type === "file" && !!content.files?.[0]?.preview?.imageHref,
    )?.files?.[0]?.preview?.imageHref;
  }

  if (!fallbackImageUrl) {
    fallbackImageUrl = product?.images?.mobile?.[0]?.url;
  }

  let fallbackDescription = thread?.preview?.descriptionPlain;
  if (!fallbackDescription) {
    fallbackDescription = thread?.content?.find(
      (content): content is Moim.Blockit.ITextBlock =>
        content.type === "text" && !!content.content.trim(),
    )?.content;
  }

  const { seoTitle, seoDescription, metaObjects } = useOGData({
    fallbackTitle: product?.ogData?.title ?? product?.name,
    fallbackDescription,
    fallbackImageUrl,
  });

  const typeUrl = (() => {
    switch (thread?.type) {
      case "productComment":
        return "comments";
      case "productQuestion":
        return "questions";
      default:
        return "reviews";
    }
  })();

  return product && thread ? (
    <PageUpdater
      title={seoTitle}
      description={seoDescription}
      metaObjects={metaObjects}
      path={`/commerce/products/${productId}/${typeUrl}/${thread.id}`}
    />
  ) : null;
}

export function ProductShowHelmet(props: IRouteComponentProps) {
  const productId = props.match.params.id;
  const product = useStoreState(
    state => state.entities.commerce_product[productId ?? ""],
  );

  const { seoTitle, seoDescription, metaObjects } = useOGData({
    ogData: product?.ogData,
    fallbackTitle: product?.name,
    fallbackDescription: product?.description,
    fallbackImageUrl: product?.images?.mobile?.[0]?.url,
  });

  return (
    <PageUpdater
      title={seoTitle}
      description={seoDescription}
      metaObjects={metaObjects}
      path={`/commerce/products/${productId}`}
    />
  );
}

export function CategoryShowHelmet(props: IRouteComponentProps) {
  const categoryId = props.match.params.id;

  const category = useStoreState(
    state => state.entities.commerce_category[categoryId ?? ""],
  );

  const { seoTitle, seoDescription, metaObjects } = useOGData({
    ogData: category?.ogData,
    fallbackTitle: category?.name,
    fallbackImageUrl: category?.imageUrl,
  });

  return category ? (
    <PageUpdater
      title={seoTitle}
      description={seoDescription}
      metaObjects={metaObjects}
      path={`/commerce/categories/${category?.id}/products`}
    />
  ) : null;
}

export function ProductSetShowHelmet(props: IRouteComponentProps) {
  const productSetId = props.match.params.id;
  const productSet = useStoreState(
    state => state.entities.commerce_productSet[productSetId ?? ""],
  );

  const { seoTitle, seoDescription, metaObjects } = useOGData({
    ogData: productSet?.ogData,
    fallbackTitle: productSet?.title,
    fallbackDescription: productSet?.description,
  });

  return productSet ? (
    <PageUpdater
      title={seoTitle}
      description={seoDescription}
      metaObjects={metaObjects}
      path={`/commerce/productsets/${productSet?.id}/products`}
    />
  ) : null;
}
export function SellerShowHelmet(props: IRouteComponentProps) {
  const sellerId = props.match.params.id;
  const seller = useStoreState(
    state => state.entities.commerce_seller[sellerId ?? ""],
  );

  const { seoTitle, seoDescription, metaObjects } = useOGData({
    ogData: seller?.ogData,
    fallbackTitle: seller?.name,
    fallbackImageUrl: seller?.imageUrl,
  });

  return seller ? (
    <PageUpdater
      title={seoTitle}
      description={seoDescription}
      metaObjects={metaObjects}
      path={`/commerce/sellers/${seller?.id}/products`}
    />
  ) : null;
}

export function DeliveryGroupShowHelmet(props: IRouteComponentProps) {
  const deliveryGroupId = props.match.params.id;
  const deliveryGroup = useStoreState(
    state => state.entities.commerce_delivery_group[deliveryGroupId ?? ""],
  );

  const { seoTitle, seoDescription, metaObjects } = useOGData({
    ogData: deliveryGroup?.ogData,
    fallbackTitle: deliveryGroup?.name,
    fallbackDescription: deliveryGroup?.description,
  });

  return deliveryGroup ? (
    <PageUpdater
      title={seoTitle}
      description={seoDescription}
      metaObjects={metaObjects}
      path={`/commerce/delivery_groups/${deliveryGroup?.id}`}
    />
  ) : null;
}
