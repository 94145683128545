import * as React from "react";
import { useIntl } from "react-intl";
import { Spacer } from "common/components/designSystem/spacer";
import { DefaultDivider } from "common/components/divider";
import ControlHead from "./components/controlHead";
import CartSellerItem from "./components/sellerItem";
import Footer from "./components/footer";
import UserCoupons from "./components/userCoupons";
import { Wrapper, List, EmptyCart, Divider } from "./styled";
import { useStoreState } from "app/store";
import { CartHandlerContext } from "./context";
import {
  getCartItemBuyableSelector,
  getCartItemsFromFlattenedCart,
} from "./helpers";
import DeliveryPolicyGuide from "./components/deliveryPolicyGuide";
import useHubSeller from "common/hooks/commerce/useHubSeller";

interface IProps {
  cartItems: Moim.Commerce.ICartSellerItem[] | undefined;
  calcResponse: Moim.Commerce.IPaymentCalcResponse | undefined;

  onChangeProductGroup(
    options: {
      sellerId: string;
      type: Moim.Commerce.DeliveryProductGroupType;
      id?: Moim.Id;
    },

    items: (
      items: Moim.Commerce.ICartItemDatum[],
    ) => Moim.Commerce.ICartItemDatum[],
  ): void;
  updateMyCart(
    items: Moim.Commerce.IFlattenedCartSellerItem[],
    successMessage?: string,
  ): void;
  calculateMyCart(payload: {
    items?: Moim.Commerce.IGroupedByDeliveryOptionCartSellerItem[];
    userCoupons?: string[];
    selectRecommendedCoupon?: boolean;
  }): void;
  onClickBuyNow(): void;
}

const CartsComponent: React.FC<IProps> = ({
  cartItems = [],
  calcResponse,
  onChangeProductGroup,
  calculateMyCart,
  updateMyCart,
  onClickBuyNow,
}) => {
  const intl = useIntl();

  const currency = calcResponse?.totalPrice_price.currency ?? "KRW";
  const hasCustomDeliveryPolicy = React.useMemo(
    () =>
      cartItems.some(groupedItems =>
        groupedItems.items.some(
          item => item?.deliveryGroup?.policy?.type === "custom",
        ),
      ),
    [cartItems],
  );

  const { isAllChecked, isGuest } = useStoreState(state => {
    const buyableCartItems = getCartItemsFromFlattenedCart(
      cartItems,
    ).filter(cartItem => getCartItemBuyableSelector(cartItem, state));
    return {
      isAllChecked: buyableCartItems?.length
        ? buyableCartItems.every(item => item.checked)
        : false,
      isGuest: !state.app.currentUserId,
    };
  });

  const hubSeller = useHubSeller();

  const cartItemElements = React.useMemo(
    () =>
      cartItems.map((item, index) => {
        const calcResponseItem = calcResponse?.items.find(
          calcItem => calcItem.sellerId === item.sellerId,
        );

        return (
          <>
            {index !== 0 && <Divider key={`divider_${index}`} />}
            <CartSellerItem
              key={item.sellerId}
              currency={currency}
              sellerId={item.sellerId}
              items={item.items}
              calcResponse={calcResponseItem?.items ?? []}
            />
          </>
        );
      }),
    [calcResponse?.items, cartItems, currency],
  );

  const shippingFee = React.useMemo(
    () =>
      calcResponse?.items.reduce(
        (acc, value) => acc + value.deliveryFee_price.numValue,
        0,
      ),
    [calcResponse?.items],
  );

  const contextValues = React.useMemo(
    () => ({
      onChangeProductGroup,
      calculateMyCart,
      updateMyCart,
    }),
    [onChangeProductGroup, calculateMyCart, updateMyCart],
  );
  const totalQuantity = React.useMemo(
    () =>
      calcResponse?.items.reduce(
        (acc, value) =>
          acc +
          value.items.reduce(
            (acc2, value2) =>
              acc2 +
              value2.items.reduce((acc3, value3) => acc3 + value3.quantity, 0),
            0,
          ),
        0,
      ),
    [calcResponse?.items],
  );

  if (!cartItems || cartItems.length === 0) {
    return (
      <>
        <Wrapper>
          <EmptyCart>
            <span>{intl.formatMessage({ id: "cart/empty" })}</span>
          </EmptyCart>
        </Wrapper>
      </>
    );
  }

  return (
    <CartHandlerContext.Provider value={contextValues}>
      <Wrapper>
        <ControlHead
          totalItemCount={cartItems.length ?? 0}
          isAllChecked={isAllChecked}
        />
        <List>{cartItemElements}</List>
        {hubSeller?.deliveryPolicy?.isActive && (
          <>
            <Divider />
            <Spacer value={8} />
            <DeliveryPolicyGuide
              termId={hubSeller?.deliveryPolicy?.termId}
              title={hubSeller?.deliveryPolicy?.title}
              buttonText={hubSeller?.deliveryPolicy?.buttonText}
              description={hubSeller?.deliveryPolicy?.description}
            />
            <Spacer value={8} />
            <Divider />
          </>
        )}
        {!isGuest && (
          <>
            <Spacer value={8} />
            <UserCoupons
              userCoupons={calcResponse?.userCouponsV2}
              couponDiscount={calcResponse?.couponDiscount_price}
            />
          </>
        )}
        <Spacer value={8} />
        <DefaultDivider />
        <Spacer value={8} />
        {calcResponse ? (
          <Footer
            shippingFee={shippingFee ?? 0}
            totalWeight={calcResponse?.totalWeight}
            totalQuantity={totalQuantity}
            totalRawPrice={calcResponse?.rawTotalPrice_price}
            totalPrice={calcResponse?.totalPrice_price}
            totalUsedCoinPrice={calcResponse.usedCoin.totalPrice_price}
            totalAdditionalFees={calcResponse?.totalAdditionalFees}
            coupon={calcResponse?.couponDiscount_price?.numValue ?? 0}
            points={calcResponse?.estimatedCreditAmount_price?.numValue ?? 0}
            usedCoins={calcResponse?.usedCoin?.coins}
            onClickBuyNow={onClickBuyNow}
            hasItemError={calcResponse?.items.some(item =>
              item.items.some(_item =>
                _item.items.some(_item2 => _item2.error),
              ),
            )}
            hasCustomDeliveryPolicy={hasCustomDeliveryPolicy}
            minimumPurchaseAmount={hubSeller?.config?.minimumPurchaseAmount}
          />
        ) : null}
      </Wrapper>
    </CartHandlerContext.Provider>
  );
};

export default CartsComponent;
