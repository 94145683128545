import { AppDispatch } from "app/store";
import { IAppState } from "app/rootReducer";

import { notCurrentChannelNewMessageHandler } from "app/actions/directMessage";
import {
  getCommentListWithUpdateCommentCount,
  getThreadList,
  openNewItemSnackbar,
} from "app/actions/forum";
import { ItemIdTypes } from "app/enums";
import { threadReplySortingOptionSelector } from "app/selectors/forum";
import { getIsCurrentChannel } from "./utils/getIsCurrentChannel";
import { getConversationMessages } from "app/actions/conversation";
import { last } from "lodash";

export async function dataHandler(
  dispatch: AppDispatch,
  getState: () => IAppState,
  payload: Moim.WebsocketEvent.NewMessage,
) {
  const state = getState();

  for (const response of payload.payload) {
    const {
      channelId,
      parentId,
      channelType,
      itemId,
      creator,
      originalId,
    } = response;

    const isCurrentChannel = getIsCurrentChannel(channelId);
    const isMessageEvent = itemId.charAt(0) === ItemIdTypes.MESSAGE;
    const isCreateByCurrentUser = creator === state.app.currentUserId;
    // new thread snackbar auto focus갸 활성화 되면 false 를 지워주세요
    const isNewThreadEvent = itemId.charAt(0) === ItemIdTypes.THREAD;
    const isNewCommentEvent =
      itemId.charAt(0) === ItemIdTypes.COMMENT &&
      getState().forumData.currentThreadId === (originalId ?? parentId);

    if (
      isCurrentChannel &&
      (isMessageEvent || isNewThreadEvent || isNewCommentEvent)
    ) {
      if (isMessageEvent) {
        const lastId = last(getState().conversation.messages[parentId].data);

        /**
         * ToDo: buffering? throttling?
         */
        dispatch(
          getConversationMessages({
            channel_id: parentId,
            after: lastId ? lastId.split("_")[1] : undefined,
          }),
        );
      } else if (isNewThreadEvent) {
        const currentPaging = state.thread.threadIds[channelId]?.paging;
        if (!currentPaging?.before) {
          dispatch(
            getThreadList(
              {
                channelId,
                before: itemId,
                inclusive: true,
              },
              undefined,
              "before",
            ),
          );

          /**
           * 여기 관련 이슈가 있는 것 같아 일단 Spec Out 위에 new thread snackbar auto focus 코멘트 참조..
           */
          // if (!isCreateByCurrentUser) {
          //   dispatch(openNewItemSnackbar("post", itemId));
          // }
        }
      } else if (isNewCommentEvent) {
        const sortingOption = threadReplySortingOptionSelector(state, parentId);
        const currentPaging = state.thread.threadIds[parentId]?.paging;
        let pagingKey: keyof Moim.IPaging =
          sortingOption.order === "ASC" ? "after" : "before";

        if (sortingOption.sort === "voteScore") {
          pagingKey = "before";
        }

        if (!currentPaging?.[pagingKey]) {
          dispatch(
            getCommentListWithUpdateCommentCount(
              {
                channelId,
                threadId: parentId,
                order: sortingOption.order,
                sort: sortingOption.sort,
                inclusive: true,
                [pagingKey]: itemId,
              },
              undefined,
              pagingKey,
            ),
          );

          if (!isCreateByCurrentUser) {
            dispatch(openNewItemSnackbar("comment", itemId));
          }
        }
      }
    } else {
      dispatch(
        notCurrentChannelNewMessageHandler({
          channelType,
          channelId,
          originalId,
          parentId,
          itemId,
        }),
      );
    }
  }
}
