import { CancelToken } from "axios";
import { MoimBaseAPI } from "common/api/base";

export default class TagAPI extends MoimBaseAPI {
  public async getTags(params: {
    limit?: number;
    after?: string;
    cancelToken?: CancelToken;
  }): Promise<Moim.IPaginatedListResponse<Moim.Tag.ITag>> {
    const { limit = 30, cancelToken, after } = params;
    const groupId = this.getCurrentGroupId();
    return (
      await this.get(
        `/groups/${groupId}/tags`,
        { limit, after },
        { cancelToken },
      )
    ).data;
  }

  public async batchTags(
    params: Moim.Tag.IBatchTagsRequest,
    cancelToken?: CancelToken,
  ): Promise<Moim.Tag.IBatchTagsResponseBody> {
    const { tags } = params;
    return (await this.post(`/tags/_batch`, { tags }, { cancelToken })).data;
  }

  public async getChildGroupsFromTags(
    params: {
      tags?: Moim.Id[];
      paging?: Moim.IPaging;
      sort?: Moim.Group.SortOptionSortType;
      order?: Moim.Group.SortOptionOrderType;
    },
    cancelToken: CancelToken,
  ): Promise<Moim.IPaginatedListResponse<Moim.Id>> {
    const groupId = this.getCurrentGroupId();
    const { paging, ...rest } = params;

    return (
      await this.get(
        `/groups/${groupId}/subgroups`,
        { ...rest, ...paging, onlyIds: true },
        { cancelToken },
      )
    ).data;
  }

  // v2
  public async batchTagGroups(
    params: { ids: string[] },
    cancelToken?: CancelToken,
  ): Promise<Moim.IPaginatedListResponse<Moim.TagSet.ITagGroup>> {
    const groupId = this.getCurrentGroupId();
    return (
      await this.post(`/groups/${groupId}/tag_groups/_batch`, params, {
        cancelToken,
      })
    ).data;
  }

  public async getTagGroups(
    params: { limit?: number } & Moim.IPaging,
    cancelToken?: CancelToken,
  ): Promise<Moim.IPaginatedListResponse<Moim.TagSet.ITagGroup>> {
    const groupId = this.getCurrentGroupId();
    return (
      await this.get(`/groups/${groupId}/tag_groups`, params, {
        cancelToken,
      })
    ).data;
  }
}
