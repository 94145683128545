import React from "react";
import BlockitRendererV2 from "../blockitEditorBase/components/blockitRenderer.v2";
import { getLayoutKey } from "common/components/footer/footerBlockV2";
import useCurrentGroup from "common/hooks/useCurrentGroup";
import { useActions, useStoreState } from "app/store";
import styled from "styled-components";
import { getComponentLayout } from "../../../actions/componentLayout";
import useCurrentUser from "common/hooks/useCurrentUser";

const ReferralBlock = () => {
  const currentUser = useCurrentUser();
  const currentGroup = useCurrentGroup();

  const { dispatchGetComponentLayout } = useActions({
    dispatchGetComponentLayout: getComponentLayout,
  });

  const { key, fallbackKey } = getLayoutKey(
    currentGroup?.id,
    "referral",
    "mobile",
  );

  const referral = useStoreState(state =>
    state.componentLayout?.layout?.[key ?? ""]?.blocks?.length
      ? state.componentLayout?.layout?.[key ?? ""]
      : state.componentLayout?.layout?.[fallbackKey ?? ""],
  );

  React.useEffect(() => {
    if (currentGroup?.id && currentUser?.id) {
      dispatchGetComponentLayout({
        id: currentGroup?.id,
        type: "referral",
        platform: "mobile",
        userId: currentUser?.id,
      });
    }
  }, [currentGroup, currentUser, dispatchGetComponentLayout]);

  return (
    <Wrapper>
      {referral?.blocks?.map((block, index) => (
        <BlockitRendererV2 key={index} block={block} />
      ))}
    </Wrapper>
  );
};

export default ReferralBlock;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
