// Ref: https://vingle.atlassian.net/wiki/spaces/BT/pages/153550849/Payment+Web
import * as React from "react";
import { ActionCreators as CommerceActionCreators } from "app/actions/commerce";
import { useActions } from "app/store";
import _ from "lodash";

export function useCoinRechargeAction() {
  const { openCheckoutRedirectDialog } = useActions({
    openCheckoutRedirectDialog:
      CommerceActionCreators.openCheckoutRedirectLoadingDialogAsCoinRecharge,
  });

  const openDialog = React.useCallback(
    async (coinId: Moim.Id) => {
      openCheckoutRedirectDialog({ coinId });
    },
    [openCheckoutRedirectDialog],
  );

  return openDialog;
}

export function useConfirmPaymentAction() {
  const { openCheckoutRedirectDialog } = useActions({
    openCheckoutRedirectDialog:
      CommerceActionCreators.openCheckoutRedirectLoadingDialogAsConfirmation,
  });

  const openDialog = React.useCallback(
    async (paymentId: string) => {
      openCheckoutRedirectDialog({ paymentId, isFromCart: false });
    },
    [openCheckoutRedirectDialog],
  );

  return openDialog;
}

export default function useBuyNowAction() {
  const { openCheckoutRedirectDialog } = useActions({
    openCheckoutRedirectDialog:
      CommerceActionCreators.openCheckoutRedirectLoadingDialog,
  });

  const openDialog = React.useCallback(
    async (
      items: Moim.Commerce.ICartSellerItem[],
      isFromCart: boolean = false,
      userCoupons?: string[],
      usedCoins?: { coinId: string; amount: number }[],
      teamBuying?: { teamBuyingId: Moim.Id; teamId?: Moim.Id },
    ) => {
      openCheckoutRedirectDialog(
        items,
        isFromCart,
        userCoupons,
        usedCoins,
        teamBuying,
      );
    },
    [openCheckoutRedirectDialog],
  );

  return openDialog;
}
