import uuid from "uuid";
// hooks
import { useActions, useStoreState } from "app/store";
import useIsMobile from "common/hooks/useIsMobile";
import { NftSetHooks } from "./hooks";
import {
  PortalRightArrow,
  PortalLeftArrow,
} from "common/components/horizontalScroll/arrows";
import BlockitListLayout from "common/components/blockitListLayout";
import { ArrowContainer } from "common/components/blockitEditorBase/styled";
import { withPlacement } from "../../../hoc/withPlacement";
import { getNftSetShow as getNftSetShowAction } from "app/actions/nft";
import useCurrentGroup from "common/hooks/useCurrentGroup";
import useCurrentHubGroup from "common/hooks/useCurrentHubGroup";
import useRedirect from "common/hooks/useRedirect";
import { MoimURL } from "common/helpers/url";
// components

import { NFTItemCell } from "common/components/NFTItemCell";
import Skeleton from "common/components/NFTItemCell/skeleton";
// style
import { ItemContainer } from "./styled";
import MoreButton from "../../../components/moreButton";
import InViewTrigger from "../../../components/inViewTrigger";
import { BlockitFeedback } from "../../../components/feedback";
import React, { memo, useCallback, useMemo, useState } from "react";
import { Spacer } from "common/components/designSystem/spacer";
import { useIntlShort } from "common/hooks/useIntlShort";

const DEFAULT_LIMIT = 20;

const NFTGroupPreviewBlock: React.FC<Moim.Blockit.V2.WEB3.INFTGroupPreviewBlock> = ({
  listElement,

  resourceId,
  moreButton,
  listShowConfig,
}) => {
  const intl = useIntlShort();
  const redirect = useRedirect();
  const currentGroup = useCurrentGroup();
  const currentHubGroup = useCurrentHubGroup();
  const [isLoading, setLoadStatus] = useState(false);
  const isMobile = useIsMobile();
  const portalSectionId = useMemo(
    () => `nft-set-preview-portal-${uuid.v4()}`,
    [],
  );

  const [nftItemIds, setNftItemIds] = useState<string[] | undefined | null>(
    undefined,
  );
  const { getNftSetShowData } = useActions({
    getNftSetShowData: getNftSetShowAction,
  });
  const { column, listElementType, maxVisibleCount } = NftSetHooks.useConfig(
    listElement,
  );

  const handleOnView = useCallback(() => {
    if (!isLoading && resourceId) {
      try {
        setLoadStatus(true);
        getNftSetShowData(resourceId, DEFAULT_LIMIT).then(nftSetData => {
          if (nftSetData.itemIds) {
            setNftItemIds([...nftSetData.itemIds]);
          }
        });
      } finally {
        setLoadStatus(false);
      }
    }
  }, [isLoading, getNftSetShowData, resourceId]);

  const handleViewMore = useCallback(() => {
    redirect(new MoimURL.NftSetShow({ id: resourceId }).toString());
  }, [redirect, resourceId]);

  const items = useStoreState(state =>
    nftItemIds
      ? nftItemIds.map(iid => state.entities.nftItems[iid])
      : undefined,
  );
  const slicedItems = useMemo(() => items?.slice(0, maxVisibleCount), [
    items,
    maxVisibleCount,
  ]);
  const itemElements = useMemo(() => {
    const isUndefinedArray = slicedItems?.some(item => item?.id === undefined);
    if (slicedItems === undefined || isLoading || isUndefinedArray) {
      return new Array(
        column *
          (isMobile || !listElement.rowCount_web
            ? listElement.rowCount
            : listElement.rowCount_web),
      )
        .fill(0)
        .map(_ => <Skeleton />);
    }

    return slicedItems.map(item => (
      <ItemContainer key={`nft_set_${item.id}`}>
        <NFTItemCell
          item={item}
          groupId={currentGroup?.id}
          hubGroupId={currentHubGroup?.id}
          config={listShowConfig}
        />
      </ItemContainer>
    ));
  }, [
    slicedItems,
    isLoading,
    column,
    isMobile,
    listElement?.rowCount_web,
    listElement?.rowCount,
    currentGroup?.id,
    currentHubGroup?.id,
    listShowConfig,
  ]);

  return (
    <>
      <InViewTrigger onVisible={handleOnView} />

      {listElementType === "horizontal" && (
        <ArrowContainer id={portalSectionId} />
      )}
      {items !== undefined && !items?.length ? (
        <BlockitFeedback.Empty textKey="nft_set_list_preview_empty" />
      ) : (
        <BlockitListLayout
          element={listElement}
          rightArrow={
            listElementType === "horizontal" ? PortalRightArrow : undefined
          }
          leftArrow={
            listElementType === "horizontal" ? PortalLeftArrow : undefined
          }
          arrowPortalTargetId={portalSectionId}
        >
          {itemElements}
        </BlockitListLayout>
      )}
      {moreButton?.show ? (
        <>
          <Spacer value={24} />
          <MoreButton
            text={intl("button_see_more_nft_group")}
            onClick={handleViewMore}
          />
        </>
      ) : null}
    </>
  );
};

export default withPlacement(memo(NFTGroupPreviewBlock));
