import styled from "styled-components";
import * as React from "react";
import ShavedTextV2 from "common/components/shavedText/v2";
import { useThreadItemContext } from "common/components/thread/commonThreadItem/context";
import { textAlignStyle } from "common/components/thread/commonThreadItem/styles";
import { ThreadDescriptionWrapper } from "common/components/thread/components/wrapper/description";
import { px2rem } from "common/helpers/rem";

const DescriptionWrapper = styled(ThreadDescriptionWrapper)<{
  textAlign?: Moim.Forum.ForumListConfigTextAlignment;
  thumbnailUrl?: string;
}>`
  margin-top: ${px2rem(4)};
  color: ${props =>
    props.thumbnailUrl
      ? props.theme.themeMode.lightPalette.colorSet.white800
      : "inherit"};
  ${textAlignStyle}
`;

function Description() {
  const {
    line = 2,
    showText,
    textAlign,
    descriptionPlain,
    thumbnailUrl,
  } = useThreadItemContext(state => ({
    showText: state.config.showText,
    line: state.config.textMaxLine,
    descriptionPlain: state.item.preview?.descriptionPlain,
    textAlign: state.config.textAlignment,
    thumbnailUrl: state.item.preview?.thumbnail?.url,
  }));

  if (!showText) {
    return null;
  }
  return (
    <DescriptionWrapper
      descriptionPlain={descriptionPlain}
      textAlign={textAlign}
      thumbnailUrl={thumbnailUrl}
    >
      {el => <ShavedTextV2 line={line}>{el}</ShavedTextV2>}
    </DescriptionWrapper>
  );
}

export default React.memo(Description);
