import styled from "styled-components";
import { px2rem } from "common/helpers/rem";
import { useOpacityHoverStyle } from "common/components/designSystem/styles";

export const POSITION_WIDTH_LIMIT = 208;

export const Wrapper = styled.div<{ enableForceDoubleRow: boolean }>`
  width: 100%;
  display: flex;
  padding: ${px2rem(3)};
  column-gap: ${px2rem(8)};
  row-gap: ${px2rem(8)};

  flex-direction: ${props => (props.enableForceDoubleRow ? "column" : "row")};
  flex-wrap: wrap;
  overflow-x: hidden;
`;

export const PositionCell = styled.span.attrs({ role: "button" })`
  display: inline-flex;
  max-width: ${px2rem(200)};

  ${useOpacityHoverStyle}
`;

export const PositionContainer = styled.div`
  display: flex;
  column-gap: ${px2rem(4)};
  width: fit-content;
`;

export const BadgeBoxAnchor = styled.a`
  display: flex;
  align-items: center;
`;

export const BadgeBox = styled.img`
  width: ${px2rem(18)};
  height: ${px2rem(18)};
`;

export const BadgeContainer = styled.div`
  display: flex;
  column-gap: ${px2rem(4)};
  width: fit-content;
`;
