import * as React from "react";

import AlertDialog from "common/components/alertDialog";

import { useActions } from "app/store";
import { useIntlShort } from "common/hooks/useIntlShort";

import { signOut } from "app/actions/app";

interface IProps {
  isOpen: boolean;
  onClose(): void;
}

const LeaveAlertDialog: React.FC<IProps> = ({ isOpen, onClose }) => {
  const intl = useIntlShort();

  const { dispatchSignOut } = useActions({
    dispatchSignOut: signOut,
  });

  const handleSignOut = React.useCallback(() => {
    dispatchSignOut(intl("log_out_moim_success_toast_message"));
  }, [dispatchSignOut, intl]);

  const alertButtons = React.useMemo(
    () => [
      {
        text: intl("cancel_button"),
        onClick: onClose,
      },
      {
        text: intl("button_leave"),
        onClick: handleSignOut,
      },
    ],
    [onClose, intl, handleSignOut],
  );

  return (
    <AlertDialog
      open={isOpen}
      title={intl("dialog_exit_signup_page_logout_title")}
      content={intl("dialog_exit_signup_page_logout_body")}
      rightButtons={alertButtons}
      onClose={onClose}
    />
  );
};

export default LeaveAlertDialog;
