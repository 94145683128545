import { useCallback } from "react";

import { useActions } from "app/store";
import { ActionCreators } from "./actions";

export function useOpenGlobalDuplicatePostDialog(initialParams?: {
  threadId: string;
}) {
  const { open } = useActions({ open: ActionCreators.open });

  return useCallback(
    (params?: { threadId: string }) => {
      const payload = params ?? initialParams;

      if (payload) {
        open(payload);
      }
    },
    [initialParams, open],
  );
}

export function useCloseGlobalDuplicatePostDialog() {
  const { close } = useActions({
    close: ActionCreators.close,
  });

  return { close };
}
