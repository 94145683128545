import * as React from "react";
import uuid from "uuid";
// components
import ProductItemCell from "common/components/productItemCell";
import ProductItemCellSkeleton from "common/components/productItemCell/skeleton";
import {
  PortalRightArrow,
  PortalLeftArrow,
} from "common/components/horizontalScroll/arrows";
import BlockitListLayout from "common/components/blockitListLayout";

import { ArrowContainer } from "common/components/blockitEditorBase/styled";
import InViewTrigger from "../../../../components/inViewTrigger";
import { BlockitFeedback } from "../../../../components/feedback";
import MoreButton from "../../../../components/moreButton";
import { Spacer } from "common/components/designSystem/spacer";
import { ProductListFilter } from "./filter";
import { useActions, useStoreState, arrayEqual } from "app/store";
import useCurrentGroup from "common/hooks/useCurrentGroup";
import useRedirect from "common/hooks/useRedirect";
import { useParseItemLayout } from "../hooks";

import { getProductsSelector } from "app/selectors/commerce";

import { searchSellerProducts } from "app/actions/commerce";
import { MoimURL } from "common/helpers/url";
import { useCommerceAnalyticsReport } from "common/hooks/commerce/useCommerceAnalytics";
import { useParseListElementConfig } from "common/components/blockitListLayout/hooks/useParseListElementConfig";
import { useIntlShort } from "common/hooks/useIntlShort";

const BrandPreview: React.FC<Moim.Blockit.V2.Commerce.IProductListPreviewBlock> = ({
  resourceId,
  itemLayout,
  itemLayout_web,
  listElement,
  filter,
  moreButton,
}) => {
  const intl = useIntlShort();
  const redirect = useRedirect();
  const productItemLayout = useParseItemLayout(
    "listPreview",
    itemLayout,
    itemLayout_web,
  );

  const { listElementType, maxVisibleCount } = useParseListElementConfig(
    listElement,
  );

  const portalSectionId = React.useMemo(
    () => `brand-preview-portal-${uuid.v4()}`,
    [],
  );

  const [list, setList] = React.useState<
    Moim.IPaginatedListResponse<Moim.Id> | undefined | null
  >(undefined);
  const currentGroup = useCurrentGroup();

  const products = useStoreState(
    state => (list ? getProductsSelector(state, list.data).data : undefined),
    arrayEqual,
  );

  const { dispatchSearchSellerProducts } = useActions({
    dispatchSearchSellerProducts: searchSellerProducts,
  });

  const handleClickViewMore = React.useCallback(() => {
    if (!resourceId) return;

    redirect(
      new MoimURL.CommerceCategories({
        id: resourceId,
        section: "products",
      }).toString(),
    );
  }, [redirect, resourceId]);

  const searchProduct = React.useCallback(
    (params?: { tagSetIds?: string[][] }) => {
      if (resourceId && currentGroup?.seller_id) {
        dispatchSearchSellerProducts(currentGroup.seller_id, {
          limit: maxVisibleCount,
          brandId: [resourceId],
          ...params,
        })
          .then(result => {
            setList(result);
          })
          .catch(() => {
            setList(null);
          });
      }
    },
    [currentGroup, maxVisibleCount, dispatchSearchSellerProducts, resourceId],
  );
  const handleOnView = React.useCallback(() => {
    if (
      !products?.length &&
      products === undefined &&
      currentGroup?.seller_id
    ) {
      searchProduct();
    }
  }, [currentGroup?.seller_id, products?.length]);
  const splicedProducts = React.useMemo(
    () => products?.slice(0, maxVisibleCount) ?? [],
    [maxVisibleCount, products],
  );

  const reports = useCommerceAnalyticsReport();

  const itemElements = React.useMemo(() => {
    const isUndefinedArray = splicedProducts.some(pd => pd?.id === undefined);
    if (isUndefinedArray || list === undefined) {
      return new Array(maxVisibleCount)
        .fill(0)
        .map((_, idx) => (
          <ProductItemCellSkeleton
            key={`plp_skeleton_${idx}`}
            direction={productItemLayout?.direction}
          />
        ));
    }

    return splicedProducts
      .filter(pd => Boolean(pd) && Boolean(pd.isDisplayed))
      .map(product => (
        <ProductItemCell
          key={`${product.id}`}
          productId={product.id}
          block={productItemLayout}
          onProductSelect={reports.reportBlockProductListPreviewProductSelect}
          onSellerSelect={reports.reportBlockProductListPreviewSellerSelect}
          onBuyNowSelect={reports.reportBlockProductListPreviewProductBuyNow}
          onAddToCartSelect={
            reports.reportBlockProductListPreviewProductAddToCart
          }
        />
      ));
  }, [splicedProducts, list, maxVisibleCount, productItemLayout]);

  const isEmpty = list === null || (list !== undefined && !list.data.length);

  return (
    <>
      <InViewTrigger onVisible={handleOnView} />
      {filter?.tagGroups?.length ? (
        <ProductListFilter
          tagGroupIds={filter?.tagGroups}
          onChangeSelectedTags={selectedTags => {
            const tagSetIds = selectedTags
              ? Object.values(selectedTags)?.filter(tags => tags.length)
              : undefined;
            searchProduct({ tagSetIds });
          }}
        />
      ) : null}
      {listElementType === "horizontal" && (
        <ArrowContainer id={portalSectionId} onClick={handleClickViewMore} />
      )}
      {isEmpty ? (
        <BlockitFeedback.Empty textKey="product_list_preview_empty" />
      ) : (
        <BlockitListLayout
          element={listElement}
          rightArrow={
            listElementType === "horizontal" ? PortalRightArrow : undefined
          }
          leftArrow={
            listElementType === "horizontal" ? PortalLeftArrow : undefined
          }
          arrowPortalTargetId={portalSectionId}
        >
          {itemElements}
        </BlockitListLayout>
      )}
      {moreButton?.show ? (
        <>
          <Spacer value={24} />
          <MoreButton
            text={intl("button_see_more_product")}
            onClick={handleClickViewMore}
          />
        </>
      ) : null}
    </>
  );
};

export default React.memo(BrandPreview);
