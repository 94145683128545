import * as React from "react";
import PageUpdater from "common/components/pageUpdater";
import { IRouteComponentProps } from "app/routes/client";
import { useStoreState } from "app/store";
import useOGData from "common/hooks/useOGData";

export function UserShowHelmet(props: IRouteComponentProps) {
  const { userId } = props.match.params;
  const user = useStoreState(state => state.entities.users[userId ?? ""]);

  const { seoTitle, seoDescription, metaObjects } = useOGData({
    fallbackTitle: user?.name,
    fallbackImageUrl: user?.avatar_url,
    fallbackDescription: user?.bio,
  });

  return user ? (
    <PageUpdater
      title={seoTitle}
      description={seoDescription}
      metaObjects={metaObjects}
      path={`/users/${userId}`}
    />
  ) : null;
}
