import styled, { createGlobalStyle } from "styled-components";
import { rgba, lighten } from "polished";
import { px2rem } from "common/helpers/rem";
import { MEDIA_QUERY } from "common/constants/responsive";

export const Wrapper = styled.div`
  position: relative;
  max-width: 100%;
`;

export const VideoContainer = styled.video`
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.colorV2.colorSet.white1000};
`;

export const PipWrapper = styled.div`
  cursor: pointer;
  position: absolute;
  bottom: ${px2rem(8)};
  right: ${px2rem(8)};
`;

export const PipButton = styled.button`
  width: ${px2rem(32)};
  height: ${px2rem(32)};
`;

export const Blurhash = styled.canvas`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: ${props => props.theme.zIndexes.below};
`;

export const ToggleVeil = styled.div.attrs({ role: "button" })`
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
  z-index: ${props => props.theme.zIndexes.default + 1};
`;

export const VolumeControl = styled.button<{ muted: boolean }>`
  font-family: VideoJS;
  font-weight: normal;
  font-style: normal;

  position: absolute;
  width: ${px2rem(32)};
  height: ${px2rem(32)};
  bottom: ${px2rem(8)};
  right: ${px2rem(8)};
  color: white;
  font-size: ${px2rem(24)};
  line-height: ${px2rem(24)};
  z-index: ${props => props.theme.zIndexes.default + 2};

  :before {
    content: ${props => (props.muted ? `"\f104"` : `"\f107"`)};
  }

  @media ${MEDIA_QUERY.EXCEPT_MOBILE} {
    transition: 150ms background-color ease-in-out;

    :hover {
      background-color: ${props => props.theme.colorV2.colorSet.grey200};
    }
  }
`;

export const CenterPlayButton = styled.button<{ playStatus: boolean }>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  visibility: hidden;
  z-index: ${props => props.theme.zIndexes.default};
  width: ${px2rem(70)};
  height: ${px2rem(70)};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${px2rem(36)};
  box-shadow: ${props => props.theme.colorV2.colorSet.grey300} 0px 0px 10px;
  background-color: ${props => props.theme.colorV2.colorSet.grey300};
  color: white;

  font-family: VideoJS;
  font-weight: normal;
  font-style: normal;

  :before {
    content: ${props => (props.playStatus ? `"\f103"` : `"\f101"`)};
  }

  @media ${MEDIA_QUERY.ONLY_MOBILE} {
    width: ${px2rem(36)};
    height: ${px2rem(36)};
    font-size: ${px2rem(24)};
  }
`;

export const VideoJsGlobalStyle = createGlobalStyle<any>`
  .video-js.vjs-has-started:not(.vjs-controls-disabled) + ${PipWrapper} {
    transform: translateY(-${px2rem(30)}); // fallback for old browser
    transform: translate3d(0, -${px2rem(30)}, 0);
  }

  .video-js.vjs-has-started.vjs-user-inactive.vjs-playing + ${PipWrapper} {
    transform: translateY(0); // fallback for old browser
    transform: translate3d(0, 0, 0);
    transition: transform 1.2s;
  }

  .vjs-fullscreen + ${PipWrapper} {
    display: none;
  }

  .video-js {
    font-size: ${px2rem(10)} !important;
    color: ${props => props.theme.colorV2.colorSet.grey300} !important;
  }

  .vjs-default-skin .vjs-big-play-button {
    font-size: 2.75em !important;
    line-height: 2em !important;
    height: 2em !important;
    width: 2em !important;
    color: ${props => props.theme.colorV2.colorSet.white1000} !important;
    /* 0.06666em = ${px2rem(2)} default */
    border: 0.06666em solid ${props =>
      props.theme.colorV2.colorSet.white1000} !important;
    /* 0.3em = ${px2rem(9)} default */
    border-radius: 50% !important;

    /* Align center */
    left: 50% !important;
    top: 50% !important;
    margin-left: -1em !important;
    margin-top: -1em !important;
  }

  /* The default color of control backgrounds is mostly black but with a little
   bit of blue so it can still be seen on all-black video frames, which are common. */
  .video-js .vjs-control-bar,
  .video-js .vjs-menu-button .vjs-menu-content {
    background-color: ${props =>
      rgba(props.theme.colorV2.colorSet.white1000, 0.9)} !important;
  }

  .vjs-mouse-display {
    display: none;
  }

  .vjs-menu-item-text {
    color: ${props =>
      rgba(props.theme.colorV2.colorSet.grey1000, 0.9)} !important;
  }

  .vjs-menu-button {
    font-size: 1em;
    line-height: 3em;
  }

  .vjs-menu li.vjs-selected,
  .vjs-menu li.vjs-selected:focus,
  .vjs-menu li.vjs-selected:hover {
    background-color: ${props =>
      rgba(props.theme.colorV2.colorSet.white800, 0.9)} !important;
    color: ${props =>
      rgba(props.theme.colorV2.colorSet.white800, 0.2)} !important
  }

  .vjs-control-bar::before {
    content: "";
    position: absolute;
    top:0;
    bottom: 0;
    left: 0;
    right:0;
    background-color: ${props =>
      rgba(props.theme.colorV2.colorSet.white1000, 0.9)};
  }

  .video-js .vjs-big-play-button {
    background-color: ${props =>
      rgba(props.theme.colorV2.colorSet.grey800, 0.2)} !important;
  }

  .video-js:hover .vjs-big-play-button,
  .video-js .vjs-big-play-button:focus {
    background-color: ${props =>
      rgba(props.theme.colorV2.colorSet.grey800, 0.2)} !important;
  }

  /* Slider - used for Volume bar and Progress bar */
  .video-js .vjs-load-progress,
  .video-js .vjs-load-progress div,
  .video-js .vjs-slider {
    background-color: ${props =>
      rgba(lighten(0.9, props.theme.colorV2.colorSet.grey50), 0.9)} !important;
  }

  /* The slider bar color is used for the progress bar and the volume bar
   (the first two can be removed after a fix that's coming) */
  .video-js .vjs-volume-level,
  .video-js .vjs-play-progress,
  .video-js .vjs-slider-bar {
    background: ${props => props.theme.colorV2.colorSet.grey300} !important;
  }

  .vjs-poster {
    img {
      width: 100%;
      height: 100%;
    }
  }

  :not(:root):fullscreen,
  .vjs-fullscreen > video {
    object-fit: contain !important;
  }

  .playButtonAnim-enter-active,
  .playButtonAnim-exit-active {
    animation: PlayAnim 600ms ease-in-out;
  }

  @keyframes PlayAnim {
    0% {
      visibility: visible;
      opacity: 0.7;
      transform: scale(1) translate(-50%, -50%);
    }

    50% {
      opacity: 1;
    }

    100% {
      opacity: 0.2;
      transform: scale(1.2) translate(-42%, -42%);
    }
  }
`;
