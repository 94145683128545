import * as React from "react";
import { IProps as SelectionBottomSheetProps } from "../index";

type IProps = SelectionBottomSheetProps & {
  inputRef: React.RefObject<HTMLInputElement>;
  scrollSectionRef: React.RefObject<HTMLDivElement>;
};

export function useHandlers(hookProps: IProps) {
  const {
    options,
    selected,
    isMultipleSelect,
    onSearch,
    onSelect,
    onNext,
    onClose,
    beforeClose,
    setSelected,
    scrollSectionRef,
  } = hookProps;

  const isSelected = React.useCallback(
    (selectedId: Moim.Id) =>
      Boolean(selected.find(selectedData => selectedData.id === selectedId)),
    [selected],
  );

  const selectOption = React.useCallback(
    (selectedId: Moim.Id) => {
      const selectedOption =
        options && options.find(option => option.value === selectedId);

      if (!selectedOption) {
        return;
      }

      if (isMultipleSelect) {
        setSelected([
          ...selected,
          {
            id: selectedOption.value,
            name: selectedOption.label,
          },
        ]);
      } else {
        setSelected([
          {
            id: selectedOption.value,
            name: selectedOption.label,
          },
        ]);
      }

      onSelect?.(selectedId);

      requestAnimationFrame(() => {
        if (scrollSectionRef.current) {
          scrollSectionRef.current.scrollTo({
            top:
              scrollSectionRef.current.scrollHeight -
              scrollSectionRef.current.clientHeight,
          });
        }
      });
    },
    [options, isMultipleSelect, setSelected, selected],
  );

  const unselectOption = React.useCallback(
    (unselectOptionId: Moim.Id) => {
      if (!isMultipleSelect) {
        return;
      }
      setSelected(
        selected.filter(selectedData => selectedData.id !== unselectOptionId),
      );
    },
    [isMultipleSelect, selected, setSelected],
  );

  const handleClickNextButton = React.useCallback(() => {
    onNext?.(selected.map(selectedData => selectedData.id));
  }, [onNext, selected]);

  const handleClickOption = React.useCallback(
    (selectedId: Moim.Id) => {
      if (isSelected(selectedId)) {
        unselectOption(selectedId);
      } else {
        selectOption(selectedId);
      }
    },
    [isSelected, unselectOption, selectOption],
  );

  const handleSearch = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const searchValue = e.currentTarget.value;
      onSearch?.(searchValue);
    },
    [onSearch],
  );

  const handleClose = React.useCallback(() => {
    beforeClose?.(selected.map(selectedData => selectedData.id));
    onClose();
    onSearch?.("");
  }, [beforeClose, onClose, onSearch, selected]);

  const handleResetData = React.useCallback(() => {
    setSelected([]);
  }, [setSelected]);

  return {
    handleClickNextButton,
    handleClickOption,
    handleSearch,
    handleClose,
    handleResetData,
    isSelected,
  };
}
