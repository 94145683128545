import * as React from "react";
import BlockitRenderer from "../..";
import { Wrapper, GridItemWrapper } from "./styled";
import { withPlacement } from "../../hoc/withPlacement";
import {
  BlockitRendererContextProvider,
  useBlockitRendererContext,
} from "../../context";

const GridItem: React.FC<Moim.Blockit.V2.IGridBlockGridItem> = ({
  blocks,
  endColumn: endX,
  endRow: endY,
  startColumn: startX,
  startRow: startY,
  verticalAlign,
  horizontalAlign,
}) => (
  <GridItemWrapper
    verticalAlign={verticalAlign}
    horizontalAlign={horizontalAlign}
    startX={startX !== undefined ? startX + 1 : undefined}
    endX={endX !== undefined ? endX + 1 : undefined}
    startY={startY !== undefined ? startY + 1 : undefined}
    endY={endY !== undefined ? endY + 1 : undefined}
  >
    {blocks.map((block, idx) => (
      <BlockitRenderer key={`${block.type}_${idx}`} block={block} />
    ))}
  </GridItemWrapper>
);
const GridBlock: React.FC<Moim.Blockit.V2.IGridBlock> = ({
  column,
  row,
  columnGap,
  rowGap,
  items,
}) => {
  const { estimatedWidth } = useBlockitRendererContext();

  return (
    <Wrapper column={column} row={row} columnGap={columnGap} rowGap={rowGap}>
      {items.map((item, index) => (
        <BlockitRendererContextProvider
          estimatedWidth={estimatedWidth - column}
        >
          <GridItem key={index} {...item} />
        </BlockitRendererContextProvider>
      ))}
    </Wrapper>
  );
};

export default withPlacement(GridBlock);
