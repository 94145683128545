import { getFlexAlignStyle } from "common/components/thread/commonThreadItem/helper";
import { css } from "styled-components";

export const textAlignStyle = css<{
  textAlign?: Moim.Forum.ForumListConfigTextAlignment;
}>`
  display: flex;
  align-items: center;

  ${(props) => getFlexAlignStyle(props.textAlign)}
`;
