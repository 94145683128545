const KEY = "MY_SHOPPING_PAYMENT_LIST_SESSION_CACHE";

const EMPTY_DATA = { data: [], paging: {} };

export const getCache = <T = any>(): T => {
  const cache = sessionStorage.getItem(KEY);
  return cache ? JSON.parse(cache) : EMPTY_DATA;
};

export const setCache = data => {
  sessionStorage.setItem(KEY, JSON.stringify(data));
};
