import React from "react";

import { IRouteComponentProps } from "app/routes/client";
import { useStoreState } from "app/store";
import PageUpdater from "common/components/pageUpdater";
import useOGData from "common/hooks/useOGData";

export function NFTCollectionShowHelmet(props: IRouteComponentProps) {
  const { match } = props;
  const contractId = match.params.contractId;

  const collection = useStoreState(
    state => state.entities.nftContracts[contractId ?? ""],
  );

  const { seoTitle, seoDescription, metaObjects } = useOGData({
    fallbackTitle: collection?.name,
    fallbackImageUrl:
      collection?.banner?.previewUrl ??
      collection?.representResources?.[0].previewUrl,
    fallbackDescription: collection?.description,
  });

  return collection ? (
    <PageUpdater
      title={seoTitle}
      description={seoDescription}
      metaObjects={metaObjects}
      path={`/collection/${collection?.id}`}
    />
  ) : null;
}
