import { Svg18BoldG, Svg18ItalicG, Svg18LinkG, Svg18MentionG, Svg18FileG, Svg18ImageG, Svg18EmojiG, Svg18SendG, Svg18TextstyleG, Svg18VideoChat } from "@moim/icons";
import * as React from "react";
import styled, { ThemeContext } from "styled-components";
// icons











import GroupInputTypes from "common/components/groupInput/type";

const SendActiveIcon = styled(Svg18SendG).attrs(props => ({
  size: "xs",
  iconColor: props.theme.colorV2.accent,
}))``;

const SendIcon = styled(Svg18SendG).attrs(props => ({
  size: "xs",
  iconColor: props.theme.colorV2.colorSet.grey100,
}))``;

const MARK_RESOURCE: Record<
  GroupInputTypes.ToolButtonType,
  {
    normal: React.ComponentType<any>;
    active: React.ComponentType<any>;
  }
> = {
  bold: {
    normal: Svg18BoldG,
    active: Svg18BoldG,
  },
  italic: {
    normal: Svg18ItalicG,
    active: Svg18ItalicG,
  },
  link: {
    normal: Svg18LinkG,
    active: Svg18LinkG,
  },
  mention: {
    normal: Svg18MentionG,
    active: Svg18MentionG,
  },
  meeting: {
    normal: Svg18VideoChat,
    active: Svg18VideoChat,
  },
  file: {
    normal: Svg18FileG,
    active: Svg18FileG,
  },
  media: {
    normal: Svg18ImageG,
    active: Svg18ImageG,
  },
  emoji: {
    normal: Svg18EmojiG,
    active: Svg18EmojiG,
  },
  send: {
    normal: SendIcon,
    active: SendActiveIcon,
  },

  textStyle: {
    normal: Svg18TextstyleG,
    active: Svg18TextstyleG,
  },
};

interface IProps {
  type: GroupInputTypes.ToolButtonType;
  isActive?: boolean;
  touch?: number;
}

const ToolButton: React.FC<IProps> = ({ type, isActive, touch }) => {
  const theme = React.useContext(ThemeContext);
  const { normal: NormalIcon, active: ActiveIcon } = React.useMemo(
    () => MARK_RESOURCE[type],
    [type],
  );

  return isActive ? (
    <ActiveIcon
      size="xs"
      touch={touch}
      role="button"
      iconColor={theme.colorV2.colorSet.grey800}
    />
  ) : (
    <NormalIcon
      size="xs"
      touch={touch}
      role="button"
      iconColor={theme.colorV2.colorSet.grey300}
    />
  );
};

export default ToolButton;
