import { Svg24CloseW } from "@moim/icons";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import styled, { css } from "styled-components";
import { Dialog } from "common/components/basicResponsiveDialog/styled";
import { AppBarModalLayout } from "common/components/modalLayout";
import { px2rem } from "common/helpers/rem";
import { TextButton } from "common/components/designSystem/buttons";

import FileUploadManage, { IRef, IItemMeta } from ".";
import { DialogContentWrapper } from "./styled";

const DialogTitleStyle = css`
  margin-top: ${px2rem(8)};
`;

interface IProps {
  open: boolean;
  initialFiles?: FileList | File[];
  onSubmit(items: IItemMeta[]): void;
  onClose(): void;
}

const FileUploadManageDialog: React.FC<IProps> = ({
  open,
  initialFiles,
  onSubmit,
  onClose,
}) => {
  const ref = React.useRef<IRef>(null);

  const handleSubmit = React.useCallback(() => {
    onSubmit(ref.current?.items ?? []);
    onClose();
  }, [onSubmit, onClose]);

  const handleClose = React.useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <AppBarModalLayout
        title={<FormattedMessage id="dialog_rearrange_image_order_title" />}
        leftElement={<CloseIcon onClick={handleClose} />}
        actionButton={
          <TextButton size="s" onClick={handleSubmit}>
            <FormattedMessage id="button_upload" />
          </TextButton>
        }
        headerWrapperStyle={DialogTitleStyle}
        customContentWrapper={DialogContentWrapper}
      >
        <FileUploadManage ref={ref} initialFiles={initialFiles} />
      </AppBarModalLayout>
    </Dialog>
  );
};

export default FileUploadManageDialog;

const CloseIcon = styled(Svg24CloseW).attrs(props => ({
  size: "s",
  touch: px2rem(24),
  iconColor: props.theme.colorV2.colorSet.grey1000,
}))``;
