import styled, { css } from "styled-components";
import { B4Regular, H10Bold } from "common/components/designSystem/typos";
import { px2rem } from "common/helpers/rem";

export const ToggleButtonGroup = styled.div`
  width: 100%;
  display: grid;
  gap: ${px2rem(8)};
  grid-template-columns: repeat(2, minmax(0, 1fr));
  padding: ${px2rem(8)} ${px2rem(16)};
`;

export const ToggleButton = styled(H10Bold).attrs({ role: "button" })<{
  selected: boolean;
}>`
  width: 100%;
  min-height: ${px2rem(50)};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${px2rem(8)} ${px2rem(16)};
  border-radius: ${px2rem(4)};
  border: ${px2rem(2)} solid ${props => props.theme.colorV2.colorSet.grey50};
  color: ${props => props.theme.colorV2.colorSet.grey800};
  text-align: center;
  user-select: none;

  ${props =>
    props.selected &&
    css`
      background-color: ${props => props.theme.colorV2.colorSet.grey10};
      border-color: ${props => props.theme.colorV2.primary.main};
    `}
`;

export const Error = styled(B4Regular)`
  padding: 0 ${px2rem(16)};
  color: ${props => props.theme.color.red};
`;
