import {
  Svg18UpvoteC,
  Svg18UpvoteFillC,
  Svg24Upvote1,
  Svg24UpvoteFill,
} from "@moim/icons";
import { useSingleLineStyle } from "common/components/designSystem/styles";
import {
  B3Regular,
  B3RegularStyle,
  B4Regular,
  B4RegularStyle,
} from "common/components/designSystem/typos";
import { MEDIA_QUERY } from "common/constants/responsive";
import { px2rem } from "common/helpers/rem";
import styled, { css } from "styled-components";

interface IIconProps {
  isActive: boolean;
  isHovered: boolean;
  disabled?: boolean;
}

const useIconHoverScaleUpTransition = css<IIconProps>`
  transition: transform 200ms ease-in;
  @media ${MEDIA_QUERY.ONLY_DESKTOP} {
    ${(props) =>
      !props.disabled &&
      props.isHovered &&
      css`
        transform: scale(1.2);
      `}
  }
`;

export const SmallWrapper = styled(B4Regular)<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colorV2.colorSet.grey300};
  ${(props) => props.disabled && `pointer-events: none;`}
`;

export const LargeWrapper = styled(B3Regular)<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colorV2.colorSet.grey800};
  ${(props) => props.disabled && `pointer-events: none;`}
`;

export const SmallUpIcon = styled(Svg18UpvoteC).attrs((props) => ({
  size: "xs",
  role: "button",
  iconColor: "currentColor",
}))<IIconProps>`
  ${useIconHoverScaleUpTransition}
`;

export const SmallUpSelectedUpIcon = styled(Svg18UpvoteFillC).attrs(
  (props) => ({
    size: "xs",
    role: "button",
    iconColor: props.theme.getReactionButtonElementPalette("like").color,
  }),
)<IIconProps>`
  ${useIconHoverScaleUpTransition}
`;

export const MiddleUpIcon = styled(Svg24UpvoteFill).attrs((props) => ({
  size: "xs",
  touch: 30,
  role: "button",
  iconColor: props.isActive
    ? props.theme.getReactionButtonElementPalette("like").color
    : "currentColor",
}))<IIconProps>`
  ${useIconHoverScaleUpTransition}
`;

export const MiddleUnSelectedUpIcon = styled(Svg24Upvote1).attrs((props) => ({
  size: "xs",
  touch: 30,
  role: "button",
  iconColor: "currentColor",
}))<IIconProps>`
  ${useIconHoverScaleUpTransition}
`;

export const LargeUpIcon = styled(Svg24UpvoteFill).attrs((props) => ({
  size: "s",
  touch: 24,
  role: "button",
  iconColor: props.isActive
    ? props.theme.getReactionButtonElementPalette("like").color
    : props.theme.colorV2.colorSet.grey800,
}))<IIconProps>`
  ${useIconHoverScaleUpTransition}
`;

export const LargeUnSelectedUpIcon = styled(Svg24Upvote1).attrs((props) => ({
  size: "s",
  touch: 24,
  role: "button",
  iconColor: props.theme.colorV2.colorSet.grey800,
}))<IIconProps>`
  ${useIconHoverScaleUpTransition}
`;

export const SmallLabel = styled.span.attrs(() => ({
  role: "button",
}))<IIconProps>`
  ${useSingleLineStyle};
  margin-left: ${px2rem(2)};
`;

export const LargeLabel = styled.span.attrs(() => ({
  role: "button",
}))<IIconProps>`
  ${useSingleLineStyle};
  margin-left: ${px2rem(4)};
`;

interface ICountProps {
  isLiked: boolean;
}
export const Count = styled.div.attrs({ role: "button" })<ICountProps>`
  transition: opacity 200ms ease-in;

  &:hover {
    opacity: 0.6;
  }

  color: ${(props) =>
    props.isLiked
      ? props.theme.colorV2.colorSet.grey800
      : props.theme.colorV2.colorSet.grey300};
  margin-left: ${px2rem(2)};
  ${useSingleLineStyle};
  ${B4RegularStyle};
  line-height: 1;
`;

export const LargeCount = styled.div.attrs({ role: "button" })<ICountProps>`
  transition: opacity 200ms ease-in;

  &:hover {
    opacity: 0.6;
  }

  margin-left: ${px2rem(4)};
  ${useSingleLineStyle};
  ${B3RegularStyle};
  line-height: 1;
`;
