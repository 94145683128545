import { ThreadItemContextProvider } from "../../context";
import Contents from "./components/contents";
import Engage from "./components/engage";
import Media from "./components/media";
import UserInfo from "./components/userInfo";
import { px2rem } from "common/helpers/rem";
import useIsMobile from "common/hooks/useIsMobile";
import React from "react";
import styled, { css } from "styled-components";

const MediaTextMixedFeedWrapper = styled.div<{ isMobile?: boolean }>`
  width: 100%;
  height: 100%;

  overflow: hidden;

  ${(props) =>
    !props.isMobile &&
    css`
      padding: ${px2rem(12)} ${px2rem(12)} ${px2rem(16)};
      border-radius: ${px2rem(16)};
      border: solid ${px2rem(1)}
        ${(props) => props.theme.colorV2.colorSet.grey50};
    `}
`;

const _MediaTextMixedFeedItem = ({
  thread,
  stat,
  config,
  className,
  children,
}: Moim.Thread.IThreadItemBaseProps) => {
  const isMobile = useIsMobile();
  return (
    <ThreadItemContextProvider config={config} stat={stat} item={thread}>
      <MediaTextMixedFeedWrapper className={className} isMobile={isMobile}>
        {children}
      </MediaTextMixedFeedWrapper>
    </ThreadItemContextProvider>
  );
};

type CompoundedComponent = typeof _MediaTextMixedFeedItem & {
  UserInfo: typeof UserInfo;
  Contents: typeof Contents;
  Media: typeof Media;
  Engage: typeof Engage;
};

const MediaTextMixedFeedItem = _MediaTextMixedFeedItem as CompoundedComponent;

MediaTextMixedFeedItem.UserInfo = UserInfo;
MediaTextMixedFeedItem.Contents = Contents;
MediaTextMixedFeedItem.Media = Media;
MediaTextMixedFeedItem.Engage = Engage;

export { MediaTextMixedFeedItem };
