import React from "react";
import _ from "lodash";

import BaseTemplate from "app/hub/createGroup/components/step/template";
import NameInput from "app/modules/commerce/components/addressManage/components/createDialog/components/name";
import CountryInput from "app/modules/commerce/components/addressManage/components/createDialog/components/country";
import AddressInput from "app/modules/commerce/components/addressManage/components/createDialog/components/address";
import NotKoreaAddressInput from "app/modules/commerce/components/addressManage/components/createDialog/components/NotKoreaAddress";
import PhoneNumberInput from "app/modules/commerce/components/addressManage/components/createDialog/components/phone";
import MemoInput from "app/modules/commerce/components/addressManage/components/createDialog/components/memo";
import { createShippingAddress } from "app/actions/commerce";

import { useIntlShort } from "common/hooks/useIntlShort";
import { useActions } from "app/store";
import useHubSeller from "common/hooks/commerce/useHubSeller";
import { validateShippingAddress } from "app/modules/commerce/components/addressManage/components/createDialog/component";
import { updateMyProfile } from "app/actions/me";
import { Contents, Wrapper } from "../styled";

export const ShippingAddress: React.FC<{
  group?: Moim.Group.IGroup | null;
  buttonText?: string;
  skipButtonText?: string;
  onNext(): void;
  onSkip?(): void;
}> = ({ group, buttonText, skipButtonText, onNext, onSkip }) => {
  const intl = useIntlShort();
  const hubSeller = useHubSeller();

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [updateData, setUpdateData] = React.useState<
    Partial<Moim.Commerce.ICommerceShippingAddress>
  >({});
  const [errors, setErrors] = React.useState<
    Record<string, string | undefined>
  >({});

  const { dispatchCreateShippingAddress, dispatchUpdateMyProfile } = useActions(
    {
      dispatchCreateShippingAddress: createShippingAddress,
      dispatchUpdateMyProfile: updateMyProfile,
    },
  );

  const visibleCountryCode =
    hubSeller?.deliveryInformation?.isAllowedAbroadShipping;

  const countryCode =
    updateData?.countryCode ?? group?.internationalizations?.[0]?.regionCode;

  const handleClickDoneButton = React.useCallback(async () => {
    try {
      if (!group?.id) {
        return;
      }
      setIsLoading(true);
      const validateResult = validateShippingAddress(intl, updateData, {
        enableCountryCodeValidate: Boolean(visibleCountryCode),
        defaultCountryCode: countryCode,
      });
      if (Object.values(validateResult).some(error => error)) {
        setErrors({
          name: validateResult.name ? validateResult.name : undefined,
          countryCode: validateResult.countryCode
            ? validateResult.countryCode
            : undefined,
          address: validateResult.address ? validateResult.address : undefined,
          phone: validateResult.phone ? validateResult.phone : undefined,
          memo: validateResult.memo ? validateResult.memo : undefined,
        });
        return;
      }
      const result = await dispatchCreateShippingAddress(
        {
          name: updateData.name ?? "",
          recipientName: updateData.name ?? "",
          recipientPhone: {
            countryCode: updateData.recipientPhoneInfo?.countryCode!,
            nationalNumber: updateData.recipientPhoneInfo?.nationalNumber!,
          },
          zipCode: updateData.zipCode!,
          address: updateData.address!,
          address2: updateData.address2,
          countryCode: updateData.countryCode,
          city: countryCode === "KR" ? undefined : updateData.city,
          state:
            countryCode === "KR" || !updateData.state
              ? undefined
              : updateData.state,
          memo: updateData.memo,
        },
        true,
        group.id,
      );

      await dispatchUpdateMyProfile(
        {
          signUpInfo: {
            shippingAddressId: result.id,
            finishedStep: "shippingAddress",
          },
        },
        undefined,
        group.id,
      );
      onNext();
    } finally {
      setIsLoading(false);
    }
  }, [
    visibleCountryCode,
    countryCode,
    updateData,
    group,
    onNext,
    dispatchCreateShippingAddress,
    dispatchUpdateMyProfile,
  ]);

  const handleChangeUpdateData = React.useCallback(
    (newUpdated: Partial<Moim.Commerce.ICommerceShippingAddress>) => {
      setUpdateData(state => ({ ...state, ...newUpdated }));
    },
    [],
  );

  return (
    <Wrapper>
      <Contents>
        <BaseTemplate
          stepData={{
            title: intl("signup_shipping_address_page_title"),
            subTitle: intl("signup_shipping_address_page_description"),
            buttonText,
            skipButtonText,
          }}
          onClick={handleClickDoneButton}
          onClickSkipButton={onSkip}
          waitingButton={isLoading}
        >
          <NameInput
            name={updateData.name}
            required={true}
            error={errors["name"]}
            onChange={handleChangeUpdateData}
          />

          {visibleCountryCode ? (
            <CountryInput
              required={true}
              error={errors["countryCode"]}
              countryCode={updateData.countryCode}
              onChange={handleChangeUpdateData}
            />
          ) : null}
          {countryCode === "KR" ? (
            <AddressInput
              required={true}
              error={errors["address"]}
              zipCode={updateData.zipCode}
              address={updateData.address}
              address2={updateData.address2}
              onChange={handleChangeUpdateData}
            />
          ) : (
            <NotKoreaAddressInput
              required={true}
              error={errors["address"]}
              zipCodeError={errors["zipCode"]}
              zipCode={updateData.zipCode}
              address={updateData.address}
              address2={updateData.address2}
              city={updateData.city}
              state={updateData.state}
              onChange={handleChangeUpdateData}
            />
          )}
          <PhoneNumberInput
            required={true}
            error={errors["phone"]}
            countryCode={updateData.recipientPhoneInfo?.countryCode}
            phone={updateData.recipientPhoneInfo?.nationalNumber}
            onChange={handleChangeUpdateData}
          />
          <MemoInput
            error={errors["memo"]}
            memo={updateData.memo}
            onChange={handleChangeUpdateData}
          />
        </BaseTemplate>
      </Contents>
    </Wrapper>
  );
};
