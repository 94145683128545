import { MoimBaseAPI } from "common/api/base";

interface IActionPayload {
  category: string;
  event: "impression" | "click" | string;
  platform: "web" | "webMobile" | "android" | "ios";

  label1?: string;
  label2?: string;
  label3?: string;
  label4?: string;

  value1?: number;
  value2?: number;
  value3?: number;
  value4?: number;

  labelKey?: {
    // NOTE: label1...4, value1...4 를 사용하면 꼭 같이 작성해주세요.
    label1?: string;
    label2?: string;
    label3?: string;
    label4?: string;

    value1?: string;
    value2?: string;
    value3?: string;
    value4?: string;
  };
  extraValues?: Record<string, string | number>;

  utmSource?: string;
  utmMedium?: string;
  utmCampaign?: string;
  utmContent?: string;

  sourceUrl?: string; // NOTE: 유저 유입 경로(ex,공동구매 초대 링크 / document.referrer)
  referralUrl?: string; // NOTE: 액션 발생 장소의 url, 즉 location.pathname.
}

export default class ActionLogAPI extends MoimBaseAPI {
  public async action(payload: IActionPayload) {
    return (await this.post("/user_activity_logs", { log: payload })).data;
  }
}
