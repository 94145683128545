import * as React from "react";
import { useStoreState } from "app/store";
import useIsMobile from "common/hooks/useIsMobile";
import { ModalContents, Dialog, ChildrenWrapper } from "./styled";

interface IProps {
  open: boolean;
  appBar: React.ReactNode;
  fullScreen?: boolean;
  onClose?(): void;
}
export default function ModalView({
  open,
  children,
  fullScreen,
  onClose,
}: React.PropsWithChildren<IProps>) {
  const isMobile = useIsMobile();
  const { isJoinGroupDialogOpen } = useStoreState(state => ({
    isJoinGroupDialogOpen: state.joinGroupDialog.open,
  }));
  const handleModalContentsClick: React.MouseEventHandler<HTMLDivElement> = React.useCallback(
    e => {
      e.stopPropagation();
    },
    [],
  );

  React.useLayoutEffect(() => {
    document.querySelector(".MuiDialog-root-ssr")?.remove();
  }, []);

  return (
    <Dialog
      hideBackdrop={true}
      open={open}
      fullScreen={fullScreen}
      onClose={onClose}
      // NOTE: https://mui.com/material-ui/api/modal/#prop-disableEnforceFocus 포커스 이슈 때문에 넣어둠
      disableEnforceFocus={!isMobile && isJoinGroupDialogOpen}
      disablePortal={isMobile}
    >
      <ModalContents onClick={handleModalContentsClick}>
        <ChildrenWrapper>{children}</ChildrenWrapper>
      </ModalContents>
    </Dialog>
  );
}
