import * as React from "react";
// hooks
import { useIntlShort } from "common/hooks/useIntlShort";
// components
import ShavedText from "common/components/shavedText";
// styled
import { Title } from "./styled";
import useThreadFetchSelector from "common/hooks/useEntitiesFetchSelectors/useThreadFetchSelector";

interface PropsType {
  event: Moim.Community.Coin.CoinHistoryEventType;
  sourceType: Moim.Community.Coin.CoinHistorySourceType;
  customTitle: string;
  amount: number;
  coinName: string;
  sourceId?: Moim.Id;
}
const CoinHistoryTitle: React.FC<PropsType> = ({
  event,
  sourceType,
  customTitle,
  amount,
  sourceId,
  coinName = "",
}) => {
  const intl = useIntlShort();

  const { threads } = useThreadFetchSelector(
    (sourceType === "unlockThread" && sourceId ? [sourceId] : []) as Moim.Id[],
  );

  const titleText = React.useMemo(() => {
    switch (event) {
      case "received": {
        switch (sourceType) {
          case "productReview": {
            return intl("candy_history_recieved_review_title");
          }
          case "purchaseItem": {
            return intl("candy_history_recieved_purchaseitem_title");
          }
          case "signUp": {
            return intl("candy_history_recieved_signup_title");
          }
          case "charge": {
            return intl("candy_history_recieved_charge_title");
          }
          case "wire": {
            return intl("candy_history_recieved_wire_title");
          }
          case "custom": {
            return intl("candy_history_token_received_title");
          }

          default: {
            return customTitle;
          }
        }
      }
      case "used": {
        switch (sourceType) {
          case "payment": {
            return intl("candy_history_used_payment_title");
          }
          default: {
            return customTitle;
          }
        }
      }
      case "returned": {
        switch (sourceType) {
          case "refund": {
            return intl("candy_history_returned_refund_title");
          }
          default: {
            return customTitle;
          }
        }
      }
      case "expired": {
        switch (sourceType) {
          case "expiration":
          default:
            return intl("candy_history_expired_title", {
              candy_name: coinName,
            });
        }
      }
      case "transferredFrom":
      case "transferTo":
      case "transferred": {
        switch (sourceType) {
          case "unlockThread":
            return intl(
              amount < 0
                ? "candy_history_get_content_title"
                : "candy_history_sell_content_title",
              { post_name: threads[0]?.title },
            );

          default:
            return intl(
              amount < 0
                ? "candy_history_token_transferred_title"
                : "candy_history_token_received_title",
            );
        }
      }
      case "recharge":
      default: {
        return customTitle;
      }
    }
  }, [event, sourceType, intl, customTitle, coinName, amount, threads]);

  return (
    <Title>
      <ShavedText line={2} value={titleText} />
    </Title>
  );
};

export default React.memo(CoinHistoryTitle);
