import * as React from "react";
import styled from "styled-components";

import ShavedText from "common/components/shavedText/v2";
import UserProfileImage from "common/components/userProfileImage";
import WithPositionChip from "common/components/withPositionChip/new";
import { H10Bold } from "common/components/designSystem/typos";

import { useStoreState } from "app/store";
import { useCurrentUserLocale } from "common/hooks/localization/useCurrentUserLocale";
import { useIntlShort } from "common/hooks/useIntlShort";
import { px2rem } from "common/helpers/rem";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: ${px2rem(8)};
  padding: ${px2rem(4)} 0;
`;

const Username = styled(H10Bold)`
  color: ${props => props.theme.colorV2.colorSet.grey800};
`;

interface IProps {
  author: Moim.Id;
  showAuthorAvatar: boolean;
  showAuthorPosition: boolean;
  anonymousData?: Moim.Forum.IAnonymousData;
  disableAnonymousSuffix?: boolean;
}

function Author({
  author: authorId,

  showAuthorAvatar,
  showAuthorPosition,
  anonymousData,
  disableAnonymousSuffix,
}: IProps) {
  const intl = useIntlShort();
  const author = useStoreState(state => state.entities.users[authorId]);
  const locale = useCurrentUserLocale();

  return (
    <Wrapper>
      {showAuthorAvatar && (
        <UserProfileImage
          src={author?.avatar_url}
          size="m"
          canOpenProfileDialog={false}
        />
      )}
      <WithPositionChip
        positions={author?.positions ?? []}
        hasPositionChip={showAuthorPosition}
      >
        <Username>
          <ShavedText line={1}>
            {anonymousData
              ? `${intl("anonymous_member")}${
                  !disableAnonymousSuffix
                    ? anonymousData.authorSuffix?.[locale]
                    : ""
                }`
              : author?.name ?? authorId}
          </ShavedText>
        </Username>
      </WithPositionChip>
    </Wrapper>
  );
}

export default React.memo(Author);
