import { AllActions } from "app/actions";
import { EntityTypes } from "app/actions/types";
import {
  nftContractNormalizer,
  nftItemNormalizer,
  nftScheduleNormalizer,
} from "app/models";
import { getInitialData } from "common/helpers/initialData";
import { produce } from "immer";

export const INITIAL_STATE_ITEM: (
  requestId?: string,
) => Record<Moim.Id, Moim.NFT.INftDetail> = (requestId?: string) => {
  const draft = {};
  const data = getInitialData("__entities.nft.item", requestId ?? "");

  if (data) {
    const result = nftItemNormalizer(data);
    Object.assign(draft, result.entities.nftItems);
  }

  return draft;
};

export const INITIAL_STATE_CONTRACT: (
  requestId?: string,
) => Record<Moim.Id, Moim.NFT.IContract> = (requestId?: string) => {
  const draft = {};
  const data = getInitialData("__entities.nft.contract", requestId ?? "");

  if (data) {
    const result = nftContractNormalizer(data);
    Object.assign(draft, result.entities.nftContracts);
  }

  return draft;
};

export const INITIAL_STATE_SCHEDULE: (
  requestId?: string,
) => Record<Moim.Id, Moim.NFT.ISchedule> = (requestId?: string) => {
  const draft = {};
  const data = getInitialData("__entities.nft.schedule", requestId ?? "");

  if (data) {
    const result = nftScheduleNormalizer(data);
    Object.assign(draft, result.entities.nftSchedules);
  }

  return draft;
};

export function nftItemReducer(
  state: Record<Moim.Id, Moim.NFT.INftDetail> = INITIAL_STATE_ITEM(),
  action: AllActions,
) {
  return produce(state, draft => {
    switch (action.type) {
      case EntityTypes.ADD_ENTITY: {
        if (action.payload.nftItems) {
          Object.entries(action.payload.nftItems).forEach(([key, value]) => {
            draft[key] = value;
          });
        }
        break;
      }

      default: {
        break;
      }
    }
  });
}

export function nftContractReducer(
  state: Record<Moim.Id, Moim.NFT.IContract> = INITIAL_STATE_CONTRACT(),
  action: AllActions,
) {
  return produce(state, draft => {
    switch (action.type) {
      case EntityTypes.ADD_ENTITY: {
        if (action.payload.nftContracts) {
          Object.entries(action.payload.nftContracts).forEach(
            ([key, value]) => {
              draft[key] = value;
            },
          );
        }
        break;
      }

      default: {
        break;
      }
    }
  });
}

export function nftScheduleReducer(
  state: Record<Moim.Id, Moim.NFT.ISchedule> = INITIAL_STATE_SCHEDULE(),
  action: AllActions,
) {
  return produce(state, draft => {
    switch (action.type) {
      case EntityTypes.ADD_ENTITY: {
        if (action.payload.nftSchedules) {
          Object.entries(action.payload.nftSchedules).forEach(
            ([key, value]) => {
              draft[key] = value;
            },
          );
        }
        break;
      }

      default: {
        break;
      }
    }
  });
}
