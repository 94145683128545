// Zeplin https://app.zeplin.io/project/5db7ff7b7ef2d22c5191d3cb/screen/5f5c3692145e177a62492dd2
import { withPlacement } from "../../hoc/withPlacement";
import { WithCarouselLink } from "../carousel/item";
import { doBlockAction as doBlockActionDispatch } from "app/actions/referenceBlock";
import { browserLocale } from "app/intl";
import { PluginPanelContext } from "app/modules/secondaryView/plugin/context";
import { useActions, useStoreState } from "app/store";
import { TextComponent } from "common/components/blockitEditorBase/components/blockitRenderer.v2/blocks/texts/component";
import { parseCommonFormat } from "common/helpers/moimDown";
import * as React from "react";

interface IProps extends Omit<Moim.Blockit.V2.ITextBlock, "content"> {
  content: React.ReactNode;
  className?: string;
}

const Texts: React.FC<IProps> = ({
  className,
  botId,
  content,
  color,
  align,
  subType_v2,
  actionId,
  params,
  href,
  border,
  textSets,
  fontWeight,
  fontFamily,
  letterSpacing,
}) => {
  const weight = fontWeight;

  const isInPluginPanel = React.useContext(PluginPanelContext);
  const { defaultLocale } = useStoreState((storeState) => ({
    defaultLocale: storeState.app.locale,
  }));
  const { doBlockAction } = useActions({
    doBlockAction: doBlockActionDispatch,
  });
  const locale = browserLocale(defaultLocale ?? undefined);
  const role = React.useMemo(
    () => (actionId ? "button" : undefined),
    [actionId],
  );
  const displayContent = React.useMemo(() => {
    if (typeof content === "string") {
      let newText = content;
      const parsedResult = parseCommonFormat(content);
      parsedResult.forEach((item) => {
        let replaceData = item.fallback;

        switch (item.type) {
          case "text_sets": {
            const textSet = textSets?.[item.value];
            if (textSet) {
              replaceData = textSet[locale].singular;
            }
            break;
          }
        }

        newText = newText.replace(item.origin, replaceData);
      });

      return (
        <span>
          <WithCarouselLink to={href}>{newText}</WithCarouselLink>
        </span>
      );
    }

    return (
      <span>
        <WithCarouselLink to={href}>{content}</WithCarouselLink>
      </span>
    );
  }, [href, content, locale, textSets]);

  const handleClick = React.useCallback(
    (e) => {
      if (botId && actionId) {
        e.preventDefault();
        e.stopPropagation();
        doBlockAction(
          {
            botId,
            data: {
              actionId,
              params,
            },
          },
          isInPluginPanel,
        );
      }
    },
    [actionId, botId, doBlockAction, isInPluginPanel, params],
  );

  return (
    <TextComponent
      role={role}
      className={className}
      color={color}
      align={align}
      border={border}
      fontWeight={weight}
      fontFamily={fontFamily}
      letterSpacing={letterSpacing}
      onClick={handleClick}
      subType_v2={subType_v2}
    >
      {displayContent}
    </TextComponent>
  );
};

export default withPlacement(Texts);
