import { Svg18CalendarB } from "@moim/icons";
import React from "react";
import styled from "styled-components";
import moment from "moment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";


import { H8BoldStyle } from "common/components/designSystem/typos";
import { px2rem } from "common/helpers/rem";

const CalendarIcon = styled(Svg18CalendarB).attrs(props => ({
  size: "xs",
  iconColor: props.theme.colorV2.colorSet.grey400,
}))``;

const InputWrapper = styled.div`
  width: 100%;
  padding: ${px2rem(8)} ${px2rem(16)};
`;

const Input = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: ${px2rem(8)};
  padding: ${px2rem(8)} ${px2rem(16)};
  border-radius: ${px2rem(4)};
  border: ${px2rem(1)} solid ${props => props.theme.colorV2.colorSet.grey50};
  input {
    color: ${props => props.theme.colorV2.colorSet.grey800};
    border: none;
    text-align: center;
    flex: 1;
    min-width: 0;

    &[value*="Y"],
    &[value*="M"],
    &[value*="D"] {
      color: ${props => props.theme.colorV2.colorSet.grey300};
    }
    &::placeholder {
      color: ${props => props.theme.colorV2.colorSet.grey300};
    }
    ${H8BoldStyle};
  }
  .MuiButtonBase-root {
    padding: 0;
  }

  .MuiInputAdornment-root {
    height: auto;
    max-height: none;
    margin: 0;
    width: ${px2rem(18)};
  }
`;

const BrowserInput = function BrowserInput(props: any) {
  const {
    inputProps,
    InputProps,
    ownerState,
    inputRef,
    error,
    ...other
  } = props;

  return (
    <Input ref={InputProps?.ref}>
      <input
        ref={inputRef}
        {...inputProps}
        {...(other as any)}
        placeholder="YYYY / MM / DD"
      />
      {InputProps?.endAdornment}
    </Input>
  );
};

export const DesktopDatePickerInput: React.FC<{
  value: string | undefined;
  onChange(value: string | undefined);
}> = ({ onChange, value }) => {
  return (
    <InputWrapper>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          openTo="year"
          views={["year", "month", "day"]}
          value={value ? moment(value) : undefined}
          onChange={newValue => {
            onChange(newValue?.format("YYYY-MM-DD"));
          }}
          slots={{
            textField: BrowserInput,
            openPickerIcon: CalendarIcon,
          }}
          format="YYYY / MM / DD"
        />
      </LocalizationProvider>
    </InputWrapper>
  );
};
