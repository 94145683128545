import React from "react";
import styled from "styled-components";
import { FlatButton } from "common/components/designSystem/buttons";
import {
  H8BoldStyle,
  H10BoldStyle,
} from "common/components/designSystem/typos";
import useGroupTexts from "common/hooks/useGroupTexts";
import useHubSeller from "common/hooks/commerce/useHubSeller";
import { useSingleLineStyle } from "common/components/designSystem/styles";
import { useIntlShort } from "common/hooks/useIntlShort";
import useCurrentGroup from "common/hooks/useCurrentGroup";

const BuyButton = styled(FlatButton)`
  width: 100%;
  flex: 1;
  padding: 0;
  ${H8BoldStyle}
`;

export const BuyNowButton: React.FC<{
  className?: string;
  buttonSize?: Moim.DesignSystem.Size;
  productType: Moim.Commerce.PRODUCT_TYPE;
  productStatus: Moim.Commerce.PRODUCT_STATUS;
  backorderAllowed?: boolean;
  fundStatus?: Moim.Commerce.FUND_STATUS;
  productStockCount?: number;
  disabled?: boolean;
  onClick: React.MouseEventHandler<HTMLElement>;
}> = React.memo(
  ({
    className,
    buttonSize,
    productType,
    productStatus,
    backorderAllowed,
    fundStatus,
    productStockCount,
    disabled,
    onClick,
  }) => {
    const intl = useIntlShort();
    const buyNowText = useGroupTexts(
      productType === "fund" ? "back_this_project" : "button_buy_now",
    );

    const buyNowTextElement = buyNowText
      ? buyNowText.singular
      : intl("button_buy_now");

    const buttonDisableStatus =
      !backorderAllowed &&
      ((productType === "fund"
        ? fundStatus !== "onSale"
        : productStatus !== "onSale") ||
        (productStockCount !== undefined && productStockCount === 0) ||
        disabled);

    return (
      <BuyButton
        className={className}
        size={buttonSize ?? "l"}
        disabled={buttonDisableStatus}
        onClick={onClick}
      >
        {buyNowTextElement}
      </BuyButton>
    );
  },
);

export const useBuyNowButtonVisible = (
  productType: Moim.Commerce.PRODUCT_TYPE,
) => {
  const hubSeller = useHubSeller();
  const currentGroup = useCurrentGroup();
  return Boolean(
    hubSeller === undefined ||
      productType === "fund" ||
      hubSeller?.config.buyNowEnabled ||
      (!hubSeller?.config.cartEnabled &&
        !currentGroup?.config.enableOpenReview),
  );
};

export const ProductShowBuyNowButton = styled(BuyNowButton)`
  flex: 1;
  padding: 0;
  ${H8BoldStyle}
`;

export const ProductItemCellBuyNowButton = styled(BuyNowButton)`
  width: 100%;
  ${useSingleLineStyle}
  ${H10BoldStyle}
`;
