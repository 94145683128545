import styled from "styled-components";

import { MEDIA_QUERY } from "common/constants/responsive";
import { px2rem } from "common/helpers/rem";

export const Wrapper = styled.div`
  /* NOTE: 45 + 8 ==> appbarHeight + margin/padding */
  height: calc(100% - ${px2rem(53)});
  @media ${MEDIA_QUERY.EXCEPT_MOBILE} {
    min-width: ${px2rem(455)};
    padding: 0 ${px2rem(24)} ${px2rem(20)};
  }
`;

export const Contents = styled.div`
  padding: 0 ${px2rem(16)};
  height: 100%;
`;
