import * as React from "react";
import { FlattenInterpolation } from "styled-components";
import { useActions, useStoreState } from "app/store";
import useCurrentGroup from "common/hooks/useCurrentGroup";
import { getProductsSelector } from "app/selectors/commerce";
import { searchSellerProducts } from "app/actions/commerce";
import ProductItemCell from "common/components/productItemCell";
import ProductItemCellSkeleton from "common/components/productItemCell/skeleton";
import InfiniteScroller from "common/components/infiniteScroller/new";
import { LoadWrapper, Loading, InfiniteScrollerWrapper } from "./styled";
import { useCommerceAnalyticsReport } from "common/hooks/commerce/useCommerceAnalytics";
import InViewTrigger from "../../../components/inViewTrigger";
import { withPlacement } from "../../../hoc/withPlacement";
import BlockitListLayout from "common/components/blockitListLayout";
import { useParseListElementConfig } from "common/components/blockitListLayout/hooks/useParseListElementConfig";
import { useParseItemLayout } from "../productListPreview/hooks";
import { ProductListFilter } from "../productListPreview/components/filter";
import { batchCommerceProductSetHandler } from "common/helpers/batchService";
import { BlockitFeedback } from "../../../components/feedback";

const LOAD_MORE_COUNT = 20;

interface IProps
  extends Omit<Moim.Blockit.V2.Commerce.IProductListBlock, "type"> {
  wrapperStyle?: FlattenInterpolation<any>;
}

const ProductList: React.FC<IProps> = ({
  resourceType,
  resourceId,
  listElement,
  itemLayout,
  itemLayout_web,
  filter,
}) => {
  const currentGroup = useCurrentGroup();
  const [isLoading, setLoadStatus] = React.useState<boolean>();
  const isInitialFetched = typeof isLoading !== "undefined";
  const [list, setList] = React.useState<Moim.IPaginatedListResponse<Moim.Id>>({
    data: [],
    paging: {},
  });
  const [selectedTagIds, setSelectedTagIds] = React.useState<
    string[][] | undefined
  >();

  const { column } = useParseListElementConfig(listElement);
  const productItemLayout = useParseItemLayout(
    resourceType === "seller" ? "seller" : "listShow",
    itemLayout,
    itemLayout_web,
  );

  const products = useStoreState(
    state => getProductsSelector(state, list.data).data,
  );

  const { dispatchSearchSellerProducts } = useActions({
    dispatchSearchSellerProducts: searchSellerProducts,
  });

  const reports = useCommerceAnalyticsReport();

  const element = React.useMemo(() => {
    const isUndefinedArray = products?.some(pd => pd?.id === undefined);

    if (!isInitialFetched || isUndefinedArray) {
      return new Array(column * 2)
        .fill(0)
        .map((_, idx) => (
          <ProductItemCellSkeleton
            key={`plp_skeleton_${idx}`}
            direction={productItemLayout?.direction}
          />
        ));
    }
    return products
      .filter(pd => Boolean(pd.isDisplayed))
      .map(product => (
        <ProductItemCell
          key={`${resourceId}_${product.id}`}
          productId={product.id}
          block={productItemLayout}
          onProductSelect={reports.reportBlockProductListProductSelect}
          onSellerSelect={reports.reportBlockProductListSellerSelect}
          onBuyNowSelect={reports.reportBlockProductListProductBuyNow}
          onAddToCartSelect={reports.reportBlockProductListProductAddToCart}
        />
      ));
  }, [
    products,
    isInitialFetched,
    column,
    productItemLayout,
    resourceId,
    reports,
  ]);

  const searchProduct = React.useCallback(
    async (paging?: Moim.IPaging, tagSetIds?: string[][]) => {
      if (currentGroup?.seller_id) {
        try {
          setLoadStatus(true);
          let result: Moim.IPaginatedListResponse<string> | undefined;

          switch (resourceType) {
            case "all": {
              result = await dispatchSearchSellerProducts(
                currentGroup.seller_id,
                {
                  limit: LOAD_MORE_COUNT,
                  after: paging?.after,
                  tagSetIds,
                },
                true,
              );
              break;
            }
            case "category": {
              if (resourceId) {
                result = await dispatchSearchSellerProducts(
                  currentGroup.seller_id,
                  {
                    categoryIds: [resourceId],
                    limit: LOAD_MORE_COUNT,
                    after: paging?.after,
                    tagSetIds,
                  },
                );
              }
              break;
            }
            case "productSet": {
              if (resourceId) {
                const productSet = await batchCommerceProductSetHandler([
                  resourceId,
                ]);

                result = await dispatchSearchSellerProducts(
                  currentGroup?.seller_id,
                  {
                    limit: LOAD_MORE_COUNT,
                    after: paging?.after,
                    tagSetIds,
                    id: productSet[0]?.productIds,
                  },
                );
              }

              break;
            }

            case "seller": {
              if (resourceId) {
                result = await dispatchSearchSellerProducts(resourceId, {
                  limit: LOAD_MORE_COUNT,
                  after: paging?.after,
                  tagSetIds,
                });
              }
              break;
            }
            case "brand": {
              if (resourceId) {
                result = await dispatchSearchSellerProducts(
                  currentGroup.seller_id,
                  {
                    limit: LOAD_MORE_COUNT,
                    after: paging?.after,
                    brandId: [resourceId],
                    tagSetIds,
                  },
                );
              }
              break;
            }
          }

          setList(state => {
            if (result) {
              return {
                data: paging ? [...state.data, ...result.data] : result.data,
                paging: result.paging,
              };
            }

            return state;
          });
        } finally {
          setLoadStatus(false);
        }
      }
    },
    [currentGroup?.seller_id, resourceType, resourceId],
  );
  const handleLoadMore = React.useCallback(async () => {
    searchProduct(list?.paging, selectedTagIds);
  }, [list?.paging, searchProduct, selectedTagIds]);

  const onChangeSelectedTags = React.useCallback(
    (selectedTags: string[][]) => {
      const tagSetIds = Object.values(selectedTags)?.filter(
        tags => tags.length,
      );
      setSelectedTagIds(tagSetIds);
      searchProduct(undefined, tagSetIds);
    },
    [selectedTagIds, searchProduct],
  );

  const isEmpty = list === null || (isInitialFetched && !list.data.length);

  return (
    <>
      <InViewTrigger onVisible={handleLoadMore} />
      {filter?.tagGroups?.length ? (
        <ProductListFilter
          tagGroupIds={filter?.tagGroups}
          onChangeSelectedTags={onChangeSelectedTags}
        />
      ) : null}
      <InfiniteScroller
        element={InfiniteScrollerWrapper}
        isLoading={isLoading}
        itemLength={element.length}
        threshold={700}
        paging={list.paging}
        loader={
          <LoadWrapper>
            <Loading />
          </LoadWrapper>
        }
        loadMore={handleLoadMore}
      >
        {isEmpty ? (
          <BlockitFeedback.Empty textKey="product_list_preview_empty" />
        ) : (
          <BlockitListLayout element={listElement}>{element}</BlockitListLayout>
        )}
      </InfiniteScroller>
    </>
  );
};

export default withPlacement(React.memo(ProductList));
