import * as React from "react";
import { FormattedMessage } from "react-intl";
// selector
import { getShippingAddressList } from "app/selectors/commerce";
// hooks
import { useActions, useStoreState } from "app/store";
import useHubSeller from "common/hooks/commerce/useHubSeller";
import useCurrentGroup from "common/hooks/useCurrentGroup";
import { useIntlShort } from "common/hooks/useIntlShort";

// actions
import {
  createShippingAddress,
  setAsDefaultShippingAddress,
  updateShippingAddress,
} from "app/actions/commerce";
// components
import ChipBase from "common/components/chips";
import { Checkbox } from "common/components/designSystem/inputs";
import AddressDeleteButton from "./components/deleteButton";
import MemoInput from "./components/memo";
import NameInput from "./components/name";
import PhoneNumberInput from "./components/phone";
import AddressInput from "./components/address";
import {
  ButtonContainer,
  CancelButton,
  DefaultAddressChipStyle,
  DefaultChipContainer,
  Divider,
  DoneButton,
  FormTable,
  Inner,
  SetDefaultContainer,
  Wrapper,
} from "./styled";
import CountryInput from "./components/country";
import NotKoreaAddressInput from "./components/NotKoreaAddress";
import { validatePhone } from "common/components/dialogs/joinGroupDialog/steps/signUpForm/form/helpers";

export interface IProps {
  open: boolean;
  shippingAddressId?: string;
  onClose(): void;
}

export const validateShippingAddress = (
  intl: ReturnType<typeof useIntlShort>,
  updateData: Partial<Moim.Commerce.ICommerceShippingAddress>,
  options: {
    enableCountryCodeValidate: boolean;
    defaultCountryCode: string | undefined;
  },
) => {
  const addressErrorValidation = () => {
    const error: {
      address?: string;
      zipCode?: string;
    } = {
      address: undefined,
      zipCode: undefined,
    };
    const zipCodeValidation = /^[0-9-]*$/g;

    if ((updateData.countryCode ?? options.defaultCountryCode) === "KR") {
      if (!updateData.address || !updateData.zipCode) {
        error.address = intl("error_form_question_required");
      }
    } else {
      if (updateData.zipCode && !zipCodeValidation.test(updateData.zipCode)) {
        error.zipCode = intl("error_add_address_zip_code_characters");
      }
      if (!updateData.address || !updateData.zipCode || !updateData.city) {
        error.address = intl("error_form_question_required");
      }
    }
    return error;
  };

  return {
    name: !updateData.name ? intl("error_form_question_required") : undefined,
    countryCode:
      options.enableCountryCodeValidate && !updateData.countryCode
        ? intl("error_form_question_required")
        : undefined,
    address: addressErrorValidation()?.address,
    zipCode: addressErrorValidation()?.zipCode,
    phone:
      !updateData.recipientPhoneInfo?.countryCode ||
      !updateData.recipientPhoneInfo?.nationalNumber
        ? intl("error_form_question_required")
        : validatePhone({
            intl,
            countryCode: updateData.recipientPhoneInfo?.countryCode,
            nationalNumber: updateData.recipientPhoneInfo?.nationalNumber,
          }),
    memo: undefined,
  };
};

const AddressCreateDialogComponent: React.FC<IProps> = ({
  open,
  shippingAddressId,
  onClose,
}) => {
  const intl = useIntlShort();
  const hubSeller = useHubSeller();
  const currentGroup = useCurrentGroup();
  const { selectedShippingAddress, originDefault } = useStoreState(state => ({
    selectedShippingAddress: shippingAddressId
      ? state.entities.commerce_shipping_address[shippingAddressId]
      : undefined,

    originDefault: Boolean(
      shippingAddressId &&
        getShippingAddressList(state)?.[0]?.id === shippingAddressId,
    ),
  }));

  const [updateData, setUpdateData] = React.useState<
    Partial<Moim.Commerce.ICommerceShippingAddress>
  >({});
  const [errors, setErrors] = React.useState<
    Record<string, string | undefined>
  >({});
  const [isDefault, setIsDefault] = React.useState<boolean>(false);
  const [loading, setLoadStatus] = React.useState(false);

  const visibleCountryCode =
    hubSeller?.deliveryInformation?.isAllowedAbroadShipping;

  const countryCode =
    updateData?.countryCode ??
    currentGroup?.internationalizations?.[0]?.regionCode;

  React.useEffect(() => {
    if (open && selectedShippingAddress) {
      setUpdateData(selectedShippingAddress);

      setIsDefault(originDefault);
    }

    if (!open) {
      setUpdateData({});
    }
  }, [open]);

  const { createAddress, updateAddress, setAsDefaultAddress } = useActions({
    createAddress: createShippingAddress,
    updateAddress: updateShippingAddress,
    setAsDefaultAddress: setAsDefaultShippingAddress,
  });

  const handleSetDefaultChange: React.ChangeEventHandler<HTMLInputElement> = React.useCallback(
    e => {
      setIsDefault(e.currentTarget.checked);
    },
    [],
  );

  const handleClickDone = React.useCallback(async () => {
    try {
      const validateResult = validateShippingAddress(intl, updateData, {
        enableCountryCodeValidate: Boolean(visibleCountryCode),
        defaultCountryCode: countryCode,
      });
      setErrors({
        name: validateResult.name ? validateResult.name : undefined,
        countryCode: validateResult.countryCode
          ? validateResult.countryCode
          : undefined,
        address: validateResult.address ? validateResult.address : undefined,
        zipCode: validateResult.zipCode ? validateResult.zipCode : undefined,
        phone: validateResult.phone ? validateResult.phone : undefined,
        memo: validateResult.memo ? validateResult.memo : undefined,
      });
      if (Object.values(validateResult).some(error => error)) {
        return;
      }
      setLoadStatus(true);

      if (shippingAddressId) {
        await updateAddress(shippingAddressId, {
          recipientName: updateData.name,
          recipientPhone:
            updateData.recipientPhoneInfo?.countryCode &&
            updateData.recipientPhoneInfo?.nationalNumber
              ? {
                  countryCode: updateData.recipientPhoneInfo?.countryCode,
                  nationalNumber: updateData.recipientPhoneInfo?.nationalNumber,
                }
              : undefined,
          zipCode: updateData.zipCode,
          address: updateData.address,
          address2: updateData.address2,
          countryCode,
          city: countryCode === "KR" ? null : updateData.city,
          state:
            countryCode === "KR" || !updateData.state ? null : updateData.state,
          memo: updateData.memo ? updateData.memo : null,
        });
        if (originDefault !== isDefault) {
          await setAsDefaultAddress(shippingAddressId);
        }
        onClose();
      } else {
        await createAddress(
          {
            name: updateData.name ?? "",
            recipientName: updateData.name ?? "",
            recipientPhone: {
              countryCode: updateData.recipientPhoneInfo?.countryCode!,
              nationalNumber: updateData.recipientPhoneInfo?.nationalNumber!,
            },
            zipCode: updateData.zipCode!,
            address: updateData.address!,
            address2: updateData.address2,
            countryCode: updateData.countryCode,
            city: countryCode === "KR" ? undefined : updateData.city,
            state:
              countryCode === "KR" || !updateData.state
                ? undefined
                : updateData.state,
            memo: updateData.memo,
          },
          isDefault,
        );

        onClose();
      }
    } finally {
      setLoadStatus(false);
    }
  }, [
    visibleCountryCode,
    shippingAddressId,
    countryCode,
    updateData,
    originDefault,
    isDefault,
    createAddress,
    onClose,
    updateAddress,
    setAsDefaultAddress,
  ]);

  const handleChangeUpdateData = React.useCallback(
    (newUpdated: Partial<Moim.Commerce.ICommerceShippingAddress>) => {
      setUpdateData(state => ({ ...state, ...newUpdated }));
    },
    [],
  );

  return (
    <Wrapper>
      <Inner>
        {originDefault && (
          <>
            <DefaultChipContainer>
              <ChipBase
                size="medium"
                shape="round"
                overrideStyle={DefaultAddressChipStyle}
              >
                <FormattedMessage id="badge_default_address" />
              </ChipBase>
            </DefaultChipContainer>
            <Divider />
          </>
        )}

        <FormTable>
          <NameInput
            name={updateData.name}
            required={true}
            error={errors.name}
            onChange={handleChangeUpdateData}
          />

          {visibleCountryCode ? (
            <CountryInput
              required={true}
              error={errors.countryCode}
              countryCode={updateData.countryCode}
              onChange={handleChangeUpdateData}
            />
          ) : null}
          {countryCode === "KR" ? (
            <AddressInput
              required={true}
              error={errors.address}
              zipCode={updateData.zipCode}
              address={updateData.address}
              address2={updateData.address2}
              onChange={handleChangeUpdateData}
            />
          ) : (
            <NotKoreaAddressInput
              required={true}
              error={errors.address}
              zipCodeError={errors.zipCode}
              zipCode={updateData.zipCode}
              address={updateData.address}
              address2={updateData.address2}
              city={updateData.city}
              state={updateData.state}
              onChange={handleChangeUpdateData}
            />
          )}
          <PhoneNumberInput
            required={true}
            error={errors.phone}
            countryCode={updateData.recipientPhoneInfo?.countryCode}
            phone={updateData.recipientPhoneInfo?.nationalNumber}
            onChange={handleChangeUpdateData}
          />
          <MemoInput
            error={errors.memo}
            memo={updateData.memo}
            onChange={handleChangeUpdateData}
          />
        </FormTable>

        {!originDefault ? (
          <>
            <Divider />
            <SetDefaultContainer>
              <Checkbox
                id="setIsDefault_checkbox"
                checked={isDefault}
                onChange={handleSetDefaultChange}
              />
              <label htmlFor="setIsDefault_checkbox">
                <FormattedMessage id="add_address_set_as_default_title" />
              </label>
            </SetDefaultContainer>
          </>
        ) : null}
        {shippingAddressId ? (
          <AddressDeleteButton
            disabled={loading}
            shippingAddressId={shippingAddressId}
            onClose={onClose}
          />
        ) : null}
        <ButtonContainer>
          <CancelButton onClick={onClose} disabled={loading}>
            <FormattedMessage id="button_cancel" />
          </CancelButton>
          <DoneButton onClick={handleClickDone} waiting={loading}>
            <FormattedMessage id="button_save" />
          </DoneButton>
        </ButtonContainer>
      </Inner>
    </Wrapper>
  );
};

export default AddressCreateDialogComponent;
