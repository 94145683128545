import * as React from "react";
import anime from "animejs";
import throttle from "lodash/throttle";
import useIsMobile from "common/hooks/useIsMobile";
import {
  Wrapper,
  LabelList,
  LabelListGradient,
  LeftArrowButton,
  RightArrowButton,
  ExpandButtonWrapper,
} from "./styled";

import {
  FilterButtonWrapper,
  FilterButton,
  FilterIcon,
  TagsCount,
} from "../tagSet/styled";

const scrollAnimationDuration = 500;

type IProps = React.PropsWithChildren<{
  tagGroups: Moim.TagSet.ITagGroup[];
  selectedAllTagsCount: number;
  openFilterDialog(): void;
}>;

export const ScrollController = ({
  children,
  tagGroups,
  selectedAllTagsCount,
  openFilterDialog,
}: IProps) => {
  const visibleExpandButton = tagGroups.length > 1;
  const isMobile = useIsMobile();
  const labelListRef = React.useRef<HTMLDivElement>();

  const [isFirst, setIsFirst] = React.useState(true);
  const [isLast, setIsLast] = React.useState(false);

  const labelScrollWithAnime = React.useCallback(
    throttle((direction: "left" | "right") => {
      requestAnimationFrame(() => {
        const node = labelListRef.current;

        if (node) {
          if (node) {
            const scrollLeftTo =
              node.scrollLeft +
              (direction === "left"
                ? -Math.floor(node.clientWidth / 2)
                : Math.floor(node.clientWidth / 2));

            anime({
              targets: node,
              scrollLeft: scrollLeftTo,
              round: 1,
              easing: "easeInSine",
              duration: scrollAnimationDuration,
            });
          }
        }
      });
    }, scrollAnimationDuration),
    [labelListRef.current],
  );

  const handlePreviousLabelButtonClick = React.useCallback(() => {
    labelScrollWithAnime("left");
  }, [labelScrollWithAnime]);

  const handleNextLabelButtonClick = React.useCallback(() => {
    labelScrollWithAnime("right");
  }, [labelScrollWithAnime]);

  const handleScrollEvent: React.UIEventHandler<HTMLDivElement> = React.useCallback(
    e => {
      const node = e.currentTarget;
      requestAnimationFrame(() => {
        if (node) {
          setIsFirst(node.scrollLeft <= 0);
          setIsLast(node.scrollLeft >= node.scrollWidth - node.clientWidth);
        }
      });
    },
    [],
  );

  const setLayoutState = React.useCallback(
    (instance: HTMLDivElement | null) => {
      if (!instance) {
        return;
      }
      labelListRef.current = instance;

      requestAnimationFrame(() => {
        setIsFirst(instance.scrollLeft <= 0);
        setIsLast(
          instance.scrollLeft >= instance.scrollWidth - instance.clientWidth,
        );
      });
    },
    [tagGroups],
  );
  return (
    <Wrapper>
      <LabelListGradient position="left" disable={isFirst} />
      <LeftArrowButton
        disabled={isFirst || isMobile}
        onClick={handlePreviousLabelButtonClick}
      />
      <LabelList ref={setLayoutState} onScroll={handleScrollEvent}>
        {children}
      </LabelList>
      <RightArrowButton
        disabled={isLast || isMobile}
        visibleExpandButton={visibleExpandButton}
        onClick={handleNextLabelButtonClick}
      />
      <LabelListGradient position="right" disable={isLast} />
      {visibleExpandButton && (
        <ExpandButtonWrapper>
          <FilterButtonWrapper>
            <FilterButton onClick={openFilterDialog}>
              <FilterIcon />
            </FilterButton>
            {selectedAllTagsCount ? (
              <TagsCount>{selectedAllTagsCount}</TagsCount>
            ) : null}
          </FilterButtonWrapper>
        </ExpandButtonWrapper>
      )}
    </Wrapper>
  );
};
