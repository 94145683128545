import { useStoreState } from "app/store";
import { browserLocale, SupportedLanguageType } from "app/intl";

export function useCurrentUserLocale() {
  const { supportedLocales, defaultLocale, userLocale } = useStoreState(
    state => {
      const groupId = state.app.currentGroupId;
      const group = state.entities.groups[groupId ?? ""];
      return {
        supportedLocales: group?.config?.locales,
        defaultLocale: group?.config?.defaultLocale as SupportedLanguageType,
        userLocale: state?.app?.locale,
      };
    },
  );

  const locale = browserLocale(userLocale, defaultLocale, supportedLocales);
  return locale;
}
