import React from "react";
import { useIntlShort } from "common/hooks/useIntlShort";
import {
  CategoryEntryButton,
  TextWrapper,
  Logo,
  FallbackLogo,
  Title,
  CategoryIcon,
} from "./styled";
import { MoimURL } from "common/helpers/url";

interface IProps {
  isSelected: boolean;
  category?: Moim.Commerce.ICategory;
  onClickItem: (category?: Moim.Commerce.ICategory, isRoot?: boolean) => void;
  isRootCategory?: boolean;
}

const CategoryItem = ({
  isSelected,
  category,
  onClickItem,
  isRootCategory,
}: IProps) => {
  const intl = useIntlShort();
  const handleClickCategory = React.useCallback(() => {
    onClickItem(category, isRootCategory);
  }, [category, isRootCategory, onClickItem]);

  const hrefUrl = React.useMemo(() => {
    if (isRootCategory) {
      return new MoimURL.CommerceCategoryAll().toString();
    }
    if (category) {
      return new MoimURL.CommerceCategories({
        id: category.id,
        section: "products",
      }).toString();
    }
    return;
  }, [category, isRootCategory]);

  const preventDefaultCallback = React.useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
    },
    [],
  );

  if (!category) {
    return (
      <CategoryEntryButton
        elementPaletteKey="menuText"
        isSelected={isSelected}
        onClick={handleClickCategory}
      >
        <TextWrapper>
          <CategoryIcon />
          <Title
            elementPaletteKey="menuText"
            href={hrefUrl}
            onClick={preventDefaultCallback}
          >
            {intl("button_all_category")}
          </Title>
        </TextWrapper>
      </CategoryEntryButton>
    );
  }
  return (
    <CategoryEntryButton
      elementPaletteKey="menuText"
      isSelected={isSelected}
      onClick={handleClickCategory}
    >
      <TextWrapper>
        {category.imageUrl ? (
          <Logo src={category.imageUrl} alt="logo" />
        ) : (
          <FallbackLogo />
        )}
        <Title
          elementPaletteKey="menuText"
          href={hrefUrl}
          onClick={preventDefaultCallback}
        >
          {category.name}
        </Title>
      </TextWrapper>
    </CategoryEntryButton>
  );
};

export default React.memo(CategoryItem);
