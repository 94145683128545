import React from "react";
import styled, { ThemeContext } from "styled-components";
import { px2rem } from "common/helpers/rem";

import ChipBase from "common/components/chips";
import { Spacer } from "common/components/designSystem/spacer";
import {
  FlatButton,
  shinyAnimationButtonStyle,
} from "common/components/designSystem/buttons";
import AlertDialog from "common/components/alertDialog";
import TokenTransferDialog from "../../../tokenTransferDialog";

import { getCoinBalance } from "app/actions/community/coin";
import { useActions, useStoreState } from "app/store";
import { useIntlShort } from "common/hooks/useIntlShort";
import useOpenState from "common/hooks/useOpenState";
import { usePostShowPermission } from "app/modules/postShow/hooks";
import { useHandleSignIn } from "common/hooks/useHandleSign";
import useCurrentUser from "common/hooks/useCurrentUser";
import { useCoinRechargeAction } from "common/hooks/commerce/useBuyNowAction";
import useCoinFetchSelector from "common/hooks/useEntitiesFetchSelectors/useCoinFetchSelector";
import { PostShowContext } from "app/modules/postShow/context";
import { MoimURL } from "common/helpers/url";

const PayMethodIcon = styled.img`
  width: ${px2rem(18)};
  height: ${px2rem(18)};
  pointer-events: none;
  margin-right: ${px2rem(8)};
`;

const AnimatedFlatButton = styled(FlatButton)`
  ${shinyAnimationButtonStyle}
  width: ${px2rem(328)};
  display:flex;
  align-items: center;
  justify-content: center;
`;
export const CoinBalanceChip = styled(ChipBase).attrs({
  shape: "rectangle",
  size: "small",
})`
  width: fit-content;
  max-width: 100%;
  height: ${px2rem(18)};

  color: ${props => props.theme.colorV2.colorSet.grey500};
  border: ${px2rem(1)} solid ${props => props.theme.colorV2.colorSet.grey100};
  border-radius: ${px2rem(10)};
  background-color: ${props => props.theme.colorV2.colorSet.white1000};
`;

const UnlockMethodInformation: React.FC<{
  unlockMethod: Moim.Forum.IThreadUnlockMethod | undefined;
}> = ({ unlockMethod }) => {
  const { post } = React.useContext(PostShowContext);
  const theme = React.useContext(ThemeContext);
  const intl = useIntlShort();
  const currentUser = useCurrentUser();
  const redirectCoinRecharge = useCoinRechargeAction();
  const onSignIn = useHandleSignIn();

  const {
    isOpen: isCoinInsufficientAlertOpen,
    open: openCoinInsufficientAlert,
    close: closeCoinInsufficientAlert,
  } = useOpenState();
  const {
    isOpen: isPermissionDeniedAlertOpen,
    open: openPermissionDeniedAlert,
    close: closePermissionDeniedAlert,
  } = useOpenState();
  const {
    isOpen: isTokenTransferDialogOpen,
    open: openTokenTransferDialog,
    close: closeTokenTransferDialog,
  } = useOpenState();
  const { hasPermission: hasUnlockPostPermission } = usePostShowPermission(
    "UNLOCK_POST",
  );

  const { coins } = useCoinFetchSelector(
    unlockMethod?.resourceId ? [unlockMethod?.resourceId] : [],
  );

  const coin = coins[0];
  const { redirectUrl } = useStoreState(state => {
    const postedMoim = post?.groupId
      ? state.entities.groups[post.groupId]
      : undefined;
    return {
      redirectUrl:
        postedMoim && postedMoim.id !== state.app.currentGroupId
          ? `${postedMoim!.url}${new MoimURL.ShowForumThread({
              forumId: post.parent_id,
              threadId: post.id,
            }).toString()}`
          : undefined,
    };
  });
  const { dispatchGetCoinBalance } = useActions({
    dispatchGetCoinBalance: getCoinBalance,
  });

  const [coinBalance, setCoinBalance] = React.useState<number>();

  const insufficientAlertButtons = React.useMemo(() => {
    if (coin?.rechargeable) {
      return [
        {
          text: intl("button_read_next"),
          textColor: theme.colorV2.colorSet.grey300,
          onClick: closeCoinInsufficientAlert,
        },
        {
          text: intl("button_reload_candy"),
          textColor: theme.colorV2.primary.main,
          onClick: () => {
            closeCoinInsufficientAlert();
            redirectCoinRecharge(coin.id);
          },
        },
      ];
    } else {
      return [
        {
          text: intl("button_read_next"),
          textColor: theme.colorV2.primary.main,
          onClick: closeCoinInsufficientAlert,
        },
      ];
    }
  }, [closeCoinInsufficientAlert, coin, intl, redirectCoinRecharge, theme]);

  const handleClickUnlockPostButton = React.useCallback(() => {
    if (redirectUrl) {
      window.open(redirectUrl, "_blank");
      return;
    }
    if (!currentUser?.id) {
      onSignIn();
      return;
    }

    if (!hasUnlockPostPermission) {
      openPermissionDeniedAlert();
      return;
    }

    if ((coinBalance ?? 0) < (unlockMethod?.quantity ?? 0)) {
      openCoinInsufficientAlert();
      return;
    }

    openTokenTransferDialog();
  }, [
    redirectUrl,
    currentUser?.id,
    hasUnlockPostPermission,
    coinBalance,
    unlockMethod?.quantity,
    openTokenTransferDialog,
    onSignIn,
    openPermissionDeniedAlert,
    openCoinInsufficientAlert,
  ]);

  React.useEffect(() => {
    if (currentUser && coin?.id) {
      dispatchGetCoinBalance(
        coin.id,
        currentUser.id,
        currentUser.metamask,
      ).then(result => setCoinBalance(result?.balance));
    }
  }, [coin?.id, currentUser]);

  return (
    <>
      <div>
        <AnimatedFlatButton size="l" onClick={handleClickUnlockPostButton}>
          <PayMethodIcon alt={coin?.name} src={coin?.imageUrl} />
          <span>
            {intl("button_send_candy_to_unlock_post", {
              n: unlockMethod?.quantity,
              unit: coin?.symbol,
            })}
          </span>
        </AnimatedFlatButton>
      </div>
      {currentUser && coinBalance !== undefined && (
        <>
          <Spacer value={8} />
          <CoinBalanceChip>
            {intl("quantity_candy", {
              n: coinBalance,
              unit: coin?.symbol,
            })}
          </CoinBalanceChip>
        </>
      )}

      <AlertDialog
        open={isCoinInsufficientAlertOpen}
        title={intl("alert_lack_of_candy_title")}
        content={intl("alert_lack_of_candy_description")}
        rightButtons={insufficientAlertButtons}
        onClose={closeCoinInsufficientAlert}
      />
      <AlertDialog
        open={isPermissionDeniedAlertOpen}
        title={intl("alert_no_right_to_unlock_content_title")}
        content={intl("alert_no_right_to_unlock_content_description")}
        rightButtons={[
          {
            text: intl("button_read_next"),
            textColor: theme.colorV2.primary.main,
            onClick: closePermissionDeniedAlert,
          },
        ]}
        onClose={closePermissionDeniedAlert}
      />
      <TokenTransferDialog
        isOpen={isTokenTransferDialogOpen}
        onClose={closeTokenTransferDialog}
        coinBalance={coinBalance ?? 0}
      />
    </>
  );
};

export default UnlockMethodInformation;
