export function tagSetBlockExtractor(
  contents: Moim.Blockit.Blocks[],
): {
  contentsWithoutTagSet: Moim.Blockit.Blocks[];
  selectedTags: Record<string, string[]>;
} {
  const tagSetBlocks = contents.filter(block => block.type === "tagSet");

  return {
    contentsWithoutTagSet: contents.filter(block => block.type !== "tagSet"),
    selectedTags: tagSetBlocks.reduce<Record<string, string[]>>(
      (result, current) => {
        if (result[current.setId]) {
          result[current.setId].push(current.valueId);
        } else {
          result[current.setId] = [current.valueId];
        }
        return result;
      },
      {},
    ),
  };
}
