import styled, { FlattenInterpolation } from "styled-components";
import { px2rem } from "app/common/helpers/rem";
import { TOP_NAVIGATION_HEIGHT } from "app/modules/layout/components/topNavigation/constant";
import { TOP_BANNER_HEIGHT as NOTIFICATION_REQUEST_BANNER_HEIGHT } from "common/components/topBanner/constants";
import { MEDIA_QUERY } from "common/constants/responsive";
import { BlockitRenderMode } from "common/components/blockitEditorBase/components/blockitRenderer.v2/context";

export const LABEL_ITEM_DESKTOP_HEIGHT = 62;
export const LABEL_ITEM_MOBILE_HEIGHT = 48;

export const Section = styled.div<{
  overrideStyle?: FlattenInterpolation<any>;
  isTopBannerOpen?: boolean;
  renderMode?: BlockitRenderMode;
}>`
  width: 100%;

  z-index: ${props =>
    props.renderMode === "live"
      ? props.theme.zIndexes.gnbSticky
      : props.theme.zIndexes.wrapper - 1};
  padding: ${px2rem(15)} ${px2rem(16)};

  @media ${MEDIA_QUERY.ONLY_MOBILE} {
    padding: ${px2rem(8)} ${px2rem(16)};
    height: ${px2rem(LABEL_ITEM_MOBILE_HEIGHT)};
    min-width: 100%;
    top: ${props =>
      props.isTopBannerOpen
        ? px2rem(TOP_NAVIGATION_HEIGHT + NOTIFICATION_REQUEST_BANNER_HEIGHT)
        : px2rem(TOP_NAVIGATION_HEIGHT)};
  }

  @media ${MEDIA_QUERY.EXCEPT_MOBILE} {
    width: 100%;
    height: ${px2rem(LABEL_ITEM_DESKTOP_HEIGHT)};
    top: 0;
  }
`;
