import { combineReducers } from "redux";
import * as UserThreadReducer from "./threads";

export const INITIAL_STATE = {
  threads: UserThreadReducer.INITIAL_STATE,
};

export type IUserPageDataState = typeof INITIAL_STATE;

export const reducer = combineReducers({
  threads: UserThreadReducer.reducer,
});
