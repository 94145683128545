import * as CookieHandler from "common/helpers/cookieHandler";
import * as ExpiredInMemoryHelper from "common/helpers/expiredInMemoryHelper";
import {
  CRYPTO_BADGE_TOKEN_KEY,
  CRYPTO_BADGE_TOKEN_KEY_FOR_GROUP,
} from "common/constants/keys";

const ONE_HOUR_MILLISECONDS_NUMBER = 60 * 60 * 1000;

export function setCryptoBadgeToken(token: string) {
  ExpiredInMemoryHelper.set(
    CRYPTO_BADGE_TOKEN_KEY,
    token,
    ONE_HOUR_MILLISECONDS_NUMBER,
  );
}

export function setOAuthTokenForGroup(authentication: Moim.IAuthentication) {
  CookieHandler.remove(CRYPTO_BADGE_TOKEN_KEY_FOR_GROUP);

  ExpiredInMemoryHelper.set(
    CRYPTO_BADGE_TOKEN_KEY_FOR_GROUP,
    authentication,
    ONE_HOUR_MILLISECONDS_NUMBER,
  );
}

export function getCryptoBadgeToken() {
  return ExpiredInMemoryHelper.get<string>(CRYPTO_BADGE_TOKEN_KEY);
}

export function getOAuthTokenForGroup(): Moim.IAuthentication | null {
  return ExpiredInMemoryHelper.get<Moim.IAuthentication>(
    CRYPTO_BADGE_TOKEN_KEY_FOR_GROUP,
  );
}

export function removeCryptoBadgeToken() {
  ExpiredInMemoryHelper.remove(CRYPTO_BADGE_TOKEN_KEY);
}
