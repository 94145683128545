// 주의: 무조건 Thread Component 내에서만 사용해야됨 (context 때문)
import { enWordKeepAllStyle } from "common/components/designSystem/styles";
import { B3RegularStyle } from "common/components/designSystem/typos";
import React from "react";
// import { isEqual } from "lodash";
import styled, { css } from "styled-components";

const ThreadDescription = styled.div<{
  isUnread?: boolean;
}>`
  ${B3RegularStyle};
  ${enWordKeepAllStyle};

  color: ${(props) => props.theme.colorV2.colorSet.grey600};
  ${(props) =>
    props.isUnread &&
    css`
      font-weight: ${props.theme.font.medium} !important;
    `}
`;

const HiddenArea = styled.div`
  position: fixed;
  top: -99999;
  left: -99999;
  visibility: hidden;
`;

export const ThreadDescriptionWrapper = ({
  children,
  className,
  isUnread,
  descriptionPlain,
}: {
  className?: string;
  isUnread?: boolean;
  descriptionPlain?: string;
  children: (value: React.ReactNode) => React.ReactElement<HTMLElement>;
}) => {
  return (
    <ThreadDescription className={className} isUnread={isUnread}>
      {children(descriptionPlain)}
    </ThreadDescription>
  );
};
