import { Svg24CloseCircleB2, Svg18AddG } from "@moim/icons";
import styled, { css } from "styled-components";
import { px2rem } from "common/helpers/rem";
import { B4RegularStyle } from "common/components/designSystem/typos";



export const Wrapper = styled.div`
  user-select: none;
  width: ${px2rem(80)};
  height: ${px2rem(80)};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

export const DeleteButton = styled.div.attrs({ role: "button" })`
  position: absolute;
  width: ${px2rem(24)};
  height: ${px2rem(24)};
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  pointer-events: none;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  z-index: ${props => props.theme.zIndexes.default + 1};
  transition: visibility 100ms ease-in;
`;

export const ImageThumbContainer = styled.div`
  position: relative;
  margin: ${px2rem(4)} 0;
  width: ${px2rem(49)};
  height: ${px2rem(49)};

  ::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    inset: 0;
    border: ${px2rem(2)} solid ${props => props.theme.colorV2.primary.main};
    z-index: ${props => props.theme.zIndexes.default};
    visibility: hidden;

    transition: visibility 100ms ease-in;
  }
  :hover {
    ::before {
      visibility: visible;
    }

    ${DeleteButton} {
      visibility: visible;
      pointer-events: initial;
    }
  }
`;

export const ImageThumb = styled.img<{ isLoading?: boolean }>`
  width: 100%;
  height: 100%;
  object-fit: cover;
  will-change: filter, backdrop-filter;

  transition-property: filter, backdrop-filter;
  transition-duration: 500ms;
  transition-timing-function: ease-in;
  filter: blur(1px);
  backdrop-filter: blur(1px);

  ${props =>
    !props.isLoading &&
    css`
      filter: blur(0);
      backdrop-filter: blur(0);
    `}
`;

export const OrderLabel = styled.div`
  width: 100%;
  padding: 0 ${px2rem(4)} ${px2rem(7)};
  color: ${props => props.theme.colorV2.colorSet.grey300};
  text-align: center;
  ${B4RegularStyle}
`;

export const AddImageThumbContainer = styled.div.attrs({ role: "button" })`
  position: relative;
  margin: ${px2rem(4)} 0;
  width: ${px2rem(49)};
  height: ${px2rem(49)};
  background-color: ${props => props.theme.colorV2.colorSet.grey10};
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${props => props.theme.colorV2.colorSet.grey50};
`;

export const DeleteIcon = styled(Svg24CloseCircleB2).attrs({
  size: "s",
})``;

export const AddIcon = styled(Svg18AddG).attrs(props => ({
  size: "xs",
  iconColor: props.theme.colorV2.colorSet.grey200,
}))``;
