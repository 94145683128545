import * as React from "react";
import { useStoreState } from "app/store";
import { useOpenProfileDialog } from "common/hooks/profileDialog";
import useBufferedUserPostingCountFetch from "../../bufferedStatFetch";
import usePositionFetchSelector from "common/hooks/useEntitiesFetchSelectors/usePositionFetchSelector";
import ShavedTextV2 from "common/components/shavedText/v2";
import UserProfileImage from "common/components/userProfileImage";
import PostWritingStat from "./components/postWritingStat";
import PositionsAndBadges from "./components/positionsAndBadges";
import {
  Wrapper,
  AvatarPlacement,
  DetailContainer,
  Username,
  Description,
} from "./styled";
import { THREAD_TYPE } from "app/typings/thread";

interface IProps {
  user: Moim.User.IOriginalUserDatum & {
    certifications?: Moim.User.NormalizedCertificationList;
  };
  types?: THREAD_TYPE[];
  showConfig?: Record<string, any>;
}

const DetailedUserProfilePreview: React.FC<IProps> = ({
  user,
  types: _types,
}) => {
  const types = _types ?? ["post"];
  const userId = user.id;

  const isIdPositions =
    user.positions?.length && typeof user.positions[0] === "string";
  const { positions } = usePositionFetchSelector(
    isIdPositions ? user.positions : [],
  );
  const dispatch = useBufferedUserPostingCountFetch();
  const typeKey = types.join("|");
  const postCount = useStoreState(
    state =>
      state.userPageData.threads[userId]?.typeThreadsCount[typeKey]?.total ?? 0,
  );

  const openProfileDialog = useOpenProfileDialog();

  const handleUsernameClick = React.useCallback(
    e => {
      openProfileDialog(user.id, {
        current: e.currentTarget,
      });
    },
    [openProfileDialog, user.id],
  );

  React.useEffect(() => {
    if (userId) {
      dispatch(userId, types);
    }
  }, [dispatch, userId]);

  return (
    <Wrapper>
      <AvatarPlacement>
        <UserProfileImage
          size="xl"
          role="button"
          aria-label="footer author avatar"
          userId={user.id}
          src={user.avatar_url}
        />
      </AvatarPlacement>
      <DetailContainer>
        <Username role="" aria-label={user.name} onClick={handleUsernameClick}>
          {user.name}
        </Username>
        <PositionsAndBadges
          userId={user.id}
          canId={user.canId}
          positions={isIdPositions ? positions : user.positions}
          badges={user.certifications}
        />
        <Description>
          <ShavedTextV2 line={2}>{user.bio}</ShavedTextV2>
        </Description>
      </DetailContainer>
      <PostWritingStat userId={user.id} postCount={postCount} />
    </Wrapper>
  );
};

export default DetailedUserProfilePreview;
