import { deleteBookmark, postBookmark } from "app/actions/bookmark";
import { deleteThread, voteThread } from "app/actions/forum";
import { useRedirectPostShowModal } from "app/modules/postShow/modal/hooks";
import { useActions, useStoreState } from "app/store";
import { useShareHandler } from "common/components/snsShareDialog/utils";
import { AnalyticsClass } from "common/helpers/analytics/analytics";
import useCancelToken from "common/hooks/useCancelToken";
import useCurrentUser from "common/hooks/useCurrentUser";
import { useHandleSignIn } from "common/hooks/useHandleSign";
import { useIntlShort } from "common/hooks/useIntlShort";
import React from "react";

export const useEngagementHandler = (thread: Moim.Forum.IThread) => {
  const channelId = thread.parent_id;
  const threadId = thread.id;
  const intl = useIntlShort();
  const redirectPostShowModal = useRedirectPostShowModal({
    postId: thread.id,
    groupId: thread.groupId,
    scrollToBottom: true,
  });

  const shareHandler = useShareHandler({
    shareData: {
      url: location.origin + location.pathname + location.search,
    },
  });

  const currentUser = useCurrentUser();
  const cancelToken = useCancelToken();

  const {
    dispatchVoteThread,
    dispatchPostBookmark,
    dispatchDeleteBookmark,
    dispatchDeleteThread,
  } = useActions({
    dispatchVoteThread: voteThread,
    dispatchPostBookmark: postBookmark,
    dispatchDeleteBookmark: deleteBookmark,
    dispatchDeleteThread: deleteThread,
  });

  const { group, isLoadingToLike, joined, currentGroupId } = useStoreState(
    (state) => ({
      group: thread.groupId ? state.entities.groups[thread.groupId] : undefined,
      isLoadingToLike: state.forumData.isLoadingToLike,
      joined: thread.groupId
        ? state.subgroupsData.joinedSubMoims.data.includes(thread.groupId)
        : undefined,
      currentGroupId: state.app.currentGroupId,
    }),
  );

  const onSignIn = useHandleSignIn();
  const isSigned = React.useMemo(
    () => joined ?? Boolean(currentUser),
    [currentUser, joined],
  );

  const handleSignIn = React.useCallback(() => {
    if (group && group.id !== currentGroupId) {
      window.open(group.url, "_blank");
    } else {
      onSignIn();
    }
  }, [onSignIn, currentGroupId, group]);

  const getAuthorizedHandler = React.useCallback(
    (handler: any) => {
      if (isSigned) {
        return handler;
      } else {
        return handleSignIn;
      }
    },
    [isSigned, handleSignIn],
  );

  const handleReaction = React.useCallback(
    (status: Moim.Enums.VoteStatus) => {
      if (currentUser && !isLoadingToLike) {
        dispatchVoteThread({
          channelId: thread.parent_id,
          threadId: thread.id,
          groupId: thread.groupId,
          type: status,
          cancelToken: cancelToken.current.token,
        });

        if (status === null) {
          AnalyticsClass.getInstance().forumPostReactCancel({
            forumId: thread.parent_id,
            postId: thread.id,
            reactType: thread.vote?.type ?? "upvote",
          });
        } else {
          AnalyticsClass.getInstance().forumPostReact({
            forumId: thread.parent_id,
            postId: thread.id,
            reactType: status,
          });
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      currentUser,
      isLoadingToLike,
      dispatchVoteThread,
      thread.parent_id,
      thread.id,
      thread.groupId,
      cancelToken,
    ],
  );

  const handleCommentClick = React.useCallback(() => {
    redirectPostShowModal();
  }, [redirectPostShowModal]);

  const handleBookmark = React.useCallback(() => {
    dispatchPostBookmark(
      { forumId: channelId, threadId },
      {
        succeed: intl("toast_message_bookmark_success"),
        failed: intl("toast_message_bookmark_failure"),
      },
      cancelToken.current.token,
      thread.groupId,
    );
  }, [
    cancelToken,
    dispatchPostBookmark,
    intl,
    channelId,
    threadId,
    thread.groupId,
  ]);

  const handleUnBookmark = React.useCallback(() => {
    dispatchDeleteBookmark(
      { forumId: channelId, threadId },
      {
        succeed: intl("toast_message_bookmark_cancel_success"),
        failed: intl("toast_message_bookmark_cancel_failure"),
      },
      cancelToken.current.token,
      thread.groupId,
    );
  }, [
    cancelToken,
    dispatchDeleteBookmark,
    intl,
    channelId,
    threadId,
    thread.groupId,
  ]);

  const handleDeleteThread = React.useCallback(() => {
    if (thread) {
      dispatchDeleteThread(
        {
          forumId: thread.parent_id,
          threadId: thread.id,
        },
        thread.groupId,
      );
    }
  }, [dispatchDeleteThread, thread.id, thread.parent_id, thread.groupId]);

  return {
    handleReaction: getAuthorizedHandler(handleReaction),
    handleUpCountClick: () => {},
    handleDownCountClick: () => {},
    handleCommentClick,
    handleShareClick: shareHandler,
    handleBookmarkClick: getAuthorizedHandler(
      !thread.is_bookmarked ? handleBookmark : handleUnBookmark,
    ),
    handleDeleteThread,
  };
};
