import * as React from "react";
import { ISelectedData } from "common/components/searchInput/selectable/type";
import RemovableChip from "common/components/removableChip";

interface IProps {
  selected: ISelectedData[];
  onClickSelectedItem?: (id: Moim.Id) => void;
  onClickSelectedItemRemoveButton?: (id: Moim.Id) => void;
  hideProfileImage?: boolean;
}

const SelectedList = ({
  selected,
  onClickSelectedItem,
  onClickSelectedItemRemoveButton,
  hideProfileImage,
}: IProps) => {
  if (selected.length === 0) {
    return null;
  }

  return (
    <>
      {selected.map(selectData => (
        <SelectItem
          key={selectData.id}
          selectData={selectData}
          onClickSelectedItem={onClickSelectedItem}
          onClickSelectedItemRemoveButton={onClickSelectedItemRemoveButton}
          hideProfileImage={hideProfileImage}
        />
      ))}
    </>
  );
};

export default SelectedList;

function SelectItem({
  selectData,
  onClickSelectedItem,
  onClickSelectedItemRemoveButton,
  hideProfileImage,
}: {
  selectData: ISelectedData;
  onClickSelectedItem?: (id: Moim.Id) => void;
  onClickSelectedItemRemoveButton?: (id: Moim.Id) => void;
  hideProfileImage?: boolean;
}) {
  return (
    <RemovableChip
      id={selectData.id}
      name={selectData.name}
      image={selectData.image}
      onClick={onClickSelectedItem}
      onClickRemoveButton={onClickSelectedItemRemoveButton}
      hideProfileImage={hideProfileImage}
    />
  );
}
