import {
  AppBarStickyWrapperStyle,
  AppBarTitleUsername,
  AppBarTitleWrapper,
  ParallaxUsername,
  ParallaxUsernameWrapper,
  ParallaxWrapper,
} from "../../styled";
import { DefaultLayout } from "app/modules/secondaryView/native/layout";
// selector
// actions
import { NativeEmojiSafeText } from "common/components/designSystem/texts";
// component
import ProfileHeader from "common/components/profileHeader";
import ShavedText from "common/components/shavedText";
import UserProfileImage from "common/components/userProfileImage";
import * as React from "react";

const SecondaryViewLayout = ({
  user,
  children,
}: React.PropsWithChildren<{
  user: Moim.User.INormalizedUser | undefined | null;
}>) => {
  return (
    <DefaultLayout
      appBar={{
        wrapperStickyStyle: AppBarStickyWrapperStyle,
        titleElement: (
          <AppBarTitleWrapper>
            <UserProfileImage
              userId={user?.id}
              size="xs"
              src={user?.avatar_url || ""}
              canOpenProfileDialog={false}
              isOnline={user?.presence === "ACTIVE"}
              enableBlockedPlaceholder={true}
            />
            <AppBarTitleUsername>
              <NativeEmojiSafeText value={user?.name ?? ""} />
            </AppBarTitleUsername>
          </AppBarTitleWrapper>
        ),
        titleAlignment: "Left",
        enableScrollParallax: true,
        parallaxWrapperComponent: ParallaxWrapper,
        titleContainerDisappearPosition: 20,
        parallaxDisappearPosition: 110,
        expendScrollParallaxElement: [
          <ProfileHeader
            type="show"
            userId={user?.id ?? ""}
            avatar={user?.avatar_url || ""}
            isOnline={user?.presence === "ACTIVE"}
          />,
          <ParallaxUsernameWrapper>
            <ParallaxUsername>
              <ShavedText
                value={<NativeEmojiSafeText value={user?.name ?? ""} />}
                line={2}
              />
            </ParallaxUsername>
          </ParallaxUsernameWrapper>,
        ],
      }}
    >
      {children}
    </DefaultLayout>
  );
};

export default SecondaryViewLayout;
