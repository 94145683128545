import React from "react";

import styled from "styled-components";
import { FormattedMessage } from "react-intl";

import { ConvertedFieldType } from "common/hooks/commerce/useConvertPaymentCustomFields";

import {
  SectionContents,
  SectionTitle,
  SectionWrapper,
  Title,
  Wrapper,
} from "../common";

import { Spacer } from "common/components/designSystem/spacer";
import { DefaultDivider } from "common/components/divider";
import { H10Bold } from "common/components/designSystem/typos";
import useCurrentGroup from "common/hooks/useCurrentGroup";

const ImageText = styled(H10Bold)`
  color: ${props => props.theme.color.blue};
  cursor: pointer;
`;

interface AdditionalInfoProps {
  customFields?: ConvertedFieldType[];
  handleImageClick: (
    e: React.MouseEvent<HTMLButtonElement>,
    _groupId: string,
    fileId: string,
    isPrivate?: boolean,
  ) => Promise<void>;
}

const FieldTitle = styled(SectionTitle)`
  width: 100%;
`;

const DividingLine = () => (
  <>
    <Spacer value={8} />
    <DefaultDivider />
    <Spacer value={8} />
  </>
);

const AdditionalInfo = ({
  customFields,
  handleImageClick,
}: AdditionalInfoProps) => {
  const currentGroup = useCurrentGroup();
  const groupId = currentGroup?.id ?? "";

  const renderCustomFieldValue = (customField: ConvertedFieldType) => {
    if (customField.type === "image") {
      return (
        <>
          {customField.value === "-" ? (
            customField.value
          ) : (
            <button
              onClick={async e =>
                handleImageClick(e, groupId, customField.value, true)
              }
            >
              <ImageText>{customField.title}</ImageText>
            </button>
          )}
        </>
      );
    }

    if (
      customField.type === "textarea" &&
      typeof customField.value === "string"
    ) {
      const lines = customField.value.split("\n");
      return (
        <>
          {lines.map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          ))}
        </>
      );
    }

    return customField.value ?? "-";
  };

  return (
    <Wrapper>
      <Title>
        <FormattedMessage id="my_shopping_purchase_details_order_additional_info_title" />
      </Title>
      {customFields?.map((customField, index) => (
        <>
          <SectionWrapper key={index} narrow>
            <FieldTitle>{customField.label}</FieldTitle>
            <SectionContents wordBreak="break-all">
              {renderCustomFieldValue(customField)}
            </SectionContents>
          </SectionWrapper>
          {index !== customFields.length - 1 && <DividingLine />}
        </>
      ))}
    </Wrapper>
  );
};

export default AdditionalInfo;
