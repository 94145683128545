import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Title, Wrapper, Section } from "../common";
import DeliveryPolicyGuideButton from "app/modules/commerce/components/deliveryPolicyGuideButton";

interface IProps {
  name?: string;
  phone?: string;
  address?: string | React.ReactNode;
  memo?: string;
  buttonText?: string;
  termId?: string;
}
export default function ShippingInformation({
  name,
  phone,
  address,
  memo,
  buttonText,
  termId,
}: IProps) {
  return (
    <Wrapper>
      <Title>
        <FormattedMessage id="my_shopping/purchase_details/shipping_info_title" />
      </Title>
      <Section
        title={
          <FormattedMessage id="my_shopping/purchase_details/recipient_name" />
        }
        contents={name}
      />
      <Section
        title={
          <FormattedMessage id="my_shopping/purchase_details/recipient_phone_number" />
        }
        contents={phone}
      />
      <Section
        title={
          <FormattedMessage id="my_shopping/purchase_details/recipient_address" />
        }
        contents={address}
      />
      <Section
        title={
          <FormattedMessage id="my_shopping/purchase_details/recipient_shipping_memo" />
        }
        contents={memo ?? "-"}
      />
      <DeliveryPolicyGuideButton buttonText={buttonText} termId={termId} />
    </Wrapper>
  );
}
