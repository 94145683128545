import * as React from "react";
import styled from "styled-components";
import { useResizeDetector } from "react-resize-detector";
import useUserFetchSelector from "common/hooks/useEntitiesFetchSelectors/useUserFetchSelector";
import SimpleUserProfilePreview from "common/components/blockitEditorBase/components/blockitRenderer/components/userProfilePreview/components/simple";
import DetailedUserProfilePreview from "common/components/blockitEditorBase/components/blockitRenderer/components/userProfilePreview/components/detailed";
import { px2rem } from "common/helpers/rem";

const Spacer = styled.div`
  width: 100%;
  height: ${px2rem(96)};
`;

const OversizeWrapper = styled.div<{ minHeight: number }>`
  min-height: ${props => px2rem(props.minHeight)};
  > div {
    position: absolute;
    left: 0;
    right: 0;
  }
`;

interface IProps {
  type: "simple" | "detailed";
  userId?: Moim.Id;
}

const BottomPostAuthor: React.FC<IProps> = ({ type, userId }) => {
  const {
    isLoading,
    users: { 0: user },
  } = useUserFetchSelector(userId ? [userId] : []);

  const { height: detailComponentHeight, ref } = useResizeDetector({
    handleHeight: true,
  });

  if (!user) {
    if (isLoading) {
      return <div>skeleton....</div>;
    }
    return null;
  }

  return (
    <>
      <Spacer />
      {type === "simple" ? (
        <SimpleUserProfilePreview user={user} />
      ) : (
        <OversizeWrapper minHeight={detailComponentHeight ?? 334}>
          <div ref={ref}>
            <DetailedUserProfilePreview user={user} />
          </div>
        </OversizeWrapper>
      )}
    </>
  );
};

export default BottomPostAuthor;
