import styled, { css } from "styled-components";
import { TopNaviBlockPropertyStyle } from "../section/styled";
import { px2rem } from "common/helpers/rem";

export const Container = styled.div<{
  element?: Partial<Moim.Layout.Navigation.MenuElementType>;
}>`
  display: flex;
  align-items: center;
  ${TopNaviBlockPropertyStyle}

  ${({ element }) => {
    const height = element?.height;
    switch (height?.type) {
      case "fixed":
        return `height: ${px2rem(height.value)};`;
    }
  }}

  > [class^="Wrapper"] {
    justify-content: ${({ element }) => {
      switch (element?.align) {
        default:
        case "left":
          return "flex-start";
        case "center":
          return "center";
        case "right":
          return "flex-end";
      }
    }};
  }

  [class^="Inner"] {
    text-align: ${({ element }) => element?.align ?? "inherit"};
  }

  ${({ theme, element }) => {
    const textColor = element?.textColor;
    return textColor
      ? css`
          --menu-text-600: ${theme.getBlockitColorValue(textColor, "grey600")};
          --menu-text-800: ${theme.getBlockitColorValue(textColor, "grey800")};
        `
      : null;
  }}
`;
