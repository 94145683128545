import * as qs from "query-string";
import { storeMoimTokenToCookie } from "common/helpers/authentication";

export function storeOnboardingTokenToCookie() {
  const queryParams = qs.parse(location.search);
  const onboardingWizardToken = queryParams[
    "onboarding-wizard-token"
  ] as string;

  if (!onboardingWizardToken) return;

  const onboardingWizardMoimId = queryParams["moim-id"] as string;

  const moimTokenData = {
    access_token: onboardingWizardToken,
    token_type: "Bearer",
    expires_in: 0,
    refresh_token: "",
  };

  storeMoimTokenToCookie(onboardingWizardMoimId, moimTokenData);
}
