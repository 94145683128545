import * as React from "react";
import { FormattedMessage } from "react-intl";
import { MoimURL } from "common/helpers/url";
// hooks
import { useOpenStateWithHashRoute } from "common/hooks/useOpenState";
import useRedirect from "common/hooks/useRedirect";
import { useNativeSecondaryView } from "common/hooks/useSecondaryView";
import { useCoinRechargeAction } from "common/hooks/commerce/useBuyNowAction";
// components
import { B4Regular } from "common/components/designSystem/typos";
import CoinTransferDialog from "../../../transferDialog";
import CurrencyFormatter from "common/components/currencyFormatter";
// styled
import {
  HeaderButtonWrapper,
  MoreIcon,
  PointButtonWrapper,
  SendButton,
  SendButtonWrapper,
  PointRightContainer,
  ExpirableWrapperDivider,
  ButtonContainer,
} from "./styled";

const HASH_KEY = "transfer-dialog";

interface IProps {
  coin?: Moim.Community.Coin.ICoin;
  balance?: number;
}

const CoinHeaderButton: React.FC<IProps> = ({ coin, balance }) => {
  const { isOpen, open, close } = useOpenStateWithHashRoute(HASH_KEY);
  const redirect = useRedirect();
  const { close: closeSecondView } = useNativeSecondaryView();
  const openBuyNowDialog = useCoinRechargeAction();

  const handleClick = React.useCallback(() => {
    if (coin?.id) {
      redirect(new MoimURL.CoinToBeExpired({ coinId: coin.id }).toString());
    }
  }, [coin?.id, redirect]);

  const handleClickRecharge = React.useCallback(() => {
    if (coin?.id) {
      openBuyNowDialog(coin.id);
    }
    closeSecondView();
  }, []);

  const handleOpenTransferDialog = React.useCallback(() => {
    open();
  }, []);

  const handleClose = React.useCallback(() => {
    close();
  }, []);

  return (
    <>
      <HeaderButtonWrapper hexCode={coin?.preview?.hexCode}>
        <ButtonContainer>
          {coin?.transferrable && (
            <SendButtonWrapper>
              <SendButton
                hexCode={coin?.preview?.hexCode}
                onClick={handleOpenTransferDialog}
              >
                <FormattedMessage id="send_button" />
              </SendButton>
            </SendButtonWrapper>
          )}
          {coin?.rechargeable && (
            <SendButtonWrapper>
              <SendButton
                hexCode={coin?.preview?.hexCode}
                onClick={handleClickRecharge}
              >
                <FormattedMessage id="button_reload" />
              </SendButton>
            </SendButtonWrapper>
          )}
        </ButtonContainer>
        {coin?.expirable && (
          <>
            <ExpirableWrapperDivider />
            <PointButtonWrapper onClick={handleClick}>
              <B4Regular>
                <FormattedMessage
                  id="candy_history_candy_to_be_expired_total"
                  values={{ candy_name: coin?.name ?? "" }}
                />
              </B4Regular>
              <PointRightContainer>
                <B4Regular>
                  {balance !== undefined ? (
                    <CurrencyFormatter
                      currency={coin?.symbol ?? ""}
                      value={balance}
                    />
                  ) : null}
                </B4Regular>
                <MoreIcon />
              </PointRightContainer>
            </PointButtonWrapper>
          </>
        )}
      </HeaderButtonWrapper>
      <CoinTransferDialog
        coinId={coin?.id}
        disableCoinSelectField={true}
        isOpen={isOpen}
        onClose={handleClose}
      />
    </>
  );
};

export default React.memo(CoinHeaderButton);
