import styled, { css } from "styled-components";
import { px2rem } from "common/helpers/rem";
import {
  FlatButton,
  GhostButton,
  TextButton,
} from "common/components/designSystem/buttons";

import { ThemeColorScaleTypes } from "app/enums";
const ButtonStyle = css`
  display: block;
  width: 100%;
  border-radius: ${px2rem(4)};
`;

export const FlatJoinButton = styled(FlatButton)<{
  joinButtonScale: Moim.Enums.ThemeColorScaleType;
}>`
  ${props => {
    const palette = props.theme.getSideAreaElementPalette("childMoimNameText");

    return css`
      background-color: var(
        --button-tint-color-800,
        ${palette.color ?? palette.fog800}
      );
      border-color: var(
        --button-tint-color-800,
        ${palette.color ?? palette.fog800}
      );

      // NOTE: purpose for Flat button text
      color: var(
        --button-tint-text-color,
        ${() => {
          if (palette.color) {
            return palette.fog800;
          }

          switch (props.joinButtonScale) {
            case ThemeColorScaleTypes.BLACK:
              return palette.white800;

            case ThemeColorScaleTypes.WHITE:
              return palette.grey800;
          }
        }}
      );
    `;
  }}

  ${ButtonStyle}
`;

export const GhostJoinButton = styled(GhostButton)<{
  joinButtonScale: Moim.Enums.ThemeColorScaleType;
}>`
  ${props => {
    const palette = props.theme.getSideAreaElementPalette("childMoimNameText");

    return css`
      border-color: var(
        --button-tint-color-800,
        ${palette.color ?? palette.fog800}
      );

      color: var(
        --button-tint-color-800,
        ${() => {
          if (palette.color) {
            return palette.fog800;
          }

          switch (props.joinButtonScale) {
            case ThemeColorScaleTypes.BLACK:
              return palette.white800;

            case ThemeColorScaleTypes.WHITE:
              return palette.grey800;
          }
        }}
      );
    `;
  }}

  ${ButtonStyle}
`;

export const TextJoinButton = styled(TextButton)<{
  joinButtonScale: Moim.Enums.ThemeColorScaleType;
}>`
  ${props => {
    const palette = props.theme.getSideAreaElementPalette("childMoimNameText");

    return css`
      color: var(
        --button-tint-color-800,
        ${() => {
          if (palette.color) {
            return palette.fog800;
          }

          switch (props.joinButtonScale) {
            case ThemeColorScaleTypes.BLACK:
              return palette.white800;

            case ThemeColorScaleTypes.WHITE:
              return palette.grey800;
          }
        }}
      );
    `;
  }}

  ${ButtonStyle}
`;
