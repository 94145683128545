import { useIntlShort } from "./useIntlShort";

/**
 * @Deprecated use intl formatMessage instead.
 * migration example: https://github.com/balmbees/moim-web/pull/2680/files#diff-a3438f58fa683bfb910472a34fbc66db7186b3b35aad42d7203d8d6b4c9baad9
 */
export default function useGroupTexts(
  key: Moim.Group.GroupTextSetKey,
  values?: Record<string, string | number>,
) {
  const intl = useIntlShort();
  const text = {
    singular: intl(key, values),
    plural: intl(`${key}_plural`, values),
  };
  return text;
}
