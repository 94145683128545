import psl from "psl";
import { isBrowser, isProd, isDev } from "common/helpers/envChecker";
import { PRODUCTION_HOST, STAGE_HOST, DEV_HOST } from "common/constants/hosts";

const defaultParser = (hostname: string) => {
  const hostnameArr = hostname.split(".");
  if (hostnameArr.length > 2) {
    return {
      subdomain: hostnameArr[0],
      domain: hostnameArr.slice(1).join("."),
    };
  }
  return undefined;
};

export function hostnameParser(_hostname?: string) {
  const hostname = _hostname ?? location.hostname;

  if (!hostname) {
    return;
  }

  const parsed = psl.parse(hostname);
  if (parsed.error) {
    return defaultParser(hostname);
  } else {
    return parsed
      ? { subdomain: parsed.subdomain, domain: parsed.domain }
      : defaultParser(hostname);
  }
}

export const getDomain = (_hostname?: string) => {
  return hostnameParser(_hostname)?.domain ?? "";
};

export const getSubdomain = (_hostname?: string) => {
  return hostnameParser(_hostname)?.subdomain ?? "";
};

export function isPathnameIncludeMoimHost(pathname?: string) {
  let targetPathname = pathname;
  if (isBrowser()) {
    targetPathname = pathname ?? location.hostname;
  }

  return targetPathname?.includes(
    isProd() ? PRODUCTION_HOST : isDev() ? DEV_HOST : STAGE_HOST,
  );
}
