import * as React from "react";

import {
  Container,
  LogoImage,
  MoimName,
  MoimNameWrapper,
  LogoImageWrapper,
} from "./styled";
import ShavedText from "common/components/shavedText";
import { NativeEmojiSafeText } from "common/components/designSystem/texts";
import CoverAnchorWrapper from "../../../../components/coverAnchorWrapper";
import GroupProfileImage from "common/components/groupProfileImage";

import useSideNavigationPanel from "common/hooks/useSideNavigationPanel";
import useIsMobile from "common/hooks/useIsMobile";
import { useStoreState } from "app/store";
import useIsCurrentGroupVisibleInTopNavi from "../../../../hooks/useIsCurrentGroupVisible";
import useCurrentGroup from "common/hooks/useCurrentGroup";
import useCurrentUser from "common/hooks/useCurrentUser";
import useCurrentHubGroup from "common/hooks/useCurrentHubGroup";

import { TopNavigationContext } from "../../../../context";

const CurrentGroupProfile: React.FC<{
  logoHeight?: number;
  blockProperties?: Partial<
    Moim.Layout.Navigation.CurrentGroupProfileElementType
  >;
}> = ({ logoHeight, blockProperties }) => {
  const { visibleMoimName } = React.useContext(TopNavigationContext);
  const { isExpanded } = useSideNavigationPanel();
  const isMobile = useIsMobile();
  const currentUser = useCurrentUser();
  const currentGroup = useCurrentGroup();
  const parentGroup = useCurrentHubGroup();
  const isCurrentGroupVisible = useIsCurrentGroupVisibleInTopNavi();
  const { logo, parentLogo } = useStoreState(storeState => ({
    logo: storeState.group.theme.logo,
    parentLogo: storeState.group.theme?.parentLogo,
  }));

  const visibleGroup = React.useMemo(() => {
    if (isCurrentGroupVisible) {
      return currentGroup as Moim.Group.INormalizedGroup | null;
    } else {
      return parentGroup;
    }
  }, [parentGroup, currentGroup, isCurrentGroupVisible]);

  const visibleLogo = React.useMemo(
    () => ((!isMobile || isExpanded || !currentUser?.id) && parentLogo) || logo,
    [isMobile, isExpanded, currentUser, parentLogo, logo],
  );

  if (blockProperties?.displayType === "logo" && visibleLogo) {
    return (
      <Container element={blockProperties}>
        <LogoImageWrapper>
          <CoverAnchorWrapper logoHeight={logoHeight}>
            <LogoImage src={logo ?? parentLogo} />
          </CoverAnchorWrapper>
        </LogoImageWrapper>
      </Container>
    );
  }

  return (
    <Container element={blockProperties}>
      <MoimNameWrapper visibleMoimName={visibleMoimName}>
        <CoverAnchorWrapper logoHeight={logoHeight}>
          <GroupProfileImage
            icon={visibleGroup?.icon}
            title={visibleGroup?.name || ""}
            size="m"
          />
          {visibleMoimName && (
            <MoimName textColor={blockProperties?.textColor}>
              <ShavedText
                line={2}
                value={<NativeEmojiSafeText value={visibleGroup?.name || ""} />}
              />
            </MoimName>
          )}
        </CoverAnchorWrapper>
      </MoimNameWrapper>
    </Container>
  );
};

export default CurrentGroupProfile;
