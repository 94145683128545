import * as React from "react";
import useRedirect from "common/hooks/useRedirect";
import ShavedText from "common/components/shavedText";
import {
  Wrapper,
  ItemContainer,
  ImageWrapper,
  TextWrapper,
  SubText,
} from "./styled";
import { Spacer } from "common/components/designSystem/spacer";

const QuickLinkItem: React.FC<Omit<Moim.Blockit.V2.IQuickLinkItem, "image"> & {
  src?: string;
  itemStyle?: Moim.Blockit.V2.IQuickLinkItemStyle;
  onSelect?(): void;
}> = ({ title, description, itemStyle, href, src, onSelect }) => {
  const redirect = useRedirect();

  const handleClick = React.useCallback(() => {
    onSelect?.();
    redirect(href);
  }, [onSelect, redirect, href]);

  return (
    <Wrapper onClick={handleClick}>
      <ItemContainer>
        {src && (
          <>
            <ImageWrapper
              shape={itemStyle?.shape ?? "square"}
              radius={itemStyle?.border?.radius}
            >
              <img alt="" src={src} />
            </ImageWrapper>
            <Spacer value={12} />
          </>
        )}

        <TextWrapper>
          <ShavedText line={src ? 3 : 2} value={title} />
        </TextWrapper>
        {Boolean(description) && (
          <SubText>
            <ShavedText value={description} line={1} />
          </SubText>
        )}
      </ItemContainer>
    </Wrapper>
  );
};

export default QuickLinkItem;
