import React, { useMemo } from "react";
import BlockitRendererV2 from "../blockitEditorBase/components/blockitRenderer.v2";
import useCurrentGroup from "common/hooks/useCurrentGroup";
import { useStoreState } from "app/store";
import useIsMobile from "common/hooks/useIsMobile";
import {
  useCheckIsHomeChannel,
  useVisibleSideNavigation,
  useVisibleTopNavigation,
} from "app/modules/layout/components/controller/hooks";
import { MoimURL } from "common/helpers/url";
import styled from "styled-components";

export const getLayoutKey = (
  groupKey?: string,
  type?: string,
  platform?: "web" | "mobile",
) => {
  if (!groupKey || !type || !platform) {
    return {
      key: undefined,
      fallbackKey: undefined,
    };
  }
  return {
    key: `${groupKey}|${type}|${platform}`,
    fallbackKey: `${groupKey}|${type}|${platform === "web" ? "mobile" : "web"}`,
  };
};

const FooterBlockV2 = () => {
  const currentGroup = useCurrentGroup();
  const isMobile = useIsMobile();
  const { key, fallbackKey } = getLayoutKey(
    currentGroup?.id,
    "footer",
    isMobile ? "mobile" : "web",
  );

  const footer = useStoreState(state =>
    state.componentLayout?.layout?.[key ?? ""]?.blocks?.length
      ? state.componentLayout?.layout?.[key ?? ""]
      : state.componentLayout?.layout?.[fallbackKey ?? ""],
  );
  const visibleTopNavigation = useVisibleTopNavigation();
  const visibleSideNavigation = useVisibleSideNavigation();

  const isHomeChannel = useCheckIsHomeChannel();

  const homeChannelType = useMemo(() => {
    if (!currentGroup?.home.web || currentGroup?.home.web.type !== "channel") {
      return null;
    }

    return currentGroup.home.web.ref.type;
  }, [currentGroup?.home.web]);

  const footerAvailableChannelLists = useMemo(
    () => [
      MoimURL.ViewShow.matchExact(location.pathname)?.isExact,
      MoimURL.CommerceProductShow.matchExact(location.pathname)?.isExact,
      MoimURL.CommerceCategoryAll.matchExact(location.pathname)?.isExact,
      MoimURL.CommerceCategories.matchExact(location.pathname)?.isExact,
    ],
    [location.pathname],
  );

  const showBottomFooterV2 = React.useMemo(() => {
    switch (footer?.options?.displayMode) {
      case "showAllPage":
        return (
          footer?.blocks?.length &&
          (footerAvailableChannelLists.some(data => Boolean(data)) ||
            (isHomeChannel && homeChannelType === "view"))
        );
      case "showHomeOnly":
        return (
          footer?.blocks?.length && isHomeChannel && homeChannelType === "view"
        );
      case "none":
      default:
        return false;
    }
  }, [
    footerAvailableChannelLists,
    footer?.options?.displayMode,
    footer?.blocks?.length,
    visibleTopNavigation,
    visibleSideNavigation,
    isHomeChannel,
    homeChannelType,
  ]);

  if (!showBottomFooterV2) return null;

  // NOTE: SINCE PRODUCT SHOW MOBILE HAS STICKY BUTTONS
  const bottomSpace =
    MoimURL.CommerceProductShow.matchExact(location.pathname)?.isExact &&
    isMobile
      ? "70px"
      : "0";

  return (
    <FooterWrapper bottomSpace={bottomSpace}>
      {showBottomFooterV2 &&
        footer?.blocks?.map((block, index) => (
          <BlockitRendererV2 key={index} block={block} />
        ))}
    </FooterWrapper>
  );
};

export default FooterBlockV2;

const FooterWrapper = styled.div<{ bottomSpace?: string }>`
  display: flex;
  flex-direction: column;
  margin-bottom: ${props => props.bottomSpace || "0"};
`;
