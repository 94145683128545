import React from "react";
import { css } from "styled-components";
import { SingleLineBoxInput } from "common/components/designSystem/boxInput";
import { px2rem } from "common/helpers/rem";
import { FormValueContext } from "../context";
import { FormItemHeader } from "../header";
import { useLocaleText } from "common/hooks/localization/useLocaleText";
import { useIntlShort } from "common/hooks/useIntlShort";
import { IRef } from "..";

export const InputFormItem = React.forwardRef<
  IRef,
  { item: Moim.Form.IFormItem }
>(({ item }, ref) => {
  const intl = useIntlShort();
  const { onChange, values, errors } = React.useContext(FormValueContext);
  const currentValue = values[item.id];
  const error = errors[item.id];
  const placeholder = useLocaleText(item, "placeholder");
  React.useImperativeHandle(ref, () => ({
    validate: () => ({
      [item.id]:
        item.isRequired && !currentValue
          ? intl("error_form_question_required")
          : undefined,
    }),
  }));
  return (
    <>
      <FormItemHeader item={item} />
      <SingleLineBoxInput
        value={currentValue}
        placeholder={placeholder}
        onChange={value => onChange(item.id, value)}
        size="Large"
        wrapperStyle={css`
          margin-left: ${px2rem(16)};
          margin-right: ${px2rem(16)};
        `}
        status={error ? "Error" : "Inactive"}
        helperText={error}
      />
    </>
  );
});
