import styled from "styled-components";

import { px2rem } from "common/helpers/rem";
import {
  B1Regular,
  B4Regular,
  H4Bold,
} from "common/components/designSystem/typos";
import { FlatButton } from "common/components/designSystem/buttons";
import { useScrollStyle } from "common/components/designSystem/styles";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;
export const Contents = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 0;
  ${useScrollStyle};
`;

export const Description = styled(B4Regular)`
  color: ${props => props.theme.colorV2.colorSet.grey300};
`;

export const Header = styled.div`
  padding: ${px2rem(15)} 0;
  margin-bottom: ${px2rem(8)};
`;

export const Title = styled(H4Bold)`
  color: ${props => props.theme.colorV2.colorSet.grey800};
`;

export const SubTitle = styled(B1Regular)`
  color: ${props => props.theme.colorV2.colorSet.grey800};
  margin-top: ${px2rem(2)};
  white-space: pre-wrap;
`;

export const ButtonWrapper = styled.div.attrs({ tabIndex: -1 })`
  display: flex;
  gap: ${px2rem(16)};
  margin: ${px2rem(14)} 0;

  & > * {
    flex: 1;
    min-width: 0;
  }
`;

export const Button = styled(FlatButton).attrs({ tabIndex: -1 })``;

export const ButtonDescription = styled(B4Regular)`
  margin-bottom: ${px2rem(16)};
  color: ${props => props.theme.colorV2.colorSet.grey300};
`;

export const InvitationInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${px2rem(8)};
`;

export const InvitationInfoText = styled(B4Regular)`
  color: ${props => props.theme.colorV2.colorSet.grey300};
`;
