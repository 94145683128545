import React from "react";
import styled from "styled-components";
import moment from "moment";

import { FormItemHeader } from "../header";
import { useIntlShort } from "common/hooks/useIntlShort";
import { FormValueContext } from "../context";
import { IRef } from "..";
import { B4Regular } from "common/components/designSystem/typos";
import { px2rem } from "common/helpers/rem";
import { DesktopDatePickerInput } from "./desktop";
import useIsMobile from "common/hooks/useIsMobile";
import { MobileDatePickerInput } from "./mobile";

function validateDate(dateStr: string) {
  return moment(dateStr, "YYYY-MM-DD", true).isValid();
}

const Error = styled(B4Regular)`
  padding: 0 ${px2rem(16)};
  color: ${props => props.theme.color.red};
`;

export const SimpleDate = React.forwardRef<IRef, { item: Moim.Form.IFormItem }>(
  ({ item }, ref) => {
    const isMobile = useIsMobile();
    const intl = useIntlShort();
    const { onChange, values, errors } = React.useContext(FormValueContext);
    const error = errors[item.id];
    const currentValue = values[item.id];

    React.useImperativeHandle(ref, () => ({
      validate: () => {
        if (item.isRequired && !currentValue) {
          return { [item.id]: intl("error_form_question_required") };
        }

        if (currentValue && !validateDate(currentValue)) {
          return { [item.id]: intl("error_simple_date_invalid_format") };
        }
        return { [item.id]: undefined };
      },
    }));

    return (
      <>
        <FormItemHeader item={item} />
        {Boolean(error) && <Error>{error}</Error>}

        {isMobile ? (
          <MobileDatePickerInput
            value={currentValue}
            onChange={value => onChange(item.id, value)}
          />
        ) : (
          <DesktopDatePickerInput
            value={currentValue}
            onChange={value => onChange(item.id, value)}
          />
        )}
      </>
    );
  },
);
