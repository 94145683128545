import styled, { css } from "styled-components";
import { TextButton } from "common/components/designSystem/buttons";
import { px2rem } from "common/helpers/rem";

import { MEDIA_QUERY } from "common/constants/responsive";
import {
  useHoverStyle,
  useScrollStyle,
} from "common/components/designSystem/styles";
import { BG_LEVEL_BACKGROUND_CLASS_NAME } from "common/components/designSystem/BGLevel";
import { LeftWrapper } from "common/components/appBar/styledComponent";

export const Wrapper = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;

  min-height: 100%;

  @media ${MEDIA_QUERY.EXCEPT_MOBILE} {
    width: ${px2rem(455)};
  }
`;

export const BottomSheetHeaderWrapper = styled.div`
  padding-inline: ${px2rem(16)};
`;

export const StickyWrapper = styled.div.attrs({
  className: BG_LEVEL_BACKGROUND_CLASS_NAME,
})`
  position: sticky;
  top: 0;
  width: 100%;
  z-index: ${props => props.theme.zIndexes.wrapper};
`;

export const ListHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-inline: ${px2rem(16)};
  border-bottom: 1px solid ${props => props.theme.colorV2.colorSet.grey50};

  @media ${MEDIA_QUERY.EXCEPT_MOBILE} {
    padding: 0 ${px2rem(24)};
  }
`;

export const ScrollSection = styled.div`
  ${useScrollStyle}
`;

export const NextButton = styled(TextButton).attrs({
  size: "m",
  direction: "right",
})<{ isActive: boolean; isLoading: boolean }>`
  ${props =>
    !props.isActive &&
    css`
      opacity: 0.4;
      cursor: not-allowed;
    `};
  ${props =>
    props.isLoading &&
    css`
      opacity: 0.4;
      cursor: progress;
    `};
`;

export const selectableItemStyle = css`
  ${useHoverStyle};
  @media ${MEDIA_QUERY.EXCEPT_MOBILE} {
    padding: 0 ${px2rem(24)};
  }
`;

export const appBarStyle = css`
  ${LeftWrapper} {
    min-width: initial;
  }
`;
