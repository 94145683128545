import * as React from "react";
import shortid from "shortid";
import { IHookProps } from "./";

export type IHookHandlers = ReturnType<typeof useHandlers>;

export default function useHandlers(props: IHookProps) {
  const {
    expandSideNavigation,
    collapseSideNavigation,
    setMainPanelKey,
  } = props;

  const expandSidebar = React.useCallback(() => {
    expandSideNavigation();
  }, [expandSideNavigation]);

  const collapseSidebar = React.useCallback(() => {
    collapseSideNavigation();
  }, [collapseSideNavigation]);

  const doRefresh = React.useCallback(() => {
    setMainPanelKey(shortid());
  }, []);

  return {
    expandSidebar,
    collapseSidebar,
    doRefresh,
  };
}
