import * as React from "react";
import { BaseItemCell, RenderRightElement } from "common/components/itemCell";
import { NativeEmojiSafeText } from "common/components/designSystem/texts";
import ShavedText from "common/components/shavedText";
import { IOption } from "common/components/designSystem/selection/type";

interface IProps {
  option: IOption;
  isSelected: boolean;
  isMultipleSelect: boolean;
  hover?: boolean;
  onClick: (userId: Moim.Id) => void;
}

function SelectableItem({
  option,
  isSelected,
  isMultipleSelect,
  hover,
  onClick,
}: IProps) {
  const button = React.useMemo(() => {
    const commonButtonSet = {
      name: "userSelectInput",
      value: option.value,
      checked: isSelected,
    };

    return isMultipleSelect
      ? {
          type: "checkbox" as const,
          ...commonButtonSet,
        }
      : {
          type: "radio" as const,
          ...commonButtonSet,
        };
  }, [option.value, isMultipleSelect, isSelected]);

  const handleClickUserItem = React.useCallback(() => {
    onClick(option.value);
  }, [onClick, option]);

  return (
    <BaseItemCell
      hover={hover}
      title={
        <ShavedText
          value={<NativeEmojiSafeText value={option.label} />}
          line={1}
        />
      }
      subTitleShaveLine={1}
      rightElement={button && <RenderRightElement {...button} />}
      disableRightPadding
      onClick={handleClickUserItem}
    />
  );
}

export default SelectableItem;
