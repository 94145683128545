import {
  Svg18SettingG,
  Svg24SettingG,
  Svg18AdminsettingG,
  Svg24AdminsettingG,
  Svg18InviteG,
  Svg24InviteG,
  Svg18ShareandrG,
  Svg24ShareandrG,
  Svg18EditG,
  Svg24EditG,
  Svg18AddG,
  Svg18CoverG,
  Svg24CoverG,
  Svg18MemberG,
  Svg24MemberG,
  Svg18LogoutG,
  Svg24LogoutG,
  Svg18BlocksG,
  Svg24BlocksG,
} from "@moim/icons";
import { useHoverStyle } from "common/components/designSystem/styles";
import { H10Bold } from "common/components/designSystem/typos";
import { MEDIA_QUERY } from "common/constants/responsive";
import styled from "styled-components";

export const MenuList = styled.ul``;

export const MenuItem = styled.li.attrs({ role: "button" })`
  ${useHoverStyle};
`;

export const MenuText = styled(H10Bold)`
  color: ${(props) => props.theme.colorV2.colorSet.grey600};

  @media ${MEDIA_QUERY.ONLY_MOBILE} {
    color: ${(props) => props.theme.colorV2.colorSet.grey800};
  }
`;

export const SettingSmallIcon = styled(Svg18SettingG).attrs((props) => ({
  size: "xs",
  iconColor: props.theme.colorV2.colorSet.grey300,
}))``;

export const SettingIcon = styled(Svg24SettingG).attrs((props) => ({
  size: "s",
  iconColor: props.theme.colorV2.colorSet.grey300,
}))``;

export const AdminSettingSmallIcon = styled(Svg18AdminsettingG).attrs(
  (props) => ({
    size: "xs",
    iconColor: props.theme.colorV2.colorSet.grey300,
  }),
)``;

export const AdminSettingIcon = styled(Svg24AdminsettingG).attrs((props) => ({
  size: "s",
  iconColor: props.theme.colorV2.colorSet.grey300,
}))``;

export const AppointMembersSmallIcon = styled(Svg18InviteG).attrs((props) => ({
  size: "xs",
  iconColor: props.theme.colorV2.colorSet.grey300,
}))``;

export const AppointMembersIcon = styled(Svg24InviteG).attrs((props) => ({
  size: "s",
  iconColor: props.theme.colorV2.colorSet.grey300,
}))``;

export const ShareMoimSmallIcon = styled(Svg18ShareandrG).attrs((props) => ({
  size: "xs",
  iconColor: props.theme.colorV2.colorSet.grey300,
}))``;

export const ShareMoimIcon = styled(Svg24ShareandrG).attrs((props) => ({
  size: "s",
  iconColor: props.theme.colorV2.colorSet.grey300,
}))``;

export const EditSmallIcon = styled(Svg18EditG).attrs((props) => ({
  size: "xs",
  iconColor: props.theme.colorV2.colorSet.grey300,
}))``;

export const EditIcon = styled(Svg24EditG).attrs((props) => ({
  size: "s",
  iconColor: props.theme.colorV2.colorSet.grey300,
}))``;

export const AddSmallIcon = styled(Svg18AddG).attrs((props) => ({
  size: "xs",
  iconColor: props.theme.colorV2.colorSet.grey300,
}))``;

export const AddIcon = styled(Svg18AddG).attrs((props) => ({
  size: "s",
  iconColor: props.theme.colorV2.colorSet.grey300,
}))``;

export const CoverSmallIcon = styled(Svg18CoverG).attrs((props) => ({
  size: "xs",
  iconColor: props.theme.colorV2.colorSet.grey300,
}))``;

export const CoverIcon = styled(Svg24CoverG).attrs((props) => ({
  size: "s",
  iconColor: props.theme.colorV2.colorSet.grey300,
}))``;

export const MemberSmallIcon = styled(Svg18MemberG).attrs((props) => ({
  size: "xs",
  iconColor: props.theme.colorV2.colorSet.grey300,
}))``;

export const MemberIcon = styled(Svg24MemberG).attrs((props) => ({
  size: "s",
  iconColor: props.theme.colorV2.colorSet.grey300,
}))``;

export const LogoutSmallIcon = styled(Svg18LogoutG).attrs((props) => ({
  size: "xs",
  iconColor: props.theme.colorV2.colorSet.grey300,
}))``;

export const LogoutIcon = styled(Svg24LogoutG).attrs({ size: "s" })``;

export const PluginsSmallIcon = styled(Svg18BlocksG).attrs((props) => ({
  size: "xs",
  iconColor: props.theme.colorV2.colorSet.grey300,
}))``;

export const PluginsIcon = styled(Svg24BlocksG).attrs((props) => ({
  size: "s",
  iconColor: props.theme.colorV2.colorSet.grey300,
}))``;
