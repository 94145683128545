import * as EnvChecker from "app/common/helpers/envChecker";
// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export default class SessionHandler {
  public static get(key: string, defaultValue?: string) {
    if (EnvChecker.isBrowser()) {
      const value = sessionStorage.getItem(key);
      if (!value) {
        return defaultValue ?? null;
      }
      return value;
    }
    return null;
  }

  public static set(key: string, value: string) {
    if (EnvChecker.isBrowser()) {
      sessionStorage.setItem(key, value);
    }
  }

  public static remove(key: string) {
    if (EnvChecker.isBrowser()) {
      sessionStorage.removeItem(key);
    }
  }
}
