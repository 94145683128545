import { CancelToken } from "axios";
import { getCommerceAPIDomain } from "app/common/helpers/domainMaker";
import { MoimCommerceAPI } from "./base/commerce";

export interface ISearchOptions {
  query?: string;
  sort?: string; // createdAt, updatedAt 같은 필드
  order?: "asc" | "desc";
  after?: Moim.PagingValue;
  from?: number | null;
  limit?: number;
  status?: Moim.Commerce.PRODUCT_STATUS;
  type?: Moim.Commerce.PRODUCT_TYPE;
  categoryIds?: string[];
  deliveryGroupId?: string;
  tagSetIds?: string[][];
  id?: string[];
  brandId?: string[];
}

export default class CommerceAPI extends MoimCommerceAPI {
  public async getBaseCurrency(
    hubSellerId: Moim.Id,
  ): Promise<{ currency: string }> {
    return (
      await this.get(`/sellers/${hubSellerId}/currency_select`, undefined, {})
    ).data;
  }

  public async getBasicInfo(): Promise<
    Moim.ISingleItemResponse<Moim.Commerce.ICommerceBasicInfo>
  > {
    return (await this.get("/info", undefined, {})).data;
  }

  public async batchCoupon(
    ids: Moim.Id[],
  ): Promise<Moim.IPaginatedListResponse<Moim.Commerce.IDownloadableCoupon>> {
    return (
      await this.post(
        `/coupons/_batch`,
        { ids },
        { baseURL: getCommerceAPIDomain() },
      )
    ).data;
  }

  public async batchProduct(
    ids: Moim.Id[],
  ): Promise<
    Moim.IPaginatedListResponse<Omit<Moim.Commerce.IProduct, "seller">>
  > {
    return (
      await this.post(
        "/products/_batch",
        { ids },
        { baseURL: getCommerceAPIDomain() },
      )
    ).data;
  }

  public async batchGetBrands(
    ids: string[],
  ): Promise<Moim.IPaginatedListResponse<Moim.Commerce.IBrand>> {
    return (
      await this.post(
        "/sellers/brands/_batch",
        {
          ids,
        },
        { baseURL: getCommerceAPIDomain() },
      )
    ).data;
  }

  public async batchProductSet(
    ids: Moim.Id[],
  ): Promise<Moim.IPaginatedListResponse<Moim.Commerce.IProductSet>> {
    return (
      await this.post(
        "/product_sets/_batch",
        { ids },
        { baseURL: getCommerceAPIDomain() },
      )
    ).data;
  }

  public async batchSeller(
    ids: Moim.Id[],
  ): Promise<Moim.IPaginatedListResponse<Moim.Commerce.ISeller>> {
    return (
      await this.post(
        "/sellers/_batch",
        { ids },
        { baseURL: getCommerceAPIDomain() },
      )
    ).data;
  }

  public async batchVariants(
    ids: Moim.Id[],
  ): Promise<Moim.IPaginatedListResponse<Moim.Commerce.IProductVariant>> {
    return (
      await this.post(
        "/product_variants/_batch",
        { ids },
        { baseURL: getCommerceAPIDomain() },
      )
    ).data;
  }

  public async batchDeliveryGroups(
    ids: Moim.Id[],
  ): Promise<Moim.IPaginatedListResponse<Moim.Commerce.IDeliveryGroup>> {
    return (
      await this.post(
        "/delivery_groups/_batch",
        { ids },
        { baseURL: getCommerceAPIDomain() },
      )
    ).data;
  }

  public async batchTeamBuying(
    ids: Moim.Id[],
  ): Promise<Moim.IPaginatedListResponse<Moim.Commerce.ITeamBuying>> {
    return (
      await this.post(
        "/team_buyings/_batch",
        { ids },
        { baseURL: getCommerceAPIDomain() },
      )
    ).data;
  }

  public async myAdministratedSellers(): Promise<
    Moim.IPaginatedListResponse<Omit<Moim.Commerce.ISeller, "accounts">>
  > {
    return (
      await this.get("/sellers", null, { baseURL: getCommerceAPIDomain() })
    ).data;
  }

  public async getSellerData(
    sellerId: Moim.Id,
    cancelToken?: CancelToken,
  ): Promise<Moim.Commerce.ISeller> {
    return (
      await this.get(`/sellers/${sellerId}`, null, {
        cancelToken,
      })
    ).data;
  }

  public async getSellerCategories(
    sellerId: Moim.Id,
    cancelToken?: CancelToken,
  ): Promise<Moim.IListResponse<Moim.Commerce.ICategory>> {
    return (
      await this.get(`/sellers/${sellerId}/categories`, null, {
        cancelToken,
      })
    ).data;
  }

  public async getCategory(
    sellerId: Moim.Id,
    categoryId: Moim.Id,
    params?: { includeView?: boolean },
    cancelToken?: CancelToken,
  ): Promise<Moim.Commerce.ICategory> {
    return (
      await this.get(`/sellers/${sellerId}/categories/${categoryId}`, params, {
        cancelToken,
      })
    ).data.data;
  }

  public async getSellerSubSellers(
    sellerId: Moim.Id,
    cancelToken?: CancelToken,
  ): Promise<Moim.IListResponse<Omit<Moim.Commerce.ISeller, "accounts">>> {
    return (
      await this.get(`/sellers/${sellerId}/sub_sellers`, null, {
        cancelToken,
      })
    ).data;
  }

  public async getSellerProducts(
    sellerId: Moim.Id,
    cancelToken?: CancelToken,
  ): Promise<
    Moim.IPaginatedListResponse<Omit<Moim.Commerce.IProduct, "seller">>
  > {
    return (
      await this.get(`/sellers/${sellerId}/products`, null, {
        cancelToken,
      })
    ).data;
  }

  public async getSellerProductSets(
    sellerId: Moim.Id,
    params?: Moim.IPaging,
    cancelToken?: CancelToken,
  ): Promise<Moim.IPaginatedListResponse<Moim.Commerce.IProductSet>> {
    return (
      await this.get(`/sellers/${sellerId}/product_sets`, params, {
        cancelToken,
      })
    ).data;
  }

  public async getProductData(
    productId: Moim.Id,
    cancelToken?: CancelToken,
  ): Promise<Required<Moim.Commerce.IProduct>> {
    return (
      await this.get(`/products/${productId}`, null, {
        cancelToken,
      })
    ).data;
  }

  public async getBrandData(
    brandId: Moim.Id,
    sellerId: Moim.Id,
    params?: { includeView?: boolean },
    cancelToken?: CancelToken,
  ): Promise<Moim.Commerce.IBrand> {
    return (
      await this.get(`/sellers/${sellerId}/brands/${brandId}`, params, {
        cancelToken,
      })
    ).data;
  }

  public async getBrands(
    sellerId: Moim.Id,
    params?: Moim.IPaging,
    cancelToken?: CancelToken,
  ): Promise<Moim.IPaginatedListResponse<Moim.Commerce.IBrand>> {
    return (
      await this.get(
        `/sellers/${sellerId}/brands`,
        { ...params },
        {
          cancelToken,
        },
      )
    ).data;
  }

  public async searchBrands(
    sellerId: Moim.Id,
    options: ISearchOptions = {},
    cancelToken?: CancelToken,
  ): Promise<Moim.IPaginatedListResponse<Moim.Commerce.IBrand>> {
    return (
      await this.post(`/sellers/${sellerId}/brands/search`, options, {
        cancelToken,
      })
    ).data;
  }

  public async searchSellerProducts(
    sellerId: Moim.Id,
    options: ISearchOptions = {},
    cancelToken?: CancelToken,
  ): Promise<
    Moim.IPaginatedListResponse<Omit<Moim.Commerce.IProduct, "seller">>
  > {
    return (
      await this.post(`/sellers/${sellerId}/products/search`, options, {
        cancelToken,
      })
    ).data;
  }

  public async getPayments(
    sellerId: Moim.Id,
    paging?: Moim.IPaging,
    cancelToken?: CancelToken,
  ): Promise<Moim.IPaginatedListResponse<Moim.Commerce.IPayment>> {
    return (
      await this.get(
        `/payments`,
        { ...paging, sellerId },
        {
          cancelToken,
        },
      )
    ).data;
  }

  public async getPayment(
    paymentId: Moim.Id,
    cancelToken?: CancelToken,
    guestToken?: string,
  ): Promise<Moim.Commerce.IPayment> {
    return (
      await this.get(`/payments/${paymentId}`, null, {
        cancelToken,
        headers: guestToken ? { Authorization: `Bearer ${guestToken}` } : {},
      })
    ).data;
  }

  public async paymentCancel(
    paymentId: Moim.Id,
    cancelReason: string,
    refundBankMethod?: Moim.Commerce.IRefundBankMethod,
    guestToken?: string,
  ): Promise<Moim.Commerce.IPayment> {
    return (
      await this.post(
        `/payments/${paymentId}/cancel`,
        {
          cancelReason,
          refundBankMethod,
        },
        {
          headers: guestToken ? { Authorization: `Bearer ${guestToken}` } : {},
        },
      )
    ).data;
  }

  public async getRefund(
    paymentId: Moim.Id,
    cancelToken?: CancelToken,
  ): Promise<any> {
    return (
      await this.get(`/refunds/${paymentId}`, undefined, {
        cancelToken,
      })
    ).data;
  }

  public async manualPurchaseConfirm(
    purchaseItemIds: Moim.Id[],
  ): Promise<Moim.IPaginatedListResponse<Moim.Commerce.IPurchase>> {
    return (
      await this.post(
        `/purchase_items/confirm`,
        {
          itemIds: purchaseItemIds,
        },
        { baseURL: getCommerceAPIDomain() },
      )
    ).data;
  }

  public async calcPayment(
    hubSellerId: Moim.Id,
    payload: {
      items: Moim.Commerce.IGroupedByDeliveryOptionCartSellerItem[];
      userCouponIds?: string[];
      selectRecommendedCoupon?: boolean;
      useCoinMaxPrice?: boolean;
      excludeCoins?: boolean;
      excludeCoupons?: boolean;
    },
    cancelToken?: CancelToken,
  ): Promise<Moim.Commerce.IPaymentCalcResponse> {
    return (
      await this.post(
        `/payments/calculate`,
        {
          sellerId: hubSellerId,
          ...payload,
        },
        {
          cancelToken,
        },
      )
    ).data;
  }

  public async productVote(
    productId: Moim.Id,
    voteType: Moim.Enums.VoteStatus,
  ): Promise<Moim.Commerce.IProduct> {
    return (
      await this.put(
        `/products/${productId}/votes`,
        {
          vote: {
            type: voteType,
          },
        },
        {},
      )
    ).data;
  }
  public async getVotedProductList(
    payload: Moim.IPaging,
  ): Promise<Moim.IPaginatedListResponse<Moim.Commerce.IProduct>> {
    return (await this.get(`/products/voted`, payload, {})).data;
  }

  public async visitProductShow(
    productId: Moim.Id,
  ): Promise<Moim.Commerce.IProduct> {
    return (await this.post(`/products/${productId}/view`, undefined, {})).data;
  }

  public async getCart(
    sellerId: Moim.Id,
  ): Promise<Moim.Commerce.ICartResponse> {
    return (await this.get("/v2/carts", { sellerId }, {})).data;
  }

  public async updateCart(
    hubSellerId: Moim.Id,
    items: Moim.Commerce.IFlattenedCartSellerItem[],
    append: boolean,
    cancelToken?: CancelToken,
  ): Promise<Moim.Commerce.ICartResponse> {
    return (
      await this.post(
        `/v2/carts`,
        {
          sellerId: hubSellerId,
          items,
          append,
        },
        {
          cancelToken,
        },
      )
    ).data;
  }

  public async updateCartGuest(
    hubSellerId: Moim.Id,
    params: {
      items: Moim.Commerce.IFlattenedCartSellerItem[];
      previousItems?: Moim.Commerce.IFlattenedCartSellerItem[];
      append: boolean;
    },
    cancelToken?: CancelToken,
  ): Promise<Moim.Commerce.ICartResponse> {
    return (
      await this.post(
        `/v2/carts/guest`,
        {
          sellerId: hubSellerId,
          ...params,
        },
        {
          cancelToken,
        },
      )
    ).data;
  }

  public async getMyCreditHistory(
    hubSellerId: Moim.Id,
    params: {
      after?: Moim.PagingValue;
      limit?: number;
      includeTotalAmount?: boolean;
      sourceType?: "referralReward-signUp";
    },
  ): Promise<
    Moim.IPaginatedListResponse<Moim.Commerce.ICreditHistoryItem> & {
      totalAmount?: number;
    }
  > {
    return (
      await this.get(`/sellers/${hubSellerId}/credit_histories`, params, {})
    ).data;
  }

  public async getMyAvailableCoupons(
    hubSellerId: Moim.Id,
    items: Moim.Commerce.IFlattenedCartSellerItem[],
    after?: Moim.PagingValue,
  ): Promise<
    Moim.IPaginatedListResponse<Moim.Commerce.IAvailableStatusCoupon>
  > {
    return (
      await this.post(
        `/user_coupons/available`,
        {
          sellerId: hubSellerId,
          items,
          after,
        },
        { baseURL: getCommerceAPIDomain() },
      )
    ).data;
  }

  public async getMyCoupons(
    hubSellerId: Moim.Id,
    type: "active" | "deactive",
    after?: Moim.PagingValue,
  ): Promise<
    Moim.IPaginatedListResponse<Moim.Commerce.IAvailableStatusCoupon>
  > {
    return (
      await this.get(
        `/user_coupons`,
        {
          sellerId: hubSellerId,
          active: type === "active",
          after,
        },
        { baseURL: getCommerceAPIDomain() },
      )
    ).data;
  }

  public async createShippingAddress(payload: {
    name: string;
    recipientName: string;
    recipientPhone?: {
      countryCode: string;
      nationalNumber: string;
    };
    address: string;
    address2?: string;
    zipCode: string;
    memo?: string;
    countryCode?: string;
    city?: string;
    state?: string;
    hubSellerId?: string;
  }): Promise<Moim.Commerce.ICommerceShippingAddress> {
    return (await this.post("/shipping_address", payload, {})).data;
  }

  public async getShippingAddress(): Promise<
    Moim.IListResponse<Moim.Commerce.ICommerceShippingAddress>
  > {
    return (await this.get("/shipping_address", undefined, {})).data;
  }

  public async updateShippingAddress(
    id: Moim.Id,
    payload: {
      name?: string;
      recipientName?: string;
      recipientPhone?: {
        countryCode: string;
        nationalNumber: string;
      };
      address?: string;
      address2?: string;
      zipCode?: string;
      memo?: string | null;
      target?: string;
      sortKey?: string;
      countryCode?: string;
      city?: string | null;
      state?: string | null;
    },
  ): Promise<Moim.Commerce.ICommerceShippingAddress> {
    return (await this.put(`/shipping_address/${id}`, payload, {})).data;
  }

  public async setAsDefaultShippingAddress(
    id: Moim.Id,
  ): Promise<Moim.Commerce.ICommerceShippingAddress> {
    return (
      await this.put(
        `/shipping_address/default`,
        {
          shippingAddressId: id,
        },
        {},
      )
    ).data;
  }

  public async deleteShippingAddress(
    id: Moim.Id,
  ): Promise<Moim.ISuccessResponse> {
    return (await this.delete(`/shipping_address/${id}`, undefined, {})).data;
  }

  public async getPaidFund(
    productId: Moim.Id,
    after?: string,
    limit?: number,
  ): Promise<Moim.IPaginatedListResponse<Moim.Commerce.IPurchaseItem>> {
    return (
      await this.get(
        `/purchase_items/paid_funds`,
        {
          productId,
          after,
          limit,
        },
        {},
      )
    ).data;
  }

  public async getMyPurchasedProductList(
    cancelToken: CancelToken,
    limit?: number,
    after?: Moim.PagingValue,
  ) {
    return (
      await this.get(
        "/products/purchased",
        { limit, after },
        {
          cancelToken,
        },
      )
    ).data;
  }

  public async getDeliveryGroup(id: string, cancelToken?: CancelToken) {
    return (
      await this.get(`/delivery_groups/${id}`, undefined, {
        cancelToken,
      })
    ).data;
  }

  public async postDownloadCoupon(
    id: Moim.Id,
    cancelToken?: CancelToken,
  ): Promise<Moim.Commerce.ICoupon> {
    return (
      await this.post(`/coupons/${id}/user_coupons`, undefined, {
        cancelToken,
      })
    ).data;
  }

  public async searchDownloadCoupons(
    parentSellerId: Moim.Id,
    payload?: {
      limit?: number;
      query?: string;
      sellerId?: Moim.Id;
      after?: Moim.PagingValue;
    },
    cancelToken?: CancelToken,
  ): Promise<Moim.IPaginatedListResponse<Moim.Commerce.ICoupons>> {
    return (
      await this.post(
        `/coupons/search`,
        {
          downloadable: true,
          parentSellerId,
          ...payload,
        },
        {
          cancelToken,
        },
      )
    ).data;
  }

  public async getTeamBuyingData(
    teamBuyingId: Moim.Id,
    cancelToken?: CancelToken,
  ): Promise<Moim.Commerce.ITeamBuying> {
    return (
      await this.get(`/team_buyings/${teamBuyingId}`, undefined, {
        cancelToken,
      })
    ).data;
  }

  public async getTeamsOfTeamBuying(
    teamBuyingId: Moim.Id,
    teamId?: Moim.Id,
    paging?: Moim.IPaging,
    cancelToken?: CancelToken,
  ): Promise<Moim.IPaginatedListResponse<Moim.Commerce.ITeamBuyingTeam>> {
    return (
      await this.get(
        `/team_buyings/${teamBuyingId}/teams`,
        {
          teamId,
          ...paging,
        },
        {
          cancelToken,
        },
      )
    ).data;
  }

  public async getTeamsOfProduct(
    productId: Moim.Id,
    cancelToken?: CancelToken,
  ): Promise<Moim.IPaginatedListResponse<Moim.Commerce.ITeamBuyingTeam>> {
    return (
      await this.get(`/products/${productId}/team_buyings/teams`, undefined, {
        cancelToken,
      })
    ).data;
  }

  public async createDraftTeamOfTeamBuying(
    teamBuyingId: Moim.Id,
  ): Promise<Moim.Commerce.ITeamBuyingTeam> {
    return (await this.post(`/team_buyings/${teamBuyingId}/teams`)).data;
  }

  public async getAllTeamCounts(
    productId: Moim.Id,
    cancelToken?: CancelToken,
  ): Promise<{ count: number }> {
    return (
      await this.get(
        `products/${productId}/team_buyings/teams/count`,
        undefined,
        { cancelToken },
      )
    ).data.data;
  }

  public async createGuestUser(
    hubSellerId: Moim.Id,
  ): Promise<{
    user: Moim.User.IUser;
    token: string;
  }> {
    return (
      await this.post("/users/guest", {
        sellerId: hubSellerId,
      })
    ).data;
  }

  public async getGuestUserToken(
    hubSellerId: Moim.Id,
    paymentId: Moim.Id,
    buyerName: string,
  ): Promise<{ token: string }> {
    return (
      await this.post("/users/guest/token", {
        sellerId: hubSellerId,
        paymentId,
        buyerName,
      })
    ).data;
  }
}
