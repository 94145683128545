import * as React from "react";
import { Emoji } from "emoji-mart";
import styled, { css } from "styled-components";
import { useStoreState } from "app/store";
import { selectMentionWithEntities } from "common/components/blockitEditorBase/selector";
import {
  parseServerSpecMention,
  serverSpecMentionReplace,
} from "common/helpers/moimDown";
import tokenizer from "common/helpers/moimDown/tokenizer";
import {
  IParentStyle,
  decodeText,
} from "common/components/blockitEditorBase/utils/moimDownConvert";
import { px2rem } from "common/helpers/rem";
import { B2RegularStyle } from "common/components/designSystem/typos";
import { rgba } from "polished";

interface IProps {
  content: string;
}

type IStyleToken = IParentStyle & Record<string, unknown>;

const styleFactory = css<{ styleToken: IStyleToken }>`
  font-weight: ${props => (props.styleToken.bold ? 600 : "inherit")};
  font-style: ${props => (props.styleToken.italic ? "italic" : "inherit")};
  font-size: ${props =>
    props.styleToken.fontSize
      ? px2rem(parseInt(props.styleToken.fontSize as string, 10))
      : "inherit"};

  line-height: ${({ styleToken }) => {
    switch (styleToken.fontSize as string) {
      case "32": {
        return px2rem(48);
      }
      case "24": {
        return px2rem(36);
      }
      default:
      case "16": {
        return px2rem(26);
      }
      case "14": {
        return px2rem(22);
      }
      case "12": {
        return px2rem(18);
      }
    }
  }};
  color: ${props =>
    props.styleToken.color ? (props.styleToken.color as string) : "inherit"};
  background-color: ${props =>
    props.styleToken.background
      ? (props.styleToken.background as string)
      : "inherit"};

  ${props =>
    (props.styleToken.code as boolean | undefined) &&
    css`
      padding: 0 ${px2rem(2)};
      border-radius: ${px2rem(2)};
      margin: 0 ${px2rem(2)};
      background-color: ${props.theme.colorV2.colorSet.grey50} !important;
      color: ${props.theme.color.red} !important;
      border: 1px solid ${props.theme.colorV2.colorSet.grey50} !important;
      font-family: "SFMono-Regular", Menlo, Consolas, "PT Mono",
        "Liberation Mono", Courier;
      ${B2RegularStyle}
    `}
`;

const MentionSpan = styled.span`
  cursor: pointer;
  color: ${props => props.theme.color.darkBlue} !important;
`;

const MarkSpan = styled.span<{ styleToken: IStyleToken }>`
  ${styleFactory}
  color: ${props => props.theme.colorV2.colorSet.grey800};
  background-color: ${props => rgba(props.theme.colorV2.accent, 0.14)};
`;

const EmojiWrapper = styled.div`
  display: inline;
  padding-right: ${px2rem(4)};
  user-select: text;
  white-space: pre-wrap;
  font-variant-ligatures: none;
`;

const LinkAnchor = styled.a<{
  styleToken: IStyleToken;
}>`
  ${styleFactory}

  cursor: pointer;
  text-decoration: underline;
  color: ${props => props.theme.color.darkBlue};
`;

const RichSpan = styled.span<{
  styleToken: IStyleToken;
}>`
  ${styleFactory}
`;

const tokenToHtml = (
  token: Moim.VGMarkDownTextView.IToken,
  parentStyle: IStyleToken = {},
) => {
  const resultArray: any[] = [];

  switch (token.type) {
    case "text": {
      resultArray.push(
        <RichSpan styleToken={parentStyle}>
          {decodeText(token.data.value)}
        </RichSpan>,
      );
      break;
    }

    case "link": {
      resultArray.push(
        <LinkAnchor href={token.data.href} styleToken={parentStyle}>
          {decodeText(token.data.value)}
        </LinkAnchor>,
      );
      break;
    }

    case "bold": {
      token.data.innerStyles.forEach(innerToken => {
        tokenToHtml(innerToken, {
          ...parentStyle,
          bold: true,
        }).forEach(elm => {
          resultArray.push(elm);
        });
      });
      break;
    }

    case "italic": {
      token.data.innerStyles.forEach(innerToken => {
        tokenToHtml(innerToken, {
          ...parentStyle,
          italic: true,
        }).forEach(element => {
          resultArray.push(element);
        });
      });
      break;
    }

    case "mark": {
      resultArray.push(
        <MarkSpan styleToken={parentStyle}>{token.data.value}</MarkSpan>,
      );
      break;
    }

    case "mention": {
      resultArray.push(
        <MentionSpan>
          {token.data.type === "user" ? "@" : "#"}
          {token.data.display}
        </MentionSpan>,
      );
      break;
    }

    case "emoji": {
      resultArray.push(
        <EmojiWrapper>
          <Emoji native={true} emoji={token.data.value} size={16} />
        </EmojiWrapper>,
      );
      break;
    }
    case "nativeEmoji": {
      resultArray.push(<EmojiWrapper>{token.data.value}</EmojiWrapper>);
      break;
    }

    case "attr": {
      if (token.data.innerStyles) {
        token.data.innerStyles.forEach(innerToken => {
          tokenToHtml(innerToken, {
            ...parentStyle,
            color: token.data.properties["font-color"],
            background: token.data.properties["background-color"],
            italic: token.data.properties.italic,
            bold: Boolean(token.data.properties["font-weight"]),
            code: token.data.properties.code,
            fontSize: token.data.properties["font-size"],
          }).forEach(element => {
            resultArray.push(element);
          });
        });
      } else {
        const value = decodeText(token.data.value);
        resultArray.push({
          insert: value,
          attributes: {
            color: token.data.properties["font-color"],
            background: token.data.properties["background-color"],
            italic: token.data.properties.italic,
            bold: Boolean(token.data.properties["font-weight"]),
            code: token.data.properties.code,
            fontSize: token.data.properties["font-size"],
          },
        });
      }
      break;
    }
  }

  return resultArray;
};

const RichTextContent: React.FC<IProps> = ({ content }) => {
  const userEntities = useStoreState(state => state.entities.users);
  const mentions = parseServerSpecMention(content);
  const mappedMentionData = selectMentionWithEntities(userEntities, mentions);
  const renderSpecMentionText = serverSpecMentionReplace(
    content,
    mappedMentionData,
  );

  const innerHtml: any[] = [];
  tokenizer(renderSpecMentionText).forEach(token => {
    tokenToHtml(token).forEach(html => {
      innerHtml.push(html);
    });
  });
  return <>{innerHtml}</>;
};

export default RichTextContent;
