import * as React from "react";
import styled from "styled-components";
import { B4Regular } from "common/components/designSystem/typos";
import useChannelFetchSelector from "common/hooks/useEntitiesFetchSelectors/useChannelFetchSelector";
import useGroupFetchSelector from "common/hooks/useEntitiesFetchSelectors/useGroupFetchSelector";
import { px2rem } from "common/helpers/rem";
import ShavedText from "common/components/shavedText/v2";
import { textAlignStyle } from "common/components/thread/styles";

const Name = styled(B4Regular)`
  display: flex;
  max-width: 100%;
  color: ${props => props.theme.colorV2.colorSet.grey300};
`;

const NameWrapper = styled.div<{
  textAlign?: Moim.Forum.ForumListConfigTextAlignment;
}>`
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  ${textAlignStyle}
  
  ${Name} + ${Name}:before {
    content: "･";
    margin: 0 ${px2rem(2)};
  }
`;

interface IProps {
  channelId?: Moim.Id;
  groupId?: Moim.Id;
  showChannel?: boolean;
  showMoim?: boolean;
  textAlign?: Moim.Forum.ForumListConfigTextAlignment;
}

function GroupAndChannelName({
  channelId,
  groupId,
  showChannel,
  showMoim,
  textAlign,
}: IProps) {
  const { channels } = useChannelFetchSelector(channelId ? [channelId] : []);
  const { groups } = useGroupFetchSelector(groupId ? [groupId] : []);

  return (
    <NameWrapper textAlign={textAlign}>
      {showMoim && (
        <Name>
          <ShavedText line={1}>{groups[0]?.name}</ShavedText>
        </Name>
      )}
      {showChannel && (
        <Name>
          <ShavedText line={1}>{channels[0]?.name}</ShavedText>
        </Name>
      )}
    </NameWrapper>
  );
}

export default React.memo(GroupAndChannelName);
