import styled from "styled-components";
import LoadingIconBase from "common/components/loading/icon";
import { px2rem } from "common/helpers/rem";

export const ListWrapper = styled.div`
  width: 100%;
  height: fit-content;

  .react-horizontal-scrolling-menu--scroll-container {
    /*  NOTE: MoimCard의 transform, box-shadow 표현을 위해서 추가 공간을 확장했습니다. */
    padding: ${px2rem(8)} ${px2rem(4)} ${px2rem(6)};
  }
`;

export const ItemContainer = styled.div`
  img {
    height: 100%;
  }

  > a > div {
    height: 100%;
    max-width: inherit;
    min-width: inherit;
  }
`;

export const LoadWrapper = styled.div`
  width: 100%;
  height: ${px2rem(42)};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Loading = styled(LoadingIconBase)``;

export const InfiniteScrollerWrapper = styled.div`
  width: 100%;
`;
