import * as React from "react";
import { FormattedMessage } from "react-intl";
import { useActions, useStoreState } from "app/store";
import { usePurchasedPickUpTime } from "./usePurchasedPickUpTime";
import useCoinRechargePackageFetchSelector from "common/hooks/useEntitiesFetchSelectors/useCoinRechargePackageFetchSelector";
import { useNativeSecondaryView } from "common/hooks/useSecondaryView";
import { useCurrentUserLocale } from "common/hooks/localization/useCurrentUserLocale";
import useOpenState from "app/common/hooks/useOpenState";
import {
  useAddCartButton,
  useDeliveryTrackingButton,
  useManualPurchaseConfirmButton,
  useReviewWriteButton,
  useMyReviewButton,
  useFundingSignButton,
  useSellerCustomButton,
  useOpenReviewWriteDialog,
} from "./buttons";
import { usePaymentStatusDisplay } from "common/helpers/commerce";
import { useIntlShort } from "common/hooks/useIntlShort";
import useRedirect from "common/hooks/useRedirect";

import { manualPurchaseItemConfirm } from "app/actions/commerce";
import { getSellerSelector } from "app/selectors/commerce";
import { currentGroupSelector } from "app/selectors/app";
import { BlockchainCurrencyTypes } from "app/enums";

import OptionItem from "common/components/commerce/optionItem";
import AlertDialog from "common/components/alertDialog";
import ButtonCasePayment, {
  IButton,
} from "common/components/commerce/optionItem/buttonCase/payment";
import ConfirmDialog from "common/components/alertDialog/confirm";
import {
  AdditionalFeeWithPreview,
  PriceWithAdditionalFees,
} from "app/modules/commerce/components/productShow/layout/productSummary/components/right/price/additionalFee";
import { PickUpScheduleText } from "app/modules/commerce/containers/paymentDetail/components/common";
import Brand from "common/components/commerce/optionItem/components/brand";
import PurchaseItemSkeleton from "./skeleton";
import {
  TitleContainer,
  Title,
  PriceWrapper,
  CustomStatus,
  StyledReviewReward,
  SubInfoText,
} from "./styled";

interface IProps {
  purchaseId?: Moim.Id;
  displayStatus?: Moim.Commerce.DisplayingPurchaseStatusType;
  purchaseItem: Moim.Commerce.IPurchaseItem;
  signUrl?: string;
  showPickUptime?: boolean;
}

export default function PurchaseItem({
  purchaseId,
  displayStatus: statusProps,
  purchaseItem,
  signUrl,
  showPickUptime = true,
}: IProps) {
  const {
    isOpen: isOpenPurchaseConfirmAlert,
    open: openPurchaseConfirmAlert,
    close: closePurchaseConfirmAlert,
  } = useOpenState();
  const {
    isOpen: isOpenSuccessConfirmPurchaseAlert,
    open: openSuccessConfirmPurchaseAlert,
    close: closeSuccessConfirmPurchaseAlert,
  } = useOpenState();

  const redirect = useRedirect();
  const { location } = useNativeSecondaryView();
  const locale = useCurrentUserLocale();
  const openReviewWriteDialog = useOpenReviewWriteDialog(
    purchaseItem,
    purchaseId,
  );

  const intl = useIntlShort();

  const isCandy = purchaseItem.type === "candy";
  const isRechargedCandy = Boolean(
    isCandy &&
      purchaseItem.extraData &&
      (purchaseItem.extraData.coinId as Moim.Id | undefined) &&
      (purchaseItem.extraData.packageId as Moim.Id | undefined),
  );

  const {
    isLoading: isRechargePackageLoading,
    rechargePackages: { 0: rechargePackage },
  } = useCoinRechargePackageFetchSelector(
    isRechargedCandy ? [purchaseItem.extraData!.packageId as Moim.Id] : [],
  );

  const { hubSeller, product } = useStoreState(state => ({
    hubSeller: getSellerSelector(
      state,
      currentGroupSelector(state)?.seller_id ?? "",
    ),
    product: state.entities.commerce_product[purchaseItem.productId],
  }));

  const { dispatchManualPurchaseItemConfirm } = useActions({
    dispatchManualPurchaseItemConfirm: manualPurchaseItemConfirm,
  });

  const enabledTeamBuyingId =
    purchaseItem.teamBuyingId && purchaseItem.teamId
      ? purchaseItem.teamBuyingId
      : undefined;

  const imageProps = React.useMemo(
    () =>
      purchaseItem.imageUrl
        ? {
            web: [{ url: purchaseItem.imageUrl }],
            mobile: [{ url: purchaseItem.imageUrl }],
          }
        : undefined,
    [purchaseItem.imageUrl],
  );

  const option = React.useMemo(() => {
    if (isCandy) {
      if (isRechargedCandy && rechargePackage) {
        return `${rechargePackage.amount} ${(purchaseItem.extraData
          ?.coinInfoSnap as Record<string, string> | undefined)?.symbol ?? ""}`;
      }
      return;
    } else {
      if (
        !purchaseItem.productVariantValue ||
        Object.keys(purchaseItem.productVariantValue).length === 0
      ) {
        return;
      }

      const res =
        purchaseItem.productVariantValue[locale as any] ??
        purchaseItem.productVariantValue.en ??
        purchaseItem.productVariantValue[
          Object.keys(purchaseItem.productVariantValue)[0]
        ];

      return Object.entries(res)
        ?.map(([key, value]) => `${key}:${value}`)
        .join(" · ");
    }
  }, [
    isCandy,
    isRechargedCandy,
    rechargePackage,
    purchaseItem.extraData,
    purchaseItem.productVariantValue,
    locale,
  ]);

  const { date, time } = usePurchasedPickUpTime(purchaseItem);

  const manualPurchaseConfirm = React.useCallback(async () => {
    await dispatchManualPurchaseItemConfirm([purchaseItem.id]);
    closePurchaseConfirmAlert();
    openSuccessConfirmPurchaseAlert();
    setTimeout(() => {
      if (location) {
        redirect(location.pathname);
      }
    }, 300);
  }, [
    closePurchaseConfirmAlert,
    dispatchManualPurchaseItemConfirm,
    redirect,
    purchaseItem.id,
  ]);
  const handleClickReviewWriteButton = React.useCallback(async () => {
    closeSuccessConfirmPurchaseAlert();
    openReviewWriteDialog();
  }, [closeSuccessConfirmPurchaseAlert, openReviewWriteDialog]);

  const DeliveryTrackingButton = useDeliveryTrackingButton(
    purchaseItem?.deliveryId,
    hubSeller?.config?.deliveryTrackingType,
    purchaseItem?.deliveryCompanyName,
    purchaseItem?.trackingNumber,
  );
  const AddToCartButton = useAddCartButton(purchaseItem);
  const ReviewWriteButton = useReviewWriteButton(openReviewWriteDialog);
  const ManualPurchaseConfirmButton = useManualPurchaseConfirmButton(
    openPurchaseConfirmAlert,
  );
  const MyReviewButton = useMyReviewButton(
    purchaseItem.productId,
    purchaseItem.reviewId,
  );
  const FundingSignButton = useFundingSignButton(signUrl);
  const SellerUpdatesButton = useSellerCustomButton(purchaseItem.customButtons);
  const buttons = React.useMemo(() => {
    const _buttons: IButton[] = [];
    if (SellerUpdatesButton) {
      _buttons.push(SellerUpdatesButton);
    }
    switch (purchaseItem.status ?? statusProps) {
      case "paymentError": {
        break;
      }
      case "created":
      case "vbankPending":
      case "paid":
        break;
      case "deliveryDelayed":
      case "preparingForDelivery":
      case "waitingToBePickedUp":
        if (purchaseItem.deliveryId) {
          _buttons.push(DeliveryTrackingButton);
        }
        break;
      case "inTransit":
      case "deliveryCompleted":
        if (purchaseItem.deliveryId) {
          _buttons.push(DeliveryTrackingButton);
        }

        _buttons.push(ManualPurchaseConfirmButton);
        break;
      case "purchaseCompleted":
        _buttons.push(AddToCartButton);
        if (purchaseItem.shippingRequired && purchaseItem.deliveryId) {
          _buttons.push(DeliveryTrackingButton);
        }
        switch (purchaseItem.reviewable) {
          case "alreadyWritten": {
            _buttons.push(MyReviewButton);
            break;
          }
          case "canWrite": {
            _buttons.push(ReviewWriteButton);
            break;
          }
          default: {
            break;
          }
        }
        break;
      case "waitingForSign":
        _buttons.push(FundingSignButton);
        break;
      case "refundRequested":
      case "refunded":
        break;
    }

    return _buttons;
  }, [
    purchaseItem.status,
    purchaseItem.deliveryId,
    purchaseItem.shippingRequired,
    purchaseItem.reviewable,
    statusProps,
    ManualPurchaseConfirmButton,
    AddToCartButton,
    FundingSignButton,
    DeliveryTrackingButton,
    MyReviewButton,
    ReviewWriteButton,
    SellerUpdatesButton,
  ]);

  const customStatusElement = React.useMemo(() => {
    if (hubSeller?.customStatusDefinition && purchaseItem.customStatus) {
      return (
        <CustomStatus>
          {hubSeller.customStatusDefinition[purchaseItem.customStatus]?.[
            locale
          ] ?? null}
        </CustomStatus>
      );
    }
    return null;
  }, [hubSeller, purchaseItem, locale]);

  const title = usePaymentStatusDisplay(
    purchaseItem.displayingStatus ?? statusProps,
    {
      shippingRequired: purchaseItem.shippingRequired,
      isBlockchainCurrency: Boolean(
        purchaseItem.price_price.currency in BlockchainCurrencyTypes,
      ),
      isTeamBuying: Boolean(enabledTeamBuyingId),
      type: purchaseItem.deliveryType,
    },
  );

  if (
    (!isCandy && !product) ||
    (isRechargedCandy && (isRechargePackageLoading || !rechargePackage))
  ) {
    return <PurchaseItemSkeleton />;
  }

  return (
    <>
      <TitleContainer>
        {customStatusElement}
        <Title>{title}</Title>
      </TitleContainer>
      {purchaseItem?.isBackOrder && (
        <SubInfoText>
          {intl("sale_status_backorder")}
          {purchaseItem?.snap?.backOrderDescription &&
            ` ・ ${purchaseItem.snap.backOrderDescription}`}
        </SubInfoText>
      )}
      {showPickUptime && Boolean(date || time) && (
        <SubInfoText>
          <PickUpScheduleText date={date} time={time} />
        </SubInfoText>
      )}
      <Brand
        brandId={purchaseItem?.brandId}
        brandName={purchaseItem?.snap?.brand?.name}
        brandLogoImageUrl={purchaseItem?.snap?.brand?.logoImageUrl}
      />
      <OptionItem
        sellerId={purchaseItem.sellerId}
        productId={
          isCandy
            ? (purchaseItem.extraData?.coinId as string) ?? ""
            : purchaseItem.productId
        }
        brandId={purchaseItem?.brandId}
        brandName={purchaseItem?.snap?.brand?.name}
        brandLogoUrl={purchaseItem?.snap?.brand?.logoImageUrl}
        variantId={purchaseItem.productVariantId}
        productType={isCandy ? "candy" : product?.type}
        status="onSale"
        title={purchaseItem.productName}
        imageUrl={imageProps}
        imageBGColor={
          isCandy ? purchaseItem.extraData?.coinInfoSnap?.color : undefined
        }
        option={option}
        price={purchaseItem.price_price}
        originPrice={purchaseItem.price_price}
        additionalFees={purchaseItem.additionalFees}
        qty={purchaseItem.quantity}
        weight={purchaseItem?.weight}
        sku={purchaseItem?.snap?.sku}
        useCheckBox={false}
      >
        {({
          Title: OptionTitle,
          Option,
          Price,
          TeamBuyingBadgeTitle,
          defaultValue,
        }) => (
          <>
            {enabledTeamBuyingId ? (
              <TeamBuyingBadgeTitle teamBuyingId={enabledTeamBuyingId} />
            ) : null}
            <OptionTitle>{defaultValue.title}</OptionTitle>
            {defaultValue.option && <Option>{defaultValue.option}</Option>}
            <Price>
              <PriceWrapper>
                <PriceWithAdditionalFees
                  currency={purchaseItem.price_price.currency}
                  price={purchaseItem.price_price.value}
                  AdditionalFeeComponent={AdditionalFeeWithPreview}
                  additionalFees={purchaseItem.additionalFees}
                >
                  {prices =>
                    (prices.length ?? 0) > 1 ? <>({prices})</> : prices
                  }
                </PriceWithAdditionalFees>
                <span className="quantity">
                  <FormattedMessage
                    id="commerce/quantity_short"
                    values={{ n: purchaseItem.quantity }}
                  />
                </span>
              </PriceWrapper>
            </Price>
            <ButtonCasePayment buttons={buttons} />
          </>
        )}
      </OptionItem>

      <ConfirmDialog
        confirmMessage=""
        disableCheckButton={true}
        open={isOpenPurchaseConfirmAlert}
        content={<FormattedMessage id="dialog_confirm_purchase_body" />}
        onClose={closePurchaseConfirmAlert}
        negativeButtonProps={{
          text: <FormattedMessage id="cancel_button" />,
          onClick: closePurchaseConfirmAlert,
        }}
        positiveButtonProps={{
          text: <FormattedMessage id="button_confirm_purchase" />,
          onClick: manualPurchaseConfirm,
        }}
      />
      <AlertDialog
        open={isOpenSuccessConfirmPurchaseAlert}
        title={<FormattedMessage id="dialog_success_confirm_purchase_title" />}
        content={<StyledReviewReward />}
        rightButtons={[
          {
            text: <FormattedMessage id="cancel_button" />,
            onClick: closePurchaseConfirmAlert,
          },
          {
            text: <FormattedMessage id="button_go_write_review" />,
            onClick: handleClickReviewWriteButton,
          },
        ]}
        onClose={closeSuccessConfirmPurchaseAlert}
      />
    </>
  );
}
