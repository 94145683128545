import React from "react";
import { FormattedNumber } from "react-intl";
import styled from "styled-components";
import RatingBase from "@material-ui/lab/Rating";
import { B4Regular } from "common/components/designSystem/typos";
import { px2rem } from "common/helpers/rem";
import { getFlexAlignStyle } from "../wrapper/styled";
import { useStoreState } from "app/store";
import { currentGroupSelector } from "app/selectors/app";

const Wrapper = styled(B4Regular)<{
  horizontalAlign?: "start" | "center" | "end" | "space-around";
}>`
  padding: ${px2rem(4)} 0;
  color: ${props => props.theme.colorV2.colorSet.grey300};
  display: flex;

  .MuiSvgIcon-root {
    width: ${px2rem(18)};
    height: ${px2rem(18)};
  }

  ${props =>
    props.horizontalAlign &&
    getFlexAlignStyle({
      direction: "row",
      horizontalAlign: props.horizontalAlign,
    })}
`;

interface IProps {
  className?: string;
  avgRate: number;
  ratingsCount?: number;
  reviewsCount?: number;
  block: Moim.Component.ProductItem.IRating;
  horizontalAlign?: "start" | "center" | "end" | "space-around";
}

const Rating = ({
  className,
  avgRate,
  ratingsCount,
  reviewsCount,
  horizontalAlign,
}: IProps) => {
  const enableProductReviewRating = useStoreState(
    state => currentGroupSelector(state)?.config.enableProductReviewRating,
  );

  if (
    !enableProductReviewRating ||
    !ratingsCount ||
    !reviewsCount ||
    !avgRate === undefined
  ) {
    return null;
  }
  return (
    <Wrapper className={className} horizontalAlign={horizontalAlign}>
      <RatingBase precision={0.1} readOnly={true} value={avgRate} /> (
      <FormattedNumber value={ratingsCount} useGrouping={true} />)
    </Wrapper>
  );
};

export default React.memo(Rating);
