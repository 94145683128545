import React from "react";
import SaleTimerComponent from "./component";
import useTeamBuyingFetchSelector from "common/hooks/commerce/useTeamBuyingFetchSelector";
import { useTheme } from "styled-components";

export function getActiveTimeSaleProductSet(
  productSets?: Moim.Commerce.ITimeSaleEntity[],
): Moim.Commerce.ITimeSaleEntity | undefined {
  const activeTimeSaleProductSets = (productSets ?? []).filter(
    item => item.showTimeSaleTimer && item.timeSaleEndAt,
  );

  return activeTimeSaleProductSets.reverse()[0];
}

interface IProps {
  size: "small" | "normal";
  productSets: Moim.Commerce.ITimeSaleEntity[] | undefined;
  teamBuyingId?: Moim.Id;
  className?: string;
}

export default function TimeSaleTimer({
  size,
  productSets,
  teamBuyingId,
  className,
}: IProps) {
  const theme = useTheme();
  const { teamBuyingData } = useTeamBuyingFetchSelector(teamBuyingId);

  if (teamBuyingId && teamBuyingData) {
    return (
      <SaleTimerComponent
        size={size}
        className={className}
        endAt={teamBuyingData.endAt}
        backgroundColor={theme.colorV2.primary.main}
      />
    );
  }

  if (Boolean(productSets?.length)) {
    const latestProductSet = getActiveTimeSaleProductSet(productSets);
    if (latestProductSet) {
      return (
        <SaleTimerComponent
          size={size}
          className={className}
          endAt={latestProductSet.timeSaleEndAt}
          backgroundColor={latestProductSet.badgeBackgroundColor}
        />
      );
    }
  }

  return null;
}
