import * as React from "react";
import { TagGroupList, TagList } from "./components/tagSet";
import { Section } from "./styled";
import { ScrollController } from "./components/scrollController";
import FilterDialog from "./components/filterDialog";
import { TagGroupPopover } from "./components/tagGroupPopover";
import useOpenState from "common/hooks/useOpenState";
import useIsMobile from "common/hooks/useIsMobile";
import { TopBannerContext } from "../topBanner/context";
import { useBlockitRendererContext } from "common/components/blockitEditorBase/components/blockitRenderer.v2/context";

interface IProps {
  className?: string;
  tagGroups: Moim.TagSet.ITagGroup[];
  selectedTags: Record<string, string[]>;
  onChangeHandler(selectedTags: Record<string, string[]>): void;
}

export const HorizontalTagList: React.FC<IProps> = ({
  className,
  tagGroups,
  selectedTags,
  onChangeHandler,
}) => {
  const isMobile = useIsMobile();
  const { isOpen, open, close } = useOpenState(false);
  const [activeTagGroupId, setActiveTagGroupId] = React.useState<string>();
  const [popoverAnchorElement, setAnchorElement] = React.useState<
    null | Element | ((element: Element) => Element)
  >();
  const [topBannerContext] = React.useContext(TopBannerContext);
  const { renderMode } = useBlockitRendererContext();

  const selectedAllTagsCount: number = React.useMemo(
    () =>
      Object.values(selectedTags).reduce((prev, curr) => prev + curr.length, 0),
    [selectedTags],
  );

  if (!tagGroups.length) {
    return null;
  }
  return (
    <Section
      className={className}
      isTopBannerOpen={topBannerContext.isOpen}
      renderMode={renderMode}
    >
      <ScrollController
        tagGroups={tagGroups}
        openFilterDialog={open}
        selectedAllTagsCount={selectedAllTagsCount}
      >
        {tagGroups.length > 1 ? (
          <TagGroupList
            tagGroups={tagGroups}
            selectedTags={selectedTags}
            activeTagGroupId={activeTagGroupId}
            onClickTagGroup={(e, tagGroup) => {
              setActiveTagGroupId(tagGroup.id);
              if (isMobile) {
                open();
              } else {
                setAnchorElement(e.currentTarget);
              }
            }}
          />
        ) : (
          <TagList
            tagGroup={tagGroups[0]}
            selectedTags={selectedTags[tagGroups[0]?.id]}
            onChange={(id, selected) => {
              onChangeHandler({ ...selectedTags, [id]: selected });
            }}
          />
        )}
      </ScrollController>
      <FilterDialog
        isOpen={isOpen}
        tagGroups={tagGroups}
        selectedTags={Object.entries(selectedTags).reduce<
          Record<string, string[]>
        >(
          (result, [key, value]) => {
            if (value.length) {
              result[key] = value;
            }
            return result;
          },
          activeTagGroupId ? { [activeTagGroupId]: [] } : {},
        )}
        onApply={onChangeHandler}
        onClose={() => {
          close();
          setActiveTagGroupId(undefined);
        }}
      />
      <TagGroupPopover
        tagGroup={tagGroups.find(tagGroup => activeTagGroupId === tagGroup.id)}
        selectedTags={selectedTags[activeTagGroupId!]}
        anchorElement={popoverAnchorElement}
        isOpen={Boolean(popoverAnchorElement)}
        onClose={() => {
          setAnchorElement(undefined);
          setActiveTagGroupId(undefined);
        }}
        onChange={(id, selected) =>
          onChangeHandler({ ...selectedTags, [id]: selected })
        }
      />
    </Section>
  );
};
