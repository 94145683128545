import React from "react";
import debounce from "lodash/debounce";
import throttle from "lodash/throttle";
import { DebouncedFunc } from "lodash";

export const useResizeDetector = <T extends HTMLElement>({
  refreshMode,
  refreshRate = 1000,
  ...options
}: ResizeObserverOptions & {
  refreshMode?: "debounce" | "throttle";
  refreshRate?: number;
}) => {
  const ref = React.useRef<T>(null);
  const [size, setSize] = React.useState<{
    width: number | undefined;
    height: number | undefined;
  }>({
    width: undefined,
    height: undefined,
  });

  const resizeCallback: ResizeObserverCallback = React.useCallback(
    (entries: ResizeObserverEntry[], _observer: ResizeObserver) => {
      entries.forEach(entry => {
        const { width, height } = entry?.contentRect || {};
        setSize(prevSize => {
          if (prevSize.width === width && prevSize.height === height) {
            return prevSize;
          }

          return { width, height };
        });
      });
    },
    [],
  );

  const resizeHandler = React.useMemo(() => {
    switch (refreshMode) {
      case "debounce":
        return debounce(resizeCallback, refreshRate);
      case "throttle":
        return throttle(resizeCallback, refreshRate);
      default:
        return resizeCallback;
    }
  }, [resizeCallback, refreshRate, refreshMode]);

  React.useEffect(() => {
    let resizeObserver: ResizeObserver | undefined;
    if (ref.current) {
      resizeObserver = new window.ResizeObserver(resizeHandler);
      resizeObserver.observe(ref.current, options);
    }

    return () => {
      resizeObserver?.disconnect?.();
      (resizeHandler as DebouncedFunc<ResizeObserverCallback>).cancel?.();
    };
  }, [resizeHandler, ref]);

  return [ref, size] as [
    React.RefObject<T>,
    { width: number | undefined; height: number | undefined },
  ];
};
