import { Svg48EmptyUser } from "@moim/icons";
import { B1Regular, H8Bold } from "common/components/designSystem/typos";
import { MEDIA_QUERY } from "common/constants/responsive";
import { px2rem } from "common/helpers/rem";
import styled, { css } from "styled-components";

export const AppBarStickyWrapperStyle = css`
  background-color: ${(props) => props.theme.colorV2.colorSet.white1000};
`;

export const RootLayoutBodyStyle = css`
  @media ${MEDIA_QUERY.ONLY_MOBILE} {
    height: 100vh;

    div[class^="SecondaryViewWrapper"] {
      display: flex;
      flex-direction: column;
    }
  }
`;

export const DefaultLayoutBodyStyle = css`
  @media ${MEDIA_QUERY.ONLY_MOBILE} {
    height: 100%;

    > div:first-child {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }

  @media ${MEDIA_QUERY.EXCEPT_MOBILE} {
    width: 100%;
    height: 100%;
  }
`;

export const AppBarTitle = styled(H8Bold)`
  color: ${(props) => props.theme.colorV2.colorSet.grey800};
`;

export const EmptyWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: ${px2rem(32)} ${px2rem(16)};
  background-color: ${(props) => props.theme.colorV2.colorSet.white1000};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${px2rem(8)};
`;

export const EmptyUserIcon = styled(Svg48EmptyUser).attrs((props) => ({
  size: "xxl",
  touch: props.size ?? 80,
}))``;

export const GuideText = styled(B1Regular)`
  text-align: center;
  white-space: pre-line;
  color: ${(props) => props.theme.colorV2.colorSet.grey600};
`;
