import * as React from "react";
import styled from "styled-components";
import {
  ItemWrapper,
  LinkChannelIcon,
  LinkChannelNameWrapper,
  WithUnreadStatusBadgeWrapper,
} from "../styled";
import ChannelUnreadCount from "../channelUnreadCount";
import {
  B3RegularStyle,
  H10BoldStyle,
} from "common/components/designSystem/typos";
import useRedirect from "common/hooks/useRedirect";
import { useStoreState } from "app/store";
import {
  channelUnreadStatusSelector,
  unreadCountSelector,
} from "app/selectors/channel";

import channelToUrl from "app/modules/layout/components/topNavigation/channelToUrl";
import useCurrentGroup from "common/hooks/useCurrentGroup";
import useMatchRoute from "common/hooks/useMatchRoute";
import useMainPanelRefresh from "common/hooks/mainPanelRefresh";
import isSelectedChannel from "common/helpers/isSelectedChannel";
import { getIsInnerUrl } from "common/components/channelList/components/channelItem";
import { getChannelUrl } from "common/helpers/getChannelUrl";

const ChannelName = styled.div<{ selected: boolean }>`
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  ${props => (props.selected ? H10BoldStyle : B3RegularStyle)}
`;

interface IProps {
  channel: Moim.Channel.SimpleChannelWithoutCategoryType;
  onClickChannel?(channel: Moim.Channel.SimpleChannelWithoutCategoryType): void;
}

function LinkChannelController({
  channel,
  children,
}: React.PropsWithChildren<Pick<IProps, "channel">>) {
  const preventDefault: React.MouseEventHandler<HTMLAnchorElement> = React.useCallback(
    e => {
      e.preventDefault();
    },
    [],
  );

  if (channel.type !== "link") {
    return <>{children}</>;
  }

  return (
    <LinkChannelNameWrapper>
      <a href={channel.url} onClick={preventDefault}>
        {children}
        {!getIsInnerUrl(channel.url) ? <LinkChannelIcon /> : null}
      </a>
    </LinkChannelNameWrapper>
  );
}

export default function ChannelItem({ channel, onClickChannel }: IProps) {
  const { isLoggedIn } = useStoreState(state => ({
    isLoggedIn: Boolean(state.app.currentUserId),
  }));
  const redirect = useRedirect();
  const mainPanelRefresh = useMainPanelRefresh();
  const currentGroup = useCurrentGroup();
  const { unreadCount, isUnread } = useStoreState(state => {
    const unread = unreadCountSelector(state, channel.id);
    return {
      unreadCount: unread,
      isUnread:
        unread === 0 ? channelUnreadStatusSelector(state, channel) : false,
    };
  });

  const selectedChannel = useMatchRoute();
  const isSelected = React.useMemo(
    () =>
      isSelectedChannel(
        selectedChannel,
        channel.type,
        channel.id,
        currentGroup?.home.web.type === "channel"
          ? currentGroup?.home.web.ref
          : undefined,
      ),
    [selectedChannel, channel, currentGroup?.home.web],
  );

  const preventAnchorCallback: React.MouseEventHandler<HTMLAnchorElement> = React.useCallback(
    e => {
      e.preventDefault();
    },
    [],
  );

  const channelNameElement = React.useMemo(() => {
    const href = channelToUrl(channel);
    return (
      <>
        <ChannelName selected={isSelected}>
          <WithUnreadStatusBadgeWrapper
            href={href ?? "#"}
            rel={!href ? "nofollow" : undefined}
            isUnread={isUnread}
            onClick={preventAnchorCallback}
          >
            {channel.name}
          </WithUnreadStatusBadgeWrapper>
        </ChannelName>
        <ChannelUnreadCount count={unreadCount} />
      </>
    );
  }, [isUnread, isSelected, unreadCount, channel, preventAnchorCallback]);

  const handleClick = React.useCallback(() => {
    const url = getChannelUrl(channel);
    if (url) {
      redirect(url, undefined, isLoggedIn);
      onClickChannel?.(channel);
      mainPanelRefresh.doRefresh();
    }
  }, [channel, mainPanelRefresh, redirect, onClickChannel]);

  return (
    <ItemWrapper onClick={handleClick} selected={isSelected}>
      <LinkChannelController channel={channel}>
        {channelNameElement}
      </LinkChannelController>
    </ItemWrapper>
  );
}
