import * as React from "react";
import { InView } from "react-intersection-observer";
import { useRouteMatch } from "react-router";
import { useActions, useStoreState } from "app/store";
import useHover from "common/hooks/useHover";
import {
  closeNewItemSnackbar,
  ActionCreators as ForumActionCreators,
} from "app/actions/forum";
import { ActionCreators as ForumCommentActionCreators } from "app/modules/postShow/components/bottom/components/commentList/action";
import { PostShowContext } from "app/modules/postShow/context";
import Component from "./component";
import useThreadFetchSelector from "common/hooks/useEntitiesFetchSelectors/useThreadFetchSelector";

const WithInViewController = React.memo(
  ({ reply }: { reply: Moim.Forum.IThread }) => {
    const match = useRouteMatch<Moim.IMatchParams>();
    const focusedId = match.params.focusedId;
    const { containerRef, showConfig } = React.useContext(PostShowContext);
    const [hoverRef, isHover] = useHover<HTMLDivElement>();
    const [forumShowPositionTop, setForumShowPositionTop] = React.useState<
      number | undefined
    >(undefined);

    const newCommentId = useStoreState(
      state =>
        state.forumData.newCommentSnackbar.newCommentId ??
        [
          ...(state.forumData.postedCommentIds[reply.parent_id] ?? []),
        ].reverse()[0],
    );

    const {
      closeNewCommentSnackbar,
      resetHasNewComment,
      setCommentSnackbarDirection,
    } = useActions({
      closeNewCommentSnackbar: closeNewItemSnackbar,
      resetHasNewComment: ForumCommentActionCreators.resetJustAdded,
      setCommentSnackbarDirection:
        ForumActionCreators.setNewItemSnackbarDirection,
    });

    const autoFocus = reply?.id === newCommentId;

    const handleViewChange = React.useCallback(
      (inView: boolean) => {
        if (inView && autoFocus) {
          resetHasNewComment?.();
        }

        if (reply.id === newCommentId) {
          if (inView) {
            closeNewCommentSnackbar("nestedReply");
          } else {
            if (hoverRef?.current) {
              if (
                hoverRef.current.getBoundingClientRect().y <
                (forumShowPositionTop || 0)
              ) {
                setCommentSnackbarDirection({
                  type: "nestedReply",
                  direction: "top",
                });
              } else {
                setCommentSnackbarDirection({
                  type: "nestedReply",
                  direction: "bottom",
                });
              }
            }
          }
        }
      },
      [
        autoFocus,
        reply.id,
        hoverRef,
        newCommentId,
        forumShowPositionTop,
        closeNewCommentSnackbar,
        resetHasNewComment,
        setCommentSnackbarDirection,
      ],
    );

    React.useEffect(() => {
      if (autoFocus) {
        requestAnimationFrame(() => {
          hoverRef.current?.scrollIntoView({
            block: "center",
            behavior: "smooth",
          });
        });
      }
    }, [autoFocus]);

    React.useEffect(() => {
      if (focusedId === reply.id) {
        requestAnimationFrame(() => {
          hoverRef.current?.scrollIntoView({
            block: "center",
            behavior: "smooth",
          });
        });
      }
    }, [reply.id, focusedId]);

    React.useEffect(() => {
      setForumShowPositionTop(containerRef?.current?.getBoundingClientRect().y);
    }, [containerRef]);

    return (
      <InView onChange={handleViewChange} triggerOnce={true}>
        <Component
          ref={hoverRef}
          thread={reply}
          focusedId={focusedId}
          isHover={isHover}
          showCommentReaction={showConfig.show_comment_reaction}
          commentReactionType={showConfig.comment_reaction_type}
          showOriginalAuthorOnReply={showConfig.show_original_author_on_reply}
        />
      </InView>
    );
  },
);

interface IProps {
  replyId: string;
}

const ReplyItem = React.memo(({ replyId }: IProps) => {
  const {
    threads: { 0: reply },
  } = useThreadFetchSelector([replyId]);

  if (!reply || reply.deleted) {
    return null;
  }

  return <WithInViewController reply={reply} />;
});

export default React.memo(ReplyItem);
