import * as React from "react";
import styled, { css, useTheme } from "styled-components";
import SaleTimer from "app/modules/commerce/components/productShow/layout/productSummary/components/right/timeSaleTimer";
import RawSaleTimerComponent from "app/modules/commerce/components/productShow/layout/productSummary/components/right/timeSaleTimer/component";
import { px2rem } from "common/helpers/rem";
import { getFlexAlignStyle } from "../wrapper/styled";
import useTeamBuyingFetchSelector from "common/hooks/commerce/useTeamBuyingFetchSelector";

const Wrapper = styled.div<{
  horizontalAlign?: "start" | "center" | "end" | "space-around";
}>`
  padding: ${px2rem(4)} 0;
  display: flex;
  width: 100%;
  ${props =>
    props.horizontalAlign &&
    getFlexAlignStyle({
      direction: "row",
      horizontalAlign: props.horizontalAlign,
    })}
`;

const commonStyle = css`
  width: 100%;
  padding: 0;
`;

const StyledTimeSaleTimer = styled(SaleTimer)`
  ${commonStyle}
`;
const StyledRawTimeSaleTimer = styled(RawSaleTimerComponent)`
  ${commonStyle}
`;

interface IProps {
  product: Moim.Commerce.IProduct;
  className?: string;
  block: Moim.Component.ProductItem.ITimer;
  horizontalAlign?: "start" | "center" | "end" | "space-around";
}

const TimeSaleTimer = ({ product, className, horizontalAlign }: IProps) => {
  const productSets = product.productSets;
  const theme = useTheme();
  const { teamBuyingData } = useTeamBuyingFetchSelector(
    product.activeTeamBuyingId,
  );

  if (product.enableTeamBuying && product.activeTeamBuyingId) {
    if (!teamBuyingData) {
      return null;
    }

    return (
      <Wrapper className={className} horizontalAlign={horizontalAlign}>
        <StyledRawTimeSaleTimer
          size="small"
          endAt={teamBuyingData.endAt}
          backgroundColor={theme.colorV2.primary.main}
        />
      </Wrapper>
    );
  }

  if (
    Boolean(productSets?.length) &&
    productSets?.some(set => set.showTimeSaleTimer && set.timeSaleEndAt)
  ) {
    return (
      <Wrapper className={className} horizontalAlign={horizontalAlign}>
        <StyledTimeSaleTimer size="small" productSets={productSets} />
      </Wrapper>
    );
  }

  return null;
};

export default React.memo(TimeSaleTimer);
