// constants
import {
  Alert,
  CustomMeeting,
  Error,
  Normal,
  Success,
  FloatFlag,
} from "common/components/snackbar.new";

export interface IProps
  extends Moim.Snackbar.IPresetProps,
    Moim.Snackbar.IFlagProps,
    Moim.Snackbar.ISnackbarLeftIconProps,
    Moim.Snackbar.ISnackbarRightIconProps,
    Moim.Snackbar.ISnackbarRightSecondIconProps {
  type?: Moim.Snackbar.GLOBAL_ALERT_SNACKBAR_TYPE;
}

function GlobalSnackbar({
  text,
  textElement,
  type = "normal",
  leftIcon,
  rightIcon,
  rightSecondIcon,
  subType = "normal",
  title,
  description,
  descTextColor,
  disableCloseButton,
  onClick,
  onClickClose,
}: IProps) {
  switch (type) {
    case "normal":
    case "info":
      return (
        <Normal.Snackbar
          text={text}
          textElement={textElement}
          leftIcon={leftIcon}
          rightIcon={rightIcon}
          rightSecondIcon={rightSecondIcon}
          onClick={onClick}
        />
      );
    case "success":
      return (
        <Success.Snackbar
          text={text}
          textElement={textElement}
          leftIcon={leftIcon}
          rightIcon={rightIcon}
          rightSecondIcon={rightSecondIcon}
          onClick={onClick}
        />
      );
    case "alert":
      return (
        <Alert.Snackbar
          text={text}
          textElement={textElement}
          leftIcon={leftIcon}
          rightIcon={rightIcon}
          rightSecondIcon={rightSecondIcon}
          onClick={onClick}
        />
      );
    case "error":
      return (
        <Error.Snackbar
          text={text}
          textElement={textElement}
          leftIcon={leftIcon}
          rightIcon={rightIcon}
          rightSecondIcon={rightSecondIcon}
          onClick={onClick}
        />
      );
    case "custom-meeting":
      return (
        <CustomMeeting
          text={text}
          textElement={textElement}
          leftIcon={leftIcon}
          rightIcon={rightIcon}
          rightSecondIcon={rightSecondIcon}
          onClick={onClick}
        />
      );
    case "float-flag":
      return (
        <FloatFlag
          subType={subType}
          title={title}
          description={description}
          descTextColor={descTextColor}
          disableCloseButton={disableCloseButton}
          leftIcon={leftIcon}
          rightIcon={rightIcon}
          rightSecondIcon={rightSecondIcon}
          onClick={onClick}
          onClickClose={onClickClose}
        />
      );
  }
}

export default GlobalSnackbar;
