import * as React from "react";
import styled, { CSSProperties } from "styled-components";
import cx from "classnames";
import { useInView } from "react-intersection-observer";
import useHover from "common/hooks/useHover";

const Trigger = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
`;

interface IProps {
  transition:
    | {
        type: "landing" | "hover" | "click";
        value:
          | "fadeIn"
          | "fadeInUp"
          | "fadeInLeft"
          | "fadeInRight"
          | "fadeInDown";
        duration?: number;
      }
    | undefined;
  children(params: { className?: string; styles?: CSSProperties }): void;
}
export default function TransitionController({ transition, children }: IProps) {
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  const [hoverRef, isHover] = useHover<HTMLDivElement>();

  const showAnimation =
    transition?.type === "hover"
      ? isHover
      : transition?.type === "landing"
      ? inView
      : false;

  if (!transition) {
    return <>{children({})}</>;
  }
  return (
    <>
      <Trigger ref={ref} />
      <Trigger ref={hoverRef} />
      {children({
        className: cx({
          animate__fadeIn: showAnimation && transition?.value === "fadeIn",
          animate__fadeInUp: showAnimation && transition?.value === "fadeInUp",
          animate__fadeInDown:
            showAnimation && transition?.value === "fadeInDown",
          animate__fadeInLeft:
            showAnimation && transition?.value === "fadeInLeft",
          animate__fadeInRight:
            showAnimation && transition?.value === "fadeInRight",
        }),
        styles: { animationDuration: `${transition?.duration ?? 1000}ms` },
      })}
    </>
  );
}
