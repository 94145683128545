import * as React from "react";
import ProductSetPreview from "./components/productSet";
import CategoryPreview from "./components/category";
import SellerPreview from "./components/seller";
import CampaignPreview from "./components/campaign";
import BrandPreview from "./components/brand";
import AllProductPreview from "./components/all";
import { withPlacement } from "../../../hoc/withPlacement";

interface IProps extends Moim.Blockit.V2.Commerce.IProductListPreviewBlock {}

const ProductListPreviewRenderer: React.FC<IProps> = ({ ...rest }) => {
  switch (rest.resourceType) {
    case "productSet": {
      return <ProductSetPreview {...rest} />;
    }

    case "category": {
      return <CategoryPreview {...rest} />;
    }

    case "seller": {
      return <SellerPreview {...rest} />;
    }

    case "campaign": {
      return <CampaignPreview {...rest} />;
    }
    case "brand": {
      return <BrandPreview {...rest} />;
    }

    case "all": {
      return <AllProductPreview {...rest} />;
    }
    default:
      return null;
  }
};

const ProductListPreview: React.FC<IProps> = ({ ...rest }) => {
  return <ProductListPreviewRenderer {...rest} />;
};

export default withPlacement(React.memo(ProductListPreview));
