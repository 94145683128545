import styled from "styled-components";
import { px2rem } from "common/helpers/rem";
import { B3Regular } from "common/components/designSystem/typos";

export const AgreementWrapper = styled.div`
  margin-top: ${px2rem(24)};
`;

export const CheckBoxTouchArea = styled.div`
  width: ${px2rem(42)};
  height: ${px2rem(42)};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: ${px2rem(4)};
`;

export const CheckBoxInputTitle = styled(B3Regular)`
  margin-left: ${px2rem(6)};
  position: relative;
`;

export const AgreementAll = styled.div`
  display: flex;
  align-items: center;
  height: ${px2rem(42)};
  ${CheckBoxInputTitle} {
    color: ${props => props.theme.colorV2.colorSet.grey800};
  }
`;

export const AgreementDetailWrapper = styled.div`
  display: flex;
  height: ${px2rem(42)};
  padding: 0 ${px2rem(16)};
`;

export const AgreementDetailItem = styled.div`
  flex: 1;
  min-width: 0;
  display: flex;
  align-items: center;

  ${CheckBoxInputTitle} {
    color: ${props => props.theme.colorV2.colorSet.grey500};
  }
`;
