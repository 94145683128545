import * as React from "react";

import DesktopSearchInput from "app/modules/search/container/searchInput/desktop";

interface IProps {
  blockProperties?: Partial<Moim.Layout.Navigation.SearchElementType>;
}

const Search: React.FC<IProps> = props => (
  <DesktopSearchInput blockProperties={props.blockProperties} />
);
export default Search;
