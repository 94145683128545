import * as React from "react";
import moment from "moment";
import { useActions } from "app/store";
import { searchUsers } from "app/actions/user";
import useCancelToken from "common/hooks/useCancelToken";
import { useIntlShort } from "common/hooks/useIntlShort";
import useCurrentGroup from "common/hooks/useCurrentGroup";
import { DefaultLayout } from "../../layout";
import MemberList from "common/components/memberList";
import UnsignedChecker from "common/components/unsiginedChecker";
import { WITHOUT_TIME_DATE_FORMAT } from "common/constants/default";
import { PermissionDeniedFallbackType } from "app/enums";
import {
  AppBarStickyWrapperStyle,
  AppBarTitle,
  DefaultLayoutBodyStyle,
  EmptyUserIcon,
  EmptyWrapper,
  GuideText,
  RootLayoutBodyStyle,
} from "./styled";

interface IProps {
  userId?: Moim.Id;
  title?: string;
}

export const UserInvitees = ({ userId, title }: IProps) => {
  const intl = useIntlShort();
  const cancelToken = useCancelToken();
  const currentGroup = useCurrentGroup();
  const [users, setUsers] = React.useState<Moim.User.IOriginalUserDatum[]>();

  const { dispatchSearchUsers } = useActions({
    dispatchSearchUsers: searchUsers,
  });

  const handleGetMembers = React.useCallback(async () => {
    const params = currentGroup?.is_hub
      ? { inviterParentId: userId }
      : { inviterId: userId };
    const result = await dispatchSearchUsers(params, cancelToken.current.token);
    setUsers(result?.data);
  }, [dispatchSearchUsers, userId, cancelToken]);

  React.useEffect(() => {
    handleGetMembers();
  }, []);

  const subTitleKeys = React.useMemo(
    () => ({
      type: "customRenderer" as const,
      value: member =>
        intl("date_signed_up", {
          date: moment(member.created_at).format(
            intl(WITHOUT_TIME_DATE_FORMAT),
          ),
        }),
    }),
    [intl],
  );
  const paginatedUsers = React.useMemo(
    () => ({ data: users ?? [], paging: {} }),
    [users],
  );

  if (!userId) {
    return null;
  }

  return (
    <DefaultLayout
      appBar={{
        wrapperStickyStyle: AppBarStickyWrapperStyle,
        enableScrollParallax: true,
        titleElement: (
          <AppBarTitle>
            {title} ({users?.length})
          </AppBarTitle>
        ),
        alwaysShowAppBarTitle: true,
      }}
      rootOverrideStyle={RootLayoutBodyStyle}
      bodyOverrideStyle={DefaultLayoutBodyStyle}
    >
      <UnsignedChecker fallbackType={PermissionDeniedFallbackType.SCREEN}>
        {users?.length !== 0 ? (
          <MemberList
            hasTitle={false}
            hasPositionChip={true}
            subTitleKeys={subTitleKeys}
            members={paginatedUsers}
            isLoading={users === undefined}
            onGetMembers={handleGetMembers}
          />
        ) : (
          <EmptyWrapper>
            <EmptyUserIcon />
            <GuideText>
              {intl("custom_system_wording_my_invitees_list_empty")}
            </GuideText>
          </EmptyWrapper>
        )}
      </UnsignedChecker>
    </DefaultLayout>
  );
};
