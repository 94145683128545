import React from "react";
import { IOption } from "common/components/designSystem/selection/type";
import { StaticSelection } from "common/components/designSystem/selection";
import useOpenState from "common/hooks/useOpenState";
import { ISelectedData } from "common/components/searchInput/selectable/type";
import { useIntlShort } from "common/hooks/useIntlShort";
import SelectionBottomSheet from "common/components/selectionBottomSheet";

interface IProps {
  item: Moim.Form.IFormItem;
  title: React.ReactNode;
  placeholder: string;
  currentValue: any;
  error: any;
  options: IOption[];
  onChange: (id: Moim.Id, value: any) => void;
}

export const SingleSelectionBottomSheetFormItem = ({
  item,
  title,
  placeholder,
  currentValue,
  error,
  options,
  onChange,
}: IProps) => {
  const intl = useIntlShort();
  const {
    isOpen: isBottomSheetOpen,
    open: openBottomSheet,
    close: closeBottomSheet,
  } = useOpenState();

  const [bottomSheetOptions, setBottomSheetOptions] = React.useState<IOption[]>(
    [],
  );
  const [bottomSheetSelectedData, setBottomSheetSelectedData] = React.useState<
    ISelectedData[]
  >([]);

  const handleCloseBottomSheet = React.useCallback(() => {
    (document.activeElement as HTMLElement)?.blur();
    closeBottomSheet();
  }, [closeBottomSheet]);

  const handleBottomSheetSearch = React.useCallback(
    (searchValue: string) => {
      if (searchValue) {
        setBottomSheetOptions(
          options.filter(option => option.label.includes(searchValue)),
        );
      }
      if (searchValue === "") {
        setBottomSheetOptions(options);
      }
    },
    [options],
  );

  React.useEffect(() => {
    setBottomSheetSelectedData(
      currentValue ? [{ id: currentValue, name: currentValue }] : [],
    );
    setBottomSheetOptions(options);
  }, [currentValue, isBottomSheetOpen, options]);

  return (
    <>
      <StaticSelection
        isMultiple={false}
        state={error ? "error" : "normal"}
        options={options}
        selected={currentValue}
        placeholder={placeholder}
        onChange={() => null}
        size="l"
        reasonText={error}
        onFocus={openBottomSheet}
        openMenuOnFocus={false}
        openMenuOnClick={false}
      />
      <SelectionBottomSheet
        isMultipleSelect={false}
        open={isBottomSheetOpen}
        options={bottomSheetOptions}
        selected={bottomSheetSelectedData}
        setSelected={setBottomSheetSelectedData}
        onClose={handleCloseBottomSheet}
        title={title}
        nextButtonText={intl("button_done")}
        enableSearch
        onSearch={handleBottomSheetSearch}
        onNext={value => {
          onChange(item.id, Array.isArray(value) ? value[0] : currentValue);
          handleCloseBottomSheet();
        }}
        beforeClose={value => {
          onChange(item.id, Array.isArray(value) ? value[0] : currentValue);
        }}
      />
    </>
  );
};
