import * as React from "react";
import { Blurhash } from "react-blurhash";
import { InView } from "react-intersection-observer";

import ShavedTextV2 from "common/components/shavedText/v2";

import { NativeEmojiSafeText } from "common/components/designSystem/texts";

import {
  Wrapper,
  TitleSection,
  TextPreviewSection,
  ImageGrid,
  ImageCell,
} from "./styled";
import Engage from "./engage";
import { useActions } from "app/store";
import { getPermission } from "app/actions/permission";
import { MoimURL } from "common/helpers/url";
import useRedirect from "common/hooks/useRedirect";
import { useRedirectPostShowModal } from "app/modules/postShow/modal/hooks";
import PostAuthor from "app/modules/postShow/components/threadShow/components/postAuthor";
import useUserFetchSelector from "common/hooks/useEntitiesFetchSelectors/useUserFetchSelector";

interface IProps {
  thread: Moim.Forum.IThread;
}

const FeedItem: React.FC<IProps> = ({ thread }) => {
  const redirect = useRedirect();
  const redirectPostShowModal = useRedirectPostShowModal({
    postId: thread.id,
    groupId: thread.groupId,
  });

  useUserFetchSelector([thread.author]);

  const images = React.useMemo(() => {
    const imageBlocks: Moim.Blockit.IImageBlock[] = thread.content.filter(
      blk => blk.type === "image",
    );
    return imageBlocks;
  }, [thread.content]);

  const handleInViewChange = React.useCallback(
    (inView: boolean) => {
      console.log(">>>>>>>inView:", thread.id, inView);
    },
    [thread.id],
  );

  const { dispatchGetPermission } = useActions({
    dispatchGetPermission: getPermission,
  });

  const handleClick: React.MouseEventHandler<HTMLDivElement> = React.useCallback(
    e => {
      if (thread.type.startsWith("product")) {
        e.preventDefault();
        switch (thread.type) {
          case "productReview": {
            redirect(
              new MoimURL.CommerceProductShowReview({
                id: thread.parent_id,
                threadId: thread.id,
              }).toString(),
            );
            break;
          }
          case "productQuestion": {
            redirect(
              new MoimURL.CommerceProductShowQnA({
                id: thread.parent_id,
                threadId: thread.id,
              }).toString(),
            );
            break;
          }
        }
      } else {
        redirectPostShowModal();
      }
    },
    [redirect, thread.id, thread.parent_id, thread.type, redirectPostShowModal],
  );

  React.useEffect(() => {
    dispatchGetPermission({
      resource: thread.id,
    });
  }, []);

  return (
    <Wrapper role="button" onClick={handleClick}>
      <InView triggerOnce={true} onChange={handleInViewChange}></InView>
      <PostAuthor
        showAuthor={true}
        showDate={true}
        showViewCount={false}
        showReadTime={false}
        author={thread.author}
        anonymous={thread.anonymous_data}
        createdAt={thread.created_at}
        viewCount={thread.viewCount}
        readTime={thread.readTime}
      />
      {thread.title ? (
        <TitleSection>
          <ShavedTextV2 line={2}>
            <NativeEmojiSafeText value={thread.title} />
          </ShavedTextV2>
        </TitleSection>
      ) : null}

      {thread.preview?.descriptionPlain ? (
        <TextPreviewSection>
          <ShavedTextV2 line={4}>
            <NativeEmojiSafeText value={thread.preview?.descriptionPlain} />
          </ShavedTextV2>
        </TextPreviewSection>
      ) : null}

      {images.length ? (
        <ImageGrid imageLength={images.length}>
          {images.slice(0, 5).map((imgBlk, idx) => (
            <ImageCell
              key={imgBlk.src}
              className={`i${idx + 1}`}
              style={{ gridArea: `i${idx + 1}` }}
            >
              {imgBlk.blurHash || imgBlk.blur_hash ? (
                <Blurhash
                  className="blurhash"
                  hash={imgBlk.blurHash ?? imgBlk.blur_hash ?? ""}
                  width="100%"
                  height="100%"
                  resolutionX={16}
                  resolutionY={16}
                  punch={1}
                />
              ) : null}

              <img
                alt=""
                src={imgBlk.src}
                srcSet={imgBlk.srcSet}
                sizes="160px"
              />
            </ImageCell>
          ))}
        </ImageGrid>
      ) : null}
      <Engage thread={thread} />
    </Wrapper>
  );
};

export default React.memo(FeedItem);
