import * as React from "react";
import UserProfileImage from "common/components/userProfileImage";
import { useIntlShort } from "common/hooks/useIntlShort";
import { useActions, useStoreState } from "app/store";
import { userSelector } from "app/selectors/user";
import { getBatchUsers } from "app/actions/user";
import { InvitationInfoText, InvitationInfoWrapper } from "./styled";

interface IProps {
  inviterId: string;
}

export const InvitationInfo = ({ inviterId }: IProps) => {
  if (!inviterId) {
    return null;
  }
  const intl = useIntlShort();
  const { inviter } = useStoreState(state => ({
    inviter: userSelector(state, inviterId),
  }));

  const { dispatchGetUsers } = useActions({
    dispatchGetUsers: getBatchUsers,
  });

  React.useEffect(() => {
    if (inviterId) {
      dispatchGetUsers([inviterId]);
    }
  }, []);

  return (
    <InvitationInfoWrapper>
      <UserProfileImage size="s" userId={inviterId} src={inviter?.avatar_url} />
      <InvitationInfoText>
        {intl("custom_system_wording_signup_header_text_invited", {
          user_name: inviter?.name,
        })}
      </InvitationInfoText>
    </InvitationInfoWrapper>
  );
};
