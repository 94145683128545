import * as React from "react";
import shortid from "shortid";
import { swap } from "react-grid-dnd";
import { useSnackbar } from "common/components/alertTemplates/alerts/globalSnackbar/useGlobalSnackbar";
import Component, { IItemMeta } from "./component";
import { HiddenFileInput } from "./styled";

export { IItemMeta };

export interface IRef {
  items: IItemMeta[];
  onOpenFileSelector(): void;
}

interface IProps {
  initialFiles?: FileList | File[];
}

const FileUploadManageContainer = React.forwardRef<IRef, IProps>(
  ({ initialFiles }, ref) => {
    const [items, setItems] = React.useState<IItemMeta[]>([]);
    const refInputFile = React.useRef<HTMLInputElement>(null);
    const { open: openErrorSnackbar } = useSnackbar({ type: "error" });

    const handleClickFileSelector = React.useCallback(() => {
      refInputFile.current?.click();
    }, []);

    const addFileItems = React.useCallback((files: FileList | File[]) => {
      Array.from(files)
        .sort((x, y) =>
          new Intl.Collator(undefined, { numeric: true }).compare(
            x.name,
            y.name,
          ),
        )
        .forEach(file => {
          if (!file.type.includes("image")) {
            return;
          }
          setItems(state => {
            if (state.find(item => item.payload.rawFile.name === file.name)) {
              return state;
            }
            return state.concat({
              id: shortid(),
              payload: {
                rawFile: file,
              },
            });
          });
        });
    }, []);

    const handleDrop: React.DragEventHandler<HTMLDivElement> = React.useCallback(
      e => {
        if (e.dataTransfer.files.length === 0) {
          openErrorSnackbar({
            text: "업로드 할 수 있는 파일이 없습니다.(NK)",
          });
          return;
        }

        if (
          Array.from(e.dataTransfer.files).some(
            file => !file.type.includes("image"),
          )
        ) {
          openErrorSnackbar({
            text: intl => intl("error_only_image_files_allowed"),
          });
        }
        addFileItems(e.dataTransfer.files);
        e.dataTransfer.clearData();
      },
      [addFileItems, openErrorSnackbar],
    );

    const handleDnDChange = React.useCallback(
      (_p1: string, sourceIndex: number, targetIndex: number, _p2: string) => {
        setItems(state => swap(state, sourceIndex, targetIndex));
      },
      [],
    );

    const handleFileInputChange: React.ChangeEventHandler<HTMLInputElement> = React.useCallback(
      e => {
        const files = e.currentTarget.files;
        if (!files) {
          return;
        }

        addFileItems(files);
        e.currentTarget.files = null;
        e.currentTarget.value = "";
      },
      [addFileItems],
    );

    const handleClickDeleteItem = React.useCallback((id: Moim.Id) => {
      setItems(state => state.filter(item => item.id !== id));
    }, []);

    React.useImperativeHandle(ref, () => ({
      onOpenFileSelector: handleClickFileSelector,
      items,
    }));

    React.useEffect(() => {
      if (initialFiles?.length) {
        addFileItems(initialFiles);
      }
    }, [initialFiles]);

    return (
      <>
        <Component
          items={items}
          onDrop={handleDrop}
          onClickAddPreview={handleClickFileSelector}
          onClickDeleteItem={handleClickDeleteItem}
          onDnDChange={handleDnDChange}
        />
        <HiddenFileInput
          ref={refInputFile}
          type="file"
          accept="image/*"
          multiple={true}
          onChange={handleFileInputChange}
        />
      </>
    );
  },
);

export default FileUploadManageContainer;
