import React from "react";
// components
import JoinDialogComponent from "./component";
import DialogStepController, {
  IStepData,
} from "common/components/dialogs/dialogStepController";

import { useActions, useStoreState } from "app/store";
import { ActionCreators as GroupActionCreators } from "app/actions/group";
import { getParentMoimUserData } from "app/actions/user";
import { currentGroupSelector } from "app/selectors/app";

export interface IProps {
  authentication: Moim.IAuthentication | null;
}
export interface IRef {
  onClose(): void;
}

export function getSignUpConfig2Step(
  config: Moim.Group.IGroupSignUpConfig | undefined,
  initialStep?: Moim.Group.JoinGroupDialogStepType,
): IStepData[] {
  const result: IStepData[] = [];

  if (config?.profileImage?.state !== "deactivated") {
    result.push({
      key: "profileImage",
      previewButtonText: "",
      nextButtonText: "",
      default: initialStep === "profileImage",
    });
  }

  if (config?.phone?.state !== "deactivated") {
    result.push({
      key: "phone",
      previewButtonText: "",
      nextButtonText: "",
      default: initialStep === "phone",
    });
  }

  if (config?.signUpForm?.state !== "deactivated") {
    result.push({
      key: "signUpForm",
      previewButtonText: "",
      nextButtonText: "",
      default: initialStep === "signUpForm",
    });
  }

  if (config?.shippingAddress?.state !== "deactivated") {
    result.push({
      key: "shippingAddress",
      previewButtonText: "",
      nextButtonText: "",
      default: initialStep === "shippingAddress",
    });
  }

  if (config?.referralCode?.state !== "deactivated") {
    result.push({
      key: "referralCode",
      previewButtonText: "",
      nextButtonText: "",
      default: initialStep === "referralCode",
    });
  }

  return result;
}
const CurrentJoinDialog = React.forwardRef<IRef, IProps>(
  ({ authentication }, ref) => {
    const {
      currentGroup,
      parentMoimUser,
      isGetParentMoimUserLoading,
      initialStep,
    } = useStoreState(state => ({
      currentGroup: currentGroupSelector(state),
      parentMoimUser: state.app.parentMoimUser,

      isGetParentMoimUserLoading:
        state.joinGroupDialog.isGetParentMoimUserLoading,
      initialStep: state.joinGroupDialog.initialStep,
    }));
    const { dispatchCloseDialog, dispatchGetParentMoimUserData } = useActions({
      dispatchCloseDialog: GroupActionCreators.closeJoinGroupDialog,
      dispatchGetParentMoimUserData: getParentMoimUserData,
    });

    React.useEffect(() => {
      const isChildGroup = !currentGroup?.is_hub;
      if (
        isChildGroup &&
        !isGetParentMoimUserLoading &&
        !parentMoimUser?.id &&
        authentication
      ) {
        dispatchGetParentMoimUserData({
          provider: authentication.provider ?? "cryptobadge",
          token: authentication.token,
        });
      }
    }, []);

    const steps = React.useMemo(
      () => [
        ...(!currentGroup?.is_hub
          ? [
              {
                key: "main",
                previewButtonText: "",
                nextButtonText: "",
                default: initialStep === "main",
              },
            ]
          : []),
        {
          key: "username",
          previewButtonText: "",
          nextButtonText: "",
          default: initialStep === "username",
        },
        ...getSignUpConfig2Step(currentGroup?.sign_up_config_v2, initialStep),
      ],
      [currentGroup, initialStep],
    );

    return (
      <DialogStepController steps={steps}>
        <JoinDialogComponent
          ref={ref}
          authentication={authentication}
          group={currentGroup as Moim.Group.IGroup}
          onClickDoneButton={dispatchCloseDialog}
        />
      </DialogStepController>
    );
  },
);

export default CurrentJoinDialog;
