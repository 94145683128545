// vendor
import DialogBase from "@material-ui/core/Dialog";
import withStyles from "@material-ui/core/styles/withStyles";
// component
// helper
import { px2rem } from "common/helpers/rem";

export const Dialog = withStyles({
  paper: {
    borderRadius: px2rem(8),
  },
})(DialogBase);

export const FixedWidthDialog = withStyles({
  paper: {
    width: px2rem(455),
    borderRadius: `${px2rem(8)} !important`,
  },
})(DialogBase);
