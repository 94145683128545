import { GlobalDuplicatePostDialogTypes } from "app/actions/types";
import { ActionUnion } from "app/actions/helpers";

function createAction<T extends { type: GlobalDuplicatePostDialogTypes }>(
  d: T,
): T {
  return d;
}

export const ActionCreators = {
  open: (payload: { threadId: string }) =>
    createAction({
      type: GlobalDuplicatePostDialogTypes.OPEN,
      payload,
    }),

  close: () =>
    createAction({
      type: GlobalDuplicatePostDialogTypes.CLOSE,
    }),
};

export type Actions = ActionUnion<typeof ActionCreators>;
