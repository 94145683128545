import useIsMobile from "common/hooks/useIsMobile";
import { useParseListElementConfig } from "../../hooks/useParseListElementConfig";

export const useBlockitListLayoutConfig = (
  type: "horizontal" | "vertical",
  listElement: Moim.Blockit.IListStyleElement,
) => {
  const isMobile = useIsMobile();

  const {
    row,
    column,
    scrollable,
    maxVisibleCount,
  } = useParseListElementConfig(listElement);
  const gapSize =
    isMobile || !listElement.itemGutterSize_web
      ? listElement.itemGutterSize
      : listElement.itemGutterSize_web;

  const columnSize = column;

  const rowSize = row;

  const processedScrollable =
    scrollable ?? (type === "horizontal" ? true : false);

  const processedItemStackDirection =
    (isMobile || !listElement.itemStackDirection_web
      ? listElement.itemStackDirection
      : listElement.itemStackDirection_web) ??
    (type === "horizontal" ? "vertical" : "horizontal");

  const maxViewCount = maxVisibleCount;

  return {
    gapSize,
    columnSize,
    rowSize,
    maxViewCount,
    scrollable: processedScrollable,
    itemStackDirection: processedItemStackDirection,
  };
};
