import * as React from "react";
import { FormattedMessage } from "react-intl";
import {
  Title,
  HeaderSection,
  ModalShowHeader,
  RightWrapper,
  CloseButton,
  PinIcon,
  PinnedPostWrapper,
  PinnedPostText,
} from "./styled";
import { Spacer } from "common/components/designSystem/spacer";
import { NativeEmojiSafeText } from "common/components/designSystem/texts";
import { MaxWidthWrapper } from "app/modules/postShow/styled";
import ChannelName from "../components/channelName";

import { PostShowContext } from "../../../context";
import { useStoreState } from "app/store";
import UnlockContentMethods from "common/components/thread/templates/post/components/unlockContentMethods";

const DesktopHeader = () => {
  const { isModalShow, post, onBack } = React.useContext(PostShowContext);

  const { pinned } = useStoreState(state => ({
    pinned: state.forumData.pinnedPostList[post.parent_id]?.includes(post.id),
  }));

  const showUnlockMethods = Boolean(
    post.locked &&
      post.unlockMethods?.find(
        method => method?.resourceType === "transferCoin" && method.quantity,
      ),
  );

  return (
    <>
      {isModalShow && (
        <ModalShowHeader>
          <RightWrapper>
            <CloseButton onClick={onBack} />
          </RightWrapper>
        </ModalShowHeader>
      )}
      {!isModalShow && <Spacer value={40} />}
      <MaxWidthWrapper>
        <HeaderSection>
          <ChannelName />
          <Spacer value={pinned || showUnlockMethods ? 34 : 8} />
          {pinned && (
            <>
              <PinnedPostWrapper>
                <PinIcon />
                <PinnedPostText>
                  <FormattedMessage id="post_show/pinned_post" />
                </PinnedPostText>
              </PinnedPostWrapper>
              {showUnlockMethods && <Spacer value={14} />}
            </>
          )}
          {showUnlockMethods && (
            <UnlockContentMethods
              textAlign="LEFT"
              unlockMethods={post.unlockMethods}
              isUnlocked={Boolean(post.unlocked_at)}
            />
          )}
          <Spacer value={8} />
          <Title>
            <NativeEmojiSafeText value={post.title} />
          </Title>
        </HeaderSection>
      </MaxWidthWrapper>
    </>
  );
};

export default DesktopHeader;
