import * as React from "react";
import uuid from "uuid";
// hooks
import { useActions, useStoreState } from "app/store";
import { useCancelTokenWithCancelHandler } from "common/hooks/useCancelToken";

import useGroupTexts from "common/hooks/useGroupTexts";
// actions
import { bufferedGetChildMoimGroupMoims as getChildMoimGroupMoimsAction } from "app/actions/childMoimGroup";
// helpers
// component

import {
  PortalRightArrow,
  PortalLeftArrow,
} from "common/components/horizontalScroll/arrows";
import BlockitListLayout from "common/components/blockitListLayout";
import { MoimCellSkeleton } from "./components/moimCell/skeleton";
import MoimItem from "./components/moimCell";
import { withPlacement } from "../../hoc/withPlacement";
import { selectMoimsById } from "app/selectors/moim";
import { ListWrapper, ItemContainer } from "./styled";
import { ArrowContainer } from "common/components/blockitEditorBase/styled";
import { useParseListElementConfig } from "common/components/blockitListLayout/hooks/useParseListElementConfig";
import InViewTrigger from "../../components/inViewTrigger";
import { BlockitFeedback } from "../../components/feedback";
import { Spacer } from "common/components/designSystem/spacer";
import MoreButton from "../../components/moreButton";
import { useIntlShort } from "common/hooks/useIntlShort";
import useRedirect from "common/hooks/useRedirect";
import { MoimURL } from "common/helpers/url";

const DEFAULT_LIMIT = 20;

const ChildMoimGroupPreview: React.FC<Moim.Blockit.V2.IChildMoimGroupListPreviewBlock> = ({
  listElement,
  resourceId,
  moreButton,
}) => {
  const intl = useIntlShort();
  const redirect = useRedirect();
  const { cancelTokenSource, handleCancel } = useCancelTokenWithCancelHandler();
  const [isLoading, setLoadStatus] = React.useState(false);

  const portalSectionId = React.useMemo(
    () => `child-moim-group-preview-portal-${uuid.v4()}`,
    [],
  );

  const { getChildMoimGroupMoims } = useActions({
    getChildMoimGroupMoims: getChildMoimGroupMoimsAction,
  });

  const childMoimText = useGroupTexts("child_moim");

  const moims = useStoreState(state =>
    state.childMoimGroup.groupByMoims[resourceId]?.data
      ? selectMoimsById(
          state,
          state.childMoimGroup.groupByMoims[resourceId].data,
        )
      : undefined,
  );

  const { listElementType, maxVisibleCount } = useParseListElementConfig(
    listElement,
  );

  const itemElements = React.useMemo(() => {
    const isUndefinedArray = moims?.some(moim => moim?.id === undefined);
    if (moims === undefined || isLoading || isUndefinedArray) {
      return new Array(maxVisibleCount).fill(0).map((_, idx) => (
        <ItemContainer key={`child_moim_group_moim_skeleton_${idx}`}>
          <MoimCellSkeleton />
        </ItemContainer>
      ));
    }

    return moims.map(moim => (
      <ItemContainer key={`child_moim_group_${moim.id}`}>
        <MoimItem moim={moim} />
      </ItemContainer>
    ));
  }, [moims, isLoading, maxVisibleCount]);

  const handleOnView = React.useCallback(() => {
    if (!isLoading && !moims?.length) {
      setLoadStatus(true);
      getChildMoimGroupMoims(
        resourceId,
        maxVisibleCount ?? DEFAULT_LIMIT,
        cancelTokenSource.current.token,
      ).finally(() => {
        setLoadStatus(false);
      });
    }
  }, [
    isLoading,
    moims?.length,
    resourceId,
    getChildMoimGroupMoims,
    maxVisibleCount,
    cancelTokenSource,
  ]);

  const handleClickViewMore = React.useCallback(() => {
    redirect(new MoimURL.ChildMoimGroupMoims({ id: resourceId }).toString());
  }, [redirect, resourceId]);

  React.useLayoutEffect(
    () => () => {
      handleCancel();
    },
    [handleCancel],
  );

  return (
    <>
      <InViewTrigger onVisible={handleOnView} />

      {listElementType === "horizontal" && (
        <ArrowContainer id={portalSectionId} />
      )}
      <ListWrapper>
        {moims !== undefined && !moims.length ? (
          <BlockitFeedback.Empty
            textKey={"child_moim_group_list_preview_empty"}
            textKeyValues={{
              child_moim: childMoimText?.singular,
              child_moim_plural: childMoimText?.plural,
            }}
          />
        ) : (
          <BlockitListLayout
            element={listElement}
            rightArrow={
              listElementType === "horizontal" ? PortalRightArrow : undefined
            }
            leftArrow={
              listElementType === "horizontal" ? PortalLeftArrow : undefined
            }
            arrowPortalTargetId={portalSectionId}
          >
            {itemElements}
          </BlockitListLayout>
        )}
      </ListWrapper>

      {moreButton?.show ? (
        <>
          <Spacer value={24} />
          <MoreButton
            text={intl("see_more_child_moims")}
            onClick={handleClickViewMore}
          />
        </>
      ) : null}
    </>
  );
};

export default withPlacement(React.memo(ChildMoimGroupPreview));
