import * as React from "react";
import { DEFAULT_CURRENCY } from "common/constants/default";
// styled
import { Wrapper } from "./styled";
import {
  useCurrentCurrencyRule,
  useHubSellerCurrency,
} from "common/hooks/commerce/useHubSeller";
import { formatClassifiedCurrency } from "./helper";

interface IProps {
  prefix?: string;
  currency?: string;
  value?: number | string;
  className?: string;
  children?: (params: {
    currency: string;
    value: string | number;
    symbol: string;
  }) => React.ReactNode;
}

const CurrencyFormatter: React.FC<IProps> = ({
  prefix,
  currency: currencyProps,
  value = 0,
  className,
  children,
}) => {
  const defaultCurrency = useHubSellerCurrency();
  const currency = currencyProps ?? defaultCurrency ?? DEFAULT_CURRENCY;
  const upperCaseCurrency = currency.toUpperCase();
  const currencyRule = useCurrentCurrencyRule(upperCaseCurrency);

  if (isNaN(Number(value))) {
    return <>{value}</>;
  }
  const numValue = Number(value);

  const { formattedValue, groupedValue, symbol } = formatClassifiedCurrency(
    currencyRule,
    numValue,
    currency,
  );

  return (
    <Wrapper className={className}>
      {Boolean(prefix) && prefix}
      {children
        ? children({
            value: groupedValue,
            currency: upperCaseCurrency,
            symbol,
          })
        : formattedValue}
    </Wrapper>
  );
};

export default CurrencyFormatter;
