import * as React from "react";
import { MoimURL } from "common/helpers/url";
import useRedirect from "common/hooks/useRedirect";
import PositionChip from "common/components/chips/preset/positionChip";
import {
  POSITION_WIDTH_LIMIT,
  PositionContainer,
  PositionCell,
} from "./styled";

interface IProps {
  positions: Moim.Position.INormalizePosition[];
}

const Positions: React.FC<IProps> = ({ positions }) => {
  const redirect = useRedirect();
  const handleClickPosition: React.MouseEventHandler<HTMLSpanElement> = React.useCallback(
    e => {
      const positionId = e.currentTarget.dataset.positionid;
      if (positionId) {
        redirect(
          new MoimURL.PositionMembers({
            positionId,
          }).toString(),
        );
      }
    },
    [redirect],
  );

  return (
    <PositionContainer>
      {positions
        .filter(i => Boolean(i))
        .map(position => (
          <PositionCell key={position.id}>
            <PositionChip
              id={position.id}
              name={position.name}
              size="medium"
              color={position.color}
              maxContentWidth={POSITION_WIDTH_LIMIT}
              onClick={handleClickPosition}
            />
          </PositionCell>
        ))}
    </PositionContainer>
  );
};

export default React.memo(Positions);
