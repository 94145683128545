import * as React from "react";
import styled, { css } from "styled-components";
import { px2rem } from "common/helpers/rem";
import { B2RegularStyle, B3RegularStyle } from "../designSystem/typos";
import { MEDIA_QUERY } from "common/constants/responsive";
import { noScrollBarStyle } from "../designSystem/styles";

export type TabType = "root" | "sub";
export type TabAlignType = "left" | "fit";

export const TabItem = styled.div.attrs({ role: "button" })<{
  type?: TabType;
  active?: boolean;
  tabItemScrollable?: boolean;
  tabItemAlignType?: TabAlignType;
}>`
  position: relative;
  padding: ${px2rem(10)} 0;

  flex: 1;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  color: ${props =>
    props.active
      ? props.theme.colorV2.colorSet.grey800
      : props.theme.colorV2.colorSet.grey600};
  font-weight: ${props =>
    props.active ? props.theme.font.bold : props.theme.font.regular};
  transition: background-color 300ms;

  a,
  span,
  p {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  span.ignoreActiveStyle {
    position: relative;
    width: auto;
    margin-left: ${px2rem(9)};
    color: ${props => props.theme.colorV2.colorSet.grey600};
    font-weight: ${props => props.theme.font.regular};
  }

  &:active {
    background-color: ${props => props.theme.colorV2.colorSet.grey10};
  }

  &::after {
    display: block;
    content: "";
    position: absolute;
    background-color: ${props => props.theme.colorV2.primary.main};
    width: 30%;
    height: ${px2rem(1)};
    left: 35%;
    bottom: -1px;
    transition: all 300ms;
    opacity: ${props => (props.active ? 1 : 0)};
    transform: scale(${props => (props.active ? 1 : 0)});

    @media ${MEDIA_QUERY.ONLY_MOBILE} {
      ${props =>
        props.tabItemScrollable &&
        props.tabItemAlignType === "left" &&
        `
        margin-left: ${px2rem(12)};
        margin-right: ${px2rem(12)};
        width: calc(100% - ${px2rem(24)});
        bottom: 0px;
        left: 0;
      `}
    }
  }

  @media ${MEDIA_QUERY.ONLY_MOBILE} {
    ${props =>
      props.tabItemScrollable &&
      `
      min-width: 22vw;
    `}

    ${props =>
      props.tabItemScrollable &&
      props.tabItemAlignType === "left" &&
      `
      flex: 0 0 auto;
      min-width: auto;

      & > span {
        position: relative;
        margin-left: ${px2rem(12)};
        margin-right: ${px2rem(12)};
      }
    `}
  }
`;

export const Tab = styled.nav<{
  type?: TabType;
  tabItemScrollable?: boolean;
  tabItemAlignType?: TabAlignType;
}>`
  display: flex;
  margin-top: ${px2rem(10)};
  height: ${px2rem(42)};
  border-bottom: ${px2rem(1)} solid
    ${props => props.theme.colorV2.colorSet.grey50};
  ${props => (props.type === "root" ? B2RegularStyle : B3RegularStyle)}

  @media ${MEDIA_QUERY.ONLY_MOBILE} {
    ${props =>
      props.tabItemScrollable &&
      `
      overflow-x: scroll;
      overflow-y: hidden;
    `}

    ${props =>
      props.tabItemScrollable &&
      props.tabItemAlignType === "left" &&
      `
    `}
  }

  ${noScrollBarStyle};

  ${props =>
    props.type === "sub" &&
    css`
      margin-top: 0;

      ${TabItem}::after {
        display: none;
      }
      ${TabItem} + ${TabItem} {
        ::before {
          display: block;
          content: "";
          position: absolute;
          background-color: ${props.theme.colorV2.colorSet.grey50};
          width: 1px;
          height: 100%;
          left: 0;
          top: ${px2rem(-4)};
          bottom: ${px2rem(-4)};
        }
      }
  `}
`;

type TabItemComponentPropsTypes<T> = React.PropsWithChildren<{
  type: T;
  active: boolean;
  tabItemScrollable?: boolean;
  tabItemAlignType?: TabAlignType;
  onClick(type: T): void;
}>;

export function TabItemComponent<T>({
  children,
  type,
  active,
  tabItemScrollable,
  tabItemAlignType,
  onClick,
}: TabItemComponentPropsTypes<T>) {
  const handleClick = React.useCallback(() => {
    onClick(type);
  }, [onClick, type]);

  return (
    <TabItem
      key={`${type}`}
      onClick={handleClick}
      active={active}
      tabItemScrollable={tabItemScrollable}
      tabItemAlignType={tabItemAlignType}
    >
      {children}
    </TabItem>
  );
}
