import { Svg18InfoG } from "@moim/icons";
import * as React from "react";
import styled from "styled-components";

import { B3Regular } from "common/components/designSystem/typos";
import { px2rem } from "common/helpers/rem";

export const InfoIcon = styled(Svg18InfoG).attrs(props => ({
  size: "xs",
  touch: 18,
  iconColor: props.theme.colorV2.colorSet.grey300,
}))``;

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  gap: ${px2rem(8)};
`;

const InfoIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${px2rem(18)};
  height: ${px2rem(18)};
`;

const Message = styled(B3Regular)`
  width: 100%;
  flex: 1;
  min-width: 0;
  color: ${props => props.theme.colorV2.colorSet.grey300};
  display: block;
  white-space: pre-line;
`;

interface IProps {
  message: React.ReactNode;
}

const GuideHint: React.FC<IProps> = ({ message }) => {
  return (
    <Wrapper>
      <InfoIconWrapper>
        <InfoIcon />
      </InfoIconWrapper>
      <Message>{message}</Message>
    </Wrapper>
  );
};

export default GuideHint;
