import { isProd, isDev, isTest, isStage } from "common/helpers/envChecker";
import {
  STAGE_HOST,
  PRODUCTION_HOST,
  STAGE_ANALYTICS_HOST,
  PRODUCTION_ANALYTICS_HOST,
  DEV_HOST,
} from "common/constants/hosts";
import { getDomain, getSubdomain } from "./hostnameParser";

export function getHost() {
  if (isProd()) {
    return PRODUCTION_HOST;
  }

  if (isDev()) {
    return DEV_HOST;
  }

  return STAGE_HOST;
}

export function getCommerceAPIDomain() {
  const domain = getDomain(location.hostname);

  if (domain !== getHost()) {
    return `https://${location.hostname}/payment-api`;
  }

  if (isProd()) {
    return `https://payment.moim.co`;
  }

  return `https://payment.vingle.network`;
}

export function getCommunityServiceAPIDomain() {
  if (isProd()) {
    return "https://service.canlab.co";
  }
  return "https://community-service.moim.mobi";
}

export function getCoinbaseAPIDomain() {
  return "https://api.coinbase.com";
}

export function getStagingDomainFromDevelopment() {
  // TODO: isStage() for temporary case. before new api end-point release.
  if (isDev() || isTest()) {
    return `https://${STAGE_HOST}/api`;
  } else {
    throw new Error(
      `This function(getStagingDomainFromDevelopment) can use only development moim mode`,
    );
  }
}

export function getApiDomain() {
  return isProd() || isStage()
    ? `https://${location.hostname}/api`
    : getStagingDomainFromDevelopment();
}

export function getOriginDomain() {
  return isDev() || isTest() || isStage()
    ? [getSubdomain(), STAGE_HOST].filter(text => Boolean(text)).join(".")
    : location.hostname;
}

export function getAnalyticsApiDomain() {
  return isProd()
    ? `https://${PRODUCTION_ANALYTICS_HOST}/cubejs-api/v1`
    : `https://${STAGE_ANALYTICS_HOST}/cubejs-api/v1`;
}

export function getCanPassAPIDomain() {
  if (isProd()) {
    return `https://canpass.me`;
  }
  return `https://test.canpass.me`;
}

export function getCryptoBadgeAPIDomain() {
  if (isProd()) {
    return `https://api.cryptobadge.xyz/api`;
  }
  return `https://dev.api.cryptobadge.xyz/m1/api`;
}

export function getWebsocketHost() {
  if (isTest()) {
    return null;
  }

  if (!isProd()) {
    return "wss://kcqqh3ap62.execute-api.ap-northeast-2.amazonaws.com/stage";
  }

  return "wss://2al0tq2b6k.execute-api.ap-northeast-2.amazonaws.com/prod";
}
