import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: inline-flex;
  min-width: 0;
  flex: 1;
`;

export const Inner = styled.div`
  display: inline-block;
  width: 100%;
  min-width: 0;
  min-height: 0;
  flex: 1;
  height: 100%;
`;

export const ChannelList = styled.div`
  display: inline-block;
  white-space: nowrap;
  height: 100%;
`;
