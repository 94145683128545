import * as React from "react";

import { HorizontalTagList } from "common/components/horizontalTagList.v2";
import styled from "styled-components";
import useTagGroupFetchSelector from "common/hooks/useEntitiesFetchSelectors/useTagGroupFetchSelector";

const StyledHorizontalTagList = styled(HorizontalTagList)`
  padding-left: 0;
  padding-right: 0;
`;

export const ContentsGroupPreviewFilter: React.FC<{
  tagGroupIds: Moim.Id[];
  onChangeSelectedTags(selectedTags: string[][]): void;
}> = ({ tagGroupIds, onChangeSelectedTags }) => {
  const { tagGroups } = useTagGroupFetchSelector(tagGroupIds);
  const [selectedTags, setSelectedTags] = React.useState<
    Record<string, string[]>
  >({});

  return (
    <StyledHorizontalTagList
      tagGroups={tagGroups}
      onChangeHandler={_selectedTags => {
        onChangeSelectedTags(Object.values(_selectedTags));
        setSelectedTags(_selectedTags);
      }}
      selectedTags={selectedTags}
    />
  );
};
