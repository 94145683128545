export function getSrcToUse(props: Moim.Blockit.ImageProps, width: number) {
  if (width < 160 && props.srcXxs) {
    return props.srcXxs;
  }

  if (width < 320 && props.srcXs) {
    return props.srcXs;
  }

  if (width < 780 && props.srcSm) {
    return props.srcSm;
  }

  if (width < 1120 && props.srcMd) {
    return props.srcMd;
  }

  if (width < 1600 && props.srcLg) {
    return props.srcLg;
  }

  if (width < 1920 && props.srcXl) {
    return props.srcXl;
  }

  return props.src;
}

export function getSrcUrlToUse(props: Moim.Forum.IThumbnail, width: number) {
  if (width < 160 && props.url_xxs) {
    return props.url_xxs;
  }

  if (width < 320 && props.url_xs) {
    return props.url_xs;
  }

  if (width < 780 && props.url_sm) {
    return props.url_sm;
  }

  if (width < 1120 && props.url_md) {
    return props.url_md;
  }

  if (width < 1600 && props.url_lg) {
    return props.url_lg;
  }

  if (width < 1920 && props.url_xl) {
    return props.url_xl;
  }

  return props.url;
}

export function getEstimatedWidth(
  listElement: Moim.Blockit.V2.IListStyleElement,
  padding?: Moim.Blockit.V2.IBlockitPadding,
  currentWidth?: number,
  web?: boolean,
) {
  const width = currentWidth ?? window?.innerWidth ?? 780;

  const paddingLeft = padding?.left ?? 0;
  const paddingRight = padding?.right ?? 0;

  const gutterSize = listElement.itemGutterSize ?? 0;
  const columnCount = web
    ? listElement.columnCount_web
    : listElement.columnCount;

  return (
    (width - paddingLeft - paddingRight - (gutterSize * columnCount - 1)) /
    columnCount
  );
}
