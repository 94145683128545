import * as React from "react";
import { useResizeDetector } from "react-resize-detector";
import {
  getShowAuthorTop,
  getShowAuthorBottom,
} from "../../utils/migrateVariable";
// components
import BlockitEditor from "common/components/blockitEditorBase";
import TagItemList from "app/modules/forum/components/editor/components/tagItemList";
import { Spacer } from "common/components/designSystem/spacer";
import { Wrapper, ContentWrapper, StickyWrapper } from "./styledComponent";
import PostAuthor from "./components/postAuthor";
import Engage from "./components/engage";

import { PostShowContext } from "../../context";
import { tagSetBlockExtractor } from "app/modules/forum/containers/forumEditor/helper";
import { POST_SHOW_MAX_CONTENT_WIDTH } from "../../constants";
import BottomPostAuthor from "./components/bottomPostAuthor";
import PostShowPermissionChecker from "../readPermissionChecker";
import BlockitRenderer from "common/components/blockitEditorBase/components/blockitRenderer";
import { isServer } from "common/helpers/envChecker";
import { ThreadContentSkeleton } from "app/modules/forum/containers/forumShow/skeleton";

interface IProps {
  followerCount: number;
  onClickTagItem?: (tagItem: Moim.TagSet.ITagItem) => void;
}

const PostShowComponent = ({ followerCount, onClickTagItem }: IProps) => {
  const { post, showConfig } = React.useContext(PostShowContext);
  const refWrapper = React.useRef<HTMLDivElement>(null);
  const [
    isUnderMaxContentWidth,
    setUnderMaxContentWidthStatus,
  ] = React.useState(false);

  const { contentsWithoutTagSet, selectedTags } = React.useMemo(
    () => tagSetBlockExtractor(post?.content ?? []),
    [post?.content],
  );

  const handleContentWrapperResize = React.useCallback((width: number) => {
    setUnderMaxContentWidthStatus(width <= POST_SHOW_MAX_CONTENT_WIDTH);
  }, []);

  const showAuthorTop = getShowAuthorTop(showConfig);
  const showAuthorBottom = React.useMemo(
    () => getShowAuthorBottom(showConfig),
    [showConfig],
  );

  useResizeDetector({
    handleWidth: true,
    onResize: handleContentWrapperResize,
    targetRef: refWrapper,
  });

  return (
    <Wrapper ref={refWrapper}>
      <PostAuthor
        showAuthor={showAuthorTop}
        showDate={showConfig.show_date}
        showViewCount={showConfig.show_view_count}
        showReadTime={showConfig.show_read_time}
        author={post?.author}
        anonymous={post?.anonymous_data}
        createdAt={post?.created_at}
        followerCount={followerCount}
        viewCount={post?.viewCount}
        readTime={post?.readTime}
      />
      <PostShowPermissionChecker>
        <ContentWrapper>
          {isServer() ? (
            contentsWithoutTagSet.map((blk, idx) => (
              <BlockitRenderer
                key={`${post?.id}_show_blk_${blk.type}_${idx}`}
                block={blk}
              />
            ))
          ) : (
            <BlockitEditor
              id={post?.id}
              readonly={true}
              contents={contentsWithoutTagSet}
              forceFullWidthFiles={isUnderMaxContentWidth}
              renderSkeleton={<ThreadContentSkeleton />}
            />
          )}

          <TagItemList
            selectedTagIds={selectedTags}
            onClickTagItem={onClickTagItem}
          />
        </ContentWrapper>
      </PostShowPermissionChecker>
      {showAuthorBottom.active && post ? (
        <BottomPostAuthor type={showAuthorBottom.type} userId={post.author} />
      ) : (
        <Spacer value={40} />
      )}
      <StickyWrapper>
        <Engage />
      </StickyWrapper>
    </Wrapper>
  );
};

export default React.memo(PostShowComponent);
