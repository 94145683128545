/* eslint-disable no-underscore-dangle */
import { ISSRLoadDataPayload } from "app/typings/ssr";
import { setInitialData } from "common/helpers/initialData";
import _ from "lodash";

export async function NFTCollectionLoadData({
  match,
  api,
  requestId,
}: ISSRLoadDataPayload) {
  const contractId = match.params.contractId;

  if (contractId) {
    const contract = await api.nft.getContractDetail(contractId);
    setInitialData("__entities.nft.contract", requestId, contract);
  }
}
