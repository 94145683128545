import React from "react";
import useRedirect from "common/hooks/useRedirect";
import { MoimURL } from "common/helpers/url";
import {
  ButtonWrapper,
  ExternalButton,
  ExternalLinkIcon,
  ExternalLinkText,
} from "./style";

interface IProps {
  buttonText?: string;
  termId?: string;
  wrapperClass?: string;
}

const DeliveryPolicyGuideButton = ({
  termId,
  buttonText,
  wrapperClass,
}: IProps) => {
  const redirect = useRedirect();

  return (
    <>
      {buttonText && termId && (
        <ButtonWrapper className={wrapperClass}>
          <ExternalButton
            onClick={() => {
              redirect(new MoimURL.About({ section: termId }).toString());
            }}
          >
            <ExternalLinkText>{buttonText}</ExternalLinkText>
            <ExternalLinkIcon />
          </ExternalButton>
        </ButtonWrapper>
      )}
    </>
  );
};

export default DeliveryPolicyGuideButton;
