import * as React from "react";
import styled, { css } from "styled-components";
import { useInView } from "react-intersection-observer";
import { px2rem } from "common/helpers/rem";
import RawHlsVideo from "common/components/hlsVideo";
import { B4Regular } from "common/components/designSystem/typos";
import { MEDIA_MAX_HEIGHT } from "common/components/designSystem/media/preview/styled";
import { resizeForFrame } from "common/components/designSystem/media/preview";

interface IWrapperProps {
  type?: "message" | "comment";
  reverse?: boolean;
}

const getReverseStyle = (reverse?: boolean) =>
  css`
    justify-content: ${reverse ? "flex-end" : "flex-start"};
  `;

const Wrapper = styled.div<IWrapperProps>`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  ${props => getReverseStyle(props.reverse)};

  padding: ${px2rem(4)} ${px2rem(16)} ${px2rem(3)} 0;
  ${props => {
    if (props.type === "message") {
      return css`
        max-width: ${px2rem(320)};
      `;
    }
  }}
`;

const FileTitle = styled(B4Regular)`
  margin-bottom: ${px2rem(4)};
  color: ${props => props.theme.colorV2.colorSet.grey300};
`;

const VideoContainer = styled.div<{
  initialSize: {
    width: number;
    height: number;
  };
}>`
  min-width: ${px2rem(76)};
  min-height: ${px2rem(46)};
  ${props =>
    props.initialSize &&
    css`
      width: ${px2rem(props.initialSize.width)};
    `};

  ${props =>
    props.initialSize &&
    props.initialSize.height > MEDIA_MAX_HEIGHT &&
    css`
      width: ${px2rem(
        (props.initialSize.width / props.initialSize.height) * MEDIA_MAX_HEIGHT,
      )};
    `};
`;

export default function Video({
  type = "comment",
  reverse,
  ref: _,
  fileName,
  ...props
}: React.ComponentProps<typeof RawHlsVideo> &
  IWrapperProps & { fileName?: string }) {
  const [adjustSize, setAdjustSize] = React.useState({
    width: props.width ?? 200,
    height: props.height ?? 200,
  });
  const { ref } = useInView({
    onChange: (_v, entry) => {
      const frameWidth = entry.intersectionRect.width;
      const frameHeight = entry.intersectionRect.height;
      setAdjustSize(
        resizeForFrame(
          frameWidth,
          props.width ?? frameWidth,
          props.height ?? frameHeight,
        ),
      );
    },
  });

  return (
    <Wrapper ref={ref} type={type} reverse={reverse}>
      {fileName ? <FileTitle>{fileName}</FileTitle> : null}
      <VideoContainer initialSize={adjustSize}>
        <RawHlsVideo
          {...props}
          disableSkeleton={true}
          isAudio={true}
          controls={true}
        />
      </VideoContainer>
    </Wrapper>
  );
}
