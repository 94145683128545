import * as React from "react";
import { Wrapper, IframeWrapper, LoaderContainer } from "./styled";
import { SkeletonBox } from "common/components/skeleton";

const EmbeddedVideoComponent: React.FC<{
  embedUrl?: string;
  provider?: string;
  width?: number;
  height?: number;
}> = ({ embedUrl, width, height }) => {
  const [isLoading, setIsLoading] = React.useState(true);

  const handleLoad = React.useCallback(() => {
    setIsLoading(false);
  }, []);

  return (
    <Wrapper width={width} height={height}>
      <IframeWrapper>
        <iframe
          title={`Embed block ${embedUrl}`}
          itemProp="image"
          src={embedUrl?.replace(/(https:|http:)/, "")}
          style={{ maxWidth: "100%" }}
          onLoad={handleLoad}
          allowFullScreen={true}
        />
      </IframeWrapper>
      <LoaderContainer className="loader" isDisplay={isLoading}>
        <SkeletonBox width="100%" height="100%" />
      </LoaderContainer>
    </Wrapper>
  );
};

export default EmbeddedVideoComponent;
