import { AppDispatch, ThunkResult } from "app/store";
import { IAppState } from "app/rootReducer";

import { getWebsocketHost } from "common/helpers/domainMaker";
import { getMoimTokenToCookie } from "common/helpers/authentication";
import { dataHandler as newMessageHandler } from "./newMessage";
import { dataHandler as updatedEntityHandler } from "./entity";
import { dataHandler as statHandler } from "./stat";
import { dataHandler as notificationDataHandler } from "./mention";

let socket: WebSocket | null;

const handleMessage = (
  getState: () => IAppState,
  dispatch: AppDispatch,
  payload:
    | Moim.WebsocketEvent.EntityUpdated
    | Moim.WebsocketEvent.NewMessage
    | Moim.WebsocketEvent.Stat
    | Moim.WebsocketEvent.Notification,
) => {
  switch (payload.type) {
    case "notification":
      notificationDataHandler(dispatch, payload);
      break;
    case "newMessage":
      newMessageHandler(dispatch, getState, payload);
      break;
    case "entityUpdated":
      updatedEntityHandler(dispatch, getState, payload);
      break;
    case "stat":
      statHandler(dispatch, getState, payload);
      break;
    default:
      break;
  }
};

function subscribeWebsocketBackOffice(
  getState: () => IAppState,
  dispatch: AppDispatch,
) {
  setTimeout(() => {
    subscribeWebsocket(getState, dispatch);
  }, getRandomNumber());
}

function getRandomNumber(): number {
  return Math.floor(Math.random() * (6000 - 3000 + 1)) + 3000;
}

const subscribeWebsocket = (
  getState: () => IAppState,
  dispatch: AppDispatch,
) => {
  const websocketHost = getWebsocketHost();

  if (!websocketHost) {
    return;
  }

  if (!socket) {
    const parentGroupId = getState().app.currentHubGroupId;

    if (!parentGroupId) {
      return;
    }

    const accessToken = getMoimTokenToCookie(parentGroupId);

    if (!accessToken) {
      return;
    }

    socket = new WebSocket(
      `${websocketHost}?token=${accessToken?.access_token}`,
    );

    if (socket) {
      socket.onclose = () => {
        socket = null;
        subscribeWebsocketBackOffice(getState, dispatch);
      };

      socket.onerror = () => {
        socket!.close();
        socket = null;
        subscribeWebsocketBackOffice(getState, dispatch);
      };

      socket.onmessage = e => {
        const payload = JSON.parse(e.data);
        handleMessage(getState, dispatch, payload);
      };
    }
  }
};

export function connectWebsocket(): ThunkResult {
  return async (dispatch, getState) => {
    subscribeWebsocket(getState, dispatch);
  };
}
