import React from "react";
import styled from "styled-components";

import { B4Regular } from "common/components/designSystem/typos";
import CurrencyFormatter from "common/components/currencyFormatter";

import {
  useCurrentHubSellerId,
  useHubSellerCurrency,
} from "common/hooks/commerce/useHubSeller";
import { useIntlShort } from "common/hooks/useIntlShort";

import { px2rem } from "common/helpers/rem";
import { useStoreState } from "app/store";

const Wrapper = styled(B4Regular)`
  color: ${props => props.theme.color.darkBlue};
  padding: ${px2rem(2)} ${px2rem(16)};
`;

const ReviewReward: React.FC<{ className?: string }> = ({ className }) => {
  const intl = useIntlShort();
  const hubSellerId = useCurrentHubSellerId();
  const currency = useHubSellerCurrency();
  const { onWriteProductReview } = useStoreState(state => ({
    onWriteProductReview:
      state.entities.commerce_seller[hubSellerId ?? ""]?.creditCase
        ?.onWriteProductReview,
  }));

  const inner = React.useMemo(() => {
    switch (onWriteProductReview?.policy) {
      case "normal":
        return intl("purchase_confirm_write_review_reward_info_same", {
          credits: (
            <CurrencyFormatter
              currency={currency}
              value={onWriteProductReview.basic}
            />
          ),
        });
      case "textOrMedia":
        return intl("purchase_confirm_write_review_reward_info_media", {
          credits1: (
            <CurrencyFormatter
              currency={currency}
              value={onWriteProductReview.text}
            />
          ),
          credits2: (
            <CurrencyFormatter
              currency={currency}
              value={onWriteProductReview.media}
            />
          ),
        });
      case "textOrImageOrVideo":
        return intl("purchase_confirm_write_review_reward_info_image_video", {
          credits1: (
            <CurrencyFormatter
              currency={currency}
              value={onWriteProductReview.text}
            />
          ),
          credits2: (
            <CurrencyFormatter
              currency={currency}
              value={onWriteProductReview.image}
            />
          ),
          credits3: (
            <CurrencyFormatter
              currency={currency}
              value={onWriteProductReview.video}
            />
          ),
        });
      default:
        return null;
    }
  }, [onWriteProductReview, currency]);

  if (!inner) {
    return null;
  }
  return <Wrapper className={className}>{inner}</Wrapper>;
};

export default React.memo(ReviewReward);
