import * as React from "react";

import uuid from "uuid";
import { useStoreState, arrayEqual } from "app/store";
import { selectCampaignProject } from "app/selectors/campaign";
// hooks
import { useParseItemLayout } from "../hooks";
// components
import { BlockitFeedback } from "../../../../components/feedback";
import ProductItemCell from "common/components/productItemCell";
import ProductItemCellSkeleton from "common/components/productItemCell/skeleton";
import BlockitListLayout from "common/components/blockitListLayout";
import {
  PortalRightArrow,
  PortalLeftArrow,
} from "common/components/horizontalScroll/arrows";
import { ArrowContainer } from "common/components/blockitEditorBase/styled";

import { useParseListElementConfig } from "common/components/blockitListLayout/hooks/useParseListElementConfig";

const CampaignPreview: React.FC<Moim.Blockit.V2.Commerce.IProductListPreviewBlock> = ({
  resourceId,
  itemLayout,
  itemLayout_web,
  listElement,
}) => {
  const productItemLayout = useParseItemLayout(
    "listPreview",
    itemLayout,
    itemLayout_web,
  );

  const { listElementType, maxVisibleCount } = useParseListElementConfig(
    listElement,
  );

  const portalSectionId = React.useMemo(
    () => `campaign-portal-${uuid.v4()}`,
    [],
  );

  const products = useStoreState(
    state =>
      resourceId
        ? selectCampaignProject(state, resourceId)?.products ?? []
        : [],
    arrayEqual,
  );

  const splicedProducts = React.useMemo(
    () => products.slice(0, maxVisibleCount),
    [maxVisibleCount, products],
  );

  const itemElements = React.useMemo(() => {
    const isUndefinedArray = splicedProducts.some(pd => pd?.id === undefined);
    if (isUndefinedArray) {
      return new Array(maxVisibleCount)
        .fill(0)
        .map((_, idx) => (
          <ProductItemCellSkeleton
            key={`plp_skeleton_${idx}`}
            direction={productItemLayout?.direction}
          />
        ));
    }

    return splicedProducts
      .filter(pd => Boolean(pd) && Boolean(pd.isDisplayed))
      .map(product => (
        <ProductItemCell
          key={`${product.id}`}
          productId={product.id}
          block={productItemLayout}
        />
      ));
  }, [splicedProducts, maxVisibleCount, productItemLayout]);

  const isEmpty =
    products === null || (products !== undefined && !products.length);
  return (
    <>
      {listElementType === "horizontal" && (
        <ArrowContainer id={portalSectionId} />
      )}
      {isEmpty ? (
        <BlockitFeedback.Empty textKey="product_list_preview_empty" />
      ) : (
        <BlockitListLayout
          element={listElement}
          rightArrow={
            listElementType === "horizontal" ? PortalRightArrow : undefined
          }
          leftArrow={
            listElementType === "horizontal" ? PortalLeftArrow : undefined
          }
          arrowPortalTargetId={portalSectionId}
        >
          {itemElements}
        </BlockitListLayout>
      )}
    </>
  );
};

export default React.memo(CampaignPreview);
