import {
  Svg18Like1,
  Svg18LikeC,
  Svg18LikeFill,
  Svg18LikeSBlue,
  Svg24Like1,
  Svg24LikeFill,
} from "@moim/icons";
import { useSingleLineStyle } from "common/components/designSystem/styles";
import {
  B3RegularStyle,
  B4RegularStyle,
} from "common/components/designSystem/typos";
import { MEDIA_QUERY } from "common/constants/responsive";
import { px2rem } from "common/helpers/rem";
import * as React from "react";
import styled, { css } from "styled-components";

export const LargeLikedIcon = styled(Svg24LikeFill).attrs((props) => {
  const iconColor = props.theme.getReactionButtonElementPalette("like").color;

  return {
    size: "s",
    touch: 24,
    role: "button",
    iconColor,
  };
})`
  transition: transform 200ms ease-in;
  @media ${MEDIA_QUERY.ONLY_DESKTOP} {
    &:hover {
      transform: scale(1.2);
    }
  }
`;

export const LargeUnLikedIcon = styled(Svg24Like1).attrs((props) => ({
  size: "s",
  touch: 24,
  role: "button",
  iconColor: "currentColor",
}))``;

export const SmallLikedIcon = styled(Svg18LikeSBlue).attrs((props) => {
  const iconColor = props.theme.getReactionButtonElementPalette("like").color;
  return {
    size: "xs",
    touch: 18,
    role: "button",
    iconColor,
  };
})``;

export const SmallUnLikedIcon = styled(Svg18LikeC).attrs((props) => ({
  size: "xs",
  touch: 18,
  role: "button",
  iconColor: "currentColor",
}))``;

export const MiddleLikedIcon = styled(Svg18LikeFill).attrs((props) => {
  const iconColor = props.theme.getReactionButtonElementPalette("like").color;
  return {
    size: "xs",
    touch: 30,
    role: "button",
    iconColor,
  };
})``;

export const MiddleUnLikedIcon = styled(Svg18Like1).attrs((props) => ({
  size: "xs",
  touch: 30,
  role: "button",
  iconColor: "currentColor",
}))``;

interface ITriangleProps {
  liked: boolean;
  onClick: React.MouseEventHandler;
}

export const LargeLikeButton = ({ liked, onClick }: ITriangleProps) =>
  liked ? (
    <LargeLikedIcon onClick={onClick} />
  ) : (
    <LargeUnLikedIcon onClick={onClick} />
  );

export const MiddleLikeButton = ({ liked, onClick }: ITriangleProps) =>
  liked ? (
    <MiddleLikedIcon onClick={onClick} />
  ) : (
    <MiddleUnLikedIcon onClick={onClick} />
  );

export const SmallLikeButton = ({ liked, onClick }: ITriangleProps) =>
  liked ? (
    <SmallLikedIcon onClick={onClick} />
  ) : (
    <SmallUnLikedIcon onClick={onClick} />
  );

export const SmallWrapper = styled.div<{
  disabled?: boolean;
}>`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colorV2.colorSet.grey300}
    ${(props) => props.disabled && `pointer-events: none;`};
`;

export const MiddleWrapper = styled.div<{
  disabled?: boolean;
}>`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colorV2.colorSet.grey300}
    ${(props) => props.disabled && `pointer-events: none;`};
`;

export const LargeWrapper = styled.div<{
  disabled?: boolean;
}>`
  display: flex;
  align-items: center;
  ${(props) => props.disabled && `pointer-events: none;`};
`;

const largeCountStyle = css<{
  isLiked: boolean;
  normalColor?: string;
  likedColor?: string;
}>`
  transition: opacity 200ms ease-in;

  &:hover {
    opacity: 0.6;
  }
  ${B3RegularStyle};
  line-height: 1;
  margin-left: ${px2rem(2)};
  color: ${(props) =>
    props.isLiked
      ? props.likedColor ?? "currentColor"
      : props.normalColor ?? "currentColor"};
`;

const smallCountStyle = css<{
  isLiked: boolean;
  normalColor?: string;
  likedColor?: string;
}>`
  transition: opacity 200ms ease-in;

  &:hover {
    opacity: 0.6;
  }
  color: ${(props) =>
    props.isLiked
      ? props.likedColor ?? "currentColor"
      : props.normalColor ?? "currentColor"};
  ${B4RegularStyle};
  line-height: 1;
`;

export const LargeCount = styled.div<{
  isLiked: boolean;
  normalColor?: string;
  likedColor?: string;
}>`
  ${largeCountStyle}
`;

export const SmallCount = styled.div<{
  isLiked: boolean;
  normalColor?: string;
  likedColor?: string;
}>`
  ${smallCountStyle}
`;

export const MiddleCount = styled.div<{
  isLiked: boolean;
  normalColor?: string;
  likedColor?: string;
}>`
  ${smallCountStyle}
`;

export const SmallLabel = styled.div.attrs(() => ({
  role: "button",
}))`
  ${B4RegularStyle};
  ${useSingleLineStyle};
  margin-left: ${px2rem(2)};
`;

export const LargeLabel = styled.div.attrs(() => ({
  role: "button",
}))`
  ${B3RegularStyle};
  ${useSingleLineStyle};
  margin-left: ${px2rem(2)};
`;
