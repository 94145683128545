const regex = /((([a-zA-Z]+(-[a-zA-Z0-9]+){0,2})|\*)(;q=[0-1](\.[0-9]+)?)?)*/g;

interface IParsedData {
  code: string;
  script?: string;
  region?: string;
  quality: number;
}
function parse(al: string) {
  var strings = (al || "").match(regex);
  return strings
    ?.map<IParsedData | undefined>(m => {
      if (!m) {
        return;
      }

      var bits = m.split(";");
      var ietf = bits[0].split("-");
      var hasScript = ietf.length === 3;

      return {
        code: ietf[0],
        script: hasScript ? ietf[1] : undefined,
        region: hasScript ? ietf[2] : ietf[1],
        quality: bits[1] ? parseFloat(bits[1].split("=")[1]) : 1.0,
      };
    })
    .filter((r): r is IParsedData => Boolean(r))
    .sort(function(a, b) {
      return b!.quality - a!.quality;
    });
}

function pick(
  supportedLanguages: string[],
  acceptLanguage: string,
  options?: { loose?: boolean },
) {
  const parsedAcceptLanguage = parse(acceptLanguage);
  if (!supportedLanguages.length || !parsedAcceptLanguage?.length) {
    return undefined;
  }

  var supported = supportedLanguages.map(function(support) {
    var bits = support.split("-");
    var hasScript = bits.length === 3;

    return {
      code: bits[0],
      script: hasScript ? bits[1] : undefined,
      region: hasScript ? bits[2] : bits[1],
    };
  });

  for (var i = 0; i < parsedAcceptLanguage.length; i++) {
    var lang = parsedAcceptLanguage[i];
    var langCode = lang.code.toLowerCase();
    var langRegion = lang.region ? lang.region.toLowerCase() : lang.region;
    var langScript = lang.script ? lang.script.toLowerCase() : lang.script;
    for (var j = 0; j < supported.length; j++) {
      var supportedCode = supported[j].code.toLowerCase();
      var supportedScript = supported[j].script
        ? supported[j].script?.toLowerCase()
        : supported[j].script;
      var supportedRegion = supported[j].region
        ? supported[j].region.toLowerCase()
        : supported[j].region;
      if (
        langCode === supportedCode &&
        ((options?.loose ?? true) ||
          !langScript ||
          langScript === supportedScript) &&
        ((options?.loose ?? true) ||
          !langRegion ||
          langRegion === supportedRegion)
      ) {
        return supportedLanguages[j];
      }
    }
  }

  return undefined;
}

const AcceptLanguageParser = {
  pick,
  parse,
};
export default AcceptLanguageParser;
