import * as React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { px2rem } from "common/helpers/rem";
import { PermissionDeniedFallbackType } from "app/enums";
import PermissionChecker, {
  useResourcePermission,
} from "common/components/permissionChecker";
import Reply from "common/components/engage/reply";
import { MiddleLike } from "common/components/engage/like";
import { MiddleUpDown } from "common/components/engage/upDown";
import { B4Bold } from "common/components/designSystem/typos";
import OriginalAuthorVoteComponent from "./originalAuthorVoteComponent";

const Wrapper = styled.div`
  display: inline-flex;
  margin-left: ${px2rem(-6)};
  align-items: center;
`;

const WriteReplyButton = styled(B4Bold).attrs({ role: "button" })<{
  hasAuthorVote?: boolean;
}>`
  color: ${props => props.theme.colorV2.colorSet.grey300};
  margin-left: ${props => px2rem(props.hasAuthorVote ? 12 : 8)};
`;

interface ICommonProps {
  groupId?: Moim.Id;
  originalAuthorId?: Moim.Id;
  disableWriteReplyButton?: boolean;
  withoutVotePermissionCheck?: boolean;
  hasAuthorVote?: boolean;
}

interface ILikeProps
  extends React.ComponentProps<typeof MiddleLike>,
    React.ComponentProps<typeof Reply>,
    ICommonProps {
  type: "like";
}

interface IUpdownProps
  extends React.ComponentProps<typeof MiddleUpDown>,
    React.ComponentProps<typeof Reply>,
    ICommonProps {
  type: "updown";
}

export default function Engage(props: ILikeProps | IUpdownProps) {
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const {
    replyCount,
    canReply,
    replyText,
    onReplyClick,
    withoutVotePermissionCheck,
    groupId,
    disableWriteReplyButton = false,
    originalAuthorId,
    hasAuthorVote,
    disableReplyCountElement,
    ...restProps
  } = props;
  const { hasPermission: votePermission } = useResourcePermission(
    "COMMENT_VOTE",
    props.threadId,
  );
  const {
    isLoading: isWritePermissionLoading,
    hasPermission: hasWriteCommentPermission,
  } = useResourcePermission("WRITE_COMMENT", props.threadId);

  const vote = React.useMemo(
    () =>
      props.type === "like" ? (
        <MiddleLike {...(restProps as ILikeProps)} />
      ) : (
        <MiddleUpDown {...(restProps as IUpdownProps)} />
      ),
    [props.type, restProps],
  );

  return (
    <Wrapper>
      {withoutVotePermissionCheck ? (
        vote
      ) : (
        <PermissionChecker
          fallbackType={PermissionDeniedFallbackType.ALERT}
          hasPermission={votePermission}
          isLoading={false}
          groupId={groupId}
        >
          {vote}
        </PermissionChecker>
      )}

      <OriginalAuthorVoteComponent
        originalAuthorId={originalAuthorId}
        hasAuthorVote={hasAuthorVote}
      />

      <Reply
        disableReplyCountElement={disableReplyCountElement}
        replyCount={replyCount}
        canReply={canReply}
        replyText={replyText}
        onReplyClick={onReplyClick}
      />

      {!disableWriteReplyButton && (
        <PermissionChecker
          fallbackType={PermissionDeniedFallbackType.ALERT}
          groupId={groupId}
          isLoading={isWritePermissionLoading}
          hasPermission={hasWriteCommentPermission}
        >
          <WriteReplyButton
            hasAuthorVote={hasAuthorVote}
            onClick={onReplyClick}
          >
            <FormattedMessage id="button_write_reply" />
          </WriteReplyButton>
        </PermissionChecker>
      )}
    </Wrapper>
  );
}
