import styled from "styled-components";
import { px2rem } from "common/helpers/rem";

import { B4Regular, H9Bold } from "common/components/designSystem/typos";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 ${px2rem(16)} 0 ${px2rem(16)};

  .policy-guide-button {
    padding: 0;
  }
`;

export const TitleWrapper = styled.div`
  padding: ${px2rem(12)} 0;
  flex: 1;
  min-width: 0;

  display: flex;
  align-items: center;
  gap: ${px2rem(8)};
`;

export const Title = styled(H9Bold)`
  color: ${props => props.theme.colorV2.colorSet.grey800};
  flex: 1;
  min-width: 0;
`;

export const PreWrappedB4Regular = styled(B4Regular)`
  white-space: pre-wrap;
`;
