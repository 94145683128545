import React from "react";
import { FormItemHeader } from "../../../../header";
import { useIntlShort } from "common/hooks/useIntlShort";
import { FormValueContext } from "../../../../context";
import { IRef } from "../../../..";
import { useFormItemOptions } from "../../../../hooks";
import { Error, ToggleButton, ToggleButtonGroup } from "../styled";

export const SingleToggleButtonGroupFormItem = React.forwardRef<
  IRef,
  { item: Moim.Form.IFormItem }
>(({ item }, ref) => {
  const intl = useIntlShort();
  const { onChange, values, errors } = React.useContext(FormValueContext);
  const options = useFormItemOptions(item.options);
  const currentValue = values[item.id];
  const error = errors[item.id];

  React.useImperativeHandle(ref, () => ({
    validate: () => {
      if (item.isRequired && !currentValue) {
        return { [item.id]: intl("error_form_question_required") };
      }

      return { [item.id]: undefined };
    },
  }));

  return (
    <>
      <FormItemHeader item={item} />
      {Boolean(error) && <Error>{error}</Error>}
      <ToggleButtonGroup>
        {options.map(option => (
          <ToggleButton
            key={option.value}
            selected={option.value === currentValue}
            onClick={() => {
              if (option.value === currentValue) {
                onChange(item.id, null);
              } else {
                onChange(item.id, option.value);
              }
            }}
          >
            {option.label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </>
  );
});
