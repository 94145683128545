import { Spacer, SpacerVertical } from "common/components/designSystem/spacer";
import {
  FlexWrapper,
  SkeletonBox,
  SkeletonCircleBox,
  SkeletonRadiusBox,
} from "common/components/skeleton";
import { parseRatio } from "common/components/thread/components/wrapper/thumbnail";
import { px2rem } from "common/helpers/rem";
import React from "react";
import styled, { css } from "styled-components";

const ImageSkeleton = styled(SkeletonBox)<{
  thumbnailConfig: Moim.Forum.IForumThumbnailConfig;
}>`
  ${(props) => {
    const totalPercent =
      props.thumbnailConfig.position === "left" ||
      props.thumbnailConfig.position === "right"
        ? 20
        : 100;
    if (props.thumbnailConfig.type === "ratio") {
      const { width, height } = parseRatio(
        props.thumbnailConfig.value ?? "5:3",
      );
      return css`
        height: 0;
        width: ${totalPercent}%;
        padding-top: ${Math.round(totalPercent * (height / width))}%;
      `;
    }

    return css`
      width: ${totalPercent}%;
      padding-top: ${totalPercent}%;
    `;
  }}
`;

const TextSkeletonWrapper = styled.div`
  flex: 1;
  min-width: 0;
`;

const Wrapper = styled.div<{
  viewType?: string;
  showThumbnail: boolean;
  thumbnailConfig: Moim.Forum.IForumThumbnailConfig;
}>`
  width: 100%;
  height: 100%;

  gap: ${px2rem(16)};
  ${(props) => {
    if (!props.showThumbnail) {
      return;
    }
    switch (props.thumbnailConfig?.position) {
      case "top":
        return css`
          display: flex;
          flex-direction: column;
        `;
      case "bottom":
        return css`
          display: flex;
          flex-direction: column-reverse;
        `;
      case "right":
        return css`
          display: flex;
          flex-direction: row-reverse;
        `;
      case "left":
      default:
        return css`
          display: flex;
          flex-direction: row;
        `;
    }
  }}
  ${(props) => {
    switch (props.viewType) {
      case "review-preview-item":
      case "compact-conversation":
        return css`
          padding: ${px2rem(12)} ${px2rem(16)};
          border-radius: ${px2rem(12)};
          border: solid ${px2rem(1)}
            ${(props) => props.theme.colorV2.colorSet.grey50};
          background-color: ${(props) =>
            props.theme.colorV2.colorSet.white1000};
        `;

      case "media-focus-feed":
      case "media-text-mixed-feed":
        return css`
          padding: ${px2rem(20)};
        `;

      case "magazine":
        return css`
          border-radius: ${px2rem(8)};
          border: solid ${px2rem(1)}
            ${(props) => props.theme.colorV2.colorSet.grey50};
          padding: ${px2rem(20)};
        `;

      default:
        return;
    }
  }}
`;

export function PostCellSkeleton({
  viewType,
  showThumbnail,
  thumbnailConfig,
}: {
  viewType?: string;
  showThumbnail: boolean;
  thumbnailConfig: Moim.Forum.IForumThumbnailConfig;
}) {
  switch (viewType) {
    case "media-focus-feed":
      return (
        <Wrapper
          className="postCellSkeleton"
          viewType={viewType}
          showThumbnail={showThumbnail}
          thumbnailConfig={thumbnailConfig}
        >
          <MediaFocusFeedSkeleton />
        </Wrapper>
      );

    case "media-text-mixed-feed":
      return (
        <Wrapper
          className="postCellSkeleton"
          viewType={viewType}
          showThumbnail={showThumbnail}
          thumbnailConfig={thumbnailConfig}
        >
          <MediaTextMixedFeedSkeleton />
        </Wrapper>
      );

    case "magazine":
      return (
        <Wrapper
          className="postCellSkeleton"
          viewType={viewType}
          showThumbnail={showThumbnail}
          thumbnailConfig={thumbnailConfig}
        >
          <MagazineSkeleton />
        </Wrapper>
      );

    default:
      return (
        <Wrapper
          className="postCellSkeleton"
          viewType={viewType}
          showThumbnail={showThumbnail}
          thumbnailConfig={thumbnailConfig}
        >
          {showThumbnail && (
            <ImageSkeleton width="100%" thumbnailConfig={thumbnailConfig} />
          )}
          <TextSkeletonWrapper>
            <TextSkeleton />
          </TextSkeletonWrapper>
        </Wrapper>
      );
  }
}

function TextSkeleton() {
  return (
    <FlexWrapper flexDirection="column">
      <SkeletonBox width="90%" height={px2rem(14)} />
      <Spacer value={8} />
      <SkeletonBox width="100%" height={px2rem(14)} />
      <Spacer value={8} />
      <FlexWrapper>
        <SkeletonBox width="60px" height={px2rem(16)} />
        <SpacerVertical value={6} />
        <SkeletonBox width="60px" height={px2rem(16)} />
      </FlexWrapper>
    </FlexWrapper>
  );
}

function UserInfoSkeleton() {
  return (
    <div style={{ maxWidth: "130px" }}>
      <FlexWrapper>
        <SkeletonCircleBox size={px2rem(34)} />
        <SpacerVertical value={10} />
        <FlexWrapper flexDirection="column">
          <SkeletonBox width="86px" height={px2rem(14)} />
          <Spacer value={10} />
          <SkeletonBox width="46px" height={px2rem(14)} />
        </FlexWrapper>
      </FlexWrapper>
    </div>
  );
}

function EngageSkeleton() {
  return (
    <FlexWrapper>
      <SkeletonBox width="24px" height={px2rem(24)} />
      <SpacerVertical value={10} />
      <SkeletonBox width="24px" height={px2rem(24)} />
    </FlexWrapper>
  );
}

export function MediaFocusFeedSkeleton() {
  return (
    <FlexWrapper flexDirection="column">
      <UserInfoSkeleton />
      <Spacer value={10} />
      <ImageSkeleton
        width="100%"
        thumbnailConfig={{ type: "ratio", position: "top", value: "1:1" }}
      />
      <Spacer value={10} />
      <EngageSkeleton />
      <Spacer value={10} />
      <SkeletonBox width="70%" height={px2rem(14)} />
      <Spacer value={10} />
      <SkeletonBox width="100%" height={px2rem(14)} />
    </FlexWrapper>
  );
}

export function MediaTextMixedFeedSkeleton() {
  return (
    <FlexWrapper flexDirection="column">
      <UserInfoSkeleton />
      <Spacer value={10} />
      <SkeletonBox width="70%" height={px2rem(14)} />
      <Spacer value={10} />
      <SkeletonBox width="100%" height={px2rem(14)} />
      <Spacer value={10} />
      <ImageSkeleton
        width="100%"
        thumbnailConfig={{ type: "ratio", position: "top", value: "1:1" }}
      />
      <Spacer value={10} />
      <EngageSkeleton />
    </FlexWrapper>
  );
}

export function MagazineSkeleton() {
  return (
    <FlexWrapper flexDirection="column">
      <Spacer value={10} />
      <SkeletonBox width="90%" height={px2rem(14)} />
      <Spacer value={10} />
      <SkeletonBox width="100%" height={px2rem(14)} />
      <Spacer value={10} />
      <FlexWrapper>
        <SkeletonRadiusBox
          radius={px2rem(8)}
          width={px2rem(43)}
          height={px2rem(17)}
        />
        <SpacerVertical value={10} />
        <SkeletonRadiusBox
          radius={px2rem(8)}
          width={px2rem(43)}
          height={px2rem(17)}
        />
        <SpacerVertical value={10} />
        <SkeletonRadiusBox
          radius={px2rem(8)}
          width={px2rem(43)}
          height={px2rem(17)}
        />
      </FlexWrapper>
      <Spacer value={10} />
      <SkeletonBox width="40%" height={px2rem(14)} />
    </FlexWrapper>
  );
}
