import {
  AuthorWrapper,
  Engage,
  EngageProfileWithPositionChip,
  EngageWrapper,
  ReadTimeWrapper,
  Status,
  StatusWrapper,
  Wrapper,
} from "./styled";
import { ItemIdTypes, VoteStatus } from "app/enums";
import CreateDayOMeter from "common/components/createDayOMeter";
import { SmallComment } from "common/components/engage/comment";
import { SmallLike } from "common/components/engage/like";
import { SmallUpDown } from "common/components/engage/upDown";
import ViewCount from "common/components/engage/viewCount";
import ShavedTextV2 from "common/components/shavedText/v2";
import { useThreadItemContext } from "common/components/thread/commonThreadItem/context";
import GroupAndChannelName from "common/components/thread/templates/common/channelName";
import UserProfileImage from "common/components/userProfileImage";
import { useCurrentUserLocale } from "common/hooks/localization/useCurrentUserLocale";
import { useIntlShort } from "common/hooks/useIntlShort";
import * as React from "react";

function Engagement({
  positions,
  author,

  handler,
  // onDownCountClick,
  // onUpCountClick,
  disableAnonymousSuffix,
}: {
  moim?: Moim.Group.IGroup;
  channel?: Moim.Channel.SimpleChannelType;
  positions?: Moim.Position.IPosition[];
  author?: Moim.User.IOriginalUserDatum;
  handler?: (type: Moim.Enums.VoteStatus) => void;
  onDownCountClick?(): void;
  onUpCountClick?(): void;
  disableAnonymousSuffix?: boolean;
}) {
  const locale = useCurrentUserLocale();
  const intl = useIntlShort();
  const anonymousTextKey = intl("anonymous_member");
  const {
    threadId,
    isDraftThread,
    upVoteCount,
    downVoteCount,
    commentCount,
    voteStatus,
    voteType,
    viewCount,
    authorId,
    createdAt,
    editedAt,
    readTime,
    textAlign,
    showReaction,
    showCommentCount,
    showAuthor,
    showAuthorAvatar,
    showAuthorPosition,
    showDate,
    showReadTime,
    showViewCount,
    showChannel,
    showMoim,
    channelId,
    groupId,
    anonymousData,
    thumbnailUrl,
  } = useThreadItemContext((state) => ({
    threadId: state.item.id,
    isDraftThread: state.item.id.startsWith(ItemIdTypes.DRAFT),
    upVoteCount: state.item.up_vote_score,
    downVoteCount: state.item.down_vote_score,
    commentCount: state.item.replies_count,
    voteStatus: state.item.vote?.type,
    voteType: state.config?.reactionType,
    viewCount: state.item.viewCount,
    authorId: state.item.author,
    createdAt: state.item.created_at,
    editedAt: state.item.edited_at,
    readTime: state.item.readTime,
    textAlign: state.config.textAlignment,
    showReaction: state.config.showReaction,
    showCommentCount: state.config.showCommentCount,
    showAuthor: state.config.showAuthor,
    showAuthorAvatar: state.config.showAuthorAvatar,
    showAuthorPosition: state.config.showAuthorPosition,
    showDate: state.config.showDate,
    showReadTime: state.config.showReadTime,
    showViewCount: state.config.showViewCount,
    showChannel: state.config.showChannel,
    showMoim: state.config.showMoim,
    channelId: state.item.root_id,
    groupId: state.item.groupId,
    anonymousData: state.item.anonymous_data,
    thumbnailUrl: state.item.preview?.thumbnail?.url,
  }));

  const showEngage =
    showReaction ||
    showCommentCount ||
    showAuthor ||
    showDate ||
    showViewCount ||
    showChannel ||
    showMoim;

  if (!showEngage) {
    return null;
  }
  return (
    <Wrapper textAlign={textAlign} thumbnailUrl={thumbnailUrl}>
      {(showReaction || showCommentCount || showReadTime) && (
        <EngageWrapper>
          {Boolean(showReadTime) && (
            <ReadTimeWrapper>
              {intl("reading_time", { n: Math.round((readTime ?? 0) / 60) })}
            </ReadTimeWrapper>
          )}
          {showReaction && (
            <Engage>
              {voteType === "up" ? (
                <SmallLike
                  threadId={threadId}
                  liked={voteStatus === VoteStatus.POSITIVE}
                  likeCount={upVoteCount}
                  handleLike={handler}
                />
              ) : (
                <SmallUpDown
                  threadId={threadId}
                  status={voteStatus ?? VoteStatus.NONE}
                  upCount={upVoteCount}
                  downCount={downVoteCount}
                  handler={handler}
                />
              )}
            </Engage>
          )}

          {showCommentCount && (
            <Engage>
              <SmallComment
                count={commentCount}
                showCommentCount={showCommentCount}
              />
            </Engage>
          )}

          {showViewCount && viewCount !== undefined ? (
            <Engage>
              <ViewCount count={viewCount} />
            </Engage>
          ) : null}
        </EngageWrapper>
      )}
      {(showChannel || showMoim) && (
        <GroupAndChannelName
          channelId={channelId}
          groupId={groupId}
          showChannel={showChannel}
          showMoim={showMoim}
          textAlign={textAlign}
        />
      )}
      {(showAuthor || showDate) && (
        <StatusWrapper>
          {showAuthor && (
            <AuthorWrapper key="author">
              {showAuthorAvatar && (
                <UserProfileImage src={author?.avatar_url} size="xs" />
              )}

              <EngageProfileWithPositionChip
                positions={positions ?? []}
                hasPositionChip={showAuthorPosition}
              >
                <ShavedTextV2 line={1}>
                  {anonymousData
                    ? `${anonymousTextKey}${
                        !disableAnonymousSuffix
                          ? anonymousData.authorSuffix?.[
                              locale as keyof typeof anonymousData.authorSuffix
                            ]
                          : ""
                      }`
                    : author?.name ?? authorId}
                </ShavedTextV2>
              </EngageProfileWithPositionChip>
            </AuthorWrapper>
          )}
          {showDate && (
            <Status key="createAt">
              <ShavedTextV2 line={1}>
                <CreateDayOMeter
                  key={isDraftThread ? "edited-at" : "create-at"}
                  givenDate={isDraftThread ? editedAt ?? createdAt : createdAt}
                  className="time"
                  useChange={false}
                  normalFormat={intl("datetime_format_short_tiny_date")}
                />
              </ShavedTextV2>
            </Status>
          )}
        </StatusWrapper>
      )}
    </Wrapper>
  );
}

export default React.memo(Engagement);
