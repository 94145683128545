import {
  B1Bold,
  B1Regular,
  B2Bold,
  B2Regular,
  B3Bold,
  B3Regular,
  B4Bold,
  B4Regular,
  CaptionRegular,
  H10Bold,
  H10Regular,
  H1Bold,
  H1Regular,
  H2Bold,
  H2Regular,
  H3Bold,
  H3Regular,
  H4Bold,
  H4Regular,
  H5Bold,
  H5Regular,
  H6Bold,
  H6Regular,
  H7Bold,
  H7Regular,
  H8Bold,
  H8Regular,
  H9Bold,
  H9Regular,
  PB1Bold,
  PB1Regular,
  PB2Bold,
  PB2Regular,
  PB3Bold,
  PB3Regular,
  PB4Bold,
  PB4Regular,
  PlainText,
} from "common/components/blockitEditorBase/components/blockitRenderer.v2/blocks/texts/styled";
import * as React from "react";

interface IProps
  extends Pick<
    Moim.Blockit.V2.ITextBlock,
    | "fontWeight"
    | "color"
    | "fontFamily"
    | "align"
    | "border"
    | "letterSpacing"
    | "subType_v2"
  > {
  children: React.ReactNode;
  className?: string;
  role?: string;
  onClick?: React.MouseEventHandler<HTMLElement>;
}

export const TextComponent = ({
  fontWeight: weight,
  color,
  fontFamily,
  align,
  border,
  letterSpacing,
  subType_v2: subType,
  children,
  role,
  className,
  onClick,
}: IProps) => {
  if (!subType) {
    return <PlainText>{children}</PlainText>;
  }

  switch (subType) {
    case "h1Regular":
      return (
        <H1Regular
          role={role}
          className={className}
          color={color}
          align={align}
          border={border}
          weight={weight}
          fontFamily={fontFamily}
          letterSpacing={letterSpacing}
          onClick={onClick}
        >
          {children}
        </H1Regular>
      );
    case "h1Bold":
      return (
        <H1Bold
          role={role}
          className={className}
          color={color}
          align={align}
          border={border}
          weight={weight}
          fontFamily={fontFamily}
          letterSpacing={letterSpacing}
          onClick={onClick}
        >
          {children}
        </H1Bold>
      );
    case "h2Regular":
      return (
        <H2Regular
          role={role}
          className={className}
          color={color}
          align={align}
          border={border}
          weight={weight}
          fontFamily={fontFamily}
          letterSpacing={letterSpacing}
          onClick={onClick}
        >
          {children}
        </H2Regular>
      );
    case "h2Bold":
      return (
        <H2Bold
          role={role}
          className={className}
          color={color}
          align={align}
          border={border}
          weight={weight}
          fontFamily={fontFamily}
          letterSpacing={letterSpacing}
          onClick={onClick}
        >
          {children}
        </H2Bold>
      );
    case "h3Regular":
      return (
        <H3Regular
          role={role}
          className={className}
          color={color}
          align={align}
          border={border}
          weight={weight}
          fontFamily={fontFamily}
          letterSpacing={letterSpacing}
          onClick={onClick}
        >
          {children}
        </H3Regular>
      );
    case "h3Bold":
      return (
        <H3Bold
          role={role}
          className={className}
          color={color}
          align={align}
          border={border}
          weight={weight}
          fontFamily={fontFamily}
          letterSpacing={letterSpacing}
          onClick={onClick}
        >
          {children}
        </H3Bold>
      );
    case "h4Regular":
      return (
        <H4Regular
          role={role}
          className={className}
          color={color}
          align={align}
          border={border}
          weight={weight}
          fontFamily={fontFamily}
          letterSpacing={letterSpacing}
          onClick={onClick}
        >
          {children}
        </H4Regular>
      );
    case "h4Bold":
      return (
        <H4Bold
          role={role}
          className={className}
          color={color}
          align={align}
          border={border}
          weight={weight}
          fontFamily={fontFamily}
          letterSpacing={letterSpacing}
          onClick={onClick}
        >
          {children}
        </H4Bold>
      );
    case "h5Regular":
      return (
        <H5Regular
          role={role}
          className={className}
          color={color}
          align={align}
          border={border}
          weight={weight}
          fontFamily={fontFamily}
          letterSpacing={letterSpacing}
          onClick={onClick}
        >
          {children}
        </H5Regular>
      );
    case "h5Bold":
      return (
        <H5Bold
          role={role}
          className={className}
          color={color}
          align={align}
          border={border}
          weight={weight}
          fontFamily={fontFamily}
          letterSpacing={letterSpacing}
          onClick={onClick}
        >
          {children}
        </H5Bold>
      );
    case "h6Regular":
      return (
        <H6Regular
          role={role}
          className={className}
          color={color}
          align={align}
          border={border}
          weight={weight}
          fontFamily={fontFamily}
          letterSpacing={letterSpacing}
          onClick={onClick}
        >
          {children}
        </H6Regular>
      );
    case "h6Bold":
      return (
        <H6Bold
          role={role}
          className={className}
          color={color}
          align={align}
          border={border}
          weight={weight}
          fontFamily={fontFamily}
          letterSpacing={letterSpacing}
          onClick={onClick}
        >
          {children}
        </H6Bold>
      );
    case "h7Regular":
      return (
        <H7Regular
          role={role}
          className={className}
          color={color}
          align={align}
          border={border}
          weight={weight}
          fontFamily={fontFamily}
          letterSpacing={letterSpacing}
          onClick={onClick}
        >
          {children}
        </H7Regular>
      );
    case "h7Bold":
      return (
        <H7Bold
          role={role}
          className={className}
          color={color}
          align={align}
          border={border}
          weight={weight}
          fontFamily={fontFamily}
          letterSpacing={letterSpacing}
          onClick={onClick}
        >
          {children}
        </H7Bold>
      );
    case "h8Regular":
      return (
        <H8Regular
          role={role}
          className={className}
          color={color}
          align={align}
          border={border}
          weight={weight}
          fontFamily={fontFamily}
          letterSpacing={letterSpacing}
          onClick={onClick}
        >
          {children}
        </H8Regular>
      );
    case "h8Bold":
      return (
        <H8Bold
          role={role}
          className={className}
          color={color}
          align={align}
          border={border}
          weight={weight}
          fontFamily={fontFamily}
          letterSpacing={letterSpacing}
          onClick={onClick}
        >
          {children}
        </H8Bold>
      );
    case "h9Regular":
      return (
        <H9Regular
          role={role}
          className={className}
          color={color}
          align={align}
          border={border}
          weight={weight}
          fontFamily={fontFamily}
          letterSpacing={letterSpacing}
          onClick={onClick}
        >
          {children}
        </H9Regular>
      );
    case "h9Bold":
      return (
        <H9Bold
          role={role}
          className={className}
          color={color}
          align={align}
          border={border}
          weight={weight}
          fontFamily={fontFamily}
          letterSpacing={letterSpacing}
          onClick={onClick}
        >
          {children}
        </H9Bold>
      );
    case "h10Regular":
      return (
        <H10Regular
          role={role}
          className={className}
          color={color}
          align={align}
          border={border}
          weight={weight}
          fontFamily={fontFamily}
          letterSpacing={letterSpacing}
          onClick={onClick}
        >
          {children}
        </H10Regular>
      );
    case "h10Bold":
      return (
        <H10Bold
          role={role}
          className={className}
          color={color}
          align={align}
          border={border}
          weight={weight}
          fontFamily={fontFamily}
          letterSpacing={letterSpacing}
          onClick={onClick}
        >
          {children}
        </H10Bold>
      );
    case "b1Regular":
      return (
        <B1Regular
          role={role}
          className={className}
          color={color}
          align={align}
          border={border}
          weight={weight}
          fontFamily={fontFamily}
          letterSpacing={letterSpacing}
          onClick={onClick}
        >
          {children}
        </B1Regular>
      );
    case "b1Bold":
      return (
        <B1Bold
          role={role}
          className={className}
          color={color}
          align={align}
          border={border}
          weight={weight}
          fontFamily={fontFamily}
          letterSpacing={letterSpacing}
          onClick={onClick}
        >
          {children}
        </B1Bold>
      );
    case "pB1Regular":
      return (
        <PB1Regular
          role={role}
          className={className}
          color={color}
          align={align}
          border={border}
          weight={weight}
          fontFamily={fontFamily}
          letterSpacing={letterSpacing}
          onClick={onClick}
        >
          {children}
        </PB1Regular>
      );
    case "pB1Bold":
      return (
        <PB1Bold
          role={role}
          className={className}
          color={color}
          align={align}
          border={border}
          weight={weight}
          fontFamily={fontFamily}
          letterSpacing={letterSpacing}
          onClick={onClick}
        >
          {children}
        </PB1Bold>
      );
    case "b2Regular":
      return (
        <B2Regular
          role={role}
          className={className}
          color={color}
          align={align}
          border={border}
          weight={weight}
          fontFamily={fontFamily}
          letterSpacing={letterSpacing}
          onClick={onClick}
        >
          {children}
        </B2Regular>
      );
    case "b2Bold":
      return (
        <B2Bold
          role={role}
          className={className}
          color={color}
          align={align}
          border={border}
          weight={weight}
          fontFamily={fontFamily}
          letterSpacing={letterSpacing}
          onClick={onClick}
        >
          {children}
        </B2Bold>
      );
    case "pB2Regular":
      return (
        <PB2Regular
          role={role}
          className={className}
          color={color}
          align={align}
          border={border}
          weight={weight}
          fontFamily={fontFamily}
          letterSpacing={letterSpacing}
          onClick={onClick}
        >
          {children}
        </PB2Regular>
      );
    case "pB2Bold":
      return (
        <PB2Bold
          role={role}
          className={className}
          color={color}
          align={align}
          border={border}
          weight={weight}
          fontFamily={fontFamily}
          letterSpacing={letterSpacing}
          onClick={onClick}
        >
          {children}
        </PB2Bold>
      );
    case "b3Regular":
      return (
        <B3Regular
          role={role}
          className={className}
          color={color}
          align={align}
          border={border}
          weight={weight}
          fontFamily={fontFamily}
          letterSpacing={letterSpacing}
          onClick={onClick}
        >
          {children}
        </B3Regular>
      );
    case "b3Bold":
      return (
        <B3Bold
          role={role}
          className={className}
          color={color}
          align={align}
          border={border}
          weight={weight}
          fontFamily={fontFamily}
          letterSpacing={letterSpacing}
          onClick={onClick}
        >
          {children}
        </B3Bold>
      );
    case "pB3Regular":
      return (
        <PB3Regular
          role={role}
          className={className}
          color={color}
          align={align}
          border={border}
          weight={weight}
          fontFamily={fontFamily}
          letterSpacing={letterSpacing}
          onClick={onClick}
        >
          {children}
        </PB3Regular>
      );
    case "pB3Bold":
      return (
        <PB3Bold
          role={role}
          className={className}
          color={color}
          align={align}
          border={border}
          weight={weight}
          fontFamily={fontFamily}
          letterSpacing={letterSpacing}
          onClick={onClick}
        >
          {children}
        </PB3Bold>
      );
    case "b4Regular":
      return (
        <B4Regular
          role={role}
          className={className}
          color={color}
          align={align}
          border={border}
          weight={weight}
          fontFamily={fontFamily}
          letterSpacing={letterSpacing}
          onClick={onClick}
        >
          {children}
        </B4Regular>
      );
    case "b4Bold":
      return (
        <B4Bold
          role={role}
          className={className}
          color={color}
          align={align}
          border={border}
          weight={weight}
          fontFamily={fontFamily}
          letterSpacing={letterSpacing}
          onClick={onClick}
        >
          {children}
        </B4Bold>
      );
    case "pB4Regular":
      return (
        <PB4Regular
          role={role}
          className={className}
          color={color}
          align={align}
          border={border}
          weight={weight}
          fontFamily={fontFamily}
          letterSpacing={letterSpacing}
          onClick={onClick}
        >
          {children}
        </PB4Regular>
      );
    case "pB4Bold":
      return (
        <PB4Bold
          role={role}
          className={className}
          color={color}
          align={align}
          border={border}
          weight={weight}
          fontFamily={fontFamily}
          letterSpacing={letterSpacing}
          onClick={onClick}
        >
          {children}
        </PB4Bold>
      );
    case "caption":
      return (
        <CaptionRegular
          role={role}
          className={className}
          color={color}
          align={align}
          border={border}
          weight={weight}
          fontFamily={fontFamily}
          letterSpacing={letterSpacing}
          onClick={onClick}
        >
          {children}
        </CaptionRegular>
      );
  }
};
