import * as React from "react";
import { useActions, useStoreState } from "app/store";
import useRedirect from "common/hooks/useRedirect";
import useGroupTexts from "common/hooks/useGroupTexts";
import { MoimURL } from "common/helpers/url";
import { ActionCreators } from "app/actions/secondaryView";
import { TitleSection, Title, RightArrow } from "./styled";

const MyDQuests: React.FC = () => {
  const { enableDquest } = useStoreState(state => ({
    enableDquest: state.entities.community_communities[
      state.app.currentHubGroupId ?? ""
    ]?.modules.includes("DQUEST"),
  }));
  const redirect = useRedirect();
  const myDQuestsTitleText = useGroupTexts("my_quest");
  const { openFromProfile } = useActions({
    openFromProfile: ActionCreators.openNativeSecondaryViewFromProfile,
  });

  const handleRedirectMyDQuests = React.useCallback(() => {
    openFromProfile(false);
    redirect(new MoimURL.MyQuestList().toString());
  }, [redirect]);

  if (!enableDquest) {
    return null;
  }

  return (
    <TitleSection onClick={handleRedirectMyDQuests}>
      <Title>{myDQuestsTitleText?.singular}</Title>
      <RightArrow />
    </TitleSection>
  );
};

export default React.memo(MyDQuests);
