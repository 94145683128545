import { CompactConversationItem } from "./templates/compactConversation";
import { GalleryItem } from "./templates/gallery";
import { Magazine } from "./templates/magazine";
import { MediaFocusFeed } from "./templates/mediaFocusFeed";
import { MediaTextMixedFeed } from "./templates/mediaTextMixedFeed";
import { PostItem } from "./templates/post";
import { Question } from "./templates/question";
import { Reply } from "./templates/reply";
import { ReviewPreviewItem } from "./templates/reviewPreviewItem";
import { useStoreState } from "app/store";
import { Thread } from "app/typings";
import useIsMobile from "common/hooks/useIsMobile";
import React from "react";

export { CompactConversationItem, PostItem };

export const ThreadRenderer = ({
  threadId,
  config,
  stat,
  titlePrefix,
  className,
  disableAnonymousSuffix,
}: Thread.IThreadItemBaseProps) => {
  const isMobile = useIsMobile();
  const viewType = React.useMemo(
    () =>
      isMobile || !config.viewType_web ? config.viewType : config.viewType_web,
    [isMobile, config.viewType, config.viewType_web],
  );

  const thread = useStoreState((state) => state.entities.threads[threadId]);

  switch (viewType) {
    case "compact-conversation":
      return (
        <CompactConversationItem
          key={threadId}
          threadId={threadId}
          stat={stat}
          titlePrefix={titlePrefix}
          config={config}
          className={className}
          disableAnonymousSuffix={disableAnonymousSuffix}
        />
      );
    case "gallery":
      return (
        <GalleryItem
          key={threadId}
          threadId={threadId}
          stat={stat}
          config={config}
          className={className}
          disableAnonymousSuffix={disableAnonymousSuffix}
        />
      );
    case "magazine":
      return (
        <Magazine
          key={threadId}
          thread={thread}
          stat={stat}
          titlePrefix={titlePrefix}
          config={config}
          className={className}
          disableAnonymousSuffix={disableAnonymousSuffix}
        />
      );
    case "media-focus-feed":
      return (
        <MediaFocusFeed thread={thread} config={config} className={className} />
      );
    case "media-text-mixed-feed":
      return (
        <MediaTextMixedFeed
          thread={thread}
          config={config}
          className={className}
        />
      );
    case "review-preview-item":
      return (
        <ReviewPreviewItem
          key={threadId}
          threadId={threadId}
          stat={stat}
          config={config}
          className={className}
          disableAnonymousSuffix={disableAnonymousSuffix}
        />
      );
    case "question":
      return (
        <Question
          key={threadId}
          threadId={threadId}
          stat={stat}
          config={config}
          className={className}
          disableAnonymousSuffix={disableAnonymousSuffix}
        />
      );
    case "reply":
      return (
        <Reply
          key={threadId}
          threadId={threadId}
          stat={stat}
          config={config}
          className={className}
          disableAnonymousSuffix={disableAnonymousSuffix}
        />
      );
    case "post":
    default:
      return (
        <PostItem
          key={threadId}
          threadId={threadId}
          stat={stat}
          titlePrefix={titlePrefix}
          config={config}
          className={className}
          disableAnonymousSuffix={disableAnonymousSuffix}
        />
      );
  }
};
