/* eslint-disable no-underscore-dangle */
import { ISSRLoadDataPayload } from "app/typings/ssr";
import { setInitialData } from "common/helpers/initialData";
import _ from "lodash";

export async function NFTShowLoadData({
  match,
  api,
  requestId,
}: ISSRLoadDataPayload) {
  const nftId = match.params.nftItemId;

  if (nftId) {
    const nft = await api.nft.getItem(nftId);
    setInitialData("__entities.nft.item", requestId, nft);
  }
}
