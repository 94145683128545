import * as React from "react";
import { FormattedMessage } from "react-intl";
import FeedbackBase from "common/components/feedBack/components/base";
import { FlatButton } from "../../styled";
import { LoginIcon } from "./styled";

import useCurrentGroup from "common/hooks/useCurrentGroup";
import { useHandleSignIn } from "common/hooks/useHandleSign";
import { useIntlShort } from "common/hooks/useIntlShort";

interface Props {
  customFeedbackTitle?: string;
}

function UnsignedFullScreenFeedback({ customFeedbackTitle }: Props) {
  const currentGroup = useCurrentGroup();
  const signIn = useHandleSignIn();
  const intl = useIntlShort();
  const signInText = intl("button_login");
  const joinText = intl("join_button");
  const childTitle = intl("non_joined_child_feedback_full");
  const parentTitle = intl("non_signed_up_parent_feedback_full");

  const title = React.useMemo(() => {
    if (customFeedbackTitle) {
      return customFeedbackTitle;
    }

    if (currentGroup?.is_hub) {
      return parentTitle;
    }

    return childTitle;
  }, [childTitle, currentGroup, parentTitle]);
  const joinButton = React.useMemo(() => {
    if (currentGroup?.is_hub) {
      return signInText;
    }

    return (
      <FormattedMessage
        id="child_moim_join_button"
        values={{
          ref_join_button: joinText ?? "",
        }}
      />
    );
  }, [currentGroup, joinText, signInText]);

  return (
    <FeedbackBase
      icon={<LoginIcon />}
      title={title}
      buttons={[<FlatButton onClick={signIn}>{joinButton}</FlatButton>]}
    />
  );
}

export default UnsignedFullScreenFeedback;
