import { MediaPreview } from "common/components/designSystem/media/";
import { MEDIA_QUERY } from "common/constants/responsive";
import { px2rem } from "common/helpers/rem";
import * as React from "react";
import { useResizeDetector } from "react-resize-detector";
import styled, { css } from "styled-components";

interface IWrapperProps {
  type: "message" | "comment";
  reverse?: boolean;
  width?: number | null;
}

const getReverseStyle = (reverse?: boolean) =>
  css`
    justify-content: ${reverse ? "flex-end" : "flex-start"};
  `;

const Wrapper = styled.div<IWrapperProps>`
  display: flex;
  flex: 1;
  width: ${(props) => (props.width ? px2rem(props.width) : "inherit")};
  min-width: 0;
  ${(props) => getReverseStyle(props.reverse)}

  padding: ${px2rem(4)} ${px2rem(16)} ${px2rem(3)} 0;
  ${(props) => {
    if (props.type === "message") {
      return css`
        max-width: ${px2rem(320)};
      `;
    }
  }}

  @media ${MEDIA_QUERY.ONLY_MOBILE} {
    > * {
      flex: 1;
      min-width: 0;
    }
  }
`;

export default function Media({
  type,
  reverse,
  ...props
}: React.ComponentProps<typeof MediaPreview> & IWrapperProps) {
  const ref = React.useRef<HTMLDivElement>(null);
  const { width } = useResizeDetector({ handleWidth: true, targetRef: ref });
  return (
    <div ref={ref}>
      <Wrapper type={type} reverse={reverse} width={width}>
        <MediaPreview
          {...props}
          previewWrapperStyle={getReverseStyle(reverse)}
        />
      </Wrapper>
    </div>
  );
}
