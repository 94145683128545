import * as React from "react";
import { Route, Switch } from "react-router-dom";
import { IRouteConfig } from "./client";
import { isServer } from "common/helpers/envChecker";

const RenderRoutes: React.FC<{
  routes: IRouteConfig[];
  switchProps?: any;
}> = ({ routes, switchProps = {} }) => (
  <Switch {...switchProps}>
    {routes.map((route, index) => {
      const path =
        route.def &&
        (Array.isArray(route.def)
          ? route.def.map(def => def.pattern)
          : route.def.pattern);

      return (
        <Route
          key={Array.isArray(path) ? path.join("|") : path || index}
          path={path}
          exact={route.exact}
          strict={route.strict}
          render={props => {
            if (route.component) {
              const HelmetComponent: any =
                route.helmets?.[props.match.path] ?? (() => <React.Fragment />);

              if (isServer()) {
                if (route.allowSSR) {
                  return (
                    <React.Suspense>
                      <HelmetComponent {...props} />
                      <route.component {...props} routes={route.routes} />
                    </React.Suspense>
                  );
                }

                return (
                  <React.Suspense>
                    <HelmetComponent {...props} />
                  </React.Suspense>
                );
              } else {
                return (
                  <React.Suspense>
                    <HelmetComponent {...props} />
                    <route.component {...props} routes={route.routes} />
                  </React.Suspense>
                );
              }
            }
          }}
        />
      );
    })}
  </Switch>
);

export default React.memo(RenderRoutes);
