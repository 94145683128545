import React from "react";
import moment from "moment";

export const usePurchasedPickUpTime = (
  purchaseItem: Moim.Commerce.IPurchaseItem,
) => {
  // useMemo를 사용하여 memoization을 구현
  const { date, time } = React.useMemo(() => {
    if (purchaseItem?.deliveryGroup?.policy?.type !== "pickUp") {
      return { date: undefined, time: undefined };
    }

    const purchaseItemPickUpDate =
      purchaseItem.pickUpTime &&
      purchaseItem.deliveryGroup.policy.timeConfig?.activeDate
        ? moment(purchaseItem.pickUpTime).format("YYYY.MM.DD")
        : undefined;

    const purchaseItemPickUpTime =
      purchaseItem.pickUpTime &&
      purchaseItem.deliveryGroup.policy.timeConfig?.activeTime
        ? moment(purchaseItem.pickUpTime).format("LT")
        : undefined;

    return { date: purchaseItemPickUpDate, time: purchaseItemPickUpTime };
  }, [purchaseItem]);

  return { date, time };
};
