import { Svg18DownvoteC, Svg18DownvoteFillC, Svg24DownvoteFill, Svg24Downvote1, Svg18UpvoteC, Svg18UpvoteFillC, Svg24UpvoteFill, Svg24Upvote1 } from "@moim/icons";
import styled, { css } from "styled-components";
import { px2rem } from "common/helpers/rem";
import {
  B3RegularStyle,
  B4RegularStyle,
} from "common/components/designSystem/typos";








import { MEDIA_QUERY } from "common/constants/responsive";

interface IIconProps {
  isActive: boolean;
  isHovered: boolean;
  disabled?: boolean;
}

const useIconHoverScaleUpTransition = css<IIconProps>`
  transition: transform 200ms ease-in;
  @media ${MEDIA_QUERY.ONLY_DESKTOP} {
    ${props =>
      !props.disabled &&
      props.isHovered &&
      css`
        transform: scale(1.2);
      `}
  }
`;

export const Wrapper = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  ${props => props.disabled && `pointer-events: none;`}
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;

  & + & {
    margin-left: ${px2rem(8)};
  }
`;

export const LargeButtonWrapper = styled.div`
  display: flex;
  align-items: center;

  & + & {
    margin-left: ${px2rem(8)};
  }
`;

export const SmallUp = styled(Svg18UpvoteC).attrs(props => ({
  size: "xs",
  role: "button",
  iconColor: props.theme.colorV2.colorSet.grey300,
}))<IIconProps>`
  ${useIconHoverScaleUpTransition}
`;

export const SmallUpSelectedUp = styled(Svg18UpvoteFillC).attrs(
  props => ({
    size: "xs",
    role: "button",
    iconColor: props.theme.getReactionButtonElementPalette("like").color,
  }),
)<IIconProps>`
  ${useIconHoverScaleUpTransition}
`;

export const MiddleUp = styled(Svg24UpvoteFill).attrs(props => ({
  size: "xs",
  touch: 30,
  role: "button",
  iconColor: props.isActive
    ? props.theme.getReactionButtonElementPalette("like").color
    : props.theme.colorV2.colorSet.grey300,
}))<IIconProps>`
  ${useIconHoverScaleUpTransition}
`;

export const MiddleUnSelectedUp = styled(Svg24Upvote1).attrs(
  props => ({
    size: "xs",
    touch: 30,
    role: "button",
    iconColor: props.theme.colorV2.colorSet.grey300,
  }),
)<IIconProps>`
  ${useIconHoverScaleUpTransition}
`;

export const LargeUp = styled(Svg24UpvoteFill).attrs(props => ({
  size: "s",
  touch: 24,
  role: "button",
  iconColor: props.isActive
    ? props.theme.getReactionButtonElementPalette("like").color
    : props.theme.colorV2.colorSet.grey800,
}))<IIconProps>`
  ${useIconHoverScaleUpTransition}
`;

export const LargeUnSelectedUp = styled(Svg24Upvote1).attrs(
  props => ({
    size: "s",
    touch: 24,
    role: "button",
    iconColor: props.theme.colorV2.colorSet.grey800,
  }),
)<IIconProps>`
  ${useIconHoverScaleUpTransition}
`;

export const SmallDown = styled(Svg18DownvoteC).attrs(props => ({
  size: "xs",
  role: "button",
  iconColor: props.theme.colorV2.colorSet.grey300,
}))<IIconProps>`
  ${useIconHoverScaleUpTransition}
`;
export const SmallDownSelectedDown = styled(Svg18DownvoteFillC).attrs(
  props => ({
    size: "xs",
    role: "button",
    iconColor: props.theme.getReactionButtonElementPalette("dislike").color,
  }),
)<IIconProps>`
  ${useIconHoverScaleUpTransition}
`;

export const MiddleDown = styled(Svg24DownvoteFill).attrs(props => ({
  size: "xs",
  touch: 30,
  role: "button",
  iconColor: props.isActive
    ? props.theme.getReactionButtonElementPalette("dislike").color
    : props.theme.colorV2.colorSet.grey300,
}))<IIconProps>`
  ${useIconHoverScaleUpTransition}
`;

export const MiddleUnSelectedDown = styled(Svg24Downvote1).attrs(
  props => ({
    size: "xs",
    touch: 30,
    role: "button",
    iconColor: props.theme.colorV2.colorSet.grey300,
  }),
)<IIconProps>`
  ${useIconHoverScaleUpTransition}
`;

export const LargeDown = styled(Svg24DownvoteFill).attrs(props => ({
  size: "s",
  touch: 24,
  role: "button",
  iconColor: props.isActive
    ? props.theme.getReactionButtonElementPalette("dislike").color
    : props.theme.colorV2.colorSet.grey800,
}))<IIconProps>`
  ${useIconHoverScaleUpTransition}
`;

export const LargeUnSelectedDown = styled(Svg24Downvote1).attrs(
  props => ({
    size: "s",
    touch: 24,
    role: "button",
    iconColor: props.theme.colorV2.colorSet.grey800,
  }),
)<IIconProps>`
  ${useIconHoverScaleUpTransition}
`;

interface ICountProps {
  isLiked: boolean;
}
export const Count = styled.div.attrs({ role: "button" })<ICountProps>`
  transition: opacity 200ms ease-in;

  &:hover {
    opacity: 0.6;
  }

  color: ${props =>
    props.isLiked
      ? props.theme.colorV2.colorSet.grey800
      : props.theme.colorV2.colorSet.grey300};
  ${B4RegularStyle};
  line-height: 1;
`;

export const LargeCount = styled.div.attrs({ role: "button" })<ICountProps>`
  transition: opacity 200ms ease-in;

  &:hover {
    opacity: 0.6;
  }

  color: ${props => props.theme.colorV2.colorSet.grey600};
  margin-left: ${px2rem(4)};
  ${B3RegularStyle};
  line-height: 1;
`;
