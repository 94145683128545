import { useCallback } from "react";
import * as qs from "query-string";
import useRedirect from "app/common/hooks/useRedirect";
import useIsMobile from "common/hooks/useIsMobile";
import { MoimURL } from "common/helpers/url";

function useOpenDQuestShowModal() {
  const isMobile = useIsMobile();
  const redirect = useRedirect();

  return useCallback(
    (questId: Moim.Id) => {
      if (questId) {
        if (isMobile) {
          redirect(new MoimURL.QuestShow({ id: questId }).toString());
        } else {
          const query = qs.parse(location.search);
          redirect({
            pathname: location.pathname,
            search: qs.stringify({
              ...query,
              questId,
            }),
          });
        }
      }
    },
    [isMobile, redirect],
  );
}

export default useOpenDQuestShowModal;
