import { CancelToken } from "axios";
import { MoimBaseAPI } from "common/api/base";

export default class FormAPI extends MoimBaseAPI {
  public async getFormItems(
    formId: string,
    cancelToken?: CancelToken,
  ): Promise<Moim.ISingleItemResponse<Moim.Form.IFormItem[]>> {
    return (
      await this.get(`/forms/${formId}/form_items`, undefined, { cancelToken })
    ).data;
  }
  public async postFormResponseItems(
    formId: string,
    payload: {
      formResponseItems: Moim.Form.IFormResponseItemPayload[];
    },
    cancelToken?: CancelToken,
  ): Promise<Moim.ISingleItemResponse<Moim.Form.IFormResponse>> {
    return (
      await this.post(`/forms/${formId}/form_responses`, payload, {
        cancelToken,
      })
    ).data;
  }

  public async updateFormResponseItems(
    formId: string,
    formResponseId: string,
    payload: {
      formResponseItems: Moim.Form.IFormResponseItemPayload[];
    },
    cancelToken?: CancelToken,
  ): Promise<Moim.ISingleItemResponse<Moim.Form.IFormResponseItemPayload[]>> {
    return (
      await this.put(
        `/forms/${formId}/form_responses/${formResponseId}/form_response_items`,
        payload,
        {
          cancelToken,
        },
      )
    ).data;
  }

  public async getFormResponseItems(
    formId: string,
    formResponseId: string,

    cancelToken?: CancelToken,
  ): Promise<Moim.ISingleItemResponse<Moim.Form.IFormResponseItem[]>> {
    return (
      await this.get(
        `/forms/${formId}/form_responses/${formResponseId}/form_response_items`,
        undefined,
        {
          cancelToken,
        },
      )
    ).data;
  }
}
