import * as React from "react";
import styled from "styled-components";
import { px2rem } from "common/helpers/rem";
import ChipBase from "common/components/chips";
import { H10BoldStyle } from "../designSystem/typos";

export const UserProfileContainer = styled.div`
  width: fit-content;
  height: fit-content;
  padding-top: ${px2rem(4)};
`;

const OriginalAuthorMarkChip = styled(ChipBase)`
  background-color: ${props => props.theme.colorV2.colorSet.grey300};
  color: ${props => props.theme.colorV2.colorSet.white1000};
  ${H10BoldStyle}
`;

export const OriginalAuthorContainer = ({
  isOriginalAuthor,
  children,
}: React.PropsWithChildren<{
  isOriginalAuthor: boolean;
}>) => {
  if (isOriginalAuthor) {
    return (
      <OriginalAuthorMarkChip size="medium" shape="round">
        {children}
      </OriginalAuthorMarkChip>
    );
  }
  return <>{children}</>;
};

export const NonSelectableDiv = styled.div`
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;
