import * as React from "react";
import * as qs from "query-string";
import { Redirect } from "react-router";
import { replace } from "connected-react-router";
import { useActions } from "app/store";
import useIsMobile from "common/hooks/useIsMobile";
import { MoimURL } from "common/helpers/url";

import ModalView from "common/layouts/listAndModal/components/desktop/modal";
import ThreadShowModalComponent, {
  IProps,
  IPostShowModalQueryParams,
} from "./component";

const ThreadShowModal = (props: Omit<IProps, "onClose" | "isModalShow">) => {
  const { dispatchReplace } = useActions({
    dispatchReplace: replace,
  });

  const handleClose = React.useCallback(() => {
    const query = qs.parse(location.search);
    delete query.groupId;
    delete query.postId;

    dispatchReplace({
      pathname: location.pathname,
      search: qs.stringify(query),
    });
  }, [dispatchReplace]);

  return (
    <ModalView open={true} appBar={undefined} onClose={handleClose}>
      <ThreadShowModalComponent
        {...props}
        isModalShow={true}
        onClose={handleClose}
      />
    </ModalView>
  );
};

export default function ThreadShowModalContainer() {
  const isMobile = useIsMobile();
  const queryParams = qs.parse(location.search, {
    arrayFormat: "bracket",
  }) as IPostShowModalQueryParams;
  const postId = queryParams.postId;

  if (!postId) {
    return null;
  }

  if (isMobile) {
    return (
      <Redirect
        to={new MoimURL.ShowThread({ threadId: postId }).toLocation({
          query: queryParams.groupId
            ? {
                groupId: queryParams.groupId,
              }
            : undefined,
        })}
      />
    );
  }

  return <ThreadShowModal postId={postId} />;
}
