/* eslint-disable no-underscore-dangle */
import { ISSRLoadDataPayload } from "app/typings/ssr";
import { setInitialData } from "common/helpers/initialData";

export async function NFTScheduleShowLoadData({
  match,
  api,
  requestId,
}: ISSRLoadDataPayload) {
  const contractId = match.params.contractId;
  const scheduleId = match.params.scheduleId;

  if (contractId) {
    const contract = await api.nft.getContractDetail(contractId);
    setInitialData("__entities.nft.contract", requestId, contract);
  }

  if (contractId && scheduleId) {
    const schedule = await api.nft.getScheduleDetail(contractId, scheduleId);
    setInitialData("__entities.nft.schedule", requestId, schedule);
  }
}
