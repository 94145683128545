import useEntitiesFetchSelector from "./useEntitiesFetchSelector";

export default function useUserFetchSelector(requestIds: Moim.Id[]) {
  const { isLoading, entities } = useEntitiesFetchSelector({
    users: requestIds,
  });

  return {
    isLoading,
    users: entities.users ?? [],
  };
}
