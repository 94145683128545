import React from "react";
import styled from "styled-components";
import UserProfileImage from "common/components/userProfileImage";
import { Spacer } from "common/components/designSystem/spacer";
import { B3Regular, H8Bold } from "common/components/designSystem/typos";
import { useIntlShort } from "common/hooks/useIntlShort";
import useGroupTexts from "common/hooks/useGroupTexts";
import { px2rem } from "common/helpers/rem";

const Title = styled(H8Bold)`
  color: ${props => props.theme.colorV2.colorSet.grey800};
  text-align: center;
  white-space: pre-wrap;
`;

const Description = styled(B3Regular)`
  color: ${props => props.theme.colorV2.colorSet.grey500};
  width: ${px2rem(324)};
  text-align: center;
  white-space: pre-wrap;
`;

const AuthorInformation: React.FC<{
  author: Moim.User.INormalizedUser | undefined;
}> = ({ author }) => {
  const intl = useIntlShort();
  const descriptionText = useGroupTexts("no_right_read_post_description");

  return (
    <>
      <UserProfileImage
        size="l"
        src={author?.avatar_url}
        userId={author?.id}
        canOpenProfileDialog={true}
      />
      <Spacer value={14} />
      <Title>
        {intl("no_right_read_post_title", { username: author?.name })}
      </Title>
      <Spacer value={12} />
      <Description>{descriptionText.singular}</Description>
    </>
  );
};

export default AuthorInformation;
