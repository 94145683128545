import { Svg18DownarrowG } from "@moim/icons";
import styled, { css } from "styled-components";

import { px2rem } from "common/helpers/rem";
import { B3Regular } from "common/components/designSystem/typos";

export const DropdownIcon = styled(Svg18DownarrowG).attrs({
  size: "xs",
  touch: 30,
})`
  path {
    fill: var(
      --menu-text-600,
      ${props => props.theme.colorV2.colorSet.grey600}
    );
  }
`;

export const MoreIconWrapper = styled.div<{
  popoverOpen: boolean;
}>`
  width: ${px2rem(30)};
  height: ${px2rem(30)};
  border-radius: ${px2rem(2)};

  &:hover {
    background-color: ${props => props.theme.colorV2.colorSet.grey10};
  }

  ${props =>
    props.popoverOpen &&
    css`
      background-color: ${props.theme.colorV2.colorSet.grey10};
    `}
`;

export const CategoryName = styled(B3Regular)`
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
`;
