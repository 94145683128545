import * as React from "react";
import uuid from "uuid";

import { MoimURL } from "common/helpers/url";
import {
  getBatchProductSets,
  searchSellerProducts,
} from "app/actions/commerce";

// hooks
import { arrayEqual, useActions, useStoreState } from "app/store";
import useCurrentGroup from "common/hooks/useCurrentGroup";
import useRedirect from "common/hooks/useRedirect";
import { useParseItemLayout } from "../hooks";
// components
import InViewTrigger from "../../../../components/inViewTrigger";

import BlockitListLayout from "common/components/blockitListLayout";
import ProductItemCellSkeleton from "common/components/productItemCell/skeleton";
import {
  PortalRightArrow,
  PortalLeftArrow,
} from "common/components/horizontalScroll/arrows";
import ProductItemCell from "common/components/productItemCell";
import { ArrowContainer } from "common/components/blockitEditorBase/styled";
import { ProductListFilter } from "./filter";
import MoreButton from "../../../../components/moreButton";
import { Spacer } from "common/components/designSystem/spacer";

import { useCommerceAnalyticsReport } from "common/hooks/commerce/useCommerceAnalytics";
import { useIntlShort } from "common/hooks/useIntlShort";
import { useParseListElementConfig } from "common/components/blockitListLayout/hooks/useParseListElementConfig";
import {
  BlockitRendererContextProvider,
  useBlockitRendererContext,
} from "../../../../context";
import useIsMobile from "common/hooks/useIsMobile";
import { getEstimatedWidth } from "../../../../helper/srcGetter";
import { isEmpty } from "lodash";
import { BlockitFeedback } from "../../../../components/feedback";

const ProductSetPreview: React.FC<Moim.Blockit.V2.Commerce.IProductListPreviewBlock> = ({
  resourceId,
  itemLayout,
  itemLayout_web,
  listElement,
  filter,
  moreButton,
}) => {
  const intl = useIntlShort();
  const redirect = useRedirect();
  const currentGroup = useCurrentGroup();
  const [tagProductLists, setTagProductLists] = React.useState<
    string[] | undefined
  >(undefined);

  const productItemLayout = useParseItemLayout(
    "listPreview",
    itemLayout,
    itemLayout_web,
  );

  const { listElementType, maxVisibleCount } = useParseListElementConfig(
    listElement,
  );

  const portalSectionId = React.useMemo(
    () => `product-set-preview-portal-${uuid.v4()}`,
    [],
  );

  const productSet = useStoreState(state => {
    if (resourceId) {
      return state.entities.commerce_productSet[resourceId];
    }

    return null;
  });

  const { dispatchBatchGetProductSet, dispatchSearchProducts } = useActions({
    dispatchBatchGetProductSet: getBatchProductSets,
    dispatchSearchProducts: searchSellerProducts,
  });

  const handleClickViewMore = React.useCallback(() => {
    if (!resourceId) return;

    redirect(
      new MoimURL.CommerceProductSets({
        id: resourceId,
        section: "products",
      }).toString(),
    );
  }, [redirect, resourceId]);

  const handleTagSelect = React.useCallback(
    async (selectedTags?: string[][]) => {
      if (isEmpty(selectedTags)) {
        setTagProductLists(undefined);
        return;
      }

      if (productSet && currentGroup?.seller_id) {
        dispatchSearchProducts(currentGroup?.seller_id, {
          limit: maxVisibleCount,
          id: productSet.productIds,
          tagSetIds: selectedTags,
        }).then(result => {
          setTagProductLists(result.data);
        });
      }
    },
    [
      currentGroup?.seller_id,
      dispatchSearchProducts,
      maxVisibleCount,
      productSet,
    ],
  );

  const { estimatedWidth, prefetchedData } = useBlockitRendererContext();
  const isMobile = useIsMobile();

  const itemWidth = getEstimatedWidth(
    listElement,
    undefined,
    estimatedWidth,
    !isMobile,
  );

  const handleOnView = React.useCallback(async () => {
    if (!productSet && resourceId) {
      dispatchBatchGetProductSet(resourceId);
    }
  }, [productSet, resourceId, dispatchBatchGetProductSet]);

  const productIds = React.useMemo(() => {
    if (tagProductLists) {
      return tagProductLists;
    }

    const cached = resourceId
      ? prefetchedData?.productSetProductIds?.[resourceId] ?? []
      : [];

    if (!productSet) {
      if (cached.length > 0) {
        return cached;
      }
    }

    return productSet?.productIds;
  }, [
    prefetchedData?.productSetProductIds,
    productSet,
    resourceId,
    tagProductLists,
  ]);

  const products = useStoreState(
    state =>
      productIds
        ? productIds.map(
            id =>
              state.entities.commerce_product[id] as
                | Moim.Commerce.IProduct
                | undefined,
          )
        : undefined,
    arrayEqual,
  );

  const slicedProducts = React.useMemo(() => {
    const needSkipUndefined =
      productSet &&
      products &&
      products.length > 0 &&
      !products.every(b => b === undefined);

    return products
      ?.filter(pd => {
        if (!needSkipUndefined) {
          // allow undefined product
          return pd === undefined || Boolean(pd?.isDisplayed);
        }

        return Boolean(pd?.isDisplayed);
      })
      .slice(0, maxVisibleCount);
  }, [products, maxVisibleCount, productSet]);

  const reports = useCommerceAnalyticsReport();

  const itemElements = React.useMemo(() => {
    if (slicedProducts === undefined) {
      return new Array(maxVisibleCount)
        .fill(0)
        .map((_, idx) => (
          <ProductItemCellSkeleton
            key={`plp_skeleton_${idx}`}
            direction={productItemLayout?.direction}
          />
        ));
    }

    return slicedProducts.map((product, idx) => (
      <BlockitRendererContextProvider estimatedWidth={itemWidth}>
        {!product ? (
          <ProductItemCellSkeleton
            key={`plp_skeleton_${idx}`}
            direction={productItemLayout?.direction}
          />
        ) : (
          <ProductItemCell
            key={`${product.id}`}
            productId={product.id}
            block={productItemLayout}
            onProductSelect={reports.reportBlockProductListPreviewProductSelect}
            onSellerSelect={reports.reportBlockProductListPreviewSellerSelect}
            onBuyNowSelect={reports.reportBlockProductListPreviewProductBuyNow}
            onAddToCartSelect={
              reports.reportBlockProductListPreviewProductAddToCart
            }
          />
        )}
      </BlockitRendererContextProvider>
    ));
  }, [slicedProducts, maxVisibleCount, productItemLayout, itemWidth]);

  return (
    <>
      <InViewTrigger onVisible={handleOnView} />
      {filter?.tagGroups?.length ? (
        <ProductListFilter
          tagGroupIds={filter?.tagGroups}
          onChangeSelectedTags={_selectedTags => {
            const tagSetIds = _selectedTags
              ? Object.values(_selectedTags)?.filter(tags => tags.length)
              : undefined;

            handleTagSelect(tagSetIds);
          }}
        />
      ) : null}
      {listElementType === "horizontal" && (
        <ArrowContainer id={portalSectionId} onClick={handleClickViewMore} />
      )}
      {productSet && isEmpty(productSet.productIds) ? (
        <BlockitFeedback.Empty textKey="product_list_preview_empty" />
      ) : (
        <BlockitListLayout
          element={listElement}
          rightArrow={
            listElementType === "horizontal" ? PortalRightArrow : undefined
          }
          leftArrow={
            listElementType === "horizontal" ? PortalLeftArrow : undefined
          }
          arrowPortalTargetId={portalSectionId}
        >
          {itemElements}
        </BlockitListLayout>
      )}

      {moreButton?.show ? (
        <>
          <Spacer value={24} />
          <MoreButton
            text={intl("button_see_more_product")}
            onClick={handleClickViewMore}
          />
        </>
      ) : null}
    </>
  );
};

export default React.memo(ProductSetPreview);
