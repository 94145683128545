/* eslint-disable @typescript-eslint/no-empty-function */
import * as React from "react";

import { useStoreState } from "app/store";
import { Thread } from "app/typings";
import { Question as QuestionItem } from "common/components/threadV2";
import { VoteStatus } from "app/enums";
import Engage from "common/components/threadV2/components/engage";
import {
  QuestionContainer,
  QuestionWrapper,
} from "app/modules/commerce/components/productThreadList/questions/styled";
import Answers from "app/modules/commerce/components/productThreadList/questions/answers";
import { emptyLoopbackFunc } from "common/helpers/loopback";

type IProps = Thread.IThreadItemBaseProps;

export function Question({ threadId, config }: IProps) {
  const thread = useStoreState(
    state =>
      state.entities.threads[threadId] as
        | Moim.Forum.IThread<Moim.Forum.IProductReviewThreadMeta | undefined>
        | undefined,
  );

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const title = useStoreState(
    state => state.entities.users[thread?.author ?? ""]?.name,
  );

  const mediaProps = React.useMemo(() => {
    const mediaFiles = thread?.content.filter(
      content => content.type === "file",
    ) as Moim.Blockit.IFileBlock[] | undefined;
    return mediaFiles && mediaFiles.length > 0
      ? {
          fileId: mediaFiles[0].files[0].id,
          readonly: true,
          isSmallDeleteButton: true,
        }
      : undefined;
  }, [thread]);

  const contents = React.useMemo(
    () => thread?.content.filter(content => content.type === "text"),
    [thread],
  );

  const engageProps = React.useMemo(() => {
    const props:
      | React.ComponentProps<typeof Engage>
      | undefined = config.showReaction
      ? {
          type: "updown",
          disableOpenVotedUserList: true,
          // handler: handleVoteComment,
          channelId: thread?.root_id,
          threadId: thread?.id ?? threadId,
          replyId: thread?.id,
          upCount: thread?.up_vote_score ?? 0,
          downCount: thread?.down_vote_score ?? 0,
          status: thread?.vote?.type ?? VoteStatus.NONE,

          replyCount: thread?.replies_count ?? 0,
        }
      : undefined;

    return props;
  }, [config.showReaction, thread, threadId]);

  if (!thread) {
    return null;
  }

  return (
    <QuestionWrapper key={`question_${thread.id}`}>
      <QuestionContainer highlighted={false}>
        <QuestionItem
          type={"question"}
          key={`question_${threadId}`}
          questionId={thread.id}
          userId={thread.author}
          showAvatar={config.showAuthorAvatar}
          editState={{
            isEditMode: false,
            onEnter: emptyLoopbackFunc,
            onCancel: emptyLoopbackFunc,
          }}
          title={title}
          contents={contents}
          media={mediaProps}
          createdAt={thread.created_at}
          disableHoverStyle={true}
          engage={engageProps}
          onEditContent={() => {}}
        />
        <Answers
          // ref={refAnswers}
          question={thread as any}
          onClickMoreButton={emptyLoopbackFunc}
        />
      </QuestionContainer>
    </QuestionWrapper>
  );
}
