import useEntitiesFetchSelector from "./useEntitiesFetchSelector";

export default function useCoinFetchSelector(requestIds: Moim.Id[]) {
  const { isLoading, entities } = useEntitiesFetchSelector({
    community_coins: requestIds,
  });

  return {
    isLoading,
    coins: entities.community_coins ?? [],
  };
}
