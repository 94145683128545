import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
// components
import UserProfileImage from "common/components/userProfileImage";
import {
  Wrapper,
  UserWrapper,
  UserInfoWrapper,
  Username,
  StatContainer,
  StatSeparate,
  StatSpan,
} from "./styled";

import CreateDayOMeter from "common/components/createDayOMeter";
import { NativeEmojiSafeText } from "common/components/designSystem/texts";
import WithPositionChip from "common/components/withPositionChip/new";
import { useOpenProfileDialog } from "common/hooks/profileDialog";
import { useStoreState } from "app/store";
import { UserStatusTypes } from "app/enums";
import useGroupTexts from "common/hooks/useGroupTexts";

const Stats: React.FC<{
  showDate: boolean;
  showViewCount: boolean;
  showReadTime: boolean;
  createdAt: number;
  viewCount: number;
  readTime: number;
}> = ({
  showDate,
  showViewCount,
  showReadTime,
  createdAt,
  viewCount,
  readTime,
}) => {
  const intl = useIntl();
  if ((showDate && createdAt) || showReadTime || showViewCount) {
    return (
      <StatContainer>
        {showDate && createdAt ? (
          <StatSpan>
            <CreateDayOMeter givenDate={createdAt} />
          </StatSpan>
        ) : null}

        {showReadTime ? (
          <>
            {showDate && createdAt ? <StatSeparate>・</StatSeparate> : null}
            <StatSpan>
              <FormattedMessage
                id="reading_time"
                values={{ n: Math.round(readTime / 60) }}
              />
            </StatSpan>
          </>
        ) : null}

        {showViewCount ? (
          <>
            {(showDate && createdAt) || showReadTime ? (
              <StatSeparate>・</StatSeparate>
            ) : null}
            <StatSpan
              title={intl.formatNumber(viewCount, { useGrouping: true })}
            >
              <FormattedMessage
                id="post_view_count"
                values={{
                  n: intl.formatNumber(viewCount, { notation: "compact" }),
                }}
              />
            </StatSpan>
          </>
        ) : null}
      </StatContainer>
    );
  }

  return null;
};
interface IProps {
  author: Moim.Id;
  anonymous?: Moim.Forum.IAnonymousData;
  showAuthor?: boolean;
  showDate?: boolean;
  showViewCount?: boolean;
  showReadTime?: boolean;
  createdAt: number;
  followerCount?: number;
  viewCount?: number;
  readTime?: number;
}
const PostAuthor = ({
  author: authorId,
  showAuthor = true,
  showDate = true,
  showViewCount = false,
  showReadTime = false,
  anonymous,
  createdAt,
  viewCount = 0,
  readTime = 0,
}: IProps) => {
  const author: Moim.User.INormalizedUser | undefined = useStoreState(
    state => state.entities.users[authorId],
  );
  const anonymousTextKey = useGroupTexts("anonymous_member");

  const openProfileDialog = useOpenProfileDialog();

  const handleUserClick: React.MouseEventHandler = React.useCallback(
    e => {
      if (author && author.status !== UserStatusTypes.DEACTIVATED) {
        openProfileDialog(author.id, { current: e.currentTarget });
      }
    },
    [openProfileDialog, author],
  );

  const displayName = author
    ? author.name
    : Boolean(anonymous)
    ? anonymousTextKey?.singular ?? ""
    : "Unknown";

  if (showAuthor && (author || anonymous)) {
    return (
      <Wrapper>
        <UserWrapper>
          <UserProfileImage
            userId={author?.id ?? anonymous ? "anonymous" : ""}
            src={author?.avatar_url ?? ""}
            size="m"
            role={!anonymous ? "button" : undefined}
            aria-label={!anonymous ? "button" : undefined}
            onClick={handleUserClick}
            canOpenProfileDialog={!anonymous}
            isOnline={
              author
                ? author.presence === "ACTIVE"
                : Boolean(anonymous)
                ? undefined
                : false
            }
          />
          <UserInfoWrapper>
            <Username
              role={!anonymous ? "button" : undefined}
              onClick={handleUserClick}
            >
              <WithPositionChip positions={author?.positions ?? []}>
                <NativeEmojiSafeText value={displayName} />
              </WithPositionChip>
            </Username>

            <Stats
              showDate={showDate}
              showViewCount={showViewCount}
              showReadTime={showReadTime}
              createdAt={createdAt}
              viewCount={viewCount}
              readTime={readTime}
            />
          </UserInfoWrapper>
        </UserWrapper>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Stats
        showDate={showDate}
        showViewCount={showViewCount}
        showReadTime={showReadTime}
        createdAt={createdAt}
        viewCount={viewCount}
        readTime={readTime}
      />
    </Wrapper>
  );
};

export default PostAuthor;
