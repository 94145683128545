import React from "react";
import { StaticChipSelection } from "common/components/designSystem/selection";
import { FormItemHeader } from "../../../header";
import { useFormItemOptions } from "../../../hooks";
import { useLocaleText } from "common/hooks/localization/useLocaleText";
import { useIntlShort } from "common/hooks/useIntlShort";
import useIsMobile from "common/hooks/useIsMobile";
import { IRef } from "../../..";
import { FormValueContext } from "../../../context";
import { MultiSelectionBottomSheetFormItem } from "../bottomSheet/multi";

export const MultiDropdownFormItem = React.forwardRef<
  IRef,
  { item: Moim.Form.IFormItem }
>(({ item }, ref) => {
  const intl = useIntlShort();
  const isMobile = useIsMobile();

  const { onChange, values, errors } = React.useContext(FormValueContext);
  const error = errors[item.id];
  const currentValue = values[item.id];
  const title = useLocaleText(item, "title");
  const placeholder = useLocaleText(item, "placeholder");
  const options = useFormItemOptions(item.options);
  const chipOptions = React.useMemo(
    () =>
      options.map(option => ({
        ...option,
        chipColor: "",
      })),
    [options],
  );

  React.useImperativeHandle(ref, () => ({
    validate: () => {
      if (
        item.isRequired &&
        (Array.isArray(currentValue) ? !currentValue.length : true)
      ) {
        return { [item.id]: intl("error_form_question_required") };
      }
      if (item.maxCount !== undefined && currentValue?.length > item.maxCount) {
        return {
          [item.id]: intl("error_options_max_count", {
            max_count: item.maxCount,
          }),
        };
      }
      return { [item.id]: undefined };
    },
  }));

  return (
    <>
      <FormItemHeader item={item} />
      {!isMobile ? (
        <StaticChipSelection
          isMultiple
          state={error ? "error" : "normal"}
          options={chipOptions}
          selected={currentValue}
          useSearch={true}
          placeholder={placeholder}
          onChange={value => {
            onChange(
              item.id,
              Array.isArray(value) ? value.map(v => v.value) : currentValue,
            );
          }}
          size="l"
          reasonText={error}
        />
      ) : (
        <MultiSelectionBottomSheetFormItem
          item={item}
          title={title}
          placeholder={placeholder}
          currentValue={currentValue}
          error={error}
          chipOptions={chipOptions}
          onChange={onChange}
        />
      )}
    </>
  );
});
