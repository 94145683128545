import React from "react";
import styled from "styled-components";
import { B4Regular } from "common/components/designSystem/typos";
import { px2rem } from "common/helpers/rem";
import useGroupTexts from "common/hooks/useGroupTexts";

const NoticeBox = styled(B4Regular)`
  width: 100%;
  padding: ${px2rem(12)};
  background-color: ${props => props.theme.colorV2.colorSet.grey10};
  border-radius: ${px2rem(4)};
  color: ${props => props.theme.colorV2.colorSet.grey300};
  white-space: pre-wrap;
`;

const Notice: React.FC = () => {
  const noticeText = useGroupTexts("unlock_content_notice_content");
  return <NoticeBox>{noticeText.singular}</NoticeBox>;
};

export default Notice;
