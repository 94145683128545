import React from "react";
import styled from "styled-components";
import GlobalPhoneInputForm from "common/components/globalPhoneInputForm";
import { FormItemHeader } from "../header";
import { useLocaleText } from "common/hooks/localization/useLocaleText";
import { FormValueContext } from "../context";
import { px2rem } from "common/helpers/rem";
import { IRef } from "..";
import { validatePhone } from "../helpers";
import { useIntlShort } from "common/hooks/useIntlShort";

const InputWrapper = styled.div`
  padding: 0 ${px2rem(16)};
`;

export const PhoneFormItem = React.forwardRef<
  IRef,
  { item: Moim.Form.IFormItem }
>(({ item }, ref) => {
  const intl = useIntlShort();
  const { onChange, values, errors } = React.useContext(FormValueContext);
  const placeholder = useLocaleText(item, "placeholder");
  const currentValue = values[item.id];
  const error = errors[item.id];
  const handleChange = React.useCallback(
    (cCode: string | null, number: string) => {
      onChange(item.id, {
        countryCode: cCode || undefined,
        nationalNumber: number,
      });
    },
    [onChange, item],
  );
  React.useImperativeHandle(ref, () => ({
    validate: () => ({
      [item.id]:
        item.isRequired &&
        (!currentValue?.countryCode || !currentValue?.nationalNumber)
          ? intl("error_form_question_required")
          : validatePhone({
              intl,
              countryCode: currentValue?.countryCode,
              nationalNumber: currentValue?.nationalNumber,
            }),
    }),
  }));
  return (
    <>
      <FormItemHeader item={item} />
      <InputWrapper>
        <GlobalPhoneInputForm
          size="l"
          countryCode={currentValue?.countryCode}
          phone={currentValue?.nationalNumber}
          inputPlaceholder={placeholder}
          inputHelperText={error}
          inputStatus={error ? "Error" : "Inactive"}
          onChange={handleChange}
        />
      </InputWrapper>
    </>
  );
});
