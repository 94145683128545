import React from "react";
import styled, { css } from "styled-components";
import { FormItemHeader } from "../header";
import { useIntlShort } from "common/hooks/useIntlShort";
import { FormValueContext } from "../context";
import { IRef } from "..";
import { px2rem } from "common/helpers/rem";
import { TagSet } from "common/components/horizontalTagList.v2/components/tagSet/styled";
import { AddEntities } from "app/actions/entity";
import { useActions } from "app/store";
import { batchTagGroupDataHandler } from "common/helpers/batchService";
import { B4Regular } from "common/components/designSystem/typos";

const TagList = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: ${px2rem(8)};
  padding: ${px2rem(8)} ${px2rem(16)};
`;

const Error = styled(B4Regular)`
  padding: 0 ${px2rem(16)};
  color: ${props => props.theme.color.red};
`;
const StyledTagSet = styled(TagSet)`
  ${props =>
    props.isSelected &&
    css`
      background-color: ${props => props.theme.colorV2.primary.main};
      span {
        color: ${props => props.theme.colorV2.colorSet.fog800} !important;
      }
    `}
`;
export const UserTagGroupFormItem = React.forwardRef<
  IRef,
  { item: Moim.Form.IFormItem }
>(({ item }, ref) => {
  const intl = useIntlShort();
  const { onChange, values, errors } = React.useContext(FormValueContext);
  const currentValue = values[item.id] ?? [];
  const error = errors[item.id];

  React.useImperativeHandle(ref, () => ({
    validate: () => {
      if (item.isRequired && !currentValue?.length) {
        return { [item.id]: intl("error_form_question_required") };
      }
      if (item.maxCount !== undefined && currentValue?.length > item.maxCount) {
        return {
          [item.id]: intl("error_options_max_count", {
            max_count: item.maxCount,
          }),
        };
      }
      return { [item.id]: undefined };
    },
  }));

  const [tagGroups, setTagGroups] = React.useState<Moim.TagSet.ITagGroup[]>([]);

  const { dispatchAddEntities } = useActions({
    dispatchAddEntities: AddEntities,
  });
  React.useEffect(() => {
    if (item.tagGroupId) {
      batchTagGroupDataHandler([item.tagGroupId]).then(result => {
        setTagGroups(result);

        dispatchAddEntities({
          tagset: result.reduce<Moim.TagSet.INormalizedTagItem>(
            (result, current) => {
              current.items.forEach(item => {
                result[item.id] = item;
              });
              return result;
            },
            {},
          ),
        });
      });
    }
  }, [item.tagGroupId]);

  return (
    <>
      <FormItemHeader item={item} />
      {Boolean(error) && <Error>{error}</Error>}
      <TagList>
        {tagGroups[0]?.items.map(tag => {
          const key = tag.id;
          const labelText = tag.value;
          const selected = currentValue?.includes(key);
          return (
            <div>
              <StyledTagSet
                id={key}
                isSelected={selected}
                onClick={() => {
                  if (selected) {
                    onChange(
                      item.id,
                      currentValue?.filter(
                        selectedTag => selectedTag !== tag.id,
                      ),
                    );
                  } else {
                    onChange(item.id, [...currentValue, tag.id]);
                  }
                }}
              >
                <span>{labelText}</span>
              </StyledTagSet>
            </div>
          );
        })}
      </TagList>
    </>
  );
});
