import styled from "styled-components";

export const Wrapper = styled.div<{
  width?: number;
  height?: number;
}>`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 0;
  padding-top: ${props =>
    props.width && props.height
      ? 100 * Math.fround(props.height / props.width)
      : 56}%;
`;

export const LoaderContainer = styled.div<{
  isDisplay: boolean;
}>`
  opacity: ${props => (props.isDisplay ? 1 : 0)};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
`;

export const IframeWrapper = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${props => props.theme.zIndexes.default};

  iframe {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    background: transparent;
  }
`;
