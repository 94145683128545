import * as React from "react";

import useIsCurrentGroupVisibleInTopNavi from "../../hooks/useIsCurrentGroupVisible";
import useCurrentHubGroup from "common/hooks/useCurrentHubGroup";
import useMainPanelRefresh from "common/hooks/mainPanelRefresh";
import { InfoAnchorWrapper, InfoLinkWrapper } from "./styled";
import { AnalyticsClass } from "common/helpers/analytics/analytics";
import { MoimURL } from "common/helpers/url";

const CoverAnchorWrapper: React.FC<React.PropsWithChildren<{
  logoHeight?: number;
}>> = ({ logoHeight, children }) => {
  const visibleCurrentGroup = useIsCurrentGroupVisibleInTopNavi();
  const parentGroup = useCurrentHubGroup();
  const mainPanelRefresh = useMainPanelRefresh();

  return !visibleCurrentGroup && parentGroup ? (
    <InfoAnchorWrapper
      sizeHeight={logoHeight}
      href={parentGroup.url}
      onClick={() => {
        AnalyticsClass.getInstance().channelListParentLogoSelect();
        mainPanelRefresh.doRefresh();
      }}
    >
      {children}
    </InfoAnchorWrapper>
  ) : (
    <InfoLinkWrapper
      sizeHeight={logoHeight}
      to={MoimURL.MoimAppHome.toString()}
      aria-label="app logo"
      onClick={() => {
        AnalyticsClass.getInstance().channelListParentLogoSelect();
        mainPanelRefresh.doRefresh();
      }}
    >
      {children}
    </InfoLinkWrapper>
  );
};

export default CoverAnchorWrapper;
