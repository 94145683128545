export const convertMediaBlocksToCarouselBlock = (
  mediaBlocks: Moim.Blockit.V2.IImageBlock[],
): Pick<Moim.Blockit.V2.ICarouselBlock, "images" | "style"> => {
  return {
    images: mediaBlocks.map((mediaBlock) => ({
      width: 1920,
      height: 1920,
      src: mediaBlock.src,
      srcSet: mediaBlock.srcSet,
    })),
    style: {
      width: 1920,
      height: 1920,
      showBottomIndicate: true,
      showSideArrowButton: false,
    },
  };
};
