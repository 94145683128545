import * as React from "react";
import styled from "styled-components";

import FallbackImage from "common/components/fallbackImage";
import { ThreadThumbnailWrapper } from "common/components/thread/components/wrapper/thumbnail";

interface IProps {
  thumbnail?: Moim.Forum.IThumbnail;
  ratio?: string;
  radius?: number;
  isVideo?: boolean;
  position?: "left" | "right" | "top" | "bottom";
}

const StyledThreadThumbnailWrapper = styled(ThreadThumbnailWrapper)`
  overflow: hidden;
`;
const StyledImage = styled.img.attrs<IProps>({ loading: "lazy", alt: "" })`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const FallbackImageWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  img.logo {
    height: initial !important;
  }
`;

function Image({ thumbnail, ratio, radius, isVideo }: IProps) {
  return (
    <StyledThreadThumbnailWrapper
      ratio={ratio}
      thumbnail={thumbnail}
      isVideo={isVideo}
      radius={radius}
    >
      {src =>
        src ? (
          <StyledImage className="review-preview-image" src={src} />
        ) : (
          <FallbackImageWrapper className="review-preview-image">
            <FallbackImage type="postItem" />
          </FallbackImageWrapper>
        )
      }
    </StyledThreadThumbnailWrapper>
  );
}

export default React.memo(Image);
