import React from "react";
import { useStoreState } from "app/store";
import useCurrentUser from "common/hooks/useCurrentUser";

import { directMessagesSelector } from "app/selectors/directMessage";

import DirectMessageList from "app/modules/navigationPanel/components/directMessageList";
import { DmWrapper } from "./styled";

const DirectMessageListElement = () => {
  const currentUser = useCurrentUser();
  const { dmChannelList } = useStoreState(state => ({
    dmChannelList: directMessagesSelector(state).data,
  }));

  return (
    <>
      {Boolean(currentUser) && (
        <DmWrapper>
          <DirectMessageList directMessages={dmChannelList} />
        </DmWrapper>
      )}
    </>
  );
};

export default DirectMessageListElement;
