import { Svg18UparrowG } from "@moim/icons";
import React from "react";
import { CSSTransition } from "react-transition-group";
import styled, { css } from "styled-components";

import { H7Bold } from "common/components/designSystem/typos";
import { px2rem } from "common/helpers/rem";
import { FormItemRenderer, IRef } from "..";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 ${px2rem(6)} 0 ${px2rem(16)};
  gap: ${px2rem(4)};
`;

const Title = styled(H7Bold)`
  padding: ${px2rem(10)} 0;
  color: ${props => props.theme.colorV2.colorSet.grey800};
`;

const CollapseButton = styled(Svg18UparrowG).attrs(props => ({
  size: "xs",
  touch: 42,
  iconColor: props.theme.colorV2.colorSet.grey300,
}))<{ isCollapsed: boolean }>`
  transition: transform 100ms cubic-bezier(0.4, 0, 0.2, 1);
  ${props =>
    props.isCollapsed
      ? css`
          transform: rotate(180deg);
        `
      : css`
          transform: rotate(0deg);
        `}
`;

const List = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${px2rem(16)};
  padding-top: ${px2rem(8)};
`;

export const SectionFormItem = React.forwardRef<
  IRef,
  { item: Moim.Form.IFormItem }
>(({ item }, ref) => {
  const refs = React.useRef<IRef[]>([]);
  const [isCollapsed, setIsCollapsed] = React.useState(false);

  React.useImperativeHandle(ref, () => ({
    validate: () =>
      refs.current.reduce<Record<Moim.Id, any>>((result, ref) => {
        return { ...result, ...ref.validate() };
      }, {}),
  }));

  return (
    <Wrapper>
      <TitleWrapper
        role="button"
        onClick={() => setIsCollapsed(state => !state)}
      >
        <Title>{item.title}</Title>
        <CollapseButton isCollapsed={isCollapsed} />
      </TitleWrapper>
      <CSSTransition
        in={!isCollapsed}
        timeout={300}
        classNames="bodyAnim"
        unmountOnExit={true}
      >
        <List>
          {item.items?.map((item, index) => (
            <div>
              <FormItemRenderer
                ref={el => {
                  if (el) {
                    refs.current[index] = el;
                  }
                }}
                key={item.id}
                item={item}
              />
            </div>
          ))}
        </List>
      </CSSTransition>
    </Wrapper>
  );
});
