import { useCallback } from "react";

import { useActions } from "app/store";
import { ActionCreators } from "./actions";

export function useOpenGlobalAlertDialog(initialParams?: {
  content: string;
  buttons?: {
    text: string;

    textColor?: string;
  }[];
}) {
  const { open } = useActions({ open: ActionCreators.open });

  return useCallback(
    async (params?: {
      content: string;
      buttons?: {
        text: string;

        textColor?: string;
      }[];
    }) => {
      const payload = params ?? initialParams;

      if (payload) {
        open(payload);
      }
    },
    [initialParams, open],
  );
}

export function useCloseGlobalAlertDialog() {
  const { close } = useActions({
    close: ActionCreators.close,
  });

  return { close };
}
