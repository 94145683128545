import React from "react";
import useRedirect from "common/hooks/useRedirect";
import { MoimURL } from "common/helpers/url";

import useSideNavigationPanel from "common/hooks/useSideNavigationPanel";

import { CategoryItem } from "./styled";

interface IProps {
  category: Moim.Commerce.ICategory;
  clickable: boolean;
  onClose?: () => void;
}

const CommerceCategoryItem = ({ category, clickable, onClose }: IProps) => {
  const redirect = useRedirect();
  const { collapseSideNavigation } = useSideNavigationPanel();

  const handleClick = React.useCallback(() => {
    if (!category?.parentId) return;
    if (clickable) {
      redirect(
        new MoimURL.CommerceCategories({
          id: category.id,
          section: "products",
        }).toString(),
      );
      if (onClose) {
        onClose();
      }
      collapseSideNavigation();
    }
  }, [
    category?.id,
    category?.parentId,
    clickable,
    collapseSideNavigation,
    onClose,
  ]);

  return (
    <CategoryItem clickable={clickable} onClick={handleClick}>
      {category.name}
    </CategoryItem>
  );
};

export default CommerceCategoryItem;
