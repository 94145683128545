import {
  Svg18Bookmark1,
  Svg18BookmarkFill,
  Svg24Bookmark1,
  Svg24BookmarkFill,
} from "@moim/icons";
import { useSingleLineStyle } from "common/components/designSystem/styles";
import { B3Regular, B4Regular } from "common/components/designSystem/typos";
import { px2rem } from "common/helpers/rem";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";

const SmallWrapper = styled(B4Regular)`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colorV2.colorSet.grey400};
`;

const LargeWrapper = styled(B3Regular)`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colorV2.colorSet.grey600};
`;

const SmallUnBookmarkedIcon = styled(Svg18Bookmark1).attrs((props) => ({
  size: "xs",
  iconColor: "currentColor",
}))``;

const LargeUnBookmarkedIcon = styled(Svg24Bookmark1).attrs((props) => ({
  size: "s",
  touch: 24,
  iconColor: "currentColor",
}))``;

const SmallBookmarkedIcon = styled(Svg18BookmarkFill).attrs((props) => ({
  size: "xs",
  iconColor: "currentColor",
}))``;

const LargeBookmarkedIcon = styled(Svg24BookmarkFill).attrs((props) => ({
  size: "s",
  touch: 24,
  iconColor: "currentColor",
}))``;

const Label = styled.span`
  margin-left: ${px2rem(2)};
  ${useSingleLineStyle};
`;

export function SmallBookmark({
  isBookmarked,
  showLabel,
}: {
  isBookmarked?: boolean;
  showLabel?: boolean;
}) {
  return (
    <SmallWrapper>
      {isBookmarked ? <SmallBookmarkedIcon /> : <SmallUnBookmarkedIcon />}
      {showLabel && (
        <Label>
          <FormattedMessage id="button_feed_bookmark" />
        </Label>
      )}
    </SmallWrapper>
  );
}

export function LargeBookmark({
  isBookmarked,
  showLabel,
}: {
  isBookmarked?: boolean;
  showLabel?: boolean;
}) {
  return (
    <LargeWrapper>
      {isBookmarked ? <LargeBookmarkedIcon /> : <LargeUnBookmarkedIcon />}
      {showLabel && (
        <Label>
          <FormattedMessage id="button_feed_bookmark" />
        </Label>
      )}
    </LargeWrapper>
  );
}
