import * as React from "react";
import { FlattenInterpolation } from "styled-components";
import AppBar from "common/components/appBar";
import { Header, Wrapper } from "./styled";

interface IProps extends React.PropsWithChildren<{}> {
  title: React.ReactNode;
  actionButton?: React.ReactNode;
  leftElement?: React.ReactNode;
  headerWrapperStyle?: FlattenInterpolation<any>;
}

export default function BottomSheetHeader({
  title,
  leftElement,
  actionButton,
  headerWrapperStyle,
}: IProps) {
  return (
    <Wrapper>
      <Header overrideStyle={headerWrapperStyle}>
        <AppBar
          titleElement={title}
          titleAlignment="Center"
          rightButton={actionButton}
          leftButton={leftElement}
        />
      </Header>
    </Wrapper>
  );
}
