// vendor
import { PostCellWrapper } from "./styled";
import { useRedirectPostShowModal } from "app/modules/postShow/modal/hooks";
import { useStoreState } from "app/store";
import { Thread } from "app/typings";
// component
import { ThreadRenderer } from "common/components/thread";
import { MoimURL } from "common/helpers/url";
import useRedirect from "common/hooks/useRedirect";
import * as React from "react";

export interface IProps {
  thread: Moim.Forum.IThread;
  config?: Thread.IThreadItemConfig;
}

const PostCell: React.FC<IProps> = (props) => {
  const { thread, config } = props;
  const redirect = useRedirect();
  const { threadStat } = useStoreState((state) => ({
    threadStat: state.entities.stats[thread.id],
  }));
  const redirectPostShowModal = useRedirectPostShowModal({
    postId: thread.id,
    groupId: thread.groupId,
  });

  const handleClick: React.MouseEventHandler<HTMLDivElement> =
    React.useCallback(
      (e) => {
        if (thread.type.startsWith("product")) {
          e.preventDefault();
          switch (thread.type) {
            case "productReview": {
              redirect(
                new MoimURL.CommerceProductShowReview({
                  id: thread.parent_id,
                  threadId: thread.id,
                }).toString(),
              );
              break;
            }
            case "productQuestion": {
              redirect(
                new MoimURL.CommerceProductShowQnA({
                  id: thread.parent_id,
                  threadId: thread.id,
                }).toString(),
              );
              break;
            }
          }
        } else {
          redirectPostShowModal();
        }
      },
      [
        redirect,
        thread.id,
        thread.meta,
        thread.parent_id,
        thread.type,
        redirectPostShowModal,
      ],
    );

  const stat = React.useMemo(
    () => ({
      count: threadStat?.count,
      isUnread: threadStat?.has_new,
    }),
    [threadStat?.count, threadStat?.has_new],
  );

  return (
    <PostCellWrapper onClick={handleClick}>
      <ThreadRenderer threadId={thread.id} config={config ?? {}} stat={stat} />
    </PostCellWrapper>
  );
};

export default React.memo(PostCell);
