import * as React from "react";
import styled from "styled-components";
import useGroupTexts from "common/hooks/useGroupTexts";
import {
  H10BoldStyle,
  H8BoldStyle,
} from "common/components/designSystem/typos";
import CurrencyFormatter from "common/components/currencyFormatter";
import { StyledTeamBuyingButton } from "./styled";
import { useSingleLineStyle } from "common/components/designSystem/styles";

interface IProps {
  productStatus: Moim.Commerce.PRODUCT_STATUS;
  className?: string;
  buttonSize?: Moim.DesignSystem.Size;
  productStockCount?: number;
  teamPrice?: Moim.Commerce.IProductPrice;
  alternateButtonText?: React.ReactNode;
  disabled?: boolean;
  onClick: React.MouseEventHandler<HTMLElement>;
}

const TeamBuyingButton: React.FC<IProps> = ({
  productStatus,
  className,
  buttonSize = "l",
  productStockCount,
  teamPrice,
  alternateButtonText,
  disabled,
  onClick,
}) => {
  const teamBuyingButtonText = useGroupTexts("button_buy_team");

  const buttonDisableStatus =
    productStatus !== "onSale" ||
    (productStockCount !== undefined && productStockCount === 0) ||
    disabled;

  return (
    <StyledTeamBuyingButton
      className={className}
      size={buttonSize}
      disabled={buttonDisableStatus}
      onClick={onClick}
    >
      {alternateButtonText !== undefined ? (
        alternateButtonText
      ) : (
        <div className="text">
          <div>{teamBuyingButtonText?.singular}</div>
          <CurrencyFormatter
            currency={teamPrice?.currency}
            value={teamPrice?.value}
          />
        </div>
      )}
    </StyledTeamBuyingButton>
  );
};

export const ProductShowTeamBuyingButton = styled(TeamBuyingButton)`
  flex: 1;
  padding: 0;
  ${H8BoldStyle}
`;
export const ProductItemTeamBuyingButton = styled(TeamBuyingButton)`
  width: 100%;
  ${useSingleLineStyle}
  ${H10BoldStyle}
`;
