import * as React from "react";
import { css } from "styled-components";
import ImageHolder from "common/components/lazyBlurHashImage";
import { VideoCell } from "./videoItem";
import ListWrapper from "./listWrapper";

import { useActions } from "app/store";
import { ActionCreators as ImageBrochureActionCreators } from "common/components/imageBrochure/actions";

const imageWrapperStyle = css`
  width: 100%;
  padding-top: 100%;
`;

const imageStyle = css`
  object-fit: cover;
`;

interface IProps {
  parentId: Moim.Id;
  images?: (
    | (Moim.IImage & { type: "image" })
    | { type: "video"; fileId: string }
  )[];
  altPrefix?: string;
}

const MultiMedia: React.FC<IProps> = ({ parentId, images, altPrefix }) => {
  const { openImageBrochure } = useActions({
    openImageBrochure: ImageBrochureActionCreators.openSrcImageBrochure,
  });

  if (!images || images.length === 0) return null;
  return (
    <ListWrapper>
      {images.map(img => {
        switch (img.type) {
          case "image":
            return (
              <ImageHolder
                data-role={`brochure-thumbnail-${parentId}`}
                data-file-id={img.fileId}
                src={img.url}
                alt={`${altPrefix ?? ""} ${img.url.split("/").pop()}`.trim()}
                srcSet={img.srcSet}
                fallBackSrc={img.fallbackSrc ?? img.url}
                blurHash={img.blurhash ?? img.blur_hash}
                overrideWrapperStyle={imageWrapperStyle}
                overrideIMGStyle={imageStyle}
                width={1}
                height={1}
                onClick={e => {
                  e.currentTarget.dataset.brochureSelected = "true";
                  const url = e.currentTarget.src;
                  openImageBrochure(url);
                }}
              />
            );

          case "video":
            return <VideoCell fileId={img.fileId} parentId={parentId} />;
          default:
            return null;
        }
      })}
    </ListWrapper>
  );
};

export default React.memo(MultiMedia);
