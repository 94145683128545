import { updateActionsForRefresh } from "app/actions/boot";
import { getInitialGroupId } from "app/actions/group";

import {
  fetchPreloadCachedData,
  IAppState,
  initialStateGenerator,
} from "app/rootReducer";
import { createAppStore, IAppStore } from "app/store";
import { isBrowser, isHubDomain } from "common/helpers/envChecker";
import selectHubMoimId from "common/helpers/selectHubMoimId";
import { History } from "history";
import { getMoimClientId } from "./helpers/moimClientId";
import {
  isRedirectFromExecutionRemit,
  isRedirectFromMint,
  isRedirectFromOAuth,
  isRedirectFromPayment,
  isRedirectFromPlugin,
} from "./helpers/redirectFromChecker";
import { migrationStoreState } from "./helpers/storeState";
import * as ExpiredInMemoryHelper from "common/helpers/expiredInMemoryHelper";
import { GROUP_ID, HUB_GROUP_ID } from "common/constants/keys";
import { compatibleOAuthV2 } from "./helpers/compatibleOAuthV2";
import { getCommunity } from "app/actions/community";
import { getCommunityCoins } from "app/actions/community/coin";
import { storeOnboardingTokenToCookie } from "./helpers/handleOnboardingWizardToken";
import { getComponentLayout } from "app/actions/componentLayout";

export async function initializeStore(history: History) {
  if (isHubDomain()) {
    return createAppStore(history);
  }
}

export async function bootStore(history: History): Promise<IAppStore> {
  try {
    let storedStore: IAppState | undefined | null;
    const cachedStoreName = isBrowser()
      ? localStorage.getItem("CURRENT_STORE_NAME")
      : undefined;
    let moimId: string | null = null;

    if (cachedStoreName) {
      moimId = await getMoimClientId(cachedStoreName);
    }

    await fetchPreloadCachedData();

    // eslint-disable-next-line @typescript-eslint/await-thenable
    const initStoreState = initialStateGenerator();
    const store = createAppStore(
      history,
      migrationStoreState(storedStore || initStoreState),
    );

    /**
     * temporary disable store cache
     */
    // subscribeStoreForCache(store);

    // 현재 state에 groupId , hubGroupId가 없으면 initialize
    const {
      currentGroupId: initialGroupId,
      currentHubGroupId: initialHubGroupId,
    } = store.getState().app;

    if (!initialGroupId || !initialHubGroupId) {
      await store.dispatch(getInitialGroupId(moimId));
    }

    const { currentGroupId, currentHubGroupId } = store.getState().app;

    if (currentGroupId) {
      const currentGroup = store.getState().entities.groups[currentGroupId];

      if (
        currentGroup &&
        (currentGroup.is_hub ||
          currentGroup.navigation_config?.isIndependentChild)
      ) {
        store.dispatch(
          getComponentLayout({
            id: currentGroupId,
            type: "top-navigation",
            platform: "web",
          }),
        );
        store.dispatch(
          getComponentLayout({
            id: currentGroupId,
            type: "top-navigation",
            platform: "mobile",
          }),
        );
      } else if (currentHubGroupId) {
        store.dispatch(
          getComponentLayout({
            id: currentHubGroupId,
            type: "top-navigation-for-child",
            platform: "web",
          }),
        );
        store.dispatch(
          getComponentLayout({
            id: currentHubGroupId,
            type: "top-navigation-for-child",
            platform: "mobile",
          }),
        );
      }
    }

    if (currentGroupId && currentHubGroupId) {
      // in memory helper에서 GroupId hubGroupId가 없으면 세팅
      if (!ExpiredInMemoryHelper.get(GROUP_ID)) {
        ExpiredInMemoryHelper.set(GROUP_ID, currentGroupId, -1);
      }

      if (!ExpiredInMemoryHelper.get(HUB_GROUP_ID)) {
        ExpiredInMemoryHelper.set(HUB_GROUP_ID, currentHubGroupId, -1);
      }
    }

    await isRedirectFromOAuth(store.dispatch, store.getState());
    isRedirectFromPlugin(store.dispatch);
    isRedirectFromPayment(store.dispatch);
    isRedirectFromExecutionRemit(store.dispatch);
    isRedirectFromMint(store.dispatch);

    storeOnboardingTokenToCookie();

    if (currentHubGroupId) {
      await compatibleOAuthV2(currentHubGroupId);
    }

    return store;
  } catch (error) {
    throw error;
  }
}

export async function bootData(store: IAppStore) {
  try {
    const state = store.getState();
    const cgId = state.app.currentGroupId;

    const currentGroup = cgId ? state.entities.groups[cgId] : undefined;
    const hubGroupId = selectHubMoimId(state);

    if (!hubGroupId) {
      return;
    }

    await Promise.all([
      state.app.currentGroupId
        ? store.dispatch(getCommunity({ id: state.app.currentGroupId }))
        : undefined,
      store.dispatch(getCommunity({ id: hubGroupId })),
      currentGroup?.config.enableCoin
        ? store.dispatch(getCommunityCoins())
        : undefined,

      store.dispatch(updateActionsForRefresh()),
    ]);

    return store;
  } catch (error) {
    throw error;
  }
}
