import styled, { FlattenInterpolation } from "styled-components";
import { px2rem } from "common/helpers/rem";

export const Section = styled.div``;

export const SectionContents = styled.div``;

export const SelectableItemWrapper = styled.div.attrs({ role: "button" })<{
  selectableItemStyle?: FlattenInterpolation<any>;
}>`
  padding: 0 ${px2rem(16)};
  ${props => props.selectableItemStyle}
`;
export const SubTitleWrapper = styled.div`
  display: flex;

  & > * + * {
    &::before {
      content: "･";
      display: inline;
      margin: 0 ${px2rem(4)};
    }
  }
`;
