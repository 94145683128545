import * as React from "react";
import useIsMobile from "common/hooks/useIsMobile";

import { useNativeSecondaryView } from "common/hooks/useSecondaryView";
import { useVisibleTopNavigation } from "app/modules/layout/components/controller/hooks";
import { useStoreState } from "app/store";
import useRedirect from "common/hooks/useRedirect";
import { IProps } from "..";
import {
  AppBarLeftButtonWrapper,
  AppBarRightButtonWrapper,
  BackIconButton,
  CloseButton,
} from "../../../styled";
import { TopBannerContext } from "common/components/topBanner/context";
import { NativeMemoryHistoryContext } from "app/modules/SecondaryHistory";
// helper
import { MoimURL } from "common/helpers/url";

export type IHookProps = ReturnType<typeof useProps>;

export function useProps(props: IProps) {
  const { appBar, renderCloseButton } = props;
  const { leftButton, rightButton, ...appBarRestProps } = appBar;
  const refWrapper = React.useRef<HTMLDivElement>(null);
  const [appBarTopPosition, setAppBarTopPosition] = React.useState(0);
  const isMobile = useIsMobile();
  const { close, goBack } = useNativeSecondaryView();
  const visibleTopNavigation = useVisibleTopNavigation();
  const [topBannerContext] = React.useContext(TopBannerContext);
  const history = React.useContext(NativeMemoryHistoryContext);
  const locationPathname = history ? history.location.pathname : "";
  const appHistory = useStoreState(state => state.app.history);
  const appHistoryLength = appHistory.locations.length;

  const mainRouterRedirect = useRedirect();

  const hasBackButton = React.useMemo(() => {
    const isProfilePage = Boolean(
      MoimURL.ProfileShare.matchExact(locationPathname),
    );
    const isMembersPage = Boolean(
      MoimURL.MoimMembers.matchExact(locationPathname)?.isExact,
    );
    const isFirstEntry = history ? history.entries.length < 3 : false;

    return !isFirstEntry && !isMembersPage && !isProfilePage;
  }, [history]);

  const handleCloseClick = React.useCallback(() => {
    if (!isMobile) {
      close();
    } else {
      if (appHistoryLength > 3) {
        window.history.back();
      } else {
        mainRouterRedirect(new MoimURL.MoimAppHome().toString());
      }
    }
  }, [isMobile, appHistoryLength]);

  const closeButtonElement = React.useMemo(
    () =>
      renderCloseButton?.(handleCloseClick) ??
      (isMobile ? (
        appHistoryLength > 3 ? (
          <BackIconButton size="s" touch={24} onClick={handleCloseClick} />
        ) : (
          <CloseButton size="s" touch={24} onClick={handleCloseClick} />
        )
      ) : (
        <CloseButton size="s" touch={24} onClick={handleCloseClick} />
      )),
    [renderCloseButton, handleCloseClick, isMobile, appHistoryLength],
  );

  const backButtonElement = React.useMemo(
    () =>
      hasBackButton && <BackIconButton size="s" touch={24} onClick={goBack} />,
    [goBack, hasBackButton],
  );

  const defaultLeftElement = React.useMemo(
    () => (isMobile ? closeButtonElement : backButtonElement),
    [isMobile, closeButtonElement, backButtonElement],
  );

  const defaultRightElement = React.useMemo(
    () => !isMobile && closeButtonElement,
    [isMobile, closeButtonElement],
  );

  const leftButtonElement = React.useMemo(
    () =>
      (defaultLeftElement || leftButton) && (
        <AppBarLeftButtonWrapper>
          {[defaultLeftElement, leftButton]}
        </AppBarLeftButtonWrapper>
      ),
    [defaultLeftElement, leftButton],
  );

  const rightButtonElement = React.useMemo(
    () =>
      (rightButton || defaultRightElement) && (
        <AppBarRightButtonWrapper>
          {[rightButton, defaultRightElement]}
        </AppBarRightButtonWrapper>
      ),
    [defaultRightElement, rightButton],
  );

  const calcAppBarOffset = React.useCallback(() => {
    requestAnimationFrame(() => {
      if (refWrapper.current) {
        setAppBarTopPosition(
          Math.max(
            refWrapper.current.getBoundingClientRect().y -
              Math.max(document.documentElement.getBoundingClientRect().y, 0),
            0,
          ),
        );
      }
    });
  }, []);

  React.useLayoutEffect(() => {
    calcAppBarOffset();
  }, [visibleTopNavigation, topBannerContext.currentVisibleState]);

  React.useEffect(() => {
    window.addEventListener("scroll", calcAppBarOffset, { passive: true });

    return () => {
      window.removeEventListener("scroll", calcAppBarOffset);
    };
  }, []);

  return {
    ...props,
    refWrapper,
    isMobile,
    appBarTopPosition,
    appBarRestProps,
    leftElement: leftButtonElement,
    rightElement: rightButtonElement,
  };
}
