import * as React from "react";
import styled from "styled-components";

import { ProductOptionItem } from "common/components/commerce/optionItem";
import ButtonCasePayment, {
  IButton,
} from "common/components/commerce/optionItem/buttonCase/payment";
import Brand from "common/components/commerce/optionItem/components/brand";
import { Spacer } from "common/components/designSystem/spacer";

import { useActions } from "app/store";
import { putProductVote } from "app/actions/commerce";
import { px2rem } from "common/helpers/rem";
import { useIntlShort } from "common/hooks/useIntlShort";
import { useSnackbar } from "common/components/alertTemplates/alerts/globalSnackbar/useGlobalSnackbar";
import useGroupTexts from "common/hooks/useGroupTexts";

import {
  useOpenPurchasePreparationDialogWithAddCart,
  useOpenPurchasePreparationDialogWithBuyNow,
} from "common/components/dialogs/purchasePreparationDialog/hooks/useOpen";
import { VoteStatus } from "app/enums";
import useBrandFetchSelector from "common/hooks/useEntitiesFetchSelectors/commerce/useBrandFetchSelector";

import { useBuyNowButtonVisible } from "app/modules/commerce/components/productShow/layout/productSummary/components/right/purchaseGloveBox/components/buttons/buyNow";
import { useAddCartButtonVisible } from "app/modules/commerce/components/productShow/layout/productSummary/components/right/purchaseGloveBox/components/buttons/addCart";

const StyledOptionItem = styled(ProductOptionItem)`
  padding: ${px2rem(8)} 0 ${px2rem(2)};
  & + & {
    margin: ${px2rem(8)} 0 ${px2rem(2)};
  }
`;

interface IProps {
  product: Moim.Commerce.IProduct;
  onDelete(productId: string): void;
}

const ProductItem: React.FC<IProps> = ({ product, onDelete }) => {
  const visibleBuyNowButton = useBuyNowButtonVisible(product.type);
  const visibleAddCartButton = useAddCartButtonVisible();
  const myShoppingWishListText = useGroupTexts(
    "my_shopping_wishlist_menu_title",
  );
  const teamBuyingButtonTexts = useGroupTexts("button_buy_team");
  const fundingButtonText = useGroupTexts("back_this_project");
  const intl = useIntlShort();
  const enabledTeamBuyingId =
    product.enableTeamBuying && product.activeTeamBuyingId
      ? product.activeTeamBuyingId
      : undefined;
  const openPurchasePreparationDialog = useOpenPurchasePreparationDialogWithBuyNow(
    product.id,
  );
  const openPurchasePreparationDialogWithAddCart = useOpenPurchasePreparationDialogWithAddCart(
    product.id,
  );
  const { open } = useSnackbar({
    text: intl("toast_message_my_shopping_wishlist_delete_item_success", {
      wishlist_title: myShoppingWishListText?.singular ?? "",
    }),
  });
  const { dispatchPutProductVote } = useActions({
    dispatchPutProductVote: putProductVote,
  });
  const {
    brands: { [0]: brand },
  } = useBrandFetchSelector(product?.brandId ? [product.brandId] : []);

  const handleClickDelete = React.useCallback(async () => {
    try {
      await dispatchPutProductVote(
        product.id,
        product.vote?.type ?? VoteStatus.POSITIVE,
        VoteStatus.NONE,
      );
      open();
      onDelete(product.id);
      // eslint-disable-next-line no-empty
    } catch (err) {}
  }, [product.vote?.type, product.id, dispatchPutProductVote, onDelete, open]);

  const handleAddToCart = React.useCallback(() => {
    openPurchasePreparationDialogWithAddCart({});
  }, [openPurchasePreparationDialogWithAddCart]);

  const handleTeamBuyingNow = React.useCallback(() => {
    openPurchasePreparationDialog({
      teamBuying: {
        teamBuyingId: enabledTeamBuyingId!,
        enterFrom: "team",
      },
    });
  }, [enabledTeamBuyingId, openPurchasePreparationDialog]);

  const buttons: IButton[] = React.useMemo(() => {
    const result: IButton[] = [
      {
        style: "general",
        text: intl("button_delete"),
        handler: handleClickDelete,
      },
    ];

    if (enabledTeamBuyingId || visibleAddCartButton || visibleBuyNowButton) {
      result.push({
        style: "primary",
        text: enabledTeamBuyingId
          ? teamBuyingButtonTexts?.singular
          : visibleAddCartButton
          ? intl("button_add_to_cart")
          : intl("button_buy_now"),
        disabled: !product.buyable,
        handler: enabledTeamBuyingId ? handleTeamBuyingNow : handleAddToCart,
      });
    }

    return result;
  }, [
    intl,
    teamBuyingButtonTexts,
    handleClickDelete,
    enabledTeamBuyingId,
    product.buyable,
    product.type,
    handleTeamBuyingNow,
    fundingButtonText,
    handleAddToCart,
    visibleBuyNowButton,
    visibleAddCartButton,
  ]);

  return (
    <>
      <Brand
        brandId={brand?.id}
        brandName={brand?.name}
        brandLogoImageUrl={brand?.logoImageUrl}
      />
      <StyledOptionItem
        productId={product.id}
        brandId={brand?.id}
        brandLogoUrl={brand?.logoImageUrl}
        brandName={brand?.name}
        qty={1}
      >
        {({ Title, Price, TeamBuyingBadgeTitle, defaultValue }) => (
          <>
            {enabledTeamBuyingId ? (
              <TeamBuyingBadgeTitle teamBuyingId={enabledTeamBuyingId} />
            ) : null}
            <Title>{defaultValue.title}</Title>
            {buttons.length > 1 ? (
              <Price>{defaultValue.price}</Price>
            ) : (
              <Spacer value={8} />
            )}
            {product?.status !== "onSale" && defaultValue.status}
            <ButtonCasePayment buttons={buttons} />
          </>
        )}
      </StyledOptionItem>
    </>
  );
};

export default React.memo(ProductItem);
