import * as React from "react";

import { PointChip, Logo, BrandWrapper } from "./styled";
import { B4Regular } from "common/components/designSystem/typos";
import { MoimURL } from "common/helpers/url";
import useRedirect from "common/hooks/useRedirect";
import { useSellerConfig } from "common/hooks/useSellerConfig";

interface IProps {
  brandId?: Moim.Id;
  brandName?: string;
  brandLogoImageUrl?: string;
  className?: string;
}

const Brand = ({
  brandId,
  brandName,
  brandLogoImageUrl,
  className,
}: IProps) => {
  const redirect = useRedirect();
  const brandEnabled = useSellerConfig("brandEnabled");

  const handleRedirect = React.useCallback(() => {
    if (brandId) {
      redirect(new MoimURL.CommerceBrandShow({ id: brandId }).toString());
    }
  }, [brandId, redirect]);

  if (!brandId || !brandEnabled) return null;

  return (
    <BrandWrapper onClick={handleRedirect} className={className}>
      <PointChip>
        {brandLogoImageUrl && <Logo src={brandLogoImageUrl} alt="logo" />}
        <B4Regular>{brandName}</B4Regular>
      </PointChip>
    </BrandWrapper>
  );
};

export default React.memo(Brand);
