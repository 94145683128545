import { Svg18LockG } from "@moim/icons";
import { H10Regular } from "common/components/designSystem/typos";
import { px2rem } from "common/helpers/rem";
import React from "react";
import styled from "styled-components";

interface IProps {
  children: React.ReactNode;
  text?: string;
}

const Container = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
  height: 100%;
`;

const Overlay = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${px2rem(60)};
  padding: ${px2rem(4)} ${px2rem(5)} ${px2rem(4)} ${px2rem(10)};
  background-color: ${(props) =>
    props.theme.themeMode.lightPalette.colorSet.grey700};
  pointer-events: none;
  bottom: ${px2rem(16)};
  right: ${px2rem(16)};
  z-index: ${(props) => props.theme.zIndexes.default};
  color: ${(props) => props.theme.themeMode.lightPalette.colorSet.white1000};
  border: 1px solid
    ${(props) => props.theme.themeMode.lightPalette.colorSet.white200};
  border-radius: ${px2rem(40)};
`;

const Text = styled(H10Regular)``;

export const LockIcon = styled(Svg18LockG).attrs(() => ({
  size: "xs",
  touch: 18,
  role: "button",
  iconColor: "currentColor",
}))``;

const OverlayInfo: React.FC<IProps> = ({ children, text = "" }) => {
  return (
    <Container>
      {children}
      <Overlay>
        <Text>{text}</Text>
        <LockIcon />
      </Overlay>
    </Container>
  );
};

export default OverlayInfo;
