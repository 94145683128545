import styled from "styled-components";
import { px2rem } from "common/helpers/rem";
import { B4RegularStyle } from "common/components/designSystem/typos";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: ${px2rem(16)} ${px2rem(24)} ${px2rem(24)};
`;

export const Description = styled.div`
  width: 100%;
  margin-bottom: ${px2rem(8)};
  color: ${props => props.theme.colorV2.colorSet.grey200};
  ${B4RegularStyle}
`;

export const InnerWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: ${px2rem(12)} ${px2rem(4)};
  overflow-y: auto;
`;

export const ImagePreviewContainer = styled.div`
  position: relative;
  width: 100%;
  height: ${px2rem(256)};
  background-color: ${props => props.theme.colorV2.colorSet.grey10};

  .topVeil,
  .bottomVeil {
    position: absolute;
    left: 0;
    right: 0;
    opacity: 0;
    height: ${px2rem(45)};
    z-index: ${props => props.theme.zIndexes.default};
    transition: opacity 200ms ease-in;
    pointer-events: none;
    user-select: none;
  }

  .topVeil {
    top: 0;
    background-image: ${props => `linear-gradient(
      to top,
      rgba(255,255,255,0) 0%,
      ${props.theme.colorV2.colorSet.grey50} 45%,
      ${props.theme.colorV2.colorSet.grey100} 100%
    )`};
  }

  .bottomVeil {
    bottom: 0;
    background-image: ${props => `linear-gradient(
      to bottom,
      rgba(255,255,255,0) 0%,
      ${props.theme.colorV2.colorSet.grey50} 45%,
      ${props.theme.colorV2.colorSet.grey100} 100%
    )`};
  }

  .topVeil[data-active="true"] {
    opacity: 1;
  }

  .bottomVeil[data-active="true"] {
    opacity: 1;
  }
`;

export const HiddenFileInput = styled.input`
  display: none;
`;

export const DialogContentWrapper = styled.div`
  position: relative;
  flex: 1;
  min-height: 0;
  width: 100%;
  height: 100%;
`;
