import * as React from "react";
import { FormattedMessage } from "react-intl";
import debounce from "lodash/debounce";

import { SingleLineBoxInput } from "common/components/designSystem/boxInput";

import {
  ButtonWrapper,
  VerifyCodeButton,
  VerifyInputWrapper,
  NotiHintText,
} from "../../styled";
import MiniTimer from "./timer";

import useCancelToken from "common/hooks/useCancelToken";
import { useActions } from "app/store";
import useCurrentGroup from "common/hooks/useCurrentGroup";
import { useOpenGlobalAlertDialog } from "common/components/dialogs/globalAlertDialog/hooks";
import { useIntlShort } from "common/hooks/useIntlShort";

import { verifyPhoneNumber } from "app/actions/user";
import useCurrentHubUser from "common/hooks/useCurrentHubUser";
import { AnalyticsClass } from "common/helpers/analytics/analytics";

const ErrorMessage = React.memo(
  ({ error }: { error?: Moim.IErrorResponse }) => {
    if (!error) {
      return null;
    }

    switch (error.code) {
      case "REQUEST_EXCEED_TIME_LIMIT":
        return (
          <FormattedMessage id="mobile_phone_number_verification/guide_expired_verification_code" />
        );
      case "AUTH_CODE_NOT_MATCH":
      case "PHONE_AUTH_NOT_FOUND":
        return (
          <FormattedMessage id="mobile_phone_number_verification/guide_invalid_verification_code" />
        );

      default:
        return <>{error.message}</>;
    }
  },
);

interface IProps {
  verifyEndTime?: number;
  error: Moim.IErrorResponse | undefined;
  phoneValidationSucceed: boolean;
  setError(error: Moim.IErrorResponse | undefined): void;
  setPhoneValidationSucceed(value: boolean): void;
  onVerifyTimeout(): void;
}
export default function VerifyCodeInput({
  verifyEndTime,
  error,
  setError,
  phoneValidationSucceed,
  setPhoneValidationSucceed,
  onVerifyTimeout,
}: IProps) {
  const intl = useIntlShort();
  const cancelToken = useCancelToken();
  const currentGroup = useCurrentGroup();
  const currentHubUser = useCurrentHubUser();
  const openGlobalAlert = useOpenGlobalAlertDialog();
  const { dispatchVerifyPhoneNumber } = useActions({
    dispatchVerifyPhoneNumber: verifyPhoneNumber,
  });
  const [code, setCode] = React.useState("");

  const handleChange = React.useCallback((value: string) => {
    setCode(value);
  }, []);

  const openPhoneNotiGuide = React.useCallback(() => {
    openGlobalAlert({
      content: intl(
        currentGroup?.event_noti_config?.phoneAuthentication?.kakaoTalk?.active
          ? "sign_up_form_verify_phone_number_detailed_description_kakao"
          : "sign_up_form_verify_phone_number_detailed_description",
      ),
    });
  }, [currentGroup]);

  const handleVerifyTimeout = React.useCallback(() => {
    setError({ code: "REQUEST_EXCEED_TIME_LIMIT" });
    onVerifyTimeout();
  }, [onVerifyTimeout, setError]);

  const handleVerifyCodeButton = React.useCallback(
    debounce(async () => {
      if (phoneValidationSucceed) {
        return;
      }

      const result = await dispatchVerifyPhoneNumber(
        {
          authCode: code,
        },
        cancelToken.current.token,
      );
      setPhoneValidationSucceed(result.success);
      setError(result.error);

      if (result.success) {
        AnalyticsClass.getInstance().signUpPhoneVerificationCodeSubmitted({
          result: "success",
          method: currentHubUser?.provider,
        });
      } else {
        AnalyticsClass.getInstance().signUpPhoneVerificationCodeSubmitted({
          result: "fail",
          method: currentHubUser?.provider,
        });
      }
    }, 150),
    [
      currentHubUser,
      cancelToken,
      code,
      dispatchVerifyPhoneNumber,
      phoneValidationSucceed,
      setError,
      setPhoneValidationSucceed,
    ],
  );

  const helperTextStatus = React.useMemo(
    () => (Boolean(error) ? "Error" : "Inactive"),
    [error],
  );

  const helperText = React.useMemo(
    () =>
      phoneValidationSucceed ? (
        <FormattedMessage id="mobile_phone_number_verification/button_verified" />
      ) : (
        <ErrorMessage error={error} />
      ),
    [error, phoneValidationSucceed],
  );

  return (
    <VerifyInputWrapper>
      <SingleLineBoxInput
        size="Large"
        value={code}
        status={helperTextStatus}
        helperText={helperText}
        placeholder={intl(
          "mobile_phone_number_verification/verfication_code_input_placeholder",
        )}
        suffix={{
          type: "text",
          text: (
            <MiniTimer
              phoneValidationSucceed={phoneValidationSucceed}
              endDateTime={verifyEndTime}
              onVerifyTimeout={handleVerifyTimeout}
            />
          ),
        }}
        onChange={handleChange}
      />

      <NotiHintText onClick={openPhoneNotiGuide}>
        <FormattedMessage id="button_number_verification_info" />
      </NotiHintText>

      <ButtonWrapper>
        <VerifyCodeButton
          size="s"
          onClick={handleVerifyCodeButton}
          disabled={!verifyEndTime || !code || phoneValidationSucceed}
        >
          <FormattedMessage id="mobile_phone_number_verification/button_verify" />
        </VerifyCodeButton>
      </ButtonWrapper>
    </VerifyInputWrapper>
  );
}
