import * as React from "react";

import { MoimAPI } from "common/api";

import { useActions } from "app/store";
import { useIntlShort } from "../useIntlShort";
import useCurrentGroup from "../useCurrentGroup";

import { ActionCreators as ImageBrochureActionCreators } from "common/components/imageBrochure/actions";

const api = new MoimAPI().file;

const getFileInfo = async (fileId: Moim.Id) => {
  const fileInfo = (await api
    .getFileUploadingStatus({ fileId })
    .then(res => res.data)) as Moim.Upload.IUploadStatusInfo &
    Moim.Upload.IFileInfo;

  return { fileInfo };
};

const getFileUrl = async (groupId: Moim.Id, fileId: Moim.Id) => {
  const url = await api
    .getPrivateFileUrl({ groupId, fileId })
    .then(res => res.url);
  return url;
};

export interface ConvertedFieldType {
  type: string;
  label: string;
  value: string;
  title?: string;
}

export const useConvertPaymentCustomFields = (
  locale: string,
  customFields?: Moim.Commerce.IPaymentCustomField[],
) => {
  const intl = useIntlShort();

  const [convertedFields, setConvertedFields] = React.useState<
    ConvertedFieldType[]
  >([]);

  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const { openImageBrochure } = useActions({
    openImageBrochure: ImageBrochureActionCreators.openSrcImageBrochure,
  });

  const handleImageClick = React.useCallback(
    async (
      e: React.MouseEvent<HTMLButtonElement>,
      _groupId: Moim.Id,
      fileId: Moim.Id,
      isPrivate?: boolean,
    ) => {
      e.currentTarget.dataset.brochureSelected = "true";
      const privateUrl = await getFileUrl(_groupId, fileId);
      openImageBrochure(privateUrl, isPrivate);
    },
    [openImageBrochure],
  );

  const currentGroup = useCurrentGroup();
  const groupId = currentGroup?.id;

  const fetchFields = async () => {
    if (!customFields) {
      return;
    }
    setIsLoading(true);
    const promises = customFields.map(async customField => {
      const mappedField: ConvertedFieldType = {
        label: customField.label[locale] || "",
        value: "-",
        title: "-",
        type: customField.type,
      };

      if (customField.type === "checkbox") {
        mappedField.value = customField.value
          ? intl("additional_info_checkbox_checked")
          : intl("additional_info_checkbox_unchecked");

        return mappedField;
      }

      if (!customField?.value) {
        return mappedField;
      }
      if (customField.type === "image") {
        try {
          const fileId = customField.value;
          if (!fileId) {
            throw new Error("fileId does not exist");
          }
          if (typeof fileId !== "string") {
            throw new Error("fileId is not string");
          }
          if (!groupId) {
            throw new Error("groupId does not exist");
          }

          const { fileInfo } = await getFileInfo(fileId);

          mappedField.title = fileInfo.title;
          mappedField.value = fileId;
        } catch (error) {
          throw error;
        }
        return mappedField;
      }

      mappedField.value = (customField.value as string) ?? "-";

      return mappedField;
    });

    const results = await Promise.all(promises);
    setIsLoading(false);
    setConvertedFields(results);
  };

  React.useEffect(() => {
    fetchFields();
  }, []);

  return { convertedFields, isLoading, handleImageClick };
};
