import { useStoreState } from "app/store";

export default function useHubSeller() {
  const hubSellerId = useCurrentHubSellerId();
  const { hubSeller } = useStoreState(state => ({
    hubSeller: state.entities.commerce_seller[hubSellerId ?? ""],
  }));

  return hubSeller;
}

export function useCurrentHubSellerId() {
  return useStoreState(state =>
    state.app.currentGroupId
      ? state.entities.groups[state.app.currentGroupId]?.seller_id
      : undefined,
  );
}

export function useHubSellerCurrency() {
  const sellerId = useCurrentHubSellerId();
  return useStoreState(state =>
    sellerId ? state.entities.commerce_seller[sellerId]?.currency : undefined,
  );
}

export function useCurrentCurrencyRule(
  currency: string,
  fallbackCurrency?: string,
) {
  const sellerId = useCurrentHubSellerId();
  return useStoreState(state => {
    if (!sellerId) return undefined;
    const currencyRule = state.entities.commerce_seller[
      sellerId
    ]?.currencies?.find(rule => rule.currency === currency);
    const fallbackCurrencyRule = state.entities.commerce_seller[
      sellerId
    ]?.currencies?.find(rule => rule.currency === fallbackCurrency);

    return currencyRule ?? fallbackCurrencyRule;
  });
}
