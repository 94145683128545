import * as React from "react";
import keycode from "keycode";
import { useIntl } from "react-intl";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions/transition";
import useCurrentGroup from "common/hooks/useCurrentGroup";
import { useOpenStateWithHashRoute } from "common/hooks/useOpenState";
import AppBar from "common/components/appBar";
import {
  CloseButton,
  Dialog,
  Input,
  InputContainer,
  InputContainerDivider,
  MobileSearchIcon,
  Rights,
  StickyWrapper,
  Wrapper,
} from "./styled";
import SearchBlockitRenderer from "./components/searchBlockitRenderer";
import { Spacer } from "common/components/designSystem/spacer";
import { DefaultDivider } from "common/components/divider";

const HASH_KEY = "mobileSearchForm";

interface IProps {
  prefixKey?: string;
  value?: string;
  onChange?(value: string): void;
  onEnter?(): void;
}

export interface IRef {
  open(): void;
  close(): void;
}

const Transition = React.forwardRef<
  any,
  TransitionProps & { children?: React.ReactElement<any, any> }
>((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const MobileSearchForm = React.forwardRef<IRef, IProps>(
  ({ prefixKey, value, onChange, onEnter }, ref) => {
    const refInput = React.useRef<HTMLInputElement>(null);
    const { isOpen, open, close, setIsOpen } = useOpenStateWithHashRoute(
      `${prefixKey}/${HASH_KEY}`,
    );
    const intl = useIntl();
    const currentMoim = useCurrentGroup();

    const handleChange: React.ChangeEventHandler<HTMLInputElement> = React.useCallback(
      e => {
        onChange?.(e.currentTarget.value);
      },
      [onChange],
    );
    const handleClickWrapper = React.useCallback(() => {
      refInput.current?.focus();
    }, []);

    const handleKeydown: React.KeyboardEventHandler<HTMLInputElement> = React.useCallback(
      e => {
        if (e.keyCode === keycode("enter")) {
          onEnter?.();
        }
      },
      [onEnter],
    );

    const handleClose = React.useCallback(() => {
      close();
    }, []);

    const handleSoftClose = React.useCallback(() => {
      setIsOpen(false);
    }, []);

    React.useImperativeHandle(ref, () => ({
      open,
      close: handleSoftClose,
    }));

    return (
      <Dialog
        open={isOpen}
        TransitionComponent={Transition}
        fullScreen={true}
        fullWidth={true}
        onClose={handleClose}
      >
        <Wrapper>
          <StickyWrapper>
            <AppBar
              titleAlignment="Center"
              rightButton={
                <Rights>
                  <CloseButton onClick={handleClose} />
                </Rights>
              }
            />
            <DefaultDivider />
            <InputContainer>
              <MobileSearchIcon role="button" onClick={handleClickWrapper} />
              <Input
                ref={refInput}
                autoFocus={true}
                value={value}
                placeholder={intl.formatMessage(
                  { id: "search_moim_placeholder" },
                  { moim_name: currentMoim?.name ?? "" },
                )}
                onChange={handleChange}
                onKeyDown={handleKeydown}
              />
            </InputContainer>
          </StickyWrapper>
          <InputContainerDivider />
          <Spacer value={8} />
          <SearchBlockitRenderer onClose={handleSoftClose} />
        </Wrapper>
      </Dialog>
    );
  },
);

export default MobileSearchForm;
