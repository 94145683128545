import React from "react";

import { Wrapper, TitleWrapper, Title, PreWrappedB4Regular } from "./styled";
import DeliveryPolicyGuideButton from "../../../deliveryPolicyGuideButton";

interface IProps {
  title?: string;
  description?: string;
  buttonText?: string;
  termId?: string;
}

const DeliveryPolicyGuide: React.FC<IProps> = React.memo(
  ({ title, description, buttonText, termId }) => (
    <Wrapper>
      <TitleWrapper>{title && <Title>{title}</Title>}</TitleWrapper>
      {description && <PreWrappedB4Regular>{description}</PreWrappedB4Regular>}
      <DeliveryPolicyGuideButton
        wrapperClass="policy-guide-button"
        buttonText={buttonText}
        termId={termId}
      />
    </Wrapper>
  ),
);

export default React.memo(DeliveryPolicyGuide);
