import * as React from "react";
import { FormattedMessage } from "react-intl";
import _ from "lodash";
// types
import useIsMobile from "common/hooks/useIsMobile";
// components
import { TagSetDialog as Dialog } from "common/components/basicResponsiveDialog/styled";
import AppBar from "common/components/appBar";

import { CustomAppBarModalLayout } from "common/components/modalLayout";
// styled
import {
  CloseButton,
  GroupFilterDialogWrapper,
  GroupFilterContainer,
  GroupFilterButtonContainer,
  GroupFilterButton,
} from "./styled";
import { FilterDialogTagGroupItem } from "./components/tagGroup";
import ResponsiveDialog from "common/components/dialogs/responsiveDialog";

const FilterDialogComponent: React.FC<{
  tagGroups: Moim.TagSet.ITagGroup[];
  selectedTags: Record<string, string[]>;
  onApply(): void;
  onChangeSelectedTags: React.Dispatch<
    React.SetStateAction<Record<string, string[]>>
  >;
}> = ({ tagGroups, selectedTags, onChangeSelectedTags, onApply }) => {
  const handleDeselectAll = React.useCallback(() => {
    onChangeSelectedTags({});
  }, [onChangeSelectedTags]);

  return (
    <GroupFilterDialogWrapper>
      <GroupFilterContainer>
        {tagGroups.map(tagGroup => (
          <FilterDialogTagGroupItem
            key={tagGroup.id}
            selectedTags={selectedTags[tagGroup.id]}
            initialFolded={!selectedTags[tagGroup.id]}
            tagGroup={tagGroup}
            onChange={selected => {
              onChangeSelectedTags(state => ({
                ...state,
                [tagGroup.id]: selected,
              }));
            }}
          />
        ))}
      </GroupFilterContainer>
      <GroupFilterButtonContainer>
        <GroupFilterButton onClick={handleDeselectAll}>
          <FormattedMessage id="select_tag_dialog_deselect_all" />(
          {_.flatten(Object.values(selectedTags))?.length ?? 0})
        </GroupFilterButton>
        <GroupFilterButton onClick={onApply}>
          <FormattedMessage id="ok_button" />
        </GroupFilterButton>
      </GroupFilterButtonContainer>
    </GroupFilterDialogWrapper>
  );
};
interface IProps {
  tagGroups: Moim.TagSet.ITagGroup[];
  selectedTags: Record<string, string[]>;
  isOpen: boolean;
  onApply(data: Record<string, string[]>): void;
  onClose(): void;
}

const FilterDialog: React.FC<IProps> = ({
  isOpen,
  onApply,
  onClose,
  selectedTags: initialSelectedTags,
  tagGroups,
}) => {
  const isMobile = useIsMobile();

  const [selectedTags, setSelectedTags] = React.useState<
    Record<Moim.Id, string[]>
  >({});

  const handleApply = React.useCallback(() => {
    onApply({ ...selectedTags });
    onClose();
  }, [onApply, onClose, selectedTags]);

  React.useEffect(() => {
    if (isOpen) {
      setSelectedTags(initialSelectedTags);
    }
  }, [isOpen]);

  return (
    <>
      <ResponsiveDialog
        open={isOpen}
        dialogBase={Dialog}
        onCloseRequest={handleApply}
        maxHeight={450}
      >
        {isMobile ? (
          <FilterDialogComponent
            tagGroups={tagGroups}
            selectedTags={selectedTags}
            onApply={handleApply}
            onChangeSelectedTags={setSelectedTags}
          />
        ) : (
          <Dialog open={isOpen} onClose={handleApply}>
            <CustomAppBarModalLayout
              appBar={
                <AppBar leftButton={<CloseButton onClick={handleApply} />} />
              }
              hasAppBarBorder={false}
            >
              <FilterDialogComponent
                tagGroups={tagGroups}
                selectedTags={selectedTags}
                onApply={handleApply}
                onChangeSelectedTags={setSelectedTags}
              />
            </CustomAppBarModalLayout>
          </Dialog>
        )}
      </ResponsiveDialog>
    </>
  );
};

export default FilterDialog;
