import produce from "immer";
import { AllActions } from "app/actions";
import { SideNavigationTypes } from "app/actions/types";
import { isServer } from "common/helpers/envChecker";
import { getInitialData } from "common/helpers/initialData";
import MobileDetect from "mobile-detect";

export interface ISideNavigationState {
  isExpand: boolean;
}

export const INITIAL_STATE: (requestId?: string) => ISideNavigationState = (
  requestId?: string,
) => {
  if (isServer()) {
    const agent = getInitialData("userAgent", requestId ?? "");
    const detect = new MobileDetect(agent);

    return {
      isExpand: !Boolean(detect.phone() || detect.mobile()),
    };
  }

  return {
    isExpand: false,
  };
};

export function reducer(state = INITIAL_STATE(), action: AllActions) {
  return produce(state, draft => {
    switch (action.type) {
      case SideNavigationTypes.EXPAND_SIDE_NAVIGATION: {
        draft.isExpand = true;
        break;
      }

      case SideNavigationTypes.COLLAPSE_SIDE_NAVIGATION: {
        draft.isExpand = false;
        break;
      }

      default: {
        break;
      }
    }
  });
}
