// hooks
import useIsMobile from "common/hooks/useIsMobile";

export const useParseListElementConfig = (
  listElement: Moim.Blockit.IListStyleElement,
) => {
  const isMobile = useIsMobile();

  const listElementType =
    (!isMobile && listElement.scrollDirection_web
      ? listElement.scrollDirection_web
      : listElement.scrollDirection) ??
    ((!isMobile && listElement.type_web
      ? listElement.type_web
      : listElement.type) === "grid"
      ? "vertical"
      : "horizontal");

  const column =
    isMobile || !listElement.columnCount_web
      ? listElement.columnCount
      : listElement.columnCount_web;

  const row =
    !isMobile && listElement.rowCount_web
      ? listElement.rowCount_web
      : listElement.rowCount;

  const scrollable =
    isMobile || listElement.scrollable_web === undefined
      ? listElement.scrollable
      : listElement.scrollable_web;

  const maxVisibleCount = scrollable
    ? isMobile || !listElement.maxDisplayedItemsCount_web
      ? listElement.maxDisplayedItemsCount
      : listElement.maxDisplayedItemsCount_web
    : column * row;

  return {
    column,
    row,
    listElementType,
    scrollable,
    maxVisibleCount,
  };
};
