import * as React from "react";

import { getCertificate_node_Certificate } from "@vingle/cryptobadge-sdk";
import PageUpdater from "common/components/pageUpdater";
import { getInitialData } from "common/helpers/initialData";
import useOGData from "common/hooks/useOGData";
import { useRequestId } from "common/hooks/useRequestId";

export function CryptoBadgeShowHelmet(props: {
  badge?: Moim.Cryptobadge.ICryptobadge;
}) {
  const { badge } = props;

  const { seoTitle, seoDescription, metaObjects } = useOGData({
    fallbackTitle: badge?.name,
    fallbackDescription: badge?.description,
    fallbackImageUrl: badge?.certificateImageUri,
  });

  return badge ? (
    <PageUpdater
      title={seoTitle}
      description={seoDescription}
      metaObjects={metaObjects}
      path={`/cryptobadge/badge/${badge?.id}`}
    />
  ) : null;
}

export function CryptoBadgeShowSSRHelmet() {
  const requestId = useRequestId();
  const badge = getInitialData("__entities.cryptobadge.badge", requestId);
  return CryptoBadgeShowHelmet({ badge });
}

export function CryptoBadgeCertificateShowHelmet(props: {
  certificate?: getCertificate_node_Certificate;
}) {
  const { certificate } = props;

  const { seoTitle, seoDescription, metaObjects } = useOGData({
    fallbackTitle: certificate?.name ?? undefined,
    fallbackDescription: certificate?.description ?? undefined,
    fallbackImageUrl: certificate?.imageUri ?? undefined,
  });

  return certificate ? (
    <PageUpdater
      title={seoTitle}
      description={seoDescription}
      metaObjects={metaObjects}
      path={`/cryptobadge/certificate/${certificate?.id}`}
    />
  ) : null;
}

export function CryptoBadgeCertificateShowSSRHelmet() {
  const requestId = useRequestId();
  const certificate = getInitialData(
    "__entities.cryptobadge.certificate",
    requestId,
  );
  return CryptoBadgeCertificateShowHelmet({ certificate });
}

export function CryptoBadgeGroupShowHelmet(props: {
  group?: Moim.Cryptobadge.ICryptobadgeGroup;
}) {
  const { group } = props;

  const { seoTitle, seoDescription, metaObjects } = useOGData({
    fallbackTitle: group?.name ?? undefined,
    fallbackDescription: group?.description ?? undefined,
  });

  return group ? (
    <PageUpdater
      title={seoTitle}
      description={seoDescription}
      metaObjects={metaObjects}
      path={`/cryptobadge/group/${group?.id}`}
    />
  ) : null;
}

export function CryptoBadgeGroupShowSSRHelmet() {
  const requestId = useRequestId();
  const group = getInitialData("__entities.cryptobadge.badgeGroup", requestId);
  return CryptoBadgeGroupShowHelmet({ group });
}
