import getIsUnread from "common/helpers/getIsUnread";

export function findUnreadChannel(
  channels: (
    | Moim.Channel.SimpleChannelWithoutCategoryType
    | Moim.DirectMessage.IDirectMessage
  )[],
) {
  return channels
    ?.filter(i => Boolean(i))
    .find(channel => {
      if (channel.type === "forum") {
        return Boolean(channel.stat?.has_new);
      }

      if (!channel.stat) {
        return false;
      }

      return getIsUnread({
        hasNew: Boolean(channel.stat.has_new),
        statCount: channel.stat.count,
      });
    });
}
