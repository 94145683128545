import getParentScrollElement from "common/helpers/getParentScrollElement";
import { isBrowser, isTest } from "./envChecker";

export default function getDocumentScrollElement(element: HTMLElement | null) {
  return isBrowser() || isTest()
    ? getParentScrollElement(element) ||
        (document.scrollingElement as HTMLElement) ||
        document.documentElement
    : null;
}
