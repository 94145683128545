import * as React from "react";

import { Checkbox } from "common/components/designSystem/inputs";

import {
  ProductGroupContainer,
  ProductGroupTitleContainer,
  ProductGroupTitle,
  TotalPriceInformation,
  Divider,
} from "./styled";
import { Spacer } from "common/components/designSystem/spacer";
import DeliveryPolicyButton from "../deliveryPolicyButton";
import { useIntlShort } from "common/hooks/useIntlShort";
import {
  NoDeliveryTotalPrice,
  ProductPrice,
  ShippingFee,
  TotalPrice,
} from "./price";
import useGroupTexts from "common/hooks/useGroupTexts";
import { Stack } from "@mui/material";

type IProps = React.PropsWithChildren<{
  title: React.ReactNode;
  subInfo?: React.ReactNode;
  currency: string;
  price: number;
  shippingFee: number;
  deliveryType: Moim.Commerce.DeliveryProductGroupType;
  deliveryPolicies?: Moim.Commerce.DeliveryPolicy[];
  additionalFees?: Moim.Commerce.IProductAdditionalFee[];
  footerButtonElement?: React.ReactNode;
  isAllChecked?: boolean;
  onClickAllCheck?: React.MouseEventHandler<HTMLInputElement>;
}>;

const ProductGroupBox = ({
  title,
  subInfo,
  currency,
  price,
  shippingFee,
  deliveryType,
  deliveryPolicies,
  additionalFees,
  footerButtonElement,
  isAllChecked,
  onClickAllCheck,
  children,
}: IProps) => {
  const intl = useIntlShort();

  const onlyPickUp = deliveryPolicies?.every(
    policy => policy.type === "pickUp",
  );

  const priceText = React.useMemo(() => {
    if (
      (deliveryPolicies &&
        deliveryPolicies?.every(
          policy => policy.type === "custom" || policy.type === "pickUp",
        )) ||
      deliveryType === "noDelivery"
    ) {
      return (
        <NoDeliveryTotalPrice
          currency={currency}
          price={price}
          additionalFees={additionalFees}
        />
      );
    }

    return (
      <>
        <ProductPrice
          currency={currency}
          price={price}
          additionalFees={additionalFees}
        />
        <span className="operator">+</span>
        <ShippingFee currency={currency} shippingFee={shippingFee} />
        <span className="operator">=</span>
        <TotalPrice
          currency={currency}
          price={price + shippingFee}
          additionalFees={additionalFees}
        />
      </>
    );
  }, [
    deliveryType,
    deliveryPolicies,
    price,
    shippingFee,
    currency,
    additionalFees,
  ]);

  const guideText = React.useMemo(() => {
    if (deliveryPolicies) {
      if (deliveryPolicies.length > 1) {
        return intl("cart_shipping_bundle_price_summary_shipping_option");
      }
      if (
        deliveryPolicies.length > 0 &&
        deliveryPolicies.every(policy => policy.type === "custom")
      ) {
        return intl("cart_shipping_bundle_price_summary_shipping_plugin");
      }
    }
    return null;
  }, [deliveryType, deliveryPolicies]);

  return (
    <ProductGroupContainer
      disableBodyPadding={Boolean(!isAllChecked && !onClickAllCheck)}
    >
      <div className="head">
        <Spacer value={8} />
        <ProductGroupTitleContainer>
          {(isAllChecked || onClickAllCheck) && (
            <Checkbox checked={isAllChecked} onChange={onClickAllCheck} />
          )}
          <Stack>
            <ProductGroupTitle>{title}</ProductGroupTitle>
            {subInfo}
          </Stack>
        </ProductGroupTitleContainer>
      </div>

      <Divider />

      <div className="body">{children}</div>

      <Divider />
      <div className="footer">
        <Spacer value={8} />
        <TotalPriceInformation>{priceText}</TotalPriceInformation>
        {!onlyPickUp && guideText && (
          <TotalPriceInformation>{guideText}</TotalPriceInformation>
        )}
        <Spacer value={16} />
        {footerButtonElement}
      </div>
    </ProductGroupContainer>
  );
};

export default ProductGroupBox;

export const ProductGroupBoxTitle: React.FC<{
  deliveryType: Moim.Commerce.DeliveryProductGroupType;
  policies?: Moim.Commerce.DeliveryPolicy[];
}> = ({ deliveryType, policies }) => {
  const shippingGroupText = useGroupTexts("product_type_shipping_group")
    ?.singular;
  const shippingPickUpText = useGroupTexts("product_type_pickup")?.singular;
  const shippingSeparateText = useGroupTexts("product_type_shipping_separate")
    ?.singular;
  const shippingOnlineText = useGroupTexts("product_type_online")?.singular;

  const onlyPickUp = React.useMemo(
    () =>
      deliveryType === "pickUp" ||
      policies?.every(policy => policy.type === "pickUp"),
    [deliveryType, policies],
  );

  const getTitle = React.useCallback(
    (type: Moim.Commerce.DeliveryProductGroupType) => {
      switch (type) {
        case "deliveryGroup":
          return shippingGroupText;
        case "deliveryAlone":
          return shippingSeparateText;
        case "noDelivery":
          return shippingOnlineText;
        case "pickUp":
          return shippingPickUpText;
      }
    },
    [
      shippingGroupText,
      shippingOnlineText,
      shippingPickUpText,
      shippingSeparateText,
    ],
  );

  return (
    <>
      {getTitle(onlyPickUp ? "pickUp" : deliveryType)}
      {policies && (
        <DeliveryPolicyButton
          deliveryType={onlyPickUp ? "pickUp" : deliveryType}
          deliveryPolicies={policies}
        />
      )}
    </>
  );
};
