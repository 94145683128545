import { useStoreState } from "app/store";
import { browserLocale, SupportedLanguageType } from "app/intl";

export const useGroupFont = () => {
  const { fontFamilies, locale } = useStoreState(state => {
    const groupId = state.app.currentGroupId ?? "";
    const group = state.entities.groups[groupId];
    const lc = browserLocale(
      state.app.locale,
      group?.config?.defaultLocale as SupportedLanguageType,
    );
    return {
      fontFamilies: group?.config?.fonts?.fontFamilies,
      locale: lc,
    };
  });

  if (!locale || !fontFamilies) {
    return {
      moimFontOptions: undefined,
    };
  }
  const moimFontOptions = fontFamilies?.[locale]?.options;

  return {
    moimFontOptions,
  };
};
