import React from "react";

interface IProps {
  children: React.ReactNode;
}

export const StopPropagationWrapper: React.FC<IProps> = ({ children }) => {
  if (!React.isValidElement(children)) {
    return children;
  }

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  return (
    <div onClick={handleClick} style={{ display: "contents" }}>
      {children}
    </div>
  );
};
