import { produce } from "immer";
import _ from "lodash";
import { AllActions } from "app/actions";
import {
  EntityTypes,
  ForumTypes,
  ForumEditorTypes,
  ForumDraftTypes,
  MeTypes,
  CommerceTypes,
} from "app/actions/types";
import { VoteStatus, ItemIdTypes } from "app/enums";
import { getLocaleContentShort } from "common/hooks/localization/useLocaleText";
import { browserLocale } from "app/intl";
import { getInitialData } from "common/helpers/initialData";
import { threadSingleItemNormalizer } from "app/models";

const loadInitialThreadData = (requestId?: string) => {
  return _.compact([
    getInitialData("__entities.thread", requestId ?? ""),
    getInitialData("__entities.reply", requestId ?? ""),
    ...(
      getInitialData("__entities.threadList", requestId ?? "")?.data ?? []
    ).map((thread: any) => ({ data: thread })),
  ]);
};

export const INITIAL_STATE: (
  requestId?: string,
) => Moim.Forum.INormalizedData = (requestId?: string) => {
  const initialDataList = loadInitialThreadData(requestId);

  if (_.isEmpty(initialDataList)) {
    return {};
  }

  if (!initialDataList) {
    return {};
  }

  const draft = {};
  const locale = browserLocale();

  initialDataList.forEach(initialData => {
    const normalized = threadSingleItemNormalizer(initialData);

    Object.entries(normalized.entities.threads || {}).forEach(
      ([key, value]) => {
        draft[key] = value;

        if (value.is_bookmarked === undefined) {
          draft[key].is_bookmarked = false;
        }

        draft[key].content = getLocaleContentShort(value, "content", locale);
      },
    );
  });

  return draft;
};

export function reducer(
  state: Moim.Forum.INormalizedData = INITIAL_STATE(),
  action: AllActions,
) {
  return produce(state, draft => {
    switch (action.type) {
      case EntityTypes.ADD_ENTITY: {
        const locale = browserLocale(action.payload.locale);
        Object.entries(action.payload.threads || {}).forEach(([key, value]) => {
          if (
            action.payload.forceUpdate ||
            !value.hasOwnProperty("updated_at") ||
            value.updated_at >= (draft[key]?.updated_at || 0)
          ) {
            draft[key] = value;

            if (value.is_bookmarked === undefined) {
              draft[key].is_bookmarked = false;
            }

            // 특정 버전 이후로 앱에서 작성된 리뷰가 array로 들어가서 커버 해두었습니다.
            if (
              value.type === "productReview" &&
              Array.isArray(value.meta?.purchaseItemSnap?.optionsLabel)
            ) {
              draft[key].meta = {
                ...value.meta,
                purchaseItemSnap: {
                  ...value.meta?.purchaseItemSnap,
                  optionsLabel: {},
                },
              };
            }

            draft[key].content = getLocaleContentShort(
              value,
              "content",
              locale,
            );
          }
        });

        break;
      }

      case ForumEditorTypes.SUCCEEDED_UPDATE_THREAD: {
        const { threadId, normalizedData } = action.payload;
        draft[threadId] = normalizedData;
        break;
      }

      case ForumTypes.START_VOTE_REPLY: {
        const { replyId, type } = action.payload;
        const selectedThread = draft[replyId];
        if (selectedThread) {
          selectedThread.vote = {
            ...selectedThread.vote,
            updated_at: Date.now(),
            type,
          } as Moim.Forum.IVote;
          selectedThread.vote_score += type === VoteStatus.POSITIVE ? 1 : -1;
        }
        break;
      }

      case ForumTypes.FAILED_VOTE_REPLY: {
        const { replyId, type } = action.payload;
        const selectedThread = draft[replyId];

        if (selectedThread && selectedThread.vote) {
          selectedThread.vote = {
            ...selectedThread.vote,
            type: type === "upvote" ? "downvote" : "upvote",
          };

          selectedThread.vote_score -= type === VoteStatus.POSITIVE ? 1 : -1;
        }
        break;
      }

      case ForumTypes.SUCCEED_DELETE_THREAD: {
        delete draft[action.payload.threadId];
        break;
      }

      case ForumDraftTypes.SUCCEEDED_DELETE_DRAFT:
      case ForumDraftTypes.SOFT_DELETE_DRAFT: {
        const { draftId } = action.payload;
        delete draft[draftId];
        break;
      }

      case ForumDraftTypes.SUCCEEDED_DELETE_ALL_DRAFT: {
        Object.keys(draft)
          .filter(id => id.toUpperCase().startsWith(ItemIdTypes.DRAFT))
          .forEach(id => {
            delete draft[id];
          });
        break;
      }

      case ForumDraftTypes.UPDATE_SILENT_DRAFT_EDIT_AT: {
        if (draft[action.payload.draftId]) {
          draft[action.payload.draftId].edited_at = action.payload.editAt;
        }
        break;
      }

      case MeTypes.START_POST_BOOKMARK:
      case MeTypes.SUCCEEDED_POST_BOOKMARK: {
        const { threadId } = action.payload;
        if (draft[threadId]) {
          draft[threadId].is_bookmarked = true;
        }
        break;
      }
      case MeTypes.FAILED_POST_BOOKMARK: {
        const { threadId } = action.payload;
        if (draft[threadId]) {
          draft[threadId].is_bookmarked = false;
        }
        break;
      }

      case MeTypes.START_DELETE_BOOKMARK:
      case MeTypes.SUCCEEDED_DELETE_BOOKMARK: {
        const { threadId } = action.payload;
        if (draft[threadId]) {
          draft[threadId].is_bookmarked = false;
        }
        break;
      }
      case MeTypes.FAILED_DELETE_BOOKMARK: {
        const { threadId } = action.payload;
        if (draft[threadId]) {
          draft[threadId].is_bookmarked = true;
        }
        break;
      }

      case ForumTypes.SUCCEED_POST_COMMENT: {
        const targetThread = draft[action.payload.threadId];
        if (targetThread) {
          targetThread.replies_count++;
        }
        break;
      }

      case CommerceTypes.SUCCEED_DELETE_PRODUCT_REVIEW: {
        if (draft[action.payload.reviewId]) {
          draft[action.payload.reviewId].deleted = true;
          if (draft[action.payload.parentId]) {
            draft[action.payload.parentId].replies_count--;
          }
        }
        break;
      }
      case CommerceTypes.SUCCEED_DELETE_PRODUCT_QUESTION: {
        if (draft[action.payload.questionId]) {
          draft[action.payload.questionId].deleted = true;
          if (draft[action.payload.parentId]) {
            draft[action.payload.parentId].replies_count--;
          }
        }
        break;
      }

      case ForumTypes.SUCCEED_DELETE_REPLY: {
        if (draft[action.payload.replyId]) {
          draft[action.payload.replyId].deleted = true;
          if (draft[action.payload.threadId]) {
            draft[action.payload.threadId].replies_count--;
          }
        }
        break;
      }

      default: {
        break;
      }
    }
  });
}
