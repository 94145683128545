import { useStoreState } from "app/store";
import useBufferedUserPostingCountFetch from "common/components/blockitEditorBase/components/blockitRenderer/components/userProfilePreview/bufferedStatFetch";
import { MoimURL } from "common/helpers/url";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Count, RightArrow, Title, TitleSection } from "./styled";
import useRedirect from "common/hooks/useRedirect";
import useIsMobile from "common/hooks/useIsMobile";
import { useCloseNativeSecondaryView } from "common/hooks/useSecondaryView";
import { THREAD_TYPE } from "app/typings/thread";
import _ from "lodash";
import { buildTypeKey } from "app/reducers/userPageData/threads";

interface IProps {
  userId: Moim.Id;
  types: {
    type: THREAD_TYPE;
    subTypes?: THREAD_TYPE[];
  }[];
  title?: string;
  pageTitle?: string;
}

const UserContentGroupPreview: React.FC<IProps> = ({
  userId,
  types,
  title,
  pageTitle,
}) => {
  const redirect = useRedirect();
  const isMobile = useIsMobile();
  const userWritingPostCountDispatch = useBufferedUserPostingCountFetch();
  const close = useCloseNativeSecondaryView();

  // type key 는 전체 조합
  // Count 는 전체 조합으로 조회 해도 무방
  // post|reply|nestedReply|review
  const typeKey = buildTypeKey(
    _.flatten(types.map(t => [t.type, ...(t.subTypes ?? [])])),
  );

  // post+reply_replyReply-reply+review
  const typeParams = types.map(t => {
    if (t.subTypes) {
      return `${t.type}_${t.subTypes.join("-")}`;
    }

    return t.type;
  });

  const postCount = useStoreState(
    state =>
      state.userPageData.threads[userId]?.typeThreadsCount[typeKey]?.total ?? 0,
  );

  const handleRedirectUserContents = React.useCallback(() => {
    if (isMobile) {
      close();
    }

    const params = {
      types: typeParams.join("+"),
      tab: types[0].type,
    };

    if (pageTitle) {
      Object.assign(params, {
        pageTitle,
      });
    }

    const destination = new MoimURL.UserThreadList({ userId }).toString(
      undefined,
      params,
    );

    redirect(destination);
  }, [close, isMobile, redirect, typeParams, types, userId]);

  React.useEffect(() => {
    userWritingPostCountDispatch(userId, typeKey.split("|") as THREAD_TYPE[]);
  }, [typeKey, userId, userWritingPostCountDispatch]);

  return (
    <TitleSection role="button" onClick={handleRedirectUserContents}>
      <Title>
        {title ?? <FormattedMessage id="profile_show_created_content_title" />}
      </Title>
      <Count>{postCount}</Count>
      <RightArrow />
    </TitleSection>
  );
};

export default UserContentGroupPreview;
