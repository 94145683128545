/* eslint-disable @typescript-eslint/no-empty-function */
import React from "react";
import { useStoreState } from "app/store";
import { Thread } from "app/typings";

import {
  Container,
  FromWhere,
} from "common/components/search/components/styled";
import SearchCommentItem from "common/components/search/components/comment/item";
import { Spacer } from "common/components/designSystem/spacer";

type IProps = Thread.IThreadItemBaseProps;

export function Reply({ threadId }: IProps) {
  const thread = useStoreState(
    state =>
      state.entities.threads[threadId] as
        | Moim.Forum.IThread<Moim.Forum.IProductReviewThreadMeta | undefined>
        | undefined,
  );

  const user = useStoreState(state =>
    thread ? state.entities.users[thread.author] : undefined,
  );

  const parentThread = useStoreState(state =>
    thread && thread.original_id
      ? state.entities.threads[thread.original_id]
      : undefined,
  );

  const channel = useStoreState(state =>
    thread && thread.root_id
      ? state.entities.channels[thread.root_id]
      : undefined,
  );

  const parentUser = useStoreState(state =>
    parentThread ? state.entities.users[parentThread.author] : undefined,
  );

  return thread ? (
    <Container>
      <FromWhere>{channel ? channel.name : ""}</FromWhere>
      <Spacer value={16} />
      <SearchCommentItem
        parentContent={
          parentThread
            ? {
                id: parentThread.id,
                createdAt: parentThread.created_at,
                title: parentThread.title,
                creator: parentUser
                  ? {
                      username: parentUser.name,
                      anonymousSuffix:
                        parentThread.anonymous_data?.authorSuffix,
                    }
                  : undefined,
                engage: {
                  replyCount: parentThread.replies_count,
                  upVoteScore: parentThread.up_vote_score,
                  upVoted: parentThread.vote?.type === "upvote",
                  viewCount: parentThread.viewCount,
                },
              }
            : undefined
        }
        content={{
          id: thread.id,
          createdAt: thread.created_at,
          body: {
            texts: thread.content
              .filter(c => c.type === "text")
              .map(c => c.content),
          },
          creator: user
            ? {
                id: user.id,
                username: user.name,
                anonymousSuffix: thread.anonymous_data?.authorSuffix,
                avatar: user.avatar,
              }
            : undefined,
        }}
      />
    </Container>
  ) : (
    <div />
  );

  // return <Comment comment={thread as any} />;
}
