import * as React from "react";
import styled, { css } from "styled-components";
import { px2rem } from "common/helpers/rem";
import UserProfileImage from "common/components/userProfileImage";
import { sizeMap } from "common/components/userProfileImage/size";
import { bgLevel1Style } from "common/components/designSystem/BGLevel";
import { useStoreState } from "app/store";

const Container = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start;
`;

const PositionBox = styled.div<{
  size: Moim.DesignSystem.Size;
  isDefaultSource: boolean;
}>`
  position: relative;
  width: ${props => px2rem(sizeMap.get(props.size)!)};
  height: ${props => px2rem(sizeMap.get(props.size)!)};
  background-color: ${props => props.theme.colorV2.colorSet.white1000};
  border-radius: 50%;
  margin-left: ${px2rem(-4)};
  ${props =>
    props.isDefaultSource &&
    css`
      ::before {
        position: absolute;
        border-radius: 100%;
        content: "";
        width: 100%;
        height: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 0;
        ${bgLevel1Style}
      }
    `}
`;

const Item: React.FC<{
  sourceType: "image" | "userId";
  size: Moim.DesignSystem.Size;
  source?: string;
}> = ({ sourceType, size, source }) => {
  const src = useStoreState(state =>
    sourceType === "userId"
      ? state.entities.users[source!]?.avatar_url
      : source,
  );
  return (
    <UserProfileImage
      size={size}
      shape="round"
      src={src}
      canOpenProfileDialog={false}
      enableBlockedPlaceholder={true}
    />
  );
};

const ProfileStacker: React.FC<{
  size?: Moim.DesignSystem.Size;
  maxDisplay?: number;
  sourceType?: "image" | "userId";
  sources?: (string | undefined)[];
}> = React.memo(
  ({ size = "xs", maxDisplay = 4, sourceType = "image", sources }) => {
    return (
      <Container>
        {sources
          ?.reverse()
          .slice(0, maxDisplay)
          .map((source, idx) => {
            return (
              <PositionBox
                key={`profile-stacker-${source ?? idx}`}
                size={size}
                isDefaultSource={!Boolean(source)}
              >
                <Item sourceType={sourceType} size={size} source={source} />
              </PositionBox>
            );
          })}
      </Container>
    );
  },
);
export default ProfileStacker;
