// vendor
import * as React from "react";
import { useIntl } from "react-intl";
import _ from "lodash";
import { useInView } from "react-intersection-observer";

// component
import { Wrapper } from "./styled";
import DirectMessageItem from "./directMessageItem";
import Category from "common/components/channelList/components/category";
// hooks
import isSelectedChannel from "common/helpers/isSelectedChannel";
import useNewDirectMessageDialog from "common/hooks/useNewDirectMessageDialog";
import useMatchRoute from "common/hooks/useMatchRoute";
import { useStoreState } from "app/store";
import { directMessagesSelector } from "app/selectors/directMessage";
import { channelWithCategorySelector } from "app/selectors/channel";
import { findUnreadChannel } from "../../container/navigation/helpers";
import { NavigationPanelContext } from "../../context";

const Item: React.FC<{ directMessage: Moim.DirectMessage.IDirectMessage }> = ({
  directMessage,
}) => {
  const selectedChannel = useMatchRoute();
  const isSelected = isSelectedChannel(selectedChannel, "dm", directMessage.id);
  const [ref, inView, entry] = useInView();

  const {
    isFirstUnreadChannelId,
    isLastUnreadChannelId,
    channelStatCount,
  } = useStoreState(state => {
    const dmChannelList = directMessagesSelector(state).data;
    const channelListWithoutCategory = _.flatten(
      channelWithCategorySelector(state).data.map(channel =>
        channel.type === "category" ? channel.items : channel,
      ),
    ) as Moim.Channel.SimpleChannelWithoutCategoryType[];

    return {
      isFirstUnreadChannelId:
        findUnreadChannel([...channelListWithoutCategory, ...dmChannelList])
          ?.id === directMessage.id,
      isLastUnreadChannelId:
        findUnreadChannel(
          [...channelListWithoutCategory, ...dmChannelList].reverse(),
        )?.id === directMessage.id,
      channelStatCount: (state.entities.channels[
        directMessage.id
      ] as Moim.Channel.SimpleChannelWithoutCategoryType)?.stat?.count,
    };
  });

  const {
    topUnreadSnackBar,
    bottomUnreadSnackBar,
    setTopUnreadSnackBar,
    setBottomUnreadSnackBar,
  } = React.useContext(NavigationPanelContext);

  React.useEffect(() => {
    if (!entry || (!isFirstUnreadChannelId && !isLastUnreadChannelId)) {
      return;
    }

    if (inView) {
      if (
        topUnreadSnackBar.open &&
        topUnreadSnackBar.openBy === directMessage.id
      ) {
        setTopUnreadSnackBar({ open: false });
      }

      if (
        bottomUnreadSnackBar.open &&
        bottomUnreadSnackBar.openBy === directMessage.id
      ) {
        setBottomUnreadSnackBar({ open: false });
      }
    } else {
      if (
        isFirstUnreadChannelId &&
        entry.boundingClientRect.y < Math.floor(innerHeight / 2)
      ) {
        setTopUnreadSnackBar({
          open: true,
          type: channelStatCount ? "mention" : "unread",
          openBy: directMessage.id,
        });
      } else if (
        isLastUnreadChannelId &&
        entry.boundingClientRect.y > Math.floor(innerHeight / 2)
      ) {
        setBottomUnreadSnackBar({
          open: true,
          type: channelStatCount ? "mention" : "unread",
          openBy: directMessage.id,
        });
      }
    }
  }, [inView, entry]);

  return (
    <div data-side-navigation-channel-id={directMessage.id}>
      <DirectMessageItem
        key={directMessage.id}
        ref={ref}
        directMessage={directMessage}
        elementPaletteProps={{ type: "sideArea", key: "menuText" }}
        isSelected={isSelected}
      />
    </div>
  );
};
export interface IProps {
  directMessages: Moim.DirectMessage.IDirectMessage[];
}

function DirectMessageList({ directMessages }: IProps) {
  const intl = useIntl();

  const { openNewDirectMessageDialog } = useNewDirectMessageDialog();

  return (
    <Wrapper>
      <Category
        categoryName={intl.formatMessage({
          id: "side_navigation/direct_message",
        })}
        useCollapse={false}
        textColorPaletteKey="categoryTitleText"
        elementPaletteKey="background"
        showChannelAddButton={true}
        onClickChannelAddButton={openNewDirectMessageDialog}
      />
      {directMessages
        ?.filter(i => Boolean(i))
        .map(directMessage => {
          return <Item key={directMessage.id} directMessage={directMessage} />;
        })}
    </Wrapper>
  );
}

export default DirectMessageList;
