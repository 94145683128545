import * as React from "react";
import _ from "lodash";
import { FlattenInterpolation, css } from "styled-components";
import { countries } from "countries-list";
// hooks
import useCurrentGroup from "common/hooks/useCurrentGroup";
// helpers
import { browserLocaleCountry } from "app/intl";
// components
import { StaticSelection } from "common/components/designSystem/selection";
import { IOption } from "common/components/designSystem/selection/type";
import BoxInput from "common/components/designSystem/boxInput/preset/singleline";
import { InputStatusType } from "common/components/designSystem/boxInput/type";
import { Wrapper, selectionStyle } from "./styled";

interface IProps {
  size?: "l" | "s";
  countryCode?: string;
  phone?: string;
  selectionPlaceholder?: string;
  selectionState?: "normal" | "error" | "disabled";
  inputPlaceholder?: string;
  inputWrapperStyle?: FlattenInterpolation<any>;
  inputHelperText?: React.ReactNode;
  inputStatus?: InputStatusType;

  onChange(countyCode: string | null, phone: string): void;
}

const GlobalPhoneInputForm: React.FC<IProps> = ({
  size = "l",
  countryCode,
  phone,
  selectionState = "normal",
  selectionPlaceholder,
  inputPlaceholder,
  inputWrapperStyle,
  inputHelperText,
  inputStatus,
  onChange,
}) => {
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const currentGroup = useCurrentGroup();
  const useableCountryCodes = React.useMemo(() => {
    const objCountries = _.toPairs(countries);
    const internationalizations = currentGroup?.internationalizations;

    let highOrderIntl: string[] = [];

    const lang = browserLocaleCountry();
    const target = Object.entries(objCountries).filter(
      ([, [key, { languages }]]) =>
        key.toUpperCase() === lang.toUpperCase() || languages.includes(lang),
    );
    switch (lang) {
      case "ko": {
        highOrderIntl = ["KR"];
        break;
      }
      case "en": {
        highOrderIntl = ["US"];
        break;
      }
      default: {
        if (target.length > 0) {
          highOrderIntl = [target[0][1][0]];
        }
        break;
      }
    }

    if (internationalizations && internationalizations.length > 0) {
      highOrderIntl = highOrderIntl.concat(
        internationalizations
          .map(intl => intl.regionCode)
          ?.filter(code => !highOrderIntl.includes(code)),
      );
    }

    const tmpData = Object.entries(objCountries);
    const data = tmpData
      .sort(([, [key]], [, [key2]]) => {
        const tmpA = highOrderIntl.findIndex(intl => intl === key);
        const tmpB = highOrderIntl.findIndex(intl => intl === key2);

        return tmpA >= 0 && tmpB >= 0
          ? tmpA < tmpB
            ? -1
            : 1
          : tmpA >= 0
          ? -1
          : 1;
      })
      .map(val => val[1]);

    return data.reduce<{
      [key: string]: { code: string; country: string; languages: string[] };
    }>((acc, [key, value]) => {
      acc[key] = {
        country: value.native,
        code: value.phone,
        languages: value.languages,
      };
      return acc;
    }, {});
  }, [currentGroup]);
  const defaultCountryCodeKey = Object.entries(useableCountryCodes).find(
    ([, value]) => value.code === countryCode,
  );
  const [countryCodeKey, setCountryCodeKey] = React.useState(
    defaultCountryCodeKey?.[1]?.code ?? null,
  );
  const countryOptions: IOption[] = React.useMemo(
    () =>
      Object.entries(useableCountryCodes).map(([, value]) => ({
        value: value.code,
        label: `+${value.code} (${value.country})`,
      })),
    [useableCountryCodes],
  );

  const handleChangeCountryCode = React.useCallback((code: Moim.Id) => {
    setCountryCodeKey(code);
  }, []);

  React.useEffect(() => {
    if (countryCode) {
      if (!phoneNumber && phone && defaultCountryCodeKey) {
        setPhoneNumber(phone);
        setCountryCodeKey(defaultCountryCodeKey[1].code);
      }
    } else {
      const lang = browserLocaleCountry();
      const target = Object.entries(useableCountryCodes).filter(
        ([key, { languages }]) =>
          key.toUpperCase() === lang.toUpperCase() || languages.includes(lang),
      );

      switch (lang) {
        case "ko": {
          setCountryCodeKey(useableCountryCodes.KR.code);
          break;
        }
        case "en": {
          setCountryCodeKey(useableCountryCodes.US.code);
          break;
        }
        default: {
          if (target.length > 0) {
            setCountryCodeKey(target[0][1].code);
          }
          break;
        }
      }
    }
  }, [useableCountryCodes, countryCode, phone, phoneNumber]);

  React.useEffect(() => {
    if (!countryCodeKey) return;
    onChange(countryCodeKey, phoneNumber);
  }, [countryCodeKey, phoneNumber]);

  return (
    <Wrapper>
      <StaticSelection
        size={size}
        state={selectionState}
        selected={countryCodeKey}
        options={countryOptions}
        isMultiple={false}
        useSearch={true}
        placeholder={selectionPlaceholder}
        overrideStyle={selectionStyle}
        onChange={handleChangeCountryCode}
      />
      <BoxInput
        size={size === "l" ? "Large" : "Small"}
        type="number"
        value={phoneNumber}
        wrapperStyle={css`
          margin-top: 0;
          margin-bottom: 0;
          ${inputWrapperStyle};
        `}
        placeholder={inputPlaceholder}
        helperText={inputHelperText}
        status={inputStatus}
        onChange={setPhoneNumber}
      />
    </Wrapper>
  );
};

export default GlobalPhoneInputForm;
