import _ from "lodash";

import React, { createContext } from "react";
import { ActionCreators } from "./actions";
import { useActions, useStoreState } from "app/store";

interface IDialogContext {
  purchaseReadyItems: Moim.Commerce.IPurchaseReadyItem[];
  teamBuying: { teamBuyingId: string; teamId?: string } | undefined;
  setPurchaseReadyItems(
    selectedItems: Moim.Commerce.IPurchaseReadyItem[],
  ): void;
  setTeamBuying(teamBuying: { teamBuyingId: string; teamId?: string }): void;
}

const dialogContextInitialValue: IDialogContext = {
  purchaseReadyItems: [],
  teamBuying: undefined,
  setPurchaseReadyItems: () => {},
  setTeamBuying: () => {},
};

const PurchasePreparationDialogContext = createContext<IDialogContext>(
  dialogContextInitialValue,
);

export { PurchasePreparationDialogContext };

export const PurchasePreparationDialogProvider = ({
  children,
}: React.PropsWithChildren) => {
  const { initialPurchaseReadyItems = [], initialPayload } = useStoreState(
    state => ({
      initialPurchaseReadyItems:
        state.purchasePreparationDialog.cachedData?.purchaseReadyItems,
      initialPayload: state.purchasePreparationDialog.payload,
    }),
  );
  const { dispatchUpdatePurchaseReadyItems } = useActions({
    dispatchUpdatePurchaseReadyItems: ActionCreators.updatePurchaseReadyItems,
  });
  const [teamBuying, setTeamBuying] = React.useState<
    | {
        teamBuyingId: Moim.Id;
        selectedTeamId?: Moim.Id;
      }
    | undefined
  >(initialPayload?.teamBuying);
  const [purchaseReadyItems, setPurchaseReadyItems] = React.useState<
    Moim.Commerce.IPurchaseReadyItem[]
  >(initialPurchaseReadyItems);

  React.useEffect(() => {
    if (!_.isEqual(initialPurchaseReadyItems, purchaseReadyItems)) {
      dispatchUpdatePurchaseReadyItems(purchaseReadyItems);
    }
  }, [purchaseReadyItems]);

  return (
    <PurchasePreparationDialogContext.Provider
      value={{
        purchaseReadyItems,
        teamBuying,
        setPurchaseReadyItems,
        setTeamBuying,
      }}
    >
      {children}
    </PurchasePreparationDialogContext.Provider>
  );
};
