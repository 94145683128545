import * as React from "react";
import { FormattedNumber } from "react-intl";
import {
  EngageItem,
  EngageIcon,
  EngageLikeIcon,
  EngageCommentIcon,
  EngageViewIcon,
  EngageLabel,
} from "./styled";
import maximumCount from "common/helpers/numberFormatter/maximumCount";

interface IProps {
  type: "voteCount" | "replyCount" | "viewCount";
  isVisible: boolean;
  value: number;
}

const EngageItemComponent: React.FC<IProps> = ({ type, isVisible, value }) => {
  const icon = React.useMemo(() => {
    switch (type) {
      case "voteCount":
        return <EngageLikeIcon />;
      case "replyCount":
        return <EngageCommentIcon />;
      case "viewCount":
        return <EngageViewIcon />;
    }
  }, [type]);

  const count = React.useMemo(() => {
    switch (type) {
      case "voteCount":
      case "replyCount":
        return maximumCount(value);

      case "viewCount":
        return <FormattedNumber value={value} notation="compact" />;
    }
  }, [type, value]);

  if (!isVisible) {
    return null;
  }

  return (
    <EngageItem>
      <EngageIcon>{icon}</EngageIcon>
      <EngageLabel>{count}</EngageLabel>
    </EngageItem>
  );
};

export default React.memo(EngageItemComponent);
