import * as React from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import Clipboard from "react-clipboard.js";
import useGroupTexts from "common/hooks/useGroupTexts";

import { Spacer } from "common/components/designSystem/spacer";
import { Title, Wrapper, Section } from "../common";
import { DefaultDivider } from "common/components/divider";
import { PriceWithAdditionalFees } from "app/modules/commerce/components/productShow/layout/productSummary/components/right/price/additionalFee";
import AdditionalFeeSection from "./additionalFeeSection";
import CurrencyFormatter from "common/components/currencyFormatter";
import { B4Regular } from "common/components/designSystem/typos";
import { usePaymentStatusDisplay } from "common/helpers/commerce";
import { BlockchainCurrencyTypes } from "app/enums/index";
import UsedCoinSection from "./usedCoinSection";
import { shaveWalletAddress } from "common/helpers/nft";
import { useSnackbar } from "common/components/alertTemplates/alerts/globalSnackbar/useGlobalSnackbar";
import { useSellerDisplayConfig } from "common/hooks/useSellerConfig";
import convertCamel2Snake from "common/helpers/convertCamel2Snake";

const PaymentMethodInformation = styled(B4Regular)`
  color: ${props => props.theme.colorV2.colorSet.grey600};
`;
const PaymentVBankMethodInformation = styled(B4Regular)<{
  isVBankPending?: boolean;
}>`
  color: ${props =>
    props.isVBankPending
      ? props.theme.color.red
      : props.theme.colorV2.colorSet.grey600};
`;

const CopyButton = styled.div.attrs({ role: "button" })`
  display: inline-block;
  color: ${props => props.theme.color.blue};

  .num {
    text-decoration: underline;
  }
`;

const PriceWithAdditionalFeesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

interface IProps {
  payment: Moim.Commerce.IPayment;
}

export default function PaymentInformation({ payment }: IProps) {
  const isCandy = payment.type === "candy";
  const isFundPayment = React.useMemo(
    () => payment.purchases[0]?.purchaseItems[0]?.product?.type === "fund",
    [payment.purchases],
  );
  const paymentStatusText = usePaymentStatusDisplay(payment.status);
  const { open } = useSnackbar({
    timeout: 3000,
  });
  const displayWeight = useSellerDisplayConfig("weight");

  const totalQuantity = React.useMemo(
    () =>
      payment.purchases.reduce(
        (result, current) =>
          result +
          current.purchaseItems.reduce(
            (result2, current2) => result2 + current2.quantity,
            0,
          ),
        0,
      ),
    [payment],
  );
  const totalWeight = React.useMemo(
    () =>
      Number(
        payment.purchases
          .reduce((result, current) => result + (current?.totalWeight ?? 0), 0)
          .toFixed(1),
      ),
    [payment],
  );

  const hasCustomDeliveryPolicy = React.useMemo(
    () =>
      payment?.purchases.some(groupedItems =>
        groupedItems.items.some(
          item => item?.deliveryGroup?.policy?.type === "custom",
        ),
      ),
    [payment?.purchases],
  );

  const isFlexibleShippingFee =
    payment.status === "submitted" && hasCustomDeliveryPolicy;

  const sectionTitleTexts = useGroupTexts(
    "my_shopping_orders_details_payment_title",
  );
  const totalProductTexts = useGroupTexts(
    "my_shopping_orders_total_product_title",
  );
  const productPrice = React.useMemo(
    () =>
      payment.purchases.reduce(
        (result, current) => result + current.totalPrice, // Note: totalPrice originalPrice 가 아닌 할인이 먹여진 price의 토탈.
        0,
      ),
    [payment],
  );
  const shippingFee = React.useMemo(
    () =>
      payment.purchases.reduce(
        (result, current) => result + current.deliveryFee,
        0,
      ),
    [payment],
  );

  const creditAmount = React.useMemo(
    () =>
      payment.purchases.reduce(
        (result, current) =>
          result +
          current.purchaseItems.reduce(
            (result2, current2) => result2 + (current2.creditAmount ?? 0),
            0,
          ),
        0,
      ),
    [payment],
  );

  const paymentMethodTitle = React.useMemo(() => {
    if (payment.currency in BlockchainCurrencyTypes) {
      return (
        <FormattedMessage
          id={
            payment.status === "vbankPending"
              ? "commerce_purchases_payment_method_cryptocurrency_unconfirmed"
              : "commerce_purchases_payment_method_cryptocurrency_confirmed"
          }
        />
      );
    }
    switch (payment.payMethod) {
      case "TOKEN":
        return (
          <FormattedMessage id="commerce_purchases_payment_method_cryptocurrency_confirmed" />
        );
      case "IN_APP":
        return (
          <FormattedMessage id="commerce_purchases_payment_method_in_app_purchase" />
        );
      case "PAY_IN_STORE":
        return <FormattedMessage id="pay_method_pay_in_store" />;
      default:
        return (
          <FormattedMessage
            id={`pay_method_${convertCamel2Snake(payment.payMethodKey)}`}
          />
        );
    }
  }, [payment.currency, payment.payMethod, payment.status]);

  const handleCopySuccess = React.useCallback(() => {
    open({
      textElement: (
        <FormattedMessage id="toast_message_copy_bank_account_number_success" />
      ),
    });
  }, []);

  const handleCopyFailed = React.useCallback(() => {
    open({
      textElement: (
        <FormattedMessage id="toast_message_copy_bank_account_number_failed" />
      ),
    });
  }, []);

  const paymentMethodInformation = React.useMemo(() => {
    switch (payment.payMethod) {
      case "CARD":
        return (
          <Section
            title={""}
            contents={
              <PaymentMethodInformation>
                {`${payment.detail?.cardName ?? ""} ${
                  payment.detail?.cardNum ? `(${payment.detail?.cardNum})` : ""
                }`}
              </PaymentMethodInformation>
            }
            contentsOption={{ textAlign: "right" }}
          />
        );

      case "TOKEN":
        return (
          <Section
            title=""
            contents={
              <PaymentMethodInformation>
                <FormattedMessage
                  id="commerce_purchases_payment_method_cryptocurrency_wallet_address"
                  values={{
                    wallet_address: payment.fromAddress
                      ? shaveWalletAddress(payment.fromAddress)
                      : "",
                  }}
                />
              </PaymentMethodInformation>
            }
            contentsOption={{ textAlign: "right" }}
          />
        );
      case "VBANK":
      case "BANK_TRANSFER":
        return (
          <Section
            title={
              <PaymentVBankMethodInformation>
                <Clipboard
                  component={CopyButton}
                  data-clipboard-text={payment.vbankNum}
                  onSuccess={handleCopySuccess}
                  onError={handleCopyFailed}
                >
                  ({payment.vbankName}){" "}
                  <span className="num">{payment.vbankNum}</span>
                </Clipboard>
              </PaymentVBankMethodInformation>
            }
            contents={
              <PaymentVBankMethodInformation
                isVBankPending={payment.status === "vbankPending"}
              >
                {paymentStatusText}
              </PaymentVBankMethodInformation>
            }
            titleOption={{ maxWidth: 170 }}
            contentsOption={{ textAlign: "right" }}
          />
        );

      case "IN_APP":
        if (!payment.detail?.extra) return;
        return (
          <Section
            title=""
            contents={
              <PaymentMethodInformation>
                <FormattedMessage
                  id={
                    payment.detail?.extra === "AppStore"
                      ? "commerce_purchases_payment_method_in_app_purchase_ios"
                      : "commerce_purchases_payment_method_in_app_purchase_android"
                  }
                />
              </PaymentMethodInformation>
            }
            contentsOption={{ textAlign: "right" }}
          />
        );

      case "PAYPAL":
        return "";
      default:
        return "";
    }
  }, [
    payment.payMethod,
    payment.detail,
    payment.fromAddress,
    payment.vbankName,
    payment.vbankNum,
    payment.status,
    paymentStatusText,
  ]);

  const shippingRequired = React.useMemo(
    () =>
      payment.purchases.some(purchase =>
        purchase.purchaseItems.some(item => item.shippingRequired),
      ),
    [payment],
  );
  return (
    <Wrapper>
      <Title>
        {isCandy ? (
          <FormattedMessage id="my_shopping_purchase_details_payment_info_title" />
        ) : sectionTitleTexts ? (
          sectionTitleTexts.singular
        ) : (
          <FormattedMessage id="my_shopping/purchase_details/order_payments_title" />
        )}
      </Title>
      <Section
        title={<FormattedMessage id="summary_total_products" />}
        contents={totalQuantity}
        titleOption={{ maxWidth: 170 }}
        contentsOption={{ textAlign: "right" }}
      />
      {displayWeight && totalWeight > 0 ? (
        <Section
          title={<FormattedMessage id="summary_total_weight" />}
          contents={`${totalWeight} kg`}
          titleOption={{ maxWidth: 170 }}
          contentsOption={{ textAlign: "right" }}
        />
      ) : null}
      <Section
        title={
          totalProductTexts ? (
            totalProductTexts.singular
          ) : (
            <FormattedMessage id="my_shopping/purchase_details/total_product_price" />
          )
        }
        contents={
          <PriceWithAdditionalFeesWrapper>
            <PriceWithAdditionalFees
              currency={payment.currency}
              price={productPrice}
              additionalFees={payment.additionalFees}
            />
          </PriceWithAdditionalFeesWrapper>
        }
        titleOption={{ maxWidth: 170 }}
        contentsOption={{ textAlign: "right", isBold: true }}
      />
      {shippingRequired ? (
        <Section
          title={
            <FormattedMessage id="my_shopping/purchase_details/total_shipping_price" />
          }
          contents={
            isFlexibleShippingFee ? (
              <FormattedMessage id="price_shipping_total_tbd" />
            ) : (
              <CurrencyFormatter
                prefix="+"
                currency={payment.currency}
                value={shippingFee}
              />
            )
          }
          titleOption={{ maxWidth: 170 }}
          contentsOption={{ textAlign: "right" }}
        />
      ) : null}
      {!isFundPayment && payment.userCoupons?.length ? (
        <Section
          title={
            <FormattedMessage id="my_shopping/purchase_details/coupon_discount" />
          }
          contents={
            <CurrencyFormatter
              prefix="-"
              currency={payment.currency}
              value={payment.couponDiscount}
            />
          }
          titleOption={{ maxWidth: 170 }}
          contentsOption={{ textAlign: "right" }}
        />
      ) : null}
      <Spacer value={8} />
      <DefaultDivider />
      <Spacer value={8} />
      <Section
        title={
          <FormattedMessage id="my_shopping/purchase_details/total_payment" />
        }
        contents={
          <PriceWithAdditionalFeesWrapper>
            <PriceWithAdditionalFees
              currency={payment.currency}
              price={payment.amount + payment.usedCredit}
              additionalFees={payment.additionalFees}
            />
          </PriceWithAdditionalFeesWrapper>
        }
        titleOption={{ maxWidth: 170 }}
        contentsOption={{ textAlign: "right", isBold: true }}
      />
      {!isFundPayment && (
        <>
          {payment.usedCoins?.map(usedCoin => (
            <UsedCoinSection
              key={`${usedCoin.coinId}_${usedCoin.amount}`}
              coinId={usedCoin.coinId}
              amount={usedCoin.amount}
              price={usedCoin.price}
            />
          ))}

          {payment.additionalFees
            ?.filter(fee => fee.type === "coin" && fee.resourceId)
            .map(fee => (
              <AdditionalFeeSection
                key={`${fee.resourceId}_${fee.amount}`}
                additionalFee={fee}
              />
            ))}
        </>
      )}

      {payment.amount ? (
        <Section
          title={paymentMethodTitle}
          contents={
            <>
              <CurrencyFormatter
                currency={payment.actualCurrency}
                value={payment.actualAmount}
              />
              <br />
              <CurrencyFormatter
                currency={payment.currency}
                value={payment.amount}
              >
                {({ currency, value }) =>
                  payment.actualCurrency !== currency
                    ? `(${value} ${currency})`
                    : null
                }
              </CurrencyFormatter>
            </>
          }
          titleOption={{ maxWidth: 170 }}
          contentsOption={{ textAlign: "right" }}
        />
      ) : null}
      {paymentMethodInformation}
      {!isFundPayment && creditAmount ? (
        <>
          <Spacer value={8} />
          <DefaultDivider />
          <Spacer value={8} />

          <Section
            title={
              <FormattedMessage id="my_shopping/purchase_details/total_credit" />
            }
            contents={
              <CurrencyFormatter
                currency={payment.currency}
                value={creditAmount}
              />
            }
            titleOption={{ maxWidth: 170 }}
            contentsOption={{ textAlign: "right", isBold: true }}
          />
        </>
      ) : null}
    </Wrapper>
  );
}
