import React from "react";
import { InputFormItem } from "./input";
import { EmailFormItem } from "./email";
import { TextareaFormItem } from "./textarea";
import { PlaintextFormItem } from "./plaintext";
import { SectionFormItem } from "./section";
import { PhoneFormItem } from "./phone";
import { UserTagGroupFormItem } from "./tagGroup";
import { SimpleDate } from "./simpleDate";
import { SingleSelectionFormItem } from "./selection/single";
import { MultiSelectionFormItem } from "./selection/multi";

export interface IRef {
  validate(): Record<Moim.Id, any>;
}
export const FormItemRenderer = React.forwardRef<
  IRef,
  { item: Moim.Form.IFormItem }
>(({ item }, ref) => {
  switch (item.type) {
    case "input":
      return <InputFormItem ref={ref} item={item} />;
    case "email":
      return <EmailFormItem ref={ref} item={item} />;
    case "textarea":
      return <TextareaFormItem ref={ref} item={item} />;
    case "plaintext":
      return <PlaintextFormItem item={item} />;
    case "section":
      return <SectionFormItem ref={ref} item={item} />;
    case "singleSelection":
      return <SingleSelectionFormItem ref={ref} item={item} />;
    case "multiSelection":
      return <MultiSelectionFormItem ref={ref} item={item} />;

    case "phone":
      return <PhoneFormItem ref={ref} item={item} />;
    case "simpleDate":
      return <SimpleDate ref={ref} item={item} />;
    case "userTagGroup":
      return <UserTagGroupFormItem ref={ref} item={item} />;

    default:
      return null;
  }
});
