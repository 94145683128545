import useGroupTexts from "common/hooks/useGroupTexts";
import React from "react";
import { FormattedMessage } from "react-intl";

interface IProps {
  user: Moim.User.INormalizedUser | null;
  web3UserName: string;
  hasJoinablePermission?: boolean;
}

const DquestPermissionMessage = ({
  user,
  web3UserName,
  hasJoinablePermission,
}: IProps) => {
  const captionNoRightText = useGroupTexts("quest_progress_caption_no_right")
    ?.singular;

  if (user) {
    if (!hasJoinablePermission) {
      return <FormattedMessage id={captionNoRightText} />;
    }
    return (
      <FormattedMessage
        id="quest_progress_caption_before_start"
        values={{ user_name_address: web3UserName }}
      />
    );
  }
  return (
    <FormattedMessage
      id="quest_progress_caption_before_start_non_login"
      values={{ user_name_address: web3UserName }}
    />
  );
};

export default DquestPermissionMessage;
