import React, { useContext } from "react";
import { isUndefined, omitBy } from "lodash";

export type BlockitRenderMode = "editor" | "preview" | "live";

interface IBlockitRendererContext {
  estimatedWidth: number;
  renderMode?: BlockitRenderMode; // NOTE: default is "live"
  backgroundColor?: string;
  prefetchedData?: {
    productSetProductIds?: Record<Moim.Id, string[]>;
    contentsGroup?: Record<Moim.Id, Moim.ContentsGroup.IContentsGroupData>;
  };
}

const blockContextInitialValue: IBlockitRendererContext = {
  estimatedWidth: window?.innerWidth ?? 720,
  renderMode: "live",
  backgroundColor: undefined,
};

export const BlockitRendererContext = React.createContext<
  IBlockitRendererContext
>(blockContextInitialValue);

export const BlockitRendererContextProvider = ({
  children,
  ...props
}: IBlockitRendererContext & React.PropsWithChildren) => {
  const currentValues = useContext(BlockitRendererContext);

  /**
   * 상위 Provider에서 설정된 Value 들을 재사용한다
   */
  const updated = { ...currentValues, ...omitBy(props, isUndefined) };

  return (
    <BlockitRendererContext.Provider value={updated}>
      {children}
    </BlockitRendererContext.Provider>
  );
};

export function useBlockitRendererContext() {
  return useContext(BlockitRendererContext);
}
