import * as React from "react";
import MultiMedia from "./multiMedia";

interface IProps {
  parentId: Moim.Id;
  images?: Moim.IImage[];
  altPrefix?: string;
}

const MultiImage: React.FC<IProps> = ({
  parentId,
  images: _images,
  altPrefix,
}) => {
  const images = React.useMemo(
    () => _images?.map(img => ({ type: "image" as const, ...img })),
    [_images],
  );
  return (
    <MultiMedia parentId={parentId} images={images} altPrefix={altPrefix} />
  );
};

export default React.memo(MultiImage);
