import {
  useOpacityHoverStyle,
  useSingleLineStyle,
} from "common/components/designSystem/styles";
import { B4Regular } from "common/components/designSystem/typos";
import { px2rem } from "common/helpers/rem";
import styled from "styled-components";

export const StatWrapper = styled.div<{
  textAlign?: Moim.Forum.ForumListConfigTextAlignment;
}>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: ${px2rem(16)};
  color: ${(props) => props.theme.colorV2.colorSet.grey300};
  padding: ${px2rem(8)} ${px2rem(16)};
  & * {
    color: ${(props) => props.theme.colorV2.colorSet.grey800};
  }
`;

export const Wrapper = styled.div<{
  textAlign?: Moim.Forum.ForumListConfigTextAlignment;
}>`
  width: 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${px2rem(16)};
  color: ${(props) => props.theme.colorV2.colorSet.grey300};
  padding-inline: ${px2rem(16)};
  & * {
    color: ${(props) => props.theme.colorV2.colorSet.grey800};
  }
`;

export const ReactionStat = styled(B4Regular)``;

export const Label = styled.span`
  margin-left: ${px2rem(4)};
`;

export const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${px2rem(4)};
  width: fit-content;
  transition: opacity 200ms ease-in;
  padding-block: ${px2rem(13)};

  ${useSingleLineStyle};

  &:hover {
    ${useOpacityHoverStyle}
  }
`;
