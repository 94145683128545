// hooks
import { useActions, useStoreState } from "app/store";
import { withPlacement } from "../../../hoc/withPlacement";
import { Inner } from "./styled";
import { getItem as getItemAction } from "app/actions/nft";
import Layout from "./components/layout";
import Media from "./components/media";
import Detail from "./components/detail";
import Description from "./components/description";
import Property from "./components/property";
import Skeleton from "./skeleton";
import { useRedirectToMoimURL } from "common/hooks/useSecondaryView";
import { MoimURL } from "common/helpers/url";
import InViewTrigger from "../../../components/inViewTrigger";
import React, { memo, useCallback, useState } from "react";
import MoreButton from "../../../components/moreButton";
import { useIntlShort } from "common/hooks/useIntlShort";
import { Spacer } from "common/components/designSystem/spacer";

const NFTSummaryBlock: React.FC<Moim.Blockit.V2.WEB3.INFTSummaryBlock> = ({
  resourceId,
  showConfig,
  moreButton,
}) => {
  const intl = useIntlShort();
  const [isLoading, setLoadingStatus] = useState<boolean | null | undefined>(
    undefined,
  );

  const redirect = useRedirectToMoimURL();

  const { dispatchGetItem } = useActions({
    dispatchGetItem: getItemAction,
  });

  const item = useStoreState(state => state.entities.nftItems[resourceId]);
  const schedule = useStoreState(state =>
    item?.scheduleId ? state.entities.nftSchedules[item.scheduleId] : undefined,
  );
  const contract = useStoreState(state =>
    item?.contractId ? state.entities.nftContracts[item.contractId] : undefined,
  );

  const handleNftShowPage = useCallback(() => {
    redirect(
      new MoimURL.NftShow({
        nftItemId: resourceId,
      }).toString(),
    );
  }, [redirect, resourceId]);

  const handleGetData = useCallback(async () => {
    try {
      if (!item && resourceId) {
        await dispatchGetItem(resourceId);
      }
      setLoadingStatus(false);
    } catch {
      setLoadingStatus(null);
    }
  }, [dispatchGetItem, item, resourceId]);

  const handleOnView = useCallback(() => {
    if (isLoading === undefined) {
      handleGetData();
    }
  }, [handleGetData, isLoading]);

  return (
    <>
      <InViewTrigger onVisible={handleOnView} />
      {isLoading !== false || !contract ? (
        <Skeleton />
      ) : (
        <Inner role="button" onClick={handleNftShowPage}>
          <Layout
            mediaElement={
              <Media
                key={item.itemUrl}
                url={item.itemUrl}
                name={item.name}
                poster={item.itemStaticPreviewUrl}
                metadata={item.itemMetadata}
                onClick={handleNftShowPage}
              />
            }
            detailElement={
              schedule ? (
                <Detail
                  collectionName={contract.name}
                  itemId={item.id}
                  itemName={item.name}
                  itemStatus={item.status}
                  ownerId={item.ownedBy?.userId ?? undefined}
                  ownerAddress={item.ownedByAddress}
                  price={schedule.price}
                  currency={contract.currency}
                  network={contract.network}
                  mintable={item.mintable}
                  scheduleMintable={schedule.mintable}
                  startAt={schedule.mintingStartAt}
                  endAt={schedule.mintingEndAt}
                  config={showConfig}
                  contractId={contract.id}
                />
              ) : (
                undefined
              )
            }
            propertyElement={
              showConfig.showAttributes && (
                <Property
                  attributes={item.attributes ?? null}
                  config={showConfig.propertyConfig}
                />
              )
            }
            descriptionElement={
              <Description
                showItemDescription={showConfig.showItemDescription}
                showDetail={showConfig.showDetail}
                description={item.description}
                contract={contract}
                tokenId={item.tokenId}
              />
            }
          />
        </Inner>
      )}
      {moreButton?.show ? (
        <>
          <Spacer value={24} />
          <MoreButton
            text={intl("button_see_more_nft_group")}
            onClick={handleNftShowPage}
          />
        </>
      ) : null}
    </>
  );
};

export default withPlacement(memo(NFTSummaryBlock));
