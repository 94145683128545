import React from "react";
import styled, { css } from "styled-components";

import BuyersCount from "../buyersCount";
import Credit from "../credit";
import Description from "../description";
import DiscountPrice from "../discountPrice";
import Image from "../image";
import Price from "../price";
import ProductName from "../productName";
import ProgressBar from "../progressBar";
import GoalAmount from "../goalAmount";
import SoldAmount from "../soldAmount";
import Rating from "../rating";
import Seller from "../seller";
import Shipping from "../shipping";
import Stat from "../stat";
import Status from "../status";
import StockCount from "../stockCount";
import TimeSaleTimer from "../timeSaleTimer";
import BuyNowButton from "../buyNowButton";
import AddCartButton from "../cartButton";
import TeamBuyingBadgeTitle from "../teamBuying/badgeTitle";
import TotalBuyerCount from "../totalBuyerCount";
import TeamBuyingButton from "../teamBuying/button";
import ProductItemWrapper from "../wrapper/product";
import FundingItemWrapper from "../wrapper/funding";
import brand from "../brand";

const commonStyle = css<{
  block: Moim.Component.ProductItem.IBaseElement;
}>`
  ${props =>
    props.block.flex &&
    css`
      flex: ${props.block.flex};
      min-width: 0;
    `}
`;

export const StyledBuyersCount = styled(BuyersCount)`
  ${commonStyle}
`;
export const StyledCredit = styled(Credit)`
  ${commonStyle}
`;
export const StyledDescription = styled(Description)`
  ${commonStyle}
`;
export const StyledDiscountPrice = styled(DiscountPrice)`
  ${commonStyle}
`;

export const StyledBrand = styled(brand)`
  ${commonStyle}
`;
export const StyledImage = styled(Image)<{ disabled?: boolean }>`
  ${commonStyle}
  opacity: ${props => (props.disabled ? 0.4 : "")};
`;
export const StyledPrice = styled(Price)`
  ${commonStyle}
`;
export const StyledProductName = styled(ProductName)`
  ${commonStyle}
`;
export const StyledProgressBar = styled(ProgressBar)`
  ${commonStyle}
`;
export const StyledSoldAmount = styled(SoldAmount)`
  ${commonStyle}
`;
export const StyledGoalAmount = styled(GoalAmount)`
  ${commonStyle}
`;
export const StyledRating = styled(Rating)`
  ${commonStyle}
`;
export const StyledSeller = styled(Seller)`
  ${commonStyle}
`;
export const StyledShipping = styled(Shipping)`
  ${commonStyle}
`;
export const StyledStat = styled(Stat)`
  ${commonStyle}
`;
export const StyledStatus = styled(Status)`
  ${commonStyle}
`;
export const StyledStockCount = styled(StockCount)`
  ${commonStyle}
`;
export const StyledTimeSaleTimer = styled(TimeSaleTimer)`
  ${commonStyle}
`;
export const StyledBuyNowButton = styled(BuyNowButton)`
  ${commonStyle}
`;
export const StyledTeamBuyingButton = styled(TeamBuyingButton)`
  ${commonStyle}
`;
export const StyledAddCartButton = styled(AddCartButton)`
  ${commonStyle}
`;
export const StyledTeamBuyingBadgeTitle = styled(TeamBuyingBadgeTitle)`
  ${commonStyle}
`;
export const StyledTotalBuyerCount = styled(TotalBuyerCount)`
  ${commonStyle}
`;

export const StyledProductItemWrapper = styled(
  (props: React.ComponentProps<typeof ProductItemWrapper>) => (
    <ProductItemWrapper {...props} />
  ),
)`
  ${commonStyle}
`;
export const StyledFundingItemWrapper = styled(
  (props: React.ComponentProps<typeof FundingItemWrapper>) => (
    <FundingItemWrapper {...props} />
  ),
)`
  ${commonStyle}
`;
