import React from "react";
import { FormattedMessage } from "react-intl";

import useGroupTexts from "common/hooks/useGroupTexts";

import WithPositionChip from "common/components/withPositionChip/new";
import { PaddedWrapper, SubjectWrapper } from "../styled";

interface AvailablePositionsProps {
  positions: string[];
  hasPositionChip?: boolean;
}

const AvailablePositions = ({
  positions,
  hasPositionChip,
}: AvailablePositionsProps) => {
  const wrappedPositions = React.useMemo(
    () => (typeof positions === "string" ? [positions] : positions),
    [positions],
  );

  const availablePositionText = useGroupTexts("quest_right_set_to_join");
  return (
    <PaddedWrapper>
      <SubjectWrapper>
        <div className="field">{availablePositionText?.singular}</div>
        <div className="list positions">
          {hasPositionChip ? (
            <WithPositionChip
              displayChipLimit={99}
              positions={wrappedPositions}
              hasPositionChip={true}
            />
          ) : (
            <FormattedMessage id="quest_right_set_to_join_empty" />
          )}
        </div>
      </SubjectWrapper>
    </PaddedWrapper>
  );
};

export default AvailablePositions;
