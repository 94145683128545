/* eslint-disable no-underscore-dangle */
import { InitialLoadData } from "app/routes/ssrLoadData";
import { ISSRLoadDataPayload } from "app/typings/ssr";
import { MoimAPI } from "common/api";
import { setInitialData } from "common/helpers/initialData";
import _ from "lodash";

async function loadPermission(
  requestId: string,
  api: MoimAPI,
  resourceId: string,
) {
  const permissions = await api.permission.getPermission({
    resource: resourceId,
  });

  setInitialData(`__resourcePermissions.${resourceId}`, requestId, permissions);
}

async function loadChannel(requestId: string, api: MoimAPI, channelId: string) {
  const channel = await api.channel.getChannel({ channelId });
  setInitialData("__entities.forum", requestId, channel);
}

export async function ViewLoadData({
  requestId,
  match,
  api,
}: ISSRLoadDataPayload) {
  await InitialLoadData(requestId);

  const promises: Promise<void>[] = [];

  if (match.params.viewId) {
    promises.push(loadPermission(requestId, api, match.params.viewId));
    promises.push(loadChannel(requestId, api, match.params.viewId));
  }

  await Promise.all(promises);
}
