import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { B3RegularStyle } from "common/components/designSystem/typos";
import { px2rem } from "common/helpers/rem";
import { MoimURL } from "common/helpers/url";

const Wrapper = styled.div`
  width: 100%;
  margin-top: ${px2rem(42)};
  color: ${props => props.theme.colorV2.colorSet.grey700};
`;

const LinkButton = styled(Link)`
  :hover {
    font-style: underline;
  }
  ${B3RegularStyle}
`;

interface IProps {
  userId: Moim.Id;
  postCount: number;
}

const PostWritingStat: React.FC<IProps> = ({ userId, postCount }) => (
  <Wrapper>
    <LinkButton
      to={new MoimURL.UserThreadList({ userId }).toString(undefined, {
        types: "post",
        tab: "post",
      })}
    >
      <FormattedMessage
        id="profile_user_bio_written_post"
        values={{ n: postCount }}
      />
    </LinkButton>
  </Wrapper>
);

export default PostWritingStat;
