// vendor
import * as React from "react";
// component
import { FormattedMessage } from "react-intl";
import Snackbar from "common/components/snackbar";
import {
  SnackBarWrapper,
  UnreadSnackBarContent,
  TopUnreadIcon,
  BottomUnreadIcon,
  TopMentionIcon,
  BottomMentionIcon,
  snackbarStyle,
} from "./styled";
import { NavigationPanelContext } from "../../context";

export type UnreadSnackBarType = "unread" | "mention";
interface IProps {
  headerHeight?: number;
  footerHeight?: number;
}

function getUnreadText(type: UnreadSnackBarType) {
  if (type === "mention") {
    return "side_navigation/unread_mentions";
  }

  return "side_navigation/more_unreads";
}

function getSnackBarIcon(
  direction: "top" | "bottom",
  type: UnreadSnackBarType,
) {
  if (direction === "top" && type === "unread") {
    return <TopUnreadIcon />;
  } else if (direction === "bottom" && type === "unread") {
    return <BottomUnreadIcon />;
  } else if (direction === "top" && type === "mention") {
    return <TopMentionIcon />;
  } else {
    return <BottomMentionIcon />;
  }
}

function NavigationPanelUnreadSnackBar({ headerHeight, footerHeight }: IProps) {
  const { bottomUnreadSnackBar, topUnreadSnackBar } = React.useContext(
    NavigationPanelContext,
  );
  const handleClickUnreadSnackBar = React.useCallback(
    (target: "top" | "bottom") => {
      if (target === "top" && topUnreadSnackBar.open) {
        const channelElement = document.querySelector<HTMLElement>(
          `[data-side-navigation-channel-id=${topUnreadSnackBar.openBy}]`,
        );
        channelElement?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }

      if (target === "bottom" && bottomUnreadSnackBar.open) {
        const channelElement = document.querySelector<HTMLElement>(
          `[data-side-navigation-channel-id=${bottomUnreadSnackBar.openBy}]`,
        );
        channelElement?.scrollIntoView({
          behavior: "smooth",
          block: "end",
        });
      }
    },
    [topUnreadSnackBar, bottomUnreadSnackBar],
  );
  const handleClickTopUnreadBadge = React.useCallback(() => {
    handleClickUnreadSnackBar("top");
  }, [handleClickUnreadSnackBar]);

  const handleClickBottomUnreadBadge = React.useCallback(() => {
    handleClickUnreadSnackBar("bottom");
  }, [handleClickUnreadSnackBar]);

  return (
    <>
      {topUnreadSnackBar.open && (
        <SnackBarWrapper transitionDirection="top" position={headerHeight}>
          <Snackbar
            isOpen={topUnreadSnackBar.open}
            content={
              topUnreadSnackBar.type && (
                <>
                  {getSnackBarIcon("top", topUnreadSnackBar.type)}
                  <UnreadSnackBarContent>
                    <FormattedMessage
                      id={getUnreadText(topUnreadSnackBar.type)}
                    />
                  </UnreadSnackBarContent>
                </>
              )
            }
            transitionDirection="top"
            wrapperStyle={snackbarStyle}
            onClickSnackbar={handleClickTopUnreadBadge}
          />
        </SnackBarWrapper>
      )}

      {bottomUnreadSnackBar.open && (
        <SnackBarWrapper transitionDirection="bottom" position={footerHeight}>
          <Snackbar
            isOpen={bottomUnreadSnackBar.open}
            content={
              bottomUnreadSnackBar.type && (
                <>
                  {getSnackBarIcon("bottom", bottomUnreadSnackBar.type)}
                  <UnreadSnackBarContent>
                    <FormattedMessage
                      id={getUnreadText(bottomUnreadSnackBar.type)}
                    />
                  </UnreadSnackBarContent>
                </>
              )
            }
            transitionDirection="bottom"
            wrapperStyle={snackbarStyle}
            onClickSnackbar={handleClickBottomUnreadBadge}
          />
        </SnackBarWrapper>
      )}
    </>
  );
}

export default NavigationPanelUnreadSnackBar;
