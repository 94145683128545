import { Wrapper } from "./styled";
import LazyBlurHashImage from "common/components/lazyBlurHashImage";
import OverlayInfo from "common/components/thread/commonThreadItem/components/common/thumbnailLock/overlayInfo";
import React from "react";

const THUMBNAIL_LOCK_IMAGE_LIMIT = 1;

const ThumbnailLock: React.FC<
  Pick<Moim.Blockit.V2.ICarouselBlock, "images" | "style"> & {
    mediaBlocksCount: number;
  }
> = ({ images, style, mediaBlocksCount }) => {
  const { width, height } = style;

  const mediaElements = React.useMemo(
    () =>
      images.slice(0, THUMBNAIL_LOCK_IMAGE_LIMIT).map((media, index) => {
        if (
          mediaBlocksCount > THUMBNAIL_LOCK_IMAGE_LIMIT &&
          index === THUMBNAIL_LOCK_IMAGE_LIMIT - 1
        ) {
          return (
            <OverlayInfo
              text={`+${mediaBlocksCount - THUMBNAIL_LOCK_IMAGE_LIMIT}`}
            >
              <LazyBlurHashImage
                src={media.src ?? media.imageSrc}
                alt={(media.src ?? media.imageSrc).split("/").pop() ?? ""}
                blurHash={media.blurHash ?? media.blur_hash}
                fallBackSrc={media.fallbackSrc ?? media.src ?? media.imageSrc}
                width={width}
                height={height}
              />
            </OverlayInfo>
          );
        }
        return (
          <LazyBlurHashImage
            src={media.src ?? media.imageSrc}
            alt={(media.src ?? media.imageSrc).split("/").pop() ?? ""}
            blurHash={media.blurHash ?? media.blur_hash}
            fallBackSrc={media.fallbackSrc ?? media.src ?? media.imageSrc}
            width={width}
            height={height}
          />
        );
      }),
    [height, images, mediaBlocksCount, width],
  );

  return <Wrapper>{mediaElements}</Wrapper>;
};

export default React.memo(ThumbnailLock);
