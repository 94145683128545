import { isServer } from "common/helpers/envChecker";
import useCurrentGroup from "./useCurrentGroup";
import { parse } from "url";

function useHost() {
  const group = useCurrentGroup();

  if (isServer()) {
    return parse(group?.url ?? "").hostname ?? "";
  }

  return window.location.host;
}

export default useHost;
