import React from "react";
import moment from "moment";
import styled from "styled-components";

import { px2rem } from "common/helpers/rem";

import ProductGroup from "./productGroup";

import { DeliveryOptionText } from "app/modules/commerce/components/carts/components/productGroup/components/productGroupBox/styled";
import { PickUpScheduleText } from "../common";
import { FlexWrapper } from "common/components/skeleton";

interface ProductGroupItemProps {
  item: Moim.Commerce.IPurchaseDeliveryProductGroup;
  purchase: Moim.Commerce.IPurchase;
}

const SubInfoWrapper = styled(FlexWrapper)`
  flex-direction: column;
  gap: ${px2rem(4)};
`;

const ProductGroupItem = ({ item, purchase }: ProductGroupItemProps) => {
  const price = item.totalPrice ?? 0;
  const deliveryFee = item.deliveryFee ?? 0;

  const isAllPickUpItems = item.items.every(
    i => i.deliveryGroup?.policy.type === "pickUp",
  );

  let activeDate = false;
  let activeTime = false;

  if (item?.deliveryGroup?.policy?.type === "pickUp") {
    activeDate = item?.deliveryGroup?.policy?.timeConfig?.activeDate ?? false;
    activeTime = item?.deliveryGroup?.policy?.timeConfig?.activeTime ?? false;
  }

  const date = React.useMemo(
    () =>
      item.items[0].pickUpTime && activeDate
        ? moment(item.items[0].pickUpTime).format("YYYY.MM.DD")
        : undefined,
    [activeDate, item.items],
  );
  const time = React.useMemo(
    () =>
      item.items[0].pickUpTime && activeTime
        ? moment(item.items[0].pickUpTime).format("LT")
        : undefined,
    [activeTime, item.items],
  );

  const optionName = item.deliveryGroup &&
    item.deliveryGroup.policy.type === "pickUp" && (
      <DeliveryOptionText>{item.deliveryGroup.policy.name}</DeliveryOptionText>
    );

  const subInfo = React.useMemo(
    () => (
      <SubInfoWrapper>
        <PickUpScheduleText date={date} time={time} />
        {optionName}
      </SubInfoWrapper>
    ),
    [date, optionName, time],
  );

  return (
    <ProductGroup
      showPickUptime={false}
      subInfo={subInfo}
      deliveryType={isAllPickUpItems ? "pickUp" : item.type}
      purchaseItems={item.items}
      displayStatus={purchase.displayingStatus}
      currency={purchase.currency}
      price={price}
      deliveryFee={deliveryFee}
      additionalFees={item.totalAdditionalFees}
    />
  );
};

export default ProductGroupItem;
