import React from "react";

import { DeliveryPolicyPriceRow } from "../styled";
import CurrencyFormatter from "common/components/currencyFormatter";

import { useIntlShort } from "common/hooks/useIntlShort";

interface IProps {
  isLast: boolean;
  isFirst: boolean;
  price: Moim.Commerce.IDeliveryPolicyPrice;
}
const DeliveryPolicyRow: React.FC<IProps> = ({ isLast, isFirst, price }) => {
  const intl = useIntlShort();
  let left: React.ReactNode = null;

  if (isLast && price.lt === undefined) {
    left = intl("shipping_price_dialog_column_title_product_price_last", {
      product_price: (
        <CurrencyFormatter
          currency={price.gte_price?.currency}
          value={price.gte_price?.value}
        />
      ),
    });
  } else if (isFirst && (price.gte_price?.numValue ?? 0) === 0) {
    left = intl("shipping_price_dialog_column_title_product_price_first", {
      product_price: (
        <CurrencyFormatter
          currency={price.lt_price?.currency}
          value={price.lt_price?.value}
        />
      ),
    });
  } else {
    left = intl("shipping_price_dialog_column_title_product_price_middle", {
      product_price_1: (
        <CurrencyFormatter
          currency={price.gte_price?.currency}
          value={price.gte_price?.value}
        />
      ),
      product_price_2: (
        <CurrencyFormatter
          currency={price.lt_price?.currency}
          value={price.lt_price?.value}
        />
      ),
    });
  }

  return (
    <DeliveryPolicyPriceRow>
      <span className="left">{left}</span>
      <span className="right">
        <CurrencyFormatter
          currency={price.price_price.currency}
          value={price.price_price.value}
        />
      </span>
    </DeliveryPolicyPriceRow>
  );
};

export default React.memo(DeliveryPolicyRow);
