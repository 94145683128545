import * as React from "react";
import { tagSetBlockExtractor } from "../../modules/forum/containers/forumEditor/helper";
import useTagGroupFetchSelector from "common/hooks/useEntitiesFetchSelectors/useTagGroupFetchSelector";

/**
 * hook to get tagGroups and tags data by thread content
 * @param thread Moim.Forum.IThread
 * @returns tagGroups, tags
 */

const useGetTagsFromThread = (thread: Moim.Forum.IThread) => {
  const { selectedTags } = React.useMemo(
    () => tagSetBlockExtractor(thread?.content ?? []),
    [thread.content],
  );
  const tagIds = Object.values(selectedTags ?? {}).flat();
  const tagGroupIds = Object.keys(selectedTags ?? {});

  const { tagGroups } = useTagGroupFetchSelector(tagGroupIds);
  const tags = React.useMemo(
    () =>
      tagGroups
        .flatMap(tagGroup => tagGroup.items)
        .filter(tag => tagIds.includes(tag.id)),
    [tagGroups, tagIds],
  );

  return { tagGroups, tags };
};

export default useGetTagsFromThread;
