import { PopoverOrigin } from "@material-ui/core/Popover";
import { push } from "connected-react-router";
import { ThunkPromiseResult } from "app/store";
import { ActionCreators as AppActionCreators } from "./app";
import { showNotification } from "common/helpers/browserNotification";
import { browserLocale } from "app/intl";
import { NotificationTypes } from "./types";
import { ActionUnion } from "./helpers";
import MoimDefaultAPI from "common/api";
import { notificationListNormalizer } from "app/models/notification";
import { loadEntities } from "./entity";
import { ItemIdTypes } from "app/enums";
import { ActionCreators as ForumActionCreators } from "app/actions/forum";
import { keys } from "lodash";

function createAction<T extends { type: NotificationTypes }>(d: T): T {
  return d;
}

export const ActionCreators = {
  startFetchingNotifications: (
    type: Moim.Enums.NotificationType,
    id: Moim.Id,
  ) =>
    createAction({
      type: NotificationTypes.START_FETCHING_NOTIFICATIONS,
      payload: {
        type,
        id,
      },
    }),
  succeedFetchingNotifications: (
    type: Moim.Enums.NotificationType,
    notifications: Moim.IPaginatedListResponse<Moim.Id>,
    fetchDirection: "before" | "after" | null,
  ) =>
    createAction({
      type: NotificationTypes.SUCCEED_FETCHING_NOTIFICATIONS,
      payload: {
        type,
        notifications,
        fetchDirection,
      },
    }),
  failedFetchingNotifications: (type: Moim.Enums.NotificationType) =>
    createAction({
      type: NotificationTypes.FAILED_FETCHING_NOTIFICATIONS,
      payload: {
        type,
      },
    }),

  openNotificationsDialog: (payload: {
    anchorElement: React.RefObject<any> | null;
    anchorOrigin?: PopoverOrigin;
    transformOrigin?: PopoverOrigin;
  }) =>
    createAction({
      type: NotificationTypes.OPEN_NOTIFICATIONS_DIALOG,
      payload,
    }),

  closeNotificationsDialog: () =>
    createAction({
      type: NotificationTypes.CLOSE_NOTIFICATIONS_DIALOG,
    }),

  readNotification: ({
    id,
    type,
  }: {
    id: string;
    type: Moim.Notification.NotificationType;
  }) =>
    createAction({
      type: NotificationTypes.READ_NOTIFICATION,
      payload: {
        id,
        type,
      },
    }),
};

export type Actions = ActionUnion<typeof ActionCreators>;

function parseRedirectTo(redirectTo: string, channelType?: Moim.Channel.Type) {
  switch (channelType) {
    case "forum":
      return redirectTo.split("#").join("/replies/");
    default:
      return redirectTo;
  }
}

export function showMentionNotification(
  mention: Moim.WebsocketEvent.Notification,
): ThunkPromiseResult {
  return async (dispatch, getState) => {
    const state = getState();
    const payload = mention.payload;

    const locale = browserLocale(state.app.locale || undefined);
    const preview =
      payload.webPrv.plainContent[locale] ??
      payload.webPrv.plainContent[keys(payload.webPrv.plainContent)[0]];
    const groupName = payload.webPrv.groupName;
    const groupIcon = payload.webPrv.groupIcon;

    if (preview) {
      const onClick = () => {
        if (payload.host !== `https://${location.host}`) {
          const win = window.open(
            `${payload.host}${parseRedirectTo(
              payload.uri,
              payload.ct as Moim.Channel.Type,
            )}`,
            "_blank",
          );
          win?.focus();
        } else {
          dispatch(
            push(parseRedirectTo(payload.uri, payload.ct as Moim.Channel.Type)),
          );

          const threadId = [payload.iid, payload.pid].find(id =>
            id.startsWith(ItemIdTypes.THREAD),
          );

          if (threadId) {
            dispatch(ForumActionCreators.updateHighlightThreadId(threadId));
          }
        }
      };

      showNotification(groupName || "", {
        body: preview,
        icon: groupIcon,
        link: payload.uri,
        vibrate: true,
        onClick,
      });
    }
  };
}

export function getNotifications(
  ...params: Parameters<typeof MoimDefaultAPI.me.getNotifications>
): ThunkPromiseResult {
  return async (dispatch, getState, { apiSelector }) => {
    try {
      const groupId = getState().app.currentGroupId;

      dispatch(
        ActionCreators.startFetchingNotifications(
          params[0].type,
          groupId ?? "",
        ),
      );
      const notifications = notificationListNormalizer(
        await apiSelector(getState(), dispatch).me.getNotifications(...params),
      );

      dispatch(loadEntities(notifications.entities));
      dispatch(
        ActionCreators.succeedFetchingNotifications(
          params[0].type,
          notifications.result,
          Boolean(params[0].after)
            ? "after"
            : Boolean(params[0].before)
            ? "before"
            : null,
        ),
      );
    } catch {
      dispatch(ActionCreators.failedFetchingNotifications(params[0].type));
    }
  };
}

export function readNotification(
  id: string,
  sortKey: string,
  type: Moim.Notification.NotificationType,
): ThunkPromiseResult {
  return async (dispatch, getState, { apiSelector }) => {
    await apiSelector(getState(), dispatch).me.readNotification(sortKey);
    const key = sortKey.slice(1);

    dispatch(ActionCreators.readNotification({ id: `${key}_${id}`, type }));
  };
}

export function doMute(): ThunkPromiseResult {
  return async dispatch => {
    dispatch(AppActionCreators.userMuteGlobalNotification());
  };
}
