import * as React from "react";
import { UserStatusTypes } from "app/enums";
import { useOpenProfileDialog } from "common/hooks/profileDialog";
import usePositionFetchSelector from "common/hooks/useEntitiesFetchSelectors/usePositionFetchSelector";
import UserProfileImage from "common/components/userProfileImage";
import WithPositionChip from "common/components/withPositionChip";
import ShavedText from "common/components/shavedText";
import { NativeEmojiSafeText } from "common/components/designSystem/texts";
import { Wrapper, Username, Description } from "./styled";

interface IProps {
  user: Moim.User.IOriginalUserDatum & {
    certifications?: Moim.User.NormalizedCertificationList;
  };
}

const SimpleUserProfilePreview: React.FC<IProps> = ({ user }) => {
  const isIdPositions =
    user.positions?.length && typeof user.positions[0] === "string";
  const { positions } = usePositionFetchSelector(
    isIdPositions ? user.positions : [],
  );
  const openProfileDialog = useOpenProfileDialog();
  const handleNameClick: React.MouseEventHandler<any> = React.useCallback(
    e => {
      if (user && user.status !== UserStatusTypes.DEACTIVATED) {
        openProfileDialog(user.id, { current: e.currentTarget });
      }
    },
    [openProfileDialog, user],
  );

  return (
    <Wrapper>
      <div className="left">
        <UserProfileImage
          size="l"
          aria-label="footer author avatar"
          userId={user.id}
          src={user.avatar_url}
        />
      </div>
      <div className="right">
        <Username>
          <WithPositionChip
            positions={isIdPositions ? positions : user.positions}
          >
            <ShavedText
              role="button"
              aria-label={user.name}
              onClick={handleNameClick}
              value={<NativeEmojiSafeText value={user.name} />}
              line={1}
            />
          </WithPositionChip>
        </Username>
        {user.bio ? (
          <Description>
            <ShavedText
              value={<NativeEmojiSafeText value={user.bio} />}
              line={2}
            />
          </Description>
        ) : null}
      </div>
    </Wrapper>
  );
};

export default React.memo(SimpleUserProfilePreview);
