import { Svg18Myshopping1, Svg24Myshopping1 } from "@moim/icons";
// vendor
import styled from "styled-components";

// component

export const MyShoppingSmallIcon = styled(Svg18Myshopping1).attrs((props) => ({
  size: "xs",
  iconColor: props.theme.colorV2.colorSet.grey300,
}))``;

export const MyShoppingIcon = styled(Svg24Myshopping1).attrs((props) => ({
  size: "s",
  iconColor: props.theme.colorV2.colorSet.grey300,
}))``;
