import { ThunkPromiseResult } from "app/store";
import { getChannels } from "app/actions/channel";
import { getDirectMessages } from "app/actions/directMessage";
import { getPermission } from "app/actions/permission";
import { getJoinedSubMoims } from "app/actions/group";
import { getInitialCarts } from "./commerce";
import { getComponentLayout } from "app/actions/componentLayout";

/**
 * 페이지 접속 또는 토큰만료로인하 새로고침시 항상 요청하는 액션에 대한 공통 묶음입니다.
 */

export function updateActionsForRefresh(): ThunkPromiseResult {
  return async (dispatch, getState) => {
    const state = getState();

    dispatch(getChannels({ limit: 100 }));
    dispatch(getPermission({}));

    if (state.app.currentGroupId) {
      const currentGroup = state.entities.groups[state.app.currentGroupId];

      if (
        currentGroup?.is_hub ||
        currentGroup?.navigation_config?.isIndependentChild
      ) {
        dispatch(
          getComponentLayout({
            id: state.app.currentGroupId,
            type: "top-navigation",
            platform: "web",
          }),
        );
        dispatch(
          getComponentLayout({
            id: state.app.currentGroupId,
            type: "top-navigation",
            platform: "mobile",
          }),
        );
      } else if (state.app.currentHubGroupId) {
        dispatch(
          getComponentLayout({
            id: state.app.currentHubGroupId,
            type: "top-navigation-for-child",
            platform: "web",
          }),
        );
        dispatch(
          getComponentLayout({
            id: state.app.currentHubGroupId,
            type: "top-navigation-for-child",
            platform: "mobile",
          }),
        );
      }

      if (currentGroup?.seller_id) {
        dispatch(getPermission({ resource: currentGroup.seller_id }));
        dispatch(getInitialCarts(currentGroup.seller_id));
      }

      dispatch(
        getComponentLayout({
          id: state.app.currentGroupId,
          type: "footer",
          platform: "web",
        }),
      );
      dispatch(
        getComponentLayout({
          id: state.app.currentGroupId,
          type: "footer",
          platform: "mobile",
        }),
      );
    }

    if (state.app.currentUserId) {
      dispatch(getDirectMessages({ limit: 100 }));
      dispatch(getJoinedSubMoims());
    }
  };
}
