import styled from "styled-components";
import { Link } from "react-router-dom";

import { px2rem } from "common/helpers/rem";

import FallbackImage from "common/components/fallbackImage";

import { getFlexAlignStyle } from "../wrapper/styled";
import { B4Regular } from "common/components/designSystem/typos";

export const Wrapper = styled(Link)<{
  horizontalAlign?: "start" | "center" | "end" | "space-around";
}>`
  display: flex;
  align-items: center;
  padding: ${px2rem(4)} 0;
  ${props =>
    props.horizontalAlign &&
    getFlexAlignStyle({
      direction: "row",
      horizontalAlign: props.horizontalAlign,
    })}
  cursor: pointer;
`;

export const FallbackImageWrapper = styled.div`
  width: 100%;
  padding-top: 100%;
  position: relative;
`;

export const ImageFallback = styled(FallbackImage)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

export const Logo = styled.img`
  width: ${px2rem(18)};
  height: ${px2rem(18)};
  margin-right: ${px2rem(2)};
  border: solid 1px ${props => props.theme.colorV2.colorSet.grey50};
  border-radius: 100%;
`;

export const BrandName = styled(B4Regular)`
  color: ${props => props.theme.colorV2.colorSet.grey800};
  text-transform: uppercase;

  flex: 1;
  min-width: 0;
`;
