import produce from "immer";
import { AllActions } from "app/actions";
import {
  EntityTypes,
  AppTypes,
  ChannelTypes,
  NotificationTypes,
  ForumTypes,
  DirectMessageTypes,
  ConversationTypes,
  StatTypes,
} from "../../actions/types";

export const INITIAL_STATE: Moim.Channel.IStatNormalizedData = {};

export function reducer(
  state: Moim.Channel.IStatNormalizedData = {},
  action: AllActions,
) {
  return produce(state, draft => {
    switch (action.type) {
      /**
       * 여기서 has_new mark 를 삭제한다.
       */
      case ConversationTypes.START_GET_CONVERSATION:
      case ChannelTypes.START_FETCHING_CHANNEL: {
        const { channelId } = action.payload;
        draft[channelId] = {
          ...draft[channelId],
          has_new: false,
        };
        break;
      }
      case ForumTypes.START_GET_THREAD: {
        const { threadId } = action.payload;
        draft[threadId] = {
          ...draft[threadId],
          has_new: false,
          updated_at: Date.now(),
        };

        break;
      }
      case DirectMessageTypes.START_GET_DIRECT_MESSAGE: {
        const { messageId } = action.payload;
        if (messageId && draft[messageId]) {
          draft[messageId] = {
            ...draft[messageId],
            has_new: false,
            count: 0,
            updated_at: Date.now(),
          };
        }
        break;
      }
      case NotificationTypes.START_FETCHING_NOTIFICATIONS: {
        const { id } = action.payload;
        if (!draft[id]) break;
        draft[id] = {
          ...draft[id],
          root_list_count: 0,
        };
        break;
      }

      case EntityTypes.ADD_ENTITY: {
        Object.entries(action.payload.stats || {}).forEach(([key, value]) => {
          const current = draft[key];

          if (Object.keys(value).length === 0 && current) {
            delete draft[key];
          }

          if (
            !current ||
            current.updated_at === undefined ||
            current.updated_at < value.updated_at
          ) {
            draft[key] = {
              ...current,
              ...value,
            };
          }
        });

        break;
      }

      case AppTypes.USER_GLOBAL_MUTE_NOTIFICATION: {
        Object.keys(draft).forEach(key => {
          draft[key] = {
            count: 0,
          } as Moim.Channel.IChannelStat;
        });
        break;
      }

      case StatTypes.NEW_MESSAGE_ARRIVED:
        const { resourceId } = action.payload;

        draft[resourceId] = {
          ...(draft[resourceId] ?? {}),
          has_new: true,
        };
    }
  });
}
