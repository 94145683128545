import { createSelector } from "reselect";
import {
  getCertificates_certificates_edges,
  getCertificates_certificates_edges_node,
} from "@vingle/cryptobadge-sdk";
import { IAppState } from "app/rootReducer";
import { certificationListDenormalizer } from "app/models";
import { entitiesSelector } from ".";

type CertificationEdges = getCertificates_certificates_edges & {
  node: getCertificates_certificates_edges_node & { issuerId: string };
};

export const userCertificationSelector = (
  state: IAppState,
  certifications: Moim.User.NormalizedCertificationList,
  issuers?: Moim.Id[],
): Moim.IListResponse<CertificationEdges> =>
  createSelector(entitiesSelector, entities => {
    const certifies = (certificationListDenormalizer(certifications, entities)
      .data as unknown) as CertificationEdges[];

    let list = certifies;

    if (issuers?.length) {
      list = certifies.filter(certify =>
        issuers.includes(certify.node.issuerId!),
      );
    }

    return {
      data: list,
    };
  })(state);
