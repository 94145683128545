import DimmedOverlay from "./dimmedOverlay";
import CarouselItemElement from "./item";
import { ArrowButton, IndicatorItem, NextArrow, PrevArrow, Wrapper } from "./styled";
import useIsMobile from "common/hooks/useIsMobile";
import * as React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const THUMBNAIL_CAROUSEL_IMAGE_LIMIT = 10;

const ThumbnailCarousel: React.FC<
  Pick<Moim.Blockit.V2.ICarouselBlock, "images" | "style"> & {
    mediaBlocksCount: number;
  }
> = ({ images, style, mediaBlocksCount }) => {
  const isMobile = useIsMobile();

  const handleClick = React.useCallback(
    (callback?: React.MouseEventHandler) => (event: React.MouseEvent) => {
      event.stopPropagation();
      if (callback) {
        callback(event);
      }
    },
    [],
  );

  const { width, height, showBottomIndicate } = style;

  const mediaElements = React.useMemo(
    () =>
      images
        .slice(0, THUMBNAIL_CAROUSEL_IMAGE_LIMIT)
        .map((media, index) => (
          <CarouselItemElement
            key={media.src}
            index={index}
            media={media}
            width={width}
            height={height}
          />
        )),
    [height, images, width],
  );

  const hasMoreThanOneElement = mediaElements.length > 1;

  const renderNextArrow = React.useCallback(
    (clickHandler: () => void, _hasPrev: boolean, _label: string) =>
      hasMoreThanOneElement && (
        <ArrowButton direction="right" onClick={handleClick(clickHandler)}>
          <NextArrow />
        </ArrowButton>
      ),
    [hasMoreThanOneElement],
  );
  const renderPrevArrow = React.useCallback(
    (clickHandler: () => void, _hasPrev: boolean, _label: string) =>
      hasMoreThanOneElement && (
        <ArrowButton direction="left" onClick={handleClick(clickHandler)}>
          <PrevArrow />
        </ArrowButton>
      ),
    [hasMoreThanOneElement],
  );
  const renderIndicator = React.useCallback(
    (
      clickHandler: (e: React.MouseEvent | React.KeyboardEvent) => void,
      isSelected: boolean,
      index: number,
      label: string,
    ) =>
      showBottomIndicate && (
        <IndicatorItem
          aria-label={label}
          role="button"
          value={index}
          tabIndex={0}
          selected={isSelected}
          onClick={handleClick(clickHandler)}
        />
      ),
    [showBottomIndicate],
  );

  const renderItem = React.useCallback(
    (item: React.ReactNode) => {
      if (!React.isValidElement(item)) {
        return item;
      }
      if (
        mediaBlocksCount > THUMBNAIL_CAROUSEL_IMAGE_LIMIT &&
        item.props.index === THUMBNAIL_CAROUSEL_IMAGE_LIMIT - 1
      ) {
        return (
          <>
            <DimmedOverlay
              text={`+ ${
                mediaBlocksCount - THUMBNAIL_CAROUSEL_IMAGE_LIMIT + 1
              }`}
            >
              <CarouselItemElement
                key={item.props.media.src}
                index={item.props.index}
                media={item.props.media}
                width={width}
                height={height}
              />
            </DimmedOverlay>
          </>
        );
      }

      return <>{item}</>;
    },
    [height, mediaBlocksCount, width],
  );

  return (
    <Wrapper>
      <Carousel
        axis="horizontal"
        autoPlay={false}
        infiniteLoop={false}
        showArrows={!isMobile && hasMoreThanOneElement}
        showIndicators={hasMoreThanOneElement ? showBottomIndicate : false}
        showThumbs={false}
        showStatus={false}
        renderArrowPrev={isMobile ? undefined : renderPrevArrow}
        renderArrowNext={isMobile ? undefined : renderNextArrow}
        renderIndicator={renderIndicator}
        renderItem={renderItem}
      >
        {mediaElements}
      </Carousel>
    </Wrapper>
  );
};

export default React.memo(ThumbnailCarousel);
