import { AppDispatch } from "../store";

import { IAppState } from "app/rootReducer";
import { groupBy, has, isEmpty, uniq } from "lodash";
import { batchUsers } from "app/actions/user";
import { batchThreads } from "app/actions/thread";
import { batchChannelData } from "common/helpers/batchService";
import { AddEntities } from "app/actions/entity";
import { getMessage } from "app/actions/conversation";

export function dataHandler(
  dispatch: AppDispatch,
  getState: () => IAppState,
  response: Moim.WebsocketEvent.EntityUpdated,
) {
  const grouped = groupBy(response.payload, "type");
  const entities = getState().entities;

  // handle users
  const userPayloads = grouped.user;
  const threadPayloads = grouped.thread;
  const messagePayloads = grouped.message;
  const channelPayloads = grouped.channel;

  if (!isEmpty(userPayloads)) {
    dispatch(
      batchUsers(
        uniq(userPayloads.map(p => p.id).filter(id => has(entities.users, id))),
      ),
    );
  }

  if (!isEmpty(threadPayloads)) {
    dispatch(
      batchThreads(
        uniq(
          threadPayloads.map(t => t.id).filter(id => has(entities.threads, id)),
        ),
      ),
    );
  }

  if (!isEmpty(channelPayloads)) {
    batchChannelData(
      uniq(
        channelPayloads.map(c => c.id).filter(id => has(entities.channels, id)),
      ),
    ).then(newEntities => {
      dispatch(AddEntities(newEntities));
    });
  }

  if (!isEmpty(messagePayloads)) {
    const storedMessagePayloads = uniq(
      messagePayloads.filter(d => has(entities.messages, d.id)),
    );

    for (const message of storedMessagePayloads) {
      if (message.channelId) {
        dispatch(
          getMessage({ messageId: message.id, channelId: message.channelId }),
        );
      }
    }
  }
}
