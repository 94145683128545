import * as React from "react";
import { FormattedMessage } from "react-intl";
import useCurrentUser from "common/hooks/useCurrentUser";
import { shaveWalletAddress } from "common/helpers/nft";

interface IProps {
  phase: "before" | "in_progress" | "not_rewarded" | "done";
}

const MissionHintMessage = ({ phase }: IProps) => {
  const currentUser = useCurrentUser();

  const web3UserName = React.useMemo(() => {
    if (currentUser) {
      return `${currentUser.name}${
        currentUser.metamask
          ? `(${shaveWalletAddress(currentUser.metamask)})`
          : ""
      }`;
    }
    return "Hey fren";
  }, [currentUser]);

  const hintMessageTextKey = React.useMemo(() => {
    switch (phase) {
      case "before":
        return currentUser
          ? "quest_progress_caption_before_start"
          : "quest_progress_caption_before_start_non_login";
      case "in_progress":
        return "quest_progress_caption_in_progress";
      case "not_rewarded":
        return "quest_progress_caption_before_achieve";
      case "done":
        return "quest_progress_caption_achieved";
    }
  }, [phase, currentUser]);

  return (
    <FormattedMessage
      id={hintMessageTextKey}
      values={{ user_name_address: web3UserName }}
    />
  );
};

export default MissionHintMessage;
