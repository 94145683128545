/* eslint-disable no-underscore-dangle */
// interfaces
import { IAppState } from "../rootReducer";
import AdminFAB from "./adminFAB";
import { IRouteConfig, commonRoutes, hubRoutes, moimRoutes } from "./client";
// helpers
import RenderRoutes from "./helpers";
// components
import RootHelmet from "./rootHelmet";
import * as Sentry from "@sentry/browser";
// actions
import { getPermission } from "app/actions/permission";
import { UserStatusTypes } from "app/enums";
import { connectWebsocket } from "app/event";
import { LayoutRoute } from "app/modules/layout";
import {
  isModalSelector,
  getPreviousLocationSelector,
  currentUserSelector,
} from "app/selectors/app";
import { AppDispatch } from "app/store";
import { VingleLoadable } from "app/vingle";
import retry from "async-retry";
import PageLoadingIndicator from "common/components/pageLoadingIndicator";
import UserProfileMask from "common/components/userProfileImage/mask";
import {
  getMoimTokenToCookie,
  storeMoimTokenToCookie,
} from "common/helpers/authentication";
import { refreshAccessToken } from "common/helpers/authentication/handlers/moim/helpers";
import { setOAuthTokenForGroup } from "common/helpers/cryptoBadgeHandlerWithInMemory";
import { isHubDomain } from "common/helpers/envChecker";
import { checkAndLogException } from "common/helpers/errorLogger";
import selectHubMoimId from "common/helpers/selectHubMoimId";
import { MoimURL } from "common/helpers/url";
import { SecondaryViewBoot } from "common/hooks/useSecondaryView";
import { replace } from "connected-react-router";
import * as React from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { connect } from "react-redux";
import { Switch, Route, withRouter, RouteComponentProps } from "react-router";
import { bindActionCreators } from "redux";

const ImageBrochure = VingleLoadable(
  /* #__LOADABLE__ */ async () => import("common/components/imageBrochure"),
);
const VotedUserListDialog = VingleLoadable(
  /* #__LOADABLE__ */ async () =>
    import("app/modules/forum/containers/votedUserListDialog"),
);
const SNSShareDialog = VingleLoadable(
  /* #__LOADABLE__ */ async () =>
    import("common/components/snsShareDialog/dialog"),
);
const ChannelNotificationSettingDialog = VingleLoadable(
  /* #__LOADABLE__ */ async () =>
    import("common/components/channelNotificationSettingDialog/dialog"),
);
const GlobalPostReportDialog = VingleLoadable(
  /* #__LOADABLE__ */ async () =>
    import("common/components/reportDialog/presets/post"),
);
const GlobalUserReportDialog = VingleLoadable(
  /* #__LOADABLE__ */ async () =>
    import("common/components/reportDialog/presets/user"),
);
const BlockitModal = VingleLoadable(
  /* #__LOADABLE__ */ async () => import("common/components/blockitModal"),
);
const GlobalDialogControlCenter = VingleLoadable(
  /* #__LOADABLE__ */ async () => import("./globalDialogs"),
);
const ProfileDialog = VingleLoadable(
  /* #__LOADABLE__ */ async () => import("app/modules/profileDialog"),
);
const DQuestMissionaryNextActionAlert = VingleLoadable(
  /* #__LOADABLE__ */ async () =>
    import("common/components/dquestMissionary/components/nextActionAlert"),
);

const LazyDialogImport = VingleLoadable(
  /* #__LOADABLE__ */ async () => import("./lazyDialogs"),
);

const NavigationModal = VingleLoadable(
  /* #__LOADABLE__ */ async () => import("common/components/navigationModal"),
);
const NotificationsDialog = VingleLoadable(
  /* #__LOADABLE__ */ async () => import("app/modules/notifications/dialog"),
);

const MoimGlobalBanner = VingleLoadable(
  /* #__LOADABLE__ */ async () =>
    import("common/components/topBanner/presets/moimGlobalBanner"),
);

const CheckoutRedirectDialog = VingleLoadable(
  /* #__LOADABLE__ */ async () =>
    import("common/components/dialogs/checkoutRedirectDialog"),
);

const PurchasePreparationDialog = VingleLoadable(
  /* #__LOADABLE__ */ async () =>
    import("common/components/dialogs/purchasePreparationDialog"),
);

const GlobalAlertDialog = VingleLoadable(
  /* #__LOADABLE__ */ async () =>
    import("common/components/dialogs/globalAlertDialog"),
);

const CoverPage = VingleLoadable(
  /* #__LOADABLE__ */ async () => import("app/modules/cover"),
);

const PersonalSetting = VingleLoadable(
  /* #__LOADABLE__ */ async () => import("app/modules/personalSetting"),
);

const ModalReplies = VingleLoadable(
  /* #__LOADABLE__ */ async () => import("app/modules/subReplies"),
);

const LoadError = VingleLoadable(
  /* #__LOADABLE__ */ async () => import("./csrError/loadError"),
);

interface IProps
  extends RouteComponentProps,
    WrappedComponentProps,
    ReturnType<typeof mapStateToProps>,
    ReturnType<typeof mapDispatchToProps> {
  isMobile?: boolean;
}

interface IState {
  routes: IRouteConfig[];
  error: "LoadError" | "etc" | null;
}

function mapStateToProps(state: IAppState) {
  return {
    currentLocationKey: state.app.history.currentLocationKey,
    isModal: isModalSelector(state),
    prevLocation: getPreviousLocationSelector(state),
    currentUser: currentUserSelector(state),
    hubGroupId: selectHubMoimId(state),
    currentGroupId: state.app.currentGroupId,
  };
}

function mapDispatchToProps(dispatch: AppDispatch) {
  return bindActionCreators(
    {
      replace,
      dispatchGetPermission: getPermission,
      dispatchConnectWebsocket: connectWebsocket,
    },
    dispatch,
  );
}

function errorCatch(error: Error) {
  if (error.message.includes("Loading chunk")) {
    return "LoadError";
  }
  return "etc";
}

class RootComponent extends React.Component<IProps, IState> {
  public state: IState = {
    routes: isHubDomain()
      ? [...hubRoutes, ...commonRoutes]
      : [...moimRoutes, ...commonRoutes],
    error: null,
  };

  public static getDerivedStateFromError(error: Error) {
    return {
      error: errorCatch(error),
    };
  }

  public async componentDidMount() {
    const { currentUser, dispatchConnectWebsocket } = this.props;

    if (currentUser) {
      dispatchConnectWebsocket();
    }
  }

  public shouldComponentUpdate(nextProps: IProps) {
    return (
      nextProps.currentLocationKey !== this.props.currentLocationKey ||
      this.props.currentUser?.id !== nextProps.currentUser?.id ||
      this.props.currentUser?.status !== nextProps.currentUser?.status
    );
  }

  public componentDidUpdate(prevProps: IProps) {
    const { currentUser, dispatchGetPermission, dispatchConnectWebsocket } =
      this.props;

    if (currentUser?.id !== prevProps.currentUser?.id) {
      dispatchConnectWebsocket();
      dispatchGetPermission({});
    }

    if (
      prevProps.currentUser?.status === UserStatusTypes.PENDING &&
      currentUser?.status === UserStatusTypes.ACTIVE
    ) {
      this.refreshToken();
    }
  }

  public componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    if (errorCatch(error) !== "LoadError") {
      Sentry.showReportDialog({
        eventId: checkAndLogException(error, errorInfo),
        lang: this.props.intl.locale,
      });
    }
  }

  public render() {
    const { isMobile, location, isModal, prevLocation } = this.props;
    const { routes } = this.state;

    if (this.state.error) {
      return <LoadError />;
    }

    const mobileModalRoutes: IRouteConfig[] = [];
    if (isMobile && isModal) {
      mobileModalRoutes.push({
        def: [MoimURL.FocusedShowSubReply, MoimURL.FocusedShowForumThread],
        exact: true,
        mobileLayoutType: "full-screen",
        component: ModalReplies,
      });
    }

    const resignedRoutes = [...mobileModalRoutes, ...routes];

    return (
      <>
        <RootHelmet />

        <LayoutRoute
          routes={resignedRoutes}
          location={!isMobile && isModal ? prevLocation : location}
        >
          <RenderRoutes
            routes={resignedRoutes}
            switchProps={{
              location: !isMobile && isModal ? prevLocation : location,
            }}
          />
        </LayoutRoute>

        {!isMobile && isModal ? (
          <NavigationModal>
            <Switch location={location}>
              <Route
                path={MoimURL.PersonalSettingMoim.pattern}
                component={PersonalSetting}
              />
              <Route path={MoimURL.CoverPage.pattern} component={CoverPage} />
            </Switch>
          </NavigationModal>
        ) : null}

        {/* // NOTE: need to separate for HUB or MOIM. */}
        {!isHubDomain() && (
          <>
            {/* 글로벌 이용 가능성 높음 */}
            <ImageBrochure />
            <VotedUserListDialog />
            <SNSShareDialog />
            <ChannelNotificationSettingDialog />
            <GlobalPostReportDialog />
            <GlobalUserReportDialog />
            <BlockitModal />
            <GlobalDialogControlCenter />
            <ProfileDialog />
            <DQuestMissionaryNextActionAlert />

            {/* 글로벌에서 제거 가능 */}
            <NotificationsDialog />

            {/* 이건좀 미묘함. */}
            <CheckoutRedirectDialog />
            <PurchasePreparationDialog />

            {/* 이건 다른 목적 */}
            <SecondaryViewBoot />
          </>
        )}

        <LazyDialogImport />

        {/* 이건좀 미묘함. */}
        <MoimGlobalBanner />
        <GlobalAlertDialog />

        {/* 이건 다른 목적 */}
        <SecondaryViewBoot />
        <UserProfileMask />
        <PageLoadingIndicator />

        <AdminFAB />
      </>
    );
  }

  private readonly refreshToken = async () => {
    const { hubGroupId, currentGroupId } = this.props;
    if (!hubGroupId || !currentGroupId) {
      return;
    }
    const response = await retry(
      async () => {
        const cookieCanPass = getMoimTokenToCookie(hubGroupId);

        if (cookieCanPass) {
          const result = await refreshAccessToken({
            groupId: hubGroupId,
            refreshToken: cookieCanPass.refresh_token,
          });

          return result;
        }

        return undefined;
      },
      {
        retries: 3,
        minTimeout: 2000,
        maxTimeout: 2000,
        factor: 1,
        randomize: true,
      },
    );

    if (!response) {
      return;
    }

    storeMoimTokenToCookie(hubGroupId, response);
    setOAuthTokenForGroup({
      token: response.access_token,
      group: hubGroupId,
    });
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(injectIntl(RootComponent)));
