import * as React from "react";
import TagChip from "common/components/chips/preset/tagChip";
import { NativeEmojiSafeText } from "common/components/designSystem/texts";
import { useActions } from "app/store";
import { AddEntities } from "app/actions/entity";
import { batchTagGroupDataHandler } from "common/helpers/batchService";
import {
  Wrapper,
  TagSetTitle,
  TagSetWrapper,
  ChipList,
  ChipWrapper,
} from "./styled";

const TagGroup: React.FC<{
  tagGroup: Moim.TagSet.ITagGroup;
  tagIds: Moim.Id[];
  readonly: boolean;
  onClickTagItem?(tagItem: Moim.TagSet.ITagItem): void;
}> = ({ tagGroup, tagIds, readonly, onClickTagItem }) => {
  return (
    <TagSetWrapper>
      <TagSetTitle>
        <NativeEmojiSafeText value={tagGroup.set} />
      </TagSetTitle>
      <ChipList>
        {tagIds.map(tagId => {
          const item = tagGroup.items.find(tag => tag.id === tagId);
          if (!item) {
            return null;
          }
          return (
            <ChipWrapper key={item.id}>
              <TagChip
                tagItem={item}
                size="medium"
                readonly={readonly}
                selected={true}
                onClick={onClickTagItem}
              />
            </ChipWrapper>
          );
        })}
      </ChipList>
    </TagSetWrapper>
  );
};
interface IProps {
  selectedTagIds: Record<string, string[]> | undefined;
  readonly?: boolean;
  onClickTagItem?(tagItem: Moim.TagSet.ITagItem): void;
}

const TagItemList: React.FC<IProps> = ({
  selectedTagIds,
  readonly = false,
  onClickTagItem,
}) => {
  const [tagGroups, setTagGroups] = React.useState<Moim.TagSet.ITagGroup[]>([]);

  const { dispatchAddEntities } = useActions({
    dispatchAddEntities: AddEntities,
  });

  React.useEffect(() => {
    const tagGroupIds = Object.keys(selectedTagIds ?? {}).filter(
      tagGroupId => !tagGroups.find(tagGroup => tagGroup.id === tagGroupId),
    );

    if (tagGroupIds?.length) {
      batchTagGroupDataHandler(tagGroupIds).then(result => {
        setTagGroups(result);

        dispatchAddEntities({
          tagset: result.reduce<Moim.TagSet.INormalizedTagItem>(
            (_result, current) => {
              current.items.forEach(item => {
                _result[item.id] = item;
              });
              return _result;
            },
            {},
          ),
        });
      });
    }
  }, [selectedTagIds]);

  return (
    <Wrapper>
      {tagGroups?.map(tagGroup => (
        <TagGroup
          key={tagGroup.id}
          tagGroup={tagGroup}
          tagIds={selectedTagIds?.[tagGroup.id] ?? []}
          readonly={readonly}
          onClickTagItem={onClickTagItem}
        />
      ))}
    </Wrapper>
  );
};

export default TagItemList;
