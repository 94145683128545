import * as React from "react";
import { useActions, useStoreState } from "app/store";
import { FormattedMessage, useIntl } from "react-intl";
import { transferCoin } from "app/actions/community/coin";
import { isBrowser } from "common/helpers/envChecker";
// hooks
import useOpenState from "common/hooks/useOpenState";
import useCurrentUser from "common/hooks/useCurrentUser";
import { useSnackbar } from "common/components/alertTemplates/alerts/globalSnackbar/useGlobalSnackbar";
import useIsMobile from "common/hooks/useIsMobile";
// components
import { MultilineBoxInput } from "common/components/designSystem/boxInput";
import { H8Bold } from "common/components/designSystem/typos";
import { Spacer } from "common/components/designSystem/spacer";
import { CoinTransferDialog as Dialog } from "common/components/basicResponsiveDialog/styled";
import AppBar from "common/components/appBar";
import { CustomAppBarModalLayout } from "common/components/modalLayout";
import ConfirmDialog from "common/components/alertDialog/confirm";
import TransferCandyRedirectLoadingDialog from "app/modules/community/coin/components/transferCandyRedirectLoadingDialog";
import MemberSelectInput from "./components/memberSelectInput";
import CoinSelectInput from "./components/coinSelectInput";
// styled
import { NumberInput } from "common/components/designSystem/boxInput/styled";
import {
  CloseButton,
  Section,
  SectionTitle,
  SectionContent,
  DescriptionBoxStyle,
  TransferAmountInputWrapper,
  TransferButton,
  TransferDialogWrapper,
  transferDialogWrapperStyle,
  TransferDialogFooter,
  TransferDialogBody,
} from "./styled";

interface IProps {
  coinId?: string;
  isOpen: boolean;
  disableCoinSelectField?: boolean;
  onClose(): void;
}

const CoinTransfer: React.FC<Omit<IProps, "isOpen">> = ({
  coinId: initialCoinId,
  disableCoinSelectField = false,
  onClose,
}) => {
  const intl = useIntl();
  const currentUser = useCurrentUser();

  const callbackUrl = `${window.location.origin}/coins/${initialCoinId}`;

  const [coinId, setCoinId] = React.useState<string | undefined>(initialCoinId);
  const [selectedUserId, setSelectedUser] = React.useState<
    string | undefined
  >();
  const [amount, setAmount] = React.useState<number | undefined>();
  const [senderMessage, setTransfererMessage] = React.useState<
    string | undefined
  >(undefined);

  const { selectedCoin, receiver } = useStoreState(state => ({
    selectedCoin: coinId ? state.entities.community_coins[coinId] : undefined,
    receiver: selectedUserId ? state.entities.users[selectedUserId] : undefined,
  }));

  const { transferCoinAction } = useActions({
    transferCoinAction: transferCoin,
  });

  const { open: openTransferSuccess } = useSnackbar({
    useCloseButton: true,
    text: intl.formatMessage(
      { id: "toast_message_send_candy_success" },
      { candy_name: selectedCoin?.name ?? "" },
    ),
    type: "success",
  });

  const {
    isOpen: isOpenTransferConfirmAlert,
    open: openTransferConfirmAlert,
    close: closeTransferConfirmAlert,
  } = useOpenState();
  const {
    isOpen: isOpenLoadingDialog,
    open: openLoadingDialog,
    close: closeLoadingDialog,
  } = useOpenState();

  const handleClickTransfer = React.useCallback(() => {
    if (coinId && receiver && currentUser && amount) {
      openLoadingDialog();
      transferCoinAction({
        coinId,
        callbackUrl,
        to: { userId: receiver.id, address: receiver.metamask },
        from: { userId: currentUser.id, address: currentUser.metamask },
        amount,
        senderMessage,
      })
        .then(result => {
          if (isBrowser() && result?.location) {
            location.href = result.location;
          } else {
            openTransferSuccess();
          }
        })
        .finally(() => {
          closeTransferConfirmAlert();
          closeLoadingDialog();
          onClose();
        });
    }
  }, [
    onClose,
    amount,
    callbackUrl,
    currentUser,
    openLoadingDialog,
    receiver,
    senderMessage,
    coinId,
    transferCoinAction,
  ]);

  return (
    <>
      <CustomAppBarModalLayout
        appBar={
          <AppBar
            leftButton={<CloseButton onClick={onClose} />}
            alwaysShowAppBarTitle={true}
            titleElement={
              <H8Bold>
                <FormattedMessage
                  id="send_candy_dialog_title"
                  values={{ candy_name: selectedCoin?.name ?? "" }}
                />
              </H8Bold>
            }
            titleAlignment="Center"
            wrapperStyle={transferDialogWrapperStyle}
          />
        }
        hasAppBarBorder={false}
      >
        <TransferDialogWrapper>
          <TransferDialogBody>
            <Spacer value={9} />

            {!disableCoinSelectField && (
              <CoinSelectInput coinId={coinId} onChange={setCoinId} />
            )}
            <MemberSelectInput
              userId={selectedUserId}
              onChange={setSelectedUser}
              isBlockchainCoin={Boolean(selectedCoin?.contractAddress)}
            />
            <Section>
              <SectionTitle>
                <FormattedMessage id="send_candy_dialog_amount_title" />
              </SectionTitle>
              <SectionContent>
                <TransferAmountInputWrapper>
                  <NumberInput
                    type="number"
                    placeholder={intl.formatMessage({
                      id: "send_candy_dialog_amount_placeholder",
                    })}
                    value={amount}
                    onChange={elem => setAmount(Number(elem.target.value))}
                  />
                </TransferAmountInputWrapper>
              </SectionContent>
            </Section>
            <Section>
              <SectionTitle>
                <FormattedMessage id="send_candy_dialog_message_title" />
              </SectionTitle>
              <SectionContent>
                <MultilineBoxInput
                  autoFocus={false}
                  wrapperStyle={DescriptionBoxStyle}
                  size="Large"
                  value={senderMessage}
                  placeholder={intl.formatMessage({
                    id: "send_candy_dialog_message_placeholder",
                  })}
                  suffix={{
                    type: "characters",
                    maxCount: 2000,
                  }}
                  helperText={
                    <FormattedMessage
                      id="send_candy_dialog_message_max_count_title"
                      values={{ maxCount: 2000 }}
                    />
                  }
                  onChange={value => setTransfererMessage(value)}
                />
              </SectionContent>
            </Section>
          </TransferDialogBody>
          <TransferDialogFooter>
            <Section>
              <Spacer value={8} />
              <TransferButton
                disabled={!coinId || !receiver || !currentUser || !amount}
                onClick={openTransferConfirmAlert}
              >
                <FormattedMessage
                  id="button_send_candy_dialog_send_candy"
                  values={{
                    candy_name: selectedCoin?.name ?? "",
                  }}
                />
              </TransferButton>
            </Section>
          </TransferDialogFooter>
        </TransferDialogWrapper>
      </CustomAppBarModalLayout>
      <ConfirmDialog
        title={
          <FormattedMessage id="send_candy_dialog_confirm_send_candy_title" />
        }
        disableCheckButton={true}
        open={isOpenTransferConfirmAlert}
        content={
          <FormattedMessage
            id="send_candy_dialog_confirm_send_candy_body"
            values={{
              candy_name: selectedCoin?.name ?? "",
              amount,
              symbol: selectedCoin?.symbol ?? "",
              recipient_username: receiver?.name ?? "",
            }}
          />
        }
        onClose={closeTransferConfirmAlert}
        negativeButtonProps={{
          text: <FormattedMessage id="cancel_button" />,
          onClick: closeTransferConfirmAlert,
        }}
        positiveButtonProps={{
          text: <FormattedMessage id="button_ok" />,
          onClick: handleClickTransfer,
        }}
        confirmMessage={undefined}
      />
      <TransferCandyRedirectLoadingDialog
        open={isOpenLoadingDialog}
        close={closeLoadingDialog}
      />
    </>
  );
};

const CoinTransferDialog: React.FC<IProps> = ({
  isOpen,
  onClose,
  coinId,
  disableCoinSelectField,
}) => {
  const isMobile = useIsMobile();
  return (
    <Dialog open={isOpen} fullScreen={isMobile} onClose={onClose}>
      <CoinTransfer
        coinId={coinId}
        disableCoinSelectField={disableCoinSelectField}
        onClose={onClose}
      />
    </Dialog>
  );
};
export default CoinTransferDialog;
