import React from "react";
import styled from "styled-components";

import { px2rem } from "common/helpers/rem";

import ChipBase from "common/components/chips";
import useProductStatusLabel from "common/components/commerce/statusLabel";

const Chip = styled(ChipBase).attrs({
  size: "small",
  shape: "rectangle",
})`
  background-color: ${props => props.theme.color.marine415};
  color: ${props => props.theme.color.marine400};
  padding: ${px2rem(2)} ${px2rem(4)};
`;

export const BackorderChip = () => {
  const { backorderText } = useProductStatusLabel("normal");

  return <Chip>{backorderText}</Chip>;
};
