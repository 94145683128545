import styled, { css } from "styled-components";
import { Spacer } from "common/components/designSystem/spacer";
import { useThreadItemContext } from "common/components/thread/commonThreadItem/context";
import TagsComponent from "../../common/tags";

const TagWrapper = styled.div<{ thumbnailUrl?: string }>`
  display: contents;
  ${props =>
    props.thumbnailUrl &&
    css`
      & * {
        color: ${props.theme.themeMode.lightPalette.colorSet.white700};
      }
    `}

  & > div > * {
    background-color: ${props =>
      props.theme.themeMode.lightPalette.colorSet.white100};
  }
`;

function Tags({ tags }: { tags: Moim.TagSet.ITagItem[] }) {
  const { showTags, textAlign, thumbnailUrl } = useThreadItemContext(state => ({
    showTags: state.config.showTags,
    textAlign: state.config.textAlignment,
    thumbnailUrl: state.item.preview?.thumbnail?.url,
  }));
  return (
    showTags &&
    tags.length !== 0 && (
      <TagWrapper thumbnailUrl={thumbnailUrl}>
        <Spacer value={8} />
        <TagsComponent tags={tags} textAlign={textAlign} />
      </TagWrapper>
    )
  );
}

export default Tags;
