import produce from "immer";
import { AllActions } from "app/actions";
import { GlobalDuplicatePostDialogTypes } from "app/actions/types";

export interface IState {
  open: boolean;
  threadId: string | null;
}

export const INITIAL_STATE: IState = {
  open: false,
  threadId: null,
};

export function reducer(state = INITIAL_STATE, action: AllActions) {
  return produce(state, draft => {
    switch (action.type) {
      case GlobalDuplicatePostDialogTypes.OPEN: {
        draft.open = true;
        draft.threadId = action.payload.threadId;

        break;
      }

      case GlobalDuplicatePostDialogTypes.CLOSE: {
        draft.open = false;
        draft.threadId = null;
        break;
      }
    }
  });
}
