import styled from "styled-components";
import { px2rem } from "common/helpers/rem";

const DEFAULT_GAP = 12;

export const Wrapper = styled.div<{
  gapSize?: number;
  columnCount?: number;
  rowCount?: number;
  stackDirection: "horizontal" | "vertical";
  backgroundColor?: string;
  disappearBottomDivider?: boolean;
}>`
  width: 100%;
  display: grid;
  position: relative;
  place-content: center;
  gap: ${props => px2rem(props.gapSize ?? DEFAULT_GAP)};
  grid-template-columns: ${props =>
    `repeat(${props.columnCount ?? 1}, minmax(0, 1fr))`};

  grid-auto-flow: ${props =>
    props.stackDirection === "vertical" ? "column" : "row"};

  &::after {
    position: absolute;
    bottom: -${props => px2rem(Math.floor((props.gapSize ?? DEFAULT_GAP) / 2))};
    width: 100%;
    display: ${props => (props.disappearBottomDivider ? "block" : "none")};

    content: "";
    height: 1px;
    background-color: ${props =>
      props.theme.getColorByAlias(
        props.backgroundColor,
        props.theme.colorV2.colorSet.white1000,
      )};
  }
`;
