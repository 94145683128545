import {
  Svg18Info,
  Svg18Question,
  Svg18Star1,
  Svg18Like1,
  Svg18Emoji1,
  Svg18DotG,
  Svg18Block1,
  Svg18TimeB,
  Svg18MessageC,
  Svg18AddB,
  Svg18RemoveB,
  Svg18PinB,
  Svg18NotionG,
  Svg18Website,
  Svg18RightarrowG,
} from "@moim/icons";
import React from "react";
import styled from "styled-components";
import Url, { qs } from "url-parse";

const SUPPORTED_ICON_SET = {
  "18_info": Svg18Info,
  "18_question": Svg18Question,
  "18_star": Svg18Star1,
  "18_heart": Svg18Like1,
  "18_smile": Svg18Emoji1,
  "18_dot": Svg18DotG,
  "18_box": Svg18Block1,
  "18_clock": Svg18TimeB,
  "18_chat": Svg18MessageC,
  "18_plus": Svg18AddB,
  "18_minus": Svg18RemoveB,
  "18_pin": Svg18PinB,
  "18_bell": Svg18NotionG,
  "18_globe": Svg18Website,
  "18_rightarrow": Svg18RightarrowG,
};

const createStyledIcon = (
  iconName: keyof typeof SUPPORTED_ICON_SET | undefined,
) => {
  if (!iconName) {
    return null;
  }

  const Icon = SUPPORTED_ICON_SET[iconName];

  if (Icon) {
    return styled(Icon).attrs<{
      colorKey?: keyof Moim.Group.IColorSet;
      size: Moim.DesignSystem.Size;
      touch: number;
    }>((props) => ({
      size: "xs",
      iconColor: props.colorKey
        ? props.theme.colorV2.colorSet[props.colorKey]
        : undefined,
    }))``;
  }

  return null;
};

const SUPPORTED_ICON_COMPONENT_SET = Object.keys(SUPPORTED_ICON_SET).reduce<
  Record<string, any>
>((result, current) => {
  result[current] = createStyledIcon(
    current as keyof typeof SUPPORTED_ICON_SET,
  );

  return result;
}, {});

const ImageWithIcon: React.FC<{
  imageUrl: string;
  className?: string;
  defaultIcon?: keyof typeof SUPPORTED_ICON_SET;
}> = ({ imageUrl, className, defaultIcon }) => {
  const url = new Url(imageUrl);

  if (url.protocol.startsWith("moimicon")) {
    const iconName = url.hostname;
    const options = qs.parse(url.query);

    const IconComponent =
      SUPPORTED_ICON_COMPONENT_SET[iconName] ??
      SUPPORTED_ICON_COMPONENT_SET[defaultIcon ?? ""];
    if (IconComponent) {
      return <IconComponent colorKey={options.color} />;
    }

    return null;
  }

  return <img className={className} src={url.href} />;
};

export default ImageWithIcon;
