import * as React from "react";
import styled from "styled-components";

import {
  Button,
  ButtonWrapper,
  Contents,
  Description,
  Header,
  SubTitle,
  Title,
  Wrapper,
} from "app/hub/createGroup/components/step/template/styled";
import { GhostGeneralButton } from "common/components/designSystem/buttons";
import BaseTemplate from "app/hub/createGroup/components/step/template";
import { InvitationInfo } from "app/hub/createGroup/components/step/template/invitationInfo";
import { px2rem } from "common/helpers/rem";

const StyledHeader = styled(Header)`
  padding-left: ${px2rem(16)};
  padding-right: ${px2rem(16)};
`;
const StyledButtonWrapper = styled(ButtonWrapper)`
  padding: 0 ${px2rem(16)};
`;

const StyledContents = styled(Contents)`
  padding-top: ${px2rem(10)};
  padding-bottom: ${px2rem(10)};
`;
export default function SignUpFormTemplate({
  stepData,
  disabledButton,
  waitingButton,
  onClick,
  onClickSkipButton,
  children,
}: React.ComponentProps<typeof BaseTemplate>) {
  const queryParams = new URLSearchParams(location.search);
  const inviterId = queryParams.get("inviterId");
  return (
    <Wrapper>
      {inviterId && (
        <StyledButtonWrapper>
          <InvitationInfo inviterId={inviterId} />
        </StyledButtonWrapper>
      )}
      <StyledHeader>
        <Title>{stepData.title}</Title>
        {stepData.subTitle && <SubTitle>{stepData.subTitle}</SubTitle>}
      </StyledHeader>
      <StyledContents>{children}</StyledContents>
      <StyledButtonWrapper>
        {stepData.skipButtonText ? (
          <GhostGeneralButton size="l" onClick={onClickSkipButton}>
            {stepData.skipButtonText}
          </GhostGeneralButton>
        ) : null}
        <Button
          disabled={disabledButton}
          waiting={waitingButton}
          size="l"
          onClick={onClick}
        >
          {stepData.buttonText}
        </Button>
      </StyledButtonWrapper>
    </Wrapper>
  );
}

export { Description };
