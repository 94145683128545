export function initialStateHelper<T>(
  initialState: T | ((_requestId?: string) => T),
  requestId?: string,
): T {
  if (typeof initialState === "function") {
    return (initialState as (_requestId?: string) => T)(requestId);
  }

  return initialState;
}
