import React from "react";
import styled from "styled-components";

import TransferCandyRedirectLoadingDialog from "app/modules/community/coin/components/transferCandyRedirectLoadingDialog";
import {
  FlatButton,
  shinyAnimationButtonStyle,
} from "common/components/designSystem/buttons";

import { transferCoin } from "app/actions/community/coin";
import { getPermission } from "app/actions/permission";

import { useActions, useStoreState } from "app/store";
import { useIntlShort } from "common/hooks/useIntlShort";
import useCurrentUser from "common/hooks/useCurrentUser";
import useOpenState from "common/hooks/useOpenState";
import { MoimURL } from "common/helpers/url";
import useCancelToken from "common/hooks/useCancelToken";
import { px2rem } from "common/helpers/rem";

export const AnimatedFlatButton = styled(FlatButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${px2rem(8)};

  ${shinyAnimationButtonStyle}

  .coinPreview {
    width: ${px2rem(18)};
    height: ${px2rem(18)};
  }
`;

const TokenTransferButton: React.FC<{
  unlockMethod: Moim.Forum.IThreadUnlockMethod;
  authorId: string;
  threadId: string;
  channelId: string;
  groupId?: string;
  coinSymbol: string | undefined;
  coinPreview?: string;
  onCloseTransferDialog(): void;
}> = ({
  authorId,
  unlockMethod,
  coinSymbol,
  coinPreview,
  groupId,
  channelId,
  threadId,
  onCloseTransferDialog,
}) => {
  const intl = useIntlShort();
  const cancelToken = useCancelToken();
  const currentUser = useCurrentUser();
  const callbackUrl = `${window.location.origin}${new MoimURL.ShowForumThread({
    forumId: channelId,
    threadId,
  }).toString()}`;

  const coinId = unlockMethod.resourceId;
  const amount = unlockMethod.quantity;

  const {
    isOpen: isOpenLoadingDialog,
    open: openLoadingDialog,
    close: closeLoadingDialog,
  } = useOpenState();

  const { receiver } = useStoreState(state => ({
    receiver: state.entities.users[authorId],
  }));
  const { dispatchTransferCoin, dispatchGetPermission } = useActions({
    dispatchTransferCoin: transferCoin,
    dispatchGetPermission: getPermission,
  });

  const handleClickTransfer = React.useCallback(async () => {
    if (groupId && coinId && receiver && currentUser && amount >= 0) {
      openLoadingDialog();
      const result = await dispatchTransferCoin(
        {
          coinId,
          callbackUrl,
          to: { userId: receiver.id, address: receiver.metamask },
          from: { userId: currentUser.id, address: currentUser.metamask },
          amount,
          sourceType: "unlockThread",
          sourceId: threadId,
          sourceUrl: callbackUrl,
        },
        cancelToken.current.token,
      );
      if (result?.location) window.location.href = result.location;
      await dispatchGetPermission(
        { resource: threadId },
        cancelToken.current.token,
        groupId,
      );
      onCloseTransferDialog();
    }
  }, [
    amount,
    callbackUrl,
    currentUser,
    receiver,
    coinId,
    threadId,
    groupId,
    openLoadingDialog,
    dispatchTransferCoin,
    dispatchGetPermission,
    onCloseTransferDialog,
  ]);

  return (
    <>
      <AnimatedFlatButton size="l" onClick={handleClickTransfer}>
        {coinPreview ? (
          <img src={coinPreview} alt={coinPreview} className="coinPreview" />
        ) : null}
        <span>
          {intl("button_send_candy_to_unlock_post", {
            n: unlockMethod?.quantity,
            unit: coinSymbol,
          })}
        </span>
      </AnimatedFlatButton>
      <TransferCandyRedirectLoadingDialog
        open={isOpenLoadingDialog}
        close={closeLoadingDialog}
      />
    </>
  );
};

export default TokenTransferButton;
