import ellipsisText from "../helpers/ellipsisText";
import { AnalyticsClass } from "common/helpers/analytics/analytics";
import useHost from "common/hooks/useHost";
import unescape from "lodash/unescape";
import * as React from "react";
import Helmet, { HelmetProps } from "react-helmet";

interface IProps extends HelmetProps {
  description?: string;
  metaObjects?: Moim.MetaTag[];
  favicons?: Moim.IFavicon[];
  ldJson?: JSX.Element;
  path?: string;
}

const OG_DESCRIPTION_MAX_SIZE = 300;
const OG_DESCRIPTION_ELLIPSIS = "...";

const PageUpdater: React.FC<IProps> = (props) => {
  const {
    meta: _meta,
    metaObjects,
    favicons,
    onChangeClientState,
    title,
    description,
    ldJson,
    ...rest
  } = props;

  const host = useHost();
  const metaTags = React.useMemo(() => {
    const filledMeta: {
      name?: string;
      property?: string;
      content?: string;
    }[] = [];
    const ogTitle = metaObjects?.find((tag) => tag.property === "og:title");
    const ogDescription = metaObjects?.find(
      (tag) => tag.property === "og:description",
    );

    if (description) {
      filledMeta.push({
        name: "description",
        content: description,
      });
    } else {
      if (ogDescription && ogDescription.content) {
        filledMeta.push({
          name: "description",
          content: ogDescription.content,
        });
      }
    }

    filledMeta.push(
      ...(metaObjects
        ? metaObjects.filter((meta) => Boolean(meta.content)).slice()
        : []),
    );

    if (!ogTitle && title) {
      filledMeta.push({
        property: "og:title",
        content: title,
      });
    }

    if (!ogDescription && description) {
      filledMeta.push({
        property: "og:description",
        content: description,
      });
    }

    if (props.path) {
      filledMeta.push({
        property: "og:content",
        content: `https://${host}${props.path}`,
      });
    }

    filledMeta.forEach((meta) => {
      if (meta.property === "og:description" && meta.content) {
        meta.content = ellipsisText(
          meta.content,
          OG_DESCRIPTION_MAX_SIZE,
          OG_DESCRIPTION_ELLIPSIS,
        );
      }

      if (meta.name === "description" && meta.content) {
        meta.content = ellipsisText(
          meta.content,
          OG_DESCRIPTION_MAX_SIZE,
          OG_DESCRIPTION_ELLIPSIS,
        );
      }

      if (meta.property === "og:image" && meta.content) {
        meta.content = unescape(meta.content);
      }
    });

    return filledMeta;
  }, [description, host, metaObjects, props.path, title]);

  const linkTags = React.useMemo(() => {
    const data: {
      rel: string;
      href: string;
      size?: string;
    }[] = [];

    if (favicons) {
      favicons.forEach((favicon) => {
        data.push({
          rel: favicon.rel,
          size: `${favicon.size}x${favicon.size}`,
          href: favicon.url,
        });
      });
    }

    if (props.path) {
      data.push({
        rel: "canonical",
        href: `https://${host}${props.path}`,
      });
    }

    return data;
  }, [favicons, host, props.path]);

  React.useEffect(() => {
    if (title) {
      AnalyticsClass.getInstance().screenView(title, location.pathname);
    }
  }, [title]);

  return (
    <>
      <Helmet
        {...rest}
        title={title}
        meta={metaTags}
        link={linkTags}
        onChangeClientState={onChangeClientState}
      >
        {ldJson}
      </Helmet>
    </>
  );
};

export default PageUpdater;
