import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

import {
  AvatarUserSQuestion,
  Ic1818MoimlistAll,
  Svg18DownarrowG,
} from "@moim/icons";
import { px2rem } from "common/helpers/rem";
import {
  B3RegularStyle,
  H10BoldStyle,
} from "common/components/designSystem/typos";
import {
  useHoverStyle,
  useScrollStyle,
} from "common/components/designSystem/styles";
import { TopNaviBlockPropertyStyle } from "../section/styled";

const channelSelectedStyle = css<{
  selected?: boolean;
  isCategory?: boolean;
  tintColor?: Moim.Blockit.V2.ColorValue;
}>`
  color: ${props => {
    if (props.tintColor) {
      return props.selected
        ? props.theme.getBlockitColorValue(props.tintColor, "grey800")
        : props.theme.getBlockitColorValue(props.tintColor, "grey600");
    }

    return props.selected
      ? props.theme.colorV2.colorSet.fog800
      : props.theme.colorV2.colorSet.fog600;
  }};

  &::after {
    content: "";
    display: block;
    position: absolute;
    background-color: ${props =>
      props.tintColor
        ? props.theme.getBlockitColorValue(props.tintColor, "grey800")
        : props.theme.colorV2.colorSet.fog800};
    height: ${px2rem(2)};
    left: ${px2rem(16)};
    right: ${props => (props.isCategory ? px2rem(24) : px2rem(16))};
    bottom: 0;
    transition: all 300ms;
    opacity: ${props => (props.selected ? 1 : 0)};
    transform: scale(${props => (props.selected ? 1 : 0)});
  }
`;

export const channelUnreadStyle = css<{
  isUnread?: boolean;
}>`
  &::before {
    content: "";
    display: block;
    position: absolute;
    border-radius: 50%;
    background-color: ${props =>
      props.theme.getAlertElementPalette("alertBadge").color ??
      props.theme.color.red};
    height: ${px2rem(3)};
    width: ${px2rem(3)};
    top: 0;
    right: -${px2rem(3)};
    transition: all 300ms;
    opacity: ${props => (props.isUnread ? 1 : 0)};
    transform: scale(${props => (props.isUnread ? 1 : 0)});
  }
`;

export const WithUnreadStatusBadgeWrapper = styled.span<{
  isUnread?: boolean;
}>`
  position: relative;
  ${channelUnreadStyle};
`;

export const InnerContainer = styled.div`
  width: 100%;
  flex: 1;
  min-width: 0;
  width: ${px2rem(375)};
  max-height: ${px2rem(700)};
  ${useScrollStyle};
`;

export const ItemWrapper = styled.div.attrs({ role: "button" })<{
  selected?: boolean;
  isCategory?: boolean;
  tintColor?: Moim.Blockit.V2.ColorValue;
  element?: Partial<Moim.Layout.Navigation.CategoryElementType>;
}>`
  min-width: 0;
  min-height: 0;
  height: 100%;
  position: relative;
  padding: 0 ${px2rem(16)};

  display: inline-flex;
  align-items: center;

  transition: opacity 200ms ease-in;

  &:hover {
    opacity: 0.6;
  }

  ${channelSelectedStyle}

  ${TopNaviBlockPropertyStyle}
`;

export const CategoryButton = styled.button<{
  isOpen: boolean;
  tintColor?: Moim.Blockit.V2.ColorValue;
}>`
  display: flex;
  align-items: center;
  position: relative;
  gap: ${px2rem(4)};
  height: 100%;
  ${B3RegularStyle};
  color: ${props =>
    props.tintColor
      ? props.theme.getBlockitColorValue(props.tintColor, "grey800")
      : props.theme.colorV2.colorSet.fog800};
  font-weight: ${props =>
    props.isOpen ? props.theme.font.bold : props.theme.font.regular};
`;

export const CategoryItem = styled.li<{
  clickable?: boolean;
}>`
  position: relative;
  height: fit-content;
  display: flex;
  align-items: center;
  padding: ${px2rem(10)} ${px2rem(20)};
  background-color: ${props => props.theme.colorV2.colorSet.grey10};
  color: ${props => props.theme.colorV2.colorSet.grey400};

  ${props =>
    props.clickable &&
    css`
      &:hover {
        cursor: pointer;
      }
    `};
`;

export const SecondCategoryTitle = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${px2rem(12)} ${px2rem(16)};
  ${B3RegularStyle};
  color: ${props => props.theme.colorV2.colorSet.grey800};
  height: ${px2rem(44)};

  &:hover {
    cursor: pointer;
    opacity: 0.6;
    transition: opacity 300ms ease-in;
  }
`;

export const AccordionHeader = styled.div<{ expanded?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${px2rem(12)} ${px2rem(16)};
  ${B3RegularStyle};
  font-weight: ${props =>
    props.expanded ? props.theme.font.bold : props.theme.font.regular};
  color: ${props => props.theme.colorV2.colorSet.grey800};
  height: ${px2rem(44)};

  &:hover {
    cursor: pointer;
    opacity: 0.6;
    transition: opacity 300ms ease-in;
  }
`;

export const CategoryListWrapper = styled.div<{ wrapperWidth?: string }>`
  display: flex;
  flex-direction: column;
  width: ${props => (props.wrapperWidth ? props.wrapperWidth : px2rem(375))};
  ${useScrollStyle};
`;

export const CategoryThirdDepsWrapper = styled.ul`
  border-top: 1px solid ${props => props.theme.colorV2.colorSet.grey50};
`;

export const RootCategory = styled.div`
  ${H10BoldStyle};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${px2rem(15)} ${px2rem(16)};
  border-top: 1px solid ${props => props.theme.colorV2.colorSet.grey50};
  ${useHoverStyle};
  color: ${props => props.theme.colorV2.colorSet.grey800};
  height: ${px2rem(49)};

  .right-arrow {
    opacity: 0;
    transition: opacity 200ms;
  }

  &:hover {
    .right-arrow {
      opacity: 1;
    }
  }
`;
export const ItemDetail = styled.div`
  display: flex;
  align-items: center;
  gap: ${px2rem(8)};
`;

export const ArrowRightIconWrapper = styled.div``;

export const ArrowDownIcon = styled(Svg18DownarrowG).attrs({
  size: "xs",
})`
  fill: ${props => props.theme.colorV2.colorSet.grey100};
`;

export const ArrowRightIcon = styled(Svg18DownarrowG).attrs<{
  isRoot?: boolean;
}>({
  size: "xs",
})`
  transform: rotate(-90deg);
`;

export const CollapseIconButton = styled.button<{
  open: boolean;
}>`
  ${ArrowDownIcon} {
    transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1);
    ${props =>
      props.open
        ? css`
            transform: rotate(180deg);
          `
        : css`
            transform: rotate(0deg);
          `}
    path {
      fill: ${props =>
        props.open
          ? props.theme.colorV2.colorSet.grey1000
          : props.theme.colorV2.colorSet.grey200};
    }
  }
`;

export const MenuIcon = styled(Ic1818MoimlistAll).attrs(props => ({
  size: "xs",
  iconColor: props.tintColor
    ? props.theme.getBlockitColorValue(props.tintColor, "grey800")
    : props.theme.colorV2.colorSet.fog800,
}))``;

export const FallbackLogo = styled(AvatarUserSQuestion).attrs({
  size: "xs",
})``;

export const Logo = styled.img`
  width: ${px2rem(18)};
  height: ${px2rem(18)};
  border: solid 1px ${props => props.theme.colorV2.colorSet.grey50};
  border-radius: 100%;
`;
