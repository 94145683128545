import * as React from "react";
import MobileTopSubNavigation from "./mobile";

import useIsMobile from "common/hooks/useIsMobile";

const TopSubNavigation: React.FC = () => {
  const isMobile = useIsMobile();
  return isMobile ? <MobileTopSubNavigation /> : null;
};

export default TopSubNavigation;
