import * as React from "react";
import { FlattenInterpolation } from "styled-components";
import { getSearchThreadAsIds as getSearchThreadAsIdsAction } from "app/actions/forum";
import { useActions, useStoreState } from "app/store";
import { withPlacement } from "../../hoc/withPlacement";
import FeedItem from "./components/feedItem";
import InfiniteScroller from "common/components/infiniteScroller/new";
import { Container } from "./styled";

interface IProps extends Omit<Moim.Blockit.IContentFeed, "type"> {
  wrapperStyle?: FlattenInterpolation<any>;
}

const ContentFeed: React.FC<IProps> = ({}) => {
  const [isLoading, setLoadState] = React.useState(false);
  const [idData, setIdData] = React.useState<
    Moim.IPaginatedListResponse<Moim.Id>
  >({
    data: [],
    paging: {},
  });
  const data = useStoreState(state =>
    idData.data.map(id => state.entities.threads[id]).filter(i => Boolean(i)),
  );
  const { getRawSearchThreads } = useActions({
    getRawSearchThreads: getSearchThreadAsIdsAction,
  });

  const fetch = React.useCallback(
    async (after?: string) => {
      if (isLoading) return;

      try {
        setLoadState(true);
        const result = await getRawSearchThreads({
          type: ["post", "productQuestion", "productReview", "productComment"],
          contentTypes: ["all"],
          sort: "createdAt",
          order: "DESC",
          after,
          limit: 10,
        });
        if (result) {
          if (after) {
            setIdData(state => ({
              data: [...state.data, ...result.data],
              paging: result.paging,
            }));
          } else {
            setIdData(result);
          }
        }
        // eslint-disable-next-line no-empty
      } catch (err) {
      } finally {
        setLoadState(false);
      }
    },
    [getRawSearchThreads, isLoading],
  );

  const handleLoadMore = React.useCallback(() => {
    if (idData.paging.after) {
      fetch(idData.paging.after);
    }
  }, [fetch, idData.paging.after]);

  React.useEffect(() => {
    fetch();
  }, []);

  return (
    <Container>
      <InfiniteScroller
        threshold={800}
        isLoading={isLoading}
        paging={idData.paging}
        itemLength={idData.data.length}
        loadMore={handleLoadMore}
      >
        {data.map(item => (
          <FeedItem key={item.id} thread={item} />
        ))}
      </InfiniteScroller>
    </Container>
  );
};

export default withPlacement(React.memo(ContentFeed));
