import * as React from "react";
import { Function1 } from "lodash";

export type WindowResizeCallbackParameter = {
  width: number;
  height: number;
  top?: number;
  left?: number;
};

export type WindowResizeCallback = Function1<
  WindowResizeCallbackParameter,
  void
>;

function useWindowSize() {
  const [size, setSize] = React.useState([0, 0]);

  React.useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }

    function updateViewportResize(event: any) {
      setSize([event.width, event.height]);
    }

    if (window.onresize) {
      window.addEventListener("resize", updateSize, { passive: true });
    }

    if (window.visualViewport?.onresize) {
      window.visualViewport.addEventListener("resize", updateViewportResize, {
        passive: true,
      });
    }

    updateSize();
    return () => {
      window.removeEventListener("resize", updateSize);
      window.visualViewport?.removeEventListener(
        "resize",
        updateViewportResize,
      );
    };
  }, []);
  return size;
}

function useRegisterWindowResizeCallback(func: WindowResizeCallback): void {
  React.useLayoutEffect(() => {
    function updateSize() {
      func({ width: window.innerWidth, height: window.innerHeight });
    }

    function updateViewportResize(event: any) {
      func({
        width: event.currentTarget.width,
        height: event.currentTarget.height,
        top: event.currentTarget.offsetTop,
        left: event.currentTarget.offsetLeft,
      });
    }

    window.addEventListener("resize", updateSize);
    window.visualViewport?.addEventListener("resize", updateViewportResize);

    updateSize();
    return () => {
      window.removeEventListener("resize", updateSize);
      window.visualViewport?.removeEventListener(
        "resize",
        updateViewportResize,
      );
    };
  }, []);
}

export { useWindowSize, useRegisterWindowResizeCallback };
