import React from "react";
import { useIntlShort } from "common/hooks/useIntlShort";
import SnackbarThemeProvider from "app/common/components/snackbar.new/themeProvider";

import * as Styled from "./styled";

function renderIcon(icon?: Moim.Snackbar.ISnackbarIconProps) {
  if (!icon) {
    return null;
  }

  return (
    <Styled.SnackbarIconClickWrapper
      width={icon.width}
      height={icon.height}
      onClick={icon.onClick}
    >
      {icon.renderComponent()}
    </Styled.SnackbarIconClickWrapper>
  );
}

type IProps = Moim.Snackbar.IFlagProps &
  Partial<
    Moim.Snackbar.ISnackbarLeftIconProps &
      Moim.Snackbar.ISnackbarRightIconProps &
      Moim.Snackbar.ISnackbarRightSecondIconProps
  >;

const FlagSnackBar = ({
  subType = "normal",
  title,
  description,
  bgColor,
  descTextColor,
  colorScale,
  leftIcon,
  rightIcon,
  rightSecondIcon,
  onClick,
  onClickClose,
  disableCloseButton,
}: IProps) => {
  const intl = useIntlShort();
  const handleClickSnackbar = React.useCallback<
    React.MouseEventHandler<HTMLDivElement>
  >(
    event => {
      event.stopPropagation();
      event.preventDefault();
      onClick?.();
    },
    [onClick],
  );

  return (
    <SnackbarThemeProvider scale={colorScale}>
      <Styled.Layer subType={subType} onClick={handleClickSnackbar}>
        <Styled.Wrapper bgColor={bgColor}>
          <Styled.TopArea>
            {renderIcon(leftIcon)}
            <Styled.Title>
              {typeof title === "string" ? intl(title) : title}
            </Styled.Title>

            {!disableCloseButton || !description ? (
              <Styled.ButtonWrapper>
                {!description && renderIcon(rightIcon)}
                {!description && renderIcon(rightSecondIcon)}

                {!disableCloseButton &&
                  renderIcon({
                    width: 30,
                    height: 30,
                    renderComponent: () => <Styled.CloseIcon />,
                    onClick: onClickClose,
                  })}
              </Styled.ButtonWrapper>
            ) : null}
          </Styled.TopArea>
          {description && (
            <>
              <Styled.BodyArea>
                <Styled.Description textColor={descTextColor}>
                  {typeof description === "string"
                    ? intl(description)
                    : description}
                </Styled.Description>
              </Styled.BodyArea>
              <Styled.FooterArea>
                <Styled.ButtonWrapper>
                  {renderIcon(rightIcon)}
                  {renderIcon(rightSecondIcon)}
                </Styled.ButtonWrapper>
              </Styled.FooterArea>
            </>
          )}
        </Styled.Wrapper>
      </Styled.Layer>
    </SnackbarThemeProvider>
  );
};

export default FlagSnackBar;
