import * as React from "react";
import {
  Input,
  InputWrapper,
  SearchIcon,
  SearchIconWrapper,
  Wrapper,
} from "./styled";
import { useIntlShort } from "common/hooks/useIntlShort";
import { RemoveIcon } from "../../../../modules/search/components/inputBar/styled";

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
  labelFor?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isBottomSheetOpen?: boolean;
}

const BottomSheetSearchInput = React.forwardRef<HTMLInputElement, IProps>(
  (props, inputRef) => {
    const { labelFor, onChange, isBottomSheetOpen, ...rest } = props;
    const intl = useIntlShort();
    const [innerValue, setInnerValue] = React.useState("");

    const handleChange = React.useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        setInnerValue(e.currentTarget.value);
        onChange(e);
      },
      [onChange],
    );

    const handleRemoveAll = React.useCallback(() => {
      onChange({ currentTarget: { value: "" } } as any);
      setInnerValue("");
    }, [onChange]);

    React.useEffect(() => {
      if (!isBottomSheetOpen) {
        setInnerValue("");
      }
    }, [isBottomSheetOpen]);

    return (
      <Wrapper htmlFor={labelFor}>
        <InputWrapper>
          <Input
            {...rest}
            value={innerValue}
            onChange={handleChange}
            placeholder={intl("search_placeholder")}
            ref={inputRef}
          />
          <SearchIconWrapper>
            {innerValue.length > 0 ? (
              <RemoveIcon onClick={handleRemoveAll} />
            ) : (
              <SearchIcon />
            )}
          </SearchIconWrapper>
        </InputWrapper>
      </Wrapper>
    );
  },
);

export default BottomSheetSearchInput;
