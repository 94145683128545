import produce from "immer";
import { AllActions } from "app/actions";
import { GlobalAlertDialogTypes } from "app/actions/types";

export interface IState {
  open: boolean;
  content: string | null;
  buttons?: {
    text: string;
    textColor?: string;
  }[];
}

export const INITIAL_STATE: IState = {
  open: false,
  content: null,
  buttons: undefined,
};

export function reducer(state = INITIAL_STATE, action: AllActions) {
  return produce(state, draft => {
    switch (action.type) {
      case GlobalAlertDialogTypes.OPEN: {
        draft.open = true;
        draft.content = action.payload.content;
        draft.buttons = action.payload.buttons;

        break;
      }

      case GlobalAlertDialogTypes.CLOSE: {
        draft.open = false;
        draft.content = null;
        draft.buttons = [];
        break;
      }
    }
  });
}
