import React from "react";
import { StepContext, IStepData } from "./context";
export { StepContext, IStepData };
const DialogStepController = ({
  children,
  steps,
}: React.PropsWithChildren<{ steps: IStepData[] }>) => {
  const defaultIndex = React.useMemo(
    () => steps.findIndex(step => step.default),
    [],
  );
  const [currentStep, setCurrentStep] = React.useState<number>(
    defaultIndex >= 0 ? defaultIndex : 0,
  );
  const [error, setError] = React.useState<string>();

  const stepCount = steps.length;

  const handlePrev = React.useCallback(() => {
    setCurrentStep(current => {
      if (current > 0) {
        return current - 1;
      }
      return current;
    });
  }, []);

  const handleNext = React.useCallback(() => {
    setCurrentStep(current => {
      if (current < stepCount) {
        return current + 1;
      }

      return current;
    });
  }, [stepCount]);
  return (
    <StepContext.Provider
      value={{
        steps,
        currentStep,
        isLastStep: currentStep + 1 === stepCount,
        error,
        onNext: handleNext,
        onPrev: handlePrev,
        setError,
        setCurrentStep,
      }}
    >
      {children}
    </StepContext.Provider>
  );
};

export default DialogStepController;
