import { H5Regular } from "common/components/designSystem/typos";
import React from "react";
import styled from "styled-components";

interface IProps {
  children: React.ReactNode;
  text?: string;
}

const Container = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
  height: 100%;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.colorV2.colorSet.grey600};
  pointer-events: none;
  z-index: ${(props) => props.theme.zIndexes.default};
`;

const TextOverlay = styled(H5Regular)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${(props) => props.theme.themeMode.lightPalette.colorSet.white1000};
  text-align: center;
  pointer-events: none;
  z-index: ${(props) => props.theme.zIndexes.default};
`;

const DimmedOverlay: React.FC<IProps> = ({ children, text = "" }) => {
  return (
    <Container>
      {children}
      <Overlay />
      {text && <TextOverlay>{text}</TextOverlay>}
    </Container>
  );
};

export default DimmedOverlay;
