import * as React from "react";
import styled from "styled-components";

import ShavedText from "common/components/shavedText/v2";
import { ThreadDescriptionWrapper } from "common/components/thread/components/wrapper/description";

import { textAlignStyle } from "common/components/thread/styles";

interface IProps {
  description?: string;
  descriptionPlain?: string;
  isUnread?: boolean;
  textAlign?: Moim.Forum.ForumListConfigTextAlignment;
  line?: number;
}

const DescriptionWrapper = styled(ThreadDescriptionWrapper)<{
  textAlign?: Moim.Forum.ForumListConfigTextAlignment;
}>`
  ${textAlignStyle}
`;

function Description({
  description,
  descriptionPlain,
  textAlign,
  line = 2,
}: IProps) {
  return (
    <DescriptionWrapper
      description={description}
      descriptionPlain={descriptionPlain}
      textAlign={textAlign}
    >
      {el => <ShavedText line={line}>{el}</ShavedText>}
    </DescriptionWrapper>
  );
}

export default React.memo(Description);
