import styled from "styled-components";
import { px2rem } from "common/helpers/rem";

type PADDING_KEY = "top" | "right" | "bottom" | "left";

export const Wrapper = styled.div<{
  isMultiRow: boolean;
}>`
  position: relative;
  width: 100%;
  height: fit-content;
`;

export const InnerWrapper = styled.div<{
  padding?: Record<PADDING_KEY, number>;
}>`
  width: 100%;
`;

export const JoinedSubMoimOpenButton = styled.div`
  position: absolute;
  top: ${px2rem(23)};
  left: 0;
  z-index: ${props => props.theme.zIndexes.fullscreen};
`;
