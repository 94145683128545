import * as React from "react";
import { FlattenInterpolation } from "styled-components";
import { RangeStatic } from "quill";
import { useIntl } from "react-intl";
import { BaseEmoji } from "emoji-mart";
import {
  ALL_VIDEO_MIME_REGEX,
  WHITE_LIST_IMAGE_MIME_REGEX,
} from "common/constants/mimeTypes";
// actions
import { useActions } from "app/store";
import useCancelToken from "common/hooks/useCancelToken";
import {
  fileUpload as fileUploadAction,
  ActionCreators as fileUploadActionCreators,
} from "app/actions/fileUpload";
import useOpenState from "common/hooks/useOpenState";
// helpers
import useIsMobile from "common/hooks/useIsMobile";
import {
  isConsentRequired,
  isAcceptableSize,
} from "common/helpers/fileUploader/utils";

// editor utils
import {
  insertFile,
  deleteFile,
  changeFilePayload,
  getFileContent,
} from "./utils/file";
import {
  insertImageFile,
  deleteImageFileImageUID,
  deleteImageFileByFileId,
  appendImageBlock,
} from "./utils/imageFile";
import {
  insertVideoFile,
  deleteVideoFileVideoUID,
  deleteVideoFileByFileId,
} from "./utils/videoFile";

import { insertUserMentionDenotation } from "./utils/mention";
import { insertLink } from "./utils/link";
import { insertEmoji } from "./utils/emoji";
import { getSelection } from "./utils/focusIntoSelection";
import { makeOrderedFiles } from "./utils/orderedUploadQueue";
// component
import { IRef as IRefQuillEditor } from "./component";
import { IItemMeta as IFileManageItemMeta } from "./components/fileUploadManageDialog";

export const TBD_VIDEO_BLOCK = true;

const DEFAULT_CONTENT_FILE_ATTACHMENT_COUNT = 50;
const DEFAULT_ONCE_UPLOAD_LIMIT_COUNT = 50;
const MINIMUM_COUNT_OF_IMAGE_UPLOAD_DIALOG_OPEN = 2;

interface IUploadQueue {
  fileId: Moim.Id;
  file: File;
  seqIndex: number;
}
let uploadQueue: Record<string, IUploadQueue | { seqIndex: number }> = {};

export interface IRefHandler {
  containerElement: HTMLDivElement | undefined | null;
  fileManagerOpenStatus: boolean;
  addLink(): void;
  addFiles(): void;
  addImageFiles(): void;
  addMediaFiles(): void;
  addMention(): void;
  addImageBlock(src: string): void;
  prependPreLinkMeeting(id: Moim.Id, name: string): void;
  addEmoji(emoji: BaseEmoji): void;
  addEmbedProduct(productIds: Moim.Id[]): void;
  addDownloadCoupon(id: Moim.Id[]): void;
  updateTextFormat(payload: Record<string, any>): void;
  fileUpload(
    filesParams: FileList | File[],
    retryObject?: {
      fileId: string;
    },
  ): void;
  getContent(): Moim.Blockit.Blocks[];
  getUploadQueue(): Moim.Id[];
  clear(): void;
  focus(): void;
  blur(): void;
  setContent?(content: Moim.Blockit.Blocks[]): void;
  onFileDelete(fileId: string): void;
}

export interface IProps {
  id: Moim.Id;
  contents: Moim.Blockit.Blocks[];
  containerStyle?: FlattenInterpolation<any>;
  maxContentFileCount?: number;
  onceUploadLimitCount?: number;
  placeholder?: string;
  readonly?: boolean;
  autofocus?: boolean;
  enableSingleLine?: boolean;
  disableFileUploadShortcut?: boolean;
  forceFullWidthFiles?: boolean;
  disableMention?: boolean;
  disableMentionPortal?: boolean;
  mentionPortalContainer?: HTMLElement | null;
  disableImageBlockUpload?: boolean;
  disableVideoBlockUpload?: boolean;
  groupId?: string;
  disableEditor?: boolean;
  renderSkeleton?: React.ReactNode;
  onFocus?(): void;
  onBlur?(): void;
  onCancel?(): void;
  onSingleLineEnter?(): void;
  onFileChange?(fileId: Moim.Id, file: Moim.Upload.IUploadFileMeta): void;
  onChange?(contents: Moim.Blockit.Blocks[]): void;
  onChangeCursorFormat?(format: Record<string, any>): void;
}

export function useProps(props: IProps) {
  const intl = useIntl();
  const fileUploadCancelToken = useCancelToken();
  const refQuillEditor = React.useRef<IRefQuillEditor>(null);
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const imageFileInputRef = React.useRef<HTMLInputElement>(null);
  const mediaFileInputRef = React.useRef<HTMLInputElement>(null);
  const [uploadFileIdQueue, setUploadFileQueue] = React.useState<Moim.Id[]>([]);
  const [storedRange, saveRange] = React.useState<RangeStatic | undefined>(
    undefined,
  );
  const [windowSelection, saveWindowSelection] = React.useState<Range | null>(
    null,
  );
  const isMobile = useIsMobile();
  const [initialImageFiles, setInitialImageFiles] = React.useState<File[]>();

  const { dispatchFileUpload, dispatchDeleteFile } = useActions({
    dispatchFileUpload: fileUploadAction,
    dispatchDeleteFile: fileUploadActionCreators.deleteFile,
  });

  const {
    isOpen: isOpenFileManageDialog,
    open: openFileManageDialog,
    close: closeFileManageDialog,
  } = useOpenState(false);

  return {
    ...props,
    intl,
    refQuillEditor,
    fileUploadCancelToken,
    fileInputRef,
    imageFileInputRef,
    mediaFileInputRef,
    isMobile,
    storedRange,
    saveRange,
    windowSelection,
    saveWindowSelection,
    uploadFileIdQueue,
    setUploadFileQueue,
    dispatchFileUpload,
    dispatchDeleteFile,

    isOpenFileManageDialog,
    openFileManageDialog,
    closeFileManageDialog,
    initialImageFiles,
    setInitialImageFiles,
  };
}

export function useHandlers(props: ReturnType<typeof useProps>) {
  const {
    intl,
    id,
    readonly,
    enableSingleLine,
    refQuillEditor,
    fileInputRef,
    imageFileInputRef,
    mediaFileInputRef,
    isMobile,
    disableImageBlockUpload,
    disableVideoBlockUpload,
    storedRange,
    forceFullWidthFiles,
    onceUploadLimitCount = DEFAULT_ONCE_UPLOAD_LIMIT_COUNT,
    maxContentFileCount = DEFAULT_CONTENT_FILE_ATTACHMENT_COUNT,
    fileUploadCancelToken,
    uploadFileIdQueue,
    setUploadFileQueue,
    saveRange,
    windowSelection,
    saveWindowSelection,
    openFileManageDialog,
    setInitialImageFiles,
    dispatchFileUpload,
    dispatchDeleteFile,
    onSingleLineEnter,
    onFocus,
    onFileChange,
  } = props;

  // #region About files
  const handleFileDelete = React.useCallback(
    (fileId: Moim.Id) => {
      const quill = refQuillEditor.current?.getQuill();
      if (quill) {
        setUploadFileQueue(state => state.filter(i => i !== fileId));
        deleteFile(quill, fileId);
      }
    },
    [refQuillEditor, setUploadFileQueue],
  );

  const handleImageFileDelete = React.useCallback(
    (payload: { fileId?: Moim.Id; UId?: Moim.Id }) => {
      const quill = refQuillEditor.current?.getQuill();
      if (quill) {
        if (payload.UId) {
          if (payload.fileId) {
            setUploadFileQueue(state =>
              state.filter(i => i !== payload.fileId),
            );
          }
          deleteImageFileImageUID(quill, payload.UId);
        } else if (payload.fileId) {
          setUploadFileQueue(state => state.filter(i => i !== payload.fileId));
          deleteImageFileByFileId(quill, payload.fileId);
        }
      }
    },
    [refQuillEditor, setUploadFileQueue],
  );

  const handleVideoFileDelete = React.useCallback(
    (payload: { fileId?: Moim.Id; UId?: Moim.Id }) => {
      const quill = refQuillEditor.current?.getQuill();
      if (quill) {
        if (payload.UId) {
          if (payload.fileId) {
            setUploadFileQueue(state =>
              state.filter(i => i !== payload.fileId),
            );
          }
          deleteVideoFileVideoUID(quill, payload.UId);
        } else if (payload.fileId) {
          setUploadFileQueue(state => state.filter(i => i !== payload.fileId));
          deleteVideoFileByFileId(quill, payload.fileId);
        }
      }
    },
    [refQuillEditor, setUploadFileQueue],
  );

  // NOTE: Don't change variable type.
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  let handleFileRetry = React.useCallback((_fileId: Moim.Id, _file: File) => {},
  []);

  const bufferedFiles = React.useCallback(() => {
    const quill = refQuillEditor.current?.getQuill();
    if (quill) {
      // Note: why using Reverse, cuz inserted editor cursor unknown
      Object.entries(uploadQueue)
        .sort(([, x], [, y]) => {
          if (x.seqIndex > y.seqIndex) return 1;
          if (x.seqIndex < y.seqIndex) return -1;
          return 0;
        })
        .reverse()
        .forEach(([, unsafeData], index) => {
          const data = unsafeData as IUploadQueue;
          const sequentialRange = {
            index: (storedRange?.index ?? 0) + index,
            length: 0,
          };

          if (data?.file && data?.fileId) {
            if (
              data.file.type.match(WHITE_LIST_IMAGE_MIME_REGEX) &&
              !disableImageBlockUpload
            ) {
              insertImageFile(
                quill,
                {
                  imageFileGroupName: id,
                  forceFullWidthFiles,
                  onFileRetry: handleFileRetry,
                  onFileDelete: handleImageFileDelete,
                },
                {
                  fileData: { fileId: data.fileId, file: data.file },
                },
                sequentialRange,
              );
            } else if (
              !TBD_VIDEO_BLOCK &&
              data.file.type.match(ALL_VIDEO_MIME_REGEX) &&
              !disableVideoBlockUpload
            ) {
              insertVideoFile(
                quill,
                {
                  videoFileGroupName: id,
                  forceFullWidthFiles,
                  onFileRetry: handleFileRetry,
                  onFileDelete: handleVideoFileDelete,
                },
                {
                  fileData: { fileId: data.fileId, file: data.file },
                },
                sequentialRange,
              );
            } else {
              insertFile(
                quill,
                {
                  imageFileGroupName: id,
                  forceFullWidthFiles,
                  onFileRetry: handleFileRetry,
                  onFileDelete: handleFileDelete,
                },
                {
                  fileId: data.fileId,
                  file: data.file,
                },
                sequentialRange,
              );
            }
          }
        });
    }
    uploadQueue = {};
  }, [
    refQuillEditor,
    disableImageBlockUpload,
    disableVideoBlockUpload,
    id,
    forceFullWidthFiles,
    handleFileRetry,
    handleImageFileDelete,
    storedRange,
    handleVideoFileDelete,
    handleFileDelete,
  ]);

  const handleAfterGetFileId = React.useCallback(
    (fileId: Moim.Id, file: Moim.Upload.IUploadFileMeta) => {
      if (!enableSingleLine) {
        uploadQueue[file.file.name] = {
          fileId,
          file: file.file,
          seqIndex: uploadQueue[file.file.name].seqIndex,
        };

        if (
          !Object.entries(uploadQueue).some(
            ([, data]) =>
              !Object.keys(data).find(
                key => key === "fileId" || key === "file",
              ),
          )
        ) {
          bufferedFiles();
        }
      }
      onFileChange?.(fileId, file);
      setUploadFileQueue(tmpState => tmpState.concat(fileId));
    },
    [bufferedFiles, enableSingleLine, onFileChange, setUploadFileQueue],
  );

  const getFileIdListener = React.useCallback(
    (file: Moim.Upload.IUploadFileMeta, retryObject?: { fileId: Moim.Id }) => (
      fileId: Moim.Id,
    ) => {
      if (retryObject && !enableSingleLine) {
        dispatchDeleteFile(retryObject.fileId);
        const quill = refQuillEditor.current?.getQuill();
        if (quill) {
          changeFilePayload(quill, retryObject.fileId, fileId);
        }
      } else {
        handleAfterGetFileId(fileId, file);
      }
    },
    [
      dispatchDeleteFile,
      enableSingleLine,
      handleAfterGetFileId,
      refQuillEditor,
    ],
  );

  const upload = React.useCallback(
    async (
      file: Moim.Upload.IUploadFileMeta,
      retryObject?: { fileId: Moim.Id },
    ) => {
      if (!file) return;
      const filename = file.file.name;
      await dispatchFileUpload(
        {
          title: filename,
          name: filename,
          file: file.file,
          cancelToken: fileUploadCancelToken.current.token,
        },
        getFileIdListener(file, retryObject),
      );
    },
    [dispatchFileUpload, fileUploadCancelToken, getFileIdListener],
  );

  const fileUpload = React.useCallback(
    async (
      filesParams: FileList | File[],
      retryObject?: { fileId: Moim.Id },
      ignoreInnerSorting?: boolean,
    ) => {
      const orderedFiles = makeOrderedFiles(filesParams, ignoreInnerSorting);
      uploadQueue = orderedFiles.reduce<Record<string, { seqIndex: number }>>(
        (accHolder, file, index) => {
          accHolder[file.file.name] = {
            seqIndex: index,
          };
          return accHolder;
        },
        {},
      );
      const maxSizeFile = orderedFiles.sort((left, right) => {
        if (left.file.size > right.file.size) return -1;
        if (left.file.size < right.file.size) return 1;
        return 0;
      })[0];

      const fileNodeCount = getFileContent(refQuillEditor.current?.getQuill())
        .length;

      if (
        fileNodeCount + orderedFiles.length > maxContentFileCount ||
        fileNodeCount >= maxContentFileCount ||
        orderedFiles.length > onceUploadLimitCount
      ) {
        window.alert(
          intl.formatMessage(
            {
              id: "input_field_file_maximum_number_toast_message",
            },
            { plain_count: maxContentFileCount },
          ),
        );
        return;
      }
      if (isMobile && isConsentRequired(maxSizeFile.file)) {
        const userConsent = window.confirm(
          intl.formatMessage({
            id: "input_field_file_upload_mobile_web_dialog_body",
          }),
        );
        if (!userConsent) {
          return;
        }
      }

      if (!(await isAcceptableSize(maxSizeFile.file))) {
        window.alert(
          intl.formatMessage(
            {
              id: "input_field_file_maximum_size_toast_message",
            },
            { plain_count: 1 },
          ),
        );
        return;
      }

      const promises: Promise<void>[] = [];
      for (
        let i = 0;
        i <
        (orderedFiles.length < onceUploadLimitCount
          ? orderedFiles.length
          : onceUploadLimitCount);
        i++
      ) {
        promises.push(upload(orderedFiles[i], retryObject));
      }

      await Promise.all(promises);
    },
    [
      intl,
      isMobile,
      maxContentFileCount,
      onceUploadLimitCount,
      refQuillEditor,
      upload,
    ],
  );

  handleFileRetry = React.useCallback(
    (fileId: Moim.Id, file: File) => {
      fileUpload([file], { fileId });
    },
    [fileUpload],
  );

  const exportFileUpload = React.useCallback(
    async (files: FileList | File[], retryObject?: { fileId: Moim.Id }) => {
      setInitialImageFiles(undefined);
      if (
        isMobile ||
        Array.from(files).some(
          file => !file.type.match(WHITE_LIST_IMAGE_MIME_REGEX),
        ) ||
        files.length < MINIMUM_COUNT_OF_IMAGE_UPLOAD_DIALOG_OPEN
      ) {
        return fileUpload(files, retryObject);
      } else {
        setInitialImageFiles(Array.from(files));
        openFileManageDialog();
      }

      return;
    },
    [fileUpload, isMobile, setInitialImageFiles],
  );

  const handleFileChange: React.ReactEventHandler<HTMLInputElement> = React.useCallback(
    e => {
      const filesParam = e.currentTarget.files;
      setInitialImageFiles(undefined);
      if (filesParam) {
        exportFileUpload(filesParam);
      }
      e.currentTarget.files = null;
      e.currentTarget.value = "";
    },
    [exportFileUpload, setInitialImageFiles],
  );

  const handleImageFileChange: React.ReactEventHandler<HTMLInputElement> = React.useCallback(
    e => {
      const filesParam = e.currentTarget.files;
      setInitialImageFiles(undefined);
      if (filesParam) {
        if (
          isMobile ||
          filesParam.length < MINIMUM_COUNT_OF_IMAGE_UPLOAD_DIALOG_OPEN
        ) {
          fileUpload(filesParam);
        } else {
          setInitialImageFiles(Array.from(filesParam));
          openFileManageDialog();
        }
      }
      e.currentTarget.files = null;
      e.currentTarget.value = "";
    },
    [fileUpload, isMobile],
  );

  // #endregion

  const handleFocus = React.useCallback(() => {
    onFocus?.();
  }, [onFocus]);

  const handleSingleLineEnter = React.useCallback(() => {
    if (!isMobile && enableSingleLine) {
      onSingleLineEnter?.();
      return false;
    }
    return true;
  }, [isMobile, enableSingleLine, onSingleLineEnter]);

  const handleEditorClear = React.useCallback(() => {
    refQuillEditor.current?.clear();
  }, [refQuillEditor]);

  const handleEditorFocus = React.useCallback(() => {
    const editor = refQuillEditor.current;

    if (!readonly && editor && windowSelection) {
      editor.setFocus();
    }
  }, [readonly, refQuillEditor, windowSelection]);

  const handleEditorBlur = React.useCallback(() => {
    refQuillEditor.current?.getQuill()?.blur();
  }, [refQuillEditor]);

  const getMoimDown = React.useCallback(
    () => refQuillEditor.current?.getContents() || [],
    [refQuillEditor],
  );

  const addFiles = React.useCallback(() => {
    handleEditorFocus();
    fileInputRef.current?.click();
  }, [fileInputRef, handleEditorFocus]);

  const addMediaFiles = React.useCallback(() => {
    handleEditorFocus();
    mediaFileInputRef.current?.click();
  }, [mediaFileInputRef, handleEditorFocus]);

  const addImageFiles = React.useCallback(() => {
    handleEditorFocus();
    imageFileInputRef.current?.click();
  }, [imageFileInputRef, handleEditorFocus]);

  const handleInsertMention = React.useCallback(() => {
    insertUserMentionDenotation(
      refQuillEditor.current?.getQuill(),
      storedRange,
    );
  }, [refQuillEditor, storedRange]);

  const handleToolBoxLinkClick = React.useCallback(() => {
    insertLink(
      refQuillEditor.current?.getQuill(),
      intl.formatMessage({ id: "post_editor/link_input_field_dialog_title" }),
      storedRange,
    );
  }, [refQuillEditor, intl, storedRange]);

  const handleSelectEmoji = React.useCallback(
    (emoji: BaseEmoji) => {
      insertEmoji(
        refQuillEditor.current?.getQuill(),
        emoji.colons,
        storedRange,
      );
    },
    [refQuillEditor, storedRange],
  );

  const handleSaveSelection = React.useCallback(
    (range: RangeStatic) => {
      saveRange(range);
      saveWindowSelection(getSelection());
    },
    [saveRange, saveWindowSelection],
  );

  const handlePrependPreLinkMeeting = React.useCallback(
    (meetingId: Moim.Id, name: string) => {
      const preLinkMeetingBlock: Moim.Blockit.IMeetingBlock = {
        type: "meeting",
        name,
        previewAttendees: [],
        attendeesCount: 0,
        status: "open",
        meetingId,
        blocks: [],
      };
      const quill = refQuillEditor.current?.getQuill();
      if (quill) {
        quill.insertEmbed(0, "blockit-render", { block: preLinkMeetingBlock });
      }
    },
    [refQuillEditor],
  );

  const getUploadQueue = React.useCallback(() => uploadFileIdQueue, [
    uploadFileIdQueue,
  ]);

  const handleAddEmbedProduct = React.useCallback(
    (productIds: Moim.Id[]) => {
      const quill = refQuillEditor.current?.getQuill();
      if (quill) {
        const currentSelection = quill.getSelection() ??
          storedRange ?? { index: 1, length: 0 };
        quill.insertEmbed(currentSelection.index, "blockit-render", {
          block: { type: "embed-product-list", productIds },
        });
      }
    },
    [refQuillEditor, storedRange],
  );

  const handleAddDownCoupon = React.useCallback(
    (ids: Moim.Id[]) => {
      const quill = refQuillEditor.current?.getQuill();
      if (quill) {
        const currentSelection = quill.getSelection() ??
          storedRange ?? { index: 1, length: 0 };
        quill.insertEmbed(currentSelection.index, "blockit-render", {
          block: { type: "downloadable-coupon-list", resources: ids },
        });
      }
    },
    [refQuillEditor, storedRange],
  );

  const handleUpdateTextFormat = React.useCallback(
    (payload: Record<string, any>) => {
      refQuillEditor.current?.updateTextFormat(payload);
    },
    [refQuillEditor],
  );

  const addImageBlock = React.useCallback(
    (src: string) => {
      const quill = refQuillEditor.current?.getQuill();
      if (!quill) return;

      handleEditorFocus();
      appendImageBlock(quill, src, handleImageFileDelete, storedRange);
    },
    [handleEditorFocus, handleImageFileDelete, storedRange],
  );

  const handleSubmitFileManage = React.useCallback(
    (items: IFileManageItemMeta[]) => {
      fileUpload(
        items.map(item => item.payload.rawFile),
        undefined,
        true,
      );
    },
    [fileUpload],
  );

  return {
    ...props,
    addFiles,
    addImageFiles,
    addMediaFiles,
    addImageBlock,
    getMoimDown,
    onceUploadLimitCount,
    exportFileUpload,
    fileUpload,
    handleSaveSelection,
    handleSingleLineEnter,
    handleFocus,
    handleFileRetry,
    handleFileDelete,
    handleFileChange,
    handleImageFileChange,
    handleImageFileDelete,
    handleToolBoxLinkClick,
    handleInsertMention,
    handleSelectEmoji,
    handleEditorClear,
    handleEditorFocus,
    handleEditorBlur,
    handlePrependPreLinkMeeting,
    handleAddEmbedProduct,
    handleAddDownCoupon,
    getUploadQueue,
    handleUpdateTextFormat,
    handleSubmitFileManage,
  };
}
