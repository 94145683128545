import * as React from "react";
import { useStoreState } from "app/store";
import { FlatJoinButton, GhostJoinButton, TextJoinButton } from "./styled";

interface IProp {
  label: React.ReactNode;
  size?: Moim.DesignSystem.Size;
  type: Moim.Layout.Navigation.IButtonStyle["type"];
  onClick(): void;
}

function JoinButtonView({ type, label, onClick }: IProp) {
  const { joinButtonScale } = useStoreState(state => ({
    joinButtonScale: state.group.theme.element.sideArea.childMoimNameText.scale,
  }));
  switch (type) {
    default:
    case "FLAT":
      return (
        <FlatJoinButton
          joinButtonScale={joinButtonScale}
          onClick={onClick}
          size="s"
        >
          {label}
        </FlatJoinButton>
      );
    case "GHOST":
      return (
        <GhostJoinButton
          joinButtonScale={joinButtonScale}
          onClick={onClick}
          size="s"
        >
          {label}
        </GhostJoinButton>
      );
    case "TEXT":
      return (
        <TextJoinButton
          joinButtonScale={joinButtonScale}
          onClick={onClick}
          size="s"
        >
          {label}
        </TextJoinButton>
      );
  }
}

export default JoinButtonView;
