import * as React from "react";
import { useLongPress } from "react-use";
import styled from "styled-components";
import { px2rem } from "common/helpers/rem";
// hooks
import { useCommonFactoryProps } from "../hooks";
import { useForumThreadInput } from "../components/threadInput/hooks";
// components
import Factory, { Row } from "../components/factory";
import { TextBodyBubble } from "../components/textBody";
import Engage from "../components/engage";
import MultiImage from "../components/media/multiImage";
import { Spacer } from "common/components/designSystem/spacer";
import { IBaseProps } from "..";
import Video from "../components/media/video";
import { NonSelectableDiv } from "common/components/threadV2/styled";

const NestedReplyHiddenReactionArea = styled.div`
  height: ${px2rem(6)};
`;

interface INestedReplyProps extends IBaseProps {
  replyId: Moim.Id;
  engage?: React.ComponentProps<typeof Engage>;
  longPressEvent?: ReturnType<typeof useLongPress>;
}

const NestedReply = React.forwardRef<HTMLDivElement | null, INestedReplyProps>(
  ({ engage, replyId, longPressEvent, ...baseProps }, ref) => {
    const {
      size = "m",
      headerProps,
      avatarProps,
      linkPreviewProps,
      mediaProps,
      hover,
      contents,
      menus,
      selected,
      editState,
      blockitElement,
    } = useCommonFactoryProps(baseProps);
    const hookProps = useForumThreadInput.useProps();
    const {
      handleEditThread: handleEditThreadBase,
    } = useForumThreadInput.useHandlers(hookProps);

    const handleEditThread = React.useCallback(
      (newContents: Moim.Blockit.Blocks[]) => {
        handleEditThreadBase(undefined, newContents);
        editState?.onEnter();
      },
      [handleEditThreadBase, editState],
    );

    const engageProps: React.ReactNode = React.useMemo(
      () =>
        engage ? (
          <Engage disableWriteReplyButton={true} {...engage} />
        ) : (
          <NestedReplyHiddenReactionArea />
        ),
      [engage],
    );

    const textBodyProps: React.ReactNode = React.useMemo(() => {
      const { isEditMode, onCancel } = editState;
      const imageBlocks =
        contents?.filter<Moim.Blockit.IImageBlock>(
          ((blk: Moim.Blockit.Blocks) => blk.type === "image") as any,
        ) ?? [];
      const videoBlocks =
        contents?.filter<Moim.Blockit.IVideoBlock>(
          ((blk: Moim.Blockit.Blocks) => blk.type === "video") as any,
        ) ?? [];

      return (
        <>
          {imageBlocks.length > 0 && !Boolean(isEditMode) && (
            <>
              <Spacer value={8} />
              <MultiImage
                parentId={replyId}
                images={imageBlocks.map(imgBlock => ({
                  fileId: imgBlock.fileId,
                  url: imgBlock.src,
                  blurhash: imgBlock.blurHash,
                  fallbackSrc: imgBlock.fallbackSrc,
                  srcSet: imgBlock.srcSet,
                }))}
              />
              <Spacer value={8} />
            </>
          )}
          {videoBlocks.length > 0 && !Boolean(isEditMode) && (
            <>
              <Spacer value={8} />
              {videoBlocks.map(vBlock => (
                <Video
                  fileName={vBlock.fileName}
                  sources={[
                    {
                      type: vBlock.mimeType,
                      src: vBlock.sourceUrl,
                    },
                  ]}
                  poster={vBlock.metaData?.poster}
                  width={vBlock.metaData?.width}
                  height={vBlock.metaData?.height}
                />
              ))}

              <Spacer value={8} />
            </>
          )}

          <TextBodyBubble
            id={replyId}
            isSimpleMode={false}
            contents={contents}
            editState={{
              isEditMode: Boolean(isEditMode),
              onEnter: handleEditThread,
              onCancel,
            }}
          />
        </>
      );
    }, [replyId, contents, editState, handleEditThread]);

    return (
      <Factory
        type="nestedReply"
        ref={ref}
        size={size}
        hover={hover}
        menus={menus}
        selected={selected || editState.isEditMode}
        isFullWidthRight={true}
        isAnonymous={baseProps.isAnonymous}
        header={headerProps}
        avatar={avatarProps}
      >
        <NonSelectableDiv className="nested-reply-wrapper" {...longPressEvent}>
          {textBodyProps && (
            <Row key="nested-reply-text-row" className="nested-reply-text-row">
              {textBodyProps}
            </Row>
          )}
          {linkPreviewProps && (
            <Row key="nested-reply-linkpreview-row">{linkPreviewProps}</Row>
          )}
          {!editState.isEditMode && mediaProps && (
            <Row key="nested-reply-media-row">{mediaProps}</Row>
          )}
          {blockitElement && (
            <Row key="nested-reply-blockit-row">{blockitElement}</Row>
          )}

          {engageProps && (
            <Row key="nested-reply-engage-row">{engageProps}</Row>
          )}
        </NonSelectableDiv>
      </Factory>
    );
  },
);

export default React.memo(NestedReply);
