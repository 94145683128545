import styled, { css } from "styled-components";

import BaseButton from "common/components/designSystem/buttons/base";
import { px2rem } from "common/helpers/rem";
import { rgba } from "polished";
import { ThemeColorScaleTypes } from "app/enums";
import { blockAlign2FlexAlign } from "../../helper/blockitStyleHelpers";
import { getTextStyleV2 } from "common/components/designSystem/typos";

const commonStyle = css<{ customStyle?: Moim.Blockit.V2.IButtonCustomStyle }>`
  ${props => props.customStyle?.text && getTextStyleV2(props.customStyle?.text)}

  ${props =>
    props.customStyle?.padding?.top !== undefined
      ? css`
          padding-top: ${px2rem(props.customStyle?.padding.top)};
        `
      : undefined};
  ${props =>
    props.customStyle?.padding?.left !== undefined
      ? css`
          padding-left: ${px2rem(props.customStyle?.padding.left)};
        `
      : undefined};
  ${props =>
    props.customStyle?.padding?.right !== undefined
      ? css`
          padding-right: ${px2rem(props.customStyle?.padding.right)};
        `
      : undefined};
  ${props =>
    props.customStyle?.padding?.bottom !== undefined
      ? css`
          padding-bottom: ${px2rem(props.customStyle?.padding.bottom)};
        `
      : undefined};

  text-align: center;
`;
export const FlatButton = styled(BaseButton).attrs(props => ({
  loaderColor: props.theme.colorV2.colorSet.white1000,
}))<{
  color?: Moim.Blockit.V2.ColorValue;
  scale?: Moim.Enums.ThemeColorScaleType;
  customStyle?: Moim.Blockit.V2.IButtonCustomStyle;
}>`
  ${props => {
    const color = props.theme.getColorByAlias(
      props.color,
      props.theme.getButtonElementPalette("button").color ??
        props.theme.colorV2.primary.main,
    );

    const textColor = props.scale
      ? props.scale === ThemeColorScaleTypes.BLACK
        ? props.theme.colorV2.colorSet.grey800
        : props.theme.colorV2.colorSet.white800
      : props.theme.getButtonElementPalette("button").fog800;
    return css`
      border: 1px solid ${color};
      background-color: ${color};
      color: ${textColor};
      ${props.isActive
        ? css`
            border: 1px solid ${rgba(color!, 0.14)};
            background-color: ${rgba(color!, 0.14)};
            color: ${color};
          `
        : null}
    `;
  }}
  ${props => ((props.size as string) === "custom" ? commonStyle : undefined)}
`;

export const GhostButton = styled(BaseButton)<{
  color?: Moim.Blockit.V2.ColorValue;
  scale?: Moim.Enums.ThemeColorScaleType;
  size: string;
  customStyle?: Moim.Blockit.V2.IButtonCustomStyle;
}>`
  ${props => {
    const color = props.theme.getColorByAlias(
      props.color,
      props.theme.getButtonElementPalette("button").color ??
        props.theme.colorV2.primary.main,
    );
    return css`
      background-color: transparent;
      color: ${color};
      border: ${px2rem(1)} solid ${color};

      ${() => {
        if (props.isActive) {
          return `
        color: ${color};
        background-color: ${rgba(color!, 0.14)};
        border: 1px solid transparent;
      `;
        }
      }}
    `;
  }}
  ${props => ((props.size as string) === "custom" ? commonStyle : undefined)}
`;

export const TextButton = styled(BaseButton)<{
  color?: Moim.Blockit.V2.ColorValue;
  scale?: Moim.Enums.ThemeColorScaleType;
  size: string;
  customStyle?: Moim.Blockit.V2.IButtonCustomStyle;
}>`
  ${props => {
    const color = props.theme.getColorByAlias(
      props.color,
      props.theme.getButtonElementPalette("button").color ??
        props.theme.colorV2.primary.main,
    );
    return css`
      color: ${color};
    `;
  }}

  background-color: transparent;
  ${props => ((props.size as string) === "custom" ? commonStyle : undefined)}
`;

export const Wrapper = styled.div<{ align: Moim.Blockit.V2.IBlockAlignment }>`
  width: 100%;
  display: flex;
  justify-content: ${props => blockAlign2FlexAlign(props.align)};
`;
