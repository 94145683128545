import * as React from "react";
import CurrentGroupProfile from "../elements/currentGroupProfile";
import CurrentUser from "../elements/currentUser";
import MenuElement from "../elements/menu";
import Search from "../elements/search";
import SectionElement from "../elements/section";
import SubMoimProfileElement from "../elements/subMoimProfile";
import CommerceCategoryMenu from "../elements/commerceCategory";

interface IProps {
  element: Moim.Layout.Navigation.ElementType;
  zIndex?: number;
}
export default function ElementRenderer({ element, zIndex = 0 }: IProps) {
  const renderChildElement = React.useCallback(
    (target: Moim.Layout.Navigation.ElementType) => {
      switch (target.type) {
        case "section": {
          return <SectionElement element={target} zIndex={zIndex} />;
        }

        case "currentGroupProfileElement": {
          return <CurrentGroupProfile blockProperties={target} />;
        }

        case "categoryElement": {
          return <CommerceCategoryMenu blockProperties={target} />;
        }

        case "subMoimProfileElement": {
          return <SubMoimProfileElement blockProperties={target} />;
        }

        case "currentUserElement": {
          return <CurrentUser blockProperties={target} />;
        }

        case "menuElement": {
          return <MenuElement blockProperties={target} />;
        }

        case "searchElement": {
          return <Search blockProperties={target} />;
        }

        default:
          return null;
      }
    },
    [],
  );

  return renderChildElement(element);
}
