import { ThemeColorScaleTypes } from "app/enums";
import generateColorTheme from "app/theme/color";
import generator from "../generator";

const BG_COLOR = generateColorTheme().red;
const TEXT_COLOR = "black"; // theme 과 상관없이 고정된 색상을 사용한다.
const SCALE: Moim.Enums.ThemeColorScaleType = ThemeColorScaleTypes.WHITE;

export default generator({
  bgColor: BG_COLOR,
  textColor: TEXT_COLOR,
  scale: SCALE,
});
