import { useMemo } from "react";
import useEntitiesFetchSelector from "./useEntitiesFetchSelector";

const EMPTY_ARRAY = [];

export default function useTagGroupFetchSelector(requestIds: Moim.Id[]) {
  const { isLoading, entities } = useEntitiesFetchSelector({
    tagGroups: requestIds,
  });

  const tagGroups = useMemo(() => {
    const originArray =
      entities.tagGroups?.filter(it => it !== undefined) ?? [];
    return originArray.length === 0 ? EMPTY_ARRAY : originArray;
  }, [entities.tagGroups]);

  return {
    isLoading,
    tagGroups: tagGroups as Moim.TagSet.ITagGroup[],
  };
}
