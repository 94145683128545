import styled from "styled-components";
import { B4Regular, H10BoldStyle } from "common/components/designSystem/typos";
import { px2rem } from "common/helpers/rem";

export const Wrapper = styled.div`
  width: 100%;
  cursor: pointer;
`;

export const TextWrapper = styled.div`
  width: 100%;
  text-align: center;
  color: ${props => props.theme.colorV2.colorSet.grey800};
  ${H10BoldStyle}
`;
export const SubText = styled(B4Regular)`
  width: 100%;
  text-align: center;
  color: ${props => props.theme.colorV2.colorSet.grey400};
`;
export const ImageWrapper = styled.div<{
  shape: "circle" | "square";
  radius?: number;
}>`
  position: relative;
  width: 100%;
  padding-top: 100%;
  & > img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: ${props =>
      props.shape === "circle" ? "50%" : px2rem(props.radius ?? 0)};
  }
`;

export const ItemContainer = styled.div`
  position: relative;
  width: 100%;

  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  transition: box-shadow 200ms ease-in-out;
`;
