import * as React from "react";

import { withPlacement } from "../../hoc/withPlacement";

import ContentGroupPreviewWithResourceId from "./components/resourceId";
import ContentGroupPreviewWithQueries from "./components/query";

const ContentGroupPreview: React.FC<Moim.Blockit.V2.IContentGroupPreviewBlock> = props => {
  if (props.query) {
    return <ContentGroupPreviewWithQueries {...props} query={props.query} />;
  }
  return (
    <ContentGroupPreviewWithResourceId
      {...props}
      resourceId={props.resourceId!}
    />
  );
};

export default withPlacement(React.memo(ContentGroupPreview));
