import produce from "immer";
import { AllActions } from "app/actions";
import { PurchasePreparationDialogTypes } from "app/actions/types";

export type ENTER_FROM_TYPES = "alone" | "team";
export type TEAM_MODE_TYPES = "new" | "join";
export interface IPurchasePreparationDialogPayload {
  productId: string;
  purchaseReadyItems?: Moim.Commerce.IPurchaseReadyItem[];
  agreementId?: Moim.Id;
  teamBuying?: {
    teamMode?: TEAM_MODE_TYPES;
    enterFrom: ENTER_FROM_TYPES;
    teamBuyingId: Moim.Id;
    teamId?: Moim.Id;
  };
}

export interface IState {
  open: boolean;
  payload: IPurchasePreparationDialogPayload | null;
  cachedData: {
    productId: string;
    purchaseReadyItems: Moim.Commerce.IPurchaseReadyItem[];
  } | null;
}

export const INITIAL_STATE: IState = {
  open: false,
  payload: null,
  cachedData: null,
};

export function reducer(state = INITIAL_STATE, action: AllActions) {
  return produce(state, draft => {
    switch (action.type) {
      case PurchasePreparationDialogTypes.OPEN: {
        draft.open = true;
        draft.payload = action.payload;
        if (draft.cachedData?.productId !== action.payload.productId) {
          draft.cachedData = {
            productId: action.payload.productId,
            purchaseReadyItems: action.payload.purchaseReadyItems ?? [],
          };
        }

        break;
      }

      case PurchasePreparationDialogTypes.CLOSE: {
        draft.open = false;
        draft.payload = null;
        break;
      }

      case PurchasePreparationDialogTypes.UPDATE_PURCHASE_READY_ITEMS: {
        if (draft.cachedData) {
          draft.cachedData = {
            ...draft.cachedData,
            purchaseReadyItems: action.payload.purchaseReadyItems ?? [],
          };
        }

        break;
      }
    }
  });
}
