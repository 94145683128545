import * as React from "react";
import styled, { css } from "styled-components";

import { useActions, useStoreState } from "app/store";
import { useIntlShort } from "common/hooks/useIntlShort";

import { ProductOptionItem } from "common/components/commerce/optionItem";
import { B4Regular } from "common/components/designSystem/typos";
import ButtonCaseTypeA from "common/components/commerce/optionItem/buttonCase/typeA";

import { VoteStatus } from "app/enums";
import { putProductVote } from "app/actions/commerce";
import { px2rem } from "common/helpers/rem";
import useBrandFetchSelector from "common/hooks/useEntitiesFetchSelectors/commerce/useBrandFetchSelector";
import Brand from "common/components/commerce/optionItem/components/brand";
import { Checkbox } from "common/components/designSystem/inputs";
import { Spacer } from "common/components/designSystem/spacer";
import { CheckboxBrandWrapper } from "../../../styled";
import { BackorderChip } from "app/modules/commerce/components/productShow/layout/productSummary/components/right/backorderChip";

const StyledOptionItem = styled(ProductOptionItem)<{ checkboxAbove: boolean }>`
  padding: ${px2rem(8)} 0 ${px2rem(2)};
  & + & {
    margin: ${px2rem(8)} 0 ${px2rem(2)};
  }

  ${props =>
    props.checkboxAbove &&
    css`
      .ITEM_WRAPPER {
        padding-left: ${px2rem(18)};
      }
    `};
`;
const DisabledReasonWrapper = styled(B4Regular)`
  text-transform: uppercase;
  color: ${props => props.theme.color.red};
`;

export const AccentedText = styled(B4Regular)<{ accented?: boolean }>`
  &&& {
    display: flex;
    color: ${props => (props.accented ? props.theme.color.blue : "")};

    * {
      color: ${props => (props.accented ? props.theme.color.blue : "")};
    }
  }
`;

interface IProps {
  item: Moim.Commerce.ICartItemDatum;
  error?: Moim.Commerce.ProductGroupItemErrorType;
  product?: Moim.Commerce.IProduct;

  onChangeOptionItem(
    productId: string,
    productVariantId: string | undefined,
    updated: Partial<Moim.Commerce.ICartItemDatum> | null,
  ): void;
}

const OptionItem: React.FC<IProps> = ({ item, error, onChangeOptionItem }) => {
  const intl = useIntlShort();
  const { product, productVariant } = useStoreState(state => ({
    product: state.entities.commerce_product[item.productId],
    productVariant: item.productVariantId
      ? state.entities.commerce_variants[item.productVariantId]
      : undefined,
  }));

  const { dispatchPutProductVote } = useActions({
    dispatchPutProductVote: putProductVote,
  });

  const handleClickFavorite = React.useCallback(() => {
    if (product?.vote?.type === VoteStatus.POSITIVE) {
      dispatchPutProductVote(
        item.productId,
        VoteStatus.POSITIVE,
        VoteStatus.NONE,
      );
    } else {
      dispatchPutProductVote(
        item.productId,
        VoteStatus.NONE,
        VoteStatus.POSITIVE,
      );
    }
  }, [dispatchPutProductVote, product?.vote?.type, item?.productId]);

  const handleChangeChecked = React.useCallback(
    (status: boolean, parcel: Moim.Commerce.IPurchaseReadyItem) => {
      onChangeOptionItem(parcel.productId, parcel.variantId, {
        checked: status,
      });
    },
    [onChangeOptionItem],
  );

  const handleChangeQty = React.useCallback(
    (value: number) => {
      onChangeOptionItem(item.productId, item.productVariantId, {
        quantity: value,
      });
    },
    [onChangeOptionItem, item.productId, item.productVariantId],
  );

  const handleClickDelete = React.useCallback(() => {
    onChangeOptionItem(item.productId, item.productVariantId, null);
  }, [onChangeOptionItem, item.productId, item.productVariantId]);

  const topErrorMessage = React.useMemo(() => {
    if (!item.disabled && !error) {
      return undefined;
    }
    switch (item.disabledReason) {
      case "INVALID_HOLDER": {
        return (
          <DisabledReasonWrapper>
            {intl("product_purchase_status_no_right")}
          </DisabledReasonWrapper>
        );
      }
    }
  }, [item.disabled, item.disabledReason]);

  const bottomErrorMessage = React.useMemo(() => {
    if (!item.disabled && !error) {
      return undefined;
    }

    switch (item.disabledReason) {
      case "INVALID_PRODUCT_VARIANT_ID": {
        return (
          <DisabledReasonWrapper>
            {intl("status_option_select_needed")}
          </DisabledReasonWrapper>
        );
      }

      case "HIDDEN_PRICE_PRODUCT": {
        return (
          <DisabledReasonWrapper>
            {intl("payment_failed_invalid_product_for_guest_users")}
          </DisabledReasonWrapper>
        );
      }

      case "INVALID_PRODUCT_STATUS":
      case "EXCEEDED_STOCK_COUNT":
        return undefined;
    }

    switch (error?.code) {
      case "INVALID_MAX_LIMITATION":
        return (
          <DisabledReasonWrapper>
            {intl("product_purchase_status_number_limited_max", {
              n: error.metadata?.quantity,
            })}
          </DisabledReasonWrapper>
        );
      case "INVALID_MIN_LIMITATION":
        return (
          <DisabledReasonWrapper>
            {intl("product_purchase_status_number_limited_min", {
              n: error.metadata?.quantity,
            })}
          </DisabledReasonWrapper>
        );

      case "HIDDEN_PRICE_PRODUCT": {
        return (
          <DisabledReasonWrapper>
            {intl("payment_failed_invalid_product_for_guest_users")}
          </DisabledReasonWrapper>
        );
      }
    }

    return undefined;
  }, [error, item.disabled, item.disabledReason]);

  const disabled =
    !product?.backOrderAllowed &&
    (item.disabled ||
      (productVariant ? !productVariant.buyable : false) ||
      (product ? !product.buyable : false));

  const stock = productVariant?.stockCount ?? product?.stockCount;

  const isBackOrderStock =
    product?.backOrderAllowed && item.quantity > (stock ?? 0);

  const maxQty = React.useMemo(() => {
    if (product?.backOrderAllowed) {
      return undefined;
    }
    return stock;
  }, [product?.backOrderAllowed, stock]);

  const isBackOrder = Boolean(
    ["onSale", "soldOut"].includes(product?.status) && isBackOrderStock,
  );

  const {
    brands: { [0]: brand },
  } = useBrandFetchSelector(product?.brandId ? [product.brandId] : []);

  return (
    <>
      {brand?.id && (
        <>
          <CheckboxBrandWrapper>
            <Checkbox
              id={`${item.productId}_${item.productVariantId}`}
              disabled={item.disabled}
              checked={item.checked}
              onChange={e => {
                handleChangeChecked(e.currentTarget.checked, {
                  productId: item.productId,
                  variantId: item.productVariantId,
                  sellerId: product.sellerId,
                  qty: item.quantity,
                });
              }}
            />
            <Brand
              className="single-brand-chip"
              brandId={brand?.id}
              brandLogoImageUrl={brand?.logoImageUrl}
              brandName={brand?.name}
            />
          </CheckboxBrandWrapper>
          <Spacer value={4} />
        </>
      )}
      <StyledOptionItem
        checkboxAbove={Boolean(brand?.id)}
        checked={item.checked}
        useCheckBox={!Boolean(brand?.id)}
        disabled={item.disabled}
        qty={item.quantity}
        productId={item.productId}
        productVariantId={item.productVariantId}
        brandId={brand?.id}
        brandName={brand?.name}
        brandLogoUrl={brand?.logoImageUrl}
        onChangeChecked={handleChangeChecked}
      >
        {({ Title, Option, Price, Status, defaultValue }) => (
          <>
            {Boolean(topErrorMessage) && <Status>{topErrorMessage}</Status>}
            <Title>{defaultValue.title}</Title>
            {defaultValue.option && <Option>{defaultValue.option}</Option>}
            <Price>{defaultValue.price}</Price>
            {isBackOrder && <BackorderChip />}
            {defaultValue.point}
            {bottomErrorMessage ? (
              <Status>{bottomErrorMessage}</Status>
            ) : isBackOrderStock || product?.stockCount === 0 ? (
              <Status>{defaultValue.status}</Status>
            ) : null}
            <ButtonCaseTypeA
              disabled={disabled}
              status={
                Boolean(
                  error?.code === "INVALID_MAX_LIMITATION" ||
                    error?.code === "INVALID_MIN_LIMITATION",
                )
                  ? "Error"
                  : undefined
              }
              isFavorite={product?.vote?.type === VoteStatus.POSITIVE}
              qty={item.quantity}
              maxQty={maxQty}
              onChangeQty={handleChangeQty}
              onClickDelete={handleClickDelete}
              onClickFavorite={handleClickFavorite}
            />
          </>
        )}
      </StyledOptionItem>
    </>
  );
};

export default React.memo(OptionItem);
