import styled from "styled-components";
import { px2rem } from "common/helpers/rem";
import { B1Regular, B4Regular } from "common/components/designSystem/typos";

export const Wrapper = styled.div`
  width: 100%;
  min-height: ${px2rem(72)};
  display: flex;
  align-items: center;
  column-gap: ${px2rem(12)};

  .left {
    width: fit-content;
  }

  .right {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

export const Username = styled(B1Regular)`
  color: ${props => props.theme.colorV2.colorSet.grey800};
`;

export const Description = styled(B4Regular)`
  margin-top: ${px2rem(2)};
  width: 100%;
  color: ${props => props.theme.colorV2.colorSet.grey300};
  white-space: pre-line;
`;
