import { Svg24ArrowG } from "@moim/icons";
import styled from "styled-components";
import { px2rem } from "common/helpers/rem";

import FallbackImage from "common/components/fallbackImage";
import ChipBase from "common/components/chips";
import { getFlexAlignStyle } from "common/components/productItemCell/components/wrapper/styled";

export const BrandWrapper = styled.div<{
  horizontalAlign?: "start" | "center" | "end" | "space-around";
}>`
  display: flex;
  align-items: center;
  padding: ${px2rem(6)} ${px2rem(17)};
  ${props =>
    props.horizontalAlign &&
    getFlexAlignStyle({
      direction: "row",
      horizontalAlign: props.horizontalAlign,
    })}

  &:hover {
    cursor: pointer;
  }
`;

export const FallbackImageWrapper = styled.div`
  width: 100%;
  padding-top: 100%;
  position: relative;
`;

export const ImageFallback = styled(FallbackImage)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

export const Logo = styled.img`
  width: ${px2rem(18)};
  height: ${px2rem(18)};
  border: solid 1px ${props => props.theme.colorV2.colorSet.grey50};
  border-radius: ${px2rem(4)};
`;

export const PointChip = styled(ChipBase).attrs({
  shape: "round",
  size: "small",
})`
  color: ${props => props.theme.colorV2.colorSet.grey800};
  text-transform: uppercase;
  gap: ${px2rem(4)};
  padding: 0;

  &:hover {
    cursor: pointer;
  }
`;

export const RightArrowIcon = styled(Svg24ArrowG).attrs({
  touch: 18,
  size: "s",
})``;
