import { useIntlShort } from "common/hooks/useIntlShort";
import React from "react";
import { Section } from "../paymentDetail/components/common";
import {
  ButtonContainer,
  ConfirmButton,
  DetailWrapper,
  GuideText,
  GuideWrapper,
  IconWrapper,
  InfoIcon,
  TrackingDialogContainer,
  TrackingDialogWrapper,
} from "./styled";

interface IProps {
  trackingNumber?: string;
  deliveryCompanyName?: string;
  onClose(): void;
}

const TrackingDialogComponent = ({
  deliveryCompanyName,
  trackingNumber,
  onClose,
}: IProps) => {
  const intl = useIntlShort();

  return (
    <TrackingDialogWrapper>
      <TrackingDialogContainer>
        <GuideWrapper>
          <IconWrapper>
            <InfoIcon />
          </IconWrapper>
          <GuideText>{intl("dialog_tracking_number_info_title")}</GuideText>
        </GuideWrapper>
        <DetailWrapper>
          <Section
            title={intl("common_text_shipping_tracking_number")}
            contents={trackingNumber}
          />
          <Section
            title={intl("common_text_shipping_company")}
            contents={deliveryCompanyName}
          />
        </DetailWrapper>
        <ButtonContainer>
          <ConfirmButton onClick={onClose} size="xl">
            {intl("button_ok")}
          </ConfirmButton>
        </ButtonContainer>
      </TrackingDialogContainer>
    </TrackingDialogWrapper>
  );
};

export default TrackingDialogComponent;
