import React from "react";
import Checkbox from "common/components/blockitEditorBase/components/blockitRenderer/components/inputs/checkbox/index";
import {
  ArrowIcon,
  CollapseIconButton,
  DialogTagItemsWrapper,
  DialogTagSet,
  DialogTagSetCount,
  DialogTagSetTitleWrapper,
  FilterCheckboxWrapperStyle,
  FilterSelectAllCheckboxWrapperStyle,
} from "./styled";
import useIsMobile from "common/hooks/useIsMobile";
import { useIntlShort } from "common/hooks/useIntlShort";

interface IProps {
  tagGroup: Moim.TagSet.ITagGroup;
  initialFolded?: boolean;
  selectedTags?: string[];
  onChange(selectedTags: string[]): void;
}

export const FilterDialogTagGroupItem: React.FC<IProps> = ({
  tagGroup,
  selectedTags = [],
  onChange,
  initialFolded = true,
}) => {
  const intl = useIntlShort();
  const [isFolded, setIsFolded] = React.useState(initialFolded);
  const isMobile = useIsMobile();
  const isAllChecked = tagGroup.items.length === selectedTags.length;

  return (
    <div>
      <DialogTagSet onClick={() => setIsFolded(state => !state)}>
        <DialogTagSetTitleWrapper>
          <span>{tagGroup.set}</span>
          {selectedTags.length > 0 && (
            <DialogTagSetCount>{selectedTags?.length}</DialogTagSetCount>
          )}
        </DialogTagSetTitleWrapper>
        <CollapseIconButton open={!isFolded}>
          <ArrowIcon />
        </CollapseIconButton>
      </DialogTagSet>
      {!isFolded && (
        <DialogTagItemsWrapper>
          {isMobile && (
            <Checkbox
              wrapperStyle={FilterSelectAllCheckboxWrapperStyle}
              label={`${intl("select_tag_dialog_select_all")}(${
                tagGroup.items?.length
              })`}
              name="select-all"
              element={{
                type: "check-box-input",
                initialChecked: isAllChecked,
              }}
              checked={isAllChecked}
              onChange={() =>
                onChange(
                  isAllChecked ? [] : tagGroup.items.map(item => item.id),
                )
              }
            />
          )}
          {tagGroup.items?.map(item => {
            const selected = selectedTags.includes(item.id);
            return (
              <Checkbox
                key={`tag-checkbox-${item.value}`}
                wrapperStyle={FilterCheckboxWrapperStyle}
                label={item.value}
                name={item.id}
                element={{
                  type: "check-box-input",
                  initialChecked: selected,
                }}
                checked={selected}
                onChange={() => {
                  if (selected) {
                    onChange(selectedTags.filter(tag => tag !== item.id));
                  } else {
                    onChange([...selectedTags, item.id]);
                  }
                }}
              />
            );
          })}
        </DialogTagItemsWrapper>
      )}
    </div>
  );
};
