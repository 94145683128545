import * as React from "react";
import PageUpdater from "common/components/pageUpdater";
import { IRouteComponentProps } from "app/routes/client";
import { useStoreState } from "app/store";
import useOGData from "common/hooks/useOGData";

export function PositionShowHelmet(props: IRouteComponentProps) {
  const { positionId } = props.match.params;
  const position = useStoreState(
    state => state.entities.positions[positionId ?? ""],
  );

  const { seoTitle, seoDescription, metaObjects } = useOGData({
    fallbackTitle: position?.name,
    fallbackDescription: position?.description,
  });

  return position ? (
    <PageUpdater
      title={seoTitle}
      description={seoDescription}
      metaObjects={metaObjects}
      path={`/positions/${positionId}`}
    />
  ) : null;
}
