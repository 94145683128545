import GridItemElement from "./item";
import { GridContainer } from "./styled";
import DimmedOverlay from "common/components/thread/commonThreadItem/components/common/thumbnailGrid/dimmedOverlay";
import React from "react";

export const THUMBNAIL_GRID_IMAGE_LIMIT = 5;

const ThumbnailGrid: React.FC<
  Pick<Moim.Blockit.V2.ICarouselBlock, "images" | "style"> & {
    mediaBlocksCount: number;
  }
> = ({ images, style, mediaBlocksCount }) => {
  const { width, height } = style;

  const mediaElements = React.useMemo(
    () =>
      images.slice(0, THUMBNAIL_GRID_IMAGE_LIMIT).map((media, index) => {
        if (
          mediaBlocksCount > THUMBNAIL_GRID_IMAGE_LIMIT &&
          index === THUMBNAIL_GRID_IMAGE_LIMIT - 1
        ) {
          return (
            <DimmedOverlay
              text={`+ ${mediaBlocksCount - THUMBNAIL_GRID_IMAGE_LIMIT + 1}`}
            >
              <GridItemElement
                key={media.src}
                media={media}
                width={width}
                height={height}
              />
            </DimmedOverlay>
          );
        }
        return (
          <GridItemElement
            key={index}
            media={media}
            width={width}
            height={height}
            halfHeight={
              (mediaBlocksCount === 3 && index === 0) || mediaBlocksCount === 2
            }
          />
        );
      }),
    [height, images, mediaBlocksCount, width],
  );

  return (
    <GridContainer imageCount={mediaBlocksCount}>{mediaElements}</GridContainer>
  );
};

export default React.memo(ThumbnailGrid);
