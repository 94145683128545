import { Svg18Groupbuying } from "@moim/icons";
import styled, { css } from "styled-components";
import { px2rem } from "common/helpers/rem";
import { H10Bold } from "common/components/designSystem/typos";


export const TitleText = styled(H10Bold)`
  color: ${props => props.theme.colorV2.primary.main};
`;

export const IconContainer = styled.div`
  width: ${px2rem(18)};
  height: ${px2rem(18)};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${px2rem(2)};
`;

export const TeamBuyingIcon = styled(Svg18Groupbuying).attrs(props => ({
  size: "xs",
  iconColor: props.theme.colorV2.primary.main,
}))``;

export const Wrapper = styled.div<{ color?: string }>`
  width: 100%;
  padding: ${px2rem(4)} 0;
  padding-left: ${px2rem(2)};
  padding-right: ${px2rem(16)};
  display: flex;
  align-items: center;

  ${props =>
    props.color
      ? css`
          ${TitleText} {
            color: ${props.theme.getColorByAlias(props.color)};
          }
        `
      : undefined}
`;
