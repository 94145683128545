import * as React from "react";
import { useActions, useStoreState } from "app/store";
import { userCertificationSelector } from "app/selectors/cryptobadge";
import { getCertificatesByUserId } from "app/actions/cryptobadge";
import { BadgeContainer, BadgeBox, BadgeBoxAnchor } from "./styled";

interface IProps {
  userId: Moim.Id;
  canId?: Moim.Id;
  badges: Moim.User.INormalizedCertification[];
}

const Badges: React.FC<IProps> = ({ userId, canId, badges }) => {
  const { isLoading, cryptoBadges } = useStoreState(state => ({
    isLoading: Boolean(state.profilePage.isCryptoBadgeLoading[userId]),
    cryptoBadges: userCertificationSelector(state, { data: badges }),
  }));
  const { dispatchGetCertifications } = useActions({
    dispatchGetCertifications: getCertificatesByUserId,
  });

  React.useEffect(() => {
    if (canId && !isLoading) {
      dispatchGetCertifications(userId, { canId });
    }
  }, [userId, canId]);

  if (isLoading) {
    return null;
  }

  return (
    <BadgeContainer>
      {cryptoBadges.data
        .map(crt => crt.node)
        .filter(i => Boolean(i))
        .map(certificationNode => (
          <BadgeBoxAnchor
            key={certificationNode.id}
            href={certificationNode.resourceUrl ?? ""}
          >
            <BadgeBox
              src={certificationNode.imageUri ?? ""}
              alt={certificationNode.name ?? ""}
            />
          </BadgeBoxAnchor>
        ))}
    </BadgeContainer>
  );
};

export default Badges;
