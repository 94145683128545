import useEntitiesFetchSelector from "./useEntitiesFetchSelector";

export default function useGroupFetchSelector(requestIds: Moim.Id[]) {
  const { isLoading, entities } = useEntitiesFetchSelector({
    groups: requestIds,
  });

  return {
    isLoading,
    groups: entities.groups ?? [],
  };
}
