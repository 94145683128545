import * as React from "react";
import Positions from "./positions";
import Badges from "./badges";
import { Wrapper } from "./styled";

interface IProps {
  userId: Moim.Id;
  canId?: Moim.Id;
  positions: Moim.Position.INormalizePosition[];
  badges?: Moim.User.NormalizedCertificationList;
}

const PositionsAndBadges: React.FC<IProps> = ({
  userId,
  canId,
  positions,
  badges = {
    data: [],
  },
}) => {
  const limitPositions = React.useMemo(() => [...positions].slice(0, 3), [
    positions,
  ]);
  const limitBadges = React.useMemo(() => [...badges.data].slice(0, 3), [
    badges.data,
  ]);

  return (
    <Wrapper
      enableForceDoubleRow={positions.length > 2 || badges.data.length > 2}
    >
      <Positions positions={limitPositions} />
      <Badges userId={userId} canId={canId} badges={limitBadges} />
    </Wrapper>
  );
};

export default React.memo(PositionsAndBadges);
