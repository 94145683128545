import { Svg18Timersolid } from "@moim/icons";
import styled, { css } from "styled-components";
import { px2rem } from "common/helpers/rem";

import { H9Bold } from "common/components/designSystem/typos";
import { MEDIA_QUERY } from "common/constants/responsive";

import { ThemeColorScaleTypes } from "app/enums";
import { TopNaviBlockPropertyStyle } from "../../../../../topNavigation/desktop/components/elements/section/styled";

const BUTTON_WIDTH = 172 + 16;

function getTextColorScale(value?: Moim.Blockit.V2.ColorValue) {
  switch (value) {
    case ThemeColorScaleTypes.BLACK:
      return ThemeColorScaleTypes.WHITE;
    case ThemeColorScaleTypes.WHITE:
      return ThemeColorScaleTypes.BLACK;
    default:
      return value;
  }
}

export const MoimInfoWrapper = styled.div<{ visibleJoinButton: boolean }>`
  padding: 0 ${px2rem(16)};
  max-width: calc(
    100% - ${props => (props.visibleJoinButton ? px2rem(BUTTON_WIDTH) : 0)}
  );
  height: 100%;

  display: flex;
  align-items: center;
  @media ${MEDIA_QUERY.ONLY_MOBILE} {
    flex: 1;
    min-width: 0;
  }
`;

export const MoimName = styled(H9Bold)`
  color: var(
    --text-color,
    ${props => {
      const palette = props.theme.getTopSubAreaElementPalette(
        "childMoimNameText",
      );
      return palette.color ?? palette.fog800;
    }}
  );
  margin-left: ${px2rem(12)};
  flex: 1;
  min-width: 0;
`;

export const ButtonWrapper = styled.div`
  width: ${px2rem(BUTTON_WIDTH)};
`;

export const Wrapper = styled.div<{
  element?: Moim.Layout.Navigation.SubMoimProfileElementType;
}>`
  width: 100%;
  display: flex;
  align-items: center;

  & > ${MoimInfoWrapper} + ${ButtonWrapper} {
    margin-right: ${px2rem(16)};
  }

  ${TopNaviBlockPropertyStyle}

  ${props => css`
    --button-tint-text-color: ${props.theme.getBlockitColorValue(
      getTextColorScale(props.element?.buttonStyle?.textColor) ?? "",
      "white800",
    )};

    --button-tint-color-800: ${props.theme.getBlockitColorValue(
      props.element?.buttonStyle?.background ?? "",
      "grey800",
    )};

    --button-tint-color-600: ${props.theme.getColorByAlias(
      props.element?.buttonStyle?.background ?? "",
      "grey600",
    )};
  `};

  ${props =>
    props.element?.textColor
      ? `--text-color: ${props.theme.getBlockitColorValue(
          props.element.textColor,
          "grey800",
        )}`
      : null};
  ${props =>
    props.element?.iconColor
      ? `--icon-color: ${props.theme.getBlockitColorValue(
          props.element.iconColor,
          "grey800",
        )}`
      : null};
`;

export const PeriodIconWrapper = styled.div.attrs({ role: "button" })<{
  selected: boolean;
}>`
  display: inline-block;
  transition: background-color 200ms ease-in;
  border-radius: ${px2rem(2)};
  position: relative;
  ${props =>
    props.selected &&
    css`
      background-color: ${props.theme.colorV2.colorSet.fog50};
    `};
  &:hover {
    background-color: ${props => props.theme.colorV2.colorSet.fog50};
  }
`;

export const PeriodIcon = styled(Svg18Timersolid).attrs(props => {
  const palette = props.theme.getTopSubAreaElementPalette("menuText");
  const iconColor = palette.color ?? palette.fog800;

  return {
    size: "xs",
    touch: 42,
    role: "button",
    iconColor,
  };
})`
  svg > g {
    fill: var(--icon-color, ${props => props.iconColor});
  }
`;
