import { CancelToken } from "axios";
import { MoimCommunityAPI } from "../base/community";

export class CommunityAPI extends MoimCommunityAPI {
  public async getCommunity(
    params: {
      communityId: string;
    },
    cancelToken?: CancelToken,
  ): Promise<Moim.Community.ICommunity> {
    return (
      await this.get(`/communities/${params.communityId}`, undefined, {
        cancelToken,
      })
    ).data;
  }

  public async getNetworkBlockConfig(
    network: Moim.Id,
  ): Promise<Moim.Community.INetworkBlockConfig> {
    return (await this.get(`/communities/networks/${network}`)).data;
  }

  public async getUsersBatch(params: {
    ids?: string[];
    addresses?: string[];
  }): Promise<Moim.Community.ICommunityUser[] | undefined> {
    return (await this.post(`/communities/users/_batch`, params)).data;
  }

  public async validateRight(
    resourceId: string,
    action: string,
    payload?: {
      validateHolderOnly?: boolean;
      quantity?: number;
    },
  ): Promise<any> {
    return await this.post(`/rights/${resourceId}/${action}/validate`, payload);
  }

  public async getRight(params: {
    action: Moim.Community.RIGHT_ACTION_TYPE;
    resourceId: Moim.Id;
    cancelToken?: CancelToken;
  }): Promise<Moim.Community.IRight> {
    const { action, resourceId, cancelToken } = params;
    return (
      await this.get(`/rights/${resourceId}/${action}`, undefined, {
        cancelToken,
      })
    ).data;
  }
}
