/* eslint-disable no-underscore-dangle */
import produce from "immer";
import { AllActions } from "app/actions";
import { EntityTypes, ChannelTypes } from "../../actions/types";
import { channelListNormalizer, channelSingleItemNormalizer } from "app/models";
import { getInitialData } from "common/helpers/initialData";
import _ from "lodash";

export const INITIAL_STATE: (
  requestId?: string,
) => Moim.Channel.INormalizedData = (requestId?: string) => {
  const channelEntities = {};

  const homeChannel = getInitialData("__homeChannel", requestId ?? "");
  if (homeChannel) {
    const result = channelSingleItemNormalizer(homeChannel).entities.channels;

    Object.entries(result).forEach(([key, value]) => {
      channelEntities[key] = value;
    });
  }

  const channels = getInitialData("__channelData", requestId ?? "");
  if (channels && channels.data.length) {
    const result = channelListNormalizer(channels).entities.channels;

    Object.entries(result).forEach(([key, value]) => {
      channelEntities[key] = value;
    });
  }

  const channel = getInitialData("__entities.channel", requestId ?? "");
  if (channel) {
    const result = channelSingleItemNormalizer(channel).entities.channels;

    Object.entries(result).forEach(([key, value]) => {
      channelEntities[key] = value;
    });
  }

  return channelEntities;
};

export function reducer(
  state: Moim.Channel.INormalizedData = INITIAL_STATE(),
  action: AllActions,
) {
  return produce(state, draft => {
    switch (action.type) {
      case EntityTypes.ADD_ENTITY: {
        Object.entries(action.payload.channels || {}).forEach(
          ([key, value]) => {
            if (
              action.payload.forceUpdate ||
              !value.hasOwnProperty("updated_at") ||
              value.updated_at >= (draft[key]?.updated_at ?? 0)
            ) {
              draft[key] = value;
            }
          },
        );

        break;
      }

      case ChannelTypes.SUCCEEDED_DELETE_CHANNEL: {
        const { channel } = action.payload;

        if (!channel) {
          break;
        }

        if (channel.type === "category") {
          delete draft[channel.id];
          break;
        }

        const parent = channel.parent ? draft[channel.parent] : undefined;

        delete draft[channel.id];
        if (parent && parent.type === "category") {
          draft[parent.id] = {
            ...parent,
            items: parent.items?.filter(target => target !== channel.id),
          };
        }

        break;
      }

      case ChannelTypes.SUCCEEDED_CREATE_CHANNEL: {
        const { channel } = action.payload;

        if (channel.type === "category") {
          draft[channel.id] = {
            ...channel,
            items: channel.items?.map(item => item.id),
          };
          break;
        }
        draft[channel.id] = channel;

        const parent = draft[channel.parent || ""];
        if (parent && parent.type === "category") {
          draft[parent.id] = {
            ...parent,
            items: [...(parent.items || []), channel.id],
          };
        }

        break;
      }

      case ChannelTypes.SUCCEEDED_EDIT_CHANNEL: {
        const { channel } = action.payload;

        if (channel.type === "category") {
          draft[channel.id] = {
            ...channel,
            items: (draft[channel.id] as Moim.Channel.INormalizedCategory)
              .items,
          };
          break;
        }
        draft[channel.id] = channel;

        const categoryChanged = !(draft[channel.parent || ""] as
          | Moim.Channel.INormalizedCategory
          | undefined)?.items?.find(channelId => channelId === channel.id);

        if (categoryChanged) {
          Object.values(draft).forEach(entity => {
            if (entity.type === "category") {
              const items =
                entity.items?.filter(item => item !== channel.id) || [];

              if (channel.parent === entity.id) {
                items.push(channel.id);
              }

              draft[entity.id] = {
                ...entity,
                items,
              };
            }
          });
        }
        break;
      }
    }
  });
}
