import styled, { css } from "styled-components";
import {
  blockAlign2FlexAlign,
  blockVerticalAlign2FlexAlign,
} from "../../helper/blockitStyleHelpers";
import { px2rem } from "common/helpers/rem";

export const Wrapper = styled.div<{
  column: number;
  row?: number;
  columnGap?: number;
  rowGap?: number;
}>`
  width: 100%;
  height: 100%;
  display: grid;

  grid-template-columns: ${props => `repeat(${props.column}, minmax(0, 1fr))`};
  grid-template-rows: ${props => `repeat(${props.row ?? 1}, minmax(0, 1fr))`};

  ${props =>
    props.columnGap &&
    css`
      grid-column-gap: ${px2rem(props.columnGap)};
    `};

  ${props =>
    props.rowGap &&
    css`
      grid-row-gap: ${px2rem(props.rowGap)};
    `};
`;

export const GridItemWrapper = styled.div<{
  verticalAlign?: Moim.Blockit.V2.IBlockVerticalAlignment;
  horizontalAlign?: Moim.Blockit.V2.IBlockAlignment;
  startX?: number;
  endX?: number;
  startY?: number;
  endY?: number;
}>`
  display: flex;
  flex-direction: column;
  justify-content: ${props =>
    blockVerticalAlign2FlexAlign(props.verticalAlign)};
  align-items: ${props => blockAlign2FlexAlign(props.horizontalAlign)};
  grid-column-start: ${props => props.startX};
  grid-column-end: ${props => props.endX};
  grid-row-start: ${props => props.startY};
  grid-row-end: ${props => props.endY};
`;
