import { Svg18TrashG, Svg24DeleteG, Svg48Play } from "@moim/icons";
import React from "react";
import styled from "styled-components";
import { rgba } from "polished";




import { ALL_VIDEO_MIME_REGEX } from "common/constants/mimeTypes";
import { SkeletonBox } from "common/components/skeleton";
import useFileURL from "common/hooks/useFileURL";
import { useStoreState } from "app/store";
import { px2rem } from "common/helpers/rem";
import { MEDIA_QUERY } from "common/constants/responsive";

const SmallRemoveButton = styled(Svg24DeleteG).attrs({
  role: "button",
  size: "s",
})``;
const TrashIcon = styled(Svg18TrashG).attrs(props => ({
  size: "xs",
  iconColor: props.theme.colorV2.colorSet.white1000,
}))``;

const MobileDeleteButton = styled.div`
  position: absolute;
  top: ${px2rem(2)};
  right: ${px2rem(2)};
  width: ${px2rem(24)};
  height: ${px2rem(24)};
  background-color: ${props => rgba(props.theme.colorV2.colorSet.grey800, 0.9)};
  border-radius: 100%;
  z-index: ${props => props.theme.zIndexes.default};

  @media ${MEDIA_QUERY.EXCEPT_MOBILE} {
    display: none;
  }
`;

const DesktopDeleteButton = styled.div.attrs({ role: "button" })`
  background-color: ${props => props.theme.colorV2.colorSet.grey600};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: ${px2rem(4)};

  align-items: center;
  justify-content: center;
  display: none;
`;

const PlayIcon = styled(Svg48Play).attrs({
  size: "l",
  touch: 48,
})``;

const PlayIconWrapper = styled.div`
  width: ${px2rem(48)};
  height: ${px2rem(48)};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const Box = styled.div`
  width: ${px2rem(110)};
  height: ${px2rem(110)};
  border-radius: ${px2rem(4)};
  position: relative;
  min-width: ${px2rem(110)};
`;

const ItemWrapper = styled(Box)`
  @media ${MEDIA_QUERY.EXCEPT_MOBILE} {
    &:hover {
      ${DesktopDeleteButton} {
        display: flex;
      }

      ${PlayIconWrapper} {
        display: none;
      }
    }
  }
`;

const Preview = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: ${px2rem(4)};
`;

export const Item: React.FC<{
  file: File;
  fileId: string;
  onClickDelete(fileId): void;
}> = ({ file, fileId, onClickDelete }) => {
  const { imageSrc, setFile } = useFileURL();
  const fileEntity = useStoreState(
    state => state.entities.files[fileId] as Moim.Upload.IFileInfo,
  );

  const handleClickDelete = React.useCallback(() => {
    onClickDelete(fileId);
  }, [fileId]);
  React.useEffect(() => {
    setFile(file);
  }, [file]);

  if (!fileEntity?.image_preview || !fileEntity?.image_urls) {
    return (
      <Box>
        <SkeletonBox width="100%" height="100%" />
      </Box>
    );
  }
  const isVideo = fileEntity?.mimetype?.match(ALL_VIDEO_MIME_REGEX);

  return (
    <ItemWrapper>
      <Preview src={!isVideo ? imageSrc : fileEntity.image_preview.url} />
      <MobileDeleteButton onClick={handleClickDelete}>
        <SmallRemoveButton />
      </MobileDeleteButton>
      <DesktopDeleteButton onClick={handleClickDelete}>
        <TrashIcon />
      </DesktopDeleteButton>
      {isVideo && (
        <PlayIconWrapper>
          <PlayIcon />
        </PlayIconWrapper>
      )}
    </ItemWrapper>
  );
};
