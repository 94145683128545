import useHubSeller from "./useHubSeller";

export function useEnableGuestCheckout() {
  const seller = useHubSeller();
  return seller?.config.guestCheckoutEnabled;
}

export function useEnableGuestCart() {
  const seller = useHubSeller();
  return seller?.config.guestCartEnabled;
}
