/* eslint-disable no-underscore-dangle */
import * as React from "react";
import * as ReactDOMClient from "react-dom/client";
import { rgba } from "polished";
import {
  createBrowserHistory,
  createMemoryHistory,
  History,
  MemoryHistory,
} from "history";
import { Provider, useDispatch } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { ThemeProvider as StyledThemeProvider } from "styled-components";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/styles";
import { createTheme } from "@material-ui/core/styles";
// components
import {
  NativeSecondaryViewHistory,
  PluginSecondaryViewHistory,
} from "../modules/SecondaryHistory";
import GlobalStyle from "common/globalStyle";
// actions
import * as EnvChecker from "common/helpers/envChecker";
import IntlWrapper from "../intl/intlWrapper";
import RootComponent from "../routes/root";
import makeTheme from "../theme";
import { IAppStore } from "../store";
import { bootData, bootStore } from "../boot";
import { DefaultLoader } from "common/components/loading";
import { ActionCreators as SnackbarActionCreators } from "app/actions/snackbar";
import { checkServerMaintenance } from "../actions/feedback";
// providers
import MoimThemeProvider from "../theme/MoimThemeProvider";
import AlertProvider from "common/components/alertTemplates/provider";
import TopBannerContextProvider from "common/components/topBanner/context";
import ModalShowContextProvider from "common/layouts/context";
import {
  IsMobileContext,
  IsMobileProvider,
} from "./providers/isMobileProvider";
import NextActionProvider from "common/helpers/nextActionProvider";
// helpers
import SessionHandler from "common/helpers/sessionHandler";
import { browserLocale, SupportedLanguageType } from "../intl";
// constants
import { currentGroupSelector } from "../selectors/app";
import { AnalyticsClass } from "common/helpers/analytics/analytics";

import ScrollRestoreProvider from "common/helpers/scrollRestoreProvider";
import {
  generateDefaultElementThemeColorSet,
  generateDefaultPalette,
} from "../theme/constants/palette";
import { MoimURL } from "common/helpers/url";
import CommerceCheckoutComplete from "../modules/commerce/complete";
import { ThemeMode } from "app/enums";
import { DEFAULT_SYSTEM_COLOR } from "app/theme/color";
import { bootstrap } from "app/actions/group";
import { getInitialData } from "common/helpers/initialData";
import { initializeHistory } from "app/actions/app";
import { entityCacheController } from "common/helpers/entityCacheController";
import { BlockitRendererContextProvider } from "common/components/blockitEditorBase/components/blockitRenderer.v2/context";
import MoimThemeContext from "app/theme/context";

function isCheckoutComplete() {
  return MoimURL.CommerceCheckoutComplete.isSame(location.pathname);
}

function HistoryListener(props: React.PropsWithChildren<{ history: any }>) {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(initializeHistory(props.history));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>{props.children}</>;
}

export default class ClientRenderer {
  private readonly history: History = createBrowserHistory();
  private readonly nativeSecondaryHistory: MemoryHistory = createMemoryHistory();
  private readonly pluginSecondaryHistory: MemoryHistory = createMemoryHistory();
  private appStore!: IAppStore;
  private groupId: string | null = null; // NOTE: ziggleziggle : 기능 개발후 groupId 제거 필요

  public buildGroupFont() {
    const state = this.appStore.getState();
    const groupId = this.groupId ?? "";

    const group = state.entities.groups[groupId];
    const locale = browserLocale(
      state.app.locale,
      group?.config?.defaultLocale as SupportedLanguageType,
    );

    const fontDefinitions = group?.config?.fonts?.fontDefinitions;
    const fontFamilies = group?.config?.fonts?.fontFamilies;

    if (!fontDefinitions || !locale) {
      return {
        moimFontOptions: undefined,
        moimFontFamilies: undefined,
      };
    }

    const moimFontFamilies = fontFamilies
      ? Object.keys(fontFamilies)
          .sort((a, b) => {
            if (a === locale) {
              return -1;
            }
            if (b === locale) {
              return 1;
            }
            return 0;
          })
          .map(key => fontFamilies?.[key]?.name)
          .join(", ")
      : undefined;

    const moimFontOptions = fontDefinitions?.find(
      font => font.name === fontFamilies?.[locale]?.name,
    )?.fontOptions;

    return {
      moimFontFamilies,
      moimFontOptions,
    };
  }

  public async render() {
    try {
      if (!(window as any).__MOIM_SSR_CONTENT__) {
        this.renderLoading();
      }

      this.appStore = await bootStore(this.history);
      this.groupId = this.appStore.getState().app.currentGroupId;
      await this.appStore.dispatch(bootstrap());
      bootData(this.appStore);
      this.appStore.dispatch(checkServerMaintenance());

      this.initializeAnalytics();
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error("[!] FAILED CLIENT RENDER INITIALIZE", err);
      if (!(err as any).response || (err as any).response.status !== 403) {
        // eslint-disable-next-line no-console
        console.error("Failed signing in: ", err);
      }
    } finally {
      if (isCheckoutComplete()) {
        const root = ReactDOMClient.createRoot(this.getAppElement()!);
        root.render(<CommerceCheckoutComplete />);
      } else {
        const muiTheme = createTheme();
        const { moimFontFamilies, moimFontOptions } = this.buildGroupFont();

        const AppJSX = () => {
          React.useEffect(() => {
            this.renderAfter();
          }, []);
          return (
            <React.Fragment>
              <GlobalStyle
                groupId={this.groupId}
                fontFamilies={moimFontFamilies}
                letterSpacing={moimFontOptions?.letterSpacing}
              />
              <Provider store={this.appStore}>
                <ConnectedRouter history={this.history}>
                  <HistoryListener history={this.history}>
                    <IsMobileProvider>
                      <IntlWrapper>
                        <ScrollRestoreProvider>
                          <NativeSecondaryViewHistory
                            history={this.nativeSecondaryHistory}
                          >
                            <PluginSecondaryViewHistory
                              history={this.pluginSecondaryHistory}
                            >
                              <MoimThemeProvider>
                                <MoimThemeContext.Consumer>
                                  {theme => (
                                    <BlockitRendererContextProvider
                                      estimatedWidth={window?.innerWidth ?? 780}
                                      backgroundColor={
                                        theme?.theme.colorV2.colorSet
                                          .white1000 ?? "#ffffff"
                                      }
                                    >
                                      <MuiThemeProvider theme={muiTheme}>
                                        <AlertProvider>
                                          <ModalShowContextProvider>
                                            <TopBannerContextProvider>
                                              <NextActionProvider>
                                                <IsMobileContext.Consumer>
                                                  {({ isMobile }) => (
                                                    <RootComponent
                                                      isMobile={isMobile}
                                                    />
                                                  )}
                                                </IsMobileContext.Consumer>
                                              </NextActionProvider>
                                            </TopBannerContextProvider>
                                          </ModalShowContextProvider>
                                        </AlertProvider>
                                      </MuiThemeProvider>
                                    </BlockitRendererContextProvider>
                                  )}
                                </MoimThemeContext.Consumer>
                              </MoimThemeProvider>
                            </PluginSecondaryViewHistory>
                          </NativeSecondaryViewHistory>
                        </ScrollRestoreProvider>
                      </IntlWrapper>
                    </IsMobileProvider>
                  </HistoryListener>
                </ConnectedRouter>
              </Provider>
            </React.Fragment>
          );
        };

        const root = ReactDOMClient.createRoot(this.getAppElement()!);
        root.render(<AppJSX />);
      }
    }
  }

  private readonly renderLoading = () => {
    const root = ReactDOMClient.createRoot(this.getAppElement()!);
    root.render(
      <StyledThemeProvider
        key="initial-loading-theme-provider"
        theme={makeTheme({
          locale: browserLocale(),
          palette: generateDefaultPalette(),
          darkPalette: generateDefaultPalette(ThemeMode.DARK),
          themeMode:
            getInitialData("__bootData.data.group.config.theme", "") ??
            ThemeMode.LIGHT,
          setThemeMode: () => {},
          elementThemePalette: generateDefaultElementThemeColorSet(),
          systemColor: DEFAULT_SYSTEM_COLOR,
          darkSystemColor: DEFAULT_SYSTEM_COLOR,
        })}
      >
        <GlobalStyle groupId={this.groupId} />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
            position: "fixed",
            left: 0,
            top: 0,
          }}
        >
          {/* GREY650 */}
          <DefaultLoader size="s" color={rgba(174, 184, 189, 0.5)} />
        </div>
      </StyledThemeProvider>,
    );
  };

  private readonly getAppElement = () => document.getElementById("vingle-app");

  private readonly renderAfter = () => {
    const remove1 = document.querySelector("#__LOADABLE_REQUIRED_CHUNKS__");
    const remove2 = document.querySelector("#__LOADABLE_REQUIRED_CHUNKS___ext");
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles) {
      jssStyles.remove();
    }

    if (remove1) {
      remove1.remove();
    }
    if (remove2) {
      remove2.remove();
    }

    const initialMessage = SessionHandler.get("INITIAL_TOAST_MESSAGE");

    if (initialMessage) {
      this.appStore.dispatch(
        SnackbarActionCreators.openSnackbar({
          text: initialMessage,
        }),
      );
    }

    SessionHandler.remove("INITIAL_TOAST_MESSAGE");
  };

  private readonly initializeAnalytics = () => {
    const currentGroup = currentGroupSelector(this.appStore.getState());

    if (currentGroup && currentGroup.analytics) {
      const analyticsIds = currentGroup.analytics;
      const currentUserId = this.appStore.getState().app.currentUserId;
      const analytics = AnalyticsClass.getInstance();
      analytics.init(
        analyticsIds,
        { id: currentGroup.id, name: currentGroup.name },
        currentUserId ?? undefined,
      );
    }
  };
}

function registerServiceWorker() {
  if ("serviceWorker" in navigator) {
    window.addEventListener("load", () => {
      try {
        const assetPath =
          EnvChecker.isProd() || EnvChecker.isStage()
            ? `./app/${process.env.DEPLOY_VERSION}`
            : "";
        navigator.serviceWorker.register(`${assetPath}/serviceWorker.js`);
      } catch (err) {
        console.error("!!!! failed to register SW", err);
      }
    });
  }
}

(() => {
  if (EnvChecker.isBrowser() && !EnvChecker.isTest()) {
    entityCacheController.checkAndClearCache();
    // Rendering part
    const renderer = new ClientRenderer();
    renderer.render();
    registerServiceWorker();
  }
})();
