import React from "react";
import styled from "styled-components";
import { px2rem } from "common/helpers/rem";

import { useStoreState } from "app/store";

import { H8Bold } from "common/components/designSystem/typos";
import { Spacer } from "common/components/designSystem/spacer";
import ProductGroupList from "./productGroupList";
import { useSellerDisplayConfig } from "common/hooks/useSellerConfig";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 ${px2rem(16)};
`;
const SellerName = styled(H8Bold)`
  color: ${props => props.theme.colorV2.colorSet.grey800};
  padding: ${px2rem(6)} 0;
`;
interface IProps {
  purchase: Moim.Commerce.IPurchase;
}

const PurchaseSellerItem: React.FC<IProps> = ({ purchase }) => {
  const seller = useStoreState(
    state => state.entities.commerce_seller[purchase.sellerId],
  );
  const displaySeller = useSellerDisplayConfig("seller");

  return (
    <Wrapper>
      {displaySeller && <SellerName>{seller?.name}</SellerName>}
      <Spacer value={8} />
      <ProductGroupList purchase={purchase} />
    </Wrapper>
  );
};

export default PurchaseSellerItem;
