import * as React from "react";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import {
  appBarStyle,
  BottomSheetHeaderWrapper,
  ListHeaderWrapper,
  NextButton,
  ScrollSection,
  selectableItemStyle,
  StickyWrapper,
  Wrapper,
} from "./styled";
import { useHandlers } from "./hooks";
import SelectableItemList from "common/components/selectionBottomSheet/selectableItemList";
import { IOption } from "common/components/designSystem/selection/type";
import { ISelectedData } from "common/components/searchInput/selectable/type";
import BottomSheetSearchInput from "common/components/selectionBottomSheet/bottomSheetSearchInput";
import BottomSheetHeader from "common/components/selectionBottomSheet/bottomSheetHeader";
import { BottomSheetBackdrop } from "common/components/dialogs/responsiveDialog/backdrop";

export interface IProps {
  open: boolean;
  title: React.ReactNode;
  listHeader?: React.ReactNode;
  listHeaderRight?: React.ReactNode;
  options: IOption[];
  selected: ISelectedData[];
  setSelected: (selected: ISelectedData[]) => void;
  onClose: () => void;
  isMultipleSelect: boolean;
  nextButtonText: string;
  onNext: (selectedIds: Moim.Id[]) => void;
  enableSearch?: boolean;
  onSearch?: (searchValue: string) => void;
  onSelect?: (selectedId: Moim.Id) => void;
  beforeClose?: (selectedIds: Moim.Id[]) => void;
}

export default function SelectionBottomSheet(props: IProps) {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const scrollSectionRef = React.useRef<HTMLInputElement>(null);

  const {
    open,
    title,
    listHeader,
    listHeaderRight,
    options,
    selected,
    nextButtonText,
    enableSearch,
    isMultipleSelect,
  } = props;

  const {
    handleClickNextButton,
    handleSearch,
    handleClose,
    handleClickOption,
    isSelected,
    handleResetData,
  } = useHandlers({ ...props, inputRef, scrollSectionRef });

  const [initialMinHeight, setInitialMinHeight] = React.useState(0);

  const handleSnapPoints = React.useCallback(
    ({ minHeight, maxHeight }) => {
      if (minHeight > initialMinHeight) {
        setInitialMinHeight(minHeight);
      }
      return [
        initialMinHeight,
        Math.min(initialMinHeight, maxHeight * 0.6),
        maxHeight,
      ];
    },
    [initialMinHeight],
  );
  const handleDefaultSnap = React.useCallback(
    ({ maxHeight }) => Math.min(initialMinHeight, maxHeight * 0.6),
    [initialMinHeight],
  );

  React.useEffect(() => {
    if (!open) {
      handleResetData();
    }
  }, [handleResetData, open]);

  return (
    <>
      {open && <BottomSheetBackdrop onCloseRequest={handleClose} />}
      <BottomSheet
        className={"custom-rsbs vanilla-header has-handler"}
        open={open}
        onDismiss={handleClose}
        header={
          <BottomSheetHeaderWrapper>
            <BottomSheetHeader
              title={title}
              actionButton={
                <NextButton
                  onClick={handleClickNextButton}
                  isActive={Boolean(selected.length)}
                  isLoading={false}
                >
                  {nextButtonText}
                </NextButton>
              }
              headerWrapperStyle={appBarStyle}
            />
            {enableSearch && (
              <BottomSheetSearchInput
                ref={inputRef}
                type="text"
                onChange={handleSearch}
                isBottomSheetOpen={open}
              />
            )}
          </BottomSheetHeaderWrapper>
        }
        snapPoints={handleSnapPoints}
        defaultSnap={handleDefaultSnap}
        blocking={false}
        initialFocusRef={false}
      >
        <Wrapper>
          <StickyWrapper>
            <ListHeaderWrapper>
              <ScrollSection ref={scrollSectionRef}>{listHeader}</ScrollSection>
              {listHeaderRight}
            </ListHeaderWrapper>
          </StickyWrapper>
          <SelectableItemList
            options={options}
            isMultipleSelect={isMultipleSelect}
            isSelected={isSelected}
            onSelected={handleClickOption}
            selectableItemStyle={selectableItemStyle}
          />
        </Wrapper>
      </BottomSheet>
    </>
  );
}
