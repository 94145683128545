import * as React from "react";
import { FormattedMessage } from "react-intl";
import { DefaultLoader as Loader } from "common/components/loading";
import { ILoaderComponentProps } from "common/components/infiniteScroller/new";
import { LoadMoreWrapper, LoadingWrapper, LoadMoreText } from "./styled";

export const ClickableLoader: React.FC<ILoaderComponentProps> = ({
  direction,
  isLoading,
  loadMore,
}) => {
  const handleClick = React.useCallback(() => {
    if (!isLoading) {
      loadMore(direction);
    }
  }, [isLoading, loadMore, direction]);

  return (
    <LoadMoreWrapper onClick={handleClick}>
      {isLoading ? (
        <LoadingWrapper>
          <Loader />
        </LoadingWrapper>
      ) : (
        <LoadMoreText>
          <FormattedMessage
            id={
              direction === "before"
                ? "post_show_comments_button_replies_more_before"
                : "post_show_comments_button_replies_more"
            }
          />
        </LoadMoreText>
      )}
    </LoadMoreWrapper>
  );
};
