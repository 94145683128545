import { MoimURL } from "common/helpers/url";
import { AppTypes } from "app/actions/types";
import { isMobileAgent } from "common/helpers/browserDetect";

export default function routeModalInterceptMiddleware(_: any) {
  return next => action => {
    if (
      action.type === AppTypes.LOCATION_CHANGE &&
      action.payload.isFirstRendering &&
      isMobileAgent() &&
      (MoimURL.FocusedShowForumThread.isSame(
        action.payload.location.pathname,
      ) ||
        MoimURL.FocusedShowSubReply.isSame(action.payload.location.pathname))
    ) {
      action.payload.location.state = {
        ...action.payload.location.state,
        modal: true,
      };
    }

    next(action);
  };
}
