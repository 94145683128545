import { Svg24MoreG } from "@moim/icons";
import { useOpacityHoverStyle } from "common/components/designSystem/styles";
import { B1Bold, B4Regular } from "common/components/designSystem/typos";
import WithPositionChip from "common/components/withPositionChip";
import { px2rem } from "common/helpers/rem";
import styled from "styled-components";

export const UserInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${px2rem(4)};
  padding: ${px2rem(10)};
  width: 100%;
`;

export const Left = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${px2rem(8)};
  width: ${px2rem(48)};
  height: ${px2rem(48)};
`;

export const Center = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${px2rem(2)};
  flex: 1;
`;

export const Right = styled.div`
  display: flex;
  align-items: center;
  gap: ${px2rem(8)};
  width: ${px2rem(48)};
  height: ${px2rem(48)};
`;

export const UserName = styled(B1Bold)`
  color: ${(props) => props.theme.colorV2.colorSet.grey1000};
`;

export const Date = styled(B4Regular)`
  display: flex;
  max-width: 100%;
  color: ${(props) => props.theme.colorV2.colorSet.grey300};
`;

export const EngageProfileWithPositionChip = styled(WithPositionChip)`
  width: 100%;
  > * {
    flex: 1;
  }
`;

export const MoreIcon = styled(Svg24MoreG).attrs((props) => ({
  size: "s",
  role: "button",
  touch: 45,
  iconColor: props.theme.colorV2.colorSet.grey300,
}))``;

export const IconWrapper = styled.div`
  transition: opacity 200ms ease-in;

  &:hover {
    ${useOpacityHoverStyle}
  }
`;
