import * as React from "react";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import { BottomSheetBackdrop } from "./backdrop";
import { Dialog } from "./styled";
import useIsMobile from "common/hooks/useIsMobile";

export { Dialog, FixedWidthDialog } from "./styled";

type IProps = React.PropsWithChildren<{
  open: boolean;
  dialogBase?: React.ComponentType<any>;
  maxHeight?: number;
  onCloseRequest(): void;
}>;

function ResponsiveDialog({
  open,
  onCloseRequest,
  children,
  dialogBase: DialogType = Dialog,
  maxHeight,
}: IProps) {
  const isMobile = useIsMobile();

  return isMobile ? (
    <>
      {open && <BottomSheetBackdrop onCloseRequest={onCloseRequest} />}
      <BottomSheet
        open={open}
        onDismiss={onCloseRequest}
        blocking={false}
        maxHeight={maxHeight}
      >
        {open && children}
      </BottomSheet>
    </>
  ) : (
    <DialogType open={open} onClose={onCloseRequest}>
      {children}
    </DialogType>
  );
}

export default ResponsiveDialog;
