import * as React from "react";
// hooks
import { useActions, useStoreState } from "app/store";
import useIsMobile from "common/hooks/useIsMobile";
import { useCancelTokenWithCancelHandler } from "common/hooks/useCancelToken";
// actions
import { bufferedGetChildMoimGroupMoims as getChildMoimGroupMoimsAction } from "app/actions/childMoimGroup";
// helpers
import { selectMoimsById } from "app/selectors/moim";
// component
import BlockitListLayout from "common/components/blockitListLayout";
import InfiniteScroller from "common/components/infiniteScroller/new";
import { MoimCellSkeleton } from "./components/moimCell/skeleton";
import MoimItem from "./components/moimCell";
import InViewTrigger from "../../components/inViewTrigger";
import {
  ItemContainer,
  LoadWrapper,
  Loading,
  InfiniteScrollerWrapper,
} from "./styled";
import { withPlacement } from "../../hoc/withPlacement";
import { useParseListElementConfig } from "common/components/blockitListLayout/hooks/useParseListElementConfig";

const DEFAULT_LIMIT = 20;

const ChildMoimGroup: React.FC<Moim.Blockit.V2.IChildMoimGroupListBlock> = ({
  listElement,
  resourceId,
}) => {
  const { cancelTokenSource, handleCancel } = useCancelTokenWithCancelHandler();
  const [isLoading, setLoadStatus] = React.useState<boolean>();

  const isMobile = useIsMobile();
  const { getChildMoimGroupMoims } = useActions({
    getChildMoimGroupMoims: getChildMoimGroupMoimsAction,
  });
  const moims = useStoreState(state =>
    selectMoimsById(
      state,
      state.childMoimGroup.groupByMoims[resourceId]?.data ?? [],
    ),
  );
  const { paging } = useStoreState(state => ({
    paging: state.childMoimGroup.groupByMoims[resourceId]?.paging ?? {},
  }));

  const { column } = useParseListElementConfig(listElement);

  const handleLoadMore = React.useCallback(() => {
    if (!isLoading && paging.after) {
      setLoadStatus(true);
      getChildMoimGroupMoims(
        resourceId,
        DEFAULT_LIMIT,
        cancelTokenSource.current.token,
        {
          after: paging.after,
        },
      ).finally(() => {
        setLoadStatus(false);
      });
    }
  }, [
    cancelTokenSource,
    getChildMoimGroupMoims,
    isLoading,
    paging.after,
    resourceId,
  ]);

  const itemElements = React.useMemo(() => {
    const isUndefinedArray = moims?.some(thread => thread?.id === undefined);
    if (isLoading !== false || isUndefinedArray) {
      return new Array(column * 2).fill(0).map((_, idx) => (
        <ItemContainer key={`child_moim_group_skeleton_${idx}`}>
          <MoimCellSkeleton />
        </ItemContainer>
      ));
    }

    return moims.map(moim => (
      <ItemContainer key={`child_moim_group_${moim.id}`}>
        <MoimItem moim={moim} />
      </ItemContainer>
    ));
  }, [moims, isLoading, column, isMobile]);

  const handleOnView = React.useCallback(() => {
    if (isLoading === undefined) {
      setLoadStatus(true);
      getChildMoimGroupMoims(
        resourceId,
        DEFAULT_LIMIT,
        cancelTokenSource.current.token,
      ).finally(() => {
        setLoadStatus(false);
      });
    }
  }, [cancelTokenSource, getChildMoimGroupMoims, isLoading, resourceId]);

  React.useLayoutEffect(
    () => () => {
      handleCancel();
    },
    [handleCancel],
  );

  return (
    <>
      <InViewTrigger onVisible={handleOnView} />

      <InfiniteScroller
        itemLength={moims.length}
        threshold={700}
        paging={paging}
        element={InfiniteScrollerWrapper}
        loader={
          <LoadWrapper>
            <Loading />
          </LoadWrapper>
        }
        loadMore={handleLoadMore}
      >
        <BlockitListLayout element={listElement}>
          {itemElements}
        </BlockitListLayout>
      </InfiniteScroller>
    </>
  );
};

export default withPlacement(React.memo(ChildMoimGroup));
