import { ThreadItem } from "../commonThreadItem";
import { UserStatusTypes } from "app/enums";
import ForumShowMenu from "app/modules/postShow/components/threadShow/components/menu";
import { useStoreState } from "app/store";
import { StopPropagationWrapper } from "common/components/stopPropagationWrapper";
import { useEngagementHandler } from "common/components/thread/templates/common/hooks/useEngagementHandler";
import { useOpenProfileDialog } from "common/hooks/profileDialog";
import usePositionFetchSelector from "common/hooks/useEntitiesFetchSelectors/usePositionFetchSelector";
import useOpenState from "common/hooks/useOpenState";
import React from "react";

export const MediaTextMixedFeed: React.FC<{
  thread: Moim.Forum.IThread;
  config: Partial<Moim.Thread.IThreadItemConfig>;

  className?: string;
  stat?: Moim.Thread.IThreadItemStatProps;
  disableAnonymousSuffix?: boolean;
}> = ({ thread, config, className, disableAnonymousSuffix, stat }) => {
  const {
    handleReaction,
    handleCommentClick,
    handleShareClick,
    handleBookmarkClick,
    handleDeleteThread,
  } = useEngagementHandler(thread);

  const author = useStoreState((state) => state.entities.users[thread.author]);
  const { positions } = usePositionFetchSelector(author?.positions ?? []);
  const refMenuButton = React.useRef(null);
  const {
    isOpen: isMenuOpen,
    open: openMenu,
    close: closeMenu,
  } = useOpenState();

  const openProfileDialog = useOpenProfileDialog();
  const handleUserInfoClick: React.MouseEventHandler<any> = React.useCallback(
    (e) => {
      if (author && author.status !== UserStatusTypes.DEACTIVATED) {
        openProfileDialog(author.id, { current: e.currentTarget });
      }
    },
    [openProfileDialog, author],
  );

  return (
    <ThreadItem.MediaTextMixedFeedItem
      thread={thread}
      config={config}
      className={className}
      stat={stat}
    >
      <ThreadItem.MediaTextMixedFeedItem.UserInfo
        disableAnonymousSuffix={disableAnonymousSuffix}
        author={author}
        positions={positions}
        onUserInfoClick={handleUserInfoClick}
        refMenuButton={refMenuButton}
        onMenuClick={openMenu}
      />
      <StopPropagationWrapper>
        <ForumShowMenu
          refMenuButton={refMenuButton}
          open={isMenuOpen}
          rootId={thread.parent_id}
          threadId={thread.id}
          authorId={thread.author}
          onClose={closeMenu}
          onDeleteThread={handleDeleteThread}
        />
      </StopPropagationWrapper>
      <ThreadItem.MediaTextMixedFeedItem.Contents />
      <ThreadItem.MediaTextMixedFeedItem.Media
        mediaBlocks={thread.mediaBlocks}
        mediaBlocksCount={thread.mediaBlocksCount}
        preview={thread.preview}
        mediaDisplayType={config.mediaDisplayType}
        thumbnailConfig={config.thumbnailConfig}
        showContent={thread.showContent}
      />
      <ThreadItem.MediaTextMixedFeedItem.Engage
        reactionHandler={handleReaction}
        onCommentClick={handleCommentClick}
        onShareClick={handleShareClick}
        onBookmarkClick={handleBookmarkClick}
      />
    </ThreadItem.MediaTextMixedFeedItem>
  );
};
