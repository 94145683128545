import { getColorByAlias } from "app/theme";
import {
  bgLevel1Style,
  bgLevel2Style,
  bgLevel3Style,
  bgLevel4Style,
} from "common/components/designSystem/BGLevel";
import { px2rem } from "common/helpers/rem";
import styled, { css } from "styled-components";

export function getHorizontalAlignStyle(
  horizontalAlign: "start" | "center" | "end" | "space-around",
) {
  switch (horizontalAlign) {
    case "start":
      return css`
        text-align: left;
      `;

    case "center":
      return css`
        text-align: center;
      `;
    case "end":
      return css`
        text-align: right;
      `;
    default:
      return undefined;
  }
}

export function getFlexAlignStyle({
  direction,

  verticalAlign,
  horizontalAlign,
}: {
  direction: "column" | "row";
  verticalAlign?: "start" | "center" | "end" | "space-around";
  horizontalAlign?: "start" | "center" | "end" | "space-around";
}) {
  const alignItems = direction === "row" ? verticalAlign : horizontalAlign;
  const justifyContent = direction === "row" ? horizontalAlign : verticalAlign;

  return css`
    ${alignItems &&
      css`
        align-items: ${alignItems === "start" || alignItems === "end"
          ? `flex-${alignItems}`
          : alignItems};
      `}
    ${justifyContent &&
      css`
        justify-content: ${justifyContent === "start" ||
        justifyContent === "end"
          ? `flex-${justifyContent}`
          : justifyContent};
      `}
  `;
}

export const Wrapper = styled.div<
  Omit<Moim.Component.ProductItem.IWrapper, "type">
>`
  width: 100%;
  display: flex;
  flex-direction: ${props => props.direction ?? "column"};

  ${props =>
    getFlexAlignStyle({
      direction: props.direction ?? "column",
      horizontalAlign: props.horizontalAlign,
      verticalAlign: props.verticalAlign,
    })}
  ${props =>
    props.gap &&
    css`
      gap: ${px2rem(props.gap)};
    `}

    ${props =>
      props.padding &&
      css`
        padding: ${px2rem(props.padding.top ?? 0)}
          ${px2rem(props.padding.right ?? 0)}
          ${px2rem(props.padding.bottom ?? 0)}
          ${px2rem(props.padding.left ?? 0)};
      `}
    ${props =>
      props.border &&
      css`
        border-radius: ${px2rem(props.border?.radius ?? 0)};
        border: ${px2rem(props.border?.weight ?? 0)} solid ${props.border.color};
      `}
    
      ${props => {
        switch (props.background?.type) {
          case "image": {
            return css`
              background-image: url(${props.background.value});
              background-size: cover;
              background-repeat: no-repeat;
              background-position: center;
            `;
          }
          case "color": {
            return css`
              background-color: ${getColorByAlias(
                props.theme.colorV2,
                props.background.value,
              )};
            `;
          }
          default:
            return;
        }
      }}

      ${props => {
        switch (props.background?.shadow) {
          case "bgLevel1":
            return bgLevel1Style;
          case "bgLevel2":
            return bgLevel2Style;
          case "bgLevel3":
            return bgLevel3Style;
          case "bgLevel4":
            return bgLevel4Style;
        }
      }}

`;
