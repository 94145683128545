import * as React from "react";
import styled from "styled-components";

import ShavedText from "common/components/shavedText/v2";
import { ThreadDescriptionWrapper } from "common/components/thread/components/wrapper/description";
import { px2rem } from "common/helpers/rem";

interface IProps {
  description?: string;
  descriptionPlain?: string;
  isUnread?: boolean;
  line?: number;
}

const DescriptionWrapper = styled(ThreadDescriptionWrapper)<{}>`
  padding: ${px2rem(4)} 0;
  flex: 1;
  min-width: 0;
`;

function Description({ description, descriptionPlain, line = 3 }: IProps) {
  return (
    <DescriptionWrapper
      description={description}
      descriptionPlain={descriptionPlain}
    >
      {el => <ShavedText line={line}>{el}</ShavedText>}
    </DescriptionWrapper>
  );
}

export default React.memo(Description);
