import * as React from "react";

import Tags from "common/components/thread/templates/common/tags";
import Image from "./components/image";
import Title from "./components/title";
import Description from "./components/description";
import Engage from "./components/engage";
import { ContentWrapper, Wrapper } from "./styled";

import { Thread } from "app/typings";
import { PreviewBottom } from "../../components/previewBottom";

import { useStoreState } from "app/store";
import { Spacer } from "common/components/designSystem/spacer";
import UnlockContentMethods from "./components/unlockContentMethods";
import { ItemIdTypes } from "app/enums";
import useGetTagsFromThread from "common/hooks/useGetTagsFromThread";

export function PostItem({
  threadId,
  stat,
  titlePrefix,
  config,
  className,
  disableAnonymousSuffix,
}: Thread.IThreadItemBaseProps) {
  const thread = useStoreState(state => state.entities.threads[threadId]);
  const showThumbnail = Boolean(config?.showThumbnail);
  const showTitle = Boolean(config?.showTitle);
  const showDescription = Boolean(config?.showText);
  const showReaction = Boolean(config?.showReaction);
  const showCommentCount = Boolean(config?.showCommentCount);
  const showAuthor = Boolean(config?.showAuthor);
  const showAuthorAvatar = Boolean(config?.showAuthorAvatar);
  const showAuthorPosition = Boolean(config?.showAuthorPosition);
  const showDate = Boolean(config?.showDate);
  const showReadTime = Boolean(config?.showReadTime);
  const showViewCount = Boolean(config?.showViewCount);
  const showTags = Boolean(config?.showTags);
  const showChannel = Boolean(config?.showChannel);
  const showMoim = Boolean(config?.showMoim);
  const showEngage =
    showReaction ||
    showCommentCount ||
    showAuthor ||
    showDate ||
    showViewCount ||
    showChannel ||
    showMoim;
  const showUnlockMethods = Boolean(
    thread.locked &&
      thread.unlockMethods?.find(
        method => method?.resourceType === "transferCoin" && method.quantity,
      ),
  );
  const isUnlocked = Boolean(thread.unlocked_at);

  const isDraftThread = thread.id.startsWith(ItemIdTypes.DRAFT);

  const textAlign = config?.textAlignment;

  const { tags } = useGetTagsFromThread(thread);

  if (!thread) {
    return null;
  }
  return (
    <Wrapper
      thumbnailPosition={config?.thumbnailConfig?.position}
      className={className}
    >
      {showThumbnail && (
        <Image
          position={config?.thumbnailConfig?.position}
          thumbnail={thread.preview?.thumbnail}
          ratio={
            config.thumbnailConfig?.type === "ratio"
              ? config.thumbnailConfig.value
              : undefined
          }
          radius={config.thumbnailConfig?.radius}
          isVideo={thread.preview?.isVideo}
        />
      )}
      <ContentWrapper>
        {config?.thumbnailConfig?.position === "top" ||
          (config?.thumbnailConfig?.position === "bottom" && (
            <Spacer value={8} />
          ))}
        {showTitle && (
          <Title
            isDraftThread={isDraftThread}
            title={thread.preview?.title}
            stat={stat}
            prefix={titlePrefix}
            textAlign={textAlign}
            line={config.titleMaxLine}
          />
        )}
        {showDescription && (
          <>
            <Spacer value={4} />
            <Description
              description={thread.preview?.description}
              descriptionPlain={thread.preview?.descriptionPlain}
              textAlign={textAlign}
              line={config.textMaxLine}
            />
          </>
        )}
        {showUnlockMethods && (
          <>
            <Spacer value={8} />
            <UnlockContentMethods
              unlockMethods={thread.unlockMethods}
              textAlign={textAlign}
              isUnlocked={isUnlocked}
            />
          </>
        )}
        {showTags && tags.length !== 0 && (
          <>
            <Spacer value={8} />
            <Tags tags={tags} textAlign={textAlign} />
          </>
        )}
        {showEngage && (
          <>
            <Spacer value={6} />
            <Engage
              threadId={thread.id}
              isDraftThread={isDraftThread}
              upVoteCount={thread.up_vote_score}
              downVoteCount={thread.down_vote_score}
              commentCount={thread.replies_count}
              voteStatus={thread.vote?.type}
              voteType={config?.reactionType}
              viewCount={thread.viewCount}
              author={thread.author}
              createdAt={thread.created_at}
              editedAt={thread.edited_at}
              readTime={thread.readTime}
              textAlign={textAlign}
              showReaction={showReaction}
              showCommentCount={showCommentCount}
              showAuthor={showAuthor}
              showAuthorAvatar={showAuthorAvatar}
              showAuthorPosition={showAuthorPosition}
              showDate={showDate}
              showReadTime={showReadTime}
              showViewCount={showViewCount}
              showChannel={showChannel}
              showMoim={showMoim}
              channelId={thread.root_id}
              groupId={thread.groupId}
              anonymousData={thread.anonymous_data}
              disableAnonymousSuffix={disableAnonymousSuffix}
            />
          </>
        )}
      </ContentWrapper>
      <PreviewBottom blockits={thread.previewBottom ?? []} config={config} />
    </Wrapper>
  );
}
