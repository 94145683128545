import * as React from "react";
import useBrandFetchSelector from "common/hooks/useEntitiesFetchSelectors/commerce/useBrandFetchSelector";

import {
  Wrapper,
  FallbackImageWrapper,
  ImageFallback,
  Logo,
  BrandName,
} from "./styled";

import { MoimURL } from "common/helpers/url";
import ShavedText from "common/components/shavedText";

interface IProps {
  className?: string;
  brandId?: Moim.Id;
  block?: Moim.Component.ProductItem.IBrand;
}

const Brand = ({ brandId, className }: IProps) => {
  const {
    brands: { [0]: brand },
  } = useBrandFetchSelector(brandId ? [brandId] : []);

  if (!brand) return null;

  const { logoImageUrl, name, id } = brand;

  return (
    <Wrapper
      to={new MoimURL.CommerceBrandShow({ id }).toString()}
      className={className}
    >
      {!logoImageUrl ? (
        <FallbackImageWrapper>
          <ImageFallback />
        </FallbackImageWrapper>
      ) : (
        <Logo src={logoImageUrl} alt="logo" />
      )}
      <BrandName>
        <ShavedText value={name} line={1} />
      </BrandName>
    </Wrapper>
  );
};
export default React.memo(Brand);
