import { schema } from "normalizr";

export const coinDefinition = {};

export const coinEntity = new schema.Entity<Moim.Community.Coin.ICoin>(
  "community_coins",
  coinDefinition,
);

export const coinListEntity = new schema.Object<Moim.Community.Coin.ICoin>({
  data: new schema.Array(coinEntity),
});

export const coinRechargePackageDefinition = {};

export const coinRechargePackageEntity = new schema.Entity<
  Moim.Community.Coin.IRechargePackage
>("community_coin_recharge_package", coinRechargePackageDefinition);

export const coinRechargePackageListEntity = new schema.Object<
  Moim.Community.Coin.IRechargePackage
>({
  data: new schema.Array(coinRechargePackageEntity),
});
