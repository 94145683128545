import * as React from "react";
import styled from "styled-components";

import ShavedText from "common/components/shavedText/v2";
import { ThreadTitleWrapper } from "common/components/thread/components/wrapper/title";

import { px2rem } from "common/helpers/rem";
import { textAlignStyle } from "common/components/thread/styles";
import { Thread } from "app/typings";

const TitleWrapper = styled(ThreadTitleWrapper)<{
  textAlign?: Moim.Forum.ForumListConfigTextAlignment;
}>`
  margin-top: ${px2rem(4)};

  ${textAlignStyle}
`;

interface IProps {
  title?: string;
  stat?: Thread.IThreadItemStatProps;
  line?: number;
  prefix?: React.ReactNode;
  textAlign?: Moim.Forum.ForumListConfigTextAlignment;
}

function Title({ title, stat, textAlign, prefix, line = 1 }: IProps) {
  return (
    <TitleWrapper stat={stat} textAlign={textAlign} prefix={prefix}>
      <ShavedText line={line}>{title}</ShavedText>
    </TitleWrapper>
  );
}

export default React.memo(Title);
