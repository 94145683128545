import * as React from "react";
import BlockitRenderer from "../..";
import { withPlacement } from "../../hoc/withPlacement";
import {
  BlockitRendererContextProvider,
  useBlockitRendererContext,
} from "../../context";

const SectionBlock: React.FC<Moim.Blockit.V2.ISectionBlock> = ({
  items,
  background,
}) => {
  const { estimatedWidth } = useBlockitRendererContext();

  return (
    <>
      {items.map((block, idx) => (
        <BlockitRendererContextProvider
          estimatedWidth={
            estimatedWidth -
            ((block.padding?.left ?? 0) + (block.padding?.right ?? 0))
          }
          backgroundColor={
            background
              ? background.type === "color"
                ? background.value
                : "rgba(255, 255, 255, 0)"
              : undefined
          }
        >
          <BlockitRenderer key={`${block.type}_${idx}`} block={block} />
        </BlockitRendererContextProvider>
      ))}
    </>
  );
};

export default withPlacement(SectionBlock);
