import React from "react";
import _ from "lodash";

import { ChannelItem as BaseChannelItem } from "common/components/channelList";
import useSideNavigationPanel from "common/hooks/useSideNavigationPanel";
import { useInView } from "react-intersection-observer";
import { findUnreadChannel } from "../../helpers";
import { useStoreState } from "app/store";
import { directMessagesSelector } from "app/selectors/directMessage";
import { channelWithCategorySelector } from "app/selectors/channel";
import { NavigationPanelContext } from "app/modules/navigationPanel/context";

const ChannelItem: React.FC<{ channelId: string }> = ({ channelId }) => {
  const { collapseSideNavigation } = useSideNavigationPanel();
  const [ref, inView, entry] = useInView();

  const {
    isFirstUnreadChannelId,
    isLastUnreadChannelId,
    channelStatCount,
  } = useStoreState(state => {
    const dmChannelList = directMessagesSelector(state).data;
    const channelListWithoutCategory = _.flatten(
      channelWithCategorySelector(state).data.map(channel =>
        channel.type === "category" ? channel.items : channel,
      ),
    ) as Moim.Channel.SimpleChannelWithoutCategoryType[];

    return {
      isFirstUnreadChannelId:
        findUnreadChannel([...channelListWithoutCategory, ...dmChannelList])
          ?.id === channelId,
      isLastUnreadChannelId:
        findUnreadChannel(
          [...channelListWithoutCategory, ...dmChannelList].reverse(),
        )?.id === channelId,
      channelStatCount: (state.entities.channels[
        channelId
      ] as Moim.Channel.SimpleChannelWithoutCategoryType)?.stat?.count,
    };
  });

  const {
    topUnreadSnackBar,
    bottomUnreadSnackBar,
    setTopUnreadSnackBar,
    setBottomUnreadSnackBar,
  } = React.useContext(NavigationPanelContext);

  React.useEffect(() => {
    if (!entry || (!isFirstUnreadChannelId && !isLastUnreadChannelId)) {
      return;
    }

    if (inView) {
      if (topUnreadSnackBar.open && topUnreadSnackBar.openBy === channelId) {
        setTopUnreadSnackBar({ open: false });
      }

      if (
        bottomUnreadSnackBar.open &&
        bottomUnreadSnackBar.openBy === channelId
      ) {
        setBottomUnreadSnackBar({ open: false });
      }
    } else {
      if (
        isFirstUnreadChannelId &&
        entry.boundingClientRect.y < Math.floor(innerHeight / 2)
      ) {
        setTopUnreadSnackBar({
          open: true,
          type: channelStatCount ? "mention" : "unread",
          openBy: channelId,
        });
      } else if (
        isLastUnreadChannelId &&
        entry.boundingClientRect.y > Math.floor(innerHeight / 2)
      ) {
        setBottomUnreadSnackBar({
          open: true,
          type: channelStatCount ? "mention" : "unread",
          openBy: channelId,
        });
      }
    }
  }, [inView, entry]);

  return (
    <div data-side-navigation-channel-id={channelId}>
      <BaseChannelItem
        ref={ref}
        elementPaletteKey="background"
        textColorPaletteKey="menuText"
        channelId={channelId}
        isMuted={false}
        onClickChannel={collapseSideNavigation}
      />
    </div>
  );
};

export default ChannelItem;
