import * as React from "react";
import { FormattedMessage } from "react-intl";
import CurrencyFormatter from "common/components/currencyFormatter";
import useIsMobile from "common/hooks/useIsMobile";
import useGroupTexts from "common/hooks/useGroupTexts";
import { useSellerDisplayConfig } from "common/hooks/useSellerConfig";

import {
  FooterWrapper,
  TextWrap,
  Divider,
  TotalPrice,
  BuyNowButton,
  BottomStickyContainer,
  Shell,
  MobileTotalPrice,
  MinimumPriceInfo,
} from "./styled";
import Points from "./components/points";
import AdditionalFees from "./components/additionalFees";
import {
  AdditionalFeeWithPreview,
  PriceWithAdditionalFees,
} from "../../../productShow/layout/productSummary/components/right/price/additionalFee";
import { Spacer } from "common/components/designSystem/spacer";
import UsedCoins from "./components/usedCoins";
import UnsignedChecker from "common/components/unsiginedChecker";
import { PermissionDeniedFallbackType } from "app/enums";
import { useStoreState } from "app/store";

interface IProps {
  shippingFee: number;
  totalPrice: Moim.Commerce.IProductPrice;
  totalUsedCoinPrice: Moim.Commerce.IProductPrice;
  totalAdditionalFees?: Moim.Commerce.IProductAdditionalFee[];
  totalRawPrice: Moim.Commerce.IProductPrice;
  totalWeight?: number;
  totalQuantity?: number;
  coupon: number;
  points: number;
  usedCoins?: Moim.Commerce.ICalculateUsedCoin[];
  hasItemError?: boolean;
  hasCustomDeliveryPolicy?: boolean;
  minimumPurchaseAmount?: number;
  onClickBuyNow(): void;
}

const Footer: React.FC<IProps> = ({
  shippingFee,
  totalPrice,
  totalRawPrice,
  totalAdditionalFees,
  totalWeight,
  totalQuantity,
  coupon,
  points,
  // totalUsedCoinPrice,
  usedCoins,
  hasItemError,
  onClickBuyNow,
  minimumPurchaseAmount,
  hasCustomDeliveryPolicy,
}) => {
  const isMobile = useIsMobile();
  const buyNowText = useGroupTexts("button_buy_now");
  const isSigned = useStoreState(state => Boolean(state.app.currentUserId));
  const totalProductPrice = totalRawPrice.numValue - shippingFee;

  const isMinimumOrderMet = (minimumPurchaseAmount ?? 0) <= totalProductPrice;

  const displayWeight = useSellerDisplayConfig("weight");

  const buyNowButton = React.useMemo(() => {
    const disabled = hasItemError || !isMinimumOrderMet;
    const buyButton = (
      <BuyNowButton
        onClick={onClickBuyNow}
        disabled={isSigned && (hasItemError || !isMinimumOrderMet)}
      >
        {buyNowText ? (
          buyNowText.singular
        ) : (
          <FormattedMessage id="button_buy" />
        )}
      </BuyNowButton>
    );

    if (disabled) {
      return (
        <UnsignedChecker fallbackType={PermissionDeniedFallbackType.ALERT}>
          {buyButton}
        </UnsignedChecker>
      );
    }
    return buyButton;
  }, [isSigned, buyNowText, hasItemError, isMinimumOrderMet, onClickBuyNow]);

  return (
    <FooterWrapper>
      {totalQuantity ? (
        <TextWrap>
          <span className="left">
            <FormattedMessage id="summary_total_products" />
          </span>
          <span className="right">{totalQuantity}</span>
        </TextWrap>
      ) : null}
      {totalWeight && displayWeight ? (
        <TextWrap>
          <span className="left">
            <FormattedMessage id="summary_total_weight" />
          </span>
          <span className="right">{totalWeight} kg</span>
        </TextWrap>
      ) : null}

      <TextWrap>
        <span className="left">
          <FormattedMessage id="price/product_discounted_total" />
        </span>
        <span className="right">
          <CurrencyFormatter
            currency={totalPrice.currency}
            value={totalProductPrice}
          />
        </span>
      </TextWrap>
      <AdditionalFees additionalFees={totalAdditionalFees} />
      <TextWrap>
        <span className="left">
          <FormattedMessage id="price/shipping_total" />
        </span>
        <span className="right">
          +{" "}
          {hasCustomDeliveryPolicy ? (
            <FormattedMessage id="price_shipping_total_tbd" />
          ) : (
            <CurrencyFormatter
              currency={totalPrice.currency}
              value={shippingFee}
            />
          )}
        </span>
      </TextWrap>

      {coupon ? (
        <TextWrap>
          <span className="left">
            <FormattedMessage id="price/coupon_total" />
          </span>
          <span className="right">
            -{" "}
            <CurrencyFormatter currency={totalPrice.currency} value={coupon} />
          </span>
        </TextWrap>
      ) : null}

      <UsedCoins usedCoins={usedCoins} />

      <Divider />
      <TotalPrice buyable={isMinimumOrderMet}>
        <span className="left">
          <FormattedMessage id="price/total_estimated" />
        </span>
        <span className="right">
          <CurrencyFormatter
            currency={totalPrice.currency}
            value={totalPrice.value}
          />
        </span>
      </TotalPrice>
      {!isMinimumOrderMet && minimumPurchaseAmount && (
        <MinimumPriceInfo>
          <FormattedMessage id="error_minimum_total_product_price" />
          <CurrencyFormatter
            currency={totalPrice.currency}
            value={minimumPurchaseAmount}
          />
        </MinimumPriceInfo>
      )}
      <AdditionalFees additionalFees={totalAdditionalFees} />

      {points ? (
        <Points currency={totalPrice.currency} points={points} />
      ) : null}

      {!isMobile && <Spacer value={16} />}

      {!isMobile && buyNowButton}
      {isMobile && (
        <BottomStickyContainer>
          <Shell>
            <PriceWithAdditionalFees
              currency={totalPrice.currency}
              price={totalPrice.value}
              additionalFees={totalAdditionalFees}
              visibleFiatPrice={Boolean(
                totalPrice.numValue === 0 && totalPrice,
              )}
              AdditionalFeeComponent={AdditionalFeeWithPreview}
            >
              {elements => (
                <MobileTotalPrice
                  buyable={isMinimumOrderMet}
                  className={elements?.length > 1 ? "small" : undefined}
                >
                  {elements}
                </MobileTotalPrice>
              )}
            </PriceWithAdditionalFees>
          </Shell>
          <Shell>{buyNowButton}</Shell>
        </BottomStickyContainer>
      )}
    </FooterWrapper>
  );
};

export default Footer;
