import { Svg18CancelG, Svg18Adminsetting } from "@moim/icons";
import React from "react";
import { useLocation } from "react-router";
import styled, { ThemeContext } from "styled-components";
import MSwitch from "@material-ui/core/Switch";
import PopoverBase from "@material-ui/core/Popover";



import useHover from "common/hooks/useHover";
import useCurrentUser from "common/hooks/useCurrentUser";
import { useActions, useStoreState } from "app/store";
import { px2rem } from "common/helpers/rem";
import { ThemeMode } from "app/enums";
import { ActionCreators as AppActionCreators } from "app/actions/app";
import { browserLocale } from "app/intl";
import { isDev, isStage } from "common/helpers/envChecker";
import useIsMobile from "common/hooks/useIsMobile";
import { MEDIA_QUERY } from "common/constants/responsive";
import { updateMyProfile } from "app/actions/me";
import { LOCAL_CURRENCY } from "common/constants/keys";
import useHubSeller from "common/hooks/commerce/useHubSeller";

const SettingDialog = styled(PopoverBase).attrs({
  classes: { paper: "paper" },
})`
  .MuiPaper-root {
    border-radius: ${px2rem(2)};

    position: absolute;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${px2rem(8)};
    padding: ${px2rem(8)} ${px2rem(16)};
    background-color: ${props =>
      props.theme.themeMode.lightPalette.colorSet.white1000};
    z-index: 10000;

    & > .item {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      select {
        width: 100%;
      }
    }
  }
`;

const SettingButton = styled(Svg18Adminsetting).attrs({
  size: "xs",
  touch: 24,
  role: "button",
})``;
const CloseButton = styled(Svg18CancelG).attrs({
  size: "xs",
  touch: 24,
  role: "button",
})``;
const CloseButtonWrapper = styled.div`
  position: absolute;
  top: ${px2rem(-6)};
  left: ${px2rem(-6)};
`;

const Wrapper = styled.div`
  position: fixed;
  bottom: 10vh;
  right: 0;
  z-index: 9999;
  padding: ${px2rem(8)};
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
  box-shadow: 0 2px 8px 0 rgba(31, 38, 42, 0.2);
  border: solid 1px
    ${props => props.theme.themeMode.lightPalette.colorSet.grey50};
  background-color: ${props =>
    props.theme.themeMode.lightPalette.colorSet.white1000};
  transition: background-color 200ms ease-in-out;
  @media ${MEDIA_QUERY.EXCEPT_MOBILE} {
    &:hover {
      background-color: ${props => props.theme.color.blue};
    }
  }
`;
const Component: React.FC = () => {
  const location = useLocation();
  const isMobile = useIsMobile();
  const [refThis, isHovered] = useHover<HTMLDivElement>();
  const { setThemeMode, themeMode } = React.useContext(ThemeContext);
  const [show, setShow] = React.useState(true);
  const [isSettingDialogOpen, setIsSettingDialogOpen] = React.useState(false);
  const {
    locale,
    textKeyVisible,
    localCurrency,
    currentUserId,
  } = useStoreState(state => ({
    locale: state.app.locale || browserLocale(),
    textKeyVisible: state.app.textKeyVisible,
    localCurrency: state.app.localCurrency,
    currentUserId: state.app.currentUserId,
  }));
  const seller = useHubSeller();
  const {
    changeLocale,
    changeTextKeyVisible,
    dispatchUpdateMyProfile,
  } = useActions({
    changeLocale: AppActionCreators.changeLocale,
    changeTextKeyVisible: AppActionCreators.changeTextKeyVisible,
    dispatchUpdateMyProfile: updateMyProfile,
  });
  React.useEffect(() => {
    if (!show) {
      setShow(true);
    }
  }, [location.pathname]);

  if (!show) {
    return null;
  }

  return (
    <>
      <Wrapper
        ref={refThis}
        onClick={() => {
          setIsSettingDialogOpen(true);
        }}
      >
        {(isMobile || isHovered) && (
          <CloseButtonWrapper>
            <CloseButton onClick={() => setShow(false)} />
          </CloseButtonWrapper>
        )}
        <SettingButton />
      </Wrapper>
      <SettingDialog
        open={isSettingDialogOpen}
        anchorEl={refThis.current}
        onClose={() => {
          setIsSettingDialogOpen(false);
        }}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <div className="item">
          <select
            value={locale}
            onChange={async e => {
              const selectedLocale = e.target.value;

              changeLocale(selectedLocale);
            }}
          >
            <option value="ko">ko</option>
            <option value="en">en</option>
            <option value="ja">ja</option>
          </select>
        </div>

        <div className="item">
          Translated
          <MSwitch
            checked={textKeyVisible}
            onClick={() => {
              changeTextKeyVisible(!textKeyVisible);
            }}
            color="primary"
          />
          Text Key
        </div>

        <div className="item">
          Light
          <MSwitch
            checked={themeMode.mode === ThemeMode.DARK}
            onClick={() => {
              setThemeMode(
                themeMode.mode === ThemeMode.DARK
                  ? ThemeMode.LIGHT
                  : ThemeMode.DARK,
              );
            }}
            color="primary"
          />
          Dark
        </div>

        <div className="item">
          <select
            value={localCurrency}
            onChange={async e => {
              const changeLocalCurrency = e.target.value;
              if (currentUserId) {
                await dispatchUpdateMyProfile({
                  currency: changeLocalCurrency,
                });
              } else {
                localStorage.setItem(LOCAL_CURRENCY, changeLocalCurrency);
              }
              window.location.reload();
            }}
          >
            {seller?.availableCurrencies?.map(currency => (
              <option value={currency}>{currency}</option>
            ))}
          </select>
        </div>
      </SettingDialog>
    </>
  );
};
const AdminFAB: React.FC = () => {
  const currentUser = useCurrentUser();
  const isAdmin =
    currentUser?.email?.endsWith("vingle.net") ||
    currentUser?.email?.endsWith("canlab.co") ||
    isStage() ||
    isDev();

  if (!isAdmin) {
    return null;
  }

  return <Component />;
};

export default AdminFAB;
