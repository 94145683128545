import * as React from "react";
import { useActions, useStoreState } from "app/store";
import { useCancelTokenWithCancelHandler } from "common/hooks/useCancelToken";
import useEntitiesFetchSelector from "common/hooks/useEntitiesFetchSelectors/useEntitiesFetchSelector";
import { nestedReplies } from "app/selectors/forum";
import { getCommentList } from "app/actions/forum";
import { reorderThreadListSelector } from "app/modules/subReplies/utils";
import { DefaultLoader as Loader } from "common/components/loading";
import InfiniteScroller from "common/components/infiniteScroller/new";
import ReplyItem from "app/modules/subReplies/components/replyItem";
import { ClickableLoader } from "app/modules/subReplies/loader";

const EMPTY_DATA = {
  data: [],
  postedReplies: [],
  paging: {},
};

interface IProps {
  channelId: Moim.Id;
  commentId: Moim.Id;
  replyId?: Moim.Id;
}

const NestedReplyList: React.FC<IProps> = ({
  channelId,
  commentId,
  replyId,
}) => {
  const { cancelTokenSource, handleCancel } = useCancelTokenWithCancelHandler();
  const [isRepliesLoading, setRepliesLoadStatus] = React.useState<
    boolean | undefined
  >(undefined);

  const threadList = useStoreState(state => {
    if (!commentId) return EMPTY_DATA;

    const replyData = nestedReplies(state, commentId);

    return {
      data: replyData.data ?? EMPTY_DATA.data,
      postedReplies: replyData.postedCommentsData ?? EMPTY_DATA.postedReplies,
      paging: replyData.paging,
    };
  });

  const { dispatchGetSubReplies } = useActions({
    dispatchGetSubReplies: getCommentList,
  });

  const {
    entities: { channels },
  } = useEntitiesFetchSelector({
    channels: [channelId],
  });

  const sorting_option = React.useMemo(
    () => ({
      sort: channels[0]?.reply_sorting_options?.[0].sort ?? "createdAt",
      order: (
        channels[0]?.reply_sorting_options?.[0].order ?? "ASC"
      ).toUpperCase(),
    }),
    [channels],
  );

  const reorderedReplyList = React.useMemo(
    () =>
      reorderThreadListSelector(
        threadList.data,
        threadList.postedReplies,
        sorting_option,
      ),
    [threadList.data, threadList.postedReplies, sorting_option],
  );

  const handleLoadMore = React.useCallback(
    pagingKey => {
      if (!isRepliesLoading && channelId && commentId) {
        setRepliesLoadStatus(true);
        dispatchGetSubReplies(
          {
            type: "nestedReply",
            channelId,
            threadId: commentId,
            limit: 10,
            sort: sorting_option.sort,
            order: sorting_option.order,

            [pagingKey]: threadList.paging[pagingKey],
          },
          cancelTokenSource.current.token,
          pagingKey,
        ).finally(() => {
          setRepliesLoadStatus(false);
        });
      }
    },
    [
      cancelTokenSource,
      channelId,
      threadList.paging,
      dispatchGetSubReplies,
      isRepliesLoading,
      sorting_option.order,
      sorting_option.sort,
      commentId,
    ],
  );

  React.useEffect(() => {
    const alreadyHasData = Boolean(threadList.data.length);
    const isDefaultListFetch =
      channelId && commentId && !replyId && isRepliesLoading === undefined;
    const isNestedListFetch =
      channelId && commentId && replyId && isRepliesLoading === undefined;

    if (alreadyHasData) {
      return;
    }

    if (isDefaultListFetch) {
      setRepliesLoadStatus(true);
      dispatchGetSubReplies(
        {
          type: "nestedReply",
          channelId,
          threadId: commentId,
          limit: 10,
          sort: sorting_option.sort,
          order: sorting_option.order,
        },
        cancelTokenSource.current.token,
      ).finally(() => {
        setRepliesLoadStatus(false);
      });
    }

    if (isNestedListFetch) {
      setRepliesLoadStatus(true);
      Promise.all([
        dispatchGetSubReplies(
          {
            type: "nestedReply",
            channelId,
            threadId: commentId,
            limit: 1,
            inclusive: true,
            before: replyId,
            sort: sorting_option.sort,
            order: sorting_option.order,
          },
          cancelTokenSource.current.token,
          "before",
        ),
        dispatchGetSubReplies(
          {
            type: "nestedReply",
            channelId,
            threadId: commentId,
            limit: 10,
            inclusive: true,
            after: replyId,
            sort: sorting_option.sort,
            order: sorting_option.order,
          },
          cancelTokenSource.current.token,
          "after",
        ),
      ]).finally(() => {
        setRepliesLoadStatus(false);
      });
    }
  }, [channelId, commentId, replyId, sorting_option]);

  React.useEffect(
    () => () => {
      handleCancel();
    },
    [],
  );

  return (
    <InfiniteScroller
      loader={<Loader />}
      threshold={300}
      isLoading={isRepliesLoading}
      itemLength={threadList.data.length}
      beforeLoadComponent={ClickableLoader}
      afterLoadComponent={ClickableLoader}
      paging={threadList.paging}
      loadMore={handleLoadMore}
    >
      {reorderedReplyList.map(datum => (
        <ReplyItem key={datum.id} replyId={datum.id} />
      ))}
    </InfiniteScroller>
  );
};

export default NestedReplyList;
