import { Svg18SearchPlaceholderG } from "@moim/icons";
import styled from "styled-components";
import { px2rem } from "common/helpers/rem";
import { B1RegularStyle } from "common/components/designSystem/typos";


import { BG_LEVEL_BACKGROUND_CLASS_NAME } from "common/components/designSystem/BGLevel";

export const SearchIcon = styled(Svg18SearchPlaceholderG).attrs({
  size: "xs",
  touch: 18,
})``;

export const SearchIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${px2rem(30)};
  height: ${px2rem(30)};
`;

export const InputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: ${px2rem(40)};
  padding: ${px2rem(9)} ${px2rem(10)} ${px2rem(9)} ${px2rem(16)};
  border-radius: ${px2rem(4)};
  border: 1px solid ${props => props.theme.colorV2.colorSet.grey200};
`;

export const Wrapper = styled.label.attrs({
  className: BG_LEVEL_BACKGROUND_CLASS_NAME,
})`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: ${px2rem(10)};
  z-index: ${props => props.theme.zIndexes.wrapper};
`;

export const Input = styled.input`
  min-width: ${px2rem(10)};
  max-width: 100%;
  background-color: transparent;
  border: 0;

  color: ${props => props.theme.colorV2.colorSet.grey800};
  ${B1RegularStyle};

  &::placeholder {
    color: ${props => props.theme.colorV2.colorSet.grey300};
  }
`;
