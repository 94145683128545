import produce from "immer";
import { AllActions } from "app/actions";
import { EntityTypes, PositionTypes } from "app/actions/types";
import { getInitialData } from "common/helpers/initialData";
import { positionListNormalizer } from "app/models/position";

export const INITIAL_STATE: (
  requestId?: string,
) => Moim.Position.INormalizedPositionEntities = (requestId?: string) => {
  const entities: Moim.Position.INormalizedPositionEntities = {};
  const data = getInitialData("__positionData", requestId ?? "");

  if (data) {
    const result = positionListNormalizer(data);
    Object.assign(entities, result.entities.positions);
  }

  return entities;
};

export function reducer(
  state: Moim.Position.INormalizedPositionEntities = INITIAL_STATE(),
  action: AllActions,
) {
  return produce(state, draft => {
    switch (action.type) {
      case EntityTypes.ADD_ENTITY: {
        if (action.payload.positions) {
          Object.entries(action.payload.positions).forEach(([key, value]) => {
            draft[key] = value;
          });
        }
        break;
      }

      case PositionTypes.SUCCEED_DELETE_POSITION: {
        const positionId = action.payload.position.data;
        delete draft[positionId];
        break;
      }

      case PositionTypes.SUCCEED_UPDATE_POSITION_PRIORITY: {
        const { position } = action.payload;
        const id = position.id;
        if (draft[id]) {
          draft[id].priority = position.priority;
        }

        break;
      }
    }
  });
}
