import { Svg48NoRight, Svg48Empty } from "@moim/icons";
import styled from "styled-components";
import { px2rem } from "common/helpers/rem";



export const Container = styled.div`
  padding: ${px2rem(80)} 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.colorV2.colorSet.grey300};
`;

export const NoRightIcon = styled(Svg48NoRight).attrs({
  size: "l",
  touch: 48,
})``;

export const EmptyIcon = styled(Svg48Empty).attrs({
  size: "l",
  touch: 48,
})``;
