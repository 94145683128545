import React from "react";

import { useStoreState } from "app/store";
import useRedirect from "common/hooks/useRedirect";
import useSideNavigationPanel from "common/hooks/useSideNavigationPanel";

import { MoimURL } from "common/helpers/url";
import { getStoreCommerceCategoriesSelector } from "app/selectors/commerce";

import {
  ArrowRightIcon,
  ArrowRightIconWrapper,
  CategoryListWrapper,
  FallbackLogo,
  ItemDetail,
  Logo,
  RootCategory,
} from "./styled";

import CommerceCategoryAccordion from "./CommerceCategoryAccordion";
import ShavedTextV2 from "common/components/shavedText/v2";

interface IProps {
  onClose?: () => void;
}

const CommerceCategoryList = ({ onClose }: IProps) => {
  const redirect = useRedirect();

  const { collapseSideNavigation } = useSideNavigationPanel();

  const categories = useStoreState(
    state => getStoreCommerceCategoriesSelector(state)?.data,
  );

  const categoryArr = Object.values(categories);
  const hasChildCategory = React.useCallback(
    (category: Moim.Commerce.ICategory) =>
      category?.items && category.items.length > 0,
    [],
  );

  const handleClick = React.useCallback(
    (category: Moim.Commerce.ICategory) => {
      if (category.id) {
        redirect(
          new MoimURL.CommerceCategories({
            id: category.id,
            section: "products",
          }).toString(),
        );
        if (onClose) {
          onClose();
        }
        collapseSideNavigation();
      }
    },
    [onClose],
  );

  const categoryList = categoryArr.map(category => {
    const hasChild = category?.items && category.items.length > 0;

    return (
      <div>
        <RootCategory
          onClick={() => {
            handleClick(category);
          }}
        >
          <ItemDetail>
            {category.imageUrl ? (
              <Logo src={category.imageUrl} alt="logo" />
            ) : (
              <FallbackLogo />
            )}
            <ShavedTextV2 line={1}>{category.name}</ShavedTextV2>
          </ItemDetail>
          <ArrowRightIconWrapper className="right-arrow">
            <ArrowRightIcon isRoot={true} />
          </ArrowRightIconWrapper>
        </RootCategory>

        {hasChild
          ? category.items.map(category2 => (
              <CommerceCategoryAccordion
                hasChildCategory={hasChildCategory(category2)}
                onClose={onClose}
                key={category2.id}
                category={category2}
              />
            ))
          : null}
      </div>
    );
  });

  return (
    <CategoryListWrapper data-scroll-lock-scrollable wrapperWidth={"100%"}>
      {categoryList}
    </CategoryListWrapper>
  );
};

export default CommerceCategoryList;
