import { ActionUnion } from "./helpers";
import { StatTypes } from "app/actions/types";

function createAction<T extends { type: StatTypes }>(d: T): T {
  return d;
}

export const ActionCreators = {
  newMessageReceived: (resourceId: string) =>
    createAction({
      type: StatTypes.NEW_MESSAGE_ARRIVED,
      payload: {
        resourceId,
      },
    }),
};

export type Actions = ActionUnion<typeof ActionCreators>;
