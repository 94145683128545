import { Engage, EngageWrapper } from "./styled";
import { VoteStatus } from "app/enums";
import { SmallComment } from "common/components/engage/comment";
import { SmallLike } from "common/components/engage/like";
import { SmallUpDown } from "common/components/engage/upDown";
import * as React from "react";

export interface IProps {
  threadId: Moim.Id;
  upVoteCount: number;
  downVoteCount: number;
  commentCount: number;
  voteType?: Moim.Forum.PostReactionType;
  voteStatus?: Moim.Enums.VoteStatus;

  showReaction: boolean;
  showCommentCount: boolean;
}

function Engagement({
  threadId,
  voteType,
  voteStatus,
  upVoteCount,
  downVoteCount,
  commentCount,
  showReaction,
  showCommentCount,
}: IProps) {
  return (
    <EngageWrapper>
      {showReaction && (
        <Engage>
          {voteType === "up" ? (
            <SmallLike
              liked={voteStatus === VoteStatus.POSITIVE}
              likeCount={upVoteCount}
              threadId={threadId ?? ""}
            />
          ) : (
            <SmallUpDown
              threadId={threadId ?? ""}
              status={voteStatus ?? VoteStatus.NONE}
              upCount={upVoteCount}
              downCount={downVoteCount}
              visibleNoneCountFallback={false}
            />
          )}
        </Engage>
      )}

      {showCommentCount && (
        <Engage>
          <SmallComment count={commentCount} />
        </Engage>
      )}
    </EngageWrapper>
  );
}

export default React.memo(Engagement);
