import { createSelector } from "reselect";
import { MoimAPI } from ".";
import { IAppState } from "app/rootReducer";
import { AppDispatch } from "../../store";

export const apiSelector = createSelector(
  (state: IAppState, _: AppDispatch, groupId?: string) =>
    groupId ?? state.app.currentGroupId,
  (state: IAppState, _: AppDispatch) => state.app.localCurrency,
  (state: IAppState, _: AppDispatch) => state.app.locale ?? undefined,
  (_: IAppState, dispatch: AppDispatch) => dispatch,
  (groupId, localCurrency, locale, dispatch) => {
    const api = new MoimAPI(dispatch, groupId, localCurrency, locale);
    return api;
  },
);
