import * as React from "react";
import { FlattenInterpolation } from "styled-components";
import AppBar from "common/components/appBar";
import {
  getAppBarStickyStyle,
  AppBarWrapperStyle,
  Body,
  SecondaryView,
  ScrollSection,
  SecondaryViewWrapper,
} from "../../styled";
import { useProps } from "./hooks";

import StickyTopBanner from "common/components/promotion/stickyTopBanner";

export interface IProps extends React.PropsWithChildren<{}> {
  appBar: React.ComponentProps<typeof AppBar>;
  rootOverrideStyle?: FlattenInterpolation<any>;
  bodyOverrideStyle?: FlattenInterpolation<any>;
  renderCloseButton?: (close: () => void) => React.ReactNode;
}

export function DefaultLayout(props: IProps) {
  const {
    refWrapper,
    isMobile,
    appBarTopPosition,
    rootOverrideStyle,
    bodyOverrideStyle,
    leftElement,
    rightElement,
    appBarRestProps,
    children,
  } = useProps(props);
  const { wrapperStickyStyle, ...rest } = appBarRestProps;

  return (
    <SecondaryView id="native-secondary-view" overrideStyle={rootOverrideStyle}>
      <StickyTopBanner />

      <SecondaryViewWrapper ref={refWrapper}>
        {!isMobile ? (
          <ScrollSection>
            <AppBar
              wrapperStickyStyle={getAppBarStickyStyle(
                appBarTopPosition,
                wrapperStickyStyle,
              )}
              wrapperStyle={AppBarWrapperStyle}
              leftButton={leftElement}
              rightButton={rightElement}
              {...rest}
            />
            <Body overrideStyle={bodyOverrideStyle}>{children}</Body>
          </ScrollSection>
        ) : (
          [
            <AppBar
              wrapperStickyStyle={getAppBarStickyStyle(
                appBarTopPosition,
                wrapperStickyStyle,
              )}
              wrapperStyle={AppBarWrapperStyle}
              leftButton={leftElement}
              rightButton={rightElement}
              {...rest}
            />,
            <Body overrideStyle={bodyOverrideStyle}>{children}</Body>,
          ]
        )}
      </SecondaryViewWrapper>
    </SecondaryView>
  );
}
