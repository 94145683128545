import DividerBase from "common/components/divider";
import { px2rem } from "common/helpers/rem";
import styled, { css } from "styled-components";

export const Divider = styled(DividerBase).attrs((props) => ({
  color: props.theme.colorV2.colorSet.grey50,
  height: px2rem(1),
}))``;

export const Wrapper = styled.div<{
  thumbnailUrl?: string;
}>`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border-radius: ${px2rem(8)};
  padding: ${px2rem(20)};

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-image: ${(props) =>
      props.thumbnailUrl ? `url(${props.thumbnailUrl})` : "none"};
    background-position: center;
    transition: transform 0.5s ease;
  }

  &:hover::before {
    transform: scale(1.2);
  }

  ${(props) =>
    props.thumbnailUrl
      ? css`
          &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: ${px2rem(8)};
            background: rgba(0, 0, 0, 0.4);
            pointer-events: none;
          }
        `
      : css`
          border: 1px solid ${(props) => props.theme.colorV2.colorSet.grey50};
          box-shadow: 0 2px 6px 1px
            ${(props) => props.theme.colorV2.colorSet.grey50};
          &:hover {
            background-color: ${(props) => props.theme.colorV2.colorSet.grey10};
          }
        `}

  & * {
    z-index: ${(props) => props.theme.zIndexes.default};
  }
`;

export const DividingSpace = styled.div`
  flex: 1;
  min-width: 0;
`;
