/* eslint-disable no-underscore-dangle */
import { ISSRLoadDataPayload } from "app/typings/ssr";
import { MoimAPI } from "common/api";
import { setInitialData } from "common/helpers/initialData";
import _ from "lodash";

async function loadChannel(requestId: string, api: MoimAPI, channelId: string) {
  const channel = await api.channel.getChannel({ channelId });

  setInitialData("__entities.channel", requestId, channel);
}

export async function ChannelLoadData({
  api,
  match,
  requestId,
}: ISSRLoadDataPayload) {
  const channelId =
    match.params.forumId ??
    match.params.conversationId ??
    match.params.viewId ??
    match.params.tag;

  if (channelId) {
    await loadChannel(requestId, api, channelId);
  }
}
