import { GalleryItem } from "./components/gallery";
import { MagazineItem } from "./components/magazine";
import { MediaFocusFeedItem } from "./components/mediaFocusFeed";
import { MediaTextMixedFeedItem } from "./components/mediaTextMixedFeed";

/**
 * duplicated from @moim/common
 */
export const ThreadItem = {
  MagazineItem,
  MediaFocusFeedItem,
  MediaTextMixedFeedItem,
  GalleryItem,
};
