import React from "react";
import styled, { css } from "styled-components";
import { Spacer } from "common/components/designSystem/spacer";
import Timer from "../../../../../components/timer";
import { px2rem } from "common/helpers/rem";
import { MEDIA_QUERY } from "common/constants/responsive";

const TimeSaleWrapper = styled.div`
  @media ${MEDIA_QUERY.EXCEPT_MOBILE} {
    padding: 0 ${px2rem(16)};
  }
`;

interface IProps {
  size: "small" | "normal";
  endAt: number;
  backgroundColor?: string;
  className?: string;
}

export default function SaleTimerComponent({
  size,
  className,
  endAt,
  backgroundColor,
}: IProps) {
  return (
    <>
      <TimeSaleWrapper className={className}>
        <Timer
          size={size}
          endDateTime={endAt}
          overrideStyle={css`
            width: 100%;
            background-color: ${backgroundColor ?? "transparent"};
            color: #ffffff;
          `}
        />
      </TimeSaleWrapper>
      <Spacer value={16} />
    </>
  );
}
