import produce from "immer";
import { AllActions } from "app/actions";
import { UserTypes } from "app/actions/types";
import { THREAD_TYPE } from "app/typings/thread";
import { uniq } from "lodash";

export const INITIAL_STATE: Record<
  Moim.Id, // userId
  {
    typeThreadsCount: Record<string, Moim.Forum.IUserThreadCountBody>;
    typeThreads: Record<
      string,
      {
        total: number;
        data: Moim.IPaginatedListResponse<Moim.Id>;
      }
    >;
  }
> = {};

export function buildTypeKey(types?: THREAD_TYPE[]) {
  return types && types.length > 0 ? uniq(types).join("|") : "all";
}

export function reducer(state = INITIAL_STATE, action: AllActions) {
  return produce(state, draft => {
    switch (action.type) {
      case UserTypes.STORE_SEARCH_USER_THREADS_COUNT: {
        const { userId, count, types } = action.payload;
        const type = buildTypeKey(types);

        if (!draft[userId]) {
          draft[userId] = {
            typeThreadsCount: {},
            typeThreads: {},
          };
        }

        draft[userId].typeThreadsCount[type] = count;
        break;
      }

      case UserTypes.SUCCEED_SEARCH_USER_THREADS: {
        const { userId, types, threads, direction } = action.payload;
        const type = buildTypeKey(types);

        if (!draft[userId]) {
          draft[userId] = {
            typeThreadsCount: {},
            typeThreads: {},
          };
        }

        if (!draft[userId].typeThreads[type]) {
          draft[userId].typeThreads[type] = {
            total: 0,
            data: {
              data: [] as string[],
              paging: {},
            },
          };
        }

        switch (direction) {
          case "before": {
            draft[userId].typeThreads[type].data.data = threads.data.concat(
              draft[userId].typeThreads[type].data.data,
            );
            draft[userId].typeThreads[type].data.paging = threads.paging;
            break;
          }
          case "after": {
            draft[userId].typeThreads[type].data.data = draft[
              userId
            ].typeThreads[type].data.data.concat(threads.data);
            draft[userId].typeThreads[type].data.paging = threads.paging;
            break;
          }
          default: {
            draft[userId].typeThreads[type].data.data = threads.data;
            draft[userId].typeThreads[type].data.paging = threads.paging;

            break;
          }
        }
        break;
      }
    }
  });
}
