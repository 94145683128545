import { PhoneNumberUtil } from "google-libphonenumber";
import { useIntlShort } from "common/hooks/useIntlShort";

export const validatePhone = ({
  intl,
  countryCode,
  nationalNumber,
}: {
  intl: ReturnType<typeof useIntlShort>;
  countryCode?: string;
  nationalNumber?: string;
}) => {
  if (!countryCode || !nationalNumber) {
    return;
  }
  const instance = PhoneNumberUtil.getInstance();
  const regionCode = instance.getRegionCodeForCountryCode(Number(countryCode));
  const _parsed = instance.parseAndKeepRawInput(nationalNumber, regionCode);
  if (!instance.isPossibleNumber(_parsed) || !instance.isValidNumber(_parsed)) {
    return intl("error_phone_invalid_format");
  }
};
