import * as React from "react";

import { Wrapper } from "./styled";
import ElementRenderer from "../../elementRenderer";

interface IProps {
  element: Moim.Layout.Navigation.SectionElementType;
  zIndex?: number;
}
export default function SectionElement({ element, zIndex = 0 }: IProps) {
  const elements = React.useMemo(
    () =>
      element.items.map((child, index) => (
        <ElementRenderer
          key={`child-${element.type}-${index}`}
          element={child}
          zIndex={zIndex + 1}
        />
      )),
    [element, zIndex],
  );
  return (
    <Wrapper element={element} zIndex={zIndex}>
      {elements}
    </Wrapper>
  );
}
