// helper
import { AllActions } from "app/actions";
import produce from "immer";
import { PermissionTypes, AuthTypes } from "app/actions/types";
import { getInitialData } from "common/helpers/initialData";
import { isServer } from "common/helpers/envChecker";

function permissionArray2Record(
  resourceId: string,
  permissions: Moim.Permission.IPermission[],
) {
  if (!permissions.length) {
    return {
      [resourceId]: {},
    };
  }
  return permissions.reduce<Moim.Permission.PermissionRecord>(
    (result, permission) => {
      const permissionResourceId = permission.resource;
      result[permissionResourceId] = {
        ...result[permissionResourceId],
        [permission.right]: permission,
      };
      return result;
    },
    {},
  );
}

export const INITIAL_STATE: (
  requestId?: string,
) => Moim.Permission.IPermissionData = (requestId?: string) => {
  const state = {
    permission: {},
    permissionLoading: {},
    permissionLoadedOnServer: {},
  };

  if (
    getInitialData("__homeChannel", requestId ?? "")?.data &&
    getInitialData("__homePermissionData", requestId ?? "")?.data
  ) {
    state.permission = {
      ...state.permission,
      ...permissionArray2Record(
        getInitialData("__homeChannel", requestId ?? "").data.id,
        getInitialData("__homePermissionData", requestId ?? "").data,
      ),
    };
  }

  const resourcePermissions = getInitialData(
    "__resourcePermissions",
    requestId ?? "",
  );
  if (resourcePermissions) {
    Object.keys(resourcePermissions).forEach(resourceId => {
      state.permission = {
        ...state.permission,
        ...permissionArray2Record(
          resourceId,
          resourcePermissions[resourceId].data,
        ),
      };
    });
  }

  if (isServer()) {
    Object.keys(state.permission).forEach(resourceId => {
      state.permissionLoadedOnServer[resourceId] = true;
    });
  }

  return state;
};

export const reducer = (state = INITIAL_STATE(), action: AllActions) =>
  produce(state, draft => {
    switch (action.type) {
      case PermissionTypes.START_GET_PERMISSION: {
        const { resourceId } = action.payload;
        draft.permissionLoading[resourceId] = true;
        break;
      }
      case PermissionTypes.SUCCEED_UPDATE_PERMISSION: {
        const { resourceId, permissions } = action.payload;
        draft.permissionLoading[resourceId] = false;

        const permissionRecord = permissionArray2Record(
          resourceId,
          permissions,
        );

        Object.keys(permissionRecord).forEach(permissionResourceId => {
          draft.permission[permissionResourceId] = {
            ...draft.permission[permissionResourceId],
            ...permissionRecord[permissionResourceId],
          };
        });
        break;
      }
      case PermissionTypes.SUCCEED_GET_PERMISSION: {
        const { resourceId, permissions } = action.payload;
        draft.permissionLoading[resourceId] = false;

        const permissionRecord = permissionArray2Record(
          resourceId,
          permissions,
        );

        Object.keys(permissionRecord).forEach(permissionResourceId => {
          draft.permission[permissionResourceId] =
            permissionRecord[permissionResourceId];
        });

        break;
      }

      case PermissionTypes.FAILED_GET_PERMISSION: {
        const { resourceId } = action.payload;

        draft.permissionLoading[resourceId] = false;

        break;
      }

      case AuthTypes.CURRENT_USER_CHANGED:
      case AuthTypes.SIGN_OUT:
      case PermissionTypes.CLEAR_PERMISSION: {
        draft.permission = INITIAL_STATE().permission;
        draft.permissionLoading = INITIAL_STATE().permissionLoading;

        break;
      }
    }
  });
