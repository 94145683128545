import React from "react";
import ChannelListElement from "./components/channelList";
import DirectMessageListElement from "./components/directMessageList";
import CommerceCategoryListElement from "./components/commerceCategoryList";

interface IProps {
  element: Moim.Navigation.NavigationElement;
}

const SideNavigationRenderer = ({ element }: IProps) => {
  switch (element.type) {
    case "category":
      return <CommerceCategoryListElement />;
    case "channel":
      return <ChannelListElement />;
    case "directMessage":
      return <DirectMessageListElement />;
    default:
      return <></>;
  }
};

export default SideNavigationRenderer;
