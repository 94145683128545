import { ItemWrapper, ReactionStat, StatWrapper, Wrapper } from "./styled";
import { VoteStatus } from "app/enums";
import { LargeBookmark } from "common/components/engage/bookmark";
import { LargeComment } from "common/components/engage/comment";
import { LargeLike } from "common/components/engage/like";
import { LargeShare } from "common/components/engage/share";
import { LargeUp } from "common/components/engage/up";
import { useThreadItemContext } from "common/components/thread/commonThreadItem/context";
import * as React from "react";
import { useIntl } from "react-intl";

function Engagement({
  reactionHandler,
  // onDownCountClick,
  onUpCountClick,
  onCommentClick,
  onShareClick,
  onBookmarkClick,
}: {
  reactionHandler?: (type: Moim.Enums.VoteStatus) => void;
  onDownCountClick?: React.MouseEventHandler;
  onUpCountClick?: React.MouseEventHandler;
  onCommentClick?: React.MouseEventHandler;
  onShareClick?: React.MouseEventHandler;
  onBookmarkClick?: React.MouseEventHandler;
}) {
  const {
    threadId,
    upVoteCount,
    // downVoteCount,
    commentCount,
    voteStatus,
    voteType,
    isBookmarked,
    textAlign,
    showReaction,
    showCommentCount,
  } = useThreadItemContext((state) => ({
    threadId: state.item.id,
    upVoteCount: state.item.up_vote_score,
    downVoteCount: state.item.down_vote_score,
    commentCount: state.item.replies_count,
    voteStatus: state.item.vote?.type,
    voteType: state.config.reactionType,
    isBookmarked: state.item.is_bookmarked,
    textAlign: state.config.textAlignment,
    showReaction: state.config.showReaction,
    showCommentCount: state.config.showCommentCount,
  }));

  const hideUpvoteStats = upVoteCount === 0;
  const hideCommentStats = commentCount === 0;
  const hideStats = hideUpvoteStats && hideCommentStats;

  const intl = useIntl();

  const handleClick = React.useCallback(
    (callback?: React.MouseEventHandler) => (event: React.MouseEvent) => {
      event.stopPropagation();
      if (callback) {
        callback(event);
      }
    },
    [],
  );

  const handleReactionClick = React.useCallback(
    (newType: Moim.Enums.VoteStatus) => {
      reactionHandler?.(voteStatus !== newType ? newType : VoteStatus.NONE);
    },
    [voteStatus, reactionHandler],
  );

  if (!showReaction && !showCommentCount && !onShareClick && !onBookmarkClick) {
    return null;
  }
  return (
    <>
      {!hideStats && (
        <StatWrapper textAlign={textAlign}>
          {!hideUpvoteStats && (
            <ReactionStat>
              {intl.formatMessage(
                { id: "stat_feed_reaction_up" },
                {
                  count: intl.formatNumber(upVoteCount, {
                    notation: "compact",
                  }),
                },
              )}
            </ReactionStat>
          )}
          {!hideCommentStats && (
            <ReactionStat>
              {intl.formatMessage(
                { id: "stat_feed_comment" },
                {
                  count: intl.formatNumber(commentCount, {
                    notation: "compact",
                  }),
                },
              )}
            </ReactionStat>
          )}
        </StatWrapper>
      )}

      <Wrapper textAlign={textAlign}>
        {showReaction && (
          <>
            {voteType === "up" ? (
              <ItemWrapper
                onClick={handleClick(() =>
                  handleReactionClick(VoteStatus.POSITIVE),
                )}
              >
                <LargeLike
                  threadId={threadId}
                  liked={voteStatus === VoteStatus.POSITIVE}
                  likeCount={upVoteCount}
                  handleLike={reactionHandler}
                  disableOpenVotedUserList
                />
              </ItemWrapper>
            ) : (
              <>
                <ItemWrapper
                  onClick={handleClick(() =>
                    handleReactionClick(VoteStatus.POSITIVE),
                  )}
                >
                  <LargeUp
                    status={voteStatus ?? VoteStatus.NONE}
                    upCount={upVoteCount}
                    onUpCountClick={handleClick(onUpCountClick)}
                    handler={reactionHandler}
                    showUpCount={false}
                    showLabel
                  />
                </ItemWrapper>
                {/* TODO: Uncomment this when reaction customization is implemented */}
                {/* <ItemWrapper
                  onClick={handleClick(() =>
                    handleReactionClick(VoteStatus.NEGATIVE),
                  )}
                >*/}
                {/*  <LargeDown*/}
                {/*    status={voteStatus ?? VoteStatus.NONE}*/}
                {/*    downCount={downVoteCount}*/}
                {/*    handler={reactionHandler}*/}
                {/*    onDownCountClick={handleClick(onDownCountClick)}*/}
                {/*    showDownCount={false}*/}
                {/*    showLabel*/}
                {/*  />*/}
                {/* </ItemWrapper>*/}
              </>
            )}
          </>
        )}

        <ItemWrapper onClick={handleClick(onCommentClick)}>
          <LargeComment
            count={commentCount}
            showCommentCount={false}
            showLabel
          />
        </ItemWrapper>

        <ItemWrapper onClick={handleClick(onShareClick)}>
          <LargeShare showLabel />
        </ItemWrapper>

        <ItemWrapper onClick={handleClick(onBookmarkClick)}>
          <LargeBookmark isBookmarked={isBookmarked} showLabel />
        </ItemWrapper>
      </Wrapper>
    </>
  );
}

export default React.memo(Engagement);
