import * as React from "react";
import styled from "styled-components";
import { useIntl } from "react-intl";
import { useRouteMatch } from "react-router-dom";
import useRedirect from "common/hooks/useRedirect";
import useCurrentGroup from "common/hooks/useCurrentGroup";
import { MoimURL } from "common/helpers/url";
import MobileSearchForm, {
  IRef,
} from "../../components/inputBar/mobileSearchForm";
import { Input } from "../../components/inputBar/mobileSearchForm/styled";
import {
  cssVariables,
  MobileSearchIcon,
} from "../../components/inputBar/styled";
import { TopNaviBlockPropertyStyle } from "app/modules/layout/components/topNavigation/desktop/components/elements/section/styled";

const StyleSheetVariablesInjector = styled.div<{
  tintColor?: Moim.Blockit.V2.ColorValue;
}>`
  ${cssVariables}
`;

const Container = styled.div<{
  element?: Partial<Moim.Layout.Navigation.SearchElementType>;
}>`
  display: flex;
  align-items: center;

  min-width: 0;
  min-height: 0;

  ${TopNaviBlockPropertyStyle}
`;

const InputContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

interface IProps {
  prefixKey?: string;
  showInputBox?: boolean;
  initialValue?: string;
  elementPaletteProps?: Moim.Theme.CommonElementThemePaletteProps;
  blockProperties?: Partial<Moim.Layout.Navigation.SearchElementType>;
}

const MobileSearchButton: React.FC<IProps> = ({
  showInputBox,
  initialValue,
  elementPaletteProps,
  blockProperties,
  prefixKey,
}) => {
  const intl = useIntl();
  const { params } = useRouteMatch<Moim.IMatchParams>();
  const redirect = useRedirect();
  const currentMoim = useCurrentGroup();

  const refSearchForm = React.useRef<IRef | null>(null);

  const [value, setValue] = React.useState(initialValue ?? "");

  const handleSearchFormOpen = React.useCallback(() => {
    refSearchForm.current?.open();
  }, []);

  const handleEnter = React.useCallback(() => {
    if (value) {
      refSearchForm.current?.close();
      if (params.tab) {
        redirect(
          new MoimURL.SearchWithTab({
            query: value,
            tab: params.tab,
          }).toString(),
        );
      } else {
        redirect(new MoimURL.Search({ query: value }).toString());
      }
    }
  }, [params.tab, redirect, value]);

  const displayElement = React.useMemo(() => {
    if (showInputBox) {
      return (
        <InputContainer onClick={handleSearchFormOpen}>
          <MobileSearchIcon />
          <Input
            readOnly={true}
            value={value}
            placeholder={intl.formatMessage(
              { id: "search_moim_placeholder" },
              { moim_name: currentMoim?.name ?? "" },
            )}
          />
        </InputContainer>
      );
    }

    return (
      <MobileSearchIcon
        elementPaletteProps={elementPaletteProps}
        role="button"
        onClick={handleSearchFormOpen}
      />
    );
  }, [
    currentMoim?.name,
    elementPaletteProps,
    handleSearchFormOpen,
    intl,
    showInputBox,
    value,
  ]);

  return (
    <>
      <StyleSheetVariablesInjector tintColor={blockProperties?.tintColor}>
        <Container element={blockProperties}>{displayElement}</Container>
      </StyleSheetVariablesInjector>
      <MobileSearchForm
        ref={refSearchForm}
        prefixKey={prefixKey}
        value={value}
        onChange={setValue}
        onEnter={handleEnter}
      />
    </>
  );
};

export default MobileSearchButton;
