import { StaticChipSelection } from "common/components/designSystem/selection";
import SelectionBottomSheet from "common/components/selectionBottomSheet";
import {
  EmptyText,
  EmptyTextWrapper,
  SelectedListWrapper,
  TextButtonWrapper,
} from "./styled";
import SelectedList from "common/components/searchInput/selectable/selectedList";
import { TextGeneralButton } from "common/components/designSystem/buttons";
import React from "react";
import useOpenState from "common/hooks/useOpenState";
import { IOption } from "common/components/designSystem/selection/type";
import { ISelectedData } from "common/components/searchInput/selectable/type";
import { useIntlShort } from "common/hooks/useIntlShort";

interface IProps {
  item: Moim.Form.IFormItem;
  title: React.ReactNode;
  placeholder: string;
  currentValue: any;
  error: any;
  chipOptions: IOption[];
  onChange: (id: Moim.Id, value: any) => void;
}

export const MultiSelectionBottomSheetFormItem = ({
  item,
  title,
  placeholder,
  currentValue,
  error,
  chipOptions,
  onChange,
}: IProps) => {
  const intl = useIntlShort();
  const {
    isOpen: isBottomSheetOpen,
    open: openBottomSheet,
    close: closeBottomSheet,
  } = useOpenState();

  const [bottomSheetOptions, setBottomSheetOptions] = React.useState<IOption[]>(
    [],
  );
  const [bottomSheetSelectedData, setBottomSheetSelectedData] = React.useState<
    ISelectedData[]
  >([]);

  const handleCloseBottomSheet = React.useCallback(() => {
    (document.activeElement as HTMLElement)?.blur();
    closeBottomSheet();
  }, [closeBottomSheet]);

  const handleBottomSheetSearch = React.useCallback(
    (searchValue: string) => {
      if (searchValue) {
        setBottomSheetOptions(
          chipOptions.filter(option => option.label.includes(searchValue)),
        );
      }
      if (searchValue === "") {
        setBottomSheetOptions(chipOptions);
      }
    },
    [chipOptions],
  );

  const handleClickSelectedOptionRemoveButton = React.useCallback(
    (selectedId: Moim.Id) => {
      setBottomSheetSelectedData(prevState =>
        prevState.filter(selectedData => selectedData.id !== selectedId),
      );
    },
    [setBottomSheetSelectedData],
  );

  React.useEffect(() => {
    setBottomSheetSelectedData(
      currentValue?.map(value => ({
        id: value,
        name: value,
      })) || [],
    );
    setBottomSheetOptions(chipOptions);
  }, [currentValue, isBottomSheetOpen, chipOptions]);

  return (
    <>
      <StaticChipSelection
        isMultiple
        state={error ? "error" : "normal"}
        options={chipOptions}
        selected={currentValue}
        placeholder={placeholder}
        onChange={() => null}
        size="l"
        reasonText={error}
        onFocus={openBottomSheet}
        openMenuOnFocus={false}
        openMenuOnClick={false}
      />
      <SelectionBottomSheet
        isMultipleSelect
        open={isBottomSheetOpen}
        options={bottomSheetOptions}
        selected={bottomSheetSelectedData}
        setSelected={setBottomSheetSelectedData}
        onClose={handleCloseBottomSheet}
        title={title}
        listHeader={
          bottomSheetSelectedData.length === 0 ? (
            <EmptyTextWrapper>
              <EmptyText>
                {intl("dialog_select_options_options_placeholder")}
              </EmptyText>
            </EmptyTextWrapper>
          ) : (
            <SelectedListWrapper>
              <SelectedList
                hideProfileImage
                selected={bottomSheetSelectedData}
                onClickSelectedItemRemoveButton={
                  handleClickSelectedOptionRemoveButton
                }
              />
            </SelectedListWrapper>
          )
        }
        listHeaderRight={
          <TextButtonWrapper>
            <TextGeneralButton
              size={"s"}
              onClick={() => setBottomSheetSelectedData([])}
              disabled={!bottomSheetSelectedData.length}
            >
              {intl("button_clear_all_delete")}
            </TextGeneralButton>
          </TextButtonWrapper>
        }
        nextButtonText={intl("button_done")}
        enableSearch
        onSearch={handleBottomSheetSearch}
        onNext={value => {
          onChange(item.id, Array.isArray(value) ? value : currentValue);
          handleCloseBottomSheet();
        }}
        beforeClose={value => {
          onChange(item.id, Array.isArray(value) ? value : currentValue);
        }}
      />
    </>
  );
};
