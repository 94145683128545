import React from "react";
import styled from "styled-components";
import { B4Regular } from "common/components/designSystem/typos";

import UserProfileImage from "common/components/userProfileImage";
import { useIntlShort } from "common/hooks/useIntlShort";
import { useStoreState } from "app/store";
import { useSingleLineStyle } from "common/components/designSystem/styles";
import { SpacerVertical } from "common/components/designSystem/spacer";
import { CoinBalanceChip } from "../../../preview/components/readMore/unlockMethod";
import { px2rem } from "common/helpers/rem";

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 0 ${px2rem(4)};
`;

const Recipient = styled(B4Regular)`
  display: flex;
  align-items: center;
  color: ${props => props.theme.colorV2.colorSet.grey300};
`;

const RecipientName = styled(B4Regular)`
  color: ${props => props.theme.colorV2.colorSet.grey300};
  ${useSingleLineStyle}
`;
const CoinTransferInfo: React.FC<{
  recipientUserId: string;
  coinBalance: number;
  coinSymbol: string | undefined;
}> = ({ recipientUserId, coinBalance, coinSymbol }) => {
  const intl = useIntlShort();
  const { recipient } = useStoreState(state => ({
    recipient: state.entities.users[recipientUserId],
  }));
  return (
    <Wrapper>
      <Recipient>
        <span>{intl("send_candy_to_unlock_post_recipient")}</span>
        <SpacerVertical value={6} />
        <UserProfileImage
          size="xs"
          userId={recipientUserId}
          src={recipient?.avatar_url}
        />
        <SpacerVertical value={4} />
        <RecipientName>{recipient?.name}</RecipientName>
      </Recipient>
      <CoinBalanceChip>
        {intl("quantity_candy", {
          n: coinBalance,
          unit: coinSymbol,
        })}
      </CoinBalanceChip>
    </Wrapper>
  );
};

export default CoinTransferInfo;
