import * as React from "react";

import Image from "./image";
import Description from "./description";
import Engage from "./engage";
import { PropertiesWithThumbnail } from "../styled";
import Author from "./author";
import Rating from "./rating";
import TimeStamp from "./timeStamp";

import { useStoreState } from "app/store";
import { currentGroupSelector } from "app/selectors/app";
import { IThreadItemConfig } from "app/typings/thread";
import { ItemIdTypes } from "app/enums";

export const Properties: React.FC<{
  thread: Moim.Forum.IThread<Moim.Forum.IProductReviewThreadMeta | undefined>;
  config: Partial<IThreadItemConfig>;
  disableAnonymousSuffix?: boolean;
}> = ({ thread, config, disableAnonymousSuffix }) => {
  const isDraftThread = thread.id.startsWith(ItemIdTypes.DRAFT);
  const enableProductReviewRating = useStoreState(
    state => currentGroupSelector(state)?.config.enableProductReviewRating,
  );
  const showThumbnail = Boolean(config?.showThumbnail);
  const showDescription = Boolean(config?.showText);
  const showAuthor = Boolean(config?.showAuthor);
  const showEngage = Boolean(config.showCommentCount || config.showReaction);
  const showRating = Boolean(enableProductReviewRating && thread.rate);
  const showReviewOfPurchaseChip = Boolean(
    config.showReviewOfPurchaseChip && thread.meta?.purchaseItem?.id,
  );
  const rating =
    showReviewOfPurchaseChip || showRating ? (
      <Rating
        rating={thread.rate}
        showReviewOfPurchaseChip={showReviewOfPurchaseChip}
        showRating={showRating}
      />
    ) : null;
  const image = showThumbnail && (
    <Image
      thumbnail={thread.preview?.thumbnail}
      ratio={
        config.thumbnailConfig?.type === "ratio"
          ? config.thumbnailConfig.value
          : undefined
      }
      radius={config.thumbnailConfig?.radius}
      isVideo={thread.preview?.isVideo}
    />
  );
  const author = showAuthor && (
    <Author
      author={thread.author}
      showAuthorAvatar={Boolean(config?.showAuthorAvatar)}
      showAuthorPosition={Boolean(config?.showAuthorPosition)}
      anonymousData={thread.anonymous_data}
      disableAnonymousSuffix={disableAnonymousSuffix}
    />
  );

  const descriptionText =
    thread.preview?.description ??
    thread.content
      .filter(data => data.type === "text")
      .map(data => data.content)
      .slice(0, config.textMaxLine ?? 3)
      .join("\n");
  const description = showDescription && (
    <Description
      description={descriptionText}
      descriptionPlain={thread.preview?.descriptionPlain}
      line={config.textMaxLine}
    />
  );

  const engage = showEngage && (
    <Engage
      threadId={thread.id}
      upVoteCount={thread.up_vote_score}
      downVoteCount={thread.down_vote_score}
      commentCount={thread.replies_count}
      voteType={config.reactionType}
      voteStatus={thread.vote?.type}
      showCommentCount={Boolean(config.showCommentCount)}
      showReaction={Boolean(config.showReaction)}
    />
  );

  const timeStamp = config.showDate && (
    <TimeStamp
      date={
        isDraftThread
          ? thread.edited_at ?? thread.created_at
          : thread.created_at
      }
    />
  );

  switch (config.thumbnailConfig?.position) {
    case "top":
      return (
        <PropertiesWithThumbnail
          thumbnailPosition={config.thumbnailConfig.position}
        >
          <div className="image">{image}</div>
          <div className="properties">
            {rating}
            {author}
            {description}
            {engage}
            {timeStamp}
          </div>
        </PropertiesWithThumbnail>
      );
    case "left":
      return (
        <PropertiesWithThumbnail
          thumbnailPosition={config.thumbnailConfig.position}
        >
          <div className="image">{image}</div>
          <div className="properties">
            {rating}
            {author}
            {description}
            {engage}
            {timeStamp}
          </div>
        </PropertiesWithThumbnail>
      );
    case "bottom":
      return (
        <PropertiesWithThumbnail
          thumbnailPosition={config.thumbnailConfig.position}
        >
          <div className="properties">
            {rating}
            {author}
            {description}
            {engage}
            {timeStamp}
          </div>
          <div className="image">{image}</div>
        </PropertiesWithThumbnail>
      );
    case "right":
      return (
        <>
          {rating}
          <PropertiesWithThumbnail
            thumbnailPosition={config.thumbnailConfig.position}
          >
            <div className="properties">
              {author}
              {description}
            </div>
            <div className="image">{image}</div>
          </PropertiesWithThumbnail>
          {engage}
          {timeStamp}
        </>
      );
    default:
      return null;
  }
};
