import React from "react";
import styled from "styled-components";

import { Spacer } from "common/components/designSystem/spacer";
import Tags from "common/components/thread/templates/common/tags";
import GroupAndChannelName from "common/components/thread/templates/common/channelName";

import Author from "./components/author";
import Title from "./components/title";
import Description from "./components/description";
import TimeStamp from "./components/timeStamp";
import Engage from "./components/engage";

import { px2rem } from "common/helpers/rem";
import { Thread } from "app/typings";

import { useStoreState } from "app/store";
import { BGLevel1 } from "common/components/designSystem/BGLevel";
import { useOpacityHoverStyle } from "common/components/designSystem/styles";
import useGetTagsFromThread from "common/hooks/useGetTagsFromThread";

const CompactConversationWrapper = styled(BGLevel1)`
  width: 100%;
  height: 100%;
  border-radius: ${px2rem(4)};
  padding: ${px2rem(4)} ${px2rem(16)};

  overflow: hidden;

  &:hover {
    ${useOpacityHoverStyle}
  }
`;

export const CompactConversationItem = ({
  threadId,
  stat,
  titlePrefix,
  config,
  className,
  disableAnonymousSuffix,
}: Thread.IThreadItemBaseProps) => {
  const thread = useStoreState(state => state.entities.threads[threadId]);
  const showReaction = Boolean(config?.showReaction);
  const showCommentCount = Boolean(config?.showCommentCount);
  const showViewCount = Boolean(config?.showViewCount);
  const showAuthorAvatar = Boolean(config?.showAuthorAvatar);
  const showAuthor = Boolean(config?.showAuthor);
  const showTitle = Boolean(config?.showTitle);
  const showDescription = Boolean(config?.showText);
  const showDate = Boolean(config?.showDate);
  const showTags = Boolean(config?.showTags);
  const showChannel = Boolean(config?.showChannel);
  const showMoim = Boolean(config?.showMoim);

  const textAlign = config?.textAlignment;

  const { tags } = useGetTagsFromThread(thread);

  if (!thread) {
    return null;
  }
  return (
    <CompactConversationWrapper className={className}>
      <Spacer value={12} />
      {(showAuthor || showAuthorAvatar) && (
        <Author
          author={thread.author}
          showAuthor={showAuthor}
          showAvatar={showAuthorAvatar}
          textAlign={textAlign}
          anonymousData={thread.anonymous_data}
          disableAnonymousSuffix={disableAnonymousSuffix}
        />
      )}
      {showTitle && (
        <Title
          title={thread.preview?.title}
          line={config.titleMaxLine}
          stat={stat}
          prefix={titlePrefix}
          textAlign={textAlign}
        />
      )}
      {showDescription && (
        <Description
          description={thread.preview?.description}
          descriptionPlain={thread.preview?.descriptionPlain}
          line={config.textMaxLine}
          isUnread={stat?.isUnread}
          textAlign={textAlign}
        />
      )}
      {showDate && (
        <TimeStamp createdAt={thread.created_at} textAlign={textAlign} />
      )}
      {(showChannel || showMoim) && (
        <>
          <Spacer value={4} />
          <GroupAndChannelName
            channelId={thread.root_id}
            groupId={thread.groupId}
            showChannel={showChannel}
            showMoim={showMoim}
            textAlign={textAlign}
          />
        </>
      )}
      {showTags && tags.length !== 0 && (
        <>
          <Spacer value={4} />
          <Tags tags={tags} textAlign={textAlign} />
        </>
      )}
      {(showReaction || showCommentCount) && (
        <>
          <Spacer value={4} />
          <Engage
            threadId={thread.id}
            upVoteCount={thread.up_vote_score}
            downVoteCount={thread.down_vote_score}
            commentCount={thread.replies_count}
            viewCount={thread.viewCount}
            voteStatus={thread.vote?.type}
            voteType={config?.reactionType}
            textAlign={textAlign}
            showReaction={showReaction}
            showCommentCount={showCommentCount}
            showViewCount={showViewCount}
          />
        </>
      )}
      <Spacer value={12} />
    </CompactConversationWrapper>
  );
};
