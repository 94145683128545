import * as React from "react";
import FallbackImage from "common/components/fallbackImage";
import {
  Wrapper,
  DeleteButton,
  DeleteIcon,
  ImageThumbContainer,
  ImageThumb,
  OrderLabel,
  AddImageThumbContainer,
  AddIcon,
} from "./styled";

interface IImagePreviewProps {
  isLoading?: boolean;
  src?: string;
  file?: File;
  positionLabel: string;
  onClickDelete(): void;
}

export const ImagePreview: React.FC<IImagePreviewProps> = ({
  isLoading,
  src,
  file,
  positionLabel,
  onClickDelete,
}) => {
  const [hasError, setErrorStatus] = React.useState(false);
  const [fileDataUrl, setFileDataUrl] = React.useState<string | undefined>(
    undefined,
  );

  const handleClickDelete = React.useCallback(() => {
    onClickDelete();
  }, [onClickDelete]);

  const handleError = React.useCallback(() => {
    setErrorStatus(true);
  }, []);

  React.useEffect(() => {
    if (file) {
      setFileDataUrl(URL.createObjectURL(file));
    }
  }, [file]);

  return (
    <Wrapper>
      <ImageThumbContainer>
        {hasError ? (
          <FallbackImage />
        ) : (
          <ImageThumb
            loading="lazy"
            src={src ?? fileDataUrl}
            isLoading={isLoading}
            onError={handleError}
          />
        )}
        <DeleteButton onClick={handleClickDelete}>
          <DeleteIcon />
        </DeleteButton>
      </ImageThumbContainer>
      <OrderLabel>{positionLabel}</OrderLabel>
    </Wrapper>
  );
};

interface IAddPreviewProps {
  onClick(): void;
}

export const AddPreview: React.FC<IAddPreviewProps> = ({ onClick }) => {
  return (
    <Wrapper>
      <AddImageThumbContainer onClick={onClick}>
        <AddIcon />
      </AddImageThumbContainer>
    </Wrapper>
  );
};
