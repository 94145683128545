import * as React from "react";
import styled from "styled-components";
import useGroupTexts from "common/hooks/useGroupTexts";

import { textAlignStyle } from "common/components/thread/styles";
import { px2rem } from "common/helpers/rem";
import { useStoreState } from "app/store";
import {
  ContentUnlockChip,
  ContentUnlockedChip,
  CoinImage,
  OwnedIconWrapper,
  OwnedIcon,
} from "common/components/productItemCell/components/image/components/chips/styled";

interface IProps {
  unlockMethods?: Moim.Forum.IThreadUnlockMethod[];
  textAlign?: Moim.Forum.ForumListConfigTextAlignment;
  isUnlocked?: boolean;
}

export const UnlockedChipComponent: React.FC = () => {
  const unlockedTexts = useGroupTexts("unlocked_post");

  return (
    <ContentUnlockedChip>
      <OwnedIconWrapper>
        <OwnedIcon />
      </OwnedIconWrapper>
      {unlockedTexts.singular}
    </ContentUnlockedChip>
  );
};

export const CoinChipComponent: React.FC<{
  coinId: Moim.Id;
  quantity: number;
}> = ({ coinId, quantity }) => {
  const coin = useStoreState(state => state.entities.community_coins[coinId]);

  if (!coin) {
    return null;
  }

  return (
    <ContentUnlockChip
      badgeColor={coin.preview?.hexCode}
      scale={coin.preview?.fogType}
    >
      {coin.preview?.XS ? (
        <CoinImage>
          <img src={coin.preview?.XS} alt={coin.name} />
        </CoinImage>
      ) : null}
      {quantity} {coin.symbol}
    </ContentUnlockChip>
  );
};

const UnlockMethodWrapper = styled.div<{
  textAlign?: Moim.Forum.ForumListConfigTextAlignment;
}>`
  display: flex;
  align-items: center;
  gap: ${px2rem(4)};
  ${textAlignStyle};
`;

function UnlockMethods({ unlockMethods, textAlign, isUnlocked }: IProps) {
  if (isUnlocked) {
    return (
      <UnlockMethodWrapper textAlign={textAlign}>
        <UnlockedChipComponent />
      </UnlockMethodWrapper>
    );
  }

  return (
    <UnlockMethodWrapper textAlign={textAlign}>
      {unlockMethods?.map(method => {
        switch (method.resourceType) {
          case "transferCoin":
            return (
              <CoinChipComponent
                key={method.resourceId}
                coinId={method.resourceId}
                quantity={method.quantity}
              />
            );
          default:
            return null;
        }
      })}
    </UnlockMethodWrapper>
  );
}

export default React.memo(UnlockMethods);
