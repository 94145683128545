import { Svg24NotioffG } from "@moim/icons";
import styled from "styled-components";
// icons

import { BG_LEVEL_BACKGROUND_CLASS_NAME } from "common/components/designSystem/BGLevel";
import { pB1RegularStyle } from "common/components/designSystem/typos";
import { px2rem } from "common/helpers/rem";

export const POST_TITLE_APPBAR_HEIGHT = 45;

export const Wrapper = styled.div`
  width: 100%;
`;

export const NotiOffIcon = styled(Svg24NotioffG).attrs({
  size: "s",
  role: "button",
  touch: 44,
})``;

export const ContentWrapper = styled.div`
  ${pB1RegularStyle}

  > *:first-child {
    margin-top: ${px2rem(8)};
  }

  [class^="PlainText"] {
    padding: 0;
  }

  [class^="PlainText"] + [class^="PlacementContainer"] {
    margin-top: ${px2rem(16)};
  }

  [class^="ImageContainer"] > img {
    width: 100%;
  }
`;

export const StickyWrapper = styled.div.attrs({
  className: BG_LEVEL_BACKGROUND_CLASS_NAME,
})`
  position: sticky;
  bottom: 0;
  z-index: ${props => props.theme.zIndexes.default};
`;
