// vendor
import * as React from "react";
// hook
// component
import PageUpdater from "common/components/pageUpdater";
// helper
import useCurrentGroup from "common/hooks/useCurrentGroup";
import useOGData from "common/hooks/useOGData";

function RootHelmet() {
  const currentMoim = useCurrentGroup();
  const favicons = currentMoim?.favicon
    ? [
        ...currentMoim.favicon.android,
        ...currentMoim.favicon.ios,
        ...currentMoim.favicon.common,
      ]
    : [];

  const { seoTitle, seoDescription, metaObjects } = useOGData({
    ogData: currentMoim?.ogData,
    fallbackTitle: currentMoim?.name,
    fallbackDescription: currentMoim?.description,
    fallbackImageUrl:
      currentMoim?.banner?.type === "image"
        ? currentMoim.banner.data.url
        : undefined,
  });

  return (
    <PageUpdater
      title={seoTitle}
      description={seoDescription}
      metaObjects={metaObjects}
      favicons={favicons}
    />
  );
}

export default RootHelmet;
