import _ from "lodash";
import { isServer } from "./envChecker";

/**
 * requestId is ignored on csr
 */
export function getInitialData(path: string, requestId: string) {
  const source = isServer() ? (global as any) : window;
  const pathToUse = isServer() && !!requestId ? `${requestId}.${path}` : path;
  return _.cloneDeep(_.get(source, pathToUse, undefined));
}

/**
 * requestId is ignored on csr
 */
export function setInitialData(path: string, requestId: string, data: any) {
  const source = isServer() ? (global as any) : window;
  const pathToUse = isServer() && !!requestId ? `${requestId}.${path}` : path;

  return _.set(source, pathToUse, data);
}
