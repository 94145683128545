import * as React from "react";
import { FlattenInterpolation } from "styled-components";
import AppBar from "common/components/appBar";
import { Wrapper, Header, Content } from "./styled";
import FreezeView from "common/components/freezeView";

type IProps = React.PropsWithChildren<{
  title: React.ReactNode;
  disableFreeze?: boolean;
  modalOpenStatus?: boolean;
  actionButton?: React.ReactNode;
  leftElement?: React.ReactNode;
  headerWrapperStyle?: FlattenInterpolation<any>;
  customContentWrapper?: React.ElementType;
}>;

export default function AppBarModalLayout({
  title,
  modalOpenStatus = false,
  disableFreeze,
  leftElement,
  actionButton,
  headerWrapperStyle,
  children,
  customContentWrapper = Content,
}: IProps) {
  const child = React.useMemo(
    () => React.createElement(customContentWrapper, undefined, children),
    [children, customContentWrapper],
  );
  return (
    <Wrapper>
      <Header overrideStyle={headerWrapperStyle}>
        <AppBar
          titleElement={title}
          titleAlignment="Center"
          rightButton={actionButton}
          leftButton={leftElement}
        />
      </Header>
      {disableFreeze ? (
        child
      ) : (
        <FreezeView isFreeze={modalOpenStatus} delayedFreeze={100}>
          {child}
        </FreezeView>
      )}
    </Wrapper>
  );
}
