import { IMoimConfigMenuButtonProp } from "../../types";
import MenuItem from "../menuItem";
import { Svg18LinkchannelG, Svg24LinkchannelG } from "@moim/icons";
import { useStoreState } from "app/store";
import { getSubdomain } from "common/helpers/hostnameParser";
import useCurrentHubGroup from "common/hooks/useCurrentHubGroup";
import * as React from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";

const LinkIcon = styled(Svg24LinkchannelG).attrs((props) => ({
  size: "s",
  iconColor: props.theme.colorV2.colorSet.grey300,
}))``;

const LinkSmallIcon = styled(Svg18LinkchannelG).attrs((props) => ({
  size: "xs",
  iconColor: props.theme.colorV2.colorSet.grey300,
}))``;

export const getAdminV2Url = (
  hubGroupUrl?: string,
  groupId?: string | null,
  path?: string,
) => {
  if (!hubGroupUrl || !groupId) return "";
  const url = new URL(hubGroupUrl);

  url.search = `?initialGroupId=${groupId}`;
  url.hostname = getSubdomain(url.hostname)
    ? `admin-${url.hostname}`
    : `admin.${url.hostname}`;

  url.pathname = path ?? "";
  return url.toString();
};

type IProps = IMoimConfigMenuButtonProp;

export function MoimAdminV2Button({ onClickButton }: IProps) {
  const intl = useIntl();
  const { groupId } = useStoreState((state) => ({
    groupId: state.app.currentGroupId,
  }));
  const hubGroup = useCurrentHubGroup();

  return (
    <a href={getAdminV2Url(hubGroup?.url, groupId)} target="_blank">
      <MenuItem
        icon={<LinkIcon />}
        smallIcon={<LinkSmallIcon />}
        onClickButton={onClickButton}
      >
        {intl.formatMessage({
          id: "menu_admin_v2",
        })}
      </MenuItem>
    </a>
  );
}
