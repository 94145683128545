import * as React from "react";
import EngageItemComponent from "./engageItem";

import { Engagement } from "./styled";

interface IProps {
  className?: string;
  voteScore: number;
  viewCount: number;
  repliesCount?: number;
  reviewsCount?: number;
  commentsCount?: number;
  block: Moim.Component.ProductItem.IStat;
  horizontalAlign?: "start" | "center" | "end" | "space-around";
}

const Stat = ({
  className,
  voteScore,
  viewCount,
  repliesCount: _repliesCount,
  reviewsCount,
  commentsCount,
  block,
  horizontalAlign,
}: IProps) => {
  const repliesCount =
    (_repliesCount ?? 0) + (reviewsCount ?? 0) + (commentsCount ?? 0);
  const visibleVoteScore = Boolean(block.showVoteScore !== false && voteScore);
  const visibleRepliesCount = Boolean(
    block.showCommentCount !== false && repliesCount,
  );
  const visibleViewCount = Boolean(block.showViewCount !== false && viewCount);

  const visible = visibleVoteScore || visibleRepliesCount || visibleViewCount;

  if (!visible) {
    return null;
  }

  return (
    <Engagement className={className} horizontalAlign={horizontalAlign}>
      <EngageItemComponent
        type="voteCount"
        isVisible={visibleVoteScore}
        value={voteScore}
      />
      <EngageItemComponent
        type="replyCount"
        isVisible={visibleRepliesCount}
        value={repliesCount}
      />
      <EngageItemComponent
        type="viewCount"
        isVisible={visibleViewCount}
        value={viewCount}
      />
    </Engagement>
  );
};

export default React.memo(Stat);
