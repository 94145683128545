import React from "react";
import styled from "styled-components";
import RatingBase from "@material-ui/lab/Rating";
import { B4Regular } from "common/components/designSystem/typos";
import { px2rem } from "common/helpers/rem";
import ChipBase from "common/components/chips";
import { useIntlShort } from "common/hooks/useIntlShort";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: ${px2rem(6)};
`;

const RatingWrapper = styled(B4Regular)`
  padding: ${px2rem(4)} 0;
  color: ${props => props.theme.colorV2.colorSet.grey400};
  display: flex;
  align-items: center;
  gap: ${px2rem(4)};

  .MuiSvgIcon-root {
    width: ${px2rem(18)};
    height: ${px2rem(18)};
  }
`;

const ReviewOfPurchaseChip = styled(ChipBase).attrs({
  shape: "rectangle",
  size: "small",
})`
  color: ${props => props.theme.colorV2.colorSet.white1000};
  background-color: ${props => props.theme.colorV2.primary.main};
`;

interface IProps {
  rating?: number;
  showReviewOfPurchaseChip?: boolean;
  showRating: boolean;
}

const Rating = ({ rating, showRating, showReviewOfPurchaseChip }: IProps) => {
  const intl = useIntlShort();
  return (
    <Wrapper>
      {showReviewOfPurchaseChip && (
        <ReviewOfPurchaseChip>
          {intl("review_by_purchase")}
        </ReviewOfPurchaseChip>
      )}
      {showRating && (
        <RatingWrapper>
          <RatingBase precision={1} readOnly={true} value={rating} />
          <span>{rating}</span>
        </RatingWrapper>
      )}
    </Wrapper>
  );
};

export default React.memo(Rating);
