import React from "react";
import { VoteStatus } from "app/enums";
import {
  StyledBuyersCount,
  StyledCredit,
  StyledDescription,
  StyledDiscountPrice,
  StyledImage,
  StyledPrice,
  StyledProductName,
  StyledRating,
  StyledSeller,
  StyledShipping,
  StyledStat,
  StyledStatus,
  StyledStockCount,
  StyledTimeSaleTimer,
  StyledBuyNowButton,
  StyledAddCartButton,
  StyledTeamBuyingButton,
  StyledTeamBuyingBadgeTitle,
  StyledTotalBuyerCount,
  StyledProductItemWrapper,
  StyledBrand,
} from "./common";
import HidePriceForGuestChecker from "common/components/unsiginedChecker/unsiginedB2BChecker";

const ProductItemElementRenderer: React.FC<{
  product: Moim.Commerce.IProduct;
  block: Moim.Component.ProductItem.ProductCellBlockType;
  wrapperBlock: Moim.Component.ProductItem.IWrapper;
  onClickLikeButtonClick?(nextStatus: boolean): void;
  onSellerSelect?(): void;
  onBuyNowSelect?(): void;
  onAddToCartSelect?(): void;
}> = ({
  product,
  block,
  wrapperBlock,
  onClickLikeButtonClick,
  onSellerSelect,
  onAddToCartSelect,
  onBuyNowSelect,
}) => {
  const enabledTeamBuying = Boolean(
    product.enableTeamBuying && product.activeTeamBuyingId,
  );
  const buyable =
    product?.backOrderAllowed ||
    (product.status === "onSale" &&
      (product?.stockCount === undefined || product?.stockCount > 0));

  switch (block.type) {
    case "wrapper":
      return <StyledProductItemWrapper block={block} product={product} />;
    case "brand":
      return <StyledBrand block={block} brandId={product?.brandId} />;
    case "image":
      return (
        <StyledImage
          block={block}
          disabled={!buyable}
          productId={product.id}
          isFavorite={product.vote?.type === VoteStatus.POSITIVE}
          images={product.images}
          productSets={product.productSets}
          onClickLikeButtonClick={onClickLikeButtonClick}
        />
      );
    case "timer":
      return <StyledTimeSaleTimer product={product} block={block} />;
    case "product-name":
      return (
        <StyledProductName
          horizontalAlign={wrapperBlock.horizontalAlign}
          block={block}
          name={product.name}
        />
      );

    case "description":
      return (
        <StyledDescription
          horizontalAlign={wrapperBlock.horizontalAlign}
          block={block}
          description={product.description}
        />
      );
    case "discount-price":
      return (
        <HidePriceForGuestChecker productId={product.id}>
          <StyledDiscountPrice
            horizontalAlign={wrapperBlock.horizontalAlign}
            block={block}
            teamBuyingId={
              enabledTeamBuying ? product.activeTeamBuyingId : undefined
            }
            price={product.price_price}
            originPrice={product.originalPrice_price}
          />
        </HidePriceForGuestChecker>
      );
    case "price":
      return (
        <HidePriceForGuestChecker productId={product.id}>
          <StyledPrice
            horizontalAlign={wrapperBlock.horizontalAlign}
            teamBuyingId={
              enabledTeamBuying ? product.activeTeamBuyingId : undefined
            }
            block={block}
            price={product.price_price}
            additionalFees={product.additionalFees}
          />
        </HidePriceForGuestChecker>
      );
    case "credit":
      return (
        <HidePriceForGuestChecker productId={product.id}>
          <StyledCredit
            horizontalAlign={wrapperBlock.horizontalAlign}
            block={block}
            productId={product.id}
          />
        </HidePriceForGuestChecker>
      );
    case "rating":
      return (
        <StyledRating
          horizontalAlign={wrapperBlock.horizontalAlign}
          block={block}
          avgRate={product.avgRate}
          ratingsCount={product.ratingsCount}
          reviewsCount={product.reviewsCount}
        />
      );
    case "shipping":
      return (
        <StyledShipping
          horizontalAlign={wrapperBlock.horizontalAlign}
          block={block}
          shippingRequired={product.shippingRequired}
          shippingFee={product.shippingFee_price}
        />
      );
    case "status":
      return (
        <StyledStatus
          horizontalAlign={wrapperBlock.horizontalAlign}
          block={block}
          productType={product.type}
          status={product.status}
        />
      );
    case "stock-count":
      return (
        <StyledStockCount
          horizontalAlign={wrapperBlock.horizontalAlign}
          block={block}
          stockCount={product.stockCount}
          soldCount={product.soldCount}
        />
      );
    case "buyers-count":
      return (
        <StyledBuyersCount
          horizontalAlign={wrapperBlock.horizontalAlign}
          block={block}
          buyersCount={product.buyersCount}
        />
      );
    case "stat":
      return (
        <StyledStat
          horizontalAlign={wrapperBlock.horizontalAlign}
          block={block}
          viewCount={product.view_count}
          voteScore={product.vote_score}
          commentsCount={product.commentsCount}
          repliesCount={product.repliesCount}
          reviewsCount={product.reviewsCount}
        />
      );
    case "seller":
      return (
        <StyledSeller
          horizontalAlign={wrapperBlock.horizontalAlign}
          block={block}
          sellerId={product.sellerId}
          onSelect={onSellerSelect}
        />
      );

    case "add-cart-button": {
      if (enabledTeamBuying) {
        return null;
      }
      return (
        <StyledAddCartButton
          block={block}
          product={product}
          onSelect={onAddToCartSelect}
        />
      );
    }
    case "buy-now-button": {
      if (enabledTeamBuying) {
        return null;
      }
      return (
        <StyledBuyNowButton
          block={block}
          product={product}
          onSelect={onBuyNowSelect}
        />
      );
    }

    case "team-buying-button":
      if (enabledTeamBuying) {
        return (
          <StyledTeamBuyingButton
            block={block}
            product={product}
            teamBuyingId={product.activeTeamBuyingId!}
            onSelect={onBuyNowSelect}
          />
        );
      }
      return null;

    case "team-buying-badge-title": {
      if (enabledTeamBuying) {
        return (
          <StyledTeamBuyingBadgeTitle
            teamBuyingId={product.activeTeamBuyingId!}
            block={block}
          />
        );
      }
      return null;
    }

    case "total-buyer-count": {
      return (
        <StyledTotalBuyerCount
          buyerCount={product.buyersCount}
          teamBuyingId={product.activeTeamBuyingId}
          block={block}
        />
      );
    }

    default:
      return null;
  }
};

export default ProductItemElementRenderer;
