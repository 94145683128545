import React from "react";
import styled from "styled-components";
import { px2rem } from "common/helpers/rem";

import { useStoreState } from "app/store";

import { ChipComponent } from "common/components/withPositionChip/new";

import { B3Regular } from "common/components/designSystem/typos";

interface IProps {
  positionId: string;
}

const PositionInfo = styled.div`
  display: flex;
  gap: ${px2rem(3)};
`;

const PositionItem = ({ positionId }: IProps) => {
  const positionName = useStoreState(
    state => state.entities.positions[positionId]?.name,
  );
  if (!positionName) return null;

  return (
    <PositionInfo>
      <B3Regular>{positionName}</B3Regular>
      (<ChipComponent positionId={positionId} />)
    </PositionInfo>
  );
};

export default PositionItem;
