// hooks
import useIsMobile from "common/hooks/useIsMobile";
import { useStoreState } from "app/store";
import { productItemLayoutSelector } from "app/selectors/componentLayout";

export const useParseItemLayout = (
  type: keyof PickValue<Moim.Component.ILayoutConfig, "productItem">,
  itemLayout?: Moim.Component.ProductItem.IWrapper,
  itemLayout_web?: Moim.Component.ProductItem.IWrapper,
) => {
  const isMobile = useIsMobile();
  const productItemLayout = useStoreState(
    state =>
      (isMobile || !itemLayout_web ? itemLayout : itemLayout_web) ??
      productItemLayoutSelector(state, type),
  );

  return productItemLayout;
};
