import AcceptLanguageParser from "common/helpers/acceptLanguageParser";
import { isBrowser } from "common/helpers/envChecker";

export type SupportedLanguageType = "en" | "ko" | "ja";

export const SUPPORTED_LANGUAGES: SupportedLanguageType[] = ["en", "ko", "ja"];
export const DEFAULT_LANGUAGE: SupportedLanguageType = "ko";

const cacheMessages: Map<string, { [key: string]: string }> = new Map();

/*
 * WARNING: Only use this function at test or ssr
 * Use this function after `await getMessages(locale)`
 * Because to use cache
 */
export function getMessagesFromCache(locale: string) {
  const messages = cacheMessages.get(locale);
  if (!messages) {
    throw new Error(
      "WARNING: Only use this function at test or ssr. Use this function after `await getMessages(locale)` Because to use cache.",
    );
  }
  return messages;
}

export function browserLocale(
  localeCode?: string | null,
  defaultLocale?: SupportedLanguageType,
  supportedLanguages?: string[],
): SupportedLanguageType {
  if (
    localeCode &&
    AcceptLanguageParser.pick(
      supportedLanguages ?? SUPPORTED_LANGUAGES,
      localeCode ?? DEFAULT_LANGUAGE,
    )
  ) {
    return localeCode?.split("-")[0] as SupportedLanguageType;
  }

  if (isBrowser()) {
    // browser side
    let nativeLocale =
      navigator.languages && navigator.languages.length > 0
        ? navigator.languages[0]
        : navigator.language || (navigator as any).userLanguage;
    nativeLocale = nativeLocale.split("-")[0];
    if (
      AcceptLanguageParser.pick(
        supportedLanguages ?? SUPPORTED_LANGUAGES,
        nativeLocale ?? DEFAULT_LANGUAGE,
      )
    ) {
      return nativeLocale;
    }
  }

  return defaultLocale ?? DEFAULT_LANGUAGE;
}

export function browserLocaleCountry(localeCode?: string): string {
  if (localeCode) {
    const locale = localeCode.split("-");
    return locale.length > 1 ? locale[1] : locale[0];
  }

  if (isBrowser()) {
    // browser side
    let locale =
      navigator.languages && navigator.languages.length > 0
        ? navigator.languages[0]
        : navigator.language || (navigator as any).userLanguage;
    locale = locale.split("-");

    return locale.length > 1 ? locale[1] : locale[0];
  } else {
    // server side
    return "KR";
  }
}
