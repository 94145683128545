import { px2rem } from "common/helpers/rem";
import styled from "styled-components";

export const ListWrapper = styled.div`
  width: 100%;
  height: fit-content;
`;

export const ItemContainer = styled.div<{
  displayRowBorder?: boolean;
  gapSize?: number;
}>`
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    height: 100%;
  }

  > div {
    height: 100%;
  }

  ${(props) =>
    props.displayRowBorder &&
    props.gapSize &&
    `
  position: relative;

  &::after {
    position: absolute;
    bottom: -${px2rem(Math.floor(props.gapSize / 2))};
    width: 100%;
    display: block;

    content: "";
    height: 1px;
    background-color: ${props.theme.colorV2.colorSet.grey50};
  }

  & > div[role="button"] > div::after {
    position: absolute;
    bottom: -6px;
    left: 0;
    width: 100%;
    display: block;
    content: "";
    height: ${props.gapSize / 2}px;
    background-color: ${props.theme.colorV2.colorSet.grey10};
    transition: opacity 200ms ease-in;
    opacity: 0;
  }

  & > div[role="button"] > div::before{
    position: absolute;
    top: -6px;
    left: 0;
    width: 100%;
    display: block;
    content: "";
    height: ${props.gapSize / 2}px;
    background-color: ${props.theme.colorV2.colorSet.grey10};
    transition: opacity 200ms ease-in;

    opacity: 0;
  }

  & > div[role="button"] > div:hover::after {
    opacity: 1;
  }

  & > div[role="button"] > div:hover::before {
    opacity: 1;
  }
  `}
`;
