import React from "react";

import BaseTemplate from "app/hub/createGroup/components/step/template";

import { useIntlShort } from "common/hooks/useIntlShort";
import { useActions, useStoreState } from "app/store";
import { updateMyProfile } from "app/actions/me";
import { Contents, Wrapper } from "../styled";
import { ProfileImageWrapper, Section, UserName } from "./styled";
import ProfileImageChanger from "app/modules/profileEditor/components/profileImageChanger";
import { useSnackbar } from "common/components/alertTemplates/alerts/globalSnackbar/useGlobalSnackbar";
import { getCurrentUserWithParentFallback } from "common/helpers/authentication/actions";
import useCurrentGroup from "common/hooks/useCurrentGroup";
import useCurrentUser from "common/hooks/useCurrentUser";

export const ProfileImage: React.FC<{
  group?: Moim.Group.IGroup | null;
  buttonText?: string;
  skipButtonText?: string;
  onNext(): void;
  onSkip?(): void;
}> = ({ group, buttonText, skipButtonText, onNext, onSkip }) => {
  const intl = useIntlShort();
  const currentUser = useCurrentUser();
  const currentGroup = useCurrentGroup();

  const { open: openSnackbar } = useSnackbar({
    type: "error",
  });

  const { parentUser } = useStoreState(state => ({
    parentUser: state.app.parentMoimUser,
  }));
  const { isAvatarUploading } = useStoreState(state => ({
    isAvatarUploading: state.profileEditorPage.isAvatarUploading,
  }));

  const [avatar, setAvatar] = React.useState<Moim.Id>();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const handleChangeAvatar = React.useCallback(async (value: Moim.Id) => {
    setAvatar(value);
  }, []);

  const { dispatchGetMeProfile } = useActions({
    dispatchGetMeProfile: getCurrentUserWithParentFallback,
  });
  const { dispatchUpdateMyProfile } = useActions({
    dispatchUpdateMyProfile: updateMyProfile,
  });

  const handleClickDoneButton = React.useCallback(async () => {
    try {
      if (!group?.id) {
        return;
      }
      setIsLoading(true);
      const { success, error } = await dispatchUpdateMyProfile(
        {
          avatar_id: avatar,
          signUpInfo: {
            finishedStep: "profileImage",
          },
        },
        undefined,
        group.id,
      );
      if (success) {
        onNext();
      } else {
        openSnackbar({
          text: error?.message,
        });
      }
    } finally {
      setIsLoading(false);
    }
  }, [group, avatar, dispatchUpdateMyProfile, onNext]);

  React.useEffect(() => {
    if (!currentUser) {
      dispatchGetMeProfile(currentGroup?.parent, true);
    }
  }, []);

  return (
    <Wrapper>
      <Contents>
        <BaseTemplate
          stepData={{
            title: intl(
              "custom_system_wording_dialog_signup_profile_picture_setting_title",
            ),
            subTitle: intl(
              "custom_system_wording_dialog_signup_profile_picture_setting_description",
            ),
            buttonText,
            skipButtonText,
          }}
          onClick={handleClickDoneButton}
          onClickSkipButton={onSkip}
          waitingButton={isLoading || isAvatarUploading}
          disabledButton={skipButtonText === undefined && !avatar}
        >
          <Section>
            <ProfileImageWrapper>
              <ProfileImageChanger
                size={"xxl"}
                src={""}
                isAvatarUpdating={isAvatarUploading}
                onChange={handleChangeAvatar}
              />
              <UserName>{currentUser?.name ?? parentUser?.name}</UserName>
            </ProfileImageWrapper>
          </Section>
        </BaseTemplate>
      </Contents>
    </Wrapper>
  );
};
